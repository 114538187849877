import { Clear, Search } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Popover,
  Select,
  Skeleton,
} from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { alertActions } from '../../actions';
import { appConstants, getLocalStorageValue, setLocalStorageValue } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setCurrentGlobalSearchSubTab } from '../../reducers/globalSearch.reducer';
import {
  fetchGlobalFindings,
  fetchGlobalSearchRelatedUrls,
  getAllGlobalSearchAttributes,
  IGlobalSearchDto,
} from './global-search-requests';
import {
  ESelectedFilterTypeOptions,
  EWebModuleTabFilterFields,
  moduleToLabel,
  MODULE_ICON,
} from './GlobalSearchConstants';
import './GlobalSearchContainer.scss';
import GlobalSearchFindings from './GlobalSearchFindings';
import useOnFetchRelatedUrlsFindings from './useOnFetchRelatedUrlsFindings';
import { useOnHandleBlur } from './useOnHandleBlur';
import GlobalSearchSubTab from './GlobalSearchSubTab';
import { AdvancedFilters, ISelectedOptions } from './AdvancedFilters';
import { AdvancedFilterParams } from './AdvancedFiltersUtils';
import useOnFetchAdvancedFilterFindings from './useOnFetchAdvancedFilterFindings';
import { advancedSearchCategories } from './AdvancedFiltersConstants';

export interface IGlobalFilterOptions {
  [key: string]: IGlobalSearchAttributes[];
}

export interface IGlobalSearchAttributes {
  id: number;
  globalSearchLabelFieldId: number;
  globalSearchTypeId: number;
  globalSearchLabelFields: IGlobalSearchLabelFields;
  globalSearchTypes: IGlobalSearchTypes;
}

export interface IGlobalSearchLabelFields {
  id: number;
  globalSearchLabelId: number;
  fieldName: string;
  globalSearchLabel: GlobalSearchLabel;
}

export interface GlobalSearchLabel {
  id: number;
  label: string;
}

export interface IGlobalSearchTypes {
  id: number;
  value: string;
  label: string;
}

export default function GlobalSearchContainer() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectFilterType, setSelectFilterType] = useState<ESelectedFilterTypeOptions>(
    ESelectedFilterTypeOptions.URL,
  );
  const [agFilterFields, setAgFilterFields] = useState<{ fieldName: string; moduleType: string }[]>(
    [],
  );
  const [globalFilterOptions, setGlobalFilterOptions] = useState<IGlobalFilterOptions>({});
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);

  const [globalSearchFindingData, setGlobalSearchFindingData] = useState<{ [key: string]: any }>({
    count: {},
  });

  const dispatch = useAppDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [advancedFilterParams, setAdvancedFilterParams] = useState<AdvancedFilterParams>();
  const [advancedFiltersSelectedOptions, setAdvancedFiltersSelectedOptions] =
    useState<ISelectedOptions>({});

  const { fetchRelatedUrls, globalSearchDstUrlsFindingData } = useOnFetchRelatedUrlsFindings({
    globalFilterOptions,
    searchValue,
    selectFilterType,
    setIsLoading,
    setAgFilterFields,
    advancedFilterParams,
  });

  const handleOpenPopover = () => {
    setAnchorEl(inputSearchRefs.current); // Always align to search icon
  };

  const handleClose = () => {
    inputSearchRefs?.current?.focus();
    setAnchorEl(null);
    resetFilterType();
  };

  const resetFilterType = useCallback(() => {
    if (selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS) {
      setSelectFilterType(ESelectedFilterTypeOptions.URL);
    }
  }, [selectFilterType]);

  const handleDropdownChange = (event: any) => {
    setSelectFilterType(event.target.value);
    handleDropdownClose();
  };

  useEffect(() => {
    const localSearchValue = getLocalStorageValue('globalSearchParams');
    const fetchAllGlobalSearchAttributes = async () => {
      try {
        const result = await getAllGlobalSearchAttributes();
        setSearchValue(localSearchValue?.searchValue || '');
        setSelectFilterType(localSearchValue?.selectFilterType || 'URL');

        setDropdownOptions([
          ...Object.keys(result).concat('Tags'),
          ESelectedFilterTypeOptions.ADVANCED_FILTERS,
        ]);
        setGlobalFilterOptions(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    void fetchAllGlobalSearchAttributes();
  }, []);

  const inputSearchRefs = useRef<HTMLDivElement>(null);

  const fetchGlobalSearchFindings = useCallback(async () => {
    dispatch(setCurrentGlobalSearchSubTab(EWebModuleTabFilterFields.src_url));
    setGlobalSearchFindingData({});
    setAdvancedFilterParams(undefined);
    setIsLoading(true);
    const searchParams: any = globalFilterOptions?.[selectFilterType]?.map(
      (item: IGlobalSearchAttributes) => {
        return {
          moduleType: item.globalSearchTypes.value,
          fieldName: item.globalSearchLabelFields.fieldName,
        };
      },
    );

    const filterFields = globalFilterOptions?.[selectFilterType]?.map(
      (item: IGlobalSearchAttributes) => {
        return {
          fieldName: item.globalSearchLabelFields.fieldName,
          moduleType: item.globalSearchTypes.value,
        };
      },
    );
    setAgFilterFields(filterFields);

    const globalSearchDto: IGlobalSearchDto = {
      searchTerm: searchValue,
      searchType: selectFilterType,
      searchParams,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const result = await fetchGlobalFindings(globalSearchDto);
      setGlobalSearchFindingData(result);
    } catch (error) {
      console.log('error', error);
      dispatch(alertActions.error('Something went wrong while fetching global search findings'));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, globalFilterOptions, searchValue, selectFilterType]);

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    setTimeout(() => {
      inputSearchRefs.current?.focus();
    }, 0);
  };

  useEffect(() => {
    setLocalStorageValue('globalSearchParams', {
      searchValue,
      selectFilterType,
    });
    if (searchValue) inputSearchRefs?.current?.focus();
  }, [searchValue, selectFilterType]);

  const formCtrlRef = useRef<HTMLDivElement>(null);
  const searchIconRef = useRef<HTMLButtonElement>(null);
  const advancedSearchRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { isFocused, setIsFocused, handleBlur } = useOnHandleBlur({
    searchValue,
    formCtrlRef,
    searchIconRef,
    advancedSearchRef,
    popoverRef,
  });

  // By default set to src_url
  useEffect(() => {
    dispatch(setCurrentGlobalSearchSubTab(EWebModuleTabFilterFields.src_url));
    return () => {
      dispatch(setCurrentGlobalSearchSubTab(EWebModuleTabFilterFields.src_url));
    };
  }, [dispatch]);

  const currentGlobalSearchSubTab = useAppSelector(
    state => state.globalSearchReducer.currentGlobalSearchSubTab,
  );

  const isDataAvailable = useMemo(() => {
    let hasData = false;

    const currentDataToDisplay =
      currentGlobalSearchSubTab === EWebModuleTabFilterFields.src_url
        ? globalSearchFindingData
        : globalSearchDstUrlsFindingData;

    Object.keys(currentDataToDisplay).forEach(key => {
      if (
        key !== 'searchType' &&
        key !== 'count' &&
        key !== 'searchValue' &&
        currentDataToDisplay[key].length > 0
      ) {
        hasData = true;
      }
    });
    return hasData;
  }, [currentGlobalSearchSubTab, globalSearchDstUrlsFindingData, globalSearchFindingData]);

  const { fetchAdvancedFilterFindings } = useOnFetchAdvancedFilterFindings({
    searchValue,
    selectedOptions: advancedFiltersSelectedOptions,
    setSelectFilterType,
    setGlobalSearchFindingData,
    setIsLoading,
    setAdvancedFilterParams,
  });

  const handleKeyDown = (e: any) => {
    const isAdvancedFilter = selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS;
    if (
      isAdvancedFilter &&
      !_.isEmpty(advancedFiltersSelectedOptions?.[advancedSearchCategories.COLUMNS])
    ) {
      fetchAdvancedFilterFindings();
    } else if (!isAdvancedFilter) {
      fetchGlobalSearchFindings();
    }
    handleOpenPopover();
  };

  const moduleTypesAndModule = useMemo(() => {
    if (advancedFiltersSelectedOptions && advancedFilterParams) {
      return advancedFilterParams?.searchParams
        ?.map((item: any) => {
          if (!MODULE_ICON[item.moduleType]) return null;

          return {
            moduleIcon: MODULE_ICON[item.moduleType],
            moduleLabel: moduleToLabel[item.moduleType],
          };
        })
        .filter(Boolean);
    }

    if (selectFilterType === ESelectedFilterTypeOptions.TAGS) {
      return [
        appConstants.CONTENT_TYPE.WEB,
        appConstants.CONTENT_TYPE.SOCIAL,
        appConstants.CONTENT_TYPE.APP_STORE,
      ]
        .map((item: any) => {
          return {
            moduleIcon: MODULE_ICON[item],
            moduleLabel: moduleToLabel[item],
          };
        })
        .filter(Boolean);
    }

    return globalFilterOptions?.[selectFilterType]
      ?.map((item: IGlobalSearchAttributes) => {
        if (!MODULE_ICON[item.globalSearchTypes.value]) return null;
        return {
          moduleIcon: MODULE_ICON[item.globalSearchTypes.value],
          moduleLabel: item.globalSearchTypes.label,
        };
      })
      .filter(Boolean);
  }, [advancedFilterParams, advancedFiltersSelectedOptions, globalFilterOptions, selectFilterType]);

  return (
    <div
      className={`global-search-container ${isFocused ? 'focused' : ''}`}
      data-testid='global-search-container'
    >
      <Paper
        className='global-search-paper'
        component={'form'}
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          background: 'transparent',
          height: '40px',
          boxShadow: 'none',
          width: isFocused ? '664px' : '120px',
        }}
        data-testid='global-search-paper'
        id='global-search-paper'
      >
        <IconButton
          ref={searchIconRef}
          sx={{ p: '10px' }}
          aria-label='search'
          data-testid='global-search-icon'
          onClick={e => {
            if (isFocused) {
              void fetchGlobalSearchFindings();
              handleOpenPopover();
            } else {
              setIsFocused(true);
              inputSearchRefs.current?.focus();
            }
          }}
          id='global-search-icon'
          disabled={selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS}
        >
          <Search
            id='global-search-icon'
            sx={{
              fill:
                selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS
                  ? 'gray !important'
                  : 'black',
            }}
          />
        </IconButton>
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
          }}
          placeholder={isFocused ? 'Search for URL, Domain, IP etc.' : 'Search '}
          inputProps={{ 'aria-label': 'search for anything' }}
          onChange={e => setSearchValue(e.target.value)}
          value={searchValue}
          onKeyDown={e => {
            if (e.key === 'Enter' && !_.isEmpty(searchValue)) {
              handleKeyDown(e);
            }
          }}
          data-testid='global-search-input'
          className='global-search-input'
          inputRef={inputSearchRefs}
          onClick={() => {
            if (isDropdownOpen) {
              setIsDropdownOpen(false);
            }
          }}
          onFocus={e => {
            if (selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS) {
              setAnchorEl(e?.currentTarget);
            }
            setIsFocused(true);
          }}
          onBlur={handleBlur}
          id='global-search-input-base'
        />

        {!_.isEmpty(searchValue) && (
          <IconButton
            color='primary'
            sx={{ p: '10px' }}
            aria-label='directions'
            onClick={e => {
              e.preventDefault();
              setSearchValue('');
              setGlobalSearchFindingData({});
              setIsFocused(true);
              inputSearchRefs?.current?.focus();
            }}
            data-testid='global-search-clear-button'
            id='global-search-clear-button'
            disabled={selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS}
          >
            <Clear
              id='global-search-clear-button'
              sx={{
                fill:
                  selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS
                    ? 'gray !important'
                    : 'black',
              }}
            />
          </IconButton>
        )}
        {isFocused && (
          <>
            <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
            <FormControl
              sx={{
                minWidth: 100,
                border: 'unset',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              ref={formCtrlRef}
            >
              <Select
                value={selectFilterType}
                defaultValue={selectFilterType}
                onChange={handleDropdownChange}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                data-testid='global-search-select'
                open={isDropdownOpen}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={handleDropdownClose}
              >
                {dropdownOptions.map(name => (
                  <MenuItem key={name} value={name} className={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Paper>
      <Popover
        ref={popoverRef}
        id='search-bar-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={() => inputSearchRefs.current?.focus()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -46,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableScrollLock={true}
        data-testid='search-bar-popover'
        sx={{
          zIndex: 9999,
        }}
      >
        <Paper
          sx={{
            p: '4px 4px',
            display: 'flex',
            width: 662,
            background: 'transparent',
            height: 'auto',
            maxHeight: 550,
            border: 'none',
            overflow: 'scroll',
            minHeight: 100,
          }}
          data-testid='search-bar-popover-container'
        >
          {selectFilterType === ESelectedFilterTypeOptions.ADVANCED_FILTERS ? (
            <AdvancedFilters
              advancedSearchRef={advancedSearchRef}
              searchValue={searchValue}
              setSelectFilterType={setSelectFilterType}
              setGlobalSearchFindingData={setGlobalSearchFindingData}
              setIsLoading={setIsLoading}
              setAdvancedFilterParams={setAdvancedFilterParams}
              setAdvancedFiltersSelectedOptions={setAdvancedFiltersSelectedOptions}
            />
          ) : isLoading ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              data-testid='search-bar-skeleton-loader'
            >
              {moduleTypesAndModule?.map((item: any) => {
                return (
                  <div
                    key={item.moduleLabel}
                    className='d-flex flex-column align-items-start p-2 ml-2'
                  >
                    <div className='d-flex align-items-center mb-3 mt-3 '>
                      <img
                        src={item.moduleIcon}
                        alt={item.moduleLabel}
                        style={{ width: 25, height: 25 }}
                      />
                      <div style={{ marginLeft: 10, fontSize: '14px' }}>{item.moduleLabel}</div>
                    </div>
                    <Skeleton variant='rectangular' height={15} width={'75%'} animation={'wave'} />
                    <div style={{ height: 10 }}></div>
                    <Skeleton variant='rectangular' height={15} width={'50%'} animation={'wave'} />
                    <div style={{ height: 10 }}></div>
                    <Skeleton variant='rectangular' height={15} width={'65%'} animation={'wave'} />
                  </div>
                );
              })}
            </Box>
          ) : (
            <div className='global-search-findings-wrapper'>
              <>
                {selectFilterType === ESelectedFilterTypeOptions.URL && (
                  <GlobalSearchSubTab fetchRelatedUrls={fetchRelatedUrls} />
                )}
              </>
              <>
                {isDataAvailable ? (
                  <GlobalSearchFindings
                    globalSearchFindingData={
                      currentGlobalSearchSubTab === 'src_url'
                        ? globalSearchFindingData
                        : globalSearchDstUrlsFindingData
                    }
                    searchTerm={searchValue}
                    agFilterFields={agFilterFields}
                    handleClose={handleClose}
                    selectFilterType={selectFilterType}
                    fetchRelatedUrls={fetchRelatedUrls}
                    advancedFilterParams={advancedFilterParams}
                  />
                ) : (
                  <div
                    className='global-search-no-data-container'
                    data-testid='global-search-no-data-container'
                  >
                    <div className='global-search-no-data-title'>
                      {currentGlobalSearchSubTab === EWebModuleTabFilterFields.dst_url &&
                      !isDataAvailable &&
                      (globalSearchFindingData[appConstants.CONTENT_TYPE.WEB]?.length > 0 ||
                        globalSearchFindingData[
                          appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER
                        ]?.length > 0)
                        ? `No redirected URLs found.`
                        : `We couldn't find anything matching your search.`}
                    </div>
                    <div className='global-search-no-data-description'>
                      Try different keywords for better results!
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
        </Paper>
      </Popover>
    </div>
  );
}
