import React, { useEffect } from 'react';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import { PageTitle } from '../../Common/PageTitle';
import AgGridAbuseMailBoxTargetedMalicious from '../../Common/Table/ag-table/AgGridAbuseMailBoxTargetedMalicious';
import { TableContextProvider } from '../../Common/Table/table.context';
import {
  ABUSE_MAIL_BOX_MODULE_TYPE,
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
} from '../constants';
import { TARGETED_MALICIOUS_COLUMNS } from './TargetedMaliciousColDefs';
import './TargetedMalicious.scss';
import '../../Common/statusLabels.scss';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { setCurrentTableId } from '../../../reducers/insightsContainer.reducer';
import TargetedMaliciousDemo from '../demo-data-bankofbolster/TargetedMalicious.poc';

export const TargetedMalicious = () => {
  window.document.title = 'Targeted Malicious | Abuse Mailbox';

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentTableId(ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID));
  }, [dispatch]);

  const isDemoBrand = useAppSelector(
    state => state.dashboardReducer.brandInfo?.brand?.brandName === 'bankofbolster',
  );

  return (
    <AuthenticationWrapper>
      <div className='absuse-mail-box-targeted-malicious-container'>
        <PageTitle title='Targeted Malicious' className='no-padding' />
        <div className='pt-3' data-testid={ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID}>
          <TableContextProvider
            dashboardName={'Abuse Mailbox Targeted Malicious'}
            tableId={ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID}
          >
            {isDemoBrand ? (
              <TargetedMaliciousDemo />
            ) : (
              <AgGridAbuseMailBoxTargetedMalicious
                tableId={ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID}
                columns={TARGETED_MALICIOUS_COLUMNS}
                moduleType={ABUSE_MAIL_BOX_MODULE_TYPE}
                style={{
                  height: '85vh',
                }}
              />
            )}
          </TableContextProvider>
        </div>
      </div>
    </AuthenticationWrapper>
  );
};
