import React, { useMemo } from 'react';
import './LookAlikeWidget.scss';
import CardWrapper from '../common/CardWrapper';
import { Button } from 'react-bootstrap';
import { history } from '../../../../helpers';
import { PRICING_PAGE_PATH } from '../../../Pricing/constants';
import '../../typosquat.scss';
import { convertToThousand } from '../../../../constants';
import InfoLight from '../../../../assets/icons/InfoLight.svg';
import InfoDark from '../../../../assets/icons/InfoDark.svg';
import ToolTip from '../../../Common/ToolTip';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';
export interface ILookAlikeWidgetProps {
  data: any;
  isPaidUser: boolean;
}

const LookAlikeWidget = ({ data, isPaidUser }: ILookAlikeWidgetProps) => {
  const total = useMemo(() => data?.scanInsights?.activeDomains || 0, [data]);
  const [isDarkMode, selectedTheme] = useIsDarkMode();

  const tooltipSrc = useMemo(() => {
    return isDarkMode ? InfoDark : InfoLight;
  }, [selectedTheme]);

  const generateScanPercentage = (): number => {
    const { metadata, domainGenerationLimit } = data;
    if (domainGenerationLimit && metadata && metadata.totalResolvedVariants) {
      const scanPercentage =
        (domainGenerationLimit / Math.max(domainGenerationLimit, metadata.totalResolvedVariants)) *
        100;
      return scanPercentage;
    }
    return 0;
  };

  const generateGradientPercentage = (): string => {
    const scanPercentage = generateScanPercentage();
    if (scanPercentage === 0) {
      return '#808080';
    }
    return `linear-gradient(to right, #3bcb8a 0% ${scanPercentage}%, #808080 ${scanPercentage}% 100%)`;
  };

  return (
    <div className='look-alike-wrap'>
      <CardWrapper>
        <div className='container'>
          <div className='header'>
            Look-Alike Domains
            {!isPaidUser && (
              <ToolTip
                id={'info'}
                tooltip={`You're on the Freemium Version with up to 300 results. Upgrade to the Starter Plan for full results!`}
              >
                <img src={tooltipSrc} alt='info' />
              </ToolTip>
            )}
          </div>
          <div className='content'>
            <div className={`active-domain-stat ${isPaidUser && ' center'}`}>{total}</div>
            {!isPaidUser && (
              <div className='bar-wrap'>
                <div className='data-wrap'>
                  <div className='data' data-testid='data-wrap'>
                    {data.domainGenerationLimit ?? 0} Free
                    <span>
                      {' / '}
                      {convertToThousand(
                        Math.max(
                          data.metadata?.totalResolvedVariants,
                          data.domainGenerationLimit,
                        ) ?? 0,
                        true,
                      )}{' '}
                    </span>
                  </div>
                  <div
                    className='scan-percentage'
                    style={{
                      background: generateGradientPercentage(),
                    }}
                  ></div>
                </div>
                <div className='upgrade-button-container'>
                  <Button
                    className='upgrade-button'
                    variant='primary'
                    onClick={() => history.push(PRICING_PAGE_PATH)}
                  >
                    Upgrade
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className='bottom-wrap'>
            <div className='tld data-wrap'>
              <span className='label'>TLDs :</span>
              <span className='data'>
                {convertToThousand(data?.metadata?.topTldVariants || 0, true)}
              </span>
            </div>
            <div className='variants data-wrap'>
              <span className='label'>Variants :</span>
              <span className='data'>
                {convertToThousand(data?.metadata?.totalResolvedVariants || 0, true)}
              </span>
            </div>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default LookAlikeWidget;
