import React, { Fragment, useEffect, useState } from 'react';
import EmailDomain from '../../../../assets/darkwebV2/EmployeeEmailDomainDarkWeb.png';
import CustomerEmailDomain from '../../../../assets/darkwebV2/CustomerEmailDomainDarkWeb.png';
import EmailAddress from '../../../../assets/darkwebV2/EmailAddressDarkWeb.png';
import CreditCard from '../../../../assets/darkwebV2/CreditCard.svg';
import Employee from '../../../../assets/darkwebV2/EmployeeDarkWeb.png';
import Others from '../../../../assets/darkwebV2/Others.svg';
import { ENTITY_MAPPER, ENTITY_TYPE } from '../../DarkWebConstants';
import { Row, Col, Card } from 'react-bootstrap';
import { Divider } from '@mui/material';
import { Nullable } from '../../../../types/common';
import { useAppDispatch } from '../../../../helpers/hooks';
import {
  setCurrentGroupedSelectedFindings,
  setCurrentSingleSelectedFindings,
} from '../../../../reducers/darkWeb.reducer';

export const ENTITY_IMAGE_MAPPER = {
  [ENTITY_TYPE.EMAIL_DOMAIN]: EmailDomain,
  [ENTITY_TYPE.CUSTOMER_EMAIL_DOMAIN]: CustomerEmailDomain,
  [ENTITY_TYPE.EMAIL_ADDRESS]: EmailAddress,
  [ENTITY_TYPE.CREDIT_CARD]: CreditCard,
  [ENTITY_TYPE.EXECUTIVE_MONITORING]: Employee,
  [ENTITY_TYPE.OTHERS]: Others,
};

interface IProps {
  selectedEntity: ENTITY_TYPE | undefined;
  widgetDetails: any;
  handleOnWidgetDetailsClick: (entity: Nullable<ENTITY_TYPE>) => void;
}

export const DarkWebFindingsWidget = ({
  selectedEntity,
  handleOnWidgetDetailsClick,
  widgetDetails,
}: IProps) => {
  const [selectedEntityIndex, setSelectedEntityIndex] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const widgetDetailsView = (
    title: ENTITY_TYPE,
    detailsInfo: any,
    nextItem: any,
    index: number,
  ) => {
    if (selectedEntity === title && selectedEntityIndex !== index) {
      setSelectedEntityIndex(index);
    }
    return (
      <Fragment>
        <div
          className={`widget-details-item ${
            selectedEntity === title
              ? 'active'
              : `${selectedEntity === nextItem ? 'hide-border' : ''}`
          }`}
          key={title}
          onClick={() => {
            handleOnWidgetDetailsClick(title);
            dispatch(setCurrentGroupedSelectedFindings([]));
            dispatch(setCurrentSingleSelectedFindings([]));
          }}
        >
          <Row className='tile'>
            <Col className='tile-head d-flex mb-1' md={12}>
              <Col className='no-padding icon' md={2}>
                <img
                  src={ENTITY_IMAGE_MAPPER[title]}
                  className='darkWeb-findings-widget-icon'
                ></img>
              </Col>
              <Col className='no-padding mb-0 title' style={{ top: '5px' }} md={10} as={'h5'}>
                {ENTITY_MAPPER[title].label}
              </Col>
            </Col>
            <Col className='tile-body d-flex' md={12}>
              <Col className='data-set no-padding'>
                <Col className='head no-padding' as={'h4'} md={12}>
                  {Object.keys(detailsInfo.searchterms).length}
                </Col>
                <Col className='body no-padding' as={'p'} md={12}>
                  {Object.keys(detailsInfo.searchterms).length > 1
                    ? ENTITY_MAPPER[title].searchTermCountPlural
                    : ENTITY_MAPPER[title].searchTermCount}
                </Col>
              </Col>
              <Col className='data-set no-padding'>
                <Col className='head no-padding' as={'h4'} md={12}>
                  {detailsInfo.totalFindings}
                </Col>
                <Col className='body no-padding' as={'p'} style={{ color: '#666' }} md={12}>
                  {ENTITY_MAPPER[title].totalFindings}
                </Col>
              </Col>
            </Col>
          </Row>
        </div>
        {
          // Divider is not needed for the last item
          index !== Object.keys(widgetDetails).length &&
            selectedEntity !== title &&
            (selectedEntityIndex as number) - 1 !== index && (
              <Divider orientation='vertical' className='divider-line' />
            )
        }
      </Fragment>
    );
  };

  if (!widgetDetails || !selectedEntity) {
    return null;
  }

  let indexCount = 0;

  return (
    <div className={'dashboard-table-container'} key={selectedEntityIndex}>
      <div
        className={`widget-details-container ${
          selectedEntityIndex == 1 ? 'hide-left-border' : ''
        } ${selectedEntityIndex == 5 ? 'hide-right-border' : ''} d-flex`}
      >
        {Object.keys(ENTITY_IMAGE_MAPPER).map((entity, index) => {
          if (widgetDetails[entity]) {
            ++indexCount;
            return widgetDetailsView(
              entity as ENTITY_TYPE,
              widgetDetails[entity],
              Object.keys(ENTITY_IMAGE_MAPPER)[index + 1],
              indexCount,
            );
          }
        })}
      </div>
    </div>
  );
};
