import React from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { getColors, numberWithCommas, WIDGET_HEIGHT } from '../../../constants';
import { LoadingWrapper } from '../LoadingWrapper';
import { IChartProps, IDonutChartProps, TDonutChartDataProps } from './Types/chart.type';
import { renderNoDataPlaceholder } from './LineChart';
import useIsDarkMode from '../CustomHooks/useIsDarkMode';

const DonutChart = ({
  title,
  isLoading,
  data,
  className,
  placeholder,
  description,
}: IChartProps & IDonutChartProps) => {
  const series: number[] = [];
  const labels: string[] = [];
  const [, selectedTheme] = useIsDarkMode();

  _.forEach(data, item => {
    series.push(item.count);
    labels.push(item.label);
  });
  const chartColors = _.map(
    data,
    (item: TDonutChartDataProps, index: number) =>
      item.color || getColors(selectedTheme)['donutChart' + (index + 1)],
  );

  const options: ApexOptions = {
    labels,
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          value: 0.35,
          type: 'lighten',
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '90%',
        },
      },
    },
    stroke: {
      width: 0,
      colors: [getColors(selectedTheme).background],
    },
    legend: {
      labels: {
        colors: getColors(selectedTheme).legendText,
      },
    },
    tooltip: {
      theme: selectedTheme,
      custom: (tooltipData: any) => {
        const { seriesIndex, series } = tooltipData;
        return (
          `<div style="background: ${getColors(selectedTheme).background}; color:${
            getColors(selectedTheme).legendText
          }; padding:4px 8px; border:1px solid ${getColors(selectedTheme).chartBorderColor};">` +
          `${labels[seriesIndex]}: <b>${numberWithCommas(series[seriesIndex])}</b>` +
          '</div>'
        );
      },
    },
  };

  return (
    <div className={'dashboard-widget dashboard-donut-chart ' + className}>
      {description ? (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + description} className={'table-source-url-tooltip'}>
              {description}
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>{title}</div>
        </OverlayTrigger>
      ) : (
        <div className='dashboard-chart-title'>{title}</div>
      )}
      <LoadingWrapper isLoading={isLoading}>
        {series.length ? (
          <div className={'donut-chart-container'}>
            <ReactApexChart options={options} series={series} height={WIDGET_HEIGHT} type='donut' />
          </div>
        ) : placeholder ? (
          renderNoDataPlaceholder(placeholder)
        ) : null}
      </LoadingWrapper>
    </div>
  );
};

export default DonutChart;
