import React, { Fragment } from 'react';
import { appConstants, setLocalStorageValue } from '../../constants';

import { DispositionStatus } from '../Common/DispositionStatus';
import {
  mappingModuleTypeIdToTableId,
  mappingModuleTypeIdToTableRouteId,
  mappingModuleTypeToInsightsRoute,
  mappingModuleTypeToLabel,
  mappingModuleTypeToPlatformRoute,
  MAX_SEARCH_RESULTS,
  MODULE_ICON,
} from './GlobalSearchConstants';
import { history } from '../../helpers';
import useOnRedirectToModuleTeaserPage from '../Common/CustomHooks/useOnRedirectToModuleTeaserPage';

interface IGlobalSearchFindingViewProps {
  moduleData: any;
  moduleLabel: string;
  moduleType: string;
  handleFilterWithNavigation: (
    moduleType: string,
    moduleTableId: string,
    moduleRoute: string,
  ) => void;
  handlePopUpClose: () => void;
  webCounts?: { [key: string]: number };
}

export default function GlobalSearchFindingView({
  moduleData,
  moduleLabel,
  moduleType,
  handleFilterWithNavigation,
  handlePopUpClose,
  webCounts,
}: IGlobalSearchFindingViewProps) {
  const { shouldShowTeaserPage } = useOnRedirectToModuleTeaserPage();
  const redirectToInsightsPage = (finding: any, key: string) => {
    setLocalStorageValue('isNotFromTableCol', true);
    setLocalStorageValue('currentTableId', mappingModuleTypeIdToTableId[moduleType][key]);
    if (shouldShowTeaserPage(moduleType)) {
      history.push(`/${mappingModuleTypeToPlatformRoute[moduleType]}/dashboard`);
    } else {
      history.push(`${mappingModuleTypeToInsightsRoute[moduleType]}/${finding.urlSha256}`);
    }
    handlePopUpClose();
  };

  return (
    <div className='global-search-findings-container' data-testid='global-search-findings-view'>
      <div className='module-header'>
        <img src={MODULE_ICON[moduleType]} alt='web-module-logo' />
        <div className='module-label'>{moduleLabel}</div>
      </div>
      <>
        {Object.keys(moduleData).map((key: any) => {
          if (moduleData[key].length === 0) {
            return null;
          }
          return (
            <Fragment key={key}>
              <div className='finding-table-label'>{mappingModuleTypeToLabel[key]}</div>
              <div className='findings-detail'>
                <>
                  {moduleData[key].slice(0, 5).map((finding: any) => {
                    return (
                      <div className='finding-wrapper' key={finding.urlSha256}>
                        <div
                          onClick={() => redirectToInsightsPage(finding, key)}
                          className='finding-title'
                          data-testid={`insights-${finding.urlSha256}`}
                        >
                          {finding.srcUrl}
                        </div>
                        {moduleType === appConstants.CONTENT_TYPE.WEB && (
                          <DispositionStatus
                            key={finding.srcUrl}
                            status={finding.currentDisposition}
                            displayedStyle={'pill'}
                          />
                        )}
                      </div>
                    );
                  })}
                  {(webCounts?.[key] ?? moduleData[key].length) > 5 && (
                    <div className='findings-more-description'>
                      <div className='description'>
                        {webCounts && webCounts[key] > MAX_SEARCH_RESULTS
                          ? `Showing 5 of 10000+ results`
                          : `Showing 5 of ${webCounts?.[key] ?? moduleData[key].length} results`}
                      </div>
                      <div
                        onClick={() =>
                          handleFilterWithNavigation(
                            moduleType,
                            mappingModuleTypeIdToTableId[moduleType][key],
                            mappingModuleTypeIdToTableRouteId[key],
                          )
                        }
                        className='see-all'
                        data-testid='see-all'
                        id={`see-all-${key}`}
                      >
                        See All
                      </div>
                    </div>
                  )}
                </>
              </div>
            </Fragment>
          );
        })}
      </>
    </div>
  );
}
