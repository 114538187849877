import React from 'react';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

export const GetIconImage = ({
  darkImgURL,
  lightImgURL,
  activeDarkImgURL = '',
  activeLightImgURL = '',
  isActive = false,
  options = {},
}: {
  darkImgURL: string;
  lightImgURL: string;
  activeDarkImgURL?: string;
  activeLightImgURL?: string;
  isActive?: boolean;
  options?: React.ImgHTMLAttributes<HTMLImageElement>;
}) => {
  const [isDarkMode] = useIsDarkMode();
  if (isActive) {
    if (isDarkMode) {
      return <img src={activeDarkImgURL} {...options} />;
    }
    return <img src={activeLightImgURL} {...options} />;
  } else {
    if (isDarkMode) {
      return <img src={darkImgURL} {...options} />;
    }
    return <img src={lightImgURL} {...options} />;
  }
};
