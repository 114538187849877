import React from 'react';
import { Grid } from '@mui/material';
import { LabelAndValue } from '../../Common/LabelAndValue';
import './ThreatTypeInsights.scss';
import { MuiCardWrapper } from '../../Common/MuiCardWrapper';
import { IThreatTypeDetails } from '../constants';

interface PhoneNumberAnalysisProps {
  phone_numberDetails: IThreatTypeDetails;
}

export const PhoneNumberAnalysis: React.FC<PhoneNumberAnalysisProps> = ({
  phone_numberDetails,
}) => {
  return (
    <div data-testid='attachment-analysis'>
      <MuiCardWrapper cardTitle='Threat Overview'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={3}>
            <LabelAndValue
              label={'Phone Number'}
              value={phone_numberDetails.sourceUrl || phone_numberDetails.name}
              direction={'column'}
              longTextLineNumberLimit={1}
              copyButton
            />
          </Grid>
          <Grid item xs={12} sm={2} md={3}>
            <LabelAndValue
              label={'Carrier Information'}
              value={phone_numberDetails.carrier}
              direction={'column'}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={3}>
            <LabelAndValue
              label={'Location'}
              direction={'column'}
              value={phone_numberDetails.location}
            />
          </Grid>
        </Grid>
      </MuiCardWrapper>
    </div>
  );
};
