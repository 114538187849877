import React, { MouseEventHandler } from 'react';
import _ from 'lodash';
import './UploadFiles.scss';
import { Button } from '@mui/material';
import { UploadFileIcon } from '../../assets/SVGIcons';

export interface UploadFilesButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  label?: string;
}

export default function UploadFilesButton({
  onClick,
  label = 'Upload Files',
}: UploadFilesButtonProps) {
  return (
    <div onClick={onClick}>
      <Button variant='outlined' className='upload-file-button'>
        <UploadFileIcon className='upload-file-icon' />
        <span>{label}</span>
      </Button>
    </div>
  );
}
