import React from 'react';
import { allEmailSubmissionsNoDataMainText, allEmailSubmissionsNoDataSubText } from './constants';
import allEmailSubmissionsNoDataFound from '../../../assets/abuseMailBox/allEmailSubmissionsNoDataFound.png';
import './AllEmailSubmissionsNoDataView.scss';

export const AllEmailSubmissionsNoDataView = () => {
  return (
    <div className='all-email-submissions-no-data-container'>
      <div>
        <img
          alt='all-email-submissions-no-data-found'
          src={allEmailSubmissionsNoDataFound}
          width='85px'
        />
      </div>
      <div className='all-email-submissions-no-data-text-container'>
        <div className='all-email-submissions-no-data-main-text'>
          {allEmailSubmissionsNoDataMainText}
        </div>
        <div className='all-email-submissions-no-data-sub-text'>
          {allEmailSubmissionsNoDataSubText}
        </div>
      </div>
    </div>
  );
};
