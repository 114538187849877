import React, { useRef, useState, useCallback, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import UgcService from '../../../../services/ugc.service';
import {
  EFindingStatus,
  IFindingsTableItemProps,
  IFindingsTableProps,
  ugcType,
  ugcTypeLabel,
} from '../../Types/ugc.types';
import { AuthenticationWrapper } from '../../../AuthenticationWrapper';
import { IFilter, TExportTable } from '../../../Common/Table/constant';
import { TableContextProvider } from '../../../Common/Table/table.context';
import { TableApi } from '../../../Common/Table/table.api';
import AddFindingForTakedownModal from '../Common/AddFindingForTakedownModal';
import DetectionBanner from '../Common/DetectionBanner';
import { ITableDataColumn } from '../../../Common/Table/table.data';
import DetectionsActionColRender from './TableColDef/DetectionsActionColRender';
import TakedownInProgressActionColRender from './TableColDef/TakedownInProgressActionColRender';
import { AppState } from '../../../../helpers';
import {
  appConstants,
  capitalize,
  DISPLAYED_NULL,
  featureIsAvailable,
  getLocalStorageValue,
  getLocalTimeFromUtcTime,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
  IFindingsTableUrls,
  isAdminUser,
  isBrandReadOnlyUser,
  isInternalUser,
  setLocalStorageValue,
} from '../../../../constants';
import { alertActions, dashboardActions } from '../../../../actions';
import { IDropdownOption } from '../../../Common/Dropdown';
import SocActionColRender from './TableColDef/SocActionColRender';
import { getCSVFile } from '../../../Assets_v2/util';
import { TableTile } from '../../../Common/Table/table.tile';
import MarkAsSafelistModal from '../Common/MarkAsSafelistModal';
import { Dropdown } from '../../../Common/Dropdown';
import UploadFindingFromFileModal from './UploadFindingFromFileModax';
import SocNotesColRender from './TableColDef/SocNotesColRender';
import UploadFailedModal from './UploadFailedModal';
import {
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
} from '../../../SocialMedia/constants';
import {
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_FINDING_REVIEW_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
} from '../../../Marketplace/constants';
import {
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
} from '../../../AppStore/constants';
import DashboardService from '../../../../services/dashboard.service';
import {
  TAGS_AUTHOR_COLUMN,
  TAGS_COLUMN,
} from '../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import Tags, { ETagsTypes, ITags } from '../../../Common/Tags/Tags';
import { socReview, ugcTypeAndInsightRoutes } from '../../constants';
import BulkActionsOverhead from '../Common/BulkActionsOverhead';
import RequestTakedownModal from '../Common/RequestTakedownModal';
import AppStoreTakedownModal from '../../../AppStore/Components/Findings/common/AppStoreTakedownModal';
import { transformUgcFilterToApiParams } from '../../../Common/Table/ag-table/ag-filter-to-api-params';
import { fetchUGCData } from '../../../Common/Table/ag-table/ag-requests';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import DeleteCustomTagsModal from '../../../Common/Tags/DeleteCustomTagsModal';
import EditCustomTagsModal from '../../../Common/Tags/EditCustomTagsModal';
import {
  setShouldTriggerAgGrigRefresh,
  setSearchTermsOptionsToDisplay,
  setTagsFromBulkAction,
  setIsGroupRelatedDataReady,
  setTableIdField,
} from '../../../../reducers/table.reducer';
import { FINDINGS_TABLE_IDS, MALICIOUS_TABLE_IDS } from '../../../MonitorAndTakedown/constants';
import { setCurrentTableId } from '../../../../reducers/insightsContainer.reducer';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

export enum EModalType {
  None = 'none',
  AddForm = 'Add New Detection',
  Upload = 'Upload Multiple Detections',
}

export enum EBulkActionModalType {
  None = 'none',
  Initiate_Takedown = 'Initiate Takedown',
  Ignore = 'Ignore',
  Add_Tags = 'Add Tags',
  Dispute_All = 'Dispute All',
  Request_Takedown = 'Request Takedown',
}

const ugcService = new UgcService();
const dashboardService = new DashboardService();

const FindingsTableComponent = (props: IFindingsTableProps) => {
  const dispatch = useAppDispatch();
  const [bulkActionModal, setBulkActionModal] = useState<EBulkActionModalType>(
    EBulkActionModalType.None,
  );
  const [displayedModalType, setDisplayedModalType] = useState<EModalType>(EModalType.None);
  const [selectedItems, setSelectedItems] = useState<IFindingsTableItemProps[]>([]);
  const [isAddingNewDetections, setIsAddingNewDetections] = useState(false);
  const [userApiKey, setUserApiKey] = useState('');
  const [showUrlFailedModal, setShowUrlFailedModal] = useState(false);
  const [failedUrls, setFailedUrls] = useState<string[]>([]);
  const [refreshOnTag, setRefreshOnTag] = useState(false);
  const [displayLayout, setDisplayLayout] = useState(true);
  const [isDarkMode] = useIsDarkMode();
  const closeDetectionsModal = () => {
    setDisplayedModalType(EModalType.None);
  };

  // Getting from the redux store
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);
  const user = useAppSelector(state => state.dashboardReducer.user);
  const findingsTableUrls = useAppSelector(state => state.dashboardReducer.findingsTableUrls);
  const usersList = useAppSelector(state => state.dashboardReducer.usersList);

  const ugcPlatforms = useAppSelector((state: AppState) => {
    const contentType = props.type as keyof typeof ugcType;
    return state.appReducer.contents[contentType]?.platforms ?? [];
  });

  const ugcOrigins = useAppSelector((state: AppState) => {
    const contentType = props.type as keyof typeof ugcType;
    return state.appReducer.contents[contentType]?.origins ?? [];
  });

  const ugcCategories = useAppSelector((state: AppState) => {
    const contentType = props.type as keyof typeof ugcType;
    return state.appReducer.contents[contentType]?.categories ?? [];
  });

  const [tagsAnchorEl, setTagsAnchorEl] = useState<null | HTMLElement>(null);
  const [tagsAddedPerSha, setTagsAddedPerSha] = useState<any>({});

  const [apiTs, setApiTs] = useState<number>(moment().valueOf());
  const newFindings = useRef<string[]>([]);
  const displayLayoutRef = useRef(true);
  const { id, columns, enableCheckbox, status, type, tableName, enableSocInterface } = props;

  const internalDefaultColumnIds: any[] = isAdminUser(user)
    ? [...props.defaultColumnIds, 'socActions']
    : [...props.defaultColumnIds];

  const refreshTable = useCallback(() => {
    setApiTs(moment().valueOf());
  }, []);
  useEffect(() => {
    dashboardService
      .getScanCount()
      .then(res => {
        setUserApiKey(res['api_key']);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const bulkActionBtnElement = document.getElementById('dropdown-basic-button');
    if (bulkActionBtnElement) {
      setTagsAnchorEl(bulkActionBtnElement);
      dispatch(setIsGroupRelatedDataReady(true));
    }
  }, [tagsAnchorEl, findingsTableUrls]);

  useEffect(() => {
    displayLayoutRef.current = displayLayout;
  }, [displayLayout]);

  const isOldTable = !user || !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

  dispatch(setTableIdField(id));

  const massageData = (rawData: any[]): IFindingsTableItemProps[] => {
    const urlParams = new URLSearchParams(window.location.search);
    const targetKey = urlParams.get('targetKey') || 'url';
    const targetValues = urlParams.get('targetValues');
    const targetValuesArr = targetValues?.split(',') || [];

    return rawData.map((finding: any): IFindingsTableItemProps => {
      let isNewAdded = newFindings.current.indexOf(finding.url) !== -1;
      if (!isNewAdded && targetKey && targetValuesArr) {
        isNewAdded = targetValuesArr.indexOf(finding[targetKey]) !== -1;
      }

      return {
        ...finding,
        platform_label: finding.platformLabel,
        origin_label: finding.originLabel,
        category_labels: finding.categoryLabels?.join(', ') || '',
        search_term_labels: finding.searchTermLabels?.join(', ') || DISPLAYED_NULL,
        upload_count: finding.uploadCount,
        external_links: finding.externalLinks,
        displayFirstSeenTime: getLocalTimeFromUtcTime(
          type !== ugcType.App_Store ? finding.createdTs : finding.firstSeenTs,
        ),
        displayTakedownTime: getLocalTimeFromUtcTime(finding.takedownTs),
        is_logo_detection:
          finding.isLogoDetection === null ? '--' : capitalize(`${finding.isLogoDetection}`),

        logos: finding.imageUrls,
        logosLength: finding.imageUrls?.length || 0,
        isNewAdded,
        'metadata->>title': finding?.metadata?.title || '',
        'metadata.activeSince': finding?.metadata?.activeSince,
        brand_logo_detected: finding?.brandLogoDetected ? 'True' : 'False',
        siteTitle: finding?.siteTitle,
        countryCode: finding?.countryCode,
        srcUrl: finding?.srcUrl,
        app_last_updated: finding?.app_last_updated,
        scanSource: capitalize(finding?.scanSource),
        domain_label: finding.domainLabel,
        displayLastScannedTime: getLocalTimeFromUtcTime(finding.createdTs),
        url_sha256: finding.sha256,
        tags_label: finding.tags ? finding?.tags.map((tag: any) => tag.label).join(',') : '--',
        platform_id: finding.platformLabel,
        search_term: finding.searchTerm || '',
      };
    });
  };

  const exportApi = async (
    query: any,
    filters: IFilter[],
    sort = undefined,
    type: TExportTable,
    columns: any[],
  ) => {
    const exportCSVTitle = `Bolster ${ugcTypeLabel[props.type as keyof typeof ugcTypeLabel]} ${
      props.title
    } ${moment().format('YYYYMMDDhhmmss')}`;

    const agGridFilter = getLocalStorageValue(['tableFilterModel', id]);
    const apiParams = transformUgcFilterToApiParams(
      agGridFilter,
      type === appConstants.CONTENT_TYPE.APP_STORE,
    );
    const statusFilter = `status=in.(${status ? status.split('|').join(',') : ''})`;
    apiParams.filter = `${statusFilter}${
      apiParams.filter.length >= 1 ? '&' + apiParams.filter : ''
    }`;

    const rawResponse = await fetchUGCData(
      {
        ...apiParams,
        order:
          props.type !== appConstants.CONTENT_TYPE.APP_STORE
            ? 'created_ts.desc.nullslast'
            : 'first_seen_ts.desc.nullslast',
        includeImages: false,
      },
      props.type,
    );

    if (rawResponse.findings.length) {
      getCSVFile(massageData(rawResponse.findings), columns, exportCSVTitle);
    }
  };
  const fetchApi = (query: any, filters: IFilter[], sort: any) => {
    return ugcService.getFindings(status, query, filters, sort, type).then((res: any) => {
      let data: IFindingsTableItemProps[] = [];
      let total = 0;
      if (res) {
        total = _.get(res, ['metadata', 'total'], res.findings?.length);
        data = massageData(res?.findings);
        setRefreshOnTag(false);
      }
      return { data, total };
    });
  };
  const onTagsChangeHandler = (isAdded: boolean, addedSha?: string, newTags?: any[]) => {
    if (isAdded) {
      setRefreshOnTag(isAdded);
    }
    if (newTags?.length) {
      setTagsAddedPerSha(newTags);
    }
  };
  const modifyColumns = async (columns: ITableDataColumn[]): Promise<any> => {
    const tagsColumn = _.find(columns, ['id', 'tags']);
    const tagsAuthorColumn = _.find(columns, ['id', 'Tags Author']);
    const filteredTags = providedTags.filter((tag: ITags) => {
      if (tag.type !== ETagsTypes.BOLSTER_RESTRICTED) {
        return true;
      }
    });

    //TODO support tags as per the moodule
    const restrictedTagSM =
      type === appConstants.CONTENT_TYPE.SOCIAL
        ? providedTags.filter((tag: any) => tag.label === 'Marked as Scam')
        : [];

    const tagsDropdown = getProvidedTagLabelValue(
      [...filteredTags, ...restrictedTagSM],
      isDarkMode,
    );

    if (
      !(
        window.location.pathname.includes(socReview) &&
        tagsColumn &&
        tagsDropdown.length &&
        tagsAuthorColumn
      )
    ) {
      columns.push(
        {
          ...TAGS_COLUMN,
          filterOptions: tagsDropdown,
          render: (rowData: any) => {
            return (
              <Tags
                leftAligned={displayLayoutRef.current}
                rowData={rowData}
                onTagsChangeHandler={onTagsChangeHandler}
                type={type}
              />
            );
          },
        },
        {
          ...TAGS_AUTHOR_COLUMN,
          filterOptions: getUsersListLabelValue(usersList),
        },
      );
    }
    switch (id) {
      case SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID:
      case MARKET_PLACE_FINDING_DETECTION_TABLE_ID:
      case APP_STORE_FINDING_DETECTION_TABLE_ID: {
        const actionNeededColumn = _.find(columns, ['id', 'actions']);
        if (actionNeededColumn) {
          actionNeededColumn.render = (data: any) => {
            return (
              <DetectionsActionColRender
                data={data}
                onSuccess={onDetectionActionSuccess}
                type={type}
                user={user}
                tableId={id}
              />
            );
          };
        }
        break;
      }
      case SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID:
      case MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID:
      case APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID: {
        const actionNeededColumn = _.find(columns, ['id', 'takedownInProgress']);
        if (actionNeededColumn) {
          actionNeededColumn.render = (data: any) => {
            return (
              <TakedownInProgressActionColRender
                data={data}
                onSuccess={onDetectionActionSuccess}
                type={type}
              />
            );
          };
        }
        break;
      }
      default:
        break;
    }

    if (isAdminUser(user)) {
      columns.push(
        {
          id: 'socActions',
          accessor: '',
          header: 'SOC Actions',
          hiddenInExport: true,
          filterDisabled: true,
          sortDisabled: true,
          columnClassName: 'action-column',
          render: (data: any) => {
            return (
              <SocActionColRender
                data={data}
                onActionSuccess={onDetectionActionSuccess}
                type={type}
              />
            );
          },
        },
        {
          id: 'socNotes',
          accessor: '',
          header: 'SOC Notes',
          hiddenInExport: true,
          filterDisabled: true,
          sortDisabled: true,
          columnClassName: 'action-column',
          render: (data: any) => {
            return (
              <Fragment key={data.urlSha256}>
                <SocNotesColRender finding={data} addSocNotes={addSocNotes} />
              </Fragment>
            );
          },
        },
      );
    }
    const addSocNotes = (notes: any, sha256: string) => {
      return ugcService
        .addSOCFindingsNotes(notes, sha256, type)
        .then(() => {
          refreshTable();
        })
        .catch(error => {
          console.log(error, 'error');
        });
    };

    const platformColumn = _.find(columns, ['id', 'platform']);
    if (platformColumn) {
      if (type !== ugcType.App_Store) {
        platformColumn.render = (data: any) => {
          const platform = _.find(ugcPlatforms, ['id', data.platformId]);
          return (
            <img
              className='platform-column-logo'
              src={platform?.logoUrl}
              key={data.platform_label}
              alt={data.platform_label}
            />
          );
        };
      }

      //TODO : support category filters for app store
      // App store platform column filter option with a Pategory header
      //SM and MP platform column filter option with a Platform header
      platformColumn.filterOptions = _.map(ugcPlatforms, p => ({
        label: p.label,
        value: type === appConstants.CONTENT_TYPE.APP_STORE ? p.label : p.value,
      }));
    }

    const originColumn = _.find(columns, ['id', 'origin']);
    if (originColumn) {
      originColumn.filterOptions = _.map(ugcOrigins, p => ({
        label: p.label,
        value: p.label,
      }));
    }

    const categoryColumn = _.find(columns, ['id', 'category']);
    if (categoryColumn) {
      categoryColumn.filterOptions = ugcCategories;
    }

    const searchTermsColumn = _.find(columns, ['id', 'searchTerm']);
    if (searchTermsColumn) {
      searchTermsColumn.filterOptions = await ugcService.getSearches(type).then((res: any) => {
        return _.chain(res)
          .map((item): IDropdownOption => {
            const { searchTerm, id } = item;
            return {
              label: searchTerm,
              value: id,
            };
          })
          .uniqBy('label')
          .value();
      });
    }

    dispatch(setSearchTermsOptionsToDisplay(searchTermsColumn?.filterOptions || []));
    return columns;
  };

  const onSubmitAddDetection = (data: any) => {
    return ugcService
      .addAndTakedownFinding(data, type)
      .then((res: any) => {
        dispatch(
          alertActions.success(`Congratulations! Your detection has successfully submitted.`),
        );
        newFindings.current = [data.get('url')];
        refreshTable();
        dispatch(setShouldTriggerAgGrigRefresh(true));
        return;
      })
      .catch(err => {
        dispatch(alertActions.error(err));
      })
      .finally(() => {
        closeDetectionsModal();
      });
  };

  const onSubmitUploadMultipleDetections = (event: any) => {
    const data = new FormData();
    let sentStatus = status;
    if (status === EFindingStatus.IN_PROGRESS_OR_PAUSED) {
      sentStatus = EFindingStatus.IN_PROGRESS;
    } else if (status === EFindingStatus.PENDING_OR_UNDER_REVIEW) {
      sentStatus = EFindingStatus.PENDING;
    }
    data.append('uploads', event.target.files[0]);
    data.append('status', sentStatus);
    data.append('insightsLink', `${window.location.host}${ugcTypeAndInsightRoutes[type]}`);
    setIsAddingNewDetections(true);
    return ugcService
      .uploadMultipleFindings(data, type)
      .then((res: any) => {
        const { count, failedUrls } = res;
        if (count) {
          dispatch(alertActions.success(`${count} detections have been added`));
        }
        if (failedUrls && failedUrls.length) {
          setShowUrlFailedModal(true);
          setFailedUrls(failedUrls);
        }
      })
      .catch(err => {
        dispatch(alertActions.error(err));
      })
      .finally(() => {
        refreshTable();
        dispatch(setShouldTriggerAgGrigRefresh(true));
        setIsAddingNewDetections(false);
        closeDetectionsModal();
      });
  };

  const onSubmitBulkAction = (data: any) => {
    // Do nothing for now unless we have other requests.
    if (featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)) {
      dispatch(dashboardActions.setFindingsTableUrls(id as FINDINGS_TABLE_IDS, [], type));
      onCloseHandler();
      setBulkActionModal(EBulkActionModalType.None);
    }
  };

  const onDetectionActionSuccess = useCallback((message?: string, data?: any) => {
    if (message) {
      dispatch(alertActions.success(message));
    }
    if (data) {
      if (data.length > 0) {
        newFindings.current = _.map(data, item => item.url);
      } else {
        newFindings.current = [data.url];
      }
    }
    if (featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)) {
      dispatch(dashboardActions.setFindingsTableUrls(id as FINDINGS_TABLE_IDS, [], type));
    } else {
      setSelectedItems([]);
    }
    refreshTable();
    dispatch(setShouldTriggerAgGrigRefresh(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemCheck = (checked: boolean, selectedItems: any) => {
    dispatch(dashboardActions.setFindingsTableUrls(id as FINDINGS_TABLE_IDS, selectedItems, type));
    if (checked) {
      const checkedItems = _.uniq([...findingsTableUrls[type][id], selectedItems]);
      dispatch(dashboardActions.setFindingsTableUrls(id as FINDINGS_TABLE_IDS, checkedItems, type));
    } else {
      const uncheckedItems = findingsTableUrls[type][id]?.filter(
        (url: { url_sha256: string }) => url.url_sha256 !== selectedItems.url_sha256,
      );
      dispatch(
        dashboardActions.setFindingsTableUrls(id as FINDINGS_TABLE_IDS, uncheckedItems, type),
      );
    }
  };

  const onCloseHandler = () => {
    dispatch(dashboardActions.setFindingsTableUrls(id as FINDINGS_TABLE_IDS, [], type));
    setLocalStorageValue(['savedSelectionState', id || ''], []);
    dispatch(dashboardActions.setSelectedWebUrls(id as MALICIOUS_TABLE_IDS, []));
  };

  const onItemCheckPrev = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  const customTools = [];

  const bulkActionOptions = [];
  if (
    id === SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID ||
    id === APP_STORE_FINDING_DETECTION_TABLE_ID
  ) {
    bulkActionOptions.push(
      {
        label: EBulkActionModalType.Ignore,
        value: EBulkActionModalType.Ignore,
      },
      {
        label: EBulkActionModalType.Initiate_Takedown,
        value: EBulkActionModalType.Initiate_Takedown,
      },
    );
  }

  if (type === appConstants.CONTENT_TYPE.APP_STORE) {
    bulkActionOptions.push({
      label: EBulkActionModalType.Add_Tags,
      value: EBulkActionModalType.Add_Tags,
    });
  }

  if (
    (id === SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID ||
      type === appConstants.CONTENT_TYPE.APP_STORE) &&
    !isBrandReadOnlyUser(user)
  ) {
    customTools.push(
      <Dropdown
        boxStyle
        disabled={findingsTableUrls[type]?.[id]?.length === 0 && selectedItems.length === 0}
        variant='outlined'
        fixedPlaceholder={'Bulk Actions'}
        key={'bulk-action'}
        options={bulkActionOptions}
        onChange={selection => {
          setBulkActionModal(selection.value);
        }}
      />,
    );
  }

  const detectionOptions = [
    { label: EModalType.AddForm, value: EModalType.AddForm },
    {
      label: EModalType.Upload,
      value: EModalType.Upload,
    },
  ];

  if (
    (id === SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID ||
      id === MARKET_PLACE_FINDING_REVIEW_TABLE_ID ||
      id === SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID ||
      id === APP_STORE_FINDING_DETECTION_TABLE_ID ||
      id === MARKET_PLACE_FINDING_DETECTION_TABLE_ID ||
      id === SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID ||
      id === APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID ||
      id === MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID) &&
    !isBrandReadOnlyUser(user)
  ) {
    customTools.push(
      <Dropdown
        boxStyle
        variant='outlined'
        fixedPlaceholder={'Add Detections'}
        key={'Add Detection Dropdown'}
        options={detectionOptions}
        onChange={selection => {
          setDisplayedModalType(selection.value);
        }}
      />,
    );
  }
  const disableDatePicker =
    status === EFindingStatus.IN_PROGRESS_OR_PAUSED &&
    (type === ugcType.Social || type === ugcType.Marketplace);

  const findingsDetection = useAppSelector(state => state.dashboardReducer.findingsTableUrls)[
    type
  ]?.[id];

  useEffect(() => {
    if (findingsDetection) {
      setSelectedItems(findingsDetection);
    }
  }, [findingsDetection]);

  useEffect(() => {
    dispatch(setCurrentTableId(id));
    return () => {
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  return (
    <AuthenticationWrapper>
      <UploadFailedModal
        showModal={showUrlFailedModal}
        setShowModal={() => {
          setShowUrlFailedModal(false);
        }}
        urlsList={failedUrls}
      />
      <div className={'table-page dashboard-page page-content'}>
        {enableSocInterface && (
          <DetectionBanner
            selectedFindings={
              featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
                ? findingsTableUrls[type][id]
                : selectedItems
            }
            addScanFindingSuccess={onDetectionActionSuccess}
            deleteFindingsSuccess={onDetectionActionSuccess}
            takedownFindingsSuccess={onDetectionActionSuccess}
            updateStatusFindingsSuccess={onDetectionActionSuccess}
            approveFindingsSuccess={onDetectionActionSuccess}
            tableId={id}
            type={type}
          />
        )}
        {(featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) ||
          isInternalUser(user)) && (
          <BulkActionsOverhead
            findingsTableUrls={findingsTableUrls as IFindingsTableUrls}
            type={type}
            tableId={id}
          />
        )}
        <Card className='table-container'>
          <TableContextProvider
            columns={columns}
            dashboardName={tableName}
            tableId={id}
            modifyColumns={modifyColumns}
          >
            <TableApi
              {...props}
              enableCheckbox={enableCheckbox || isAdminUser(user)}
              indexBy={'url'}
              onItemCheck={
                featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) ||
                isInternalUser(user)
                  ? (checkbox: boolean, data: IFindingsTableItemProps) => {
                      onItemCheck(checkbox, data);
                    }
                  : onItemCheckPrev
              }
              selectedItems={
                featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) ||
                isInternalUser(user)
                  ? findingsTableUrls[type][id]
                  : selectedItems
              }
              apiTs={apiTs}
              tableIndex={'findings'}
              fetchApi={fetchApi}
              exportApi={exportApi}
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              tileComp={TableTile}
              tileTopThreeProps={['url', 'searchTerm', 'category']}
              customTools={customTools}
              defaultColumnIds={internalDefaultColumnIds}
              disableDatePicker={disableDatePicker}
              refreshOnTag={refreshOnTag}
              tagsAddedPerSha={tagsAddedPerSha}
              refreshOnColChange={true}
              handleDisplayLayout={(layout: string) => {
                setDisplayLayout(layout === 'table');
              }}
              ugcStatus={status}
            />
          </TableContextProvider>
        </Card>
      </div>
      <AddFindingForTakedownModal
        show={displayedModalType === EModalType.AddForm}
        onCloseModal={closeDetectionsModal}
        onSubmitModal={onSubmitAddDetection}
        type={type}
        status={status}
      />
      <UploadFindingFromFileModal
        show={displayedModalType === EModalType.Upload}
        onHide={closeDetectionsModal}
        onSubmit={onSubmitUploadMultipleDetections}
        isAddingNewDetections={isAddingNewDetections}
        type={type}
      />
      {EBulkActionModalType.Ignore === bulkActionModal && (
        <MarkAsSafelistModal
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][id]
              : selectedItems
          }
          show={EBulkActionModalType.Ignore === bulkActionModal}
          onCloseModal={() => setBulkActionModal(EBulkActionModalType.None)}
          onModalSubmit={onSubmitBulkAction}
          type={type}
        />
      )}
      {EBulkActionModalType.Initiate_Takedown === bulkActionModal && type !== ugcType.App_Store && (
        <RequestTakedownModal
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][id]
              : selectedItems
          }
          show={EBulkActionModalType.Initiate_Takedown === bulkActionModal}
          onCloseModal={() => setBulkActionModal(EBulkActionModalType.None)}
          onModalSubmit={onSubmitBulkAction}
          type={type}
        />
      )}
      {type === ugcType.App_Store && EBulkActionModalType.Initiate_Takedown === bulkActionModal && (
        <AppStoreTakedownModal
          show={EBulkActionModalType.Initiate_Takedown === bulkActionModal}
          onCloseModal={() => setBulkActionModal(EBulkActionModalType.None)}
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][id]
              : selectedItems
          }
          type={type}
          onModalSubmit={onSubmitBulkAction}
        />
      )}
      {EBulkActionModalType.Add_Tags === bulkActionModal && (
        <Tags
          fromAgGrid
          agAnchorEl={tagsAnchorEl}
          rowData={findingsTableUrls[type][id]}
          showTagsOverlay={true}
          onTagsChangeHandler={onTagsChangeHandler}
          type={type}
          bulkOption
          onClose={() => {
            setBulkActionModal(EBulkActionModalType.None);
          }}
          getUpdatedTags={async (updatedTagsRes: any) => {
            const { tagIdsToAdd, tagIdsToRemove, urlShas256 } = updatedTagsRes;
            dispatch(setTagsFromBulkAction(updatedTagsRes));
          }}
        />
      )}

      {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
      {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
    </AuthenticationWrapper>
  );
};

export default FindingsTableComponent;
