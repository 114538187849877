import React, { useState } from 'react';
import { DEFAULT_DATE_RANGE } from '../../constants';
import moment from 'moment';
import { useAppDispatch } from '../../helpers/hooks';
import { IOption } from '../Common/DropdownMultiSelect';
import { shouldRefetchOldTable } from '../../reducers/reports.reducer';
import { getFirstAvailableModule } from '../../helpers/get-available-modules';
import { selectReportValueMap } from './reports-utils';
export function useOnReportsGeneration() {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState(moment().subtract(DEFAULT_DATE_RANGE, 'day'));
  const [endDate, setEndDate] = useState(moment());
  const [prevStartDate, setPrevStartDate] = useState(moment().subtract(DEFAULT_DATE_RANGE, 'day'));
  const [prevEndDate, setPrevEndDate] = useState(moment().subtract(DEFAULT_DATE_RANGE * 2, 'day'));

  const firstModuleName = getFirstAvailableModule();
  const [module, setModule] = useState<IOption[]>([
    { label: firstModuleName, value: selectReportValueMap[firstModuleName] },
  ]);
  const [selectedOuId, setSelectedOuId] = useState<IOption>({ label: '', value: '' });

  //TODO: Refactor this function to make it more readable
  const onDateRangeChange = (startDate: moment.Moment, endDate: moment.Moment) => {
    const difference = endDate.diff(startDate, 'days');
    let currentPrevStartDate = moment();
    let currentPrevEndDate = moment();
    if (
      startDate.format('DD-MM-YYYY') === moment().startOf('year').format('DD-MM-YYYY') &&
      endDate.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
    ) {
      //current year
      currentPrevStartDate = moment().subtract(1, 'year').startOf('year');
      currentPrevEndDate = moment().subtract(1, 'year').endOf('year');
    } else if (
      startDate.format('DD-MM-YYYY') ===
        moment().startOf('year').subtract(1, 'years').format('DD-MM-YYYY') &&
      endDate.format('DD-MM-YYYY') ===
        moment().subtract(1, 'years').endOf('year').format('DD-MM-YYYY')
    ) {
      //previous year
      currentPrevStartDate = moment().subtract(2, 'year').startOf('year');
      currentPrevEndDate = moment().subtract(2, 'year').endOf('year');
    } else if (
      startDate.format('DD-MM-YYYY') ===
        moment().startOf('year').subtract(2, 'years').format('DD-MM-YYYY') &&
      endDate.format('DD-MM-YYYY') ===
        moment().subtract(2, 'years').endOf('year').format('DD-MM-YYYY')
    ) {
      //previous 2 year
      currentPrevStartDate = moment().subtract(3, 'year').startOf('year');
      currentPrevEndDate = moment().subtract(3, 'year').endOf('year');
    } else {
      currentPrevStartDate = moment(startDate).subtract(difference, 'days');
      currentPrevEndDate = moment(startDate);
    }

    setStartDate(startDate);
    setEndDate(endDate);
    setPrevStartDate(currentPrevStartDate);
    setPrevEndDate(currentPrevEndDate);
  };

  const resetGeneration = () => {
    setStartDate(moment().subtract(DEFAULT_DATE_RANGE, 'day'));
    setEndDate(moment());
    setModule([{ label: 'Web', value: 'web' }]);
    dispatch(shouldRefetchOldTable(moment().valueOf()));
  };

  return {
    onDateRangeChange,
    resetGeneration,
    setSelectedOuId,
    startDate,
    endDate,
    module,
    setModule,
    selectedOuId,
    prevEndDate,
    prevStartDate,
  };
}
