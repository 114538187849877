import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import {
  getColors,
  IColors,
  numberWithCommas,
  SMALLER_WIDGET_HEIGHT,
  WIDGET_HEIGHT,
} from '../../../constants';
import resetIcon from '../../../assets/icons/Reset.svg';
import { LoadingWrapper } from '../LoadingWrapper';
import { LabelAndValue } from '../LabelAndValue';
import { IChartPlaceholderProps, IChartProps, ILineChartProps } from './Types/chart.type';
import useIsDarkMode from '../CustomHooks/useIsDarkMode';

export const renderNoDataPlaceholder = (placeholder: IChartPlaceholderProps) => {
  const { icon, title, description } = placeholder;
  return (
    <div className={'no-data-placeholder-container'}>
      <img src={icon} alt={'No Data'} />
      <div className={'title'}>{title}</div>
      <div className={'text'}>{description}</div>
    </div>
  );
};

const LineChart = ({
  title,
  isLoading,
  data,
  className,
  yAxisTitle = '',
  placeholder,
  description,
  tooltipFormatter,
  highLightValue,
  hideLegend = false,
}: IChartProps & ILineChartProps) => {
  const [, selectedTheme] = useIsDarkMode();
  const chartColors: IColors = getColors(selectedTheme);
  const lineColors = _.map(data, i => i.color);
  const series = _.map(data, i => ({ name: i.legend, data: i.values }));
  const yTickAmount = 2;
  let yMax = 2;
  _.forEach(data, series => {
    _.forEach(series.values, valuePair => {
      if (yMax < valuePair[1]) {
        yMax = valuePair[1];
      }
    });
  });
  yMax += yMax % yTickAmount;

  const countTicksInterval = () => {
    return series[0]?.data.length <= 2 ? 1 : 2;
  };

  const options: ApexOptions = {
    chart: {
      width: '100%',
      type: 'line',
      fontFamily: 'Fakt',
      toolbar: {
        show: true,
        offsetX: 10,
        offsetY: -40,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: '<img src="' + resetIcon + '" width="20" alt="reset">',
        },
      },
      events: {
        zoomed: () => {
          const resetBtn = document.getElementsByClassName('apexcharts-reset-icon')[0];
          resetBtn.setAttribute('style', 'opacity: 1; cursor: pointer');
        },
      },
    },
    stroke: {
      width: 3,
    },
    colors: lineColors,
    grid: {
      borderColor: chartColors.chartBorderColor,
      padding: {
        top: 30,
      },
    },
    markers: {
      hover: {
        size: 5,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: chartColors.axisLabel,
        },
        formatter: function (val: string) {
          return moment(val).format('M/DD');
        },
      },
      axisTicks: {
        show: false,
      },
      tickAmount: countTicksInterval(),
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        style: {
          color: chartColors.axisLabel,
        },
      },
      tickAmount: yTickAmount,
      labels: {
        style: {
          colors: chartColors.axisLabel,
        },
        formatter: function (val: number) {
          return numberWithCommas(val?.toFixed(0));
        },
      },
      max: yMax,
      min: 0,
    },
    tooltip: {
      theme: selectedTheme,
      y: {
        title: {
          formatter: tooltipFormatter,
        },
      },
    },
    legend: {
      show: !hideLegend,
      position: 'top',
      horizontalAlign: 'left',
      floating: false,
      offsetX: -35,
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      labels: {
        colors: chartColors.legendText,
      },
      formatter: (legend: string, data: any) => {
        const seriesData = series[data.seriesIndex].data;
        let value = 0;
        _.forEach(seriesData, item => {
          value += item[1];
        });
        return `<div>${legend}<b>${numberWithCommas(value)}</b></div>`;
      },
    },
  };

  return (
    <div className={'dashboard-widget dashboard-line-chart ' + className}>
      {description ? (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + description} className={'table-source-url-tooltip'}>
              {description}
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title'>{title}</div>
        </OverlayTrigger>
      ) : (
        <div className='dashboard-chart-title'>{title}</div>
      )}
      <LoadingWrapper isLoading={isLoading}>
        {data.length ? (
          <>
            {highLightValue && (
              <LabelAndValue
                label={highLightValue.label}
                value={highLightValue.value}
                renderDom={highLightValue.renderDom}
                direction={'column'}
                noMarginBottom
              />
            )}
            <ReactApexChart
              options={options}
              series={series}
              height={highLightValue ? SMALLER_WIDGET_HEIGHT : WIDGET_HEIGHT}
              type='line'
            />
          </>
        ) : placeholder ? (
          renderNoDataPlaceholder(placeholder)
        ) : null}
      </LoadingWrapper>
    </div>
  );
};

export default LineChart;
