import React from 'react';
import { Annotation } from '../../Common/Annotation';
import { useAppSelector } from '../../../helpers/hooks';
import { getScreenshotUrl } from '../../../helpers/screenshotUrl';
import { Stack } from '@mui/material';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { downloadImg } from '../helpers';
import { attachmentsDetails } from '../constants';
interface IAllEmailScreenShotAnalysis {
  emailScreenshotUrl?: string;
  attachments?: attachmentsDetails[];
}

const AllEmailScreenShotAnalysis = ({
  emailScreenshotUrl,
  attachments,
}: IAllEmailScreenShotAnalysis) => {
  const isDemoBrand = useAppSelector(
    state => state.dashboardReducer.brandInfo?.brand?.brandName === 'bankofbolster',
  );

  return (
    <div className='all-email-submissions-section-details-wrap screenshot-section-wrap'>
      <Stack direction={'row'} spacing={1}>
        <div className='stack-item screenshot-header'>
          <span
            className='section-header'
            data-testid={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.SCREENSHOT}
            id={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.SCREENSHOT}
          >
            Screenshot
          </span>
        </div>
        {!emailScreenshotUrl && !attachments?.length ? (
          <span>Not available</span>
        ) : (
          <div className='screenshot-container'>
            {emailScreenshotUrl ? (
              <div className='stack-item screenshot email-screenshot'>
                <div className='screenshot-header'>
                  <span className='label'>Email</span>
                  <FileDownloadOutlinedIcon
                    className='download-icon'
                    data-testid='download-email-screenshot-btn'
                    onClick={downloadImg.bind(
                      null,
                      !isDemoBrand ? getScreenshotUrl(emailScreenshotUrl) : emailScreenshotUrl,
                    )}
                    sx={{
                      marginInline: 0.5,
                    }}
                  />
                </div>
                <Annotation
                  className={'img-fluid'}
                  imagePath={
                    !isDemoBrand ? getScreenshotUrl(emailScreenshotUrl) : emailScreenshotUrl
                  }
                  alt={'email-details-screenshot'}
                  hideHeader={true}
                />
              </div>
            ) : (
              <></>
            )}
            {attachments?.map(
              (attachment, index) =>
                attachment.screenshot?.[0] && (
                  <div className='stack-item screenshot attachment-screenshot' key={index}>
                    <div className='screenshot-header'>
                      <span className='label'>Attachment</span>
                      <FileDownloadOutlinedIcon
                        className='download-icon'
                        onClick={downloadImg.bind(
                          null,
                          !isDemoBrand
                            ? getScreenshotUrl(attachment.screenshot?.[0])
                            : attachment.screenshot?.[0],
                        )}
                        data-testid='download-attachment-screenshot-btn'
                      />
                    </div>
                    <Annotation
                      className={'img-fluid'}
                      imagePath={
                        !isDemoBrand
                          ? getScreenshotUrl(attachment.screenshot?.[0])
                          : attachment.screenshot?.[0]
                      }
                      alt={'attachment-details-screenshot'}
                      hideHeader={true}
                    />
                  </div>
                ),
            )}
          </div>
        )}
      </Stack>
    </div>
  );
};

export default AllEmailScreenShotAnalysis;
