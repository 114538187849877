import React from 'react';
import _, { property } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { LabelAndValue } from '../LabelAndValue';
import { Image, ON_ERROR_IMAGE } from '../Image';
import { LoadingWrapper } from '../LoadingWrapper';
import { Checkbox } from '../Checkbox';
import { getScreenshotUrl } from '../../../helpers/screenshotUrl';
import { isBrandReadOnlyUser } from '../../../constants';
import { useReadOnlyUser } from '../../../basic-hooks/useUserRoles';

export interface IAdditionalProperty {
  id: string;
  label: string;
  value: string;
  renderDom?: any;
  copyButton?: boolean;
  noMarginBottom?: boolean;
  hidden?: boolean;
  hiddenInTileView?: boolean;
}

interface IComponentProps {
  data: any;
  properties: IAdditionalProperty[];
  topThreeProps?: string[];
  enableCheckbox: boolean;
  selectedItems: any[];
  onItemCheck: (checked: boolean, item: any) => void;
}

const TableTile = ({
  data,
  properties,
  topThreeProps = [],
  enableCheckbox,
  selectedItems,
  onItemCheck,
}: IComponentProps) => {
  const { imagePath, isNewAdded, isLoading = false } = data;

  let compClassName = 'dashboard-tile-component table-tile-component';
  if (isNewAdded) {
    compClassName += ' is-new-added';
  }
  if (_.some(selectedItems, ['_index', data._index])) {
    compClassName += ' is-checked';
  }

  const firstMajorProp = _.find(properties, ['id', topThreeProps[0]]);
  const secondMajorProp = _.find(properties, ['id', topThreeProps[1]]);
  const thirdMajorProp = _.find(properties, ['id', topThreeProps[2]]);
  const otherProps = _.filter(
    properties,
    property =>
      topThreeProps.indexOf(property.id) === -1 && !property?.hidden && !property?.hiddenInTileView,
  );

  const tagProps = _.find(properties, ['id', 'tags']);
  const hasChecked = _.some(selectedItems, ['_index', data._index]);

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <div className={compClassName}>
      <Row className='major-content'>
        <Col sm={12} md={12} lg={6}>
          <Image
            className={'screenshot'}
            imagePath={getScreenshotUrl(imagePath)}
            alt='scan result screenshot'
          />
        </Col>
        <Col sm={12} md={12} lg={6} className='major-info-content'>
          {firstMajorProp && (
            <LabelAndValue
              longTextLineNumberLimit={1}
              copyButton
              noMarginBottom={firstMajorProp.noMarginBottom}
              label={firstMajorProp.label}
              value={firstMajorProp.value || '--'}
              renderDom={firstMajorProp.renderDom}
              direction={'column'}
            />
          )}
          <div className='ip-and-status d-flex'>
            <Col sm={6}>
              {secondMajorProp && (
                <LabelAndValue
                  longTextLineNumberLimit={1}
                  noMarginBottom
                  label={secondMajorProp.label}
                  value={secondMajorProp.value || '--'}
                  renderDom={secondMajorProp.renderDom}
                  direction={'column'}
                />
              )}
            </Col>
            <Col sm={6}>
              {thirdMajorProp && (
                <LabelAndValue
                  longTextLineNumberLimit={1}
                  noMarginBottom
                  label={thirdMajorProp.label}
                  value={thirdMajorProp.value || '--'}
                  renderDom={thirdMajorProp.renderDom}
                  direction={'column'}
                />
              )}
            </Col>
          </div>
        </Col>
      </Row>
      <Row className='secondary-content d-none d-lg-flex'>
        {tagProps && (
          <LabelAndValue
            noColon
            longTextLineNumberLimit={1}
            noMarginBottom={tagProps.noMarginBottom}
            label={''}
            value={tagProps.value || '--'}
            renderDom={tagProps.renderDom}
            direction={'column'}
          />
        )}
      </Row>
      <Row className='secondary-content d-none d-lg-flex'>
        {_.map(otherProps, (property: IAdditionalProperty, idx: number) => (
          <Col md={3} key={property.label + property.value + idx}>
            <div>
              <LabelAndValue
                longTextLineNumberLimit={1}
                copyButton={property.copyButton}
                noMarginBottom={property.noMarginBottom}
                label={property.label}
                value={property.value}
                renderDom={property.renderDom}
                direction={'column'}
              />
            </div>
          </Col>
        ))}
      </Row>
      {isLoading && <LoadingWrapper className={'tile-loading-mask'} />}
      {enableCheckbox && !isReadOnlyUser && (
        <Checkbox
          onChange={(checked: boolean) => {
            onItemCheck(checked, data);
          }}
          defaultChecked={hasChecked}
          isStateless
        />
      )}
    </div>
  );
};

export { TableTile };
