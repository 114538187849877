import React, { useCallback } from 'react';
import { useAppDispatch } from '../../../../helpers/hooks';
import {
  setSelectedEntityActive,
  setSelectedEntityIgnored,
  setSelectedEntityMitigated,
} from '../../../../reducers/darkWeb.reducer';
import { Nullable } from '../../../../types/common';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';

export default function useOnSelectingEntity({ status }: { status: EDarkWebFindingStatus }) {
  const dispatch = useAppDispatch();

  const setSelectedEntity = useCallback(
    (selectedValue: Nullable<string>) => {
      switch (status) {
        case EDarkWebFindingStatus.ACTIVE:
          dispatch(setSelectedEntityActive(selectedValue));
          break;
        case EDarkWebFindingStatus.MITIGATED:
          dispatch(setSelectedEntityMitigated(selectedValue));
          break;
        case EDarkWebFindingStatus.IGNORED:
          dispatch(setSelectedEntityIgnored(selectedValue));
          break;
      }
    },
    [dispatch, status],
  );
  return { setSelectedEntity };
}
