import React from 'react';
import { ContentAnalysisParams } from '../constants';
import { Stack, Tooltip } from '@mui/material';
import LableValue from '../Insights/LableValue';
import { DISPOSITION_TO_ICON_MAPPER } from '../AbuseMailboxDashboard/components/MostReportedDomains';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';
import _ from 'lodash';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
interface IAllEmailIntentAnalysis {
  intentAnalysisData: ContentAnalysisParams;
}

function fetchIntents(intentAnalysisData: string[] | undefined) {
  if (_.isEmpty(intentAnalysisData) || intentAnalysisData === undefined) return ['', ''];
  if (_.isString(intentAnalysisData)) return [intentAnalysisData, ''];
  return [intentAnalysisData[0], intentAnalysisData?.slice(1)?.join(', ')];
}

const AllEmailIntentAnalysis = ({ intentAnalysisData }: IAllEmailIntentAnalysis) => {
  const [, currentTheme] = useIsDarkMode();
  const { intentsIdentified } = intentAnalysisData;
  const [mainIntent, otherIntent] = fetchIntents(intentsIdentified);
  const displayNames = new Intl.DisplayNames(['en'], { type: 'language' });

  return (
    <div className='all-email-submissions-section-details-wrap intent-details'>
      <Stack direction={'row'} spacing={1}>
        <div className='stack-item intent-details-header'>
          <span
            className='section-header'
            data-testid={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.INTENT}
            id={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.INTENT}
          >
            Intent
          </span>
        </div>

        <Stack>
          <span className='stack-item disposition-row'>
            <LableValue
              lable='Initial Disposition'
              value={
                <span className='disposition-wrap'>
                  <img
                    src={
                      DISPOSITION_TO_ICON_MAPPER[
                        `${intentAnalysisData.initialDisposition.toLocaleLowerCase()}-${currentTheme}`
                      ]
                    }
                  />
                  {intentAnalysisData.initialDisposition}
                </span>
              }
            />
          </span>
          <span className='stack-item intent-data'>
            <span className='row'>
              {intentAnalysisData.sha256 ? (
                <LableValue
                  lable='Intents Identified'
                  value={
                    <div className='sender-domain-link'>
                      <Tooltip title={mainIntent}>
                        <span>{mainIntent}</span>
                      </Tooltip>
                    </div>
                  }
                />
              ) : (
                <LableValue lable='Intents Identified' value={mainIntent} />
              )}
              <LableValue
                lable='Source'
                value={intentAnalysisData.source}
                className='border-left'
              />
              <LableValue
                lable='Language'
                value={displayNames.of(intentAnalysisData.language) || 'unknown language'}
                className='border-left'
              />
              <LableValue lable='Tone' value={intentAnalysisData.tone} className='border-left' />
            </span>
            <span className='row'>
              <LableValue lable='Other Intents' value={otherIntent || '--'} />
            </span>
          </span>
        </Stack>
      </Stack>
    </div>
  );
};

export default AllEmailIntentAnalysis;
