import { capitalize, DISPLAYED_NULL } from '../../../../constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import '../../Style/PlatformDetails.scss';
import _ from 'lodash';
import { useAppDispatch } from '../../../../helpers/hooks';
import {
  setCurrentDataLeakDescription,
  setCurrentDataLeakParams,
  setShouldShowDataLeakTablePopup,
} from '../../../../reducers/darkWeb.reducer';

interface IPlatformDetailsProps {
  data: any;
}

export default function PlatformDetails({ data }: IPlatformDetailsProps) {
  const { platform, source_specific_info } = data;

  const dispatch = useAppDispatch();

  const openDataLeakDrawer = () => {
    dispatch(setShouldShowDataLeakTablePopup(true));
    const description =
      source_specific_info?.description || source_specific_info?.breach_description;
    if (source_specific_info && description) {
      dispatch(setCurrentDataLeakDescription(description));
    } else {
      dispatch(setCurrentDataLeakDescription(''));
    }

    if (platform) {
      dispatch(setCurrentDataLeakParams(platform));
    } else {
      dispatch(setCurrentDataLeakParams(''));
    }
  };

  return (
    <div className={'platform-container'} onClick={openDataLeakDrawer}>
      <div className={'table-long-text long-text-ellipsis-1'}>
        {capitalize(platform) || DISPLAYED_NULL}
      </div>
      <div style={{ marginLeft: '6px' }}>
        <InfoOutlinedIcon />
      </div>
    </div>
  );
}
