import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { appConstants, featureIsAvailable, getSingleOrPluralForm } from '../../../constants';
import { Dropdown, IDropdownOption } from '../../Common/Dropdown';
import { DropdownDatePicker } from '../../Common/DropdownDatePicker';
import { DropdownMultiSelect } from '../../Common/DropdownMultiSelect';
import { DropdownTimePicker } from '../../Common/DropdownTimePicker';
import { DropdownTimezone } from '../../Common/DropdownTimezone';
import { Step } from '../../Common/Step';
import { IFilter } from '../../Common/Table/constant';
import { TableFilterController } from '../../Common/Table/filter.controller';
import { NewPlaybookTemplatesTile } from './NewPlaybookTemplateTile';
import { PlaybookContext } from '../../../context/playbook.context';
import '../Style/playbook.scss';
import {
  EPlaybookSteps,
  INewPlaybookModal,
  ITemplate,
  OUTPUT_TYPE_OPTION,
  PLAYBOOK_STEPS,
} from './PlaybookTypes';
import { Playbook_Filter_Controller, PLAYBOOK_PROPERTY_TYPE } from './PlaybookConstants';
import { AppState } from '../../../helpers';
import { Checkbox } from '../../Common/Checkbox';
import useFetchConnectorDefinition from '../PlaybookCustomHooks/useFetchConnectorDefinition';
import useModifiedPlaybookColumns from '../PlaybookCustomHooks/useModifiedPlaybookColumns';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  setRecentChangesDateRange,
  setRecentChangesMultiOptions,
} from '../../../reducers/playbook.reducer';
import { setShouldRefreshClientSideTable } from '../../../reducers/table.reducer';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';

const HOURS_OPTION = ((): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (let i = 1; i <= 24; i++) {
    if (24 % i === 0) {
      options.push({ value: JSON.stringify(i), label: i + getSingleOrPluralForm(i, ' hour') });
    }
  }
  return options;
})();

const DESCRIPTION_MAX_LENGTH = 1000;

const NewPlaybookModal = ({
  templates,
  useTemplate,
  modalShown,
  setModalShown,
  onSubmit,
  dataClass,
  type,
  addPlaybookFilterIds,
  resetFilterIds,
  removePlaybookFilterId,
  newPlaybook,
  setNewPlaybook,
}: INewPlaybookModal) => {
  const playbookContext = useContext(PlaybookContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    useTemplate ? EPlaybookSteps.INTITIATE_PLAYBOOK : EPlaybookSteps.DEFINE_OUTPUT,
  );
  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);
  const [outputListType, setOutputListType] = useState('');
  const filtersRef = useRef<any>(null);
  const [attributes, setAttributes] = useState<any>([]);
  const [selectedAttributeIds, setSelectedAttributeIds] = useState<any>([]);
  const [, selectedTheme] = useIsDarkMode();
  const [selectedAttributes, setSelectedAttributes] = useState<any>([]);
  const descriptionLength = playbookContext.description?.length || 0;
  const { initData } = playbookContext;

  const recentChangesDateRange = useAppSelector(
    state => state.playbookReducer.recentChangesDateRange,
  );

  const dispatch = useAppDispatch();
  const { connectorOptions } = useFetchConnectorDefinition();
  const { tagsOptions } = useModifiedPlaybookColumns(type);

  useEffect(() => {
    if (connectorOptions?.length === 1 && !playbookContext.connector.value) {
      onSelectConnector(connectorOptions[0]);
    }
  }, [connectorOptions, playbookContext]);

  useEffect(() => {
    if (playbookContext.attributes.length) {
      setOutputListType('Sites');
    }
    if (playbookContext.dataAttributes === undefined && dataClass) {
      playbookContext.updateDataClass(dataClass[0]);
    }
    if (playbookContext.dataAttributes) {
      addValueToAttributes(playbookContext.dataAttributes);
    }
  }, [playbookContext]);

  useEffect(() => {
    if (playbookContext.dataAttributes && playbookContext.steps) {
      attributeIdsArray();
    }
  }, [attributes]);

  useEffect(() => {
    setCurrentStep(useTemplate ? EPlaybookSteps.INTITIATE_PLAYBOOK : EPlaybookSteps.DEFINE_OUTPUT);
  }, [useTemplate, modalShown]);

  const closeModal = () => {
    playbookContext.resetData();
    setCurrentStep(EPlaybookSteps.DEFINE_OUTPUT);
    setOutputListType('');
    setIsLoading(false);
    setModalShown(false);
    setSelectedAttributeIds('');
    setAttributes([]);
    resetFilterIds();
    setNewPlaybook(false);

    // Reset recent changes date range and multi options
    dispatch(setRecentChangesDateRange({ dateRange: '' }));
    dispatch(setRecentChangesMultiOptions({ fields: [] }));
  };

  const onSelectTemplate = useCallback(
    (template: ITemplate) => {
      template.scheduleDropdown.date = moment().valueOf();
      initData(template);
      setCurrentStep(EPlaybookSteps.DEFINE_CONNECTOR);
    },
    [initData],
  );
  const addValueToAttributes = (dataAttributes: any) => {
    if (dataAttributes.hasOwnProperty('attributes')) {
      dataAttributes.attributes.forEach((item: any) => {
        item['value'] = item.label;
      });
      setAttributes(dataAttributes.attributes);
    }
  };
  const onNextClicked = useCallback(() => {
    if (currentStep === EPlaybookSteps.DEFINE_QUERY) {
      playbookContext.updateFilters(filtersRef.current.state.filters);
    }
    if (currentStep === EPlaybookSteps.DEFINE_SCHEDULE) {
      setIsLoading(true);
      onSubmit(playbookContext, closeModal, () => {
        setIsLoading(false);
        dispatch(setShouldRefreshClientSideTable(true));
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [playbookContext, currentStep]);

  const onSelectConnector = useCallback(selection => {
    playbookContext.updateConnector(selection);
  }, []);

  const onScheduleDateChange = useCallback(selection => {
    playbookContext.updateSchedule('date', selection.valueOf());
  }, []);

  const onScheduleTimeChange = useCallback(selection => {
    playbookContext.updateSchedule('time', selection);
  }, []);

  const onScheduleFrequencyChange = useCallback(selection => {
    playbookContext.updateSchedule('frequency', selection);
  }, []);

  const onTimezoneChange = useCallback(selection => {
    playbookContext.updateTimezone(selection.value);
  }, []);

  const frequencyOptions = useMemo(() => {
    const dateValue: any = _.get(playbookContext, ['scheduleDropdown', 'date']);
    const timeValue: any = _.get(playbookContext, ['scheduleDropdown', 'time']);
    if (dateValue === '' || timeValue === '') {
      return [];
    }
    const dateMoment = moment(dateValue);
    return [
      {
        value: `hourly`,
        label: 'Hourly',
      },
      {
        value: `daily`,
        label: 'Daily',
      },
      {
        value: `weekly`,
        label: 'Weekly on ' + dateMoment.format('dddd'),
      },
      {
        value: `monthly`,
        label: 'Monthly on ' + dateMoment.format('Do'),
      },
      {
        value: `annually`,
        label: 'Annually on ' + dateMoment.format('MMM Do'),
      },
      {
        value: `weekdays`,
        label: 'Every weekday (Monday-Friday)',
      },
    ];
  }, [playbookContext]);

  const afterFilterChange = useCallback(
    (filters: IFilter[]) => {
      playbookContext.updateFilters(filtersRef.current.state.filters);
    },
    [playbookContext],
  );

  const nextButtonDisabled = useMemo(() => {
    const { name, outputType, attributes, filters, connector, scheduleDropdown } = playbookContext;
    switch (currentStep) {
      case EPlaybookSteps.DEFINE_OUTPUT:
        return _.isEmpty(outputType.value) || attributes.length === 0;
      case EPlaybookSteps.DEFINE_QUERY:
        for (let filterIdx = 0; filterIdx < filters.length; filterIdx++) {
          if (_.get(filters[filterIdx], 'filterType') === 'recentChangeDateOptions') {
            //Special case to check the recent changes options and date range filter value is nqt empty
            if (
              !_.get(filters[filterIdx], 'filterValue') ||
              recentChangesDateRange?.dateRange === ''
            ) {
              return true;
            }
          } else if (!_.get(filters[filterIdx], 'filterValue')) {
            return true;
          }
        }
        return false;
      case EPlaybookSteps.DEFINE_CONNECTOR:
        const { value, fields } = connector;
        for (let fieldIdx = 0; fieldIdx < fields.length; fieldIdx++) {
          if (_.isEmpty(fields[fieldIdx].value)) {
            return true;
          }
        }
        return value === '' || _.isEmpty(JSON.stringify(value));
      case EPlaybookSteps.DEFINE_SCHEDULE:
        const { date, time, frequency } = scheduleDropdown;
        return (
          _.isEmpty(name) ||
          _.isEmpty(JSON.stringify(date)) ||
          _.isEmpty(time.value) ||
          _.isEmpty(frequency.value)
        );
      default:
        return true;
    }
  }, [currentStep, playbookContext]);
  const attributeIdsArray = () => {
    let selectArray: any = [];
    let attributeIds: any = [];
    if (playbookContext.steps.length) {
      playbookContext.steps.forEach((item: any) => {
        if (item.step.key === 'QUERY') {
          attributeIds = [...attributeIds, ...item.details.attributeIds];
        }
      });
    }
    if (attributes) {
      selectArray = attributes.filter((item: any) =>
        attributeIds.find((attr: any) => attr === item.id),
      );
      setSelectedAttributes(selectArray);
    }
  };

  const frequencyValue = playbookContext.scheduleDropdown.frequency.value;
  return (
    <Modal
      show={modalShown}
      size='lg'
      backdrop={'static'}
      onHide={closeModal}
      className={`${selectedTheme}`}
    >
      <Modal.Header>
        {currentStep === EPlaybookSteps.INTITIATE_PLAYBOOK ? (
          <Modal.Title>
            <div className={'choose-template-modal-header'}>Choose a playbook template</div>
          </Modal.Title>
        ) : (
          <Form.Control
            defaultValue={playbookContext.name}
            className={'playbook-name-input'}
            placeholder={'Write playbook name...'}
            required={true}
            onChange={(e: any) => {
              playbookContext.updateName(e.currentTarget.value);
            }}
          />
        )}
        <div onClick={closeModal}>
          <ClearRoundedIcon />
        </div>
      </Modal.Header>
      <Modal.Body className={'playbook-modal-body modal-body'}>
        {currentStep === EPlaybookSteps.INTITIATE_PLAYBOOK ? (
          _.map(templates, (template, i) => (
            <NewPlaybookTemplatesTile
              key={'template-' + i}
              data={template}
              onClick={onSelectTemplate}
            />
          ))
        ) : (
          <>
            <Form.Label>Description (optional)</Form.Label>
            <Form.Control
              as='textarea'
              maxLength={DESCRIPTION_MAX_LENGTH}
              required={true}
              className={'playbook-description-input'}
              defaultValue={playbookContext.description}
              onChange={(e: any) => {
                playbookContext.updateDescription(e.currentTarget.value);
              }}
            />
            <Form.Text>
              {DESCRIPTION_MAX_LENGTH - descriptionLength} characters remaining.
            </Form.Text>
            <Step
              steps={PLAYBOOK_STEPS}
              key={currentStep}
              initialStep={currentStep}
              disabledFutureClick
              onStepChange={step => setCurrentStep(step.stepId)}
            />
            <div className={'config-content-container w-100'}>
              {currentStep === EPlaybookSteps.DEFINE_OUTPUT && (
                <div>
                  <Form.Label>I want to create a list of:</Form.Label>
                  {dataClass.length !== 0 && (
                    <Dropdown
                      options={dataClass}
                      defaultSelection={
                        playbookContext.dataAttributes !== '' ? dataClass[0] : undefined
                      }
                      boxStyle
                      onChange={selection => {
                        playbookContext.updateDataClass(selection);
                      }}
                      className='my-end--8'
                    />
                  )}
                  {playbookContext.dataAttributes && (
                    <DropdownMultiSelect
                      label={'With the following attributes:'}
                      options={attributes}
                      boxStyle
                      updateOnChange
                      initialSelections={playbookContext.id ? selectedAttributes : []}
                      onSubmit={selections => {
                        playbookContext.updateColumns(selections);
                      }}
                    />
                  )}

                  {outputListType === 'Sites' && (
                    <>
                      <Form.Label className={'mt-3'}>Export Format:</Form.Label>
                      <Dropdown
                        boxStyle
                        options={OUTPUT_TYPE_OPTION}
                        defaultSelection={playbookContext.outputType}
                        onChange={selection => {
                          playbookContext.updateOutputType(selection);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
              {currentStep === EPlaybookSteps.DEFINE_QUERY && (
                <div className={'d-flex flex-column'}>
                  <Form.Label>Filters</Form.Label>
                  <TableFilterController
                    ref={filtersRef}
                    filters={playbookContext.filters}
                    autoAddFirstFilter={false}
                    alwaysExpanded={true}
                    addPlaybookFilterIds={addPlaybookFilterIds}
                    removePlaybookFilterId={removePlaybookFilterId}
                    dataClass={dataClass}
                    playbookFilterIds={playbookContext.playbookFilterIds}
                    tagsOptions={tagsOptions}
                    type={type}
                    columns={_.map(
                      Playbook_Filter_Controller[PLAYBOOK_PROPERTY_TYPE[type]],
                      (column: any) => {
                        return {
                          ...column,
                          filterDisabled: column?.filterDisabledOnPlaybook || false,
                        };
                      },
                    )}
                    onFiltersApplied={_.noop}
                    onFiltersCancelled={_.noop}
                    onToggleFilter={_.noop}
                    afterFilterChange={afterFilterChange}
                  />
                </div>
              )}
              {currentStep === EPlaybookSteps.DEFINE_CONNECTOR && (
                <div>
                  <Form.Label className={'mt-3'}>Output to:</Form.Label>
                  <Dropdown
                    boxStyle
                    defaultSelection={_.find(connectorOptions, [
                      'value',
                      playbookContext.connector.value,
                    ])}
                    options={connectorOptions}
                    ordersPersist
                    onChange={onSelectConnector}
                  />
                  {playbookContext.connector &&
                    playbookContext.connector.fields &&
                    _.map(playbookContext.connector.fields, field => {
                      if (_.isEmpty(field.key)) {
                        return null;
                      }
                      return (
                        <div key={field.key}>
                          <Form.Label className={'mt-3'}>{field.label}</Form.Label>
                          <Form.Control
                            value={_.chain(playbookContext.connector.fields)
                              .find(['key', field.key])
                              .get('value', '')
                              .value()}
                            onChange={(e: any) => {
                              playbookContext.updateConnectorField(
                                field.key,
                                e.currentTarget.value,
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
              {currentStep === EPlaybookSteps.DEFINE_SCHEDULE && (
                <div>
                  <Form.Label className={'mt-3'}>Output schedule</Form.Label>
                  <div className={'w-100 d-flex'}>
                    <DropdownDatePicker
                      initDate={moment(playbookContext.scheduleDropdown.date)}
                      onChange={onScheduleDateChange}
                    />
                    <Dropdown
                      boxStyle
                      disabled={playbookContext.id === '' && frequencyOptions.length === 0}
                      defaultSelection={playbookContext.scheduleDropdown.frequency}
                      btnClassName={'dropdown-frequency-component ml-3'}
                      options={frequencyOptions}
                      ordersPersist
                      onChange={onScheduleFrequencyChange}
                    />
                    {frequencyValue === 'hourly' && (
                      <Dropdown
                        boxStyle
                        disabled={playbookContext.id === '' && frequencyOptions.length === 0}
                        defaultSelection={playbookContext.scheduleDropdown.time || HOURS_OPTION[0]}
                        btnClassName={'dropdown-frequency-component ml-3'}
                        options={HOURS_OPTION}
                        ordersPersist
                        onChange={onScheduleTimeChange}
                      />
                    )}
                    {!_.isEmpty(frequencyValue) && frequencyValue !== 'hourly' && (
                      <>
                        <DropdownTimePicker
                          key={frequencyValue}
                          defaultSelection={playbookContext.scheduleDropdown.time}
                          className={'ml-3'}
                          onChange={onScheduleTimeChange}
                        />
                        <DropdownTimezone
                          className={'ml-3'}
                          defaultValue={playbookContext.timezone}
                          onSelectTimezone={onTimezoneChange}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
              {featureIsAvailable(user, appConstants.FEATURE_CODE.INTERNAL_PLAYBOOK_ACCESS) &&
                newPlaybook && (
                  <div className='playbook-internal-wrapper'>
                    <Checkbox
                      label={'Internal Playbook'}
                      defaultChecked={playbookContext.internalPlaybook}
                      onChange={(checkMark: boolean) => {
                        playbookContext.updateInternalPlaybook(checkMark);
                      }}
                    />
                  </div>
                )}
              {featureIsAvailable(user, appConstants.FEATURE_CODE.INTERNAL_PLAYBOOK_ACCESS) &&
                !newPlaybook && (
                  <div className='playbook-internal-wrapper'>
                    <Checkbox
                      label={'Internal Playbook'}
                      defaultChecked={playbookContext.internalPlaybook}
                      isStateless={true}
                    />
                  </div>
                )}
            </div>
          </>
        )}
      </Modal.Body>
      {currentStep > EPlaybookSteps.INTITIATE_PLAYBOOK && (
        <Modal.Footer>
          <Button
            disabled={nextButtonDisabled || isLoading}
            variant='contained'
            onClick={onNextClicked}
          >
            {isLoading ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : currentStep === EPlaybookSteps.DEFINE_SCHEDULE ? (
              'Submit'
            ) : (
              'Next'
            )}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default NewPlaybookModal;
