import React from 'react';
import { SenderDomainAnalysisParams } from '../constants';
import { Stack, Tooltip } from '@mui/material';
import LableValue from '../Insights/LableValue';
import { Link } from 'react-router-dom';
import CopyButton from '../../Common/CopyButton';
import { DISPOSITION_TO_ICON_MAPPER } from '../AbuseMailboxDashboard/components/MostReportedDomains';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
import _ from 'lodash';

interface IAllEmailSenderDomainAnalysis {
  senderDomainData: SenderDomainAnalysisParams;
}

const AllEmailSenderDomainAnalysis = ({ senderDomainData }: IAllEmailSenderDomainAnalysis) => {
  const [, currentTheme] = useIsDarkMode();
  const { initialDisposition, senderDomain } = senderDomainData;
  const isSenderDomainNotAvailable =
    initialDisposition === 'Unknown' && senderDomain === 'unavailable';
  return (
    <div className='all-email-submissions-section-details-wrap sender-domain-details'>
      <Stack direction={'row'} spacing={1}>
        <div className='stack-item sender-domain-header'>
          <span
            className='section-header'
            data-testid={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.SENDER_DOMAIN}
            id={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.SENDER_DOMAIN}
          >
            Sender Domain
          </span>
        </div>
        <Stack>
          <span className='stack-item disposition-row'>
            {!isSenderDomainNotAvailable ? (
              <LableValue
                lable='Initial Disposition'
                value={
                  <span className='disposition-wrap'>
                    <img
                      src={
                        DISPOSITION_TO_ICON_MAPPER[
                          `${senderDomainData.initialDisposition.toLocaleLowerCase()}-${currentTheme}`
                        ]
                      }
                    />
                    {senderDomainData.initialDisposition}
                  </span>
                }
              />
            ) : (
              <span className='not-available-text'>Not available</span>
            )}
          </span>
          <div className='stack-item sender-domain-link-wrap'>
            {_.isEmpty(senderDomainData.senderDomain) ||
            senderDomainData.senderDomain !== 'unavailable' ||
            _.isEmpty(senderDomainData.sha256) ? (
              <>
                <LableValue
                  lable='Sender Domain'
                  value={
                    <div className='sender-domain-link'>
                      <Tooltip title={senderDomainData.senderDomain}>
                        <span>{senderDomainData.senderDomain}</span>
                      </Tooltip>
                      <CopyButton
                        value={senderDomainData.senderDomain}
                        copyButton={true}
                        className='custom-copy-button'
                      />
                    </div>
                  }
                />
              </>
            ) : null}
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default AllEmailSenderDomainAnalysis;
