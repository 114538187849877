import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import '../Style/playbook.scss';
import NewPlaybookModal from './NewPlaybookModal';
import { playbookDefaultValue, PlaybookContextProvider } from '../../../context/playbook.context';
import Card from 'react-bootstrap/Card';
import Cron from 'cron';
import { AuthenticationWrapper } from '../../AuthenticationWrapper';
import { DropdownOptionDefaultValue } from '../../Common/Dropdown';
import PlaybookService from '../../../services/playbook.service';
import { PlaybookGetStarted } from './PlaybookGetStarted';
import { PlaybooksTable } from './PlaybooksTable';
import moment from 'moment';
import { setDateRangeFilter, setScanTableFilter } from '../../../services/dashboard.service';
import { AppState } from '../../../helpers';
import { alertActions } from '../../../actions';
import { connect } from 'react-redux';
import { ordinalSuffixOf, WEEKDAYS, getSingleOrPluralForm, appConstants } from '../../../constants';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { IPlaybook, IPlaybooksProps, ITemplate } from './PlaybookTypes';
import { buildFilterQueryString } from '../../../services/ugc.service';
import { fetchConnectorDefinition } from '../playbook-requests';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { IFilter } from '../../Common/Table/constant';
import { setShouldRefreshClientSideTable } from '../../../reducers/table.reducer';

const playbookService = new PlaybookService();

const STEP_MATCHED_KEYS = {
  queryEngine: 'QUERY',
  dataFormatter: 'FORMATTER',
  connector: 'CONNECTOR',
};

const Playbooks = (props: IPlaybooksProps) => {
  window.document.title = 'Playbooks | Automation';
  const { attributes, type } = props;
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [newPlaybook, setNewPlaybook] = useState<boolean>(false);
  const [useTemplate, setUseTemplate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [playbooks, setPlaybooks] = useState<IPlaybook[]>([]);
  const [playbookTemplates, setPlaybookTemplates] = useState<any[]>([]);
  const [playbookDataClass, setPlaybookDataClass] = useState<any[]>([]);
  const [playbooksFilterIds, setPlaybooksFilterIds] = useState<any[]>([]);
  const recentChangesDateRange = useAppSelector(
    state => state.playbookReducer.recentChangesDateRange,
  );
  const recentChangesMultiOptions = useAppSelector(
    state => state.playbookReducer.recentChangesMultiOptions,
  );

  const dispatch = useAppDispatch();
  const stepConfig = useRef<any>(null);

  useEffect(() => {
    if (attributes.length) {
      void fetchPlaybooks();
      getStepConfig();
    }
  }, [attributes]);

  const resetFilterIds = () => {
    setPlaybooksFilterIds([]);
  };

  useEffect(() => {
    if (playbookDataClass[0]?.attributes) {
      playbookDataClass[0].attributes.forEach((item: any) => {
        const splitBeginning = item.field.split('.');
        item.field = splitBeginning[splitBeginning.length - 1];
      });
    }
  }, [playbookDataClass]);

  const removePlaybookFilterId = (filterValue: string, fid?: number) => {
    let filterId;
    if (fid) {
      filterId = fid;
    } else {
      filterId = _.find(
        playbookDataClass[0].attributes,
        attribute => attribute.field === filterValue,
      );
    }
    const indexOfId = playbooksFilterIds.indexOf(filterId);
    const tmpArray = [...playbooksFilterIds];
    tmpArray.splice(indexOfId, 1);
    setPlaybooksFilterIds(tmpArray);
  };

  const addPlaybookFilterIds = (filterName: string) => {
    const filterId = _.find(
      playbookDataClass[0].attributes,
      attribute => attribute.field === filterName,
    );

    if (filterId) {
      if (playbooksFilterIds.includes(filterId.id)) {
        removePlaybookFilterId(filterName, filterId);
      } else {
        setPlaybooksFilterIds([...playbooksFilterIds, filterId.id]);
      }
    }
  };
  const fetchPlaybooks = async () => {
    try {
      const [
        connectorDefinitionRes,
        connectorsListRes,
        playbooksListRes,
        playbooksTemplatesRes,
        playbooksDataClass,
      ] = await Promise.all([
        fetchConnectorDefinition(),
        playbookService.getConnectorsList(),
        playbookService.getPlaybooksList(type),
        playbookService.getPlaybookTemplatesList(type),
        playbookService.getPlaybooksDataClassList(type),
      ]);

      const getBasicInfo = (rawData: any) => {
        return {
          name: rawData.name,
          description: rawData.description,
          steps: rawData.steps,
          connector: {
            id: -1,
            label: '',
            value: '',
            fields: [],
          },
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          scheduleDropdown: {
            date: moment().format(),
            time: DropdownOptionDefaultValue,
            frequency: DropdownOptionDefaultValue,
          },
        };
      };

      const getOutputInfo = (rawData: any) => {
        const attributeFields: any[] = [];
        const queryEngineStep = _.find(rawData.steps, step => {
          return step.step.key === STEP_MATCHED_KEYS.queryEngine;
        });
        let filterIds: any[] = [];
        if (queryEngineStep) {
          _.forEach(queryEngineStep.details.attributeIds, id => {
            const attribute = _.find(attributes, { id: id });
            if (attribute) {
              attributeFields.push({
                ...attribute,
                value: attribute.field,
              });
            }
          });

          if (queryEngineStep.details?.postProcessor?.filterNestedAttributeIds.length) {
            filterIds = queryEngineStep.details?.postProcessor?.filterNestedAttributeIds;
          }
        }
        const dataFormatterStep = _.find(rawData.steps, step => {
          return step.step.key === STEP_MATCHED_KEYS.dataFormatter;
        });
        return {
          outputType: _.get(dataFormatterStep, ['details', 'outputType'], 'JSON'),
          attributes: attributeFields,
          filterIds: filterIds,
        };
      };

      const getQueryInfo = (rawData: any) => {
        const filters: any[] = [];
        if (type !== appConstants.CONTENT_TYPE.SOCIAL) {
          if (rawData && rawData?.mustFilterDto) {
            _.forEach(rawData?.mustFilterDto, (filter: any, key: string) => {
              filters.push({
                key,
                value: filter.value || filter,
              });
            });
          }
          if (rawData && rawData?.mustNotFilterDto) {
            _.forEach(rawData?.mustNotFilterDto, (filter: any, key: string) => {
              filters.push({
                key,
                value: filter.value || filter,
                isMustNot: true,
              });
            });
          }
        } else {
          if (rawData && rawData?.mustFilterDto?.pgFilters) {
            _.forEach(rawData?.mustFilterDto?.pgFilters, (filter: any, key: string) => {
              filters.push({
                key,
                value: filter.value || filter,
              });
            });
          }
          if (rawData && rawData?.mustNotFilterDto?.pgFilters) {
            _.forEach(rawData?.mustNotFilterDto?.pgFilters, (filter: any, key: string) => {
              filters.push({
                key,
                value: filter.value || filter,
                isMustNot: true,
              });
            });
          }
        }

        return {
          filters,
          dateRanges: rawData.mustDateRangeDto,
        };
      };

      const getConnectorInfo = (rawData: any) => {
        let connector: any = {
          id: -1,
          label: '',
          value: '',
          fields: [],
        };
        let outputTo = '';
        const connectorStep = _.find(rawData.steps, step => {
          return step.step.key === STEP_MATCHED_KEYS.connector;
        });
        if (connectorStep) {
          const connectorData = _.find(connectorsListRes.result, {
            id: connectorStep.details.connectorId,
          });
          if (connectorData) {
            const connectorDef = _.find(connectorDefinitionRes.result, {
              id: connectorData.connectorTypeId,
            });
            outputTo = connectorData.name;
            connector = {
              id: connectorData.id,
              label: connectorData.name,
              value: connectorData.id,
              fields: _.chain(connectorDef.metadata)
                .filter(i => !i.isGlobal)
                .map(field => {
                  return {
                    key: field.key,
                    label: field.label,
                    value: connectorStep.details[field.key],
                  };
                })
                .value(),
            };
          }
        }
        return {
          connector,
          outputTo,
        };
      };

      const getScheduleInfo = (rawData: any) => {
        let displaySchedule = '';
        const internalPlaybook = rawData.isInternal;
        const startDate = rawData.startDate;
        const startDateMoment = moment(startDate);
        const timezone = rawData.timezone;
        const cronTime = new Cron.CronTime(rawData.schedule, timezone);
        let nextIdx = 1;
        let nextRunMoment = cronTime.sendAt(nextIdx)[nextIdx - 1];
        while (nextRunMoment.valueOf() < startDateMoment.valueOf()) {
          nextIdx++;
          nextRunMoment = cronTime.sendAt(nextIdx)[nextIdx - 1];
        }
        const [min, hour, dayOfMonth, month, dayOfWeek] = rawData.schedule.split(' ').slice(1);

        const scheduleDropdownTimeValue = min * 60000 + hour * 3600000;
        const timeMoment = moment.utc(scheduleDropdownTimeValue);
        let scheduleDropdownTime = {
          label: moment.utc(scheduleDropdownTimeValue).format('hh:mm A'),
          value: JSON.stringify(scheduleDropdownTimeValue),
        };
        let scheduleDropdownFrequency = DropdownOptionDefaultValue;
        if (month !== '*') {
          scheduleDropdownFrequency = {
            value: `annually`,
            label: 'Annually on ' + ordinalSuffixOf(dayOfMonth),
          };
          displaySchedule =
            'Annually on ' + startDateMoment.format('MMM Do, ') + timeMoment.format('h:mm A');
        } else if (dayOfMonth !== '*') {
          scheduleDropdownFrequency = {
            value: `monthly`,
            label: 'Monthly on ' + ordinalSuffixOf(dayOfMonth),
          };
          displaySchedule =
            'Monthly on ' + startDateMoment.format('Do, ') + timeMoment.format('h:mm A');
        } else if (dayOfWeek === '1-5') {
          scheduleDropdownFrequency = {
            value: `weekdays`,
            label: 'Every weekday (Monday-Friday)',
          };
          displaySchedule = 'Weekday, ' + timeMoment.format('h:mm A');
        } else if (dayOfWeek !== '*') {
          scheduleDropdownFrequency = {
            value: `weekly`,
            label: 'Weekly on ' + WEEKDAYS[dayOfWeek],
          };
          displaySchedule =
            'Weekly on ' + startDateMoment.format('dddd, ') + timeMoment.format('h:mm A');
        } else if (hour.indexOf('*') === -1 && min !== '*') {
          scheduleDropdownFrequency = {
            value: `daily`,
            label: 'Daily',
          };
          displaySchedule = 'Daily, ' + timeMoment.format('h:mm A');
        } else if (hour.indexOf('*') !== -1 && min !== '*') {
          const everyXHour = hour.split('/')[1] || '1';
          const label = everyXHour + getSingleOrPluralForm(parseInt(everyXHour, 10), ' hour');
          scheduleDropdownFrequency = {
            value: 'hourly',
            label: 'Hourly',
          };
          scheduleDropdownTime = {
            value: everyXHour,
            label,
          };
          displaySchedule = 'Every ' + label;
        }

        const nextRun = `${nextRunMoment.local().format('DD-MMM-YYYY, h:mmA')}`;
        displaySchedule += ` (${timezone})`;

        const createdBy = rawData.createdBy;
        const createdTs = rawData.createdTs;
        const updatedTs = rawData.updatedTs;

        return {
          startDate,
          schedule: rawData.schedule,
          timezone,
          displaySchedule,
          scheduleDropdown: {
            date: startDate,
            time: scheduleDropdownTime,
            frequency: scheduleDropdownFrequency,
          },
          nextRun,
          internalPlaybook,
          nextRunValue: nextRunMoment.valueOf(),
          createdBy,
          createdTs,
          updatedTs,
        };
      };

      const getPlaybookOperationalInfo = (rawData: any) => {
        const lastRun = _.get(rawData, ['metadata', 'lastRun']);
        let lastRunCount = 0;
        if (!_.isEmpty(lastRun)) {
          lastRunCount = _.get(lastRun, ['resultCount'], 0);
        }
        return {
          id: rawData.id,
          author: rawData.createdBy?.firstName,
          isActive: rawData.is_active,
          runCount: parseInt(_.get(rawData, ['metadata', 'runCount'], 0), 10),
          totalResultCount: _.get(rawData, ['metadata', 'totalResultCount'], 0),
          lastRunCount,
          lastRun,
        };
      };

      const processTemplateData = (rawTemplateData: any) => {
        return _.map(rawTemplateData, (rawData: any): ITemplate => {
          return {
            ...getBasicInfo(rawData),
            ...getOutputInfo(rawData),
            ...getQueryInfo(rawData),
          };
        });
      };

      const processPlaybookData = (rawPlaybookData: any) => {
        return _.map(rawPlaybookData, (rawData: any): IPlaybook => {
          return {
            ...getBasicInfo(rawData),
            ...getOutputInfo(rawData),
            ...getQueryInfo(rawData),
            ...getConnectorInfo(rawData),
            ...getScheduleInfo(rawData),
            ...getPlaybookOperationalInfo(rawData),
          };
        });
      };

      let data: IPlaybook[] = [];
      if (playbooksListRes.length) {
        data = processPlaybookData(playbooksListRes);
      }
      setPlaybooks(data);
      if (playbooksDataClass.length) {
        setPlaybookDataClass(playbooksDataClass);
      }
      let templates: ITemplate[] = [];
      if (playbooksTemplatesRes.length) {
        templates = processTemplateData(playbooksTemplatesRes);
        setPlaybookTemplates(templates);
      }
      dispatch(setShouldRefreshClientSideTable(true));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getStepConfig = () => {
    playbookService
      .getStep()
      .then((res: any) => {
        const getStepId = (key: string) => {
          return _.find(res, { key: key }).id;
        };

        stepConfig.current = {
          queryEngine: getStepId(STEP_MATCHED_KEYS.queryEngine),
          dataFormatter: getStepId(STEP_MATCHED_KEYS.dataFormatter),
          connector: getStepId(STEP_MATCHED_KEYS.connector),
        };
      })
      .catch(err => {
        console.error(err);
      });
  };

  const onRunNowPlaybook = (playbook: any) => {
    playbookService
      .runPlaybook(playbook.id)
      .then(res => {
        props.alertSuccess(`Playbook ${playbook.name} run started`);
        void fetchPlaybooks();
      })
      .catch(err => {
        props.alertError(`Run playbook ${playbook.name} failure. ` + JSON.stringify(err));
      });
  };

  const onDeletePlaybook = (playbook: any) => {
    playbookService
      .deletePlaybook(playbook.id)
      .then(res => {
        props.alertSuccess(`Successfully deleted playbook '${playbook.name}'`);
        void fetchPlaybooks();
      })
      .catch(err => {
        props.alertError('Deleting playbook failure, ' + JSON.stringify(err));
      });
  };

  const onModalSummit = (values: any, onSuccess: any, onFailure: any) => {
    const time = parseInt(_.get(values, ['scheduleDropdown', 'time', 'value'], ''), 10);
    const frequency = _.get(values, ['scheduleDropdown', 'frequency', 'value'], '');
    const dateMoment = moment(_.get(values, ['scheduleDropdown', 'date']));
    const timeMoment = moment.utc(time);
    let dateAndTimeMoment = moment();
    let month = '';
    let dayOfMonth = '';
    let dayOfWeek = '';
    let hour = '';
    let minute = '';

    if (frequency === 'hourly') {
      hour = `*/${time}`;
      minute = '0';
    } else {
      const timeFormatString =
        dateMoment.format('YYYY-MM-DD') + 'T' + timeMoment.format('HH:mm:ss.000');
      dateAndTimeMoment = moment(timeFormatString);
      month = '';
      dayOfMonth = '';
      dayOfWeek = '';
      hour = dateAndTimeMoment.format('H');
      minute = dateAndTimeMoment.format('m');
    }

    switch (frequency) {
      case 'hourly':

      case 'daily':
        break;
      case 'weekly':
        dayOfWeek = JSON.stringify(dateAndTimeMoment.day());
        break;
      case 'monthly':
        dayOfMonth = JSON.stringify(dateAndTimeMoment.date());
        break;
      case 'annually':
        dayOfMonth = JSON.stringify(dateAndTimeMoment.date());
        month = JSON.stringify(dateAndTimeMoment.month());
        break;
      case 'weekdays':
        dayOfWeek = '1-5';
        break;
      default:
        break;
    }

    let queryStep, connectorStep, formatterStep;
    if (values.id) {
      queryStep = _.find(values.steps, step => {
        return step.step.key === STEP_MATCHED_KEYS.queryEngine;
      });
      formatterStep = _.find(values.steps, step => {
        return step.step.key === STEP_MATCHED_KEYS.dataFormatter;
      });
      connectorStep = _.find(values.steps, step => {
        return step.step.key === STEP_MATCHED_KEYS.connector;
      });
    }
    const details = {};
    _.forEach(values.connector.fields, field => {
      details[field.key] = field.value;
    });

    const playbookFilters = values.filters || [];
    const excludedFilters = playbookFilters.filter((filter: IFilter) => {
      return !filter.isExcluded;
    });

    const { must, mustNot } = setScanTableFilter(excludedFilters, type);
    _.forEach(values.hiddenFilters, (filter, key) => {
      const filterValue = filter?.value || filter;
      if (key === 'query_strings') {
        must['query_strings'] = filterValue;
      } else {
        if (filter.isMustNot) {
          delete filter.isMustNot;
          mustNot[key] = filterValue;
        } else {
          must[key] = filterValue;
        }
      }
    });

    const dateRange = setDateRangeFilter(
      excludedFilters,
      values.timezone,
      appConstants.CONTENT_TYPE.PLAYBOOK,
    );
    dateRange.playbookStartDate = dateMoment.format();

    const sortBy = {};
    sortBy[values.sortBy.value] = values.sortDirection.value;

    const queryStepId = stepConfig.current.queryEngine;
    const scheduleFormatterStepId = stepConfig.current.dataFormatter;
    const formatterOutputType = values.outputType.value.toUpperCase();
    const scheduleConnectorStepId = stepConfig.current.connector;
    const scheduleConnectorDetails = {
      connectorId: values.connector.value,
    };
    _.forEach(values.connector.fields, ({ key, value }) => {
      scheduleConnectorDetails[key] = value;
    });
    setIsLoading(true);
    const data = {
      schedule: {
        id: values.id || undefined,
        name: values.name,
        description: values.description,
        isInternal: values.internalPlaybook,
        createdBy: values.createdBy,
        isActive: true, //TODO
        second: '0',
        minute,
        hour,
        dayOfMonth,
        month,
        dayOfWeek,
      },
      scheduleQuery: {
        queryObjId: _.get(queryStep, 'id', undefined),
        attributeIds: _.map(values.attributes, attribute => {
          if (attribute) {
            return attribute.id;
          }
        }),
        dataClassId: values.dataAttributes.id || 1,
        queryOrder: queryStepId,
        postProcessor: {
          filterNestedAttributeIds: playbooksFilterIds,
        },
        queryStepId,
        index: values.dataAttributes.index || 'brand_analytics',
        recentChanges: {
          fields: recentChangesMultiOptions?.fields || [],
          dateRange: recentChangesDateRange?.dateRange || '',
        },
      },
      scheduleFormatter: formatterOutputType !== 'JSON' && {
        formatterObj: {
          id: _.get(formatterStep, 'id', undefined),
          details: {
            outputType: formatterOutputType,
          },
          order: scheduleFormatterStepId,
          stepId: scheduleFormatterStepId,
        },
      },
      scheduleConnector: {
        connectorObj: {
          id: _.get(connectorStep, 'id', undefined),
          details: scheduleConnectorDetails,
          order: scheduleConnectorStepId,
          stepId: scheduleConnectorStepId,
        },
      },
      must,
      mustNot,
      sortBy,
      dateRange,
    };
    if (type === appConstants.CONTENT_TYPE.SOCIAL) {
      const queryStringValue = buildFilterQueryString(
        values.filters,
        undefined,
        appConstants.CONTENT_TYPE.SOCIAL_PLAYBOOK,
      );
      data['queryString'] = queryStringValue;
    }
    playbookService
      .updatePlaybook(data, type)
      .then(res => {
        const action = values.id ? 'updated' : 'added';
        props.alertSuccess(`Successfully ${action} playbook '${values.name}'`);
        onSuccess();
      })
      .catch(err => {
        onFailure();
        props.alertError(JSON.stringify(err));
      })
      .finally(() => {
        setIsLoading(false);
        void fetchPlaybooks();
      });
  };

  return (
    <AuthenticationWrapper>
      <PlaybookContextProvider
        value={playbookDefaultValue}
        type={type}
        dataClass={playbookDataClass}
        playbookFilterIds={playbooksFilterIds}
        setCurrentFilterIds={setPlaybooksFilterIds}
      >
        <LoadingWrapper isLoading={isLoading}>
          <div className={'playbooks-page page-content'}>
            {!isLoading && (
              <Card className='playbooks-table-container'>
                {playbooks.length === 0 ? (
                  <PlaybookGetStarted
                    setModalShown={setModalShown}
                    setUseTemplate={setUseTemplate}
                  />
                ) : (
                  <PlaybooksTable
                    playbooks={playbooks}
                    setNewPlaybook={setNewPlaybook}
                    setModalShown={setModalShown}
                    setUseTemplate={setUseTemplate}
                    onRunNowPlaybook={onRunNowPlaybook}
                    onDeletePlaybook={onDeletePlaybook}
                    tableId={props.tableId}
                  />
                )}
              </Card>
            )}
          </div>
        </LoadingWrapper>
        {modalShown && (
          <NewPlaybookModal
            type={type}
            templates={playbookTemplates}
            useTemplate={useTemplate}
            modalShown={modalShown}
            setModalShown={setModalShown}
            newPlaybook={newPlaybook}
            setNewPlaybook={setNewPlaybook}
            onSubmit={onModalSummit}
            dataClass={playbookDataClass}
            removePlaybookFilterId={removePlaybookFilterId}
            addPlaybookFilterIds={addPlaybookFilterIds}
            resetFilterIds={resetFilterIds}
          />
        )}
      </PlaybookContextProvider>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  const { attributes } = state.appReducer;
  return {
    user,
    attributes,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedPlaybooks = connect(mapStateToProps, mapDispatchToProps)(Playbooks);

export { connectedPlaybooks as Playbooks };
