import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  FilterChangedEvent,
  IServerSideSelectionState,
  GridReadyEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { convertToFetchApiParams, getAgGridThemeClassName, sortKeyMap } from './ag-utils';
import { fetchWebTableData } from './ag-requests';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';
import {
  setOutGoingPersistentFilterString,
  setIsPersistentFilterSaveButtonVisible,
} from '../../../../reducers/table.reducer';
import { MALICIOUS_TABLE_IDS, mapTableIdToEnum } from '../../../MonitorAndTakedown/constants';
import { alertActions, dashboardActions } from '../../../../actions';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { generateWebColDefs } from './ag-col-defs';
import { ITableApiColumn } from '../table.api';
import DashboardService from '../../../../services/dashboard.service';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import useOnCustomTagActions from '../../CustomHooks/useOnCustomTagActions';
import useOnTagFilterOptions from '../../CustomHooks/useOnTagFilterOptions';
import { useAgGridEvents } from './useAgGridEvents';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { TABLE_EMPTY_RESULTS_MESSAGE, paginationPageSizeSelector } from './constants';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import useOnAgRowHighlight from './useOnAgRowHighlight';
import { useUpdateTagsFromBulkAction } from './useUpdateTagsFromBulkAction';
import { useOnSelectionChangeEvent } from './useOnSelectionChangeEvent';
import { useAgGridCurrentPageRows } from './useAgGridCurrentPageRows';
import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import useAgGridFilterChange from './useAgGridFilterChange';
import { useNumOfCategoryFltrConditions } from './useNumOfCategoryFltrConditions';
import { setCurrentTableId } from '../../../../reducers/insightsContainer.reducer';
import useOnStoringPaginationControls from '../../CustomHooks/useOnStoringPaginationControls';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const AgGridWebTableFlatView = ({
  columns,
  tableId,
  enableCheckbox = false,
  moduleType,
}: {
  columns: ITableApiColumn[];
  tableId: MALICIOUS_TABLE_IDS;
  enableCheckbox?: boolean;
  moduleType?: string;
}) => {
  const isReadOnlyUser = useReadOnlyUser();
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const agRef = useRef<AgGridReact>(null);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs, onTableSaveColumns } = useContext(TableContext);
  const [totalNumOfNodes, setTotalNumOfNodes] = useState<number>(0);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const dispatch: any = useAppDispatch();
  const { numOfOtherCategories } = useAppSelector(state => state.tableReducer);
  const { savedNumOfCategoryFltrConditions } = useNumOfCategoryFltrConditions({ tableId });
  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({ tableId });

  const colDefs: any = useMemo<ColDef[]>(() => {
    return generateWebColDefs({
      columns,
      savedNumOfCategoryFltrConditions,
      firstRenderDisplayColumnIdSet,
      enableCheckbox: enableCheckbox && !isReadOnlyUser,
      moduleType,
    });
    // We have to add displayedColumnsIDs here, to update colDefs with latest visible columns
  }, [columns, displayedColumnsIDs, isReadOnlyUser, savedNumOfCategoryFltrConditions]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      checkboxSelection: enableCheckbox,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, [enableCheckbox]);

  const isFilterModelCacheUsed = useRef(false);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  const { handlePaginationControls } = useOnStoringPaginationControls({ tableId });

  const { getRowClass } = useOnAgRowHighlight();

  const dashboardService = useMemo(() => new DashboardService(), []);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };

        try {
          const apiParams = convertToFetchApiParams(agRequest);
          const { query, sortBy } = apiParams as any;
          const pageNumber = params.api?.paginationGetCurrentPage?.() || 0;
          const pageSize = params.api?.paginationGetPageSize?.();

          query['type'] = tableId;
          query['pageNumber'] = pageNumber;
          query['pageSize'] = pageSize;
          if (Object.keys(sortBy).length > 1) {
            delete sortBy['first_seen_ts'];
          }
          setLocalStorageValue(['tableApiParams', tableId], apiParams);

          const rawResponse = await fetchWebTableData(apiParams, false);
          response.data = rawResponse.result?.urlInfo?.map((item: any) => {
            return dashboardService.massageDataCommon(item, mapTableIdToEnum[tableId]);
          });
          response.total = rawResponse.result?.total;

          // Store the current table settings for insights pagination
          handlePaginationControls(pageNumber, pageSize, response.total as number, response.data);
          setLocalStorageValue(['currentTableId'], tableId); // set the current table id

          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }
          setAllRowData(response.data);
          setTotalNumOfNodes(response.total as number);
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: response.total,
            });
          }, 0);
        } catch (error) {
          console.log('Error in fetching ugc :', error);
          params.fail();
        }
      },
    };
  }, [dashboardService, dispatch, tableId]);

  const { isFilterFromDashboard } = useAppSelector(state => state.tableReducer);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      // first render, only initiate filter model from local storage.
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource],
  );

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data._id}-${params.data.primary_domain}-${params.data.domain_sha256}-${params.data.url_sha256}`;
  }, []);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData,
  });

  useEffect(() => {
    setLocalStorageValue(['savedSelectionState', tableId], []);
  }, []);

  const {
    handleFirstDataRendered,
    handleOnPaginationChanged,
    currentPageNumber,
    currentPaginationSize,
  } = usePersistentTablePageNumber(tableId);

  const { currentRowsOfPage } = useAgGridCurrentPageRows({
    agRef,
    allRowData,
    currentPageNumber,
    currentPaginationSize,
  });

  const { handleOnSelectionChange } = useOnSelectionChangeEvent({
    agRef,
    tableId,
    allRowData,
    moduleType,
    currentRowsOfPage,
    currentPageNumber,
    currentPaginationSize,
  });

  const { updateTagsFromBulkAction } = useUpdateTagsFromBulkAction({ agRef });

  useEffect(() => {
    updateTagsFromBulkAction();
  }, [updateTagsFromBulkAction]);

  const { handleUGCModuleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(dashboardActions.setSelectedWebUrls(tableId, []));
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
      setLocalStorageValue(['savedSelectionState', tableId], []);
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  //Custom hooks for triggering fetch call on custom tag action
  useOnCustomTagActions(agRef);

  //custom hook to add new custom tag filter options
  useOnTagFilterOptions(agRef, columns, 'tags', moduleType);

  //use to refresh the table when the triggerAgGridRefresh is true
  useOnAgGridRefresh(agRef);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={paginationPageSizeSelector}
        cacheBlockSize={currentPaginationSize}
        blockLoadDebounceMillis={300}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        rowSelection={'multiple'}
        onSelectionChanged={handleOnSelectionChange}
        onFilterChanged={handleUGCModuleFilterChanged}
        suppressRowClickSelection={true}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        getRowClass={getRowClass}
        suppressRowVirtualisation={true}
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridWebTableFlatView;
