import React, { useCallback } from 'react';
import {
  IGroupedPaginationControls,
  IPaginationControls,
  setGroupedPaginationControls,
  setPaginationControls,
} from '../../../reducers/insightsContainer.reducer';
import { useAppDispatch } from '../../../helpers/hooks';
import { getLocalStorageValue, setLocalStorageValue } from '../../../constants';
import {
  FINDINGS_TABLE_IDS,
  GROUPED_MALICIOUS_TABLE_IDS,
  MALICIOUS_TABLE_IDS,
} from '../../MonitorAndTakedown/constants';
import _ from 'lodash';
import { BULK_SCAN_TABLE_ID } from '../../BulkScan/BulkScanConstants';
import moment from 'moment';

export default function useOnStoringPaginationControls({
  tableId,
}: {
  tableId:
    | MALICIOUS_TABLE_IDS
    | FINDINGS_TABLE_IDS
    | typeof BULK_SCAN_TABLE_ID
    | GROUPED_MALICIOUS_TABLE_IDS;
}) {
  const dispatch = useAppDispatch();

  const handlePaginationControls = useCallback(
    (pageNumber: number, pageSize: number, totalDataCount: number, allFindings: any[]) => {
      const paginationControls: IPaginationControls = {};
      const allUniqShas = allFindings.map((item: any) => {
        return {
          urlSha256: item.url_sha256 || item.urlSha256 || item.sha256,
          timestamp: item.timestamp || moment(item.createdTs).valueOf(),
        };
      });

      paginationControls[tableId] = {
        currentPage: pageNumber,
        pageSize: pageSize,
        totalItems: totalDataCount as number,
        tableId: tableId,
        totalPages: Math.ceil((totalDataCount as number) / pageSize),
        urlShaAndTimestamps: allUniqShas,
      };
      dispatch(setPaginationControls(paginationControls));
      setLocalStorageValue(['paginationControls', tableId], paginationControls[tableId]);
    },
    [dispatch, tableId],
  );

  const handleGroupedPaginationControls = useCallback(
    (
      pageNumber: number,
      pageSize: number,
      totalDataCount: number,
      allFindings: any[],
      groupedKey: string,
    ) => {
      const paginationControls: IGroupedPaginationControls = {};

      const groupedPaginationControls = getLocalStorageValue('groupedPaginationControls');

      const allUniqShas = allFindings.map((item: any) => {
        return {
          urlSha256: item.url_sha256 || item.urlSha256,
          timestamp: item.timestamp,
        };
      });

      const activeTableId = tableId;
      paginationControls[activeTableId] = {
        ...groupedPaginationControls[activeTableId],
        [groupedKey]: {
          currentPage: pageNumber,
          pageSize: pageSize,
          totalItems: totalDataCount as number,
          tableId: tableId,
          totalPages: Math.ceil((totalDataCount as number) / pageSize),
          urlShaAndTimestamps: allUniqShas,
        },
      };
      dispatch(setGroupedPaginationControls(paginationControls));
      setLocalStorageValue(
        ['groupedPaginationControls', activeTableId],
        paginationControls[activeTableId],
      );
    },
    [dispatch, tableId],
  );

  return { handlePaginationControls, handleGroupedPaginationControls };
}
