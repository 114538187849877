import { DashboardData } from '../constants';

export const dashboardMockData: DashboardData = {
  emailCategories: {
    '[Account] Targeted Malicious': 345,
    'Generic Malicious': 67,
    '[Account] Official Emails': 127,
    Other: 1628,
  },
  emailDisposition: {
    malicious: 15,
    phishing: 10,
    scam: 5,
  },
  emailSubmissions: {
    total: 1728,
    users: 1100,
    trendsChart: {
      '2024-07-01': {
        total: 2,
        malicious: 1,
      },
      '2024-07-08': {
        total: 3,
        malicious: 2,
      },
      '2024-07-15': {
        total: 4,
        malicious: 3,
      },
      '2024-07-22': {
        total: 5,
        malicious: 4,
      },
      '2024-08-01': {
        total: 6,
        malicious: 3,
      },
      '2024-08-08': {
        total: 7,
        malicious: 4,
      },
      '2024-08-15': {
        total: 8,
        malicious: 5,
      },
    },
  },
  threatDistribution: {
    'Phishing Links': {
      email: 60,
      sms: 60,
    },
    'Scam Links': {
      email: 39,
      sms: 20,
    },
    'Typosquat Sender Domains': {
      email: 12,
      sms: 10,
    },
    'Malicious Intent': {
      email: 10,
      sms: 6,
    },
    'Malware Attachments': {
      email: 69,
      sms: 20,
    },
  },
  urlThreatBreakdown: {
    'Email Inline': 64,
    'Email QR Code': 30,
    'Email Image': 22,
    'Email Documents': 21,
    'SMS QR Code': 7,
    'SMS Inline': 3,
  },
  threatSourcesBreakdown: {
    maliciousUrl: {
      'Email Inline': 64,
      'Email QR Code': 30,
      'Email Image': 22,
      'Email Documents': 21,
    },
    maliciousPhoneNumber: {
      'SMS QR Code': 7,
      'SMS Inline': 3,
    },
  },
  userDomains: {
    'comcast.net': 6723,
    'yahoo.com': 4678,
    'gmail.com': 3890,
    'aol.com': 2910,
    'hotmail.com': 2289,
    'charter.net': 834,
    'version.net': 482,
    'outlook.com': 226,
    'icloud.com': 189,
    'msn.com': 96,
    'outlook.uk.com': 85,
    'planet.nl': 76,
  },
  threatActorDomains: {
    'comcast.net': 5236,
    'yahoo.com': 3567,
    'gmail.com': 3456,
    'aol.com': 7860,
    'hotmail.com': 3423,
    'charter.net': 657,
    'version.net': 345,
    'outlook.com': 212,
    'icloud.com': 223,
    'msn.com': 56,
    'outlook.uk.com': 24,
    'plane.nl': 24,
  },
  threatActorDomainsV2: {
    'comcast.net': {
      count: 5236,
      type: 'malicious',
    },
    'yahoo.net': {
      count: 5236,
      type: 'malicious',
    },
    'gmail.net': {
      count: 5236,
      type: 'malicious',
    },
    'aol.net': {
      count: 5236,
      type: 'malicious',
    },
    'hotmail.net': {
      count: 5236,
      type: 'malicious',
    },
    'charter.net': {
      count: 5236,
      type: 'malicious',
    },
  },
  takedownTimeAnalytics: {
    phish: {
      avg: '5 hours',
      median: '12.2 hours',
    },
    scam: {
      avg: '6 hours',
      median: '10.2 hours',
    },
    maliciousSenderDomain: {
      avg: '7 hours',
      median: '14.2 hours',
    },
  },
  topSuspiciousThreats: {
    phish: [
      {
        threatType: 'URL',
        disposition: 'phish',
        sourceUrl: 'http://example1.com',
        sha256: '60d7a960a631f39b81b716d3c4d9481ef5a85274c9ba7c91a4dbb8c06c53e1af',
        timestamp: '1713992655163',
        count: 200,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Linode, LLC',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'active',
      },
      {
        threatType: 'URL',
        disposition: 'phish',
        sourceUrl: 'http://example2.com',
        sha256: 'af8333a389b1027b884bb85b4781d86b677842dc3ca3e05e18480395d9bc18a0',
        timestamp: '1713992655163',
        count: 167,
        threatSubType: 'url-inline',
        hostingProvider: 'Go-Daddy, LLC',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'in_progress',
      },
      {
        threatType: 'URL',
        disposition: 'phish',
        sourceUrl: 'http://example3.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 80,
        threatSubType: 'url-inline',
        hostingProvider: 'Cloudflare',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'taken_down',
      },
      {
        threatType: 'URL',
        disposition: 'phish',
        sourceUrl: 'http://example4.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 50,
        threatSubType: 'url-qrcode',
        hostingProvider: 'NameCheap',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'in_progress',
      },
      {
        threatType: 'URL',
        disposition: 'phish',
        sourceUrl: 'http://example5.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 49,
        threatSubType: 'url-document',
        hostingProvider: 'Amazon.com',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'active',
      },
    ],
    scam: [
      {
        threatType: 'URL',
        disposition: 'scam',
        sourceUrl: 'http://example1.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 200,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Linode, LLC',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'Request TakeDown',
      },
      {
        threatType: 'URL',
        disposition: 'scam',
        sourceUrl: 'http://example2.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 147,
        threatSubType: 'url-inline',
        hostingProvider: 'Go-Daddy, LLC',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'In Progress',
      },
      {
        threatType: 'URL',
        disposition: 'scam',
        sourceUrl: 'http://example3.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 60,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Cloudflare',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'In Progress',
      },
      {
        threatType: 'URL',
        disposition: 'scam',
        sourceUrl: 'http://example4.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 40,
        threatSubType: 'url-document',
        hostingProvider: 'NameCheap',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'In Progress',
      },
      {
        threatType: 'URL',
        disposition: 'scam',
        sourceUrl: 'http://example5.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 20,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Amazon.com',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'TakenDown',
      },
    ],
    spam: [],
    senderDomain: [
      {
        threatType: 'senderDomain',
        disposition: 'typosquat',
        sourceUrl: 'http://example1.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 200,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Linode, LLC',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'active',
      },
      {
        threatType: 'senderDomain',
        disposition: 'typosquat',
        sourceUrl: 'http://example1.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 120,
        threatSubType: 'url-inline',
        hostingProvider: 'Go-Daddy, LLC',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'in_progress',
      },
      {
        threatType: 'senderDomain',
        disposition: 'typosquat',
        sourceUrl: 'http://example1.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 60,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Cloudflare',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'in_progress',
      },
      {
        threatType: 'senderDomain',
        disposition: 'typosquat',
        sourceUrl: 'http://example1.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 50,
        threatSubType: 'url-document',
        hostingProvider: 'NameCheap',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'taken_down',
      },
      {
        threatType: 'senderDomain',
        disposition: 'typosquat',
        sourceUrl: 'http://example1.com',
        sha256: '8911a9dff13c74debe50a0c5cccbcecd96284d40ac1dc64a77d36c6ec1782596',
        timestamp: '1713992655163',
        count: 30,
        threatSubType: 'url-qrcode',
        hostingProvider: 'Amazon.com',
        hostingCountry: 'US',
        firstSeen: '06-12-2024',
        status: 'active',
      },
    ],
    phoneNumber: [],
  },
  needReview: 20,
  emptyState: true,
  abuseMailBoxId: 'example@bolster.ai',
};
