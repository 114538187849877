import fetchApi from '../../services/api.service';
import { setUrlScanTableFilter } from '../../services/checkphish.service';
import { setScanTableSort } from '../../services/dashboard.service';
import { handleResponse } from '../../services/serviceWorker';
import { SCANTYPE } from '../Checkphish/AllScans/scanType.enum';
import { IFilter } from '../Common/Table/constant';
import { ICountryCodeTlds } from './ScanLocationInfo.poc';

export interface IScanLocationTypes {
  locationCode: string;
  locationName: string;
  countryCode: string;
  continentCode: string;
  continent: IContinentTypes;
}

export interface IContinentTypes {
  continentCode: string;
  continentName: string;
}

export type IScanLocationWithoutContinentCode = Omit<IScanLocationTypes, 'continentCode'>;

export const fetchBulkScanData = async (
  type: SCANTYPE,
  query: any,
  filters: IFilter[],
  sort: any,
) => {
  const { startDate, endDate, pageNumber, pageSize } = query;
  const { must, mustNot } = setUrlScanTableFilter(filters);
  const sortBy = setScanTableSort(sort);
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: {
        type,
        startDate,
        endDate,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        pageNumber,
        pageSize,
      },
      must,
      mustNot,
      sortBy,
    }),
  };
  return fetchApi('/platform-api/v1/live-scan/get-scan-data', requestOptions).then(handleResponse);
};

export const getBrandScanPreference = async () => {
  return fetchApi('/platform-api/v1/brand/scan-preference', {
    credentials: 'include',
  }).then(handleResponse);
};

export const getScanLocations = async (): Promise<IScanLocationWithoutContinentCode[]> => {
  return fetchApi('/platform-api/v1/bulkscan/scan-location', {
    credentials: 'include',
  }).then(handleResponse);
};

export const getPremiumScanLocations = async (): Promise<IScanLocationTypes[]> => {
  return fetchApi('/platform-api/v1/bulkscan/premium-scan-location', {
    credentials: 'include',
  }).then(handleResponse);
};

export const getCountryCodeTlds = async (): Promise<ICountryCodeTlds> => {
  return fetchApi('/platform-api/v1/bulkscan/country-code-tlds', {
    credentials: 'include',
  }).then(handleResponse);
};
