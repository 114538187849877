import React, { useEffect, useState } from 'react';
import { getColors } from '../../../../constants';
import { IDonutGraphType } from '../../types';
import '../../Styles/DonutGraph.scss';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

interface IDonutGraph {
  innerLable: number;
  donutLabel: string;
}

export type Props = IDonutGraph & IDonutGraphType;

const DonutGraph = ({ dataSet, innerLable, showGradient, donutLabel }: Props) => {
  const [series, setSeries] = useState<any[]>([]);
  const [labels, setLables] = useState<any[]>([]);
  const [, selectedTheme] = useIsDarkMode();
  const { darkWebHighRisk, darkWebLowRisk, darkWebMediumRisk } = getColors(selectedTheme);

  useEffect(() => {
    const series = dataSet.map((item: any) => item.percentage);
    const labels = ['High', 'Medium', 'Low'];
    setSeries(series);
    setLables(labels);
  }, [dataSet]);

  const genrateOptions = () => {
    const options: ApexOptions = {
      labels: [...labels],
      legend: { show: false },
      colors: [darkWebHighRisk, darkWebMediumRisk, darkWebLowRisk],
      chart: { height: '50', width: '50' },
      dataLabels: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            value: 0.35,
            type: 'lighten',
          },
        },
        active: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '96%',
            background: 'transparent',
            labels: {
              show: false,
              name: { show: false },
              total: {
                show: false,
                showAlways: false,
                formatter: function (w) {
                  const totals = w.globals.seriesTotals;
                  const result = totals.reduce((a: any, b: any) => a + b, 0);
                  return (result / 1000).toFixed(3);
                },
              },
            },
          },
        },
      },
      stroke: {
        width: 0,
        colors: ['transparent'],
      },
      tooltip: {
        custom: (tooltipData: any) => {
          const { seriesIndex, series } = tooltipData;
          return (
            `<div style="background: transparent; padding:4px 8px;">` +
            `Risk Level: ${labels[seriesIndex]} ${series[seriesIndex]}%</b>` +
            '</div>'
          );
        },
      },
    };
    return options;
  };

  return (
    <div className='donut'>
      <div className='heading' role='heading' aria-level={2}>
        {donutLabel}
      </div>
      {showGradient || _.isEmpty(series) ? (
        <div className='outer-circle' data-testid={'gradient-circle'}>
          <div className='inner-circle'>
            <div className='data-container-circle' data-testid={'inner-circle'}>
              {innerLable}
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper' data-testid={'no-gradient-circle'}>
          <ReactApexChart options={genrateOptions()} series={series} type='donut' height={205} />
          <div className='inner-circle'>
            <div className='data-container-circle' data-testid={'inner-circle'}>
              {innerLable}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonutGraph;
