import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserService from '../../services/user.service';
import { connect } from 'react-redux';
import { AppState } from '../../helpers';
import { userActions } from '../../actions';
import { PASSWORD_LENGTH, PasswordEye } from '../Common/PasswordEye';
import './auth.scss';
import { CompanyIcon } from '../Common/CompanyIcon';
import Spinner from 'react-bootstrap/Spinner';

interface RouteParams {
  token: string;
}

interface IConfirmRegistrationState {
  user: {
    tempPassword: string;
    newPassword: string;
    token: string;
  };
  submitted: boolean;
  isLoading: boolean;
  idx: number;
}

type Props = RouteComponentProps<RouteParams> & ILinkDispatchProps;

class ConfirmRegistration extends React.Component<Props, IConfirmRegistrationState> {
  private readonly userdService: UserService;
  private tempPasswordDom: any = '';
  private newPasswordDom: any = '';
  private errMsg: any = [
    '',
    'Temporary or new password should not be empty.',
    'Please enter a password with ' + PASSWORD_LENGTH + ' or more characters.',
    'Invalid temporary password. Please contact support@bolster.ai.',
  ];
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        tempPassword: '',
        newPassword: '',
        token: '',
      },
      submitted: false,
      isLoading: false,
      idx: 0,
    };
    this.userdService = new UserService();
    window.document.title = 'Confirm Registration | Bolster Platform';
  }

  componentDidMount() {
    this.setState({
      user: {
        ...this.state.user,
        token: this.props.match.params.token,
      },
    });
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const {
      user: { tempPassword, newPassword, token },
    } = this.state;
    const submittedUser = {
      tempPassword,
      newPassword,
      token,
    };
    if (tempPassword === '' || newPassword === '') {
      this.setState({ submitted: true, idx: 1 });
    } else if (newPassword.length < PASSWORD_LENGTH) {
      this.setState({ submitted: true, idx: 2 });
    } else if (tempPassword && newPassword && token) {
      // Send to the user service to submit to backend
      this.props
        .confirmRegistration(submittedUser)
        .then((res: any) => {
          if (!res['result']) {
            this.setState({
              submitted: true,
              idx: 3,
            });
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  render() {
    const { submitted, isLoading, idx } = this.state;
    return (
      <div>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <div className={'auth-page-wrapper'}>
          <form name='form' className={'change-password-wrapper'} onSubmit={this.handleSubmit}>
            <h3>Welcome to Bolster</h3>
            <div className='confirm-nav-text'>
              Please input the temporary password from the email and create a new password to sign
              in.
            </div>
            <PasswordEye
              isError={idx === 1 || idx === 3}
              label={'Temporary Password'}
              name={'tempPassword'}
              onChange={this.handleChange}
            />
            {(idx === 1 || idx === 3) && (
              <div className={'help-block' + (submitted ? '' : ' hidden')}>{this.errMsg[idx]}</div>
            )}

            <PasswordEye
              isError={idx === 2}
              label={'New Password'}
              name={'newPassword'}
              onChange={this.handleChange}
            />
            {idx === 2 && (
              <div className={'help-block' + (submitted ? '' : ' hidden')}>{this.errMsg[idx]}</div>
            )}
            <div className={'helper-bottom'}>
              <button type='submit' className='btn btn-primary' disabled={isLoading}>
                {isLoading ? (
                  <Spinner className='spinner' animation='border' variant='light' size='sm' />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

interface ILinkDispatchProps {
  confirmRegistration: (user: object) => any;
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  confirmRegistration: userActions.confirmRegistration,
};

const connectedConfirmRegistration = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmRegistration);
export { connectedConfirmRegistration as ConfirmRegistration };
