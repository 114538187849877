import { useCallback } from 'react';
import { alertActions } from '../../actions';
import { useAppDispatch } from '../../helpers/hooks';
import {
  AdvancedFilterParams,
  transformAdvancedSearchOptionsToParams,
} from './AdvancedFiltersUtils';
import { ESelectedFilterTypeOptions } from './GlobalSearchConstants';
import { fetchAdvancedFilterFindingsAPI } from './global-search-requests';

interface handleAdvancedSearchProps {
  searchValue: string;
  selectedOptions: any;
  setSelectFilterType: React.Dispatch<React.SetStateAction<ESelectedFilterTypeOptions>>;
  setGlobalSearchFindingData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdvancedFilterParams: React.Dispatch<React.SetStateAction<AdvancedFilterParams | undefined>>;
}

const useOnFetchAdvancedFilterFindings = ({
  searchValue,
  selectedOptions,
  setSelectFilterType,
  setGlobalSearchFindingData,
  setIsLoading,
  setAdvancedFilterParams,
}: handleAdvancedSearchProps) => {
  const dispatch = useAppDispatch();

  const fetchAdvancedFilterFindings = useCallback(async () => {
    const transformedAdvancedSearchOptions = transformAdvancedSearchOptionsToParams(
      searchValue,
      selectedOptions,
    );
    const { fieldName, ...advancedSearchParams } = transformedAdvancedSearchOptions;
    setAdvancedFilterParams?.(advancedSearchParams);
    setSelectFilterType(transformedAdvancedSearchOptions?.fieldName as ESelectedFilterTypeOptions);
    setGlobalSearchFindingData({});
    try {
      setIsLoading(true);
      const result = await fetchAdvancedFilterFindingsAPI(advancedSearchParams);
      setGlobalSearchFindingData(result);
    } catch (error) {
      console.log('Error fetching global search findings:', error);
      dispatch(alertActions.error('Something went wrong while fetching global search findings'));
    } finally {
      setIsLoading(false);
    }
  }, [
    searchValue,
    selectedOptions,
    setAdvancedFilterParams,
    setSelectFilterType,
    setIsLoading,
    setGlobalSearchFindingData,
    dispatch,
  ]);

  return { fetchAdvancedFilterFindings };
};

export default useOnFetchAdvancedFilterFindings;
