import { Drawer } from '@mui/material';
import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import DataLeakDetails from './DataLeakDetails';
import {
  setCurrentDataLeakDescription,
  setCurrentDataLeakEntityType,
  setCurrentDataLeakParams,
  setShouldShowDataLeakTablePopup,
} from '../../../../reducers/darkWeb.reducer';
import { useOnClickOutside } from '../../../Common/CustomHooks/useOnClickOutside';

export default function SingleFindingDataLeakPopup() {
  const drawerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const shouldShowDataLeakTablePopup = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakTablePopup,
  );

  const closeDrawer = () => {
    dispatch(setShouldShowDataLeakTablePopup(false));
    dispatch(setCurrentDataLeakParams(''));
    dispatch(setCurrentDataLeakEntityType(''));
    dispatch(setCurrentDataLeakDescription(''));
  };

  useOnClickOutside(drawerRef, closeDrawer);
  return (
    <Drawer hideBackdrop open={shouldShowDataLeakTablePopup} anchor='right' variant='persistent'>
      <div style={{ marginTop: '40px' }} data-testid='data-leak-details-drawer' ref={drawerRef}>
        <DataLeakDetails />
      </div>
    </Drawer>
  );
}
