import React, { useMemo } from 'react';
import './CriticalAlertWidget.scss';
import _ from 'lodash';

export interface ICriticalAlertWidget {
  criticalAlerts: any[];
  icon: any;
  onClick: any;
}

const CriticalAlertWidget = ({ criticalAlerts, icon, onClick }: ICriticalAlertWidget) => {
  const data = useMemo(() => {
    if (_.isEmpty(criticalAlerts)) return 0;
    return Object.keys(criticalAlerts).length;
  }, [criticalAlerts]);
  return (
    <div className='critical-alerts-container' data-testid='critical-alerts-continer'>
      <div
        className={`critical-alerts ${data ? 'alert-red' : 'alert-blue'}`}
        data-testid={data ? 'alert-red' : 'alert-blue'}
      >
        <div className='critical-header'>Critical</div>
        <div className='critical-count'>{data}</div>
        <div className='content-wrap'>
          <div className='critical-content-header'>{data ? 'New Phish and Scam' : 'Good Job!'}</div>
          <div className='d-flex no-padding '>
            <div className='critical-content'>
              {data
                ? `Your takedown
              request is required`
                : 'No new phish and scam, no takedown request is required'}
            </div>
            {data ? <img src={icon} alt='chevron' onClick={onClick} /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriticalAlertWidget;
