export const res = {
  emailDetails: [
    {
      id: 'bankofbolster_dd17ktivut2i3vs56uri7l4m9alg926u8pfspsg1',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:34:38.000Z',
      lastScannedDate: '2024-11-06T22:34:40.696Z',
      disposition: 'Malicious',
      subject: 'Fw: Your transfer of 0.2 ETH on the Ethereum network is being processed.',
      contentAnalysis: {
        tone: 'mundane',
        intentsIdentified: ['purchase notification', 'renewal', 'finance'],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '4f5b55ecaa4fec9f8af62b718272ef743239e15fd90d0bfdcb081dfbbeeb946e',
        senderDomain: 'civilmindschool.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [],
        urlCounts: 0,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/1.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 808 289 8192',
            initialDisposition: 'Clean',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_oq1o6bg4ic1umbge2lrmhlb6p8lo822uenh72jo1',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:34:19.000Z',
      lastScannedDate: '2024-11-06T22:35:14.404Z',
      disposition: 'Malicious',
      subject: "FW: Let's breathe new life into your listing (No pressure, just results!)",
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: [
          'update account',
          'tech support',
          'payment information',
          'personal information',
          'verification',
          'finance',
        ],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '44e396b5e686a5594e6594c2823235ae511ae9068c9d5decb3b50598c5855da0',
        senderDomain: 'consumer.lindalogistics.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '0ab21729209518726e88542468f102b5e3db2ed4d3b72389bc4e8ff575180a40',
            name: 'https://bukuniver.edu.ua/ea2pt.php?217041#4a855e252c6aaef09d1c8e9131d159ba',
            currentDisposition: 'Spam',
            initialDisposition: 'Spam',
            status: 'active',
          },
        ],
        urlCounts: 1,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/2.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '1-844-505-2993',
            initialDisposition: 'Malicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_r56vpii3vu2rn3dae4obfchbaqrhh56406o26781',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:32:51.000Z',
      lastScannedDate: '2024-11-06T22:32:52.892Z',
      disposition: 'Clean',
      subject: 'Fwd: Your Order Has Been Received. Your E-Invoice 243887375501',
      contentAnalysis: {
        tone: 'mundane',
        language: 'es',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
        source: [],
      },
      senderDomainAnalysis: {
        sha256: '8ab7685e5875a7f431fc28472e2252d8d5a46f7664ec3f87a59e64a1f139e819',
        senderDomain: 'account.bankofbolster.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'fbc1caf4f2c1823944e9d3e9f8f7644d9ca5f599d97e18475ac4d44198c939a4',
            name: 'https://www.bankofbolster.com/notificationsettings/email?g=cbd3d66c-20a9-4c30-8c96-6ee160c676e4&lkid=URL_COMM_SETTINGS&lnktrk=EVO&id=BQE0AAEBEM8ersTCf%2F9QH23Ow5fi7JaAkLshXGmL5O1AuwsWcTF0L5uyUluXzJIq2s6NtiKX94YF29VEfLRyni0DJ23%2BYbU6zuWSO%2B8nsZL2N6qwJkiW4EFHH1p4p13N2fMfgoCq%2F2UDTe0xzYde4JAtr7JWTmzUXi%2BohY4Zqh3PJ7Km03bVZibPbQv6d29L4i3fXP%2Fx6g%2FxpD1nsLy31gmLvpnT2m6vUw%3D%3D&mid=none',
            currentDisposition: 'Spam',
            initialDisposition: 'Spam',
            status: 'active',
          },
          {
            sha256: '7a6e532c81a3919cbff68870f3f7119f666a74e1956a9f15a53987d8006b76f8',
            name: 'https://help.bankofbolster.com/contactus?g=cbd3d66c-20a9-4c30-8c96-6ee160c676e4&lkid=URL_CONTACT&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '61f9aecbd3964bdbe095fdd7bab029ad30ed0f7b2f1fd7e582fe47c57d7ec418',
            name: 'https://www.bankofbolster.com/YourAccount?g=cbd3d66c-20a9-4c30-8c96-6ee160c676e4&lkid=URL_CTA&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '3b446369cbcce7f9d0b2cf4c1f4bc036d465c16b3e4af8c43923a411190b4c51',
            name: 'https://help.bankofbolster.com/support/122698?g=cbd3d66c-20a9-4c30-8c96-6ee160c676e4&lkid=URL_HELP_PT&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 4,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/3.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 900 759 106',
            initialDisposition: 'Suspicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_5dpc0j0v385cncs6r45qimsdo4g8u0qiptgf7j81',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:31:22.000Z',
      lastScannedDate: '2024-11-06T22:31:23.890Z',
      disposition: 'Suspicious',
      subject: 'Fw: Saviiings|$300-Off your purchase of any.Enduranceee.AutoProtectiiion.Plan',
      contentAnalysis: {
        tone: 'mundane',
        intentsIdentified: ['promotion offer'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '950e6500956437e2aa282d597680a41e4d456b491d0a7c85958a78b83dbb9219',
            name: 'https://www.dominos.com/rewards/?utm_source=Rewards&utm_medium=EMAIL&utm_campaign=LSO&utm_agy=HS2&utm_content=SF241106,GEN,,MixAndMatch,27285&storenumber=&fcode=',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '797a3099466c6e589aba277f82e7647b50c54565a933337eb43031e2c609fce5',
            name: 'https://click.e-offers.dominos.com/u/?qs=f359bd7b9d97afefa52c2b22fe67cbacd86fe77730f733e8532bce2c1e60f51977c5c7e80a27fd6dac8a195bc5f091799f350ef31972c7ef170cf1da905b7f50',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'fbf736be6b69ce1fe577e5a568a336010e0eedc3f208d9a54729fbc6056ea6e2',
            name: 'https://click.e-offers.dominos.com/u/?qs=f359bd7b9d97afefae688c01d0fb09acdb79f209cddd5037f2a631b420c3882f9958756b8be1be313ce3e38431d35b903afdcf8b1b77431869adfc36bbcaf9939e46014cbee42f16',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'baa96dc114bc7802d56b96fc18fd19a1046d8f7d4ee83ef834f9e0dd8437fb86',
            name: 'https://view.e-offers.dominos.com/?qs=4946b45506a62c925854e1461c4ca9abb4b8080371410ea36e7bd54beb37f3fcf0909c628f77ec5bd15adad933d5deb6f252184af77436f507aaf3bba33f01d85e846aa5adc00e42',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '2731396fdad21a872bafa15ffa1a79cea9a936705b28c84b0ecfee960abf6068',
            name: 'https://view.e-offers.dominos.com/?qs=4946b45506a62c925854e1461c4ca9abb4b8080371410ea36e7bd54beb37f3fcf0909c628f77ec5bd15adad933d5deb6f252184af77436f5b64cfea623d3b0862a89bbf5f25529d2',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '0f760dfcddb510f180629579e2654bb77dc1ee0da77aa6935bd078d436d386a6',
            name: 'https://www.dominos.com/en/pages/content/opt-in/opt-in?utm_source=OptIn&utm_medium=EMAIL&utm_campaign=LSO&utm_agy=HS2&utm_content=SF241106,GEN,,MixAndMatch,27285&storenumber=&fcode=',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 6,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/5.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_dgku2tqeliiknsd5b4ps35ck7vf73vdcu4ibf281',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:31:07.000Z',
      lastScannedDate: '2024-11-06T22:31:09.342Z',
      disposition: 'Suspicious',
      subject: 'Fwd: hey',
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: ['renewal', 'donation'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '3067e65d91422c7f9ba76be1beda4ff935f72b6a13ac53a854dccd010c463484',
            name: 'https://link.aclu.org/click/37335516.348773/aHR0cHM6Ly9hY3Rpb24uYWNsdS5vcmcvZ2l2ZS9hY2x1LWZyZWVkb20tZGVmZW5zZS1mdW5kP2NpZD03MDFVVzAwMDAwTndzVndZQUomaW5pdG1zX2FmZj1uYXQmaW5pdG1zX2NoYW49ZW1sJnV0bV9tZWRpdW09ZW1sJmluaXRtcz1kZC1uYS1zYWlsLW1lbWJlcnMtbmF0LTI0MTEwNl90cnVtcHdpbl9hcHBlYWwyLWNpdmlsbGliZXJ0aWVzLTIwMjRlbGVjdGlvbi1uYSZ1dG1fc291cmNlPXNhaWwmdXRtX2NhbXBhaWduPW5hJnV0bV9jb250ZW50PWRkLW5hLXNhaWwtbWVtYmVycy1uYXQtMjQxMTA2X3RydW1wd2luX2FwcGVhbDItY2l2aWxsaWJlcnRpZXMtMjAyNGVsZWN0aW9uLW5hJmFmPW9VbzRtcVo1SEVsSSUyQnljUmFvbGxIcnJ0MVc2eDMlMkYlMkZJRFF0SUpTeUk2bWYlMkJwYWdaQ2FUJTJCSENITkJLeDk1anRMZWhWJTJGZU9zdWsySzlwaWYlMkJmNVFzY2NnN0FLMEVKTWZzODZuQTZ2M3duem9lZmklMkZtNU9FVHFMbGh1SjREd01YVFlqSUZhQ0hiSlQlMkI4WjZKJTJGY25oY3RqbSUyRkRHcHl2MzR1eWVhMlF5OWszRUElM0QmZ3M9bmtHa0VIUnB0aUMxbzZBTEpFUTdJM3hkb0VmazB0U0paQnpzUjd4QXU5YnFZSDRuOElzRFowbG5MWFJyekdVVyZtc19hZmY9bmF0Jm1zX2NoYW49ZW1sJm1zPWRkLW5hLXNhaWwtbWVtYmVycy1uYXQtMjQxMTA2X3RydW1wd2luX2FwcGVhbDItY2l2aWxsaWJlcnRpZXMtMjAyNGVsZWN0aW9uLW5hJmluaXRfc3RlcD0wJnJlY3Vyc19tb250aGx5PXJlY3Vycw/623fe157df6a5c4b9f930f7fC2cd07151',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'c1a4d24d6a6c841098bec0182b36c4037478375692e38aacc8f7b9d967bf78ef',
            name: 'https://link.aclu.org/click/37335516.348773/aHR0cHM6Ly9hY3Rpb24uYWNsdS5vcmcvZ2l2ZS9hY2x1LWZyZWVkb20tZGVmZW5zZS1mdW5kP2NpZD03MDFVVzAwMDAwTndzVndZQUomaW5pdG1zX2FmZj1uYXQmaW5pdG1zX2NoYW49ZW1sJnV0bV9tZWRpdW09ZW1sJmluaXRtcz1kZC1uYS1zYWlsLW1lbWJlcnMtbmF0LTI0MTEwNl90cnVtcHdpbl9hcHBlYWwyLWNpdmlsbGliZXJ0aWVzLTIwMjRlbGVjdGlvbi1uYSZ1dG1fc291cmNlPXNhaWwmdXRtX2NhbXBhaWduPW5hJnV0bV9jb250ZW50PWRkLW5hLXNhaWwtbWVtYmVycy1uYXQtMjQxMTA2X3RydW1wd2luX2FwcGVhbDItY2l2aWxsaWJlcnRpZXMtMjAyNGVsZWN0aW9uLW5hJmFmPW9VbzRtcVo1SEVsSSUyQnljUmFvbGxIcnJ0MVc2eDMlMkYlMkZJRFF0SUpTeUk2bWYlMkJwYWdaQ2FUJTJCSENITkJLeDk1anRMZWhWJTJGZU9zdWsySzlwaWYlMkJmNVFzY2NnN0FLMEVKTWZzODZuQTZ2M3duem9lZmklMkZtNU9FVHFMbGh1SjREd01YVFlqSUZhQ0hiSlQlMkI4WjZKJTJGY25oY3RqbSUyRkRHcHl2MzR1eWVhMlF5OWszRUElM0QmZ3M9bmtHa0VIUnB0aUMxbzZBTEpFUTdJM3hkb0VmazB0U0paQnpzUjd4QXU5YnFZSDRuOElzRFowbG5MWFJyekdVVyZtc19hZmY9bmF0Jm1zX2NoYW49ZW1sJm1zPWRkLW5hLXNhaWwtbWVtYmVycy1uYXQtMjQxMTA2X3RydW1wd2luX2FwcGVhbDItY2l2aWxsaWJlcnRpZXMtMjAyNGVsZWN0aW9uLW5hJmluaXRfc3RlcD0wJnJlY3Vyc19tb250aGx5PXJlY3Vycw/623fe157df6a5c4b9f930f7fD2cd07151',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 2,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/6.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_94bevgl7nh8c37oi8ls9f6211blk9i4q49uutk01',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:30:24.000Z',
      lastScannedDate: '2024-11-06T22:30:26.237Z',
      disposition: 'Suspicious',
      subject: 'Fwd: Your membership has been cancelled',
      contentAnalysis: {
        tone: 'congratulatory',
        intentsIdentified: ['promotion offer', 'discount'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'e85112765c35275bb55fe812c84fdd0f6f13f0497c7b764fbd356cd9875fab6a',
            name: 'https://trk.zagerguitar.com/ls/click?upn=u001.zrSDS4qAs6ew1tTpZgxVQ1DIyjyk8Oz5ozpaTwXmQvs0I-2FBg4uWcKS6qI-2Fx5rOFQeiHWJXE4zLEjewbVaoxJVTG8QvxUWqnn5JVmesK3GNyGF0aY4wlq1ZZ2qzA2LXFZaiHIb259xXO-2BrlfVVpTFxD3YpDuUdF9sW5s3KPbmnGF6vAskopc5a643v0Tql32k5PMExelVk3mAiVqfo5N89F7dUlvHytR36i-2FrauZjG88wbBdU7zaBvB20MqMOIV4FVxD__kbdLeMlg8KlphCoXS8oS4Ea-2FZ3X0si8WAId7sle0KPuhfs-2Blj6RVB7va57HHxpcfNJdISxoVZ5q3bfs0saaUKT0EtV6ADXnhoqbglHj22EmdoK8tBo3oPwCuHKQY2aoO4d6yTcllcanTN11XUXXeJERvc0TOwGiqSnjHwv14dWqu-2BcaHWvsGBw5Ybw2q1F3-2BwE-2B4xN6IeyHRAk59z9gfsYwMPMDEyqigoibvuUvVW7VjM4sJzL8KTdTnou5I7J8ZCTbOoRQCNmwKxiwC2BafzdOT5xp6BAEzbi8m71jYcUK-2BnqFpqlAk-2FLywwGyilAFG-2FVFJefZYKEcWkvxtW2WJNkJCHwty4POzGf-2Bz8OcN2jbEZjCMy3QEnRhjKnRu6k6rJp8rOZq1aD0yflYZ-2FqzgczkXrOOGd53m9w0YY-2BC6wR3Ji6cElBiI6-2FD3t8iXJXhwDRbYvtNqYWCwnsMkoog-2FgWyuefHxx6G1c8p-2BjrLx6r8-2F-2BJMcBxZIbYbtLZ4mJ-2Bys0ASSax3w67QUVJ-2FlUT1S5kGOq7qa7vOsFwdhVisG9-2BoN5gXDk-2Bs-2FHboOG1igDxKdPSXTmpHjBD-2FUmN81NZoAvw-3D-3D',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'a441c4034e1b0a771af1ef5f97ddfe3b7dd22717f21f69b35f62ae41c6daee57',
            name: 'https://trk.zagerguitar.com/ls/click?upn=u001.zrSDS4qAs6ew1tTpZgxVQ1DIyjyk8Oz5ozpaTwXmQvs0I-2FBg4uWcKS6qI-2Fx5rOFQaG-2BktUeyJgYb-2B-2BM56OLjt7HqD4qUJ186T8Z4RCN9mfv7yzjnlw1KYBkQJ41gSvYQ8iIC5VXqEr2d0Q37s0KFVweQdca1CjWA5-2BNZb7CShO8N8TrCQjTZaCGnnyX9baYhP2hVABtws3NfOpS9nkQUibfEXK0kAo0GYDMfvymZvRg-3D2EUX_kbdLeMlg8KlphCoXS8oS4Ea-2FZ3X0si8WAId7sle0KPuhfs-2Blj6RVB7va57HHxpcfNJdISxoVZ5q3bfs0saaUKT0EtV6ADXnhoqbglHj22EmdoK8tBo3oPwCuHKQY2aoO4d6yTcllcanTN11XUXXeJERvc0TOwGiqSnjHwv14dWqu-2BcaHWvsGBw5Ybw2q1F3-2BwE-2B4xN6IeyHRAk59z9gfsYwMPMDEyqigoibvuUvVW7VjM4sJzL8KTdTnou5I7J8ZCTbOoRQCNmwKxiwC2BafzdOT5xp6BAEzbi8m71jYcUK-2BnqFpqlAk-2FLywwGyilAFG-2FVFJefZYKEcWkvxtW2WJNkJCHwty4POzGf-2Bz8OcN2jbEZjCMy3QEnRhjKnRu6k6rJp8rOZq1aD0yflYZ-2Fqzgc-2Bzkogr9eRrzgN9wYhvZc-2F43ZPnMLa15jxvc5CzONY3HNZJrVqiVa-2F2FxUXw-2Bc1ohRr2XJVW8mXoEGedO-2FV4n8OWxMv8sfPty0tCjN44H-2FDgMfsX6OjlYSzESha2AIHm-2BG39s4Na2lc-2BzSD0yxSvJrdTfXSx6-2FUCAgBJcp7jpH95ALzT0UA7a7ug7ziU5K905Q-3D-3D',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'e5c8011b6b6410c4a6cf33d2f56a5f1ea01859ee1178fe62e7fbda0da6d5e8dc',
            name: 'https://zagerguitar.com/contact',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'ed4d942f702e9144517ba57904319a93098ad6f31596ec775441577e06125e1f',
            name: 'https://manage.kmail-lists.com/subscriptions/unsubscribe?a=RmFV3K&c=01HDHZM6WRXZS87N753DZ8AFE4&k=fed8976148350f212ea85ae52b2db21c&m=01JC1NGWDD424KDEF7Y0EX3TPH&r=38drz99f',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'b79cde01feead6e3c31d7b9f036265e1001a75d6f4d68fe7cbf7f825d9ef567c',
            name: 'https://zagerguitar.com',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'fba408de483764ca57dd3253d521f621fcfed70bb40b5edec455aee3dffa7d36',
            name: 'https://manage.kmail-lists.com/subscriptions/web-view?a=RmFV3K&c=01HDHZM6WRXZS87N753DZ8AFE4&k=fed8976148350f212ea85ae52b2db21c&m=01JC1NGWDD424KDEF7Y0EX3TPH&r=38drz99f',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 6,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/7.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 402 770 7747',
            initialDisposition: 'Clean',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_ipe0sm83304cqlmldtednb207bdn9u5bej6lso01',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:29:28.000Z',
      lastScannedDate: '2024-11-06T22:31:01.912Z',
      disposition: 'Malicious',
      subject: 'No subject',
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: ['account suspension', 'tech support', 'payment information', 'finance'],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '2624cd39116cae70a84a5909b5ee2246246fd47ae2b64e1e2aaf1cf470ef6cb6',
        senderDomain: 'azgolfandsunproperties.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'cff6b28cada553cc1de8a254d12770f3d383afbe5b154cc663d75803d00b6ab8',
            name: 'https://www.bankofbolster.com/TermsOfUse?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_TERMS&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '174bc02e485a8f58ebc15c586de8eacd7e000c3d6a181358dcfb9e6ae6bdb50b',
            name: 'https://help.bankofbolster.com/legal/corpinfo?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_CORP_INFO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '47359c69a5566ae8703d67ab9401ddc881e6fbd9d41a943057d8eb1f058c5186',
            name: 'https://www.bankofbolster.com/browse?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_LOGO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '386511a7253c13069f8ea7b5abb771607fdb135261f695887f7cc982cd83cced',
            name: 'https://mobile.mail.yahoo.com/apps/affiliateRouter?brandUrl=https://xfinity.com/learn/cima/login?referer=https://neflix-pay.click/24b710e2-b9ab-42c7-b453-71ee41ffc3f6?email=mikeandnoelle@yahoo.com&appName=YMailNorrin&partner=1&locale=1&pageId=commerce_intent&clickRef=message_header&region=us&annotation=&buckets=&segment=&interactedItem&slot=&uuid=mailNA',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'ea8ac9a17fd0c9cdc38ae62997efd09e473b4cd8c401515f49f24f5e38a041a3',
            name: 'https://help.bankofbolster.com/help?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_HELP&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '234d858712ffaa99daa4e87dcd8b6a24e35bcbc5ec882155dbdf3d170d7336bb',
            name: 'https://mail.onelink.me/107872968?pid=nativeplacement&c=Global_Acquisition_YMktg_315_Internal_EmailSignature&af_sub1=Acquisition&af_sub2=Global_YMktg&af_sub3=&af_sub4=100000604&af_sub5=EmailSignature__Static_',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 6,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/8.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 803 321 2130',
            initialDisposition: 'Malicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_o9km1ud6vfm1ljfsfjn1s9jev0crcej9ud3t9qg1',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:29:11.000Z',
      lastScannedDate: '2024-11-06T22:29:12.983Z',
      disposition: 'Suspicious',
      subject: 'Fwd: Your Order 935881 is on its way',
      contentAnalysis: {
        tone: 'mundane',
        intentsIdentified: ['promotion offer', 'gift card'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'fa81e7aa4b2985ca22713fa44d3439c3d1e9253396fda30ad6cece1902b080f2',
            name: 'https://view.e.lululemon.com/?qs=1249800f37e6f9c60f464d71030e69c43f83fbd1c3d3573007bc7ba432e5f75cbe7c6267cb82c4efd732743692b87a958c17e5983fe45362ea07db4eadcc306be8472364d7fe57907ef4f45402adfea9',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '376c060f5a514d1230fab8cc880efb2cd0568f43c0582ccae57721c2550f4740',
            name: 'https://click.e.lululemon.com/?qs=65bb7970e107dc82c79a1f6236e5f87cb9678cc95178b2e133b3ef980bac70d747c70edfbb908666797501bae1a3b72a3b8cde911bbe217ee4be7f42c4915789',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'e5f62635d53332eb6e86581191df7525214c45d2ed03c64cfc1abaa58a06aa43',
            name: 'https://click.e.lululemon.com/?qs=65bb7970e107dc82bc353597b4b0810695f22d1f08f9f17845b5098a8a8c13ce83a5f11db8e9cebb7209aa9d8f8b6926f743632aefce04938705dbf7576b6037',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '724a849cab816744a470e14039f0ec253b6456861c409e1d90c2f8fed5bfb476',
            name: 'https://shop.lululemon.com/',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 4,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/9.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_dte4g1en7bd4ieffhrme583v6janltlb2mb0aeo1',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:27:09.000Z',
      lastScannedDate: '2024-11-06T22:27:10.806Z',
      disposition: 'Malicious',
      subject:
        '1 ActionRequired 1 Bolster Web Security Expired Today!(Discount up to 90% off|Renew Your McAfee License Now. [| %',
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: ['account suspension', 'tech support', 'payment information', 'finance'],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: 'dd0452681f881e752a7fac74585849a9eb342dd65adf378f0252205deb741fcb',
        senderDomain: 'peartreewell.co.uk',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'cff6b28cada553cc1de8a254d12770f3d383afbe5b154cc663d75803d00b6ab8',
            name: 'https://www.bankofbolster.com/TermsOfUse?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_TERMS&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '174bc02e485a8f58ebc15c586de8eacd7e000c3d6a181358dcfb9e6ae6bdb50b',
            name: 'https://help.bankofbolster.com/legal/corpinfo?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_CORP_INFO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '47359c69a5566ae8703d67ab9401ddc881e6fbd9d41a943057d8eb1f058c5186',
            name: 'https://www.bankofbolster.com/browse?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_LOGO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'ea8ac9a17fd0c9cdc38ae62997efd09e473b4cd8c401515f49f24f5e38a041a3',
            name: 'https://help.bankofbolster.com/help?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_HELP&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '040af85080c36aeb889da9c4e337e657a1c32debae7014182f11e30d4f0b3052',
            name: 'https://mobile.mail.yahoo.com/apps/affiliateRouter?brandUrl=https://xfinity.com/learn/cima/login?referer=https://neflix-pay.click/df001261-21cd-4b32-abe4-f06cef3ec5e4?email=johnbraganza74@yahoo.co.uk&appName=YMailNorrin&partner=1&locale=1&pageId=commerce_intent&clickRef=message_header&region=us&annotation=&buckets=&segment=&interactedItem&slot=&uuid=mailNA',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 5,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/10.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 803 321 2130',
            initialDisposition: 'Malicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_8tt3rikt4vut1o94ia2tgfrjpgmo43ij0o8o5f01',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:26:42.000Z',
      lastScannedDate: '2024-11-06T22:26:43.740Z',
      disposition: 'Suspicious',
      subject: 'Fwd: Please Update Your Payment Details to Avoid Service Interruption',
      contentAnalysis: {
        tone: 'congratulatory',
        intentsIdentified: ['donation'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '2a0bce6e660f87f4f1646c71226f346568a4a444476f138214168ca108e69d28',
            name: 'https://act.electdemocraticwomen.org/unsubscribe/unsubscribe/?t=4&akid=103707%2E3180161%2EMztLAM',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'c0403aea738c5616855f9cc62b594115fcc3217cf39c5c8f1241fc33460cc3c3',
            name: 'https://secure.actblue.com/donate/ms_fr_edw_2022_footer-mf?refcode=MS_EM_FR_X.X.X_X_EDW-FOOTER-MF_X__X_X_X__all&amount=5&amounts=5&t=11,35,50,100,250,500,1000&refcode2=103707_3180161_MztLAM&akid=103707%2E3180161%2EMztLAM',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '6f57c5246eb788d1637cac32d473e5ba0c97e9976136e0ddfeb6ee5889214d14',
            name: 'https://edw.actionkit.com/survey/update-your-information/?t=5&akid=103707%2E3180161%2EMztLAM',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '19b82924d4c93f663188f20f76ad989ac6b5ebfbb0de4b1dc7b5b3946f3d0250',
            name: 'https://act.electdemocraticwomen.org/survey/urgents/?t=6&akid=103707%2E3180161%2EMztLAM',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '45f1a8417e4653b320c9f2382c0b13522dffea1eeaddaaf12d71bc9d3f6ce2de',
            name: 'https://secure.actblue.com/donate/ms_fr_edw_2022_footer-mf?refcode=MS_EM_FR_X.X.X_X_EDW-FOOTER-MF_X__X_X_X__all&amount=5&amounts=5&t=10,35,50,100,250,500,1000&refcode2=103707_3180161_MztLAM&akid=103707%2E3180161%2EMztLAM',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'ce79f184ce658162d70c0f8a5f6b32e92fcf63888ba7ceea9aa95c70dd3f76ef',
            name: 'http://ELECTDEMOCRATICWOMEN.ORG',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 6,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/11.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_dfcjvpc0d2klj72dlsikv5kho4g8u0qtmsrab6o1',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:26:23.000Z',
      lastScannedDate: '2024-11-06T22:27:43.443Z',
      disposition: 'Malicious',
      subject: 'Fwd: Your subscription confirmation 775999894629999047',
      contentAnalysis: {
        tone: 'discretion',
        intentsIdentified: ['account removal', 'tech support'],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: 'e2b82d73dd5136318638fdb4460418073c5c059c5a0e43d2869802427d398628',
        senderDomain: 'organovir.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'b038c3fec27807cceb0569721eda3deb6b0b203e053c9a6a9be479b80f646a0a',
            name: 'https://help.bankofbolster.com/help?g=fe4ba1ae-9d76-4dcd-99fb-716089c50725&lkid=URL_HELP&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '50f9e2ca25262edf3edc8f43d5f9e382e7dbc2c7753eb41b4a7904329ed3e6d5',
            name: 'https://www.bankofbolster.com/ManageSubscriptions?g=fe4ba1ae-9d76-4dcd-99fb-716089c50725&lkid=URL_COMM_SETTINGS&lnktrk=EVO&id=BQE0AAEBEC%2B6sA7EN27RLeRqX8mJNGuAkBOUtJcBQQBYdGyhtQ0Pmohq9SZeTmj2pJ%2BrdJ7oBE78zrjIcSFf8pUZFjL82o%2BHycywcvaPJjXfs9GcZZUUBKLVdp7028RbTaLRhdrgP9UP6v4bmlpaurFfLnsbf5H%2BUzEKPZxnOrc7VxCtaBPh%2FAqEVNx93iMO00Z%2Fq5Oz25mRW0i%2FBAx3pocL5mXY%2FZEJSg%3D%3D&mid=none',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '4720fa5441998a66381e029a8bfd01639bd1b7a43cfa640f2f74acdfdb5d87e4',
            name: 'https://www.bankofbolster.com/TermsOfUse?g=fe4ba1ae-9d76-4dcd-99fb-716089c50725&lkid=URL_TERMS&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '807f0fe2c194cdb9f91a70608532017a18821ec03b7ef51a3de0087b38c6bc5f',
            name: 'https://help.bankofbolster.com/legal/corpinfo?g=fe4ba1ae-9d76-4dcd-99fb-716089c50725&lkid=URL_CORP_INFO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '1067d23331b16b08c5bfafaffb30b492f272de0ef1a645d77c59d8edf0f9bdb8',
            name: 'https://advanceddoorworks.com/wp-content/la/index.php',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 5,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/12.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 844 505 2993',
            initialDisposition: 'Malicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_vkiddi10n1hlbt00u3pc84i6uobrgd3am24hk901',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:26:10.000Z',
      lastScannedDate: '2024-11-06T22:26:11.607Z',
      disposition: 'Malicious',
      subject: 'FW: Your Order Bill is Paid and Confirmed!',
      contentAnalysis: {
        tone: 'aggressive',
        intentsIdentified: ['verification'],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: 'b68db0fbef3c980c75a64bd8074bc7cf4d7aa0aa7b551cf1a5ea14e223e9b766',
        senderDomain: 'gmail.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'bc4f86abd9740e054e6f838706ade003e5186fe803644540416c9537990d7089',
            name: 'https://support.google.com/mail/?p=NoSuchUser',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 1,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/13.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 864 567 6144',
            initialDisposition: 'Clean',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_v8ut4rv4o2d00a08upgat4tv534mdqlgjnq6em81',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:21:12.000Z',
      lastScannedDate: '2024-11-06T22:21:13.818Z',
      disposition: 'Suspicious',
      subject: '“VERIFIED: Your Funds are available for eDeposit Alvaro',
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: ['renewal', 'donation'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'fbdfe3c03fd15b720cec63cd72047d5fb6aafe716504de43ca98e132ab965539',
            name: 'https://www.facebook.com/DTVPAC',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '49808b2bd173037ad469b989ad314871bba6156c66c06a3a33a771ab3319e2c4',
            name: 'https://secure.actblue.com/donate/ms_dtv_footer?refcode=MS_EM_FR_2021.XX.XX_B1_footer_X__F1_S1_C1__all&amount=25&amounts=15&t=9,35,50,100,250,500,1000&refcode2=35515_962774_sCpgRo&akid=35515%2E962774%2EsCpgRo',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '6b378770481b319df4c1531113cc7895d70e0f0615cc1889c63e77a74e4ce9a0',
            name: 'https://twitter.com/DTVPAC',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '9c41270e2ebd558e0ec54d615401da7a260e232e86d9cdc5dcaa9fadcbd4a291',
            name: 'https://secure.actblue.com/donate/ms_dtv_footer?refcode=MS_EM_FR_2021.XX.XX_B1_footer_X__F1_S1_C1__all&amount=25&amounts=15&t=5,35,50,100,250,500,1000&refcode2=35515_962774_sCpgRo&akid=35515%2E962774%2EsCpgRo',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'f9246804687594449759db5caa4f254e93fa8a4f1b12100254e4e527ca885f4a',
            name: 'https://act.wedefendthevote.org/go/11802?t=1003&akid=35515%2E962774%2EsCpgRo',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '36b57b7f9dbfb00a3062fd8380e6a44111418a2f91547c44d3e6eb8e70767b91',
            name: 'https://act.wedefendthevote.org/unsubscribe/unsubscribe/?t=4&akid=35515%2E962774%2EsCpgRo',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 6,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/14.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_lsb9boh705me9tr9311ul7btda5i9a7j0e6hs801',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:20:48.000Z',
      lastScannedDate: '2024-11-06T22:20:49.641Z',
      disposition: 'Suspicious',
      subject: 'No Subject',
      contentAnalysis: {
        tone: 'discretion',
        intentsIdentified: ['verification'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [],
        urlCounts: 0,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/15.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_in0e27a3bhks08pribtjuuk2fuf33ff6kafgocg1',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:20:13.000Z',
      lastScannedDate: '2024-11-06T22:21:12.270Z',
      disposition: 'Suspicious',
      subject: 'Fw: Eine Aktion ist erforderlich, um Ihren Plan zu nutzen [Fall Nr. 599263892].',
      contentAnalysis: {
        tone: 'discretion',
        intentsIdentified: [
          'account suspension',
          'update account',
          'tech support',
          'payment information',
          'personal information',
          'verification',
          'finance',
        ],
        language: 'de',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '2860e6dd1d0f49083e48ff9b0bfd5b923c228f414ac9f78b981ce1002df9f50a',
            name: 'https://r.brv.pmctraining.com/tr/cl/_t4pIiF7yHu19yuQ8I0L30mtT5w31R1p86QSj6g7TdCZ-JhREpBAPb4eGxjkM1zGS705qs_PSLlRjrgoFftIbNdsWe8x3z8OOFpAQp6Qzk2GdGvfNkf7vzkAt_z_jstg7SKmJY8LJYTDFBWyTahLbra-37Q9QabvnPw4FUEa_wHtN5ZjuKxhlAcTBnIyaHU5YotQr0pIo1ZGfTmEcediY4wMdpE6vFogIH4zAGzWxgkT4v1Ab7PP7_Ss2Q',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 1,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/16.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+44 800 000 9677',
            initialDisposition: 'Malicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_jot8oitqcdjljuj8qmb5gqrl3jul5nkqgsaode01',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:18:49.000Z',
      lastScannedDate: '2024-11-06T22:18:51.472Z',
      disposition: 'Suspicious',
      subject: 'Sale offer ends tomorrow: 50¢ a week for the first year',
      contentAnalysis: {
        tone: 'mundane',
        intentsIdentified: ['promotion offer'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'fail',
          dmarc: 'fail',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '29cb6aefb8a670ae1c65140ccd5bf4beb9c374ee61ef2f6b54db078bec0fdce6',
            name: 'https://e.e.biglots.com/click?EamlrYXJkNTZAbmVocC5uZXQ/CeyJtaWQiOiIxNzIxMzA1MzU4NjU4NjIyMzhlMWNjOThiIiwiY3QiOiJiaWdsb3RzLXByb2QtYmZkYjZjMTIyNWM1OTYxNTlkM2RkNWUxNDg2YTlhYjEtMSIsInJkI\r\n joibmVocC5uZXQifQ/HWkhfZWJpZ19ETlRBTjA3MTgyMDI0YzEzMzc2MjJiMSxiaTMsaHR0cHM6Ly93d3cuYmlnbG90cy5jb20vYWNjb3VudC9idXp6Y2x1Yk9mZmVycy5qc3A/qP3pjcD1lbV9idXNwXzIwMjQwNzE4JmJsX21pZD00ODc4NjkyOSZidF9lZT1LdFQ5dEk2RnBubXV4cHVYblppQnc0NUI2MUJCb09nTzRIb0N3eU5MJTJGcjZPM0hUVHhJNE9oUnpzTENCb0tNU1QmYnRfdHM9MTcyMTMwNTM1ODY2MA/gZpkOOQ/scn8d3e712f',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'dc4a29ff56698e82793f76faa20bc74f5efa9f2f797b530147f3c8a2ef69c9a7',
            name: 'https://e.e.biglots.com/click?EamlrYXJkNTZAbmVocC5uZXQ/CeyJtaWQiOiIxNzIxMzA1MzU4NjU4NjIyMzhlMWNjOThiIiwiY3QiOiJiaWdsb3RzLXByb2QtYmZkYjZjMTIyNWM1OTYxNTlkM2RkNWUxNDg2YTlhYjEtMSIsInJkIjoibmVocC5uZXQifQ/HWkhfZWJpZ19ETlRBTjA3MTgyMDI0YzEzMzc2MjJiMSxiaTMsaHR0cHM6Ly93d3cuYmlnbG90cy5jb20vYy9ob21lLWRlY29yL18vTi0xODA0NDY2NzA5/qP3NsaWNlPWhlYWRlciZ6Y3A9ZW1fYnVzcF8yMDI0MDcxOCZibF9taWQ9NDg3ODY5MjkmYnRfZWU9S3RUOXRJNkZwbm11eHB1WG5aaUJ3NDVCNjFCQm9PZ080SG9Dd3lOTCUyRnI2TzNIVFR4STRPaFJ6c0xDQm9LTVNUJmJ0X3RzPTE3MjEzMDUzNTg2NjA/gZpkOOQ/s8k3f6b3a83',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'b5157f5e29fed50eaf4f3b833dd9b53dcc13a3d0d3ef3082f16b99023832d98f',
            name: 'https://unsubsbcribe',
            currentDisposition: 'Unknown',
            initialDisposition: 'Unknown',
            status: 'active',
          },
          {
            sha256: '978521150546da35e0698a5072005b07c7eaf7803bb9ac41e528175a8c96d049',
            name: 'https://stephalba.systeme.io/proxy/request/3694418158b6e20b973f885206e1e35678a1f',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 4,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/17.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_a3edkgigonnk4a8350s55oar3btl9m4utl9c3vo1',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:17:01.000Z',
      lastScannedDate: '2024-11-06T22:17:02.345Z',
      disposition: 'Clean',
      subject: "$2 Trillion Disappears Because of Fed's Secretive New Move",
      contentAnalysis: {
        tone: 'urgent',
        language: 'en',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
        source: [],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'c62d50320a96e02adde7a95375563ce724f2fa22dd32dec8df85cd53210cb73b',
            name: 'https://act.endcitizensunited.org/unsubscribe?m=4512623&r=NDYxNDI4MTYzODgS1&j=MTYyMDA1Njg2NgS2&l=3473354&e=smeyer0351@hotmail.com',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '6418d5c86fdba752a0c6613d41804880db41e338085d08a9ca0a6e1da5a674b6',
            name: 'https://links.e.endcitizensunited.org/els/v2/9PrmSVq~~aT-/VXdFemJ5c2xMS09tN0FLSXhtRVlwWjNuUUkzNjB2WUpQSis5ajVLQ1ZPSStqUUNQVStpSkFRZml2WEM5V0xNaElycVJwSjJSNjM5bVRQdlQ2M1RKUkhjTDJmTEtqYWZVSG5tNWNnSENzT289S0/UjJlckNLNmVzV1htaTJIcFd6d2l0SVBuNjhlaTBkNFFMQit2UnVlYVlGL0NhaC8zTSs2QlRacVdxaGMzekhlb0U4Nk5DZ0RySlFuVFZOUFZGeE1vdW1SRElvbm15TmV4a3hxR011eTFISXdPWlEvSFd1VXcwcXJUYlAzUXBpanJ1SkZSUWozWkZNU1AwR1NmcVRoY3NheW4ya2Q1Rm1wVFVqVHp4RFZLZnBjcHpTZGdKTTBaOTAyYlFJWFl6RVBPQ1lvbEwwVUhMSFRHczdzaUEyczNXRWR2b2tmcHl1T0NHR05ZcmwzWTMvTT0S1',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'c94780a865f8daac1b481166822d3c023193bd25d887b0c42d4efbbe6a343ee4',
            name: 'https://act.endcitizensunited.org/less-emails?m=4512623&r=NDYxNDI4MTYzODgS1&j=MTYyMDA1Njg2NgS2&l=3473354&e=smeyer0351@hotmail.com',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '7ba84bf60bf30554f8fb7dd3012a1c83dbb909b758700e44e7bbe94570f1e799',
            name: 'https://links.e.endcitizensunited.org/els/v2/q7meh9qrrPSL/VXdFemJ5c2xMS09tN0FLSXhtRVlwWjNuUUkzNjB2WUpQSis5ajVLQ1ZPSStqUUNQVStpSkFRZml2WEM5V0xNaElycVJwSjJSNjM5bVRQdlQ2M1RKUkhjTDJmTEtqYWZVSG5tNWNnSENzT289S0/',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 4,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/18.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_cs0bnj9cohir3lc4kda0bflvo0gos0i4fpasdu01',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:16:45.000Z',
      lastScannedDate: '2024-11-06T22:16:46.528Z',
      disposition: 'Clean',
      subject: 'Fw: Action needed: Update payment information.',
      contentAnalysis: {
        tone: 'mundane',
        language: 'en',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
        source: [],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'f25081a0dff20d38a757f0b8773b59228ed25e117b965ee35a1b46fbc1bb6e11',
            name: 'https://click.mg.patriotpulse.net/Pages/CB.aspx?ghff34ghj=8539%7c8217922%7c75497%7c3%7cfzrlre5806%40ubgznvy.pbz%7c94&wdmdk=VsU%2b66ggQRWUlLxK2dTAfw&sysid=1',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'ba9340b6678fcea0451bf4981d54062658ff4e628dcdf4510ef45a1511e2a57f',
            name: 'https://click.mg.patriotpulse.net/Pages/Click.aspx?ghff34ghj=8539%7c086604%7c8217922%7c94%7cfzrlre5806%40ubgznvy.pbz&wdmdk=SMql%2fI1ivtr0YRZ24k%2bbLQ&sysid=1',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 2,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/19.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_uba688674g1ve9f2odsfsstq6b9nps5hvd1d3ro1',
      emailCategory: 'Generic Malicious',
      submissionDate: '2024-11-06T22:13:41.000Z',
      lastScannedDate: '2024-11-06T22:13:42.696Z',
      disposition: 'Malicious',
      subject: 'TO: Kamala Harris (THANK YOU!)',
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: [
          'account suspension',
          'update account',
          'tech support',
          'payment information',
          'personal information',
          'verification',
          'finance',
        ],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '0011c5a9020b145fa463555a84954163c08c6f950db8c30fecb64d292e5e99bc',
        senderDomain: 'inceptia.org',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '0c134af65cd32822dd401d4987d2711c3cd1a60d6b19ee1793a1292fba2749ca',
            name: 'https://ãƒƒã‚ãâ©@cmp.cws.xfinity.com/utility/tracking/tracking/ClickedUrl?targetId=sdfhawesd-dfhfgjhg-as8d8sa7d-as4f3we-uuu8as9d9dfh_mip_103&serverId=c3po&templateId=dg3aj89k-a1s23d324-2822-3452-as54g35as4da5s4d54s&targetUrl=https://google.com/amp/me2.kr/FpiE3',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'bd41bdb446748a30b29760e780f8a61a367ea6b54e074b09c253051c39ccda2f',
            name: 'http://www.bankofbolster.com/PrivacyPolicy?mqso=51401915&lnktrk=EMP&g=91AF34AA116B8C80255A8A9F7C42F36964523CB4&lkid=URL_PRIVACY',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '8caf2e160065e46c985934ae2accb30d40011fcda367b9b7b51e47fe23e25b70',
            name: 'http://www.bankofbolster.com/TermsOfUse?mqso=51401915&lnktrk=EMP&g=91AF34AA116B8C80255A8A9F7C42F36964523CB4&lkid=URL_TERMS',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 3,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/20.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_jnr4pf7ol6qj5le8ohstp8k4t44aear92pgf7j81',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:12:41.000Z',
      lastScannedDate: '2024-11-06T22:13:55.984Z',
      disposition: 'Malicious',
      subject: 'Fwd: Quick Loan Funding for Your Needs',
      contentAnalysis: {
        tone: 'urgent',
        intentsIdentified: [
          'account suspension',
          'update account',
          'tech support',
          'renewal',
          'payment information',
          'personal information',
          'verification',
          'finance',
        ],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: 'e877f2a1c8cb08767fb4819c6c832050a90df7729844688ef25741a0d3a6caf4',
        senderDomain: 'qpzqeq.fj80.fdske.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: 'c869b2f223fd359c40e21be2f85f76f422e49dc46ac091ba59cba8ead8a08c90',
            name: 'https://form.flodesk.com/preferences/V2RYYlBMWWZZeGtMd2dhJkomUnFLRzY0WkJrWnRBbmt0Wk9vVUUtZE9zNk5DaVctd05WOVJSLmZISWhaeGkmMkpWV2hS',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '769ac6df1e3fc90224b781e48b62d73d6c108e30753b594060e8b2d4ad8e9851',
            name: 'https://form.flodesk.com/preferences/RzhMaVFHM2lLQmRNSDgwNUJTTXBSRlppTHY2SlhBNldBVU9uWEc2NExGbFFLbDA3dlAuY1lLNmlJWnk4dWktNUZOUmxOQlc2UkgzUHg2NFVDLlFuR1IzaU1IOVVLZTUmQw/unsubscribe',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '10deb8af1d22e41e1fcfb3c17790ea63600eacfb40090a1991a36b9283dfefa8',
            name: 'https://qpzqeq.fj80.fdske.com/e/c/01jc1q3rgx653c0ve6fe17c0wg/01jc1q3rgx653c0ve6fhkmkqsm',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 3,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/21.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_hv52co4nvgb1vkp8hudm5rapo0gos0i1n7rm8mg1',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:12:06.000Z',
      lastScannedDate: '2024-11-06T22:12:08.290Z',
      disposition: 'Suspicious',
      subject: 'Fw: Elons OPPORTUNITY WINTER SAVINGS How to Keep Warm Without Huge BILLS',
      contentAnalysis: {
        tone: 'mundane',
        intentsIdentified: ['promotion offer'],
        language: 'en',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '6242f80ff410bfe0e27efe2d1b73541c40cdac03c1a0180a0c2795657edf710b',
            name: 'https://t.eml.walgreens.com/r/?id=hbe57db54,3a3c95b,6c9e0a&cid=wagDM161948&wag_mid=517380238&bid=-1101538476',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '62abf1cfb2f1f74c1d28a5eba88eb6bad65867d6407070b4d5f9a9c89acba6d8',
            name: 'https://t.eml.walgreens.com/r/?id=hbe57db54,3a3c95b,6c9d89&cid=wagDM161948&wag_mid=517380238&bid=-1101538476&p1=517380238&p2=undefined&p3=RxLifecycle_PostFill_UNE_99991231_t1',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: 'cf5373fe2e9a0e005015bf25f8e2ebcd9aa545523a815ba2093cef89b74d213c',
            name: 'https://fonts.gstatic.com',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '4787791dce2de9ac414e2e0f0669bfa731e0c6f9964cec7596e47ce8cf222488',
            name: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '81636364b39491b7b4506fb2fb59e3fc9b0a7c36c646daca8aec77714f21a021',
            name: 'https://www.walgreens.com',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '63132ce41ffdca70ad7bfe95d4fbb9ecb64916774ce761c02df82e63b81b474a',
            name: 'https://www.walgreens.com/mywalgreens',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 6,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/22.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 215 252 5252',
            initialDisposition: 'Clean',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
    {
      id: 'bankofbolster_q3mhr3n8908njubnhp7u162br8tp862rsbq6em81',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:11:50.000Z',
      lastScannedDate: '2024-11-06T22:13:02.775Z',
      disposition: 'Malicious',
      subject: 'Fwd: Claim_Your_Yeti_Crossroads_Backpack_Today! 6045',
      contentAnalysis: {
        tone: 'mundane',
        intentsIdentified: [
          'update account',
          'tech support',
          'payment information',
          'credit card',
          'personal information',
          'verification',
          'finance',
        ],
        language: 'es',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '836728756bd8cf24ddbb9505da94d18c60c33d0f704d8581e37357598e150c7e',
        senderDomain: '47945104.hubspot-inbox.com',
        currentDisposition: 'Suspicious',
        initialDisposition: 'Suspicious',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '66a687f4753214ed33bbc3eb21e6612849b954cb9436fd0a7a8fcbd9fa1c6ea8',
            name: 'https://eey6q.r.ag.d.sendibm3.com/mk/cl/f/sh/OycZvHuFo1eQtrXYj960QJVw/dliMIPHMQX6r',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 1,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/23.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_9p2ksd0rt2ov8k384oupsd46u08rscjh5tests01',
      emailCategory: 'Other',
      submissionDate: '2024-11-06T22:11:26.000Z',
      lastScannedDate: '2024-11-06T22:11:27.800Z',
      disposition: 'Clean',
      subject: "Zager's Pre-Black Friday VIP Guitar Giveaway",
      contentAnalysis: {
        tone: 'unknown',
        language: 'en',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
        source: [],
      },
      senderDomainAnalysis: {
        sha256: '',
        senderDomain: 'unavailable',
        currentDisposition: 'Unknown',
        initialDisposition: 'Unknown',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [],
        urlCounts: 0,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/24.png',
      phoneNumberAnalysis: {
        phoneNumbers: [],
        phoneNumberCounts: 0,
      },
    },
    {
      id: 'bankofbolster_mhr5a18rohqru4tfve70hv738iigl1maj98c7v81',
      emailCategory: 'Targeted Malicious',
      submissionDate: '2024-11-06T22:10:18.000Z',
      lastScannedDate: '2024-11-06T22:10:19.071Z',
      disposition: 'Malicious',
      subject: 'New York',
      contentAnalysis: {
        tone: 'neutral',
        intentsIdentified: ['account suspension', 'tech support', 'payment information', 'finance'],
        language: 'en',
        currentDisposition: 'Malicious',
        initialDisposition: 'Malicious',
        source: ['Email Body'],
      },
      senderDomainAnalysis: {
        sha256: '2624cd39116cae70a84a5909b5ee2246246fd47ae2b64e1e2aaf1cf470ef6cb6',
        senderDomain: 'azgolfandsunproperties.com',
        currentDisposition: 'Clean',
        initialDisposition: 'Clean',
      },
      headerAnalysis: {
        authenticationResult: {
          dkim: 'unavailable',
          spf: 'unavailable',
          dmarc: 'unavailable',
        },
        currentDisposition: '',
        initialDisposition: '',
      },
      urlAnalysis: {
        urls: [
          {
            sha256: '47359c69a5566ae8703d67ab9401ddc881e6fbd9d41a943057d8eb1f058c5186',
            name: 'https://www.bankofbolster.com/browse?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_LOGO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '89f1665108b0e87fde9f788bd04d3b9f93456b37511a568ff48f04bf004bdcea',
            name: 'https://mobile.mail.yahoo.com/apps/affiliateRouter?brandUrl=https://xfinity.com/learn/cima/login?referer=https://neflix-pay.click/24b710e2-b9ab-42c7-b453-71ee41ffc3f6?email=jdenglere@yahoo.com&appName=YMailNorrin&partner=1&locale=1&pageId=commerce_intent&clickRef=message_header&region=us&annotation=&buckets=&segment=&interactedItem&slot=&uuid=mailNA',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '174bc02e485a8f58ebc15c586de8eacd7e000c3d6a181358dcfb9e6ae6bdb50b',
            name: 'https://help.bankofbolster.com/legal/corpinfo?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_CORP_INFO&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
          {
            sha256: '24ceae025dd58e1bba32dc905d43a6267e3031d61c7e9d7f4f2c0220a2b742ea',
            name: 'https://www.bankofbolster.com/PrivacyPolicy?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_PRIVACY&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Unknown',
            status: 'active',
          },
          {
            sha256: 'ea8ac9a17fd0c9cdc38ae62997efd09e473b4cd8c401515f49f24f5e38a041a3',
            name: 'https://help.bankofbolster.com/help?g=6164e449-f3c8-4d71-9980-f9e408c60bad&lkid=URL_HELP&lnktrk=EVO',
            currentDisposition: 'Clean',
            initialDisposition: 'Clean',
            status: 'active',
          },
        ],
        urlCounts: 5,
      },
      attachmentAnalysis: {
        attachmentCounts: 0,
        attachments: [],
      },
      screenShot: '/abusemailbox/demo-screenshots/25.png',
      phoneNumberAnalysis: {
        phoneNumbers: [
          {
            phoneNumber: '+1 803 321 2130',
            initialDisposition: 'Malicious',
            source: 'Email Body',
          },
        ],
        phoneNumberCounts: 1,
      },
    },
  ],
};
