import React, { useState, useEffect, useCallback } from 'react';
import './bulkscan.scss';
import { getName } from 'country-list';
import { ScanStrategy, SCAN_STRATEGY_ORDER } from './BulkScanConstants';

export interface ICountryCodeTlds {
  [tld: string]: {
    countryCode: string;
    countryName: string;
  };
}

interface IScanLocationInfoProps {
  url: string;
  brandScanPref: any;
  countryCodeTlds: ICountryCodeTlds;
}

const ScanLocationInfo = (props: IScanLocationInfoProps) => {
  const { url, brandScanPref, countryCodeTlds } = props;
  const [tld, setTld] = useState<string | null>(null);

  // Function to extract TLD from URL
  const extractTld = useCallback((url: string): string | null => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`;
    }
    try {
      const hostname = new URL(url)?.hostname;
      const parts = hostname.split('.');
      if (parts.length >= 2) {
        return parts[parts.length - 1]; // Get the last part as TLD
      }
    } catch (error) {
      return null;
    }
    return null;
  }, []);

  // Function to check if a scan location is already present
  const isScanLocPresent = useCallback((scanLocations: string[], scanLoc: string): boolean => {
    return scanLocations.some(sl => sl === scanLoc);
  }, []);

  useEffect(() => {
    const extractedTld = url ? extractTld(url) : null;
    setTld(extractedTld);
  }, [url]);

  const generateScanLocationText = useCallback((): string => {
    const scanLocations: string[] = [];
    const scanTexts: string[] = [];
    let joinedText: string = '';

    if (!tld) {
      return '';
    }

    SCAN_STRATEGY_ORDER.forEach(strategy => {
      switch (strategy) {
        case ScanStrategy.BRAND_SCAN_PREFERENCE.name: {
          const countryCode = brandScanPref?.countryCode;
          if (countryCode && !isScanLocPresent(scanLocations, countryCode)) {
            scanTexts.push(`${getName(countryCode)} (${ScanStrategy.BRAND_SCAN_PREFERENCE.label})`);
            scanLocations.push(countryCode);
          }
          break;
        }
        case ScanStrategy.SRC_TLD.name: {
          const tldCountryCode = countryCodeTlds[tld] || null;
          if (tldCountryCode && !isScanLocPresent(scanLocations, tldCountryCode.countryCode)) {
            scanTexts.push(`${tldCountryCode.countryName} (${ScanStrategy.SRC_TLD.label})`);
            scanLocations.push(tldCountryCode.countryCode);
          }
          break;
        }
        case ScanStrategy.NET_NUT.name || ScanStrategy.INHOUSE.name:
          if (!isScanLocPresent(scanLocations, 'US')) {
            scanTexts.push(`US Residential`);
            scanLocations.push('US');
          }
          break;
        case ScanStrategy.HOSTING_PROVIDER.name:
          scanTexts.push(`Hosting Provider`);
          break;
        default:
          break;
      }
    });

    // Combine scanTexts into a single sentence
    if (scanTexts.length === 0) {
      return '';
    }

    if (scanTexts.length === 1) {
      joinedText = `Scans will be performed in this order: ${scanTexts[0]}.`;
    }

    // Join with commas and add 'and' before the last item
    joinedText =
      scanTexts.slice(0, -1).join(', ') +
      `${scanTexts.length >= 3 ? ',' : ''} and ` +
      scanTexts[scanTexts.length - 1];

    return `Scans will be performed in this order: ${joinedText}.`;
  }, [brandScanPref?.countryCode, countryCodeTlds, isScanLocPresent, tld]);

  return <p style={{ fontSize: '14px' }}>{generateScanLocationText()}</p>;
};

export default ScanLocationInfo;
