import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Theme.scss';
import './App.scss';

import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { AppState, history } from '../helpers';
import { LoginSsoPage } from '../components/Auth/login.sso.component';
import { ForgotPass } from '../components/Auth/forgotpass.component';
import { ResetPass } from '../components/Auth/resetpass.component';
import { VerifyUser } from '../components/Auth/verifyuser.component';
import { VerifyWorkEmail } from '../components/Auth/verifyworkemail.component';
import ForgotPassHelper from '../components/Auth/forgotpass.helper.component';
import { AppWrapper } from '../components/Drawer/AppWrapper';
import { ConfirmRegistration } from '../components/Auth/confirmregistration.component';
import { TermsAndConditionsPage } from '../components/Auth/terms.conditions.component';
import { FilterFaq } from '../components/FilterFaq/FilterFaq';
import { PicFiles } from '../components/Others/PicFiles';
import { SignupPage } from '../components/Auth/signup.component';
import { LoginPage } from '../components/Auth/login.component';
import WebSideMenu from '../components/Drawer/WebSideMenu';
import { PublicDomainInsights } from '../components/Insights/PublicDomainInsights';
import ThemeContext, {
  defaultThemeContextValue,
  ThemeContextProvider,
} from '../context/ThemeContext';
import { AppSettingContextProvider } from '../context/AppSettingContext';
import { ActiveRoutesContextProvider } from '../context/ActiveRoutesContext';
import { TAB_ITEMS_HEADER } from '../components/Drawer/TabItems';
import { connect } from 'react-redux';
import { appActions } from '../actions';
import { IRunByClientAppType, setLocalStorageValue } from '../constants';
import { PublicInsights } from '../components/Insights/PublicInsights';
import { PublicIpInsights } from '../components/Insights/PublicIpInsights';
import {
  CheckPhishContactUsContextProvider,
  CheckphishSubscriptionProvider,
} from '../context/CheckPhishContactUsContext';
import AnnonymousScanPage from '../components/HomePage/AnnonymousScans';
import { TyposquatContextProvider } from '../context/TyposquatContext';
import { setRedirectRoute } from '../constants';
import { ActiveBrowserTabContextProvider } from '../context/ActiveBrowserTabContext';
import { ELoggedIn } from '../reducers';
import DigestInsights from '../components/Common/DigestInsights';
import ActiveAppContext, { ActiveAppContextProvider } from '../context/CheckphishActiveAppContext';
import MaintenanceAlert from '../components/Common/MaintenanceAlert/MaintenanceAlert';
interface IAppProps {
  isLoggedIn: ELoggedIn;
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
  user?: any;
}

export interface ILoginError {
  status: boolean;
  message: string;
}

interface IState {
  isWebSideMenuOpen: boolean;
  loginError: ILoginError;
}

class App extends Component<IAppProps, IState> {
  private TAB_ITEMS_HEADER = TAB_ITEMS_HEADER;

  constructor(props: IAppProps) {
    super(props);
    this.state = {
      isWebSideMenuOpen: false,
      loginError: { status: false, message: '' },
    };
    props.runByClientApp({
      onCheckPhish: async () => {
        await setRedirectRoute();
        const favicon = document.getElementById('favicon');
        if (favicon) {
          (favicon as HTMLLinkElement).href = '/logo_checkphish.ico';
        }
      },
    });
  }

  toggleWebSideMenu = () => {
    this.setState({ isWebSideMenuOpen: !this.state.isWebSideMenuOpen });
  };

  changeLoginError = (newState: ILoginError) => {
    this.setState({ loginError: newState });
  };

  componentDidMount(): void {
    setLocalStorageValue('cpLastVisitedTs', new Date().getTime());
  }

  render() {
    const { runByClientApp, isLoggedIn, user } = this.props;
    return (
      <AppSettingContextProvider>
        <ActiveRoutesContextProvider>
          <CheckphishSubscriptionProvider subscription={user?.subscription}>
            <CheckPhishContactUsContextProvider>
              <TyposquatContextProvider>
                <ActiveBrowserTabContextProvider>
                  <ThemeContextProvider value={defaultThemeContextValue}>
                    <ActiveAppContextProvider>
                      <ThemeContext.Consumer>
                        {context => (
                          <Router history={history}>
                            {/* TO DO: ENABLE this is DEV */}
                            {runByClientApp({
                              onCheckPhish: () => {
                                if (this.state.isWebSideMenuOpen) {
                                  return (
                                    <WebSideMenu
                                      isOpen={this.state.isWebSideMenuOpen}
                                      items={this.TAB_ITEMS_HEADER}
                                      close={this.toggleWebSideMenu}
                                    />
                                  );
                                }
                              },
                            })}
                            <div
                              className={`App ${runByClientApp({
                                onBolster: () => context.selectedTheme,
                                onCheckPhish: () => '',
                              })}`}
                            >
                              <MaintenanceAlert />
                              <Switch>
                                <Route exact path='/' render={() => <Redirect to='/sign-in' />} />
                                <Route
                                  path='/sign-in'
                                  render={() => (
                                    <LoginPage
                                      theme={context.selectedTheme}
                                      loginError={this.state.loginError}
                                      history={history}
                                    />
                                  )}
                                />
                                <Route path='/sign-in-sso' component={LoginSsoPage} />
                                <Route path='/sign-up' component={SignupPage} />
                                <Route path='/verify/:token' component={VerifyUser} />
                                <Route
                                  path='/verify-work-email/:token'
                                  component={VerifyWorkEmail}
                                />
                                <Route path='/forgot-password' component={ForgotPass} />
                                <Route path='/reset-password/:token' component={ResetPass} />
                                <Route
                                  path='/forgot-password-helper'
                                  component={ForgotPassHelper}
                                />
                                <Route
                                  path='/confirm-registration/:token'
                                  component={ConfirmRegistration}
                                />
                                <Route
                                  path='/terms-and-conditions'
                                  component={TermsAndConditionsPage}
                                />
                                {runByClientApp({
                                  onCheckPhish: () => (
                                    <Route
                                      path='/live-scan'
                                      render={() => (
                                        <AnnonymousScanPage
                                          changeLoginError={this.changeLoginError}
                                        />
                                      )}
                                    />
                                  ),
                                })}
                                {runByClientApp({
                                  onCheckPhish: () => (
                                    <Route
                                      path='/domain-monitor'
                                      render={() => (
                                        <AnnonymousScanPage
                                          changeLoginError={this.changeLoginError}
                                        />
                                      )}
                                    />
                                  ),
                                })}
                                <Route path='/filterFaq' component={FilterFaq} />
                                <Route path='/_pics' component={PicFiles} />
                                {/* public insight */}
                                <Route
                                  path={`/public/insights`}
                                  render={() => <PublicInsights />}
                                />
                                <Route path={`/public/ip`} render={() => <PublicIpInsights />} />
                                <Route
                                  path={`/public/domain`}
                                  render={() => <PublicDomainInsights />}
                                />
                                {/* digest link */}
                                <Route
                                  path={'/web/digest/:url_sha256'}
                                  render={() => <DigestInsights />}
                                />
                                <Route
                                  path={'/bulk-scan/digest/:url_sha256'}
                                  render={() => <DigestInsights />}
                                />
                                <Route
                                  path={'/domain-monitoring/digest/:url_sha256'}
                                  render={() => <DigestInsights />}
                                />
                                <AppWrapper />
                              </Switch>
                            </div>
                          </Router>
                        )}
                      </ThemeContext.Consumer>
                    </ActiveAppContextProvider>
                  </ThemeContextProvider>
                </ActiveBrowserTabContextProvider>
              </TyposquatContextProvider>
            </CheckPhishContactUsContextProvider>
          </CheckphishSubscriptionProvider>
        </ActiveRoutesContextProvider>
      </AppSettingContextProvider>
    );
  }
}
const mapDispatchToProps = {
  runByClientApp: appActions.runByClientApp,
};
const mapStateToProps = (state: AppState) => {
  const { loggedIn } = state.login;
  const { user } = state.dashboardReducer;
  return { isLoggedIn: loggedIn, user };
};
const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
