import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../Common/PageTitle';
import { AlertActionsTypes } from '../../../../constants';
import { AppState } from '../../../../helpers';
import { DashBoardDto } from '../../../../constants';
import EntityWidget from '../Widgets/EntityWidget';
import '../../Styles/DarkWebDashboardv2.scss';
import OtherEntityWidget from '../Widgets/OtherEntityWidget';
import DarkWebService from '../../../../services/darkWeb.service';
import { IEntityLableDataSet } from '../../types';
import EmailDomainEntityIcon from '../../../../assets/darkwebV2/EmployeeEmailDomainDarkWeb.png';
import CustomerEmailDomainEntityIcon from '../../../../assets/darkwebV2/CustomerEmailDomainDarkWeb.png';
import EmailAddressEntityIcon from '../../../../assets/darkwebV2/EmailAddressDarkWeb.png';
import CreditCardEntityIcon from '../../../../assets/icons/CreditCardEntityDarkWeb.svg';
import ExecutiveEntityIcon from '../../../../assets/darkwebV2/EmployeeDarkWeb.png';
import OthersEntityIcon from '../../../../assets/icons/OtherEntityIconDark.svg';
import { ENTITY_TYPE, generateEnitityLables } from '../../DarkWebConstants';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { alertActions } from '../../../../actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import ThemeContext from '../../../../context/ThemeContext';
import { useContext } from 'react';
import { initialAnalyticsData } from '../../DarkWebConstants';
import { IAnalyticsData } from '../../types';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import LockBanner from '../../../Drawer/CheckPhishV2/LockBanner';
import { titleMapper } from '../../../Drawer/CheckPhishV2/Constants';
import { getBreachDescriptions } from '../Common/darkweb-requests';
import { setBreachDescriptions } from '../../../../reducers/darkWeb.reducer';
import DataLeakDetailsDrawer from '../Common/DataLeakDetailsDrawer';
import { darkwebMocAnalyticskData } from '../../../../constants';

const darkWebService = new DarkWebService();

interface IDarkWebDashboardV2 {}

type Props = IDarkWebDashboardV2;

const DarkWebDashboardV2 = (props: Props) => {
  window.document.title = 'Dark Web Dashboard';
  const [entityTypes, setEnitityTypes] = useState<string[]>([]);
  const [analyticsData, setAnalyticsData] = useState<IAnalyticsData>(initialAnalyticsData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isDemo = window.location.href.includes('premium') || false;

  const entityLables: IEntityLableDataSet = generateEnitityLables(
    EmailDomainEntityIcon,
    EmailAddressEntityIcon,
    CreditCardEntityIcon,
    ExecutiveEntityIcon,
    OthersEntityIcon,
    CustomerEmailDomainEntityIcon,
  );

  const widgetOrder = [
    ENTITY_TYPE.EMAIL_DOMAIN,
    ENTITY_TYPE.CUSTOMER_EMAIL_DOMAIN,
    ENTITY_TYPE.EMAIL_ADDRESS,
    ENTITY_TYPE.CREDIT_CARD,
    ENTITY_TYPE.EXECUTIVE_MONITORING,
    ENTITY_TYPE.OTHERS,
  ];
  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);

  const setWidgetData = (res: any) => {
    const entityTypes = Object.keys(res);
    const entityList: string[] = widgetOrder.filter(
      (item: any) => entityTypes.indexOf(item) !== -1 && !_.isEmpty(res[item]),
    );
    const analyticsData = { ...initialAnalyticsData };
    entityList.forEach((item: string) => {
      const data = res[item].map((searchTermData: any) => {
        if (item === ENTITY_TYPE.OTHERS) {
          return {
            category: searchTermData.category || '',
            times: searchTermData.count || 0,
            created_ts: searchTermData.metadata.created_ts || '',
            searchTerm: searchTermData.searchTerm || '',
            entity: searchTermData.entity || '--',
          };
        }
        return {
          searchTerm: searchTermData.searchTerm || '',
          searchTermId: searchTermData.searchTermId?.[0] || '',
          sources: searchTermData.values.map((sources: any) => ({
            label: sources.platform,
            value: sources.doc_count.value,
          })),
          dataSetItem: {
            unique: searchTermData.unique_doc_count || 0,
            total: searchTermData.doc_count || 0,
          },
          passwordDataSet: {
            high: searchTermData.plain_text_passwords || 0,
            medium: searchTermData.hashed_passwords || 0,
            low: searchTermData.without_passwords || 0,
          },
          cardDataSet: {
            high: searchTermData.card_with_details || 0,
            medium: searchTermData.unexpired_cards || 0,
            low: searchTermData.card_without_details || 0,
          },
        };
      });
      analyticsData[item] = [...data];
    });
    setAnalyticsData(analyticsData);
    setEnitityTypes(entityList);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    isDemo
      ? setWidgetData(darkwebMocAnalyticskData)
      : darkWebService
          .getDashboardV2Analytics()
          .then(res => {
            setWidgetData(res);
          })
          .catch(err => {
            dispatch(alertActions.error('Error in fetching data.'));
            console.error(err);
          })
          .finally(() => setIsLoading(false));
  }, [user]);

  const setExpandedByDefault = (widgetIndex: number) => {
    const totalWidgets = Object.keys(entityTypes).length;
    if (totalWidgets < 3) return true;
    return widgetIndex === totalWidgets && widgetIndex % 2 == 1;
  };

  useEffect(() => {
    try {
      const fetchbBreachDescriptions = async () => {
        const response = await getBreachDescriptions();
        if (response) {
          dispatch(setBreachDescriptions(response));
        }
      };
      void fetchbBreachDescriptions();
    } catch (error) {
      console.log('Error in fetching breach descriptions', error);
    }
  }, [dispatch]);

  return (
    <div className='dark-web-dashboard-container'>
      <PageTitle
        title={isDemo ? 'Dark Web Demo Dashboard' : 'Dashboard'}
        className={'dashboard-page-title'}
      />
      {isDemo && (
        <LockBanner
          showLockedIcon={false}
          heading={<>Don’t worry! This isn’t your data.</>}
          subHeading={
            <>
              Use Bolster's sample data to explore the functionality of{' '}
              <span className='highlight normal-text'>{titleMapper['darkweb']} monitoring</span>{' '}
              dashboard.
            </>
          }
          upgradeBtnText='Request Demo'
        />
      )}
      <LoadingWrapper isLoading={isLoading}>
        <div className='widgets-wrapper'>
          {entityTypes.map((entity: string, index: number) =>
            entity === ENTITY_TYPE.OTHERS ? (
              <OtherEntityWidget
                key={index}
                widgetData={{ entityData: analyticsData[entity] }}
                entityLables={entityLables[entity]}
                expandByDefault={setExpandedByDefault(index + 1)}
              />
            ) : (
              <EntityWidget
                key={index}
                type={entity}
                entityLables={entityLables[entity]}
                widgetData={{
                  entityData: analyticsData[entity],
                }}
                expandByDefault={setExpandedByDefault(index + 1)}
              />
            ),
          )}
          <DataLeakDetailsDrawer />
        </div>
      </LoadingWrapper>
    </div>
  );
};

export { DarkWebDashboardV2 };
