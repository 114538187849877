import React, { useEffect, useMemo } from 'react';
import './NavItem.scss';
import { Link } from 'react-router-dom';
import { navItemIsActive } from '../../../helpers/url-helpers';
import ExpandableNavItem from './ExpandableNavItem';
import _ from 'lodash';
import { ThemeModes } from '../../../constants';
import LockIcon from '../../../assets/icons/LockGray.svg';
import LockIconDark from '../../../assets/icons/LockGrayDark.svg';

type Props = {
  navItem: any;
  selectedTheme: any;
  triggerPaywall: Function;
  isExpanded: boolean;
};

const NavItemV2 = ({ navItem, selectedTheme, triggerPaywall, isExpanded }: Props) => {
  const {
    id,
    label,
    href,
    disabled = false,
    isNew = false,
    subItems = [],
    onClick,
    isLocked,
  } = navItem;

  const onClickFn = useMemo(() => {
    if (onClick) {
      const value = onClick();
      if (value.type === 'hubspot_form') {
        return () => triggerPaywall(value.formType);
      }
    }
    return _.noop;
  }, [onClick]);

  const lockIcon = useMemo(() => {
    return selectedTheme === ThemeModes.LIGHT.toLocaleLowerCase() ? LockIcon : LockIconDark;
  }, [selectedTheme]);

  if (!_.isEmpty(subItems)) {
    return (
      <ExpandableNavItem
        navItem={navItem}
        selectedTheme={selectedTheme}
        triggerPaywall={triggerPaywall}
        isExpanded={isExpanded}
      />
    );
  }

  return (
    <div
      data-testid={'navItem'}
      className={`nav-item-wrap nav-item ${navItemIsActive(href) ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }  `}
    >
      {disabled ? (
        <span id={id} className='nav-link sidebar-label'>
          {label}
        </span>
      ) : (
        <Link id={id} to={href} className='nav-link sidebar-label' onClick={onClickFn}>
          {label}
        </Link>
      )}
      {isExpanded && (
        <div className={`tags-wrap ${isNew && isLocked && ' position-relative'}`}>
          {isNew && <span className='is-new-tag'>New!</span>}
          {isLocked && <img className='lock-img' src={lockIcon} />}
        </div>
      )}
    </div>
  );
};

export default NavItemV2;
