import React from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import './LabelAndValue.scss';
import { Link } from 'react-router-dom';
import CopyButton, { ICopyButtonProps } from './CopyButton';
import RedirectButton from './RedirectButton';
import { Status, StatusLabels } from './statusLabels';
import { Tooltip } from '@mui/material';

interface IValueProps extends ICopyButtonProps {
  tooltip?: string;
  renderDom?: any;
  toLink?: string;
  longTextLineNumberLimit?: 0 | 1 | 2 | 3;
  customButton?: any;
  redirectButton?: boolean;
  status?: string;
}

export interface IComponentProps extends IValueProps {
  label: string;
  tooltip?: string;
  direction?: 'row' | 'column';
  noMarginBottom?: boolean;
  noColon?: boolean;
  width?: React.CSSProperties['width'];
  whiteSpace?: React.CSSProperties['whiteSpace'];
}
export interface ILabelAndValueProps extends Omit<IComponentProps, 'value'> {
  value?: string | string[];
  alignItems?: string;
}

const ValueDom = (props: IValueProps) => {
  const {
    value = '',
    renderDom = null,
    tooltip,
    longTextLineNumberLimit = 0,
    toLink = '',
    copyButton = false,
    redirectButton = false,
    status = '',
    onCopy = _.noop,
  } = props;
  let longTextClassName = 'card-value';
  if (longTextLineNumberLimit) {
    longTextClassName += ' long-text-ellipsis-' + longTextLineNumberLimit;
  }

  let content = _.isEmpty(toLink) ? (
    <Card.Text className={longTextClassName}> {value} </Card.Text>
  ) : (
    <Link className={longTextClassName} to={toLink}>
      {value}
    </Link>
  );

  if (longTextLineNumberLimit || tooltip) {
    content = (
      <Tooltip
        title={tooltip || value}
        placement='top-start'
        classes={{
          tooltip: 'table-source-url-tooltip',
        }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        {content}
      </Tooltip>
    );
  }

  return (
    <div className={'d-flex align-items-center'}>
      {renderDom ? renderDom : content}
      <CopyButton value={value} copyButton={copyButton} onCopy={onCopy} />
      <StatusLabels status={status} />
      {redirectButton && <RedirectButton value={value} />}
    </div>
  );
};

export const LabelAndValue = (props: ILabelAndValueProps): any => {
  const {
    label,
    value,
    renderDom = null,
    direction = 'row',
    noMarginBottom = false,
    redirectButton = false,
    customButton,
    noColon = false,
    alignItems,
    width = '100%',
    status,
    whiteSpace,
    className = '',
  } = props;
  const style = {
    display: 'flex',
    flexDirection: direction,
    alignItems: alignItems ?? (direction === 'row' ? 'center' : 'flexStart'),
    // width: width,
    whiteSpace: whiteSpace,
  };

  let compClassName = 'label-and-value-component';
  if (noMarginBottom) {
    compClassName += ' no-margin-bottom';
  }

  // TODO: We should return -- when value is null, undefined or empty string here,
  // so all usages of LabelAndValue dont have to add fallback them selves

  return (
    <div className={compClassName + ' ' + className} style={style}>
      <Card.Text className={`card-label direction-${direction}`}>
        {label + (noColon ? '' : ':')}
        {customButton}
      </Card.Text>

      {renderDom ? (
        <div className={'d-flex'}>
          {renderDom}
          {typeof value === 'string' && <CopyButton {...props} value={value} />}
          {typeof status === 'string' && <StatusLabels status={status as string} />}
          {typeof value === 'string' && redirectButton && <CopyButton {...props} value={value} />}
        </div>
      ) : typeof value === 'object' ? (
        _.map(value, (v, i) => <ValueDom {...props} value={v} key={v + i} />)
      ) : (
        <ValueDom {...props} value={value} />
      )}
    </div>
  );
};
