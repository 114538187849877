import _ from 'lodash';
import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { Overlay } from 'react-bootstrap';
import {
  AlertActionsTypes,
  appConstants,
  featureIsAvailable,
  setLocalStorageValue,
} from '../../../constants';
import ThemeContext from '../../../context/ThemeContext';
import DashboardService from '../../../services/dashboard.service';
import '../Checkbox';
import PillLabel from '../PillLabel';
import './Tags.scss';
import useTagsChangeHandler from '../CustomHooks/useTagsChangeHandler';
import { LoadingWrapper } from '../LoadingWrapper';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { CHECKBOX_STATES, Checkbox } from '../Checkbox';
import ShowAppliedTags from './ShowAppliedTags';
import { getTagsStyle } from './util';
import CustomTags from './CustomTags';
import { LoadingSpinner } from '../LoadingSpinner';
import { Dropdown } from '../Dropdown';
import { DotDotDot } from '../../../assets/SVGIcons';
import { setModifyCustomTag } from '../../../reducers/tags.reducer';
import { alertActions, dashboardActions } from '../../../actions';
import { setShouldTriggerAgGrigRefresh } from '../../../reducers/table.reducer';
import { TableContext } from '../Table/table.context';
import { FINDINGS_TABLE_IDS, MALICIOUS_TABLE_IDS } from '../../MonitorAndTakedown/constants';
import { useReadOnlyUser } from '../../../basic-hooks/useUserRoles';
import { Button } from '@mui/material';

export interface ITags {
  id: number;
  label: string;
  description: string;
  type: string;
  active: boolean;
  color: {
    id?: number;
    lightColor: string;
    darkColor: string;
  };
  createdBy: any;
  updatedBy: any;
  updatedDate: string;
  checkAll?: boolean;
}

export interface IBulkTagsPayload {
  urlShas256: string[];
  tagIdsToAdd: number[];
  tagIdsToRemove: number[];
}

export enum ETagsTypes {
  BOLSTER_RESTRICTED = 'BOLSTER_RESTRICTED',
  BOLSTER_PROVIDED = 'BOLSTER_PROVIDED',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
}

export interface ITagsProps {
  rowData: any;
  alertSuccess?: (message: string) => AlertActionsTypes;
  alertError?: (message: string) => AlertActionsTypes;
  onTagsChangeHandler?: (isAdded: boolean, url_sha256: string, newTags: any[]) => void;
  type?: string;
  bulkOption?: boolean;
  leftAligned?: boolean;
  showTagsOverlay?: boolean;
  hideTagsOverlayHandler?: () => void;
  onTagsChanged?: (show: boolean) => void;
  getUpdatedTags?: (payload: IBulkTagsPayload) => void;
  fromAgGrid?: boolean;
  agAnchorEl?: any;
  onClose?: () => void;
}

export enum ECustomTagOptionTypes {
  DELETE = 'delete',
  EDIT = 'edit',
}

const Tags = ({
  rowData,
  alertSuccess,
  alertError,
  onTagsChangeHandler,
  type,
  bulkOption = false,
  leftAligned = false,
  showTagsOverlay,
  hideTagsOverlayHandler,
  onTagsChanged,
  getUpdatedTags,
  fromAgGrid = false,
  agAnchorEl,
  onClose,
}: ITagsProps) => {
  const DEFAULT_DROPDOWN_OPTIONS = [
    { label: 'Delete Tag', value: ECustomTagOptionTypes.DELETE },
    { label: 'Edit Tag', value: ECustomTagOptionTypes.EDIT },
  ];
  const dashboardService = new DashboardService();

  const [appliedTags, setAppliedTags] = useState<ITags[]>([]);
  const [restrictedAppliedTags, setRestrictedAppliedTags] = useState<ITags[]>([]);

  const [selectedTags, setSelectedTags] = useState<ITags[]>([]);
  const [detectionData, setDetectionData] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [uncheckAllTags, setUncheckAllTags] = useState<any[]>([]);
  const [checkAllTags, setCheckAllTags] = useState<any[]>([]);
  const [prevTags, setPrevTags] = useState<any[]>([]);

  const { user } = useAppSelector(state => state.dashboardReducer);
  const { isGroupRelatedDataReady } = useAppSelector(state => state.tableReducer);
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);

  const [allProvidedTags, setAllProvidedTags] = useState<any[]>([]);

  const tagRefs = useRef(null);
  const { selectedTheme } = useContext(ThemeContext);

  const dispatch = useAppDispatch();
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);
  const tableIdField = useAppSelector(state => state.tableReducer.tableIdField);

  const { tableId } = useContext(TableContext);

  const { updateParentState } = useTagsChangeHandler({
    type,
    onTagsChangeHandler,
    selectedTags,
    detectionData,
    appliedTags,
  });

  useEffect(() => {
    if (rowData)
      setDetectionData({ ...rowData, url_sha256: rowData.url_sha256 || rowData.content_sha_256 });
  }, [rowData]);

  useEffect(() => {
    onInitialRender();
  }, [bulkOption, rowData]);

  useEffect(() => {
    showTagsOverlay && setShow(showTagsOverlay);
  }, [showTagsOverlay]);

  const mappedTypeAndColorForCurrentFindingTags = (rowData: any) => {
    return {
      ...rowData,
      tags: mappedTypeAndColorWithTag(rowData.tags),
    };
  };
  const mappedTypeAndColorForCurrentFindingBulkTags = (rowData: any[]) => {
    return rowData.map((row: any) => {
      return {
        ...row,
        tags: mappedTypeAndColorWithTag(row.tags),
      };
    });
  };

  useEffect(() => {
    setAllProvidedTags(providedTags);
  }, [providedTags]);

  // On initial render
  const onInitialRender = useCallback(() => {
    if (bulkOption) {
      const initialTags: any = [];
      const initialCheckAllTags: any = [];
      const count = {};
      rowData.forEach((row: any, index: any) => {
        const tagIds =
          row.tags?.map((tag: any) => {
            count[tag.id] = (count[tag.id] || 0) + 1;
            return tag.id;
          }) ?? [];
        initialTags.push({ urlSha256: row._id || row.url_sha256 || row.sha256, tagIds: tagIds });
      });

      for (const key in count) {
        if (count[key] === rowData.length) {
          initialCheckAllTags.push(parseInt(key));
        }
      }

      setPrevTags(initialTags);
      if (initialTags.length !== 0) {
        setCheckAllTags(initialCheckAllTags);
      }
      updateTags(mappedTypeAndColorForCurrentFindingBulkTags(rowData));
    } else {
      updateTags(mappedTypeAndColorForCurrentFindingTags(rowData));
    }
  }, [bulkOption, rowData]);

  const isReadOnlyUser = useReadOnlyUser();

  const onTagColClick = () => {
    if (!isReadOnlyUser) setShow(true);
  };

  const onCancel = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShow(false);
    setSelectedTags([]);
    setAllProvidedTags(providedTags);

    if (bulkOption) {
      hideTagsOverlayHandler && hideTagsOverlayHandler();
      onTagsChangeHandler && onTagsChangeHandler(false, '', []);
      onClose && onClose();
      clearSelectionAfterBulkAction();
    }
  };

  const updateTags = (findingData: any) => {
    if (!findingData) return;
    if (bulkOption) {
      const preSelectedTags = findingData.flatMap((finding: any) => {
        return _.filter(
          finding.tags,
          (tag: ITags) =>
            (tag.active && tag.type === ETagsTypes.BOLSTER_PROVIDED) ||
            (tag.active && tag.type === ETagsTypes.CUSTOMER_CREATED),
        );
      });

      setAppliedTags(preSelectedTags);
      setSelectedTags(preSelectedTags);
    } else {
      const preSelectedTags = _.filter(
        findingData.tags,
        (tag: ITags) =>
          tag.active &&
          (tag.type === ETagsTypes.BOLSTER_PROVIDED || tag.type === ETagsTypes.CUSTOMER_CREATED),
      );
      setAppliedTags(preSelectedTags);
      setSelectedTags(preSelectedTags);
      if (type === appConstants.CONTENT_TYPE.WEB || type === appConstants.CONTENT_TYPE.SOCIAL) {
        setRestrictedAppliedTags(
          _.filter(
            findingData.tags,
            (tag: ITags) => tag.active && tag.type === ETagsTypes.BOLSTER_RESTRICTED,
          ),
        );
      }
    }
  };

  const onSearchCreateTag = useCallback(
    newTags => {
      if (!newTags.length) {
        setAllProvidedTags(providedTags);
        return;
      }

      const filteredTags = allProvidedTags.filter((tag: ITags) => {
        return tag.label.toLowerCase().includes(newTags.toLowerCase());
      });

      setAllProvidedTags(filteredTags);
    },
    [providedTags, allProvidedTags],
  );

  const onSubmitBulkFindingTags = async () => {
    const bulkTagPayload: IBulkTagsPayload = {
      urlShas256: [],
      tagIdsToAdd: [],
      tagIdsToRemove: [],
    };

    prevTags.forEach((row: any) => {
      bulkTagPayload.urlShas256.push(row.urlSha256);
    });

    bulkTagPayload.tagIdsToAdd = checkAllTags;
    bulkTagPayload.tagIdsToRemove = uncheckAllTags;

    dashboardService
      .bulkAddTags(bulkTagPayload, type)
      .then(res => {
        if (res) {
          alertSuccess?.('Tags updated successfully');
          dispatch(alertActions.success('Tags updated successfully'));
          onTagsChanged && onTagsChanged(false);
        }
      })
      .catch(err => {
        alertError?.('Error updating tags');
      })
      .finally(() => {
        getUpdatedTags && getUpdatedTags(bulkTagPayload);
        setIsLoading(false);
        setShow(false);
        hideTagsOverlayHandler && hideTagsOverlayHandler();
        onClose && onClose();
        dispatch(setShouldTriggerAgGrigRefresh(true));
      });
  };

  const mappedTypeAndColorWithTag = (tags: ITags[]) => {
    if (!tags || tags.length === 0) return [];
    return tags.map((tag: ITags) => {
      return {
        ...tag,
        type: _.first(_.filter(providedTags, (item: ITags) => item.id === tag.id))?.type,
        color: _.first(_.filter(providedTags, (item: ITags) => item.id === tag.id))?.color,
      };
    });
  };
  const onSubmitSingleFindingTags = async () => {
    setIsLoading(true);

    const { url_sha256 } = detectionData;

    const payload: any[] = [];
    [...selectedTags, ...restrictedAppliedTags].forEach((tag: any) => {
      const tagPayload = {};
      tagPayload['tagId'] = tag.id;
      tagPayload['sha256'] = url_sha256;
      tagPayload['active'] = tag.active;
      payload.push(tagPayload);
    });

    try {
      const res = await dashboardService.addTags({ tags: payload }, type);
      if (res && res.tags && res.tags[url_sha256]) {
        alertSuccess?.('Tags updated successfully');
        const newTags: any = mappedTypeAndColorWithTag(res.tags[url_sha256]);
        setAppliedTags(newTags);
        setDetectionData((prevState: any) => {
          const newDetections = { ...prevState, tags: newTags };
          return newDetections;
        });
      }
      updateParentState();
    } catch (error) {
      alertError?.('Error updating tags');
    } finally {
      setIsLoading(false);
      setShow(false);
      dispatch(setShouldTriggerAgGrigRefresh(true));
    }
  };

  const onBulkTagsSelection = (checked: any, tag: ITags) => {
    const newCheckAllTags = checkAllTags;
    const newUnCheckAllTags = uncheckAllTags;
    if (checked.checkType === CHECKBOX_STATES.Checked) {
      !checkAllTags.includes(tag.id) && newCheckAllTags.push(tag.id);
      newUnCheckAllTags.includes(tag.id) &&
        newUnCheckAllTags.splice(newUnCheckAllTags.indexOf(tag.id), 1);

      setCheckAllTags(newCheckAllTags);
      setUncheckAllTags(newUnCheckAllTags);
    } else if (checked.checkType === CHECKBOX_STATES.Previous) {
      newCheckAllTags.includes(tag.id) &&
        newCheckAllTags.splice(newCheckAllTags.indexOf(tag.id), 1);
      newUnCheckAllTags.includes(tag.id) &&
        newUnCheckAllTags.splice(newUnCheckAllTags.indexOf(tag.id), 1);
    } else if (checked.checkType === CHECKBOX_STATES.Empty) {
      !newUnCheckAllTags.includes(tag.id) && newUnCheckAllTags.push(tag.id);
      newCheckAllTags.includes(tag.id) &&
        newCheckAllTags.splice(newCheckAllTags.indexOf(tag.id), 1);
      setUncheckAllTags(newUnCheckAllTags);
    }
    onTagsChanged && onTagsChanged(true);
  };

  const onSingleTagsSelection = (checked: boolean, tag: ITags) => {
    let isTagSelected = false;
    const newSelectedTags = selectedTags.map(prevTag => {
      if (prevTag.id === tag.id) {
        prevTag.active = checked;
        isTagSelected = true;
      }
      return prevTag;
    });

    if (!isTagSelected) {
      newSelectedTags.push({
        ...tag,
        active: checked,
      });
    }
    setSelectedTags(newSelectedTags);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (bulkOption) {
      setIsLoading(true);
      onSubmitBulkFindingTags();
      clearSelectionAfterBulkAction();
    } else {
      onSubmitSingleFindingTags();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setShouldTriggerAgGrigRefresh(false));
    };
  }, [dispatch]);

  const tagPreSelect = (tag: ITags) => {
    return _.find(appliedTags, item => item.id === tag.id) !== undefined;
  };

  const itemOnCheck = (checked: boolean, tag: ITags) => {
    if (bulkOption) {
      onBulkTagsSelection(checked, tag);
    } else {
      onSingleTagsSelection(checked, tag);
    }
  };

  const enterDeleteMode = useCallback(
    (tag: ITags) => {
      dispatch(
        setModifyCustomTag({
          selectedTag: tag,
          isDeleteMode: true,
          isEditMode: false,
          triggerFetchCall: false,
        }),
      );
      setShow(false);
    },
    [dispatch],
  );

  const enterEditMode = useCallback(
    (tag: ITags) => {
      dispatch(
        setModifyCustomTag({
          selectedTag: tag,
          isDeleteMode: false,
          isEditMode: true,
          triggerFetchCall: false,
        }),
      );
      setShow(false);
    },
    [dispatch],
  );

  const clearSelectionAfterBulkAction = () => {
    if (type === appConstants.CONTENT_TYPE.APP_STORE) {
      dispatch(dashboardActions.setFindingsTableUrls(tableIdField as FINDINGS_TABLE_IDS, [], type));
    }
    setLocalStorageValue(['savedSelectionState', tableId || tableIdField], []);
    dispatch(dashboardActions.setSelectedWebUrls(tableId as MALICIOUS_TABLE_IDS, []));
  };

  //render tag labels with checkboxes and colors for adding on the finding
  const renderLabels = () => {
    return allProvidedTags
      .filter((tag: ITags) => {
        if (tag.type !== ETagsTypes.BOLSTER_RESTRICTED) {
          return true;
        }
      })
      .map((tag: ITags, index: number) => {
        const initialTagCount = {};
        const initialTagIds = prevTags.flatMap(row => row.tagIds);
        initialTagIds.forEach(function (x) {
          initialTagCount[x] = (initialTagCount[x] || 0) + 1;
        });
        return (
          <div key={index} className='d-flex flex-row align-items-center justify-content-between'>
            <div className='d-flex flex-row align-items-center'>
              <Checkbox
                isStateless={false}
                onChange={(checked: any) => {
                  itemOnCheck(checked, tag);
                }}
                defaultChecked={tagPreSelect(tag)}
                bulkOption={bulkOption}
                checkAll={
                  checkAllTags.includes(tag.id) || initialTagCount[tag.id] === rowData?.length
                }
                checkPartial={initialTagIds.includes(tag.id)}
              />
              <div className='label-wrapper'>
                <PillLabel label={tag.label} customStyle={getTagsStyle(tag, selectedTheme)} />
              </div>
            </div>

            {tag.type === ETagsTypes.CUSTOMER_CREATED && (
              <div className='tag-action-wrapper'>
                <Dropdown
                  options={DEFAULT_DROPDOWN_OPTIONS}
                  onChange={selection => {
                    if (selection.value === ECustomTagOptionTypes.DELETE) {
                      enterDeleteMode(tag);
                    } else if (selection.value === ECustomTagOptionTypes.EDIT) {
                      enterEditMode(tag);
                    }
                    onClose && onClose();
                  }}
                  btnClassName={'bg-transparent'}
                  hideArrow
                  fixedPlaceholder={<DotDotDot />}
                  className='remove-outline px--end-14'
                />
              </div>
            )}
          </div>
        );
      });
  };

  const isOldTable = !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

  return (
    <div ref={tagRefs} className='tags-column-container'>
      <div
        onClick={onTagColClick}
        className='tags-column-wrapper'
        data-testid='tags-column-wrapper'
      >
        {!bulkOption && (
          <ShowAppliedTags
            bulkOption={bulkOption}
            appliedTags={appliedTags}
            restrictedAppliedTags={restrictedAppliedTags}
          />
        )}
      </div>
      <Overlay
        target={fromAgGrid ? agAnchorEl : tagRefs.current}
        show={show}
        placement={leftAligned ? 'left-start' : 'auto'}
        flip={!leftAligned}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            className={`${selectedTheme} tags-container`}
            style={{
              ...props.style,
            }}
            data-test-id='tags-overlay-container'
          >
            <CustomTags
              onSearchCreateTag={onSearchCreateTag}
              allProvidedTags={allProvidedTags}
              onCancel={onCancel}
            />
            <LoadingWrapper
              isLoading={isOldTable || !bulkOption ? false : !isGroupRelatedDataReady}
            >
              {allProvidedTags.length > 0 && (
                <div className='tags-label-wrapper'>{renderLabels()}</div>
              )}
            </LoadingWrapper>
            {allProvidedTags.length > 0 && (
              <div className='tags-btn-wrapper'>
                <Button
                  className='cancel-btn-wrapper'
                  onClick={onCancel}
                  data-testid='cancel-tag-overaly'
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className='tags-apply-btn'
                  variant='contained'
                >
                  {isLoading ? (
                    <LoadingSpinner
                      size={20}
                      customInnerClass='tags-loading'
                      customOuterClass='tags-loading-wrapper'
                    />
                  ) : (
                    'Apply'
                  )}
                </Button>
              </div>
            )}
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default Tags;
