import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { useAgGridEvents } from './useAgGridEvents';
import { LicenseManager } from 'ag-grid-enterprise';
import { generateAgGridGlobalTakedownTableColumnDefs } from './ag-col-defs';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';

import { ITableApiColumn } from '../table.api';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import useOnClientSideTableExport from '../../CustomHooks/useOnClientSideExport';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { convertToFetchGlobalWebTableParams, getAgGridThemeClassName } from './ag-utils';

import { ExportCSV } from '../../Export/ExportCSV';
import TakedownTimelineContainer from '../../../GlobalTakedown/TakedownTimelineContainer';
import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';
import useAgGridFilterChange from './useAgGridFilterChange';
import { fetchGlobalTakedownWebData } from '../../../GlobalTakedown/web-requests';
import { massageGlobalTakeDownWebData } from '../../../GlobalTakedown/GlobalTakeDownUtils';
import { TABLE_EMPTY_RESULTS_MESSAGE, paginationPageSizeSelector } from './constants';
import { alertActions } from '../../../../actions';
import {
  setGtdWebBolsterTakeDownCount,
  setGtdWebCustomerTakeDownCount,
} from '../../../../reducers/table.reducer';
import { useSearchTermFilters } from './useSearchTermFilters';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const AgGridGlobalTakedownTable = ({
  columns,
  tableId,
  className,
  style,
}: {
  columns: ITableApiColumn[];
  tableId: 'global_takedown_table_web';
  className?: string;
  style?: React.CSSProperties;
}) => {
  const agRef = useRef<AgGridReact>(null);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs } = useContext(TableContext);

  useSearchTermFilters({ agRef, columns, colFieldForSearchTerm: 'source_feed' });

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({
      tableId,
    });

  const colDefs = useMemo<ColDef[]>(() => {
    return generateAgGridGlobalTakedownTableColumnDefs({
      columns,
      firstRenderDisplayColumnIdSet,
      tableId,
    });
  }, [columns, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const isFilterModelCacheUsed = useRef(false);
  const dispatch = useAppDispatch();

  const { handleFilterClick } = useAgOnFilterClick({ agRef });
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[] } = { data: [] };
        try {
          const apiParams = convertToFetchGlobalWebTableParams(agRequest, tableId);
          apiParams['pageNumber'] = params.api?.paginationGetCurrentPage?.() || 0;
          apiParams['pageSize'] = params.api?.paginationGetPageSize();
          const rawResponse = await fetchGlobalTakedownWebData({
            order: apiParams.order,
            pageSize: apiParams.pageSize,
            pageNumber: apiParams.pageNumber,
            filter: apiParams.filter || undefined,
          });
          response.data = massageGlobalTakeDownWebData(rawResponse.findings, brandInfo);
          dispatch(setGtdWebBolsterTakeDownCount(rawResponse.bolsterTakeDownCount));
          dispatch(setGtdWebCustomerTakeDownCount(rawResponse.customerTakeDownCount));
          setLocalStorageValue(['currentTableId'], tableId);
          setAllRowData(response.data);
          if (rawResponse.totalCount === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: rawResponse.totalCount,
            });
          }, 0);
        } catch (error) {
          console.log('Error fetching data :', error);
          params.fail();
        }
      },
    };
  }, [tableId, brandInfo]);

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
      const savedColumnState = getLocalStorageValue(['savedColumnState', tableId]);
      agRef.current?.api.applyColumnState({
        state: savedColumnState,
        applyOrder: true,
      });
    },
    [datasource],
  );

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const isExportCSVCalled = useAppSelector(state => state.tableReducer.isExportCSVCalled);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data.primary_domain}-${params.data.url_sha256}`;
  }, []);

  const { handleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  const { onExportCSV } = useOnClientSideTableExport(isExportCSVCalled, agRef, tableId, columns);

  const { handleFirstDataRendered, handleOnPaginationChanged, currentPaginationSize } =
    usePersistentTablePageNumber(tableId);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData: allRowData,
  });

  useOnAgGridRefresh(agRef);

  const detailCellRenderer = useCallback(TakedownTimelineContainer, []);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme) + ` ${className}`}
      style={style}
    >
      <div className='tvc-table-description-wrapper'>
        <div className='tvc-table-description'>
          Table below shows In Progress Takedowns and Takedowns that were completed in last 30 days.
        </div>
        <ExportCSV onClick={onExportCSV} />
      </div>
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        rowModelType='serverSide'
        animateRows={true}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        onFilterChanged={handleFilterChanged}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
        autoGroupColumnDef={autoGroupColumnDef}
        detailRowAutoHeight={true}
        masterDetail={true}
        detailCellRenderer={detailCellRenderer}
        rowHeight={50}
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={paginationPageSizeSelector}
        cacheBlockSize={currentPaginationSize}
        embedFullWidthRows={true}
      />
    </div>
  );
};

export default AgGridGlobalTakedownTable;
