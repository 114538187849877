import React, { useCallback, useEffect, useMemo } from 'react';
import './InsightsPaginationControls.scss';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  appConstants,
  getLocalStorageValue,
  getTimestampAndUrlSHA256,
  setLocalStorageValue,
} from '../../../constants';
import { history } from '../../../helpers';
import { setShouldFetchInsightsOnNext } from '../../../reducers/insightsContainer.reducer';
import { setPreviousUrlSha } from '../../../reducers/table.reducer';
import _ from 'lodash';
import { Button, MobileStepper } from '@mui/material';
import useOnPaginationControlsRefreshed from './useOnPaginationControlsRefreshed';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

export default function InsightsPaginationGroupedViewControls({
  moduleType,
}: {
  moduleType: string;
}) {
  const [timestamp, sha256] = getTimestampAndUrlSHA256() ?? [];

  const dispatch = useAppDispatch();
  const currentTableId = getLocalStorageValue('currentTableId');
  const groupedPaginationControls = useAppSelector(
    state => state.insightsContainerReducer.groupedPaginationControls,
  );

  const currentGroupedKey = useAppSelector(
    state => state.insightsContainerReducer.currentGroupParentKey,
  );

  const [currentUrlIndex, setCurrentUrlIndex] = React.useState(0);

  const { currentPage, totalPages, totalItems, pageSize } =
    groupedPaginationControls?.[currentTableId as string]?.[currentGroupedKey] ?? {};

  useOnPaginationControlsRefreshed({
    currentTableId: currentTableId as string,
    sha256,
    setCurrentUrlIndex,
    moduleType,
  });

  useEffect(() => {
    const urlShaAndTimestamps: { urlSha256: string; timestamp: number }[] =
      groupedPaginationControls?.[currentTableId as string]?.[currentGroupedKey]
        ?.urlShaAndTimestamps;

    if (urlShaAndTimestamps && urlShaAndTimestamps.length > 0) {
      const index = urlShaAndTimestamps.findIndex(item => item.urlSha256 === sha256);
      setCurrentUrlIndex(index);
    }
  }, [currentTableId, currentGroupedKey, groupedPaginationControls, sha256]);

  //For web module
  const redirectInsightsPage = useCallback(
    (shasAndTimestamp: { urlSha256: string; timestamp: number }) => {
      history.push(`/web/insights/${shasAndTimestamp?.timestamp}/${shasAndTimestamp?.urlSha256}`);
      dispatch(setPreviousUrlSha(shasAndTimestamp?.urlSha256));
    },
    [dispatch],
  );

  const onNextUrl = useCallback(() => {
    let activeIndex = currentUrlIndex;
    activeIndex = activeIndex + 1;

    const getTimestampAndUrlSHA256 =
      groupedPaginationControls?.[currentTableId as string]?.[currentGroupedKey]
        ?.urlShaAndTimestamps?.[activeIndex];

    redirectInsightsPage(getTimestampAndUrlSHA256);

    dispatch(setShouldFetchInsightsOnNext(true));
    setCurrentUrlIndex(activeIndex);
  }, [
    groupedPaginationControls,
    currentTableId,
    currentGroupedKey,
    currentUrlIndex,
    redirectInsightsPage,
    dispatch,
  ]);

  const onPrevUrl = useCallback(() => {
    let activeIndex = currentUrlIndex;
    activeIndex = activeIndex - 1;

    const getTimestampAndUrlSHA256 =
      groupedPaginationControls?.[currentTableId as string]?.[currentGroupedKey]
        ?.urlShaAndTimestamps?.[activeIndex];

    redirectInsightsPage(getTimestampAndUrlSHA256);

    setCurrentUrlIndex(activeIndex);
    dispatch(setShouldFetchInsightsOnNext(true));
  }, [
    currentGroupedKey,
    currentTableId,
    currentUrlIndex,
    dispatch,
    groupedPaginationControls,
    redirectInsightsPage,
  ]);

  const activeStep = currentPage * pageSize + currentUrlIndex;

  return (
    <div className='insights-pagination-controls' data-testid='insights-pagination-controls'>
      <MobileStepper
        steps={totalItems || 0}
        variant='text'
        position='static'
        activeStep={activeStep || 0}
        sx={{ maxWidth: 250, flexGrow: 1, alignItems: 'center', backgroundColor: 'transparent' }}
        nextButton={
          <Button
            size='small'
            onClick={onNextUrl}
            disabled={currentPage >= totalPages - 1 && currentUrlIndex >= totalItems - 1}
            data-testid='next-url'
          >
            Next
            <KeyboardArrowRightOutlinedIcon />
          </Button>
        }
        backButton={
          <Button
            size='small'
            onClick={onPrevUrl}
            disabled={currentUrlIndex <= 0 && currentPage === 0}
            data-testid='prev-url'
          >
            <KeyboardArrowLeftOutlinedIcon />
            Previous
          </Button>
        }
      />
    </div>
  );
}
