import React, { Fragment, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import './SideBar_v2.scss';
import CheckPhishSideBar from './CheckPhishV2/CheckPhishSideBar';
import { navItemIsActive, NavItems } from './TopNavigationBar';
import { DRAWER_SUB_ITEMS, EMenuType, MOBILE_L1_DRAWER } from './RouteOptions';
import { history } from '../../helpers';
import {
  BigArrowRightIcon,
  CloseNavIcon,
  ChevronDownIcon,
  MenuCloseIcon,
} from '../../assets/SVGIcons';
import useWindowSize from '../Common/WindowSizeCustomHook';
import ActiveAppContext from '../../context/CheckphishActiveAppContext';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import CheckPhishContactUsContext, {
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import PlanChip from '../Pricing/Common/PlanChip';
import { PRICING_PLANS } from '../Pricing/types';
import { PRICING_PAGE_PATH } from '../Pricing/constants';
import { Link } from 'react-router-dom';
import { appOptions } from './CheckPhishV2/Constants';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

export default function SideBarV2({
  user,
  brandInfo,
  navItems,
  navHeader,
  onSelectedPage,
  hideSideBar,
  prevPage = null,
  currentPage = null,
  isCommunity,
}: any) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [prevUrl, updatePrevUrl] = useState('/bulk-scan');
  const windowWidth = useWindowSize().width;
  const [isDarkMode, selectedTheme] = useIsDarkMode();
  const { selectedApp } = useContext(ActiveAppContext);
  const { setShowFeedbackPopup, setAfterCancelationFeedback } = useContext(
    CheckPhishContactUsContext,
  );
  const { currentTyposquatSubscription } = useContext(CheckphishSubscriptionContext);

  useEffect(() => {
    setIsDrawerOpen(true);
  }, []);

  useEffect(() => {
    if (currentPage === '/web/dashboard') {
      if (windowWidth > 1200) {
        setIsDrawerOpen(true);
      } else {
        setIsDrawerOpen(false);
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    if (currentPage?.length && prevPage?.length) {
      const currentUrl = currentPage.split('/').slice(1, 4).join('/');
      const prevUrl = prevPage.split('/').slice(1, 4).join('/');
      //do not update the prev url if we are moving between tabs on insight page.
      if (currentUrl !== prevUrl) {
        updatePrevUrl(prevUrl);
      }
    }
  }, [currentPage, prevPage]);

  const onHideL2Drawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  if (
    hideSideBar ||
    !navItems ||
    (navItems[0]?.hideDrawer !== undefined && navItems[0]?.hideDrawer === true)
  ) {
    return null;
  }

  return (
    <div className={'side-bar-container' + (!isDrawerOpen ? ' border-right-only' : '')}>
      <div className={`${!isDrawerOpen ? 'open-drawer-button-wrapper' : ''}`}>
        {!isDrawerOpen && (
          <div onClick={onHideL2Drawer}>
            <MenuCloseIcon />
          </div>
        )}
      </div>
      <div
        className={
          'side-bar-web-2nd side-bar-web-level' +
          (isDrawerOpen ? '' : ' width-60') +
          (isCommunity ? ' auto-height-overflow' : '')
        }
      >
        {isCommunity ? (
          <AuthenticationWrapper>
            {selectedApp === appOptions[0] ? (
              <div
                className='navHeader'
                style={{
                  padding: '0 10px',
                }}
              >
                <PlanChip
                  planName={
                    currentTyposquatSubscription ? PRICING_PLANS.STARTER : PRICING_PLANS.FREE
                  }
                  planChipText={currentTyposquatSubscription ? 'Starter Plan' : 'Free Plan'}
                  customClass={'small-chip'}
                />
                <div
                  className='user-info'
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  <div className='user-name-checkphish'>
                    {user.first_name + ' ' + user.last_name}
                    <Link
                      to={PRICING_PAGE_PATH}
                      style={{
                        fontSize: '11px',
                        fontWeight: 'bold',
                        marginLeft: '3px',
                        textDecoration: 'none',
                      }}
                    >
                      Upgrade
                    </Link>
                  </div>
                  {isDrawerOpen && (
                    <div onClick={onHideL2Drawer}>
                      <CloseNavIcon color='grey' />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <CheckPhishSideBar
              hasTyposquatData={user.monitoredDomainPresent}
              selectedTheme={selectedTheme}
              navHeader={navHeader}
              isExpanded={isDrawerOpen}
            />
          </AuthenticationWrapper>
        ) : (
          <>
            <div className={'second-level-header'}>
              {navHeader !== 'Main' && (
                <div
                  className={'right-arrow-icon'}
                  onClick={() =>
                    onSelectedPage &&
                    onSelectedPage(MOBILE_L1_DRAWER(user, isCommunity)['Main'], 'Main')
                  }
                >
                  {isDarkMode ? (
                    <img
                      src='/static/assets/dark/regular/sidebar-nav-close-dark.svg'
                      alt=''
                      width={22}
                    />
                  ) : (
                    <img
                      src='/static/assets/light/regular/sidebar-nav-close-light.svg'
                      alt=''
                      width={22}
                    />
                  )}
                </div>
              )}
              <div className={'directory-container'}>
                <div className='brand-name'>{_.get(brandInfo, ['brand', 'displayName'], '')}</div>
                <div className='navHeader'> {navHeader} </div>
              </div>
              {isDrawerOpen && (
                <div onClick={onHideL2Drawer}>
                  <MenuOpenRoundedIcon />
                </div>
              )}
            </div>
            <div className={'second-level-body'}>
              {navItems.map((navItem: any = {}, index: number) => {
                const { subItems, type } = navItem;
                if (type === EMenuType.MENU_HEADER) {
                  if (subItems?.length > 0) {
                    return (
                      <ExpandableItem
                        key={navItem.id + index}
                        isCommunity={isCommunity}
                        navItem={navItem}
                        user={user}
                        onSelectedPage={onSelectedPage}
                      />
                    );
                  } else {
                    const l1SubItems = DRAWER_SUB_ITEMS(user, isCommunity)[navItem.label];
                    if (!l1SubItems) return;
                    const l2SubItems = l1SubItems[0].subItems;
                    if (l1SubItems.length === 1 && l2SubItems.length) {
                      return (
                        <ExpandableItem
                          key={navItem.id + index}
                          navItem={navItem}
                          user={user}
                          onSelectedPage={onSelectedPage}
                          subItems={l2SubItems}
                        />
                      );
                    }
                  }
                }

                if (
                  navItem?.subItems?.length === 0 &&
                  (navItem.label === 'Dashboard' ||
                    navItem.label === 'SETUP' ||
                    navItem.label === 'Live Scan' ||
                    navItem.label === 'All Scans')
                ) {
                  return (
                    <Fragment key={index}>
                      <NavItems
                        user={user}
                        navItem={navItem}
                        subItemClassName={'dashboard-label-wrapper'}
                        iconForSideBar={true}
                      />
                    </Fragment>
                  );
                }
                return (
                  <div className={'nav-sub-item-container'} key={index}>
                    {navItem.label && navItem.subItems.length > 0 && navHeader === 'Main' && (
                      <div
                        className={
                          'nav-sub-item-mobile' + (navItemIsActive(navItem) ? ' selected' : '')
                        }
                        onClick={event => {
                          onSelectedPage &&
                            onSelectedPage(
                              DRAWER_SUB_ITEMS(user, isCommunity)[navItem.label],
                              navItem.label,
                            );
                          if (!navItem.disabled) {
                            if (navItem.href) {
                              history.push(navItem.href);
                            } else {
                              history.push(window.location.pathname);
                            }
                          }
                        }}
                      >
                        {navItem.label}
                      </div>
                    )}
                    <div>
                      {navItem.subItems?.map((item: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            <NavItems
                              user={user}
                              navItem={{ ...item, href: item.disabled ? null : item.href }}
                              prevUrl={prevUrl}
                              subItemClassName={`drawer-sub-item ${
                                item.disabled ? 'disabled-sub-item' : ''
                              }`}
                              iconForSideBar={true}
                            />{' '}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {isDrawerOpen && selectedApp.value === 'checkphish' && isCommunity && (
        <>
          {/* <TyposquatSideBanner isDrawerOpen={isDrawerOpen} /> */}
          <span
            className='feedback-text'
            onClick={() => {
              setShowFeedbackPopup(true);
              setAfterCancelationFeedback(false);
            }}
          >
            Submit Feedback
          </span>
        </>
      )}
    </div>
  );
}

export const ExpandableItem = ({ navItem, user, onSelectedPage, subItems, isCommunity }: any) => {
  const [expanded, setExpended] = useState(true);
  const { label } = navItem;
  subItems = subItems || navItem.subItems;

  const toggleExpended = () => {
    setExpended(!expanded);
  };

  return (
    <div
      className={'expandable-item-component nav-sub-item-container ' + (expanded ? 'expanded' : '')}
    >
      <div
        className={'nav-sub-item-mobile' + (navItemIsActive(navItem) ? ' selected' : '')}
        onClick={toggleExpended}
      >
        <div className={'expandable-item d-flex align-items-center justify-content-between w-100'}>
          <div>{label}</div>
          <ChevronDownIcon className='chevron-icon black' />
        </div>
      </div>
      <div className='nav-sub-items-container'>
        {_.map(subItems, subItem =>
          DRAWER_SUB_ITEMS(user, isCommunity)[subItem.label] ? (
            subItem.id === '/marketplace/dashboard' && user?.brand !== 'bankofbolster' ? null : (
              <div
                key={subItem.id}
                className={'drawer-sub-item w-100' + (navItemIsActive(subItem) ? ' selected' : '')}
                onClick={event => {
                  onSelectedPage &&
                    onSelectedPage(
                      DRAWER_SUB_ITEMS(user, isCommunity)[subItem.label],
                      subItem.label,
                    );
                }}
              >
                <div className='w-100'>{subItem.label}</div>
                <div>
                  <BigArrowRightIcon color='black' />
                </div>
              </div>
            )
          ) : (
            <NavItems
              key={subItem.id}
              user={user}
              navItem={subItem}
              subItemClassName={'drawer-sub-item'}
            />
          ),
        )}
      </div>
    </div>
  );
};
