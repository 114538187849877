import React from 'react';
import { Card } from '@mui/material';
import './CardWrapper.scss';

type Props = {
  children: React.ReactNode;
  customClassName?: string;
};

function CardWrapper({ children, customClassName = '' }: Props) {
  return (
    <Card
      variant={'outlined'}
      style={{ display: 'flex', padding: '24px', width: '100%', height: '100%' }}
      className={'card-wrapper ' + customClassName}
    >
      {children}
    </Card>
  );
}

export default CardWrapper;
