import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { alertActions, appActions } from '../../actions';
import { mapNetworkProviderToLink } from '../../constants/insights.constants';
import {
  appConstants,
  DISPLAYED_NULL,
  featureIsAvailable,
  getTimestampAndUrlSHA256,
} from '../../constants';
import ThemeContext from '../../context/ThemeContext';
import { AppState } from '../../helpers';
import UgcService from '../../services/ugc.service';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { Annotation } from '../Common/Annotation';
import { Dropdown } from '../Common/Dropdown';
import { LabelAndValue } from '../Common/LabelAndValue';
import Tags from '../Common/Tags/Tags';
import {
  SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME,
} from '../SocialMedia/constants';
import { EFindingStatus } from '../Ugc/Types/ugc.types';
import './insights.scss';
import Map from './map';
import ScanSettings from './ScanSettings';
import { ELoggedIn } from '../../reducers';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { DEFAULT_INSIGHT_TEMPLATE } from '../InsightsContainer/InsightsContainer';
import { DISPOSITION_FILTERS } from '../ThreatIntelligence/ThreatIntelligenceComponent';
import { getScreenshotUrl } from '../../helpers/screenshotUrl';
import ToolTip from '../Common/ToolTip';
import moreInfoIcon from '../../assets/icons/Info.svg';
import { IInsightsState, InsightProps, UgcScanCategoryType } from './constants';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID } from '../AbuseMailbox/constants';

export const renderDispositionFilter = (currentValue: any, onChange: any) => {
  return (
    <Dropdown
      boxStyle
      key={'dns-filter'}
      options={DISPOSITION_FILTERS}
      onChange={onChange}
      defaultSelection={currentValue}
    />
  );
};

const Insights: React.FC<InsightProps> = ({
  loggedIn,
  type,
  insightsData,
  isLoading,
  isPublic,
  webDashboardRoute,
  runByClientApp,
}) => {
  const [socialMediaState, setSocialMediaState] = useState<IInsightsState['socialMedia']>({
    url: '--',
    link: '--',
    isLoading: true,
  });
  const [timestamp, setTimestamp] = useState('');
  const [urlSHA256, setUrlSHA256] = useState('');

  const ugcService = new UgcService();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.dashboardReducer);

  useEffect(() => {
    const [timestampValue, urlSHA256Value] = getTimestampAndUrlSHA256();
    document.title = 'URL Insight | Dashboard | Bolster Platform';
    setTimestamp(timestampValue);
    setUrlSHA256(urlSHA256Value);
  }, [timestamp, urlSHA256]);

  const fetchData = () => {
    if (insightsData?.scanResults?.scanContext) {
      const scanContext = insightsData?.scanResults.scanContext;
      const scanCategoryType = insightsData?.scanResults.scanCategoryType;
      ugcService
        .getFinding(scanContext, UgcScanCategoryType[scanCategoryType])
        .then(finding => {
          let path = '';
          switch (finding.status) {
            case EFindingStatus.PENDING:
            case EFindingStatus.UNDER_REVIEW:
              path = SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME;
              break;
            case EFindingStatus.LIVE:
              path = SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME;
              break;
            case EFindingStatus.IN_PROGRESS:
            case EFindingStatus.PAUSED:
              path = SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME;
              break;
            case EFindingStatus.DOWN:
              path = SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME;
              break;
            case EFindingStatus.SAFELIST:
              path = SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME;
              break;
            default:
              path = SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME;
              break;
          }
          setSocialMediaState({
            url: finding.url,
            link: `${path}/insights/${scanContext}`,
            isLoading: false,
          });
        })
        .catch(err => {
          console.error(err);
          setSocialMediaState({
            url: DISPLAYED_NULL,
            link: '',
            isLoading: false,
          });
        });
    } else {
      setSocialMediaState({
        url: DISPLAYED_NULL,
        link: '',
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isNonBrandCustomer = () => {
    return user.type_name === 'non_brand';
  };

  const isUpperTierUser = () => {
    return featureIsAvailable(user, appConstants.FEATURE_CODE.WEB);
  };

  const socialMediaIsEnabled = () => {
    return featureIsAvailable(user, appConstants.FEATURE_CODE.SOCIAL_MEDIA);
  };

  const getDateFormat = (date: string | number) => {
    if (date === '--') return '--';
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  };

  const generateIpLink = (ip: string) => {
    const suffix = webDashboardRoute ? webDashboardRoute : `/${type}`;
    return `${suffix?.replace('_', '-')}/ip/${ip}`;
  };

  const renderNetworkProvider = (networkProvider: any) => {
    const isCommunity = runByClientApp({
      onBolster: () => false,
      onCheckPhish: () => true,
    });
    const { link } = mapNetworkProviderToLink(networkProvider);
    if (isCommunity && link) {
      return (
        <div className='network-wrapper'>
          <a href={link} rel='noreferrer noopener' target='_blank'>
            {networkProvider}
          </a>
          <ToolTip
            id='network-provider-tooltip'
            tooltip={'Visit CheckPhish community to get step by step guides take-down'}
            customClassName='blue-content-tooltip'
          >
            <img src={moreInfoIcon} alt='more info' className='more-info-icon' />
          </ToolTip>
        </div>
      );
    }
    return <p>{networkProvider}</p>;
  };

  const isNonBrand = isNonBrandCustomer();
  const isUpperTier = isUpperTierUser();
  const scanResults = insightsData?.scanResults ?? DEFAULT_INSIGHT_TEMPLATE.scanResults;
  const showJobId = window.location.pathname.split('/')[1] === 'domain-monitoring';
  const markerPosition = insightsData?.markerPosition ?? DEFAULT_INSIGHT_TEMPLATE.markerPosition;
  const ip = scanResults?.ip;
  const countryClassName = 'country-icon flag-icon flag-icon-' + scanResults?.countryCode;
  const ipDom =
    _.isEmpty(ip) || ip === '0.0.0.0' || ip === '--' ? (
      <LabelAndValue label={'IP Address'} value={'--'} direction={'column'} />
    ) : isPublic ? (
      <LabelAndValue
        label={'IP Address'}
        renderDom={<div className={'height-20 ip-url-value-wrapper'}>{ip}</div>}
        direction={'column'}
      />
    ) : (
      <LabelAndValue
        label={'IP Address'}
        renderDom={
          <Link to={generateIpLink(ip)} className={'height-20 ip-url-value-wrapper'}>
            {ip}
          </Link>
        }
        direction={'column'}
      />
    );
  const certDom =
    scanResults.cert === '--' ? (
      <LabelAndValue
        label={'Certificate Details'}
        renderDom={
          <div className='card-value card-text long-text-ellipsis-2'>
            <div>{scanResults.cert}</div>
            <div>{'  '}</div>
          </div>
        }
        direction={'column'}
      />
    ) : (
      <LabelAndValue
        label={'Certificate Details'}
        value={scanResults.cert + ': ' + insightsData?.certList.toString()}
        direction={'column'}
        longTextLineNumberLimit={2}
      />
    );

  const renderAllMXRecords = (allMXRecords: any[]) => {
    const result = _.map(allMXRecords, record => {
      return record.exchange;
    });
    return result.join('; ') || '--';
  };

  const onSuccessHandler = (message: string) => {
    dispatch(alertActions.success(message));
  };

  const onErrorHandler = (message: string) => {
    dispatch(alertActions.error(message));
  };
  return (
    <AuthenticationWrapper skipAuthentication={isPublic}>
      <div className='page-content insights-container'>
        <LoadingWrapper isLoading={isLoading}>
          {/* ----START: Scan Results---- */}
          <Row>
            <Col md={6} className='self-6'>
              <div>
                <Card bg='light' className='card-between'>
                  {runByClientApp({
                    onBolster: () => {
                      return (
                        loggedIn === ELoggedIn.true && (
                          <Tags
                            rowData={scanResults}
                            type={
                              //Todo: use ABUSE_MAILBOX from appConstrants.Content_type instead of tableid's
                              type === ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID ? 'web' : type
                            }
                          />
                        )
                      );
                    },
                  })}
                  <Card.Header className='self-card-header'>Scan Results</Card.Header>
                  <Card.Body className='self-card-body'>
                    <Row>
                      <Col sm={6}>
                        <LabelAndValue
                          label={'Source URL'}
                          value={scanResults.srcURL}
                          direction={'column'}
                          longTextLineNumberLimit={1}
                          copyButton
                        />
                        {scanResults.redirectedChains &&
                          scanResults.redirectedChains.length > 0 && (
                            <div className='redirected-chains-wrapper'>
                              <div className='redirected-chains-label'>Redirected Chains:</div>
                              <div className='redirected-chains-list'>
                                {scanResults.redirectedChains.map((url, index) => (
                                  <div key={index} className='redirected-chains-item'>
                                    {url}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        <LabelAndValue
                          label={'Redirected URL'}
                          value={scanResults.redirectURL}
                          direction={'column'}
                          longTextLineNumberLimit={1}
                        />
                        {ipDom}
                        {!isNonBrand && (
                          <LabelAndValue
                            label={'Scan Source'}
                            value={scanResults.scanSource}
                            direction={'column'}
                          />
                        )}

                        {socialMediaIsEnabled() && (
                          <LabelAndValue
                            label={'Insight Page of Social Media Finding'}
                            value={socialMediaState.url}
                            copyButton={
                              !socialMediaState.isLoading && socialMediaState.url !== DISPLAYED_NULL
                            }
                            renderDom={
                              socialMediaState.isLoading ? (
                                <Spinner
                                  className='spinner'
                                  animation='border'
                                  variant='primary'
                                  size='sm'
                                />
                              ) : socialMediaState.url === DISPLAYED_NULL ? (
                                '--'
                              ) : (
                                <Link
                                  to={socialMediaState.link}
                                  className='card-value ip-url-value-wrapper long-text-ellipsis-1'
                                >
                                  {socialMediaState.url}
                                </Link>
                              )
                            }
                            direction={'column'}
                            longTextLineNumberLimit={1}
                          />
                        )}
                        {!isNonBrand && (
                          <LabelAndValue
                            label={'# Customer Scans'}
                            value={scanResults.brandScanCount}
                            direction={'column'}
                          />
                        )}
                        {!isNonBrand && (
                          <LabelAndValue
                            label={'# Bolster Scans'}
                            value={scanResults.bolsterScanCount}
                            direction={'column'}
                          />
                        )}
                        {isNonBrand && (
                          <LabelAndValue
                            label='Detection Date'
                            value={getDateFormat(scanResults.createdTS)}
                            direction={'column'}
                            longTextLineNumberLimit={1}
                          />
                        )}
                        {isNonBrand && !showJobId && (
                          <LabelAndValue
                            copyButton={scanResults.jobId !== '--'}
                            label={'Job ID'}
                            value={scanResults.jobId}
                            direction={'column'}
                            longTextLineNumberLimit={1}
                          />
                        )}
                        {certDom}
                        {isUpperTier && (
                          <>
                            <LabelAndValue
                              label={'Category'}
                              value={scanResults.finalCategory}
                              direction={'column'}
                              longTextLineNumberLimit={1}
                            />
                            <LabelAndValue
                              label={'# MX Records'}
                              value={scanResults.mxRecords}
                              direction={'column'}
                            />
                            <LabelAndValue
                              label={'List of MX Records'}
                              value={renderAllMXRecords(scanResults.allMXRecords)}
                              direction={'column'}
                            />
                          </>
                        )}
                      </Col>
                      <Col sm={6}>
                        <LabelAndValue
                          label={'Brand'}
                          value={scanResults.brand}
                          direction={'column'}
                        />
                        <LabelAndValue label={'TLD'} value={scanResults.tld} direction={'column'} />
                        {scanResults.location === '--' ? (
                          <LabelAndValue
                            label={'Location'}
                            value={scanResults.location}
                            direction={'column'}
                          />
                        ) : (
                          <LabelAndValue
                            label={'Location'}
                            renderDom={
                              <div className='dom-value'>
                                <span className={countryClassName} />
                                <span className='card-value card-text long-text-ellipsis-1'>
                                  {scanResults.location}
                                </span>
                              </div>
                            }
                            direction={'column'}
                          />
                        )}
                        <LabelAndValue
                          label={'Hosting Provider'}
                          renderDom={
                            _.isEmpty(scanResults.networkOwner)
                              ? '--'
                              : renderNetworkProvider(scanResults.networkOwner)
                          }
                          direction={'column'}
                          longTextLineNumberLimit={1}
                        />
                        <LabelAndValue
                          label={'ASN'}
                          value={scanResults.asn}
                          direction={'column'}
                          longTextLineNumberLimit={1}
                        />
                        {isUpperTier && (
                          <>
                            <LabelAndValue
                              label={'Registration Date'}
                              value={getDateFormat(scanResults.registrationDate)}
                              direction={'column'}
                              longTextLineNumberLimit={1}
                            />
                            <LabelAndValue
                              label={'Registrant'}
                              value={scanResults.registrant || '--'}
                              direction={'column'}
                            />
                            <LabelAndValue
                              label={'Nameservers'}
                              value={scanResults.nameservers}
                              direction={'column'}
                            />
                            <LabelAndValue
                              label={'Safe Browsing Blocked'}
                              value={scanResults.sfbDetected}
                              direction={'column'}
                            />
                          </>
                        )}
                        <LabelAndValue
                          label={'Scan Source Category'}
                          value={scanResults.sourceFeed}
                          direction={'column'}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card bg='light' className='card-between'>
                  <Card.Header className='self-card-header'>Scan Settings</Card.Header>
                  <Card.Body className='self-card-body'>
                    <ScanSettings
                      scanInfo={scanResults}
                      scanSettings={scanResults.scanSettings}
                      user={user}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col md={6} className='self-6'>
              <div>
                <Annotation
                  className={'img-fluid ' + (isNonBrand ? 'non-brand' : '')}
                  imagePath={getScreenshotUrl(scanResults.imagePath)}
                  annotations={scanResults.annotations}
                  brandMnemonic={isNonBrand ? '' : scanResults.brandId}
                  alt='scan result screenshot'
                />
              </div>
              <div>
                {/* ----START: Geo Location---- */}
                <Card bg='light' className='card-between geo-card'>
                  <Card.Header>Geo Location</Card.Header>
                  <Map
                    markerPosition={markerPosition}
                    location={scanResults.location}
                    className={isNonBrand ? 'non-brand' : ''}
                  />
                </Card>
                {/* ----END: Geo Location---- */}
              </div>
            </Col>
          </Row>
          {/* ----END: Scan Results---- */}
        </LoadingWrapper>
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { loggedIn } = state.login;
  return {
    loggedIn,
  };
};

const mapDispatchToProps = {
  runByClientApp: appActions.runByClientApp,
};

const connectedInsights = connect(mapStateToProps, mapDispatchToProps)(Insights);
export { connectedInsights as Insights };
