import _ from 'lodash';
import { Dispatch } from 'redux';
import { appConstants, IRunByClientAppType, runByClientAppType } from '../constants';
import AppConfigService from '../services/appConfig.service';
import AttributesService from '../services/attributes.service';
import DarkWebService from '../services/darkWeb.service';
import BulkScanService from '../services/bulkscan.service';
import { ICountryCode } from '../components/Common/Country';

const getEnterpriseAppConfig = () => {
  return async (dispatch: Dispatch) => {
    const attributesService = new AttributesService();
    const appConfigService = new AppConfigService();
    const darkWebService = new DarkWebService();
    const bulkScanService = new BulkScanService();
    const [
      attributes,
      categories,
      urlConstructions,
      brandPropertyTypes,
      darkWebEntityOptions,
      scanSourceCategories,
    ] = await Promise.all([
      attributesService.getAttributes(),
      appConfigService.getAllCategories(),
      appConfigService.getAllUrlConstructions(),
      appConfigService.getBrandPropertyTypes(),
      darkWebService.getSearchOptions(),
      appConfigService.getSourceFeedCategories(),
    ]).catch(() => {
      return [[], [], [], [], [], []];
    });

    let countryCodes: Record<string, ICountryCode> = {};
    try {
      countryCodes = await bulkScanService.getAllCountryCodes();
    } catch {
      countryCodes = {};
    }

    const contents = {};
    await Promise.all(
      Object.keys(appConstants.UGC_CONTENT_TYPE).map(async t => {
        const type = appConstants.UGC_CONTENT_TYPE[t];
        const [platformsRes, originsRes, categoriesRes] = await Promise.all([
          appConfigService.getContentPlatforms(type),
          appConfigService.getContentOrigins(type),
          appConfigService.getContentCategories(type),
        ]);
        const platforms = platformsRes?.platforms || [];
        const origins = originsRes?.origins || [];
        const categories = categoriesRes?.categories || [];
        _.forEach(platforms, p => {
          p.docValue = p.value;
          p.value = p.id;
          _.forEach(p.origins, o => {
            o.docValue = o.value;
            o.value = o.id;
          });
        });
        _.forEach(origins, o => {
          o.docValue = o.value;
          o.value = o.id;
        });
        _.forEach(categories, c => {
          c.docValue = c.value;
          c.value = c.id;
        });

        contents[type] = {};
        contents[type].platforms = platforms;
        contents[type].origins = origins;
        contents[type].categories = categories;
      }),
    );

    dispatch({
      type: appConstants.GET_ENTERPRISE_APP_CONFIG,
      payload: {
        attributes,
        categories,
        urlConstructions,
        contents,
        brandPropertyTypes,
        darkWebEntityOptions,
        scanSourceCategories,
        countryCodes,
      },
    });
  };
};

const getCheckphishAppConfig = () => {
  return async (dispatch: Dispatch) => {
    const appConfigService = new AppConfigService();
    const [categories, scanSourceCategories, urlConstructions] = await Promise.all([
      appConfigService.getAllCategories(),
      appConfigService.getSourceFeedCategories(),
      appConfigService.getAllUrlConstructions(),
    ]).catch(err => {
      console.log(err);
      return [[], []];
    });

    dispatch({
      type: appConstants.GET_CHECKPHISH_APP_CONFIG,
      payload: {
        categories,
        scanSourceCategories,
        urlConstructions,
      },
    });
  };
};

const getAttributes = () => {
  return (dispatch: Dispatch): any => {
    const attributesService = new AttributesService();
    return attributesService.getAttributes().then(res => {
      dispatch({
        type: appConstants.GET_ATTRIBUTES,
        payload: res,
      });
    });
  };
};

const setPricingPopupFlag = (value: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({ type: appConstants.SET_PRICING_POPUP, showPricingPopup: value });
  };
};

const runByClientApp = ({ onBolster, onCheckPhish }: IRunByClientAppType) => {
  return () => runByClientAppType({ onBolster, onCheckPhish });
};

export const appActions = {
  getEnterpriseAppConfig,
  getCheckphishAppConfig,
  getAttributes,
  setPricingPopupFlag,
  runByClientApp,
};
