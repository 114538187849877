import React from 'react';
import DonutChart from '../../../Common/DashboardWidgets/DonutChart';
import { getColors } from '../../../../constants';
import {
  TColorMap,
  IChartPlaceholderProps,
  TDonutChartDataProps,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import NoDetections from '../../../../assets/icons/NoDetections.svg';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

interface IComponentProps {
  isLoading: boolean;
  widgetData: any;
}

const placeholder: IChartPlaceholderProps = {
  icon: NoDetections,
  title: `No Category Found`,
  description: `We haven't detected any Category for this period. Please select another time frame, or scan more.`,
};

const DashboardUrlByCategories: React.FC<IComponentProps> = (props: IComponentProps) => {
  const isLoading = props.isLoading;
  const widgetData = props.widgetData;

  const [, selectedTheme] = useIsDarkMode();
  const colorMap: TColorMap = {
    ['1']: getColors(selectedTheme).priority1,
    ['2']: getColors(selectedTheme).priority2,
    ['3']: getColors(selectedTheme).priority3,
    ['4']: getColors(selectedTheme).priority4,
    ['5']: getColors(selectedTheme).priority5,
    ['6']: getColors(selectedTheme).donutChart6,
    ['7']: getColors(selectedTheme).donutChart7,
    ['8']: getColors(selectedTheme).donutChart8,
    ['9']: getColors(selectedTheme).donutChart9,
  };

  const massageData = (rawData: any): TDonutChartDataProps[] => {
    const formatedData = Object.keys(rawData).map((item, index) => ({
      label: item,
      count: rawData[item],
      color: colorMap[index + 1],
    }));
    return formatedData;
  };
  const priorityData = Object.keys(widgetData).length ? massageData(widgetData) : [];

  return (
    <DonutChart
      title='URLs By Categories'
      isLoading={isLoading}
      description='URLs By Categories'
      data={priorityData}
      placeholder={placeholder}
    />
  );
};
export default DashboardUrlByCategories;
