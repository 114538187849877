import React from 'react';
import './statusLabels.scss';
import { STATUS_LABELS_MAP } from './statusLables.constants';

export interface StatusLabelProps {
  status: string;
}

export interface Status {
  value: string;
  label: string;
}

export const StatusLabels: React.FC<StatusLabelProps> = ({ status }) => {
  return (
    <div className='status_labels_container'>
      <span className={`status_chip status_${status}`}>{STATUS_LABELS_MAP[status]}</span>
    </div>
  );
};
