import { SUBMISSION_CATEGORY_COLOR_MAP } from '../../constants';

export const getDayOrDaysBasedOnValue = (value: number): string => {
  return value === 1 ? 'day' : 'days';
};

export const getCategoryColor = (category: string) => {
  for (const key in SUBMISSION_CATEGORY_COLOR_MAP) {
    if (category.endsWith(key)) {
      return SUBMISSION_CATEGORY_COLOR_MAP[key];
    }
  }
  return SUBMISSION_CATEGORY_COLOR_MAP['Other'];
};
