import React, { useEffect } from 'react';
import { useAppSelector } from '../../../helpers/hooks';
import { appConstants, getProvidedTagLabelValue } from '../../../constants';
import { ITags } from '../Tags/Tags';
import { checkTagForFilterOptions } from '../Table/ag-table/ag-utils';
import useIsDarkMode from './useIsDarkMode';

const useOnTagFilterOptions = (
  agRefs: React.MutableRefObject<any>,
  columns: any[],
  colFieldId: 'tags' | 'tags.id',
  moduleType?: string,
) => {
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);
  const [isDarkMode] = useIsDarkMode();

  const filterTags = providedTags.filter((tag: ITags) => {
    return checkTagForFilterOptions(tag, moduleType);
  });

  const restrictedTagSM =
    moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ? providedTags.filter((tag: any) => tag.label === 'Marked as Scam')
      : [];

  const tagsDropdown = getProvidedTagLabelValue([...filterTags, ...restrictedTagSM], isDarkMode);

  useEffect(() => {
    const colDefs: any = agRefs.current?.api?.getColumnDefs();
    if (!colDefs) return;

    const tagColDef = colDefs.find((col: any) => col.field === colFieldId);
    if (!tagColDef) return;

    tagColDef.filterParams = {
      values: [
        'No tags',
        ...new Set(tagsDropdown?.map((option: { label: string; value: string }) => option.label)),
      ],
      suppressSorting: true,
      defaultToNothingSelected: true,
      buttons: ['reset'],
      suppressSelectAll: true,
    };

    agRefs.current?.api?.setGridOption('columnDefs', colDefs);
  }, [agRefs, colFieldId, columns, tagsDropdown]);
};

export default useOnTagFilterOptions;
