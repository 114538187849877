import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { setShouldRestorePageNumber } from '../../../../reducers/table.reducer';
import { AgGridEvent } from 'ag-grid-community';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { trackPendoEvent } from '../../../../services/pendo-requests';
import moment from 'moment';

// store and restore the page number when necessary
export const usePersistentTablePageNumber = (tableId: any) => {
  const pageNumberRef = useRef<number | null>(null);
  const dispatch = useAppDispatch();
  const { shouldRestorePageNumber } = useAppSelector(state => state.tableReducer);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPaginationSize, setCurrenPaginationsize] = useState(15);
  const { sizeColumnsToFitGrid } = useAgGridFeaturesConfigs({ tableId });

  // store the page number when leaving the page
  const storePageNumber = useCallback(() => {
    if (pageNumberRef.current !== null) {
      setLocalStorageValue(['tablePageNumber', tableId], pageNumberRef.current);
    }
  }, [tableId]);

  useEffect(() => {
    return () => {
      storePageNumber();
    };
  }, [storePageNumber]);

  const handleFirstDataRendered = useCallback(
    (params: AgGridEvent) => {
      sizeColumnsToFitGrid(params.api);
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      if (shouldRestorePageNumber) {
        const pageNumber = getLocalStorageValue(['tablePageNumber', tableId]);
        params.api.paginationGoToPage(pageNumber);
        dispatch(setShouldRestorePageNumber(false));
      }
    },
    [dispatch, tableId, shouldRestorePageNumber],
  );

  const handleOnPaginationChanged = useCallback((event: AgGridEvent) => {
    pageNumberRef.current = event.api?.paginationGetCurrentPage();
    setCurrentPageNumber(pageNumberRef.current);
    setCurrenPaginationsize(event.api?.paginationGetPageSize());
    if (event && 'newPage' in event && event.newPage) {
      trackPendoEvent('Pagination Change', {
        elemenText: `${pageNumberRef.current}`,
        pageUrl: window.location.pathname,
        timestamp: moment().valueOf(),
      });
    }
  }, []);

  return {
    handleFirstDataRendered,
    handleOnPaginationChanged,
    currentPageNumber,
    currentPaginationSize,
  };
};
