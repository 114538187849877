import _, { filter } from 'lodash';
import fetch from './api.service';
import { IFilter } from '../components/Common/Table/constant';
import { EDarkWebFindingStatus } from '../components/DarkWeb/Components/Types/DarkWeb.types';
import { handleResponse, setPostRequestOptions } from './serviceWorker';
import { convertToUtcDateTime } from './ugc.service';

const buildFilterQueryString = (filters: IFilter[], status?: EDarkWebFindingStatus): string => {
  let queryString = '';
  if (status !== undefined) {
    queryString += `status=in.(${status.split('|').join(',')})`;
  }
  const orFilters: Record<string, string[]> = {};
  const orNotFilters: Record<string, string[]> = {};
  _.forEach(filters, filter => {
    const filterMethod = filter.filterMethod.value;
    let filterBy = filter.filterBy.value;
    const filterValue = filter.filterValue;

    let startOper = '';
    let endOper = '';
    let queryOperator = 'eq';
    if (filterBy === 'category') {
      if (filterMethod === 'includes') {
        if (!orFilters[filterBy]) orFilters[filterBy] = [];
        orFilters[filterBy].push(filterValue);
      } else {
        if (!orNotFilters[filterBy]) orNotFilters[filterBy] = [];
        orNotFilters[filterBy].push(filterValue);
      }
      return;
    }
    switch (filterBy) {
      case 'search_term':
        if (filterBy === 'search_term') {
          filterBy = 'search_term_id';
          queryString += `&${filterBy}=${queryOperator}.${startOper}${filterValue}${endOper}`;
        }
        break;
      case 'tags.id':
        switch (filterMethod) {
          case 'is':
          case 'includes':
            queryOperator = 'in';
            break;
          case 'isNot':
          case 'notIncludes':
            queryOperator = 'not.in';
            break;
          default:
            break;
        }
        queryString += `&${filterBy}=${queryOperator}.(${filterValue.replaceAll(/[&|]/g, ',')})`;
        break;
      case 'tags.updated_by':
        switch (filterMethod) {
          case 'is':
          case 'includes':
            queryOperator = 'in';
            break;
          case 'isNot':
          case 'notIncludes':
            queryOperator = 'not.in';
            break;
          default:
            break;
        }
        queryString += `&${filterBy}=${queryOperator}.(${filterValue})`;
        break;
      default:
        switch (filterMethod) {
          case 'is':
            queryOperator = 'eq';
            break;
          case 'isNot':
            queryOperator = 'not.eq';
            break;
          case 'beginWith':
            endOper = '*';
            queryOperator = 'ilike';
            break;
          case 'notBeginWith':
            endOper = '*';
            queryOperator = 'not.ilike';
            break;
          case 'includes':
            queryOperator = 'ilike';
            startOper = '*';
            endOper = '*';
            break;
          case 'notIncludes':
            queryOperator = 'not.ilike';
            startOper = '*';
            endOper = '*';
            break;
          case 'numberRange':
            const [min, max] = filterValue.split(',');
            queryString += `&${filterBy}=gte.${min}&${filterBy}=lte.${max}`;
            break;
          default:
            break;
        }
        if (filterMethod !== 'numberRange') {
          queryString += `&${filterBy}=${queryOperator}.${startOper}${filterValue}${endOper}`;
        }
        break;
    }
  });
  if (!_.isEmpty(orFilters)) {
    for (const key in orFilters) {
      queryString += `&${key}=in.(${orFilters[key].join(',')})`;
    }
  }

  if (!_.isEmpty(orNotFilters)) {
    for (const key in orNotFilters) {
      queryString += `&${key}=not.in.(${orFilters[key].join(',')})`;
    }
  }

  return queryString;
};

export default class DarkWebService {
  private breachDescriptions: Record<string, string> | null = null;
  getDarkWebCategory(filters: IFilter[] = [], signal?: AbortSignal) {
    const postBody = {
      filter: buildFilterQueryString(filters),
    };
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify({ must: postBody }));
    return fetch(`/platform-api/v1/content/dark-web/category`, { ...requestOptions, signal }).then(
      handleResponse,
    );
  }

  getFindings(
    status: EDarkWebFindingStatus | undefined,
    query: any,
    filters: IFilter[],
    sort: any,
    options?: { includeFields?: string[]; excludeFields?: string[] },
  ) {
    const data = {
      filter: buildFilterQueryString(filters, status),
      startDate: convertToUtcDateTime(query.startDate),
      endDate: convertToUtcDateTime(query.endDate, true),
      limit: query.pageSize,
      offset: query.pageSize * query.pageNumber,
      order: `${sort.sortBy}.${sort.sortDirection}.nullslast`,
      total: true,
      includeFields: options?.includeFields,
      excludeFields: options?.excludeFields,
    };
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify({ must: data }));
    return fetch(`/platform-api/v1/content/dark-web/findings`, requestOptions).then(handleResponse);
  }

  exportFindings(
    status: EDarkWebFindingStatus,
    query: any,
    filters: IFilter[],
    sort: any,
    includeFields?: string[],
    excludeFields?: string[],
  ) {
    return this.getFindings(status, query, filters, sort, {
      includeFields,
    });
  }

  getFinding(sha256: string, options?: { includeFields?: string[]; excludeFields?: string[] }) {
    const requestOptions: RequestInit = setPostRequestOptions(JSON.stringify(options));
    return fetch(`/platform-api/v1/content/dark-web/findings/${sha256}`, requestOptions).then(
      handleResponse,
    );
  }

  getSimilarFinding(
    similarDetails: { category: string; excludeSha256s?: string[] },
    options?: { includeFields?: string[]; excludeFields?: string[] },
  ) {
    const requestOptions: RequestInit = setPostRequestOptions(
      JSON.stringify({ ...similarDetails, options }),
    );
    return fetch('/platform-api/v1/content/dark-web/findings/similar', requestOptions).then(
      handleResponse,
    );
  }

  getDarkWebInsight(status: EDarkWebFindingStatus, sha256: string) {
    return fetch(`/platform-api/v1/content/dark-web/findings/insight/${sha256}`)
      .then(handleResponse)
      .then(res => {
        return {
          insightData: res?.finding,
          similarFindings: res?.similarFindings ?? [],
        };
      });
  }

  updateFindingStatus(formData: any) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: formData,
    };
    return fetch(
      `/platform-api/v1/content/dark-web/finding/status-update/bulk`,
      requestOptions,
    ).then(handleResponse);
  }

  getThreatActors() {
    return fetch('/platform-api/v1/content/dark-web/threat-actors')
      .then(handleResponse)
      .then(res => {
        return _.map(res, actor => {
          actor.categories = actor.categories.join(', ');
          return actor;
        });
      });
  }

  getActorChatters(threatActor: string) {
    return fetch(`/platform-api/v1/content/dark-web/nonbrand-findings/${threatActor}`)
      .then(handleResponse)
      .then(res => {
        return _.map(res, actor => {
          actor.url = _.get(actor, ['source_specific_info', 'url']);
          actor.titleAndContent = `${actor.title} ${actor.content.replace(/\n/g, ' ')}`;
          return actor;
        });
      });
  }

  getSearchOptions() {
    return fetch('/platform-api/v1/content/dark-web/option')
      .then(handleResponse)
      .then(res => res);
  }

  getBreachDescriptions(): Promise<Record<string, string>> {
    return fetch('/platform-api/v1/content/dark-web/breach-descriptions/map')
      .then(handleResponse)
      .then(res => res)
      .catch(err => {
        console.error(err);
        return {};
      });
  }

  getDashboardV2Analytics() {
    return fetch('/platform-api/v1/content/dark-web/analytics', {
      credentials: 'include',
    })
      .then(handleResponse)
      .then(res => res);
  }
}
