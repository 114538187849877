import React from 'react';
import {
  BRAND_COLUMN,
  BRAND_PROPERTY_ACTION_COL,
  LOGO_STATUS_COL,
  OFFICIAL_PHONE_NUMBER_COUNTRY,
  OFFICIAL_PHONE_NUMBER_CREATED_AT,
  OFFICIAL_PHONE_NUMBER_CREATED_BY,
  OFFICIAL_PHONE_NUMBER_PHONE_NUMBER,
} from './AssetsColDefination/BrandPropertyCols';
import BrandPropertyComponent from './BrandPropertyComponent';
import { OFFICIAL_PHONE_NUMBER_TABLE_ID } from './constants';
import { PropertyType } from './PropertyTypes';

const OfficialPhoneNumberAssets = () => {
  const PAGE_TITLE = 'Official Phone Numbers';
  window.document.title = `${PAGE_TITLE} | Assets`;

  const TABLE_COLUMNS = [
    OFFICIAL_PHONE_NUMBER_PHONE_NUMBER,
    OFFICIAL_PHONE_NUMBER_COUNTRY,
    BRAND_COLUMN,
    OFFICIAL_PHONE_NUMBER_CREATED_AT,
    OFFICIAL_PHONE_NUMBER_CREATED_BY,
    LOGO_STATUS_COL,
    BRAND_PROPERTY_ACTION_COL,
  ];

  const defaultColumnIds = [
    'phoneNumber',
    'country',
    'brand',
    'created_ts',
    'created_at',
    'status',
    'assets-action-col',
  ];

  return (
    <BrandPropertyComponent
      tableName={PAGE_TITLE}
      id={OFFICIAL_PHONE_NUMBER_TABLE_ID}
      columns={TABLE_COLUMNS}
      title={PAGE_TITLE}
      disableDatePicker
      type={PropertyType.OFFICIAL_PHONE_NUMBER}
      defaultColumnIds={defaultColumnIds}
    />
  );
};

export default OfficialPhoneNumberAssets;
