import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './AdvancedFilters.scss';
import { Button } from '@mui/material';
import { useAppSelector } from '../../helpers/hooks';
import { ESelectedFilterTypeOptions } from './GlobalSearchConstants';
import {
  advancedSearchCategories,
  advancedSearchCategoriesAndOptions,
  advancedSearchOptions,
  columnOptionsDisabledMapping,
  moduleOptionsDisabledMapping,
} from './AdvancedFiltersConstants';
import { AdvancedFilterParams } from './AdvancedFiltersUtils';
import _ from 'lodash';
import useOnFetchAdvancedFilterFindings from './useOnFetchAdvancedFilterFindings';

export interface ISelectedOptions {
  [key: string]: string;
}

interface AdvancedFilterProps {
  advancedSearchRef: React.RefObject<HTMLDivElement>;
  searchValue: string;
  setSelectFilterType: React.Dispatch<React.SetStateAction<ESelectedFilterTypeOptions>>;
  setGlobalSearchFindingData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAdvancedFilterParams: React.Dispatch<React.SetStateAction<AdvancedFilterParams | undefined>>;
  setAdvancedFiltersSelectedOptions?: React.Dispatch<React.SetStateAction<ISelectedOptions>>;
}

export const AdvancedFilters = (props: AdvancedFilterProps) => {
  const {
    advancedSearchRef,
    searchValue,
    setSelectFilterType,
    setGlobalSearchFindingData,
    setIsLoading,
    setAdvancedFilterParams,
    setAdvancedFiltersSelectedOptions,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<ISelectedOptions>({});
  const { brandInfo } = useAppSelector(state => state.dashboardReducer);

  const { fetchAdvancedFilterFindings } = useOnFetchAdvancedFilterFindings({
    searchValue,
    selectedOptions,
    setSelectFilterType,
    setGlobalSearchFindingData,
    setIsLoading,
    setAdvancedFilterParams,
  });

  const updateOptionsMapping = useCallback(() => {
    const scanSource = brandInfo?.brand?.brandName;
    if (scanSource) {
      [ESelectedFilterTypeOptions.EMAIL_ADDRESS, ESelectedFilterTypeOptions.CCN].forEach(key => {
        if (columnOptionsDisabledMapping[key]) {
          columnOptionsDisabledMapping[key].push(scanSource);
        }
      });
    }
  }, [brandInfo]);

  useEffect(() => {
    updateOptionsMapping();
  }, [brandInfo]);

  useEffect(() => {
    setAdvancedFiltersSelectedOptions?.(selectedOptions);
  }, [selectedOptions]);

  const isSearchButtonEnabled = useMemo(() => {
    const isSearchValueEntered = !_.isEmpty(searchValue);
    const isRequiredOptionSelected = advancedSearchCategories.COLUMNS in selectedOptions;
    return isSearchValueEntered && isRequiredOptionSelected;
  }, [searchValue, selectedOptions]);

  const handleAvancedSearchOptions = useCallback(
    (category: string, value: string) => {
      setSelectedOptions(prevState => {
        const colDisabledOptions = columnOptionsDisabledMapping[value as string];
        const moduleDisabledOptions = moduleOptionsDisabledMapping[value as string];
        const allDisabledOptions = [
          ...(colDisabledOptions || []),
          ...(moduleDisabledOptions || []),
        ];
        const updatedState = Object.fromEntries(
          Object.entries(prevState).filter(([key, val]) => {
            return !allDisabledOptions.includes(val);
          }),
        );

        const isOptionSelected = prevState[category] === value;
        if (isOptionSelected) {
          return Object.fromEntries(
            Object.entries(updatedState).filter(([key]) => key !== category),
          );
        } else {
          return {
            ...updatedState,
            [category]: value,
          };
        }
      });
    },
    [selectedOptions],
  );

  const getCategoryOptions = (
    category: string,
    options: {
      label: string;
      value: string;
    }[],
  ) => {
    return category === advancedSearchCategories.SCAN_SOURCE && brandInfo
      ? [
          ...options,
          { label: `By ${brandInfo?.brand?.displayName}`, value: brandInfo?.brand?.brandName },
        ]
      : options;
  };

  const isOptionDisabled = (value: string) => {
    const columnSelected = selectedOptions[advancedSearchCategories.COLUMNS];
    const disabledModules = columnOptionsDisabledMapping[columnSelected as string] || [];
    const isModuleDisabled = disabledModules?.includes?.(value);

    const selectedModule = selectedOptions[advancedSearchCategories.MODULE];
    const disabledOptions = moduleOptionsDisabledMapping[selectedModule as string];
    const isModuleColumnOptionDisabled = disabledOptions?.includes?.(
      value as advancedSearchOptions,
    );

    return isModuleDisabled || isModuleColumnOptionDisabled;
  };

  return (
    <div className='advanced-search-container' ref={advancedSearchRef}>
      <div className='advanced-search-header'>{ESelectedFilterTypeOptions.ADVANCED_FILTERS}</div>
      <div className='advanced-search-categories-options-container'>
        {Object.entries(advancedSearchCategoriesAndOptions).map(([category, options]) => {
          const categoryOptions = getCategoryOptions(category, options);
          return (
            <div className={`advanced-search-category-options ${category}`} key={category}>
              <div className='advanced-search-category'>
                {category}
                {category === advancedSearchCategories.COLUMNS && (
                  <span className='advanced-search-category-required'>(Required)</span>
                )}
              </div>
              <div className='advanced-search-options-container'>
                {categoryOptions?.map(({ label, value }) => {
                  const uniqueKey = `${category}-${value}`;
                  const isDisabled = isOptionDisabled(value);
                  return (
                    <button
                      className={`advanced-search-options ${
                        selectedOptions[category] === value ? 'selected' : ''
                      } ${isDisabled ? 'disabled' : ''}`}
                      id={
                        label.includes?.(brandInfo?.brand?.displayName as string)
                          ? 'advanced-filter-by-brandName'
                          : `advanced-filter-${label}`
                      }
                      key={uniqueKey}
                      onClick={() => handleAvancedSearchOptions(category, value)}
                      disabled={isDisabled}
                    >
                      {label}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className='advanced-search-button-container'>
        <Button
          variant='text'
          className='advanced-search-clear margin-start-10'
          id='advanced-filter-clear'
          onClick={() => {
            setSelectedOptions({});
          }}
        >
          Clear All
        </Button>
        <Button
          variant='contained'
          className='advanced-search-apply'
          id='advanced-filter-apply'
          onClick={fetchAdvancedFilterFindings}
          disabled={!isSearchButtonEnabled}
        >
          Search
        </Button>
      </div>
    </div>
  );
};
