import React from 'react';
import { PageTitle } from '../../Common/PageTitle';
import LockBanner from './LockBanner';
import './LockPage.scss';
import MockTableImage from '../../../assets/checkphish-mock-logo-table.png';
import MockTableImageDark from '../../../assets/checkphish-mock-logo-table-dark.png';
import { useParams } from 'react-router';
import { titleMapper } from './Constants';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';

type Props = {};

type IParams = {
  page: string;
};

const LockedPage = (props: Props) => {
  const [isDarkMode] = useIsDarkMode();
  const { page } = useParams<IParams>();
  const heading = 'Request Customize Demo for Your Domain';
  const subHeading = (
    <>
      Gain <span className='highlight-text normal-text'>278% ROI</span> and increase security
      productivity by
      <span className='highlight-text normal-text'> over 30% </span> when managing
      <br /> domain risks and phishing attacks with Bolster.
    </>
  );

  const tableImg = isDarkMode ? MockTableImageDark : MockTableImage;

  return (
    <div className='locked-page-wrap page-wrap'>
      {titleMapper[page] && <PageTitle title={titleMapper[page]} />}
      <LockBanner
        showLockedIcon={true}
        heading={heading}
        subHeading={subHeading}
        upgradeBtnText='Request Demo'
      />
      <div className='mock-table-wrapper blurr-img'>
        <img src={tableImg} alt='mock-table-data' />
      </div>
    </div>
  );
};

export default LockedPage;
