import moment from 'moment';
import { abuseMailBoxTargetedMaliciousParams } from './TargetedMalicious/TargetedMaliciousUtils';

export const ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID = 'abuse_mailbox_targeted_malicious';
export const ABUSE_MAIL_BOX_MODULE_TYPE = 'abuse_mailbox';
export const ABUSE_MAIL_BOX_TARGETED_MALICIOUS_PATH_NAME = '/abuse-mailbox/targeted-malicious';
export const SENDER_DOMAIN = 'sender_domain';
export const ABUSE_MAIL_BOX_MODULE_PATH_NAME = '/abuse-mailbox';
export const ABUSE_MAIL_BOX_ALL_EMAIL_SUBMISSIONS_TABLE_ID = 'abuse_mail_box_all_email_submissions';
export const ABUSE_MAIL_BOX_ALL_URL_ANALYSIS = '/abuse-mailbox/all-email-submissions';

export const TARGETED_MALICIOUS_STATUS = [
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Takendown', value: 'taken_down' },
  { label: 'Not Applicable', value: 'not_applicable' },
  { label: 'Active', value: 'active' },
];

export const TARGETED_MALICIOUS_THREAT_TYPES = [
  { label: 'URL', value: 'url' },
  { label: 'Sender Domain', value: 'sender_domain' },
  { label: 'Attachment', value: 'attachment' },
  { label: 'Intent', value: 'intent' },
  { label: 'Phone Number', value: 'phone_number' },
];

export const TARGETED_MALICIOUS_DISPOSITIONS = [
  { label: 'Phish', value: 'phish' },
  { label: 'Malicious', value: 'malicious' },
  { label: 'Scam', value: 'scam' },
  { label: 'Malware', value: 'malware' },
  { label: 'Spam', value: 'spam' },
];

export const ALL_EMAIL_SUBMISSION = [
  { label: 'Malicious', value: 'malicious' },
  { label: 'Phish', value: 'phish' },
  { label: 'Scam', value: 'scam' },
  { label: 'Clean', value: 'clean' },
  { label: 'Malware', value: 'malware' },
  { label: 'Unknown', value: 'unknown' },
];

export const ALL_URL_ANALYSIS_DISPOSITIONS = [
  { label: 'Phish', value: 'phish' },
  { label: 'Scam', value: 'scam' },
  { label: 'Clean', value: 'clean' },
  { label: 'Suspicious', value: 'suspicious' },
];

export const TARGETED_MALICIOUS_CATEGORIES = [
  { label: 'Account Compromise', value: 'account compromise' },
  { label: 'Purchase', value: 'purchase' },
  { label: 'Baiting', value: 'baiting' },
  { label: 'Spam', value: 'spam' },
  { label: 'Sensitive Information', value: 'sensitive information' },
  { label: 'Malware', value: 'malware' },
  { label: 'Tech Support Scam', value: 'tech_support_scam' },
  { label: 'Support', value: 'support' },
  { label: 'URL Shortener', value: 'url_shortener' },
  { label: 'Traffic Stealer', value: 'traffic_stealer' },
];

export const AM_BUTTON_LABEL_MAP: Record<Top5Tabs | 'maliciousSenderDomain', string> = {
  phish: 'Phish',
  scam: 'Scam',
  maliciousSenderDomain: 'Malicious Sender Domain',
  senderDomain: 'Sender Domain',
  phoneNumber: 'Phone Number',
  spam: 'Spam',
};

export const SUBMISSION_CATEGORY_COLOR_MAP: Record<string, string> = {
  'Targeted Malicious': '#FC5449',
  'Generic Malicious': '#FF7D75',
  'Official Emails': '#0A89FF',
  Other: '#99DAFF',
};

export interface EmailParams {
  emailId?: string;
  sha256?: string;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
  // Pagination params for elastic search
  from?: number;
  size?: number;
  // Filter by disposition
  emailDisposition?: string[];
  emailCategory?: string[];
  threatType?: string[];
  threatDisposition?: string[];
}

export interface EmailDetailsParams {
  id: string;
  submissionDate: string;
  lastScannedDate: string;
  disposition: string;
  subject: string;
  emailCategory?: string;
  contentAnalysis: ContentAnalysisParams;
  senderDomainAnalysis: SenderDomainAnalysisParams;
  headerAnalysis: HeaderAnalysisParams;
  urlAnalysis: UrlAnalysisParams;
  attachmentAnalysis: AttachmentAnalysisParams;
  screenShot: string;
  phoneNumberAnalysis: phoneNumberAnalysisParams;
}

export interface ContentAnalysisParams {
  tone: string;
  intentsIdentified?: string[];
  language: string;
  initialDisposition: string;
  source?: string[];
  sha256?: string;
}

export interface SenderDomainAnalysisParams {
  sha256: string;
  senderDomain: string;
  currentDisposition?: string;
  initialDisposition: string;
}

export interface HeaderAnalysisParams {
  authenticationResult:
    | {
        spf: string;
        dkim: string;
        dmarc: string;
      }
    | string;
  initialDisposition?: string | undefined;
  currentDisposition?: string | undefined;
}

export interface urlDetails {
  sha256?: string;
  name?: string;
  initialDisposition?: string;
  status?: string;
  currentDisposition?: string | undefined;
  timestamp?: number | undefined;
  source?: string;
}

export interface UrlAnalysisParams {
  urlCounts: number;
  urls: urlDetails[];
}

export interface AttachmentAnalysisParams {
  attachmentCounts: number;
  attachments: attachmentsDetails[];
}

export interface attachmentsDetails {
  attachmentType?: string;
  name?: string;
  sha256?: string;
  initialDisposition?: string;
  sourceUrl?: string;
  screenshot?: string[];
}

export const abuseMailBoxTeaserPageDescription =
  'Subscribe to Abuse Mailbox Protection for automated threat detection and removal, keeping you and your customers safe from phishing, scams, and other malicious threats.';

export const getLabelByValue = (key: string, array: { label: string; value: string }[]) => {
  const item = array?.find(item => item?.value === key);
  return item ? item?.label : '--';
};

// POST /platform-api/abuse-mailbox/dashboard/stats
// Return type: DashboardData
// Define a common interface for threat details
interface ThreatDetails {
  threatType: string;
  disposition: string;
  sourceUrl: string;
  sha256: string;
  timestamp: string;
  count: number;
  threatSubType: string;
  hostingProvider: string;
  hostingCountry: string;
  firstSeen: string;
  status: string;
}

export const TOP_5_TABS = ['phish', 'scam', 'spam', 'senderDomain', 'phoneNumber'] as const;

export type Top5Tabs = (typeof TOP_5_TABS)[number];

// Use the Record type to map the enum to the common interface
type TopSuspiciousThreats = Record<Top5Tabs, ThreatDetails[]>;

export type DashboardData = {
  emailCategories?: Record<string, number>;
  needReview?: number;
  emailDisposition?: Record<string, number>;
  emailSubmissions: {
    total: number;
    users: number;
    trendsChart: {
      [key: string]: {
        total: number;
        malicious: number;
      };
    };
  };
  threatDistribution: {
    [key: string]: {
      email: number;
      sms: number;
    };
  };
  urlThreatBreakdown: {
    [key: string]: number;
  };
  threatSourcesBreakdown: {
    [key: string]: {
      [key: string]: number;
    };
  };
  userDomains: {
    [key: string]: number;
  };
  threatActorDomains: {
    [key: string]: number;
  };
  threatActorDomainsV2: {
    [key: string]: {
      count: number;
      type: string;
    };
  };
  takedownTimeAnalytics: {
    [key: string]: {
      avg: string;
      median: string;
      dayAggregations?: {
        [key: string]: number;
      };
    };
  };
  topSuspiciousThreats: TopSuspiciousThreats;
  emptyState: boolean;
  abuseMailBoxId: string;
};

export const valNotAvailable = 'Unavailable';

export interface phoneNumberAnalysisParams {
  phoneNumberCounts: number;
  phoneNumbers: phoneNumberDetails[];
}

export interface phoneNumberDetails {
  phoneNumber?: string;
  initialDisposition?: string;
  source?: string;
  sha256?: string;
}

export const takedownTimeAnalyticsNoDataText =
  'We are gathering data. Average and median times will appear soon.';

export const takeDownTimeAnalyticsDataPoints = [15, 10, 5, 0];

export const top5SuspiciousURLsNoDataMainText = 'No Malicious Threat found';

export const top5SuspiciousURLsNoDataSubText =
  'No malicious threat found at the moment. Please check back later for updates.';

export const top5SuspiciousURLsWidgetHeader = 'Top 5 Most Reported Malicious Threats';

export const abuseMailBoxDashboardNoDataMainText = 'Your Dashboard Awaits Real Data';

export interface IThreatTypeDetails {
  sha256: string;
  threatType: string;
  sourceUrl?: string;
  status?: string;
  timestamp?: string;
  disposition?: string;
  size?: string;
  fileType?: string;
  count?: number;
  category?: string;
  informationGathering?: string;
  threatSourceType?: string;
  carrier?: string;
  location?: string;
  name?: string;
  fileTypeMime?: string;
}

export const dispositionPriority: {
  [key: string]: number;
} = {
  phish: 1,
  scam: 2,
  malware: 3,
  suspicious: 4,
  clean: 5,
  unknown: 6,
};

export const ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID = 'abuse_mailbox_all_url_analysis';

export const defaultAbuseMailboxApiParams = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  BLOCK_SIZE: number,
  pageNumber: number = 0,
  after?: any,
  excludeThreatShas?: string[],
): abuseMailBoxTargetedMaliciousParams => ({
  filter: [
    {
      field: 'submissionTs',
      operator: 'gte',
      value: startDate.format('YYYY-MM-DD'),
      isNot: false,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      field: 'submissionTs',
      operator: 'lte',
      value: endDate.format('YYYY-MM-DD'),
      isNot: false,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  ],
  pageSize: BLOCK_SIZE,
  pageNumber: pageNumber,
  order: {
    field: 'lastSeen',
    order: 'desc',
  },
  ...(after && { after }),
  ...(excludeThreatShas && { excludeThreatShas }),
});
