import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ITableApiColumn } from '../../../Common/Table/table.api';
import { getInsightsPathBasedOnThreatType } from '../../Insights/InsightsUtils';
import React from 'react';
import { Link } from 'react-router-dom';
import { DispositionStatus } from '../../../Common/DispositionStatus';
import {
  ALL_EMAIL_SUBMISSION,
  ALL_URL_ANALYSIS_DISPOSITIONS,
  TARGETED_MALICIOUS_STATUS,
} from '../../constants';
import _ from 'lodash';
import { StatusLabels } from '../../../Common/statusLabels';
import { getDisplayTimeFromTimeStamp, setLocalStorageValue } from '../../../../constants';
import AgGridDateRangeFilter from '../../../Common/Table/ag-table/AgGridDateRangeFilter';
import { Image } from '../../../Common/Image';
import { getScreenshotUrl } from '../../../../helpers/screenshotUrl';
import './AllUrlAnalysis.scss';
import {
  ABUSE_MAILBOX_INSIGHTS_PARAMS,
  INSIGHTS_TYPE,
} from '../../TargetedMalicious/TargetedMaliciousColDefs';
import moment from 'moment';

export interface IAllUrlAnalysisColumns extends ITableApiColumn {
  width?: number;
}

export const ALL_URL_ANALYSIS_COLUMNS: IAllUrlAnalysisColumns[] = [
  {
    id: 'count',
    accessor: 'count',
    header: 'Report Count',
    type: 'number',
    filterDisabled: true,
    agFilterType: 'agNumberColumnFilter',
    render: (data: any) => {
      if (!data.count) {
        return '--';
      }
      return data.count;
    },
  },
  {
    id: 'disposition',
    accessor: 'disposition',
    header: 'Disposition',
    render: (data: any) => {
      if (!data.disposition) {
        return '--';
      }
      return <DispositionStatus status={data.disposition} />;
    },
    type: 'options',
    filterOptions: _.sortBy(ALL_URL_ANALYSIS_DISPOSITIONS, 'label'),
    agFilterType: 'agTextColumnFilter',
  },
  {
    id: 'firstSeen',
    accessor: 'firstSeen',
    header: 'First Seen',
    fieldForExport: 'firstSeen',
    type: 'date',
    isLongText: true,
    render: (data: any) => {
      return getDisplayTimeFromTimeStamp(data.firstSeen, 'MMMM D, YYYY h:mm a');
    },
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'lastScan',
    accessor: 'lastScan',
    header: 'Last Scan Time',
    fieldForExport: 'lastScan',
    type: 'date',
    isLongText: true,
    render: (data: any) => {
      return getDisplayTimeFromTimeStamp(data.lastScan, 'MMMM D, YYYY h:mm a');
    },
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'screenshot',
    accessor: 'screenshot',
    header: 'Screenshot',
    headerTooltip: 'Screenshot',
    filterDisabled: true,
    sortDisabled: true,
    agFilterType: 'agTextColumnFilter',
    render: ({ screenshot }) => {
      if (!screenshot) return '--';
      const imgPath = getScreenshotUrl(screenshot);
      return (
        <div style={{ maxWidth: '74px' }}>
          <Image imagePath={imgPath} alt={imgPath}></Image>
        </div>
      );
    },
    hiddenInTileView: true,
  },
  {
    id: 'ip',
    accessor: 'ip',
    fieldForExport: 'ip',
    header: 'IP Address',
    headerTooltip: 'Link to IP Details View',
    type: 'ipv4',
    columnClassName: 'hide-below-sm',
    render: (data: any) => {
      const { ip: ipAddress } = data;
      if (_.isEmpty(ipAddress) || ipAddress === '0.0.0.0') {
        return '--';
      }
      return ipAddress;
    },
    agFilterType: 'agTextColumnFilter',
    sortDisabled: true,
    filterDisabled: true,
  },
  {
    id: 'hostingProvider',
    accessor: 'hostingProvider',
    header: 'Hosting Provider',
    headerTooltip: 'Entity that hosts the site and to which Bolster directs takedown requests',
    fieldForExport: 'hostingProvider',
    isLongText: true,
    agFilterType: 'agTextColumnFilter',
    sortDisabled: true,
    filterDisabled: true,
  },
  {
    id: 'brandLogoDetected',
    accessor: 'brandLogoDetected',
    header: 'Logo Detection',
    type: 'options',
    filterOptions: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
    agFilterType: 'agTextColumnFilter',
    sortDisabled: true,
    filterDisabled: true,
    render: ({ brandLogoDetected }) => (brandLogoDetected ? 'True' : 'False'),
  },
];

export const generateSourceUrlColumn = (
  startDate: moment.Moment,
  endDate: moment.Moment,
): IAllUrlAnalysisColumns => ({
  id: 'sourceUrl',
  accessor: 'sourceUrl',
  fieldForExport: 'sourceUrl',
  header: 'Source URL',
  headerTooltip: 'Link to Threat Details View',
  sortDisabled: true,
  render: (data: any) => {
    const { sourceUrl, disposition, threatType } = data;
    const insightsPath = getInsightsPathBasedOnThreatType(data);
    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + sourceUrl} className={'table-source-url-tooltip'}>
            {sourceUrl}
          </Tooltip>
        }
      >
        <div className='table-source-url-column d-flex align-items-center'>
          <Link
            to={insightsPath}
            onClick={() => {
              setLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS, {
                insightsType: INSIGHTS_TYPE.ALL_URL_ANALYSIS,
                disposition,
                threatType,
                submissionTs: [startDate, endDate],
              });
            }}
          >
            {sourceUrl}
          </Link>
        </div>
      </OverlayTrigger>
    );
  },
  hiddenInDragDrop: true,
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
});
