import moment from 'moment';
import { useCallback, useState } from 'react';
import { getLocalStorageValue, setLocalStorageValue } from '../constants';
import _ from 'lodash';

export const PERSISTANT_DATE_FILTER = 'persistentDatePickers';

export const usePersistanceDate = (defaultRange: number, persistantFilterId: string) => {
  // If there is range like 30, there will be no startDate nor endDate
  const dateFilter = getLocalStorageValue([PERSISTANT_DATE_FILTER, persistantFilterId]);

  const [startDate, setStartDate] = useState<moment.Moment>(
    dateFilter?.range
      ? moment().subtract(dateFilter.range, 'day')
      : dateFilter?.startDate
      ? moment(dateFilter?.startDate)
      : moment().subtract(defaultRange, 'day'),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment(dateFilter?.endDate));
  const onDateChange = useCallback(
    (
      startDate: moment.Moment,
      endDate: moment.Moment,
      range: number,
      isCustomCalendarRange: boolean,
    ) => {
      // If isCustomCalendarRange is true, save startDate and endDate
      // If isCustomCalendarRange is false, save range only
      setLocalStorageValue(
        [PERSISTANT_DATE_FILTER, persistantFilterId],
        !isCustomCalendarRange
          ? { range: endDate.diff(startDate, 'days') }
          : {
              startDate,
              endDate,
            },
      );
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [persistantFilterId],
  );

  return { startDate, endDate, onDateChange };
};

export const getPersistanceDate = (
  persistantFilterId: string,
): { startDate: moment.Moment; endDate: moment.Moment } | undefined => {
  const dateFilter = getLocalStorageValue([PERSISTANT_DATE_FILTER, persistantFilterId]);

  // this method returns the persistance date if exist
  if (_.isEmpty(dateFilter)) return;
  return {
    startDate: dateFilter?.range
      ? moment().subtract(dateFilter.range, 'day')
      : moment(dateFilter?.startDate),
    endDate: moment(dateFilter?.endDate),
  };
};
