import React, { useEffect, useState } from 'react';
import './LogoPage.scss';
import { PageTitle } from '../../Common/PageTitle';
import './Dashboard.scss';
import { Card } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import UploadFiles from '../../Common/UploadFiles';
import _ from 'lodash';
import LockBanner from './LockBanner';
import MockLogoTableImage from '../../../assets/logo-detection-light.png';
import MockLogoTableDarkImage from '../../../assets/logo-detection-dark.png';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';

type Props = {
  onDone?: any;
  onCancel?: any;
  onImageUrlChange?: any;
};

function LogoPage({ onCancel, onDone, onImageUrlChange }: Props) {
  const [uploadedFile, setUploadedFile] = useState<any>(null); // full file
  const [uploadedImage, setUploadedImage] = useState<any>(null); // image
  const [imageUrl, setImageUrl] = useState<any>('');
  const [isValidUrl, setIsValidUrl] = useState<any>(false);
  const [showResultPage, setShowResultPage] = useState<any>(false);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [dropFile, setDropFile] = useState<boolean>(false);
  const [isDarkMode] = useIsDarkMode();

  const validatorUrl = () => {
    const url = imageUrl;
    const pattern = /^https?:\/\/(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})/;
    // Check if the URL matches the pattern
    const match = url.match(pattern);
    if (match) {
      return setIsValidUrl(true);
    } else {
      return setIsValidUrl(false);
    }
  };

  const showResults = () => {
    if ((!_.isEmpty(imageUrl) && isValidUrl) || uploadedFile !== null) {
      setShowResultPage(true);
    }
  };

  useEffect(() => {
    if ((!_.isEmpty(imageUrl) && isValidUrl) || uploadedFile !== null) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [imageUrl, uploadedFile, isValidUrl]);

  const resetData = () => {
    setDropFile(true);
    setUploadedImage(null);
    setUploadedFile(null);
    setImageUrl('');
  };

  useEffect(() => {
    uploadedFile && setImageUrl('');
  }, [uploadedFile]);

  useEffect(() => {
    onImageUrlChange ? onImageUrlChange() : validatorUrl();
  }, [imageUrl]);

  const tableImg = isDarkMode ? MockLogoTableDarkImage : MockLogoTableImage;

  return (
    <div className={'page-wrap'}>
      <PageTitle title='Monitor Logo' />
      {showResultPage ? (
        <>
          <span className='sub-heading' data-testid='subheading'>
            We found the following logos similar to yours.
          </span>
          <LockBanner
            showLockedIcon={false}
            heading={<>Are these links be misusing your logo to impersonate your brand?</>}
            subHeading={
              <>
                Activate Premium for full logo visibility and robust brand protection across all
                channels.
              </>
            }
            upgradeBtnText='Upgrade Plan'
          />
          <div className='mock-table-wrapper'>
            <img src={tableImg} alt='mock-table-data' />
          </div>
        </>
      ) : (
        <>
          <span className='sub-heading' data-testid='subheading'>
            Monitor and flag similar logos in real-time to prevent spam and protect your brand
            integrity.
          </span>
          <div className='logo-upload-wrap'>
            <Card className='logo-upload-container'>
              <div className='upload-via-file'>
                <span className='title'>Upload Logo</span>
                <div className='logo-upload'>
                  <Form.Group>
                    <UploadFiles
                      onChangeHandler={(files: File[], controlId: string) => {
                        if (files[0]) {
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(files[0]);
                          fileReader.addEventListener('load', function () {
                            setUploadedImage(this.result);
                            setUploadedFile(files[0]);
                          });
                        }
                      }}
                      dragAndDrop={true}
                      customSubHeadingText={'Click or drag your logo image here'}
                      customHelpText={'Supports: PNG, JPG, JPEG, WEBP'}
                      filesLimit={1}
                      accept={'image/png, image/jpeg, image/jpg, image/webp'}
                      onFileRemove={() => {
                        setUploadedFile(null);
                        setUploadedImage(null);
                      }}
                      fileDropped={dropFile}
                      disabled={isValidUrl}
                      onFileDropped={() => {
                        setDropFile(false);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='division-wrap'>
                <div className='left-line'></div>
                <span>or</span>
                <div className='right-line'></div>
              </div>
              <div className='upload-via-url'>
                <Form.Group>
                  <Form.Label>
                    <span className='title'>Import from URL</span>
                  </Form.Label>
                  <Form.Control
                    type='url'
                    value={imageUrl}
                    onChange={(e: any) => setImageUrl(e.target.value)}
                    isValid={isValidUrl}
                    className={'logo-url' + (uploadedFile ? ' disabled' : ' active-outline')}
                    placeholder='Enter the logo image URL here'
                    data-testid='logo-input'
                    disabled={uploadedFile}
                  />
                </Form.Group>
              </div>
              <div className='btn-wrap'>
                <Button
                  variant='outline-secondary mr-3'
                  onClick={() => {
                    onCancel ? onCancel() : resetData();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={() => {
                    onDone ? onDone() : showResults();
                  }}
                  disabled={disabledBtn}
                >
                  Done
                </Button>
              </div>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}

export default LogoPage;
