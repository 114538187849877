import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  FilterChangedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import {
  TableApiParams,
  UgcTableApiParams,
  convertToFetchApiParams,
  getAgGridThemeClassName,
  transformTagObject,
} from './ag-utils';
import { fetchRelatedData, fetchWebTableData } from './ag-requests';
import DashboardService from '../../../../services/dashboard.service';
import ThemeContext from '../../../../context/ThemeContext';
import { appConstants, getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';
import BasicMenu from './BasicMenu';
import Tags from '../../Tags/Tags';
import {
  setOutGoingPersistentFilterString,
  setIsPersistentFilterSaveButtonVisible,
  setIsGroupRelatedDataReady,
  setIsWebTablesGroupView,
} from '../../../../reducers/table.reducer';
import {
  GROUPED_MALICIOUS_TABLE_IDS,
  MALICIOUS_TABLE_IDS,
  mapGroupedTableIdToTableId,
  mapTableIdToEnum,
} from '../../../MonitorAndTakedown/constants';
import { alertActions, dashboardActions } from '../../../../actions';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { generateWebColDefs } from './ag-col-defs';
import { ITableApiColumn } from '../table.api';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import {} from '../../../../reducers/tags.reducer';
import useOnCustomTagActions from '../../CustomHooks/useOnCustomTagActions';
import useOnTagFilterOptions from '../../CustomHooks/useOnTagFilterOptions';
import { useAgGridEvents } from './useAgGridEvents';
import {
  TABLE_EMPTY_RESULTS_MESSAGE,
  getDefaultEndDate,
  getDefaultStartDate,
  paginationPageSizeSelector,
} from './constants';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import useAgGridFilterChange from './useAgGridFilterChange';
import { useNumOfCategoryFltrConditions } from './useNumOfCategoryFltrConditions';
import { setCurrentTableId } from '../../../../reducers/insightsContainer.reducer';
import useOnStoringPaginationControls from '../../CustomHooks/useOnStoringPaginationControls';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';
import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const AgGridWebTable = ({
  columns,
  tableId,
  enableCheckbox,
  moduleType,
}: {
  columns: ITableApiColumn[];
  tableId: GROUPED_MALICIOUS_TABLE_IDS;
  enableCheckbox?: boolean;
  moduleType?: string;
}) => {
  const agRef = useRef<AgGridReact>(null);
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs, onTableSaveColumns, setFindings } = useContext(TableContext);
  const dashboardService = useMemo(() => new DashboardService(), []);
  const { numOfOtherCategories } = useAppSelector(state => state.tableReducer);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const defaultApiParams = useMemo<TableApiParams>(() => {
    return {
      must: {},
      mustNot: {},
      query: {
        startDate: getDefaultStartDate(),
        endDate: getDefaultEndDate(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: tableId,
      },
      sortBy: { first_seen_ts: 'desc' },
    };
  }, [tableId]);

  const apiParamsRef = useRef<TableApiParams | UgcTableApiParams>(defaultApiParams);
  const [curFindings, setCurFindings] = useState<object[]>([]);
  const [showTagsOverlay, setShowTagsOverlay] = useState<{ [key: string]: boolean }>({});
  const [activeGroupKey, setActiveGroupKey] = useState<string>('');
  const [tagsAnchorEl, setTagsAnchorEl] = useState<null | HTMLElement>(null);
  const [tagsForActiveGroup, setTagsForActiveGroup] = useState<{
    tagIdsToAdd: number[];
    tagIdsToRemove: number[];
    urlShas256: Set<string>;
  }>({ tagIdsToAdd: [], tagIdsToRemove: [], urlShas256: new Set() });

  const openOneGroupTagsOverlay = useCallback(
    (groupKey: string) => () => {
      setShowTagsOverlay(preState => ({ ...preState, [groupKey]: true }));
      setActiveGroupKey(groupKey);
    },
    [],
  );

  const dispatch = useAppDispatch();

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({
      tableId: tableId + '_groupView',
    });

  const getRelatedFindings = useCallback(
    (domain: string) => async () => {
      // TODO check if ag-grid has related cache
      dispatch(setIsGroupRelatedDataReady?.(false));
      // TODO CACHE2 check whether there is cached data for this domain
      const response = await fetchRelatedData(domain, apiParamsRef.current as TableApiParams);
      const findings = response.findings.map((item: any) =>
        dashboardService.massageDataCommon(item, mapTableIdToEnum[tableId]),
      );
      setCurFindings(findings);
      setFindings?.(findings);
      dispatch(setIsGroupRelatedDataReady?.(true));
      // TODO CACHE1 cache the domain related data, so that if we expand group later, don't need to fetch it again
    },
    [apiParamsRef, dashboardService, dispatch, setFindings, tableId],
  );

  const isReadOnlyUser = useReadOnlyUser();

  const { savedNumOfCategoryFltrConditions } = useNumOfCategoryFltrConditions({ tableId });

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      colSpan: (params: any) =>
        params?.node?.id?.startsWith('group-row-id') ? displayedColumnsIDs.length : 1,
      cellRendererParams: {
        checkbox: !isReadOnlyUser && enableCheckbox,
      },
    };
  }, [enableCheckbox, isReadOnlyUser]);

  const colDefs: any = useMemo<ColDef[]>(() => {
    return generateWebColDefs({
      columns,
      savedNumOfCategoryFltrConditions,
      firstRenderDisplayColumnIdSet,
      moduleType,
      renderGroupActionBtn: (groupKey: string) => {
        return (
          <BasicMenu
            openOneGroupTagsOverlay={openOneGroupTagsOverlay(groupKey)}
            getRelatedFindings={getRelatedFindings(groupKey)}
            setTagsAnchorEl={setTagsAnchorEl}
          />
        );
      },
    });
    // We have to add displayedColumnsIDs here, to update colDefs with latest visible columns
  }, [
    columns,
    getRelatedFindings,
    openOneGroupTagsOverlay,
    displayedColumnsIDs,
    savedNumOfCategoryFltrConditions,
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: false,
      menuTabs: ['columnsMenuTab'],
      suppressHeaderMenuButton: true,
      //sorting is disabled in groupView
      // mainMenuItems : columnHeaderMenuItems
    };
  }, []);

  const isFilterModelCacheUsed = useRef(false);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );
  const { handleGroupedPaginationControls } = useOnStoringPaginationControls({
    tableId,
  });

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        // console.log('[Datasource] - rows requested by grid: ', params);
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };

        const apiParams = convertToFetchApiParams(agRequest);
        const { query } = apiParams;
        query['type'] = mapGroupedTableIdToTableId[tableId];
        const isGroupDetailsRequest = agRequest.groupKeys.length > 0;
        try {
          if (!isGroupDetailsRequest) {
            query['pageNumber'] = params.api?.paginationGetCurrentPage?.() || 0;
            query['pageSize'] = params.api?.paginationGetPageSize();
            const rawGroupResponse = await fetchWebTableData(apiParams);
            const groupData = rawGroupResponse.result?.urlInfo || [];
            groupData.forEach(
              (groupDataItem: { domain: string; related_findings_count: number }) => {
                groupDataItem['primary_domain'] = groupDataItem.domain;
              },
            );
            response.data = groupData;
            response.total = rawGroupResponse.result?.total;
          } else {
            const currentGroupedNode = params.parentNode.data;
            const groupedKey = params.parentNode.key;

            const { related_findings_count } = currentGroupedNode;

            apiParams.query['pageSize'] = related_findings_count ?? 100;
            const rawResponse = await fetchRelatedData(agRequest.groupKeys[0], apiParams);
            setCurFindings(rawResponse.findings);
            response.data = rawResponse.findings.map((item: any) => {
              return dashboardService.massageDataCommon(item, mapTableIdToEnum[tableId]);
            });
            response.total = rawResponse.findings.length;

            handleGroupedPaginationControls(
              0,
              response.total as number,
              response.total as number,
              response.data,
              groupedKey as string,
            );
            setLocalStorageValue(['currentTableId'], tableId); // set the current table id
          }
          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }

          apiParamsRef.current = apiParams;
          setAllRowData(response.data);
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: response.total,
            });
          }, 0);
        } catch (error) {
          console.log('Error:', error);
          params.fail();
        }
      },
    };
  }, [tableId, dispatch, handleGroupedPaginationControls, dashboardService]);

  const { isFilterFromDashboard } = useAppSelector(state => state.tableReducer);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      // first render, only initiate filter model from local storage.
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource],
  );

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `${params.data.related_findings_count ? 'group-' : ''}row-id-${params.data._id}-${
      params.data.primary_domain
    }-${params.data.domain_sha256}-${params.data.url_sha256}`;
  }, []);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId: tableId + '_groupView',
    allRowData,
  });

  const tags = useAppSelector(state => state.tagsReducer.allPlatformTags);

  const updateTagsForActiveGroup = useCallback(() => {
    if (agRef.current && agRef.current.api) {
      // get tag details from appState, mappping tagIds to tag objects, and create new Node data
      const { tagIdsToAdd, tagIdsToRemove, urlShas256 } = tagsForActiveGroup;
      const tagsToAdd = tags
        .filter((tag: any) => {
          return tagIdsToAdd.includes(tag.id);
        })
        .map(transformTagObject); //map appreducer tag datastructure to related findings datastructure
      agRef.current.api.forEachNode(node => {
        if (!node.data) return;
        if (urlShas256.has(node.data.url_sha256)) {
          const newData = {
            ...node.data,
            tags: node.data.tags
              .filter(
                (tag: any) => !tagIdsToRemove.includes(tag.id) && !tagIdsToAdd.includes(tag.id),
              )
              .concat(tagsToAdd),
          };
          node.setData(newData);
        }
      });
    }
  }, [tags, tagsForActiveGroup]);

  useEffect(() => {
    void updateTagsForActiveGroup();
  }, [updateTagsForActiveGroup]);

  const onSelectionChanged = useCallback(
    event => {
      const selectedRows: any = event.api.getSelectedRows();
      const filteredSelectedRows = selectedRows.filter((row: any) => row.url_sha256);

      dispatch(
        dashboardActions.setSelectedWebUrls(tableId as MALICIOUS_TABLE_IDS, filteredSelectedRows),
      );
    },
    [dispatch, tableId],
  );

  const { handleUGCModuleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  const { handleFirstDataRendered, handleOnPaginationChanged, currentPaginationSize } =
    usePersistentTablePageNumber(tableId);

  //Custom hooks for triggering fetch call on custom tag action
  useOnCustomTagActions(agRef);

  //custom hook to add new custom tag filter options
  useOnTagFilterOptions(agRef, columns, 'tags', moduleType);

  useOnAgGridRefresh(agRef);
  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
      dispatch(setIsWebTablesGroupView(false));
      dispatch(setCurrentTableId(''));
    };
  }, []);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={`${getAgGridThemeClassName(selectedTheme)} group-view-table-container
      `}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        paginationPageSizeSelector={paginationPageSizeSelector}
        cacheBlockSize={currentPaginationSize}
        blockLoadDebounceMillis={300}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        rowSelection={'multiple'}
        groupSelectsChildren={true}
        groupSelectsFiltered={true}
        onSelectionChanged={onSelectionChanged}
        autoGroupColumnDef={autoGroupColumnDef}
        groupDisplayType={'multipleColumns'}
        showOpenedGroup={true}
        onFilterChanged={handleUGCModuleFilterChanged}
        suppressRowClickSelection={true}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
        rowHeight={50}
      />
      {/* Web module's Group view, bulk edit tags popup */}
      <Tags
        fromAgGrid
        agAnchorEl={tagsAnchorEl}
        rowData={curFindings}
        type={appConstants.CONTENT_TYPE.WEB}
        bulkOption
        showTagsOverlay={showTagsOverlay[activeGroupKey]}
        hideTagsOverlayHandler={() =>
          setShowTagsOverlay(preState => ({ ...preState, [activeGroupKey]: false }))
        }
        getUpdatedTags={async (updatedTagsRes: any) => {
          const { tagIdsToAdd, tagIdsToRemove, urlShas256 } = updatedTagsRes;
          setTagsForActiveGroup({ tagIdsToAdd, tagIdsToRemove, urlShas256: new Set(urlShas256) });
        }}
      />
    </div>
  );
};

export default AgGridWebTable;
