import React, { useState } from 'react';
import _ from 'lodash';
import Card from 'react-bootstrap/Card';
import './LabelAndValue.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { copyToClipboard } from '../../constants';
import './CopyButton.scss';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

export interface ICopyButtonProps {
  value?: string;
  copyButton?: boolean;
  onCopy?: () => void;
  showCopyText?: boolean;
  className?: string;
}

const CopyButton = (props: ICopyButtonProps) => {
  const {
    value = '',
    copyButton = false,
    onCopy = _.noop,
    showCopyText = false,
    className = '',
  } = props;
  const [copied, setCopied] = useState(false);
  return copyButton ? (
    <OverlayTrigger
      overlay={
        <Tooltip id='tooltip-bulk-scan'>
          <Card.Text> {copied ? 'Copied to clipboard' : 'click to copy'} </Card.Text>
        </Tooltip>
      }
    >
      <div
        className={`copy-button-wrapper ${className}`}
        onClick={() => {
          copyToClipboard(value);
          setCopied(true);
          onCopy();
          setTimeout(() => {
            setCopied(false);
          }, 10000);
        }}
        data-testid='copy-button'
      >
        <ContentCopyRoundedIcon className='copy-text-icon' />{' '}
        {showCopyText && <div className='copy-text'>Copy Link</div>}
      </div>
    </OverlayTrigger>
  ) : null;
};

export default CopyButton;
