import { AgGridReact } from 'ag-grid-react';
import {
  ABUSE_MAIL_BOX_MODULE_TYPE,
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
} from '../constants';
import { generateAbuseMailBoxTargetedMaliciousColDefs } from '../TargetedMalicious/TargetedMaliciousUtils';
import { getAgGridThemeClassName } from '../../Common/Table/ag-table/ag-utils';
import ThemeContext from '../../../context/ThemeContext';
import React, { useContext, useRef } from 'react';
import { res } from './targeted-malicious.poc';
import { TARGETED_MALICIOUS_COLUMNS } from '../TargetedMalicious/TargetedMaliciousColDefs';
import { LicenseManager } from 'ag-grid-enterprise';
import { ExportCSV } from '../../Common/Export/ExportCSV';
import PersistentFilters from '../../Common/PersistentFilters/PersistentFilters';
import useAgGridFilterChange from '../../Common/Table/ag-table/useAgGridFilterChange';

// BoB demo only
LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);
export default function TargetedMaliciousDemo() {
  const agRef = useRef<AgGridReact>(null);
  const { handleUGCModuleFilterChanged } = useAgGridFilterChange(
    agRef,
    ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
  );

  const selectedTheme = useContext(ThemeContext).selectedTheme;
  const simplifiedColumns = TARGETED_MALICIOUS_COLUMNS.map(column => {
    if (column.id === 'firstSeen') {
      return {
        ...column,
        agFilterType: 'agDateColumnFilter',
      };
    }
    return column;
  });
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div
        id='ag-table-container'
        data-testid='ag-table-container'
        className={getAgGridThemeClassName(selectedTheme)}
        style={{
          height: '85vh',
        }}
      >
        <div className='ag-grid-abuse-mailbox-table-tool-bar'>
          <PersistentFilters
            tableId={ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID}
            moduleType={ABUSE_MAIL_BOX_MODULE_TYPE}
          />
          <ExportCSV
            onClick={() => agRef.current?.api.exportDataAsCsv()}
            style={{ marginLeft: '8px' }}
          />
        </div>
        <AgGridReact
          ref={agRef}
          columnDefs={generateAbuseMailBoxTargetedMaliciousColDefs({
            columns: simplifiedColumns,
            firstRenderDisplayColumnIdSet: new Set([
              'sourceUrl',
              'threatType',
              'count',
              'disposition',
              'category',
              'firstSeen',
              'status',
            ]),
            tableId: ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
          })}
          rowData={res.threatFindings.map(item => ({
            ...item,
            firstSeen: new Date(item.firstSeen),
          }))}
          columnMenu={'new'}
          rowModelType='clientSide'
          pagination={true}
          paginationPageSize={15}
          reactiveCustomComponents
          groupDisplayType={'groupRows'}
          onFilterChanged={handleUGCModuleFilterChanged}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: false,
                  suppressColumnSelectAll: false,
                  suppressColumnExpandAll: true,
                },
              },
            ],
            defaultToolPanel: 'columns',
          }}
        />
      </div>
    </div>
  );
}
