import React, { useEffect, useMemo, useState } from 'react';
import { getDefaultEndDate, getDefaultStartDate } from '../../Common/Table/ag-table/constants';
import { EmailDetailsParams, EmailParams } from '../constants';
import { EmailDetailsAnalysisContainer } from './EmailDetailsAnalysisContainer';
import { useOnFetchEmailDetails } from './useOnFetchEmailDetails';
import { massagedAbuseMailBoxEmailDetails } from './EmailDetailsUtils';
import { EmailsListViewPanel } from './EmailsListViewPanel';
import './ThreatTypeInsights.scss';
import { Grid } from '@mui/material';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { history } from '../../../helpers';
import '../AllEmailSubmissions/AllEmailSubmissions.scss';
import { getLocalStorageValue } from '../../../constants';
import {
  ABUSE_MAILBOX_INSIGHTS_PARAMS,
  INSIGHTS_TYPE,
} from '../TargetedMalicious/TargetedMaliciousColDefs';
import _ from 'lodash';

export interface IEmailDetails {
  sha256: string;
  isLoading?: boolean;
  totalEmailsCount?: number;
}

export const EmailDetails = ({ sha256, totalEmailsCount }: IEmailDetails) => {
  const [visiableEmailCount, setVisiableEmailCount] = useState(25);

  const extraParams = getLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS);
  const insightsType = extraParams['insightsType'] || INSIGHTS_TYPE.TARGETED_MALICIOUS;
  const threatType = extraParams['threatType'];
  const [startDate, endDate] = useMemo(() => {
    return getLocalStorageValue('abuse_mailbox_insights_params').submissionTs || [];
  }, []);

  const emailParams: EmailParams = useMemo(() => {
    const params: EmailParams = {
      sha256: sha256,
      startDate: getDefaultStartDate(startDate),
      endDate: getDefaultEndDate(endDate),
      from: visiableEmailCount - 25,
      size: 25,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (extraParams.disposition) {
      params.threatDisposition = [extraParams.disposition];
    }
    if (insightsType === INSIGHTS_TYPE.TARGETED_MALICIOUS) {
      params.emailCategory = ['Targeted Malicious'];
    }
    if (threatType) {
      params.threatType = [threatType];
    }
    return params;
  }, [sha256, visiableEmailCount, startDate, endDate]);

  const { abuseMailBoxEmailDetails: deltaEmailDetails } = useOnFetchEmailDetails(emailParams);
  const [emailDetails, setEmailDetails] = useState<EmailDetailsParams[]>([]);

  // append deltaEmailDetails to emailDetails
  useEffect(() => {
    setEmailDetails(prev => [...prev, ...deltaEmailDetails.emailDetails]);
  }, [deltaEmailDetails]);

  const massagedData = massagedAbuseMailBoxEmailDetails(emailDetails);

  const [selectedEmail, setSelectedEmail] = useState<EmailDetailsParams>(massagedData[0]);

  useEffect(() => {
    if (!selectedEmail && massagedData.length > 0) {
      setSelectedEmail(massagedData[0]);
    }
  }, [massagedData, selectedEmail]);

  // add email id to the url, as the tail of the current url
  useEffect(() => {
    if (selectedEmail) {
      history.replace(`${window.location.pathname}?id=${selectedEmail.id}`);
    }
  }, [selectedEmail]);

  if (massagedData.length === 0) {
    return <LoadingWrapper isLoading={true} />;
  }

  return (
    <div className='abuse-mailBox-emailDetails-main-container'>
      {massagedData.length > 1 && (
        <EmailsListViewPanel
          setVisiableEmailCount={setVisiableEmailCount}
          abuseMailBoxEmailDetails={massagedData}
          handleEmailSelection={(email: EmailDetailsParams) => {
            setSelectedEmail(email);
          }}
          totalEmailsCount={totalEmailsCount as number}
        />
      )}
      <div className={'abuse-mailBox-emailDetails-container'}>
        <div
          data-testid='emailDetails-insights-container'
          className='page-content emailDetails-insights-container all-email-submissions'
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              {selectedEmail && (
                <div className='inbox-item-details abuse-mailBox-emailDetails-container'>
                  <EmailDetailsAnalysisContainer abuseMailBoxEmailDetails={selectedEmail} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
