import { capitalize } from '../../../constants';
import { EmailDetailsParams, valNotAvailable } from '../constants';

export function massagedAbuseMailBoxEmailDetails(
  abuseMailBoxEmailDetailsData: any[],
): EmailDetailsParams[] {
  return abuseMailBoxEmailDetailsData.map(emailDetail => ({
    ...emailDetail,
    submissionDate: emailDetail.submissionDate || '--',
    lastScannedDate: emailDetail.lastScannedDate || '--',
    subject: emailDetail.subject || '--',
    contentAnalysis: {
      tone: capitalize(emailDetail.contentAnalysis?.tone) || '--',
      intentsIdentified: emailDetail.contentAnalysis?.intentsIdentified?.length
        ? emailDetail.contentAnalysis.intentsIdentified?.map(capitalize)
        : '--',
      language: emailDetail.contentAnalysis?.language || 'en',
      initialDisposition: emailDetail.contentAnalysis?.initialDisposition || '--',
      source:
        emailDetail.contentAnalysis?.source?.length > 0
          ? emailDetail.contentAnalysis?.source?.map?.(capitalize)
          : '--',
      sha256: emailDetail.contentAnalysis?.sha256 || '',
    },
    senderDomainAnalysis: {
      sha256: emailDetail.senderDomainAnalysis?.sha256 || '--',
      senderDomain: emailDetail.senderDomainAnalysis?.senderDomain || valNotAvailable,
      initialDisposition: emailDetail.senderDomainAnalysis?.initialDisposition || '--',
    },
    headerAnalysis: {
      authenticationResult: Object.values(emailDetail.headerAnalysis?.authenticationResult).every(
        value => value === '',
      )
        ? valNotAvailable
        : emailDetail.headerAnalysis?.authenticationResult,
      initialDisposition: emailDetail.headerAnalysis?.initialDisposition || '--',
    },
  }));
}
