import React from 'react';
import _ from 'lodash';
import { ITableApiColumn } from '../../../../Common/Table/table.api';
import TakedownStatusColRender from './TakedownStatusColRender';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { EFindingExternalLinks, EFindingStatus } from '../../../Types/ugc.types';
import ScanStatusColRender from './ScanStatusColRender';
import moment from 'moment';
import LogoImageColRender from './LogoImageColRender';
import AgGridDateRangeFilter from '../../../../Common/Table/ag-table/AgGridDateRangeFilter';
import { Image } from '../../../../Common/Image';

export const URL_COLUMN = (urlPath: string): ITableApiColumn => ({
  id: 'url',
  accessor: 'url',
  header: 'URL',
  copyFieldValue: ({ url }) => {
    return url;
  },
  render: (data: any) => {
    const { url } = data;
    if (url) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + url} className={'table-source-url-tooltip'}>
              {url}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            <Link to={`${urlPath}/insights/${data.sha256}`}>{url}</Link>
          </div>
        </OverlayTrigger>
      );
    }
    return null;
  },
  hiddenInDragDrop: true,
  agFilterType: 'agTextColumnFilter',
});

export const PLATFORM_COLUMN: ITableApiColumn = {
  id: 'platform',
  accessor: 'platform_id',
  header: 'Platform',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
};

export const ORIGIN_COLUMN: ITableApiColumn = {
  id: 'origin',
  accessor: 'origin_label',
  header: 'Origin',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ origin_label }) => {
    return origin_label;
  },
};

export const CATEGORY_COLUMN: ITableApiColumn = {
  id: 'category',
  accessor: 'category_labels',
  header: 'Category',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ category_labels }) => {
    return category_labels;
  },
};

export const SOURCE_COLUMN: ITableApiColumn = {
  id: 'source',
  accessor: 'source',
  header: 'Source',
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ source }) => {
    return source;
  },
};

export const ACTION_NEEDED_COLUMN: ITableApiColumn = {
  id: 'actions',
  accessor: 'actions',
  header: 'Actions Needed',
  hiddenInExport: true,
  filterDisabled: true,
  sortDisabled: true,
};

export const TAKEDOWN_IN_PROGRESS_ACTION_COLUMN: ITableApiColumn = {
  id: 'takedownInProgress',
  accessor: 'actions',
  header: 'Actions Needed',
  hiddenInExport: true,
  filterDisabled: true,
  sortDisabled: true,
};

export const TAKEDOWN_STATUS_COLUMN: ITableApiColumn = {
  id: 'takedownStatus',
  accessor: 'status',
  header: 'Status',
  type: 'options',
  filterOptions: [
    { label: 'Takedown In Progress', value: EFindingStatus.IN_PROGRESS },
    { label: 'Takedown Paused', value: EFindingStatus.PAUSED },
  ],
  agFilterType: 'agTextColumnFilter',
  render: (data: any) => {
    return <TakedownStatusColRender data={data} />;
  },
};

export const TAKEDOWN_TIME_COLUMN: ITableApiColumn = {
  id: 'takedownTs',
  accessor: 'takedown_ts',
  header: 'Takedown Time',
  type: 'date',
  fieldForExport: 'displayTakedownTime',
  render: (data: any) => {
    return data.displayTakedownTime;
  },
  agFilterType: AgGridDateRangeFilter,
};

export const FIRST_FOUND_COLUMN: ITableApiColumn = {
  id: 'createdTs',
  accessor: 'created_ts',
  header: 'First Seen',
  fieldForExport: 'displayFirstSeenTime',
  isDefaultSort: true,
  type: 'date',
  isLongText: true,
  render: (data: any) => {
    return data.displayFirstSeenTime;
  },
  agFilterType: AgGridDateRangeFilter,
};

export const MATCH_LOGO_IMAGE_COLUMN: ITableApiColumn = {
  id: 'logos',
  accessor: 'logos',
  fieldForExport: 'logosLength',
  header: 'Matched Logo & Images',
  render: (data: any) => {
    return <LogoImageColRender data={data} />;
  },
  filterDisabled: true,
  sortDisabled: true,
  agFilterType: 'agNumberColumnFilter',
};

export const IS_LOGO_DETECTION_COL: ITableApiColumn = {
  id: 'isLogoDetection',
  accessor: 'is_logo_detection',
  header: 'Logo Detection',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ is_logo_detection }) => {
    return is_logo_detection;
  },
};
export const MATCH_SEARCH_TERMS_COLUMN: ITableApiColumn = {
  id: 'searchTerm',
  accessor: 'search_term_labels',
  header: 'Matched Search Terms',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
  copyFieldValue: ({ search_term_labels }) => {
    return search_term_labels;
  },
};

export const SCAN_STATUS_COLUMN: ITableApiColumn = {
  id: 'scanStatus',
  accessor: 'status',
  header: 'Scan Status',
  type: 'options',
  filterOptions: [
    { label: 'In Progress', value: EFindingStatus.PENDING },
    { label: 'Ready to Approval', value: EFindingStatus.UNDER_REVIEW },
  ],
  render: (data: any) => {
    return <ScanStatusColRender data={data} />;
  },
  agFilterType: 'agTextColumnFilter',
};

export const ACTIVE_SINCE: ITableApiColumn = {
  id: 'activeSince',
  accessor: `metadata->>active_since`,
  header: 'Active Since',
  fieldForExport: 'metadata.activeSince',
  filterDisabled: false,
  sortDisabled: false,
  type: 'date',
  isLongText: true,
  render: (data: any) => {
    return data.metadata?.activeSince
      ? moment(data.metadata?.activeSince).format('DD-MMM-YYYY')
      : '--';
  },
  agFilterType: AgGridDateRangeFilter,
};

export const EXTERNAL_LINKS_COL: ITableApiColumn = {
  id: 'externalLinks',
  accessor: 'external_links',
  header: 'External Links',
  fieldForExport: 'externalLinks',
  type: 'options',
  filterOptions: [
    { label: 'None', value: EFindingExternalLinks.NONE },
    { label: 'Clean', value: EFindingExternalLinks.CLEAN },
    { label: 'Malicious', value: EFindingExternalLinks.MALICIOUS },
  ],
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ external_links }) => {
    return external_links;
  },
};

export const TITLE_COLUMN: ITableApiColumn = {
  id: 'title',
  accessor: 'metadata->>title',
  header: 'Title',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: (data: any) => {
    return data?.metadata?.title;
  },
};

export const FINDING_SOURCE: ITableApiColumn = {
  id: 'findingSource',
  accessor: 'findingSource',
  header: 'URL Source',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
  sortDisabled: true,
  filterDisabled: true,
  copyFieldValue: ({ findingSource }) => {
    return findingSource;
  },
};

export const PAGE_HOSTING_LINK: ITableApiColumn = {
  id: 'fbPageLink',
  accessor: 'metadata->>fb_page_link',
  header: 'Page Hosting in Ads',
  render: (data: any) => {
    const { metadata } = data;
    if (metadata && metadata?.fbPageLink) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + metadata?.fbPageLink} className={'table-source-url-tooltip'}>
              {metadata?.fbPageLink}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            <a target='_blank' href={`${metadata?.fbPageLink}`} rel='noreferrer'>
              {metadata?.fbPageLink}
            </a>
          </div>
        </OverlayTrigger>
      );
    }
    return '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const UGC_SCREEN_SHOT_COLUMN: ITableApiColumn = {
  id: 'screenshot',
  accessor: 'screenshot',
  header: 'Screenshot',
  headerTooltip: 'Screenshot',
  filterDisabled: true,
  sortDisabled: true,
  agFilterType: 'agTextColumnFilter',
  render: ({ imagePath }) => {
    if (!imagePath) return '--';
    return (
      <div style={{ maxWidth: '74px' }}>
        <Image imagePath={imagePath} alt={imagePath}></Image>
      </div>
    );
  },
  hiddenInTileView: true,
};
