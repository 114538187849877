import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UgcService from '../../../services/ugc.service';
import { IDropdownOption } from '../../Common/Dropdown';
import { ugcType } from '../../Ugc/Types/ugc.types';
import _ from 'lodash';
import { appConstants } from '../../../constants';
import { useAppSelector } from '../../../helpers/hooks';
import { PLAYBOOK_PROPERTY_TYPE, Playbook_Filter_Controller } from '../Common/PlaybookConstants';
import { ETagsTypes, ITags } from '../../Common/Tags/Tags';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';

export default function useModifiedPlaybookColumns(moduleType: string) {
  // Used this hooks to modify the columns of the playbook for filter options  based on the module type
  const ugcPlatforms = useAppSelector(state => state.appReducer.contents?.[moduleType]?.platforms);
  const ugcOrigins = useAppSelector(state => state.appReducer.contents?.[moduleType]?.origins);
  const ugcCategories = useAppSelector(
    state => state.appReducer.contents?.[moduleType]?.categories,
  );

  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);
  const user = useAppSelector(state => state.dashboardReducer.user);
  const usersList = useAppSelector(state => state.dashboardReducer.usersList);
  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);

  const [isDarkMode] = useIsDarkMode();
  const [tagsOptions, setTagsOptions] = useState<any[]>([]);

  const ugcService = useMemo(() => new UgcService(), []);

  const modifyCols = useCallback(async (columns: any) => {
    const scanSourceColumn = _.find(columns, ['id', 'scan_source']);
    if (scanSourceColumn) {
      scanSourceColumn.filterOptions = [
        { label: 'Bolster', value: 'bolster' },
        {
          label: brandInfo?.brand.displayName || brandInfo?.brand.orgName,
          value: brandInfo?.brand.brandName,
        },
      ];
    }

    const brandColumn = _.find(columns, ['id', 'sub_brand_id']);
    if (brandColumn) {
      brandColumn.filterOptions = _.chain(brandInfo?.brand.subBrands)
        .map(subBrand => ({
          label: subBrand.brand_display_name || subBrand.brand_name,
          value: subBrand.brand_name,
        }))
        .sortBy('label')
        .value();
    }

    const searchTermsColumn = _.find(columns, ['id', 'search_term']);
    if (searchTermsColumn) {
      const availableSearchTerms = await ugcService.getSearches(moduleType);
      if (availableSearchTerms) {
        searchTermsColumn.filterOptions = _.chain(availableSearchTerms)
          .map((item): IDropdownOption => {
            const { searchTerm, id, category, option } = item;
            if (moduleType === ugcType.Dark_Web) {
              return {
                label: `${searchTerm} - ${category?.label} ${
                  option?.options ? '- ' + option?.options?.label : ''
                } `,
                value: id,
              };
            } else {
              return { label: searchTerm, value: id };
            }
          })
          .uniqBy('label')
          .value();
      }
    }

    const platformColumn = _.find(columns, ['id', 'platform']);
    if (platformColumn) {
      platformColumn.filterOptions = _.map(ugcPlatforms, p => ({
        label: p.label,
        value: p.id,
      }));
    }

    const originColumn = _.find(columns, ['id', 'origin']);
    if (originColumn) {
      originColumn.filterOptions = _.map(ugcOrigins, p => ({
        label: p.label,
        value: p.id,
      }));
    }

    const categoryColumn = _.find(columns, ['id', 'category']);
    if (categoryColumn) {
      if (moduleType === appConstants.CONTENT_TYPE.DARK_WEB) {
        categoryColumn.filterOptions = _.map(ugcCategories, p => ({
          label: p.label,
          value: p.label,
        }));
      } else {
        categoryColumn.filterOptions = _.map(ugcCategories, p => ({
          label: p.label,
          value: p.id,
        }));
      }
    }

    const tagOptions: any = [];
    const tagAuthorOptions: { label?: string; value?: string }[] = [];

    usersList?.forEach((user: any) => {
      const labelAndValue = {};
      labelAndValue['label'] = `${user.user.firstName} ${user.user.lastName}`;
      labelAndValue['value'] = user.user.id;
      tagAuthorOptions.push(labelAndValue);
    });

    const tagsAuthorColumn = _.find(columns, ['id', 'Tags Author']);
    if (tagsAuthorColumn) {
      tagsAuthorColumn.filterOptions = tagAuthorOptions;
    }
    const filterTags =
      moduleType === appConstants.CONTENT_TYPE.SOCIAL ||
      moduleType === appConstants.CONTENT_TYPE.DARK_WEB
        ? providedTags.filter((tag: ITags) => tag.type !== ETagsTypes.BOLSTER_RESTRICTED)
        : providedTags;

    const restrictedTagSM =
      moduleType === appConstants.CONTENT_TYPE.SOCIAL
        ? providedTags.filter((tag: ITags) => tag.label === 'Marked as Scam')
        : [];

    [...filterTags, ...restrictedTagSM].forEach((tag: ITags) => {
      const labelAndValue: any = {};
      labelAndValue['label'] = tag.label;
      labelAndValue['value'] = tag.id;
      labelAndValue['color'] = isDarkMode ? tag.color.darkColor : tag.color.lightColor;
      tagOptions.push(labelAndValue);
    });

    setTagsOptions(tagOptions);
  }, []);

  useEffect(() => {
    void modifyCols(Playbook_Filter_Controller[PLAYBOOK_PROPERTY_TYPE[moduleType]]);
  }, [moduleType, modifyCols]);

  return { tagsOptions, modifyCols };
}
