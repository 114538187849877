import React, { useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './FlyoutMenus.scss';
import { DashBoardDto, featureIsAvailable } from '../../constants';
import { AppState } from '../../helpers';
import { INavSubItem } from './AppWrapper';
import { DRAWER_SUB_ITEMS } from './RouteOptions';
import { NavItems } from './TopNavigationBar';
import { LockIcon } from '../../assets/SVGIcons';
import { useOnClickOutside } from '../Common/CustomHooks/useOnClickOutside';

interface IFlyoutMenusProps {
  items: INavSubItem[];
  closeFlyoutMenus: () => void;
}

interface IPropsFromGlobalState {
  user: DashBoardDto;
  brandInfo: any;
}

const FlyoutMenus = (props: IFlyoutMenusProps & IPropsFromGlobalState) => {
  const { items, user, closeFlyoutMenus } = props;
  let marketplaceUnavailable = false;
  const ref = useRef<any>();
  useOnClickOutside(ref, (e: any) => {
    if (!e.target.classList.contains('flyout-nav-item') && e.target.tagName !== 'path') {
      closeMenu();
    }
  });

  const closeMenu = () => {
    closeFlyoutMenus();
  };
  if (!featureIsAvailable(user, DRAWER_SUB_ITEMS(user)['Marketplace'][0].featureCode[0])) {
    marketplaceUnavailable = true;
  }
  return (
    <div ref={ref} className='flyout-menus'>
      {_.map(
        marketplaceUnavailable ? items.filter(item => item.label !== 'Marketplace') : items,
        item => {
          const featureAvailable = featureIsAvailable(user, item.featureCode);
          return (
            <div className='flyout-menu-item' key={item.id}>
              <div className='flyout-menu-item-header'>
                {featureAvailable ? null : <LockIcon color='black' />}
                {item.label}
              </div>
              <ul className='flyout-menu-item-body pl-0'>
                <FlyoutMenuItems
                  items={DRAWER_SUB_ITEMS(user)[item.label]}
                  user={user}
                  closeMenu={closeMenu}
                />
              </ul>
            </div>
          );
        },
      )}
      <div className='flyout-menus-right'></div>
    </div>
  );
};

interface IFlyoutMenusItemsProps {
  items: INavSubItem[];
  user: DashBoardDto;
  closeMenu: () => void;
}
const FlyoutMenuItems = ({ items, user, closeMenu }: IFlyoutMenusItemsProps) => {
  const flyoutMenuItems: JSX.Element[] = [];

  const pushItem = (item: INavSubItem) => {
    if (item.subItems?.length) {
      _.forEach(item.subItems, item => {
        pushItem(item);
      });
    } else {
      flyoutMenuItems.push(
        <NavItems
          key={item.label}
          user={user}
          navItem={item}
          subItemClassName={'dashboard-label-wrapper'}
          onAfterClick={closeMenu}
        />,
      );
    }
  };

  _.forEach(items, item => {
    pushItem(item);
  });
  return <>{flyoutMenuItems}</>;
};

const mapStateToProps = (state: AppState) => {
  const { user, brandInfo } = state.dashboardReducer;
  return {
    user,
    brandInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlyoutMenus);
