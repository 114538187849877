import React, { useCallback, useEffect, useMemo } from 'react';
import './InsightsPaginationControls.scss';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  appConstants,
  getLocalStorageValue,
  getTimestampAndUrlSHA256,
  saveBulkscanTableParameters,
  setLocalStorageValue,
} from '../../../constants';
import { history } from '../../../helpers';
import { setShouldFetchInsightsOnNext } from '../../../reducers/insightsContainer.reducer';
import { setPreviousUrlSha, setShouldRestorePageNumber } from '../../../reducers/table.reducer';
import { fetchUGCData, fetchWebTableData } from '../Table/ag-table/ag-requests';
import useOnStoringPaginationControls from '../CustomHooks/useOnStoringPaginationControls';
import useOnPaginationControlsRefreshed from './useOnPaginationControlsRefreshed';
import { ugcTypeAndInsightRoutes } from '../../Ugc/constants';
import _ from 'lodash';
import { Button, MobileStepper } from '@mui/material';
import { ContactsOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { fetchBulkScanData } from '../../BulkScan/bulk-scan-requests';

export const mappWebAndBulkScanInsights = {
  [appConstants.CONTENT_TYPE.WEB]: '/web/insights',
  [appConstants.CONTENT_TYPE.BULK_SCAN]: '/bulk-scan/insights',
};
export default function InsightsPaginatonControls({ moduleType }: { moduleType: string }) {
  const [timestamp, sha256] = getTimestampAndUrlSHA256() ?? [];

  const dispatch = useAppDispatch();
  const currentTableId = getLocalStorageValue('currentTableId');
  const paginationControls = useAppSelector(
    state => state.insightsContainerReducer.paginationControls,
  );

  const [currentUrlIndex, setCurrentUrlIndex] = React.useState(0);

  const { handlePaginationControls } = useOnStoringPaginationControls({
    tableId: currentTableId,
  } as any);

  const { currentPage, totalPages, totalItems, pageSize } =
    paginationControls?.[currentTableId as string] ?? {};

  useOnPaginationControlsRefreshed({
    currentTableId: currentTableId as string,
    sha256,
    setCurrentUrlIndex,
    moduleType,
  });

  useEffect(() => {
    const urlShaAndTimestamps: { urlSha256: string; timestamp: number }[] =
      paginationControls[currentTableId as string]?.urlShaAndTimestamps;

    if (urlShaAndTimestamps && urlShaAndTimestamps.length > 0) {
      if (
        moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
        moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ) {
        const index = _.findIndex(urlShaAndTimestamps, { urlSha256: sha256 });
        setCurrentUrlIndex(index);
      } else {
        const index = urlShaAndTimestamps.findIndex(
          (item: { urlSha256: string; timestamp: number }) => {
            return item.urlSha256 === sha256 && String(item.timestamp) === timestamp;
          },
        );
        setCurrentUrlIndex(index);
      }
    }
  }, [currentTableId, paginationControls, sha256]);

  //For web module
  const redirectInsightsPage = useCallback(
    (shasAndTimestamp: { urlSha256: string; timestamp: number }) => {
      history.push(
        `${mappWebAndBulkScanInsights[moduleType]}/${shasAndTimestamp?.timestamp}/${shasAndTimestamp?.urlSha256}`,
      );
      dispatch(setPreviousUrlSha(shasAndTimestamp?.urlSha256));
    },
    [dispatch, moduleType],
  );

  //For UGC module : App Store, Social Media, Marketplace
  const redirectUGCInsightsPage = useCallback(
    (shasAndTimestamp: { urlSha256: string }) => {
      history.push(`${ugcTypeAndInsightRoutes[moduleType]}/${shasAndTimestamp?.urlSha256}`);
      dispatch(setPreviousUrlSha(shasAndTimestamp?.urlSha256));
    },
    [dispatch, moduleType],
  );

  const outBoundNextUrls = useCallback(
    async (activeIndex: number, pageSize: number) => {
      const newPage = currentPage + 1;
      setLocalStorageValue(['tablePageNumber', currentTableId], newPage);
      dispatch(setShouldRestorePageNumber(true));
      const apiParams = getLocalStorageValue(['tableApiParams', currentTableId]);

      // For web module
      if (moduleType === appConstants.CONTENT_TYPE.WEB) {
        // Fetch the data for new page
        if (apiParams?.query) {
          apiParams.query['pageNumber'] = newPage;
        }
        const rawResponse = await fetchWebTableData(apiParams, false);

        const data = rawResponse.result?.urlInfo;
        const total = rawResponse.result?.total;

        handlePaginationControls(newPage, pageSize, total as number, data);
        activeIndex = 0;
        const urlShaAndTimestamps = data.map((item: any) => {
          return {
            urlSha256: item.url_sha256,
            timestamp: item.timestamp,
          };
        });
        const getTimestampAndUrlSHA256 = urlShaAndTimestamps[activeIndex];

        // Redirect to the new insights page
        redirectInsightsPage(getTimestampAndUrlSHA256);
      }

      // For UGC module
      if (
        moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
        moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ) {
        // Fetch the data for new page
        const rawResponse = await fetchUGCData(
          { ...apiParams, offset: newPage * apiParams.limit },
          moduleType,
        );

        const data = rawResponse.findings;
        const total = rawResponse.metadata?.total;

        handlePaginationControls(newPage, pageSize, total as number, data);
        activeIndex = 0;
        const urlShaAndTimestamps = data.map((item: any) => {
          return {
            urlSha256: item.sha256,
          };
        });
        const getTimestampAndUrlSHA256 = urlShaAndTimestamps[activeIndex];

        // Redirect to the new insights page
        redirectUGCInsightsPage(getTimestampAndUrlSHA256);
      }

      // For Bulk Scan module
      if (moduleType === appConstants.CONTENT_TYPE.BULK_SCAN) {
        const { query, filters, sort } = apiParams;
        if (query) {
          query['pageNumber'] = newPage;
        }

        // Save the new page number for bulk scan table page sync
        saveBulkscanTableParameters({ ...apiParams, query: { ...query, pageNumber: newPage + 1 } });
        const rawResponse = await fetchBulkScanData(apiParams.query.type, query, filters, sort);
        const data = rawResponse?.urlInfo;
        const total = rawResponse?.total;

        handlePaginationControls(newPage, pageSize, total, data);

        activeIndex = 0;
        const urlShaAndTimestamps = data.map((item: any) => {
          return {
            urlSha256: item.urlSha256,
            timestamp: item.timestamp,
          };
        });
        const getTimestampAndUrlSHA256 = urlShaAndTimestamps[activeIndex];
        // Redirect to the new insights page
        redirectInsightsPage(getTimestampAndUrlSHA256);
      }
      dispatch(setShouldFetchInsightsOnNext(true));
    },
    [
      currentPage,
      currentTableId,
      dispatch,
      handlePaginationControls,
      moduleType,
      redirectInsightsPage,
      redirectUGCInsightsPage,
    ],
  );

  const onNextUrl = useCallback(() => {
    const { currentPage, pageSize } = paginationControls?.[currentTableId as string] ?? {};

    let activeIndex = currentUrlIndex;
    activeIndex = activeIndex + 1;

    //Outbound and fetch the data for new page
    if (activeIndex === pageSize && currentPage < totalPages - 1) {
      void outBoundNextUrls(activeIndex, pageSize);
    } else {
      const getTimestampAndUrlSHA256 =
        paginationControls[currentTableId as string]?.urlShaAndTimestamps[activeIndex];

      if (
        moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
        moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ) {
        redirectUGCInsightsPage(getTimestampAndUrlSHA256);
      } else {
        redirectInsightsPage(getTimestampAndUrlSHA256);
      }
      dispatch(setShouldFetchInsightsOnNext(true));
    }
    setCurrentUrlIndex(activeIndex);
  }, [
    currentTableId,
    currentUrlIndex,
    dispatch,
    moduleType,
    outBoundNextUrls,
    paginationControls,
    redirectInsightsPage,
    redirectUGCInsightsPage,
    totalPages,
  ]);

  const outBoundPrevUrls = useCallback(
    async (activeIndex: number, pageSize: number) => {
      const newPage = currentPage - 1;
      setLocalStorageValue(['tablePageNumber', currentTableId], newPage);
      dispatch(setShouldRestorePageNumber(true));
      const apiParams = getLocalStorageValue(['tableApiParams', currentTableId]);

      // For web module
      if (moduleType === appConstants.CONTENT_TYPE.WEB) {
        if (apiParams?.query) {
          apiParams.query['pageNumber'] = newPage;
        }
        const rawResponse = await fetchWebTableData(apiParams, false);

        const data = rawResponse.result?.urlInfo;
        const total = rawResponse.result?.total;

        handlePaginationControls(currentPage, pageSize, total as number, data);
        activeIndex = 14;
        const urlShaAndTimestamps = data.map((item: any) => {
          return {
            urlSha256: item.url_sha256,
            timestamp: item.timestamp,
          };
        });
        const getTimestampAndUrlSHA256 = urlShaAndTimestamps[activeIndex];
        redirectInsightsPage(getTimestampAndUrlSHA256);
      }

      // For UGC module
      if (
        moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
        moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ) {
        const rawResponse = await fetchUGCData(
          { ...apiParams, offset: apiParams.offset - apiParams.limit },
          moduleType,
        );

        const data = rawResponse.findings;
        const total = rawResponse.metadata?.total;

        handlePaginationControls(currentPage, pageSize, total as number, data);
        activeIndex = 14;
        const urlShaAndTimestamps = data.map((item: any) => {
          return {
            urlSha256: item.url_sha256 || item.urlSha256,
          };
        });
        const getTimestampAndUrlSHA256 = urlShaAndTimestamps[activeIndex];
        redirectUGCInsightsPage(getTimestampAndUrlSHA256);
      }

      // For Bulk Scan module
      if (moduleType === appConstants.CONTENT_TYPE.BULK_SCAN) {
        const { query, filters, sort } = apiParams;
        if (query) {
          query['pageNumber'] = newPage;
        }
        // Save the new page number for bulk scan table page sync
        console.log('apiParams', apiParams);
        console.log('query', query);

        saveBulkscanTableParameters({ ...apiParams, query });
        const rawResponse = await fetchBulkScanData(query.type, query, filters, sort);
        const data = rawResponse?.urlInfo;
        const total = rawResponse?.total;

        handlePaginationControls(currentPage, pageSize, total, data);
        activeIndex = 14;
        const urlShaAndTimestamps = data.map((item: any) => {
          return {
            urlSha256: item.urlSha256,
            timestamp: item.timestamp,
          };
        });
        const getTimestampAndUrlSHA256 = urlShaAndTimestamps[activeIndex];
        // Redirect to the new insights page
        redirectInsightsPage(getTimestampAndUrlSHA256);
      }
      dispatch(setShouldFetchInsightsOnNext(true));
    },
    [
      currentPage,
      currentTableId,
      dispatch,
      handlePaginationControls,
      moduleType,
      redirectInsightsPage,
      redirectUGCInsightsPage,
    ],
  );

  const onPrevUrl = useCallback(() => {
    // From Redux store
    const { pageSize, currentPage } = paginationControls[currentTableId as string] ?? {};

    let activeIndex = currentUrlIndex;
    activeIndex = activeIndex - 1;

    //Outbound and fetch the data for new page
    if (activeIndex < 0 && currentPage > 0) {
      void outBoundPrevUrls(activeIndex, pageSize);
    } else {
      const getTimestampAndUrlSHA256 =
        paginationControls[currentTableId as string]?.urlShaAndTimestamps[activeIndex];

      if (
        moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
        moduleType === appConstants.CONTENT_TYPE.SOCIAL
      ) {
        redirectUGCInsightsPage(getTimestampAndUrlSHA256);
      } else {
        // For inbound data
        const getTimestampAndUrlSHA256 =
          paginationControls[currentTableId as string]?.urlShaAndTimestamps[activeIndex];
        redirectInsightsPage(getTimestampAndUrlSHA256);
      }
      dispatch(setShouldFetchInsightsOnNext(true));
    }

    setCurrentUrlIndex(activeIndex);
  }, [
    paginationControls,
    currentTableId,
    currentUrlIndex,
    dispatch,
    moduleType,
    redirectInsightsPage,
    redirectUGCInsightsPage,
    outBoundPrevUrls,
  ]);

  const showNextAndPrevEnabled = useMemo(() => {
    return !getLocalStorageValue('isNotFromTableCol') && totalItems > 0;
  }, [totalItems]);

  const activeStep = currentPage * pageSize + currentUrlIndex;
  const isLastItem = activeStep === totalItems - 1;

  return (
    <div className='insights-pagination-controls' data-testid='insights-pagination-controls'>
      {showNextAndPrevEnabled ? (
        <MobileStepper
          steps={totalItems || 0}
          variant='text'
          position='static'
          activeStep={activeStep || 0}
          sx={{
            maxWidth: 250,
            flexGrow: 1,
            alignItems: 'center',
            backgroundColor: 'transparent',
            fontSize: '14px',
          }}
          nextButton={
            <Button
              size='small'
              onClick={onNextUrl}
              disabled={currentPage >= totalPages - 1 && isLastItem}
              data-testid='next-url'
              sx={{ paddingLeft: '8px' }}
              id={`next-url-${moduleType}`}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={onPrevUrl}
              disabled={currentUrlIndex <= 0 && currentPage === 0}
              data-testid='prev-url'
              sx={{ paddingRight: '8px' }}
              id={`prev-url-${moduleType}`}
            >
              <KeyboardArrowLeft />
              Previous
            </Button>
          }
        />
      ) : (
        <MobileStepper
          steps={1}
          variant='text'
          position='static'
          activeStep={0}
          sx={{
            maxWidth: 250,
            flexGrow: 1,
            alignItems: 'center',
            backgroundColor: 'transparent',
            fontSize: '14px',
          }}
          nextButton={
            <Button
              size='small'
              onClick={_.noop}
              disabled={true}
              data-testid='next-url'
              sx={{ paddingLeft: '8px' }}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={_.noop}
              disabled={true}
              data-testid='prev-url'
              sx={{ paddingRight: '8px' }}
            >
              <KeyboardArrowLeft />
              Previous
            </Button>
          }
        />
      )}
    </div>
  );
}
