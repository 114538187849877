import React from 'react';
import { Modal, Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  IContentCategory,
  IContentPlatform,
  IContentOrigin,
  appConstants,
} from '../../../../constants';
import { AppState } from '../../../../helpers';
import { ugcType } from '../../Types/ugc.types';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

const SAMPLES = {
  [ugcType.Social]: `https://www.youtube.com/watch?v=7mIsOQgc_gA, youtube, video, executive_impersonations`,
  [ugcType.Marketplace]: `https://www.amazon.com/search=?bankofbolster-desk-calenders, amazon, product, miscellaneous`,
  [ugcType.App_Store]: `https://www.apk4fun.com/apps/com.ubercab.uberlite/`,
  [ugcType.Dark_Web]: ``,
};

const SAMPLES_SEARCH_TERMS = {
  [ugcType.App_Store]: `roblox`,
};

interface IUploadAssetModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: any, onSuccess?: any, onFailure?: any) => void;
  isAddingNewDetections: boolean;
  type: string;
  ugcPlatforms: IContentPlatform[];
  ugcCategories: IContentCategory[];
  ugcOrigins: IContentOrigin[];
}

function UploadFindingFromFileModal({
  show,
  onHide,
  onSubmit,
  type,
  isAddingNewDetections,
  ugcPlatforms,
  ugcCategories,
}: IUploadAssetModalProps) {
  let fileSelector: any = {};

  const [, selectedTheme] = useIsDarkMode();
  const submitUpload = (event: any) => {
    onSubmit(event);
  };

  const convertValuesToString = (arr: any) => {
    let strResult = '';
    arr.forEach((item: any) => (strResult += `${item.docValue}, `));
    return strResult.slice(0, strResult.length - 2);
  };
  return (
    <div>
      <input
        type='file'
        name='file'
        id='file'
        accept={'.csv'}
        key={Math.random()}
        ref={ref => (fileSelector = ref)}
        onChange={submitUpload}
        style={{ display: 'none' }}
      />
      <Modal show={show} size='lg' onHide={onHide} className={`${selectedTheme}`}>
        <Modal.Header>
          <Modal.Title id='upload-detections'>Upload Detection</Modal.Title>
          <div onClick={onHide}>
            <ClearRoundedIcon />
          </div>
        </Modal.Header>
        <Modal.Body className={'modal-body upload-detection-modal-body'}>
          <p>
            Please select a <i>.csv</i> file with URLs separated by new rows
          </p>
          {type === 'app-store' ? (
            <>
              <p> Each row contains url of the detection and the Search Term (optional)</p>
              <table>
                <tbody>
                  <tr>
                    <th>URLs, Search Term (optional)</th>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <p>
                Each row contains url, platform, corresponding origin of platform, and category of
                the detection
              </p>
              <table>
                <tbody>
                  <tr>
                    <th>Platform</th>
                    <th>Corresponding origin of platform</th>
                  </tr>
                  {ugcPlatforms.map((platform: IContentPlatform, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{platform.docValue}</td>
                        <td>{convertValuesToString(platform.origins) || '--'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
          <br />
          <p>
            <b>Category: </b>
            {convertValuesToString(ugcCategories)}
          </p>
          <p>
            Sample row: {SAMPLES[type]}
            {type === appConstants.CONTENT_TYPE.APP_STORE && (
              <p>Sample Search Term: {SAMPLES_SEARCH_TERMS[type]}</p>
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={isAddingNewDetections}
            onClick={(e: any) => {
              e.preventDefault();
              fileSelector.click();
            }}
          >
            {isAddingNewDetections ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Select File'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { contents } = state.appReducer;
  return {
    ugcPlatforms: contents?.[type]?.platforms || [],
    ugcCategories: contents?.[type]?.categories || [],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFindingFromFileModal);
