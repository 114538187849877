import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import BarChart from '../../../Common/DashboardWidgets/BarChart';
import {
  IBarChartDataProps,
  IChartPlaceholderProps,
  TColorMap,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import { getColors } from '../../../../constants';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import UgcService from '../../../../services/ugc.service';
import { socialMediaSearchTermMockData } from '../../../../constants';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  type: string;
  widgetsTitle: any;
  isCustomCalendarRange: boolean;
}

const ugcService = new UgcService();

const SEARCH_TERM = 'Search Terms';
const LABEL_MAP = {
  SEARCH_TERM: SEARCH_TERM,
};

const convert2BarChartDataFormat = (rawData: any): IBarChartDataProps[] => {
  const result: IBarChartDataProps[] = [];
  _.forEach(rawData, detections => {
    const categories: { label: string; count: number }[] = [];
    const total = detections.count;
    categories.push({
      label: LABEL_MAP.SEARCH_TERM,
      count: total,
    });
    result.push({
      label: detections.label,
      total,
      categories,
    });
  });
  return result;
};

const placeholder: IChartPlaceholderProps = {
  icon: NoHosting,
  title: 'No Detection found',
  description:
    'We haven’t detected any phish and scam site hosting for this period. Please select another time frame.',
};

function UgcSearchTerm({
  startDate,
  endDate,
  type,
  widgetsTitle,
  isCustomCalendarRange,
}: IComponentProps) {
  const [, selectedTheme] = useIsDarkMode();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IBarChartDataProps[]>([]);
  const { title, tooltipDescription } = widgetsTitle;
  const isDemo = window.location.pathname.includes('premium') || false;

  const setWidgetData = (data: any) => {
    setData(convert2BarChartDataFormat(data));
    setIsLoading(false);
  };

  const colorMap: TColorMap = {
    [SEARCH_TERM]: getColors(selectedTheme).purpleDark2,
  };
  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      field: 'search_term_labels',
      startDate: startDate,
      endDate: endDate,
      limit: 10,
      isCustomCalendarRange: isCustomCalendarRange,
    };
    isDemo && type === 'social'
      ? setWidgetData(socialMediaSearchTermMockData)
      : ugcService
          .getDetectionsByField(query, type)
          .then((res: any) => {
            setWidgetData(res.counts);
          })
          .catch((error: any) => {
            console.log(error);
          });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate]);

  return (
    <BarChart
      title={title}
      isLoading={isLoading}
      description={tooltipDescription}
      data={data}
      colorMap={colorMap}
      placeholder={placeholder}
      showForSingleSeries={false}
    />
  );
}

export { UgcSearchTerm };
