import React, { useMemo } from 'react';
import { AttachmentAnalysisParams } from '../constants';
import { Stack } from '@mui/material';
import LableValue from '../Insights/LableValue';
import DispositionInsightsBlock, { InsightsAnalysisData } from './DispositionInsightsBlock';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
import { sortDispositionByPriority } from '../helpers';

type AggByDispotion = {
  [key: string]: Array<InsightsAnalysisData>;
};

interface IAllEmailAttachmentAnalysis {
  attachmentAnalysisData: AttachmentAnalysisParams;
}

function AllEmailAttachmentAnalysis({ attachmentAnalysisData }: IAllEmailAttachmentAnalysis) {
  const aggByDispotion: AggByDispotion = useMemo(() => {
    const data: AggByDispotion = {};
    attachmentAnalysisData.attachments.forEach(attachment => {
      const { initialDisposition } = attachment;
      const insights: InsightsAnalysisData = {
        label: 'Attachment SHA',
        value: attachment.sourceUrl || '',
        sha256: attachment.sha256 || '',
        source: attachment.attachmentType,
        name: attachment.name,
      };
      if (data[initialDisposition as string]) {
        data[initialDisposition as string].push(insights);
      } else {
        data[initialDisposition as string] = [insights];
      }
    });
    return data;
  }, [attachmentAnalysisData]);

  const renderNotAvailableText = () => {
    return <span className='not-available-text'>Not available</span>;
  };

  return (
    <div className='all-email-submissions-section-details-wrap email-attachment'>
      <Stack direction={'row'} spacing={1}>
        <div className='stack-item'>
          <span
            className='section-header'
            data-testid={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT}
            id={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT}
          >
            Attachment
          </span>
          <LableValue
            lable={`Number of Attachments`}
            value={attachmentAnalysisData.attachmentCounts}
            className='label-text-color'
          />
        </div>
        <div className='stack-item'>
          {!attachmentAnalysisData.attachmentCounts && renderNotAvailableText()}
          {sortDispositionByPriority(Object.keys(aggByDispotion)).map(key => (
            <DispositionInsightsBlock
              type={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT}
              initialDispotion={key}
              insightsAnalysisData={aggByDispotion[key]}
              key={key}
            />
          ))}
        </div>
      </Stack>
    </div>
  );
}

export default AllEmailAttachmentAnalysis;
