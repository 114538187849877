import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { alertActions } from '../../actions';
import { appConstants } from '../../constants';
import { useAppDispatch } from '../../helpers/hooks';
import { redirectUrlModuleTypeMap } from './AdvancedFiltersConstants';
import { AdvancedFilterParams } from './AdvancedFiltersUtils';
import {
  fetchAdvancedFilterRelatedUrls,
  fetchGlobalSearchRelatedUrls,
  IGlobalSearchDto,
} from './global-search-requests';
import { ESelectedFilterTypeOptions } from './GlobalSearchConstants';
import { IGlobalFilterOptions, IGlobalSearchAttributes } from './GlobalSearchContainer';

export interface IUseOnFetchRelatedUrlsFindingsProps {
  globalFilterOptions: IGlobalFilterOptions;
  searchValue: string;
  selectFilterType: ESelectedFilterTypeOptions;
  setIsLoading: (value: boolean) => void;
  setAgFilterFields: (value: any) => void;
  advancedFilterParams?: AdvancedFilterParams;
}
export default function useOnFetchRelatedUrlsFindings({
  globalFilterOptions,
  searchValue,
  selectFilterType,
  setIsLoading,
  setAgFilterFields,
  advancedFilterParams,
}: any) {
  const [globalSearchDstUrlsFindingData, setGlobalSearchDstUrlsFindingData] = useState<{
    [key: string]: any;
  }>({
    count: {},
  });

  const dispatch = useAppDispatch();

  const fetchRelatedUrls = useCallback(async () => {
    if (
      !_.isEmpty(globalSearchDstUrlsFindingData.count) &&
      globalSearchDstUrlsFindingData.searchValue === searchValue
    ) {
      return;
    }
    setIsLoading(true);
    const searchParams: any = globalFilterOptions?.[selectFilterType]
      ?.map((item: IGlobalSearchAttributes): { moduleType: string; fieldName: string } => {
        return {
          moduleType: item.globalSearchTypes.value,
          fieldName: item.globalSearchLabelFields.fieldName,
        };
      })
      .filter(
        (item: { moduleType: string; fieldName: string }) =>
          item.moduleType === appConstants.CONTENT_TYPE.WEB,
      );

    const filterFields = globalFilterOptions?.[selectFilterType]?.map(
      (item: IGlobalSearchAttributes) => {
        return {
          fieldName: item.globalSearchLabelFields.fieldName,
          moduleType: item.globalSearchTypes.value,
        };
      },
    );
    setAgFilterFields(filterFields);

    const globalSearchDto: IGlobalSearchDto = {
      searchTerm: searchValue,
      searchType: selectFilterType,
      searchParams,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    let updatedAdvancedFilterParams = { ...advancedFilterParams };

    if (updatedAdvancedFilterParams) {
      updatedAdvancedFilterParams = {
        ...updatedAdvancedFilterParams,
        moduleType: updatedAdvancedFilterParams?.moduleType?.map(
          (type: string) => redirectUrlModuleTypeMap[type] || type,
        ),
      };
    }

    try {
      const result = await (advancedFilterParams
        ? fetchAdvancedFilterRelatedUrls(updatedAdvancedFilterParams)
        : fetchGlobalSearchRelatedUrls(globalSearchDto));
      setGlobalSearchDstUrlsFindingData({ ...result, searchValue });
    } catch (error) {
      console.log('error', error);
      dispatch(alertActions.error('Something went wrong while fetching global search findings'));
    } finally {
      setIsLoading(false);
    }
  }, [
    globalFilterOptions,
    searchValue,
    selectFilterType,
    globalSearchDstUrlsFindingData,
    advancedFilterParams,
  ]);

  return {
    fetchRelatedUrls: () => void fetchRelatedUrls(),
    globalSearchDstUrlsFindingData,
  };
}
