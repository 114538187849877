import React from 'react';

export const LinksBreakdown: React.FC<{
  category: string;
  value: number;
  max: number;
  color: string;
}> = ({ category, value, max, color }) => {
  const percentage = (value / max) * 100 || 0;

  return (
    <div className='links-breakdown-container'>
      <div className='category-label'>{category}</div>
      <div className='rectangle-bar'>
        <div
          className={`category-bar ${percentage ? 'category-bar-has-value' : ''}`}
          style={{
            width: `${percentage}%`,
            backgroundColor: color,
          }}
        ></div>
        <div className='total-number'>{value}</div>
      </div>
    </div>
  );
};
