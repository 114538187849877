import React, { useCallback, useState } from 'react';
import { ITags } from './Tags';
import CustomTagColorPalette from './CustomTagColorPalette';
import { ICreateCustomTag, createCustomTag, fetchAllPlatformTags } from './tag-request';
import { useAppDispatch } from '../../../helpers/hooks';
import { alertActions, appActions } from '../../../actions';
import { LoadingSpinner } from '../LoadingSpinner';
import { setAllPlatformTags } from '../../../reducers/tags.reducer';
import { Button } from '@mui/material';

interface ICustomTagsProps {
  onSearchCreateTag: (newTag: string) => void;
  allProvidedTags: ITags[];
  onCancel: (e: React.SyntheticEvent) => void;
}

export default function CustomTags({
  onSearchCreateTag,
  allProvidedTags,
  onCancel,
}: ICustomTagsProps): JSX.Element {
  const [newTags, setNewTags] = useState<string>('');
  const [selectedColorId, setSelectedColorId] = useState<number>(0);

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCreateTagHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTags(e.target.value);
      onSearchCreateTag(e.target.value);
    },
    [setNewTags, onSearchCreateTag],
  );

  const resetCompState = useCallback(() => {
    setNewTags('');
    setSelectedColorId(0);
  }, [setNewTags, setSelectedColorId]);

  const onSubmitCreateCustomTags = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload: ICreateCustomTag = {
        colorId: selectedColorId,
        label: newTags,
      };
      const response = await createCustomTag(payload);
      if (response) {
        dispatch(alertActions.success('Custom tag created successfully'));
        const tags = await fetchAllPlatformTags();
        dispatch(setAllPlatformTags(tags));
      }

      resetCompState();
    } catch (error: any) {
      dispatch(alertActions.error(error?.message || 'Failed to create custom tag'));
    } finally {
      setIsLoading(false);
    }
  }, [newTags, selectedColorId, dispatch, resetCompState]);

  return (
    <div className='custom-tags-wrapper'>
      <input
        type='text'
        className={'form-control custom-tag-input'}
        placeholder='Search or create new tag'
        name='custom-tag'
        value={newTags}
        onChange={onCreateTagHandler}
      />
      {allProvidedTags.length === 0 && newTags && (
        <>
          <div className='color-palette-container'>
            <div className='custom-tag-title'>
              {' '}
              Create new tag <span>{`${newTags}`}</span>
            </div>
            <CustomTagColorPalette onColorSelect={setSelectedColorId} />
          </div>
          <div className='d-flex col-gap-12'>
            <Button variant='outlined' onClick={onCancel} data-testid='cancel-custom-tag-overaly'>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              onClick={onSubmitCreateCustomTags}
              disabled={isLoading || !newTags || !selectedColorId}
            >
              {isLoading ? (
                <LoadingSpinner
                  size={20}
                  customInnerClass='tags-loading'
                  customOuterClass='tags-loading-wrapper'
                />
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
