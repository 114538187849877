import { useCallback, useEffect, useState } from 'react';
import { EmailDetailsParams, EmailParams } from '../constants';
import { fetchEmailDetails } from './EmailDetails-requests';

export function useOnFetchEmailDetails(emailParams?: EmailParams, shouldFetchData: boolean = true) {
  const [abuseMailBoxEmailDetails, setAbuseMailBoxEmailDetails] = useState<{
    emailDetails: EmailDetailsParams[];
  }>({ emailDetails: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await fetchEmailDetails(emailParams);
      setAbuseMailBoxEmailDetails(resp);
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  }, [emailParams]);

  useEffect(() => {
    if (!shouldFetchData) return;
    void fetchData();
  }, [fetchData, shouldFetchData]);

  return { abuseMailBoxEmailDetails, isLoading };
}
