import React, { useEffect, useState } from 'react';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { PageTitle } from '../Common/PageTitle';
import { Form, Button } from 'react-bootstrap';
import './NotificationsSetting.scss';
import NotificationServices from '../../services/notifications.service';
import { Loader } from 'react-bootstrap-typeahead';
export interface INotificationSetting {
  id: number;
  name: string;
  description: string;
}

const notificationService = new NotificationServices();

const NotificationsSetting = () => {
  window.document.title = 'Notification | Checkphish Platform';
  const [isLoading, setIsLoading] = useState(true);
  const [updatingPreferenceState, setUpdatingPreferenceState] = useState(false);
  const [allNotificationSettings, setAllNotificationSettings] = useState<INotificationSetting[]>(
    [],
  );
  const [originalUserNotificationSettings, setOriginalUserNotificationSettings] = useState<
    Record<string, INotificationSetting>
  >({});
  const [userNotificationSettings, setUserNotificationSettings] = useState<
    Record<string, INotificationSetting>
  >({});

  const handleIncomingUserNotificationSettings = (userNotifications: INotificationSetting[]) => {
    const tempUserNotificationSettings: Record<string, INotificationSetting> = {};
    userNotifications.forEach(userNotification => {
      tempUserNotificationSettings[userNotification.id] = userNotification;
    });
    setUserNotificationSettings(tempUserNotificationSettings);
    setOriginalUserNotificationSettings(tempUserNotificationSettings);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      notificationService.getAllCommunityNotificationConfig(),
      notificationService.getCommunityUserNotificationPrefrences(),
    ])
      .then(results => {
        const [allNotifications, userNotifications] = results;
        setAllNotificationSettings(allNotifications);
        handleIncomingUserNotificationSettings(userNotifications);
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const onSettingsChange = (event: any, allNotificationSetting: INotificationSetting) => {
    const { id, checked } = event.target;
    if (checked) {
      userNotificationSettings[allNotificationSetting.id] = allNotificationSetting;
    } else {
      delete userNotificationSettings[allNotificationSetting.id];
    }
    setUserNotificationSettings({
      ...userNotificationSettings,
    });
  };

  const updateNotificationSettings = () => {
    setUpdatingPreferenceState(true);
    notificationService
      .updateCommunityUserNotificationSettings(Object.values(userNotificationSettings))
      .then(resp => handleIncomingUserNotificationSettings(resp))
      .catch(error => {
        console.log(error);
        setUserNotificationSettings({ ...originalUserNotificationSettings });
      })
      .finally(() => setUpdatingPreferenceState(false));
  };

  return (
    <AuthenticationWrapper>
      <PageTitle title={'Notification Settings'} titleAlwaysShown className={'background-white'} />
      {isLoading ? (
        <div className='loader-wrap'>
          <Loader />
        </div>
      ) : (
        <div className='notification-wrap'>
          <div className='sub-header'>
            Would you like to receive email notifications when changes to a domain you are
            monitoring happens:
          </div>
          <div className='notification-checkbox-wrap'>
            <Form className='notification-settings-form'>
              {allNotificationSettings.map((allNotificationSetting, index) => (
                <Form.Check
                  key={index}
                  type='checkbox'
                  id={`${allNotificationSetting.id}`}
                  label={allNotificationSetting.description}
                  className='notification-check'
                  checked={userNotificationSettings[allNotificationSetting.id] ? true : false}
                  onChange={e => onSettingsChange(e, allNotificationSetting)}
                />
              ))}
            </Form>
            <Button
              onClick={updateNotificationSettings}
              className='save-btn'
              disabled={updatingPreferenceState}
            >
              {updatingPreferenceState ? <Loader /> : 'Save'}
            </Button>
          </div>
        </div>
      )}
    </AuthenticationWrapper>
  );
};

export { NotificationsSetting };
