import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, CircularProgress } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

export interface IModalOverlayProps {
  show: boolean;
  title?: string;
  children?: ReactNode;
  submitButtonDisabled?: boolean;
  showButtons?: boolean;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  hideSubmitBtn?: boolean;
  hideCancelButton?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  customClassName?: string;
  centered?: boolean;
}

const ModalOverlay = ({
  show,
  title,
  children,
  submitButtonDisabled,
  showButtons,
  submitButtonLabel,
  cancelButtonLabel,
  isLoading,
  onSubmit,
  onCancel,
  hideSubmitBtn = false,
  size,
  hideCancelButton = false,
  customClassName,
  centered = false,
}: IModalOverlayProps) => {
  const [, selectedTheme] = useIsDarkMode();
  return (
    <Modal
      show={show}
      onHide={onCancel}
      className={`${selectedTheme} ${customClassName}`}
      size={size}
      centered={centered}
    >
      <Modal.Header>
        {title && <Modal.Title>{title}</Modal.Title>}
        <div onClick={onCancel}>
          <ClearRoundedIcon />
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {showButtons && (
        <Modal.Footer>
          <div className='button-container'>
            {!hideCancelButton && (
              <Button variant='outlined' onClick={onCancel}>
                {cancelButtonLabel}
              </Button>
            )}

            {!hideSubmitBtn && (
              <Button
                variant='contained'
                disabled={submitButtonDisabled || isLoading}
                onClick={onSubmit}
              >
                {isLoading ? <CircularProgress size={16} /> : submitButtonLabel}
              </Button>
            )}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalOverlay.defaultProps = {
  showButtons: true,
  submitButtonLabel: 'Submit',
  cancelButtonLabel: 'Cancel',
};

export default ModalOverlay;
