import React, { useContext, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { RISK_WIDGET_TYPE } from '../../../../constants';
import MailServerLightIcon from '../../../../assets/icons/newDomainMonitoringDashboard/MailServerLight.svg';
import MailServerLightDark from '../../../../assets/icons/newDomainMonitoringDashboard/MailServerDark.svg';
import HostingIpLightIcon from '../../../../assets/icons/newDomainMonitoringDashboard/HostingIpLight.svg';
import HostingIpDarkIcon from '../../../../assets/icons/newDomainMonitoringDashboard/HostingIpDark.svg';
import LiveDomainLightIcon from '../../../../assets/icons/newDomainMonitoringDashboard/LiveDomainLight.svg';
import LiveDomainDarkIcon from '../../../../assets/icons/newDomainMonitoringDashboard/LiveDomainDark.svg';
import CategoryLightIcon from '../../../../assets/icons/newDomainMonitoringDashboard/CategoryLight.svg';
import CategoryDarkIcon from '../../../../assets/icons/newDomainMonitoringDashboard/CategoryDark.svg';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes } from '../../../../constants';
import { faSortUp } from '@fortawesome/free-solid-svg-icons';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter, Link } from 'react-router-dom';
import './TopRiskWidget.scss';
import CardWrapper from '../common/CardWrapper';
import _ from 'lodash';
import { history } from '../../../../helpers';

export type RiskDataType = { href: string; value: string };

export interface TopRiskWidgetProps {
  type: RISK_WIDGET_TYPE;
  deltaVal: {
    heading: string;
    total: number;
    positive: boolean;
    delta: number;
  };
  topRiskVal: {
    heading: string;
    isLink: boolean;
    data: RiskDataType[];
  };
}

const NoDataMapper = {
  [RISK_WIDGET_TYPE.ACTIVE_DOMAIN]: 'active domains',
  [RISK_WIDGET_TYPE.A_RECORD]: 'A records',
  [RISK_WIDGET_TYPE.NS_RECORD]: 'NS records',
  [RISK_WIDGET_TYPE.MX_RECORD]: 'MX records',
};

const TopRiskWidget = ({ type, deltaVal, topRiskVal }: TopRiskWidgetProps) => {
  const { themeName } = useContext(ThemeContext);
  const icon = useMemo(() => {
    switch (type) {
      case RISK_WIDGET_TYPE.MX_RECORD:
        return themeName === ThemeModes.LIGHT ? MailServerLightIcon : MailServerLightDark;
      case RISK_WIDGET_TYPE.A_RECORD:
        return themeName === ThemeModes.LIGHT ? HostingIpLightIcon : HostingIpDarkIcon;
      case RISK_WIDGET_TYPE.ACTIVE_DOMAIN:
        return themeName === ThemeModes.LIGHT ? LiveDomainLightIcon : LiveDomainDarkIcon;
      default:
        return themeName === ThemeModes.LIGHT ? CategoryLightIcon : CategoryDarkIcon;
    }
  }, [type, themeName]);
  const deltaIcon = useMemo(() => {
    if (deltaVal.positive) {
      return (
        <FontAwesomeIcon
          icon={faSortDown}
          style={{ color: '#27A95B', height: '10px', marginBlockEnd: '2px' }}
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faSortUp}
        style={{ color: '#FC4949', height: '10px', marginBlockEnd: '2px' }}
      />
    );
  }, [deltaVal.positive]);
  return (
    <div style={{ margin: '5px' }} className='top-risk-container' data-testid='data-risk-wrap'>
      <CardWrapper>
        <div className='risk-widget-wrap'>
          <div className='delta-wrap'>
            <div className='heading-wrap'>
              <div className='main-heading'>{deltaVal.heading}</div>
              <img src={icon} alt={`risk-type-icon-${themeName}`} />
            </div>
            <div>
              <div className='delta-data'>
                <div className='total-count'>{deltaVal.total}</div>
                {!_.isNaN(deltaVal.delta) && deltaVal.delta ? (
                  <>
                    <div
                      className={`delta ${deltaVal.positive ? 'delta-green' : 'delta-red'}`}
                      data-testid='delta-val'
                    >
                      {deltaIcon} <span>{deltaVal.delta}</span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className='sub-heading'>Compared to last week</div>
            </div>
          </div>
          <div className='top-risk-data-wrap'>
            <div className='heading'>{topRiskVal.heading}</div>
            <div className='risk-data'>
              {_.isEmpty(topRiskVal.data) ? (
                <span className='no-data'>No {NoDataMapper[type]} found since the last scan. </span>
              ) : (
                topRiskVal.data.map((item: RiskDataType, index: number) =>
                  topRiskVal.isLink ? (
                    <button
                      onClick={() => history.push(item.href)}
                      className='risk-item link'
                      key={index}
                    >
                      {item.value}
                    </button>
                  ) : (
                    <span className='risk-item' key={index}>
                      {item.value}
                    </span>
                  ),
                )
              )}
            </div>
          </div>
        </div>
      </CardWrapper>
    </div>
  );
};

export default TopRiskWidget;
