import React, { useContext } from 'react';
import { EFindingStatus, ugcType } from '../../../Types/ugc.types';
import FindingStatus from '../../Common/FindingStatus';
import { history } from '../../../../../helpers';
import { PageTitleInsights } from '../../../../Common/PageTitleInsights';
import { appConstants } from '../../../../../constants';
import ActiveRoutesContext from '../../../../../context/ActiveRoutesContext';
import {
  UGC_DETECTION_PATHNAME,
  UGC_TAKENDOWN_PATHNAME,
  UGC_IGNORED_PATHNAME,
  UGC_IN_PROGRESS_PATHNAME,
  UGC_REVIEW_PATHNAME,
} from '../../../constants';
import { useReadOnlyUser } from '../../../../../basic-hooks/useUserRoles';
import { Button } from '@mui/material';

export const typeToModuleRoute = {
  [appConstants.CONTENT_TYPE.SOCIAL]: '/social-media',
  [appConstants.CONTENT_TYPE.APP_STORE]: '/app-store',
  [appConstants.CONTENT_TYPE.MARKETPLACE]: '/marketplace',
};

const Header = ({ data, onRequestTakedown, onResumeTakedown, onMarkSafelist, moduleType }: any) => {
  const { activeRoute } = useContext(ActiveRoutesContext);

  const { status } = data;

  const renderBackPages = () => {
    switch (activeRoute) {
      case UGC_DETECTION_PATHNAME:
        history.push(`${typeToModuleRoute[moduleType]}/${UGC_DETECTION_PATHNAME}`);
        break;
      case UGC_IN_PROGRESS_PATHNAME:
        history.push(`${typeToModuleRoute[moduleType]}/${UGC_IN_PROGRESS_PATHNAME}`);
        break;
      case UGC_IGNORED_PATHNAME:
        history.push(`${typeToModuleRoute[moduleType]}/${UGC_IGNORED_PATHNAME}`);
        break;
      case UGC_TAKENDOWN_PATHNAME:
        history.push(`${typeToModuleRoute[moduleType]}/${UGC_TAKENDOWN_PATHNAME}`);
        break;
      case UGC_REVIEW_PATHNAME:
        history.push(`${typeToModuleRoute[moduleType]}/${UGC_REVIEW_PATHNAME}`);
        break;
    }
  };

  const UgcActions = () => {
    let actions = null;
    switch (status) {
      case EFindingStatus.LIVE:
        actions = (
          <>
            <Button variant='text' className={'finding-header-button'} onClick={onRequestTakedown}>
              Request Takedown
            </Button>
            <Button variant='text' className={'finding-header-button'} onClick={onMarkSafelist}>
              Add to Ignored
            </Button>
          </>
        );
        break;
      case EFindingStatus.PAUSED:
        actions = (
          <div className={'finding-header-button'} onClick={onResumeTakedown}>
            Resume Takedown
          </div>
        );
        break;
      default:
        break;
    }
    const isReadOnlyUser = useReadOnlyUser();
    if (isReadOnlyUser) {
      actions = null;
    }
    return (
      <div className={'dispute-dom-container d-flex align-items-center'}>
        <FindingStatus status={status} />
        {actions}
      </div>
    );
  };

  return <PageTitleInsights title={data.url} backFn={renderBackPages} toolsLeft={<UgcActions />} />;
};

export default Header;
