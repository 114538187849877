import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Button } from '@mui/material';
import { isAdminUser, appConstants, featureIsAvailable, isBrandModuleAdmin } from '../../constants';
import { getCSVFile } from '../Assets_v2/util';
import { TExportTable } from '../Common/Table/constant';
import { TableContextProvider } from '../Common/Table/table.context';
import { ITableDataColumn, TableData } from '../Common/Table/table.data';
import {
  TEAM_MEMBER_ACTION_COL,
  TEAM_MEMBER_BRAND_NAME,
  TEAM_MEMBER_EMAIL,
  TEAM_MEMBER_FIRST_NAME,
  TEAM_MEMBER_IS_VERIFIED,
  TEAM_MEMBER_LAST_ACTIVITY,
  TEAM_MEMBER_LAST_NAME,
  TEAM_MEMBER_MODULE,
  TEAM_MEMBER_ROLE,
} from './common/TeamMemberColDefs';
import './TeamMembers.scss';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { alertActions } from '../../actions';
import AddTeamMemberModal from './common/AddTeamMemberModal';
import { TEAM_MEMBER_TABLE_ID } from './TeamMemberConstant';
import { massagedTeamMembersData } from './team-member-utils';
import { addNewTeamMember, deleteTeamMember, fetchAllTeamMembers } from './team-member-requests';
import DeleteTeamMemberModal from './common/DeleteTeamMemberModal';
import {
  setIsAgGridClientMode,
  setShouldRefreshClientSideTable,
} from '../../reducers/table.reducer';

export interface ITeamMemberData {
  firstName: string;
  lastName: string;
  email: string;
  verified: string;
  lastActivity: string;
  roleId: string;
  editable: boolean;
  brandId: string;
  lastActiveTs: string;
}

export interface ITeamMemberProps {}

export interface IUserListItemDto {
  email: string;
  lastActiveTs: string;
  isVerified: boolean;
  user: User;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  brand: IBrandDisplayName;
  userAttribute: IUserAttribute;
  userFeatureAccess: IUserFeatureAccess[];
}

export interface IUserFeatureAccess {
  userId: number;
  productFeatureAccess: {
    description: string;
    pf: {
      code: string;
      label: string;
    };
  };
}

export interface IBrandDisplayName {
  brandDisplayName: string;
}

export interface IUserAttribute {
  userId: number;
  roleId: number;
  typeId: number;
  isInternal: boolean;
  createdTs: string;
  updatedTs: string;
  role: IUserRole;
}

export interface IUserRole {
  role_name: string;
}

const TeamMembers = (props: ITeamMemberProps) => {
  window.document.title = 'Bolster Platform | Team Members';
  const dispatch = useAppDispatch();
  const TABLE_COLUMNS: any[] = [
    TEAM_MEMBER_FIRST_NAME,
    TEAM_MEMBER_LAST_NAME,
    TEAM_MEMBER_EMAIL,
    TEAM_MEMBER_BRAND_NAME,
    TEAM_MEMBER_IS_VERIFIED,
    TEAM_MEMBER_ROLE,
    TEAM_MEMBER_LAST_ACTIVITY,
  ];
  const user = useAppSelector(state => state.dashboardReducer.user);
  const exportCSVTitle = `Bolster Platform Team members`;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamMembersData, setTeamMembersData] = useState<ITeamMemberData[]>([]);
  const [showAddTeamMembersModal, setShowAddTeamMembersModal] = useState<boolean>(false);
  const customTools = [];

  const shouldRefreshClientSideTable = useAppSelector(
    state => state.tableReducer.shouldRefreshClientSideTable,
  );

  if (user && isAdminUser(user, false, false)) {
    customTools.push(
      <Button
        key='add-team-member'
        variant='contained'
        className='ml-3'
        onClick={() => setShowAddTeamMembersModal(true)}
      >
        Add Team Member
      </Button>,
    );
  }

  const fetchTeamMembers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchAllTeamMembers();
      if (response) {
        const teamMembers = massagedTeamMembersData(response, user);
        //
        setTeamMembersData(teamMembers);
      }
    } catch (error) {
      dispatch(alertActions.error(error as any));
    } finally {
      setIsLoading(false);
      if (shouldRefreshClientSideTable) {
        dispatch(setShouldRefreshClientSideTable(false));
      }
    }
  }, [dispatch, user, shouldRefreshClientSideTable]);

  //This call on initial load
  useEffect(() => {
    void fetchTeamMembers();
  }, []);

  //This call on refetching the members data after editing
  useEffect(() => {
    if (shouldRefreshClientSideTable) {
      void fetchTeamMembers();
    }
  }, [shouldRefreshClientSideTable, fetchTeamMembers]);

  const onAddNewMember = async (payload: any) => {
    setIsLoading(true);
    try {
      const res = await addNewTeamMember(payload);
      if (res.result) {
        dispatch(
          alertActions.success(
            '1 user ' + payload['firstName'] + ' ' + payload['lastName'] + ' has been added',
          ),
        );
        dispatch(alertActions.success('Email sent to invite ' + payload['email']));
        void fetchTeamMembers();
      }
    } catch (error) {
      dispatch(alertActions.error(_.get(error, ['User'], 'Add user failed!')));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  const onDeleteTeamMember = async (deletedMember: ITeamMemberData) => {
    setIsLoading(true);
    try {
      const res = await deleteTeamMember(deletedMember);

      if (res.result) {
        dispatch(
          alertActions.success(
            `User ${deletedMember['firstName']} ${deletedMember['lastName']} deleted`,
          ),
        );
        void fetchTeamMembers();
      }
    } catch (error: any) {
      dispatch(alertActions.error((typeof error == 'string' && error.trim()) || error?.message));
    } finally {
      setIsLoading(false);
    }
  };

  const exportTeamMembers = (data: any, columns: ITableDataColumn[], type: TExportTable) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  const closeAddTeamMembersModal = () => {
    setShowAddTeamMembersModal(false);
  };

  const modifyTeamMembersCols = (cols: ITableDataColumn[]) => {
    const shouldEditable = user && isAdminUser(user, false, false) ? true : false;
    const editableFirstName = _.find(cols, ['id', 'firstName']);
    if (editableFirstName) {
      editableFirstName.editable = shouldEditable;
    }
    const editableLastName = _.find(cols, ['id', 'lastName']);
    if (editableLastName) {
      editableLastName.editable = shouldEditable;
    }
    if (user && isAdminUser(user, false, false) && !isBrandModuleAdmin(user)) {
      cols.push({
        ...TEAM_MEMBER_ACTION_COL,
        render: (data: ITeamMemberData) => {
          return (
            <DeleteTeamMemberModal
              data={data}
              onDeleteTeamMember={onDeleteTeamMember}
              isLoading={isLoading}
            />
          );
        },
      });
    }
    return cols;
  };

  const isReadOnlyFeatureOn = featureIsAvailable(
    user,
    appConstants.FEATURE_CODE.READ_ONLY_USER_PER_MODULE,
  );

  if (
    isReadOnlyFeatureOn &&
    user &&
    isAdminUser(user, false, false) &&
    !TABLE_COLUMNS.includes(TEAM_MEMBER_MODULE)
  ) {
    // put module column in last 2nd position
    TABLE_COLUMNS.splice(TABLE_COLUMNS.length - 1, 0, TEAM_MEMBER_MODULE);
  }

  if (!user?.role_name) return null;

  // filter teamMembersData based on user role?

  return (
    <>
      <div className={' table-page page-content team-member-page'}>
        <Card className='team-member-table-container'>
          <TableContextProvider
            columns={TABLE_COLUMNS}
            dashboardName={'Team Members'}
            tableId={TEAM_MEMBER_TABLE_ID}
            modifyColumns={modifyTeamMembersCols}
          >
            <TableData
              id={TEAM_MEMBER_TABLE_ID}
              tableIndex={'brand_team_member'}
              title={'Team Members'}
              indexBy={'email'}
              showLoader={isLoading}
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              exportFn={exportTeamMembers}
              data={teamMembersData}
              columns={TABLE_COLUMNS}
              user={user}
              disableDatePicker
              disablePagination
              hideDeleteBin
              customTools={customTools}
              type={appConstants.CONTENT_TYPE.TEAM_MEMBERS}
            />
          </TableContextProvider>
        </Card>
      </div>
      {showAddTeamMembersModal && (
        <AddTeamMemberModal
          showAddTeamMembersModal={showAddTeamMembersModal}
          onAddNewMember={onAddNewMember}
          closeAddTeamMembersModal={closeAddTeamMembersModal}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default TeamMembers;
