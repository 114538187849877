import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { IDropdownOption, Dropdown } from '../Common/Dropdown';
import { Form, Modal, Spinner } from 'react-bootstrap';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Button } from '@mui/material';

export const DOMAIN_NAMES_SEPARATOR = ',';
interface IFormValues {
  domain: string;
  brandId: number;
}
export interface IAddNewAssetModalProps {
  initialDomains?: any[];
  show: boolean;
  onHide: () => void;
  onSubmit: (data: any, onSuccess?: any, onFailure?: any) => void;
  brandInfo: any;
  isAddingNewAssets: boolean;
  selectedTheme: string;
}

const AddNewAssetModal = ({
  initialDomains = [],
  show,
  onHide,
  onSubmit,
  brandInfo,
  isAddingNewAssets,
  selectedTheme,
}: IAddNewAssetModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [emptyValues, setEmptyValues] = useState({
    domains: false,
    brandId: false,
  });

  const [formValues, setFormValues] = useState<IFormValues>({
    domain: '',
    brandId: 0,
  });

  useEffect(() => {
    setEmptyValues(prevState => {
      const nextState = { ...prevState };
      Object.keys(nextState).forEach(key => (nextState[key] = false));
      return nextState;
    });
    setFormValues({
      domain: '',
      brandId: 0,
    });
  }, [show]);

  const updateFormValues = (key: string, value: any) => {
    setFormValues(prevState => ({ ...prevState, [key]: value }));
  };

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormValues('domain', event.target.value);
  };

  const handleBrandInputChange = (selection: IDropdownOption) => {
    updateFormValues('brandId', selection.value);
  };

  const onFormSubmit = () => {
    const data = {};
    Object.keys(formValues).forEach(key => {
      const value = formValues[key];
      if (emptyValues[key]) {
        data[key] = '';
      } else if (value !== undefined && value !== '') {
        data[key] = value;
      }
    });

    setIsLoading(true);
    onSubmit(
      data,
      () => {
        setIsLoading(false);
        onHide();
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const brandOptions = (): any[] => {
    //return array of subBrands
    const brands: any = [];
    brandInfo?.subBrands.map((brand: any) => {
      const result = {};
      result['label'] = brand.brand_display_name;
      result['value'] = brand.brand_id;
      brands.push(result);
    });
    return brands;
  };
  return (
    <Modal show={show} size='lg' onHide={onHide} className={`${selectedTheme}`}>
      <Modal.Header>
        <Modal.Title id='add-assets-input'>Add Asset</Modal.Title>
        <div onClick={onHide}>
          <ClearRoundedIcon />
        </div>
      </Modal.Header>
      <Modal.Body className={'modal-body'}>
        <Form>
          {brandInfo?.brandMapping === true && brandInfo?.subBrands?.length > 1 ? (
            <Form.Group>
              <Form.Label>Select Brand:</Form.Label>
              <Dropdown boxStyle options={brandOptions()} onChange={handleBrandInputChange} />
            </Form.Group>
          ) : null}

          <Form.Group>
            <Form.Label>
              Please enter one or more domains, separated by comma (e.g. www.abc.com)
            </Form.Label>
            <Form.Control as='textarea' rows={10} onChange={handleDomainChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={onHide}>
          Cancel
        </Button>
        <Button variant='contained' disabled={isAddingNewAssets} onClick={onFormSubmit}>
          {isLoading ? (
            <Spinner className='spinner' animation='border' variant='light' size='sm' />
          ) : (
            'Submit'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewAssetModal;
