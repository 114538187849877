import React, { useState, useEffect, useCallback, createContext } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../../../helpers';
import { alertActions, dashboardActions } from '../../../actions';
import { IDropdownOption } from '../Dropdown';
import {
  AlertActionsTypes,
  generateWidgetId,
  DashBoardDto,
  ITableConfigDto,
  IBrandInfo,
  IAttribute,
  ICategory,
  BrandType,
  capitalize,
  setColumnsLabels,
  getUsersListLabelValue,
  featureIsAvailable,
  appConstants,
} from '../../../constants';
import { ITableApiColumn } from './table.api';
import DashboardService from '../../../services/dashboard.service';
import { WebUrlData } from '../../../types/web-url-data.interface';
import { IUserListItemDto } from '../../TeamMembers/TeamMembers';
import {
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_BOLSTER,
  MANAGED_BY_ORGANIZATION,
} from '../../Assets_v2/constants';
import { defaultDisplayColumns } from './ag-table/ag-defaultColumns-constants';
import { useAppDispatch } from '../../../helpers/hooks';
import { TEAM_MEMBER_TABLE_ID } from '../../TeamMembers/TeamMemberConstant';
import { WebScanSourceFilterOptions } from './constant';

const dashboardService = new DashboardService();

interface IContextValue {
  columns: ITableApiColumn[];
  displayedColumnsIDs: string[];
  onTableSaveColumns?: (columnNames: string[], cb: () => void) => void;
  setFindings?: (findings: WebUrlData[]) => any;
  takedownModalShown?: React.Dispatch<React.SetStateAction<boolean>>;
  disputeModalShown?: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardName?: string;
  tableId?: string;
  onItemCheck?: (checked: boolean, item: any, selectedItems: any) => void;
}

const contextDefaultValue: IContextValue = {
  columns: [],
  displayedColumnsIDs: [],
  onTableSaveColumns: _.noop,
};

export const TableContext = createContext(contextDefaultValue);

const TABLE_WIDGET_PREFIX = 'table_';
interface ITableWidget {
  idx: number;
  name: string;
  widgetType: string;
  columns: string;
}
const createTableConfigWidget = (widgetName: string, columns: string): ITableWidget => {
  const ts = generateWidgetId();
  return {
    idx: ts,
    name: widgetName + '_' + ts,
    widgetType: 'table',
    columns,
  };
};

interface ITableDashboardConfig {
  name: string;
  isDefault: boolean;
  dashboardType: BrandType;
  widgets: ITableWidget[];
}
interface ITableDashboard {
  dashboard: ITableDashboardConfig;
}

export const DASHBOARD_TYPE_TABLE_CONFIG = 'table-config';
const createTableConfigDashboard = (
  dashboardName: string,
  widgets: ITableWidget[],
): ITableDashboard => {
  return {
    dashboard: {
      name: dashboardName,
      isDefault: false,
      dashboardType: DASHBOARD_TYPE_TABLE_CONFIG,
      widgets,
    },
  };
};

export interface ILinkTableCntextDispatchProps {
  user: DashBoardDto;
  usersList: IUserListItemDto[];
  columns?: ITableApiColumn[];
  brandInfo: IBrandInfo | undefined;
  tablesConfig: ITableConfigDto[];
  tableIndex?: string;
  attributes: IAttribute[];
  categories: ICategory[];
  categoriesAndGroupsOptions: IDropdownOption[];
  urlConstructions: IDropdownOption[];
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  getDashboardConfig: (name?: string, cb?: () => void) => void;
  onSaveDashboard: (config: any) => any;
  getBrandInfo: (brandType: BrandType) => void;
  onItemCheck?: (checked: boolean, item: any, selectedItems: any) => void;
}

export interface ITableContextComponentProps {
  dashboardName: string;
  tableId: string;
  children: any;
  modifyColumns?: (columns: ITableApiColumn[]) => any;
  isGroupViewEnabled?: boolean;
  setFindings?: (findings: WebUrlData[]) => any;
  takedownModalShown?: React.Dispatch<React.SetStateAction<boolean>>;
  disputeModalShown?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultColumnIds?: string[];
}

export interface IEventProps {
  onUserChanged?: (user: DashBoardDto) => void;
}

const TableContextProvider = (
  props: ILinkTableCntextDispatchProps & ITableContextComponentProps & IEventProps,
) => {
  const {
    user,
    brandInfo,
    tablesConfig,
    tableIndex,
    attributes,
    categories,
    categoriesAndGroupsOptions,
    urlConstructions,
    dashboardName,
    tableId,
    children,
    onUserChanged = _.noop,
    getDashboardConfig,
    onSaveDashboard,
    getBrandInfo,
    isGroupViewEnabled,
    setFindings,
    takedownModalShown,
    disputeModalShown,
    usersList,
    onItemCheck,
  } = props;

  const defaultColumnToDisplay = defaultDisplayColumns?.[tableId]?.split(',');
  const [displayedColumnsIDs, setDisplayedColumnsIDs] = useState<string[]>(defaultColumnToDisplay);
  const [columns, setColumns] = useState<ITableApiColumn[]>(props.columns || []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!_.isEmpty(brandInfo) && !_.isEmpty(categories)) {
      modifyColumns();
    }
  }, [brandInfo, attributes, categories, urlConstructions, isGroupViewEnabled]);

  useEffect(() => {
    onUserChanged(user);
    if (!brandInfo && !_.isEmpty(user.email)) {
      getBrandInfo(user.type_name);
    }
  }, [user]);

  // on intial render get the table config from zionback
  useEffect(() => {
    const dashboard = _.find(tablesConfig, dashboard => dashboard.dashboardName === dashboardName);
    if (
      tablesConfig !== undefined &&
      tablesConfig.length >= 1 &&
      dashboard?.dashboardName !== 'Default'
    ) {
      if (dashboard) {
        const tableWidget = _.find(dashboard.widgets, widget => {
          //REGEX to get the correct tableId from the widget name
          const tableIdInWidget = widget.name.includes('-')
            ? widget.name.match(/table_(\w+-\w+)_\d+/)?.[1]
            : widget.name.match(/table_(\w+)_\d+/)?.[1];
          return (
            widget.name.indexOf(TABLE_WIDGET_PREFIX + tableId) === 0 && tableIdInWidget === tableId
          );
        });
        if (tableWidget) {
          const displayedColumns = _.get(tableWidget, ['columns'], '');
          if (!_.isEmpty(displayedColumns)) {
            setDisplayedColumnsIDs(displayedColumns.split(','));
          } else {
            if (displayedColumns.length === 0) {
              setDisplayedColumnsIDs(defaultColumnToDisplay);
            }
          }
        }
      } else {
        onTableSaveColumns(displayedColumnsIDs);
      }
    } else {
      if (tablesConfig?.length == 1 && dashboard?.dashboardName === 'Default') {
        setDisplayedColumnsIDs(defaultColumnToDisplay);
      }
    }
  }, [tablesConfig]);

  const modifyColumns = () => {
    const modifiedColumns = _.cloneDeep(columns);
    if (tableIndex) {
      setColumnsLabels(attributes, tableIndex, modifiedColumns);
    }
    setSourceColumnOptions(modifiedColumns);
    setBrandColumnOptions(modifiedColumns, 'sub_brand_id', 'brand_name');
    setBrandColumnOptions(modifiedColumns, 'brand', 'brand_id');
    setCategoryColumnOptions(modifiedColumns);
    setUrlConstructionColumnOptions(modifiedColumns);
    if (props.modifyColumns) {
      props.modifyColumns(modifiedColumns);
    }
    setColumns(modifiedColumns);
  };

  const setSourceColumnOptions = (columns: ITableApiColumn[]) => {
    const sourceColumn = _.find(columns, ['id', 'scan_source']);
    if (sourceColumn && usersList) {
      sourceColumn.filterOptions = [
        { label: 'Bolster', value: WebScanSourceFilterOptions.BOLSTER },
        { label: 'Not Bolster', value: WebScanSourceFilterOptions.NOT_BOLSTER },
        ...getUsersListLabelValue(usersList),
      ];
    }
  };

  const setBrandColumnOptions = (columns: ITableApiColumn[], columnId: string, value: any) => {
    const brandColumn = _.find(columns, ['id', columnId]);
    if (brandColumn && brandInfo) {
      brandColumn.filterOptions = _.chain(brandInfo.brand.subBrands)
        .map(subBrand => ({
          label: capitalize(subBrand.brand_display_name) || capitalize(subBrand.brand_name),
          value:
            tableId === MANAGED_BY_BOLSTER ||
            tableId === MANAGED_BY_ORGANIZATION ||
            tableId === TEAM_MEMBER_TABLE_ID ||
            tableId === MANAGED_BY_AFFILIATED
              ? subBrand.brand_display_name
              : subBrand[value],
        }))
        .sortBy('label')
        .value();
    }
  };

  const setCategoryColumnOptions = (columns: ITableApiColumn[]) => {
    const categoryColumn = _.find(columns, ['id', 'final_category']);
    if (categoryColumn && brandInfo) {
      categoryColumn.filterOptions = categories;
    }
  };

  const setUrlConstructionColumnOptions = (columns: ITableApiColumn[]) => {
    const urlConstructionColumn = _.find(
      columns,
      c => c.id === 'matrix_algorithm' || c.id === 'fuzz_algorithm',
    );
    if (urlConstructionColumn) {
      urlConstructionColumn.filterOptions = urlConstructions;
      urlConstructionColumn.render = (data: any) => {
        let value = data.matrix_algorithm || data.fuzz_algorithm || '--';
        value = _.chain(urlConstructions).find(['value', value]).get('label', '--').value();
        return value;
      };
    }
  };

  const onTableSaveColumns = useCallback(
    async (columns, cb?) => {
      let tableWidget;
      const dashboard = _.find(
        tablesConfig,
        dashboard => dashboard.dashboardName === dashboardName,
      );
      if (dashboard) {
        tableWidget = _.find(
          dashboard.widgets,
          widget => widget.name.indexOf(TABLE_WIDGET_PREFIX + tableId) === 0,
        );
      }
      try {
        if (tableWidget) {
          await dashboardService
            .updateTableColumns(tableWidget.name, columns.join(','))
            .catch((error: any) => {
              dispatch(alertActions.error(error?.[0] ?? 'Error saving table columns'));
            });
        } else {
          const tableWidget = createTableConfigWidget(
            TABLE_WIDGET_PREFIX + tableId,
            columns?.join(','),
          );
          const dashboardConfig = createTableConfigDashboard(dashboardName, [tableWidget]);
          await onSaveDashboard(dashboardConfig);
        }
      } catch (error: any) {
        dispatch(alertActions.error('Error saving table columns'));
      } finally {
        getDashboardConfig('', cb);
      }
    },
    [tablesConfig],
  );

  return (
    <TableContext.Provider
      value={{
        columns,
        displayedColumnsIDs: displayedColumnsIDs,
        onTableSaveColumns,
        setFindings,
        disputeModalShown,
        takedownModalShown,
        dashboardName,
        tableId,
        onItemCheck,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

const mapStateToProps = (state: AppState) => {
  const { usersList, user, brandInfo, tablesConfig } = state.dashboardReducer;
  const { attributes, categories, categoriesAndGroupsOptions, urlConstructions } = state.appReducer;
  return {
    user,
    usersList,
    brandInfo,
    tablesConfig,
    attributes,
    categories,
    categoriesAndGroupsOptions,
    urlConstructions,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  getBrandInfo: dashboardActions?.getBrandInfo || _.noop,
  getDashboardConfig: dashboardActions?.getDashboardConfig || _.noop,
  onSaveDashboard: dashboardActions?.onSaveDashboard || _.noop,
};

const connectedTableContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableContextProvider);
export { connectedTableContextProvider as TableContextProvider };
