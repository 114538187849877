export const STATUS_LABELS_MAP: { [key: string]: string } = {
  in_progress: 'In Progress',
  taken_down: 'Takendown',
  request_takedown: 'Request Takedown',
  not_applicable: 'Not Applicable',
  exhausted: 'Exhausted',
  awaiting_documents: 'Awaiting Documents',
  active: 'Active',
  cancelled: 'Cancelled',
};
