import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import ModalOverlay from './ModalOverlay';
import { TimerIcon } from '../../assets/SVGIcons';
import './UserInActiveOverlay.scss';
import { userActions } from '../../actions';
import { getLocalStorageValue, setLocalStorageValue } from '../../constants';
import moment from 'moment';
import ActiveBrowserTabContext from '../../context/ActiveBrowserTabContext';
import { useAppDispatch } from '../../helpers/hooks';
import UserService from '../../services/user.service';

const UserInActiveOverlay = () => {
  const sessionInActiveLimit = getLocalStorageValue('sessionInActiveLimit');
  const { isTabHidden, onSameDomain } = useContext(ActiveBrowserTabContext);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const overlayDetails = useRef({
    title: 'Are you still here?',
    description: 'If not, your online session will end in:',
  });
  const userService = useMemo(() => new UserService(), []);

  const dispatch = useAppDispatch();

  const timeoutId = useRef<NodeJS.Timeout>();

  const resetTimer = useCallback(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setShowOverlay(true);
    }, sessionInActiveLimit);
  }, [sessionInActiveLimit]);

  const handleUserActivity = useCallback(() => {
    setLocalStorageValue('userLatestActivity', moment().valueOf());
    if (!showOverlay) {
      resetTimer();
    }
  }, [showOverlay, resetTimer]);

  const checkSessionExpiration = useCallback(async () => {
    const sessionExpiration = getLocalStorageValue('sessionExpiration');
    const now = moment().valueOf();
    const remainingTime = sessionExpiration - now;

    if (remainingTime <= 0) {
      dispatch(userActions.signOut('/sign-in?error=Session timed out.'));
    } else if (remainingTime <= 120000) {
      // if session is expiring in 2mins, refresh the session
      try {
        const { expired } = await userService.refreshUserSession();
        setLocalStorageValue('sessionExpiration', expired);
      } catch (error) {
        console.error('Error refreshing user session:', error);
        dispatch(userActions.signOut('/sign-in?error=Session timed out.'));
      }
    }
  }, [dispatch, userService]);

  useEffect(() => {
    resetTimer();
    void checkSessionExpiration();

    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutId.current);
    };
  }, [resetTimer, checkSessionExpiration, handleUserActivity]);

  useEffect(() => {
    if (countdown === 0) {
      setShowOverlay(false);
      dispatch(userActions.signOut('/sign-in?error=Session timed out.'));
      return;
    }
  }, [countdown, onSameDomain, showOverlay, dispatch]);

  useEffect(() => {
    if (showOverlay) {
      const countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => (prevCountdown > 1 ? prevCountdown - 1 : 0));
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [showOverlay]);

  // when user comes back from other tab
  useEffect(() => {
    if (isTabHidden) return;
    void checkSessionExpiration();
    const userLatestActivity = getLocalStorageValue('userLatestActivity');
    const now = moment().valueOf();
    const diff = now - userLatestActivity;
    if (diff > sessionInActiveLimit) {
      setShowOverlay(true);
    }
  }, [sessionInActiveLimit, isTabHidden, checkSessionExpiration]);

  const onLogoutHandler = useCallback(() => {
    setShowOverlay(false);
    dispatch(userActions.logout());
  }, [dispatch]);

  const onCancel = useCallback(() => {
    setShowOverlay(false);
    setCountdown(120);
    resetTimer();
  }, [resetTimer]);

  return (
    <ModalOverlay
      show={showOverlay}
      title={'Session Timeout'}
      isLoading={false}
      onSubmit={onLogoutHandler}
      onCancel={onCancel}
      submitButtonLabel={'Log out'}
      cancelButtonLabel={`I'm here`}
      hideCancelButton={countdown === 0 ? true : false}
      customClassName={`${'alert-session-wrapper'}`}
      centered={false}
    >
      <div className='session-timeout-container'>
        <div className='session-title'>{overlayDetails.current.title} </div>
        <div className='session-description'>
          <div className='session-icon'>
            <TimerIcon />{' '}
          </div>
          <div className='description-wrapper'>
            {overlayDetails.current.description}{' '}
            <span className='timer'>
              {Math.floor(countdown / 60)}:
              {countdown % 60 <= 9 ? '0' + (countdown % 60) : countdown % 60}
            </span>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default UserInActiveOverlay;
