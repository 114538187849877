import React from 'react';
import { DispositionStatus } from '../../Common/DispositionStatus';
import { ThreatTypesReportCount } from './ThreatTypesReportCount';

interface DisputeStatusProps {
  disposition: string;
  reportCount: number;
}

export const ThreatTypeInsightsHeader: React.FC<DisputeStatusProps> = ({
  disposition,
  reportCount,
}) => {
  return (
    <div className='abuse-mailBox-insights-header-container'>
      {disposition && (
        <div className='abuse-mailBox-insights-header-dipsosition'>
          <DispositionStatus
            status={disposition}
            className='disposition-status'
            displayedStyle={'pill'}
          />
        </div>
      )}
      <ThreatTypesReportCount reportCount={reportCount} />
    </div>
  );
};
