import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import './DropdownMultiSelect.scss';
import { Dropdown } from 'react-bootstrap';
import { Checkbox } from './Checkbox';
import Form from 'react-bootstrap/Form';
import RemovableItem from './RemovableItem';
import { Button } from '@mui/material';

export interface IOptionGroup {
  value: string;
  label?: string;
  options?: IOption[];
}
export interface IOption {
  value: string;
  label?: string;
  isDefault?: boolean;
  id?: number;
  disabled?: boolean;
  disableSelect?: boolean;
  disableSelectAction?: () => void;
}
export enum DropdownTypeValues {
  normal = 'normal',
  location = 'location',
}
interface IDropdownMultiSelectProps {
  oneOption?: IOption; // once the oneOption is selected, the other options will become deselected and disabled.
  options: IOption[] | IOptionGroup[];
  label?: string;
  initialSelections?: IOption[];
  placeholder?: string;
  onSubmit: (selections: IOption[], controlId: string) => void;
  removable?: boolean;
  updateOnChange?: boolean;
  hideFooter?: boolean;
  boxStyle?: boolean;
  controlId?: string;
  renderDom?: any;
  renderDomKeys?: Record<string, string>[];
  limit?: number;
  defaultText?: string;
  maxValueLimit?: number;
  type?: DropdownTypeValues;
  disabled?: boolean;
  customClassName?: string;
}

const getValueFromSelections = (selections: IOption[], maxValueLimit: number) => {
  if (selections.length === 0) {
    return;
  } else if (selections.length <= maxValueLimit) {
    return _.map(selections, s => s.label).join(', ');
  }
  return (
    _.map(selections.slice(0, maxValueLimit), s => s.label).join(', ') +
    ', +' +
    (selections.length - maxValueLimit)
  );
};

const DropdownMultiSelect: FunctionComponent<IDropdownMultiSelectProps> = ({
  oneOption,
  options = [],
  label = '',
  initialSelections = [],
  placeholder = 'Select',
  onSubmit,
  removable = false,
  updateOnChange = false,
  hideFooter = false,
  boxStyle = false,
  controlId = '',
  renderDom = null,
  renderDomKeys = [],
  limit = Infinity,
  defaultText = '',
  maxValueLimit = 7,
  type = DropdownTypeValues.normal,
  disabled = false,
  customClassName = '',
}) => {
  const [selections, setSelections] = useState<IOption[]>(initialSelections);
  const [timestamp, setTimestamp] = useState<number>(moment().valueOf());
  const [value, setValue] = useState<string>(
    getValueFromSelections(initialSelections, maxValueLimit) || placeholder,
  );

  const theOneItemOnCheck = (checked: boolean, option: IOption) => {
    if (!option.disableSelect) {
      let newSelections: IOption[] = [];
      if (checked) {
        newSelections = [option];
      }
      setSelections(newSelections);
      setTimestamp(moment().valueOf());
    } else {
      if (option.disableSelectAction) {
        option.disableSelectAction();
      }
    }
  };
  const clearSelections = () => {
    setSelections([]);
  };
  const itemOnCheck = (checked: boolean, option: IOption) => {
    if (!option.disableSelect) {
      let newSelections;
      if (checked) {
        newSelections = [...selections, option];
      } else {
        newSelections = _.filter(
          selections,
          selection => !_.isEqual(selection.value, option.value),
        );
      }
      setSelections(newSelections);
    } else {
      if (option.disableSelectAction) {
        option.disableSelectAction();
      }
    }
  };

  const selectAll = () => {
    setSelections(options);
    setTimestamp(moment().valueOf());
  };

  const deselectAll = () => {
    setSelections([]);
    setTimestamp(moment().valueOf());
  };

  const onUpdate = () => {
    onSubmit(selections, controlId);
    handleClose();
  };

  useEffect(() => {
    if (initialSelections?.length > 0) {
      setSelections(initialSelections);
    }
  }, [initialSelections]);

  useEffect(() => {
    setValue(getValueFromSelections(selections, maxValueLimit) || placeholder);
    updateOnChange && onSubmit(selections, controlId);
  }, [selections, placeholder, updateOnChange]);

  let compClassName = `dropdown-multi-select-component ${customClassName}`;
  if (boxStyle) {
    compClassName += ' box-style';
  }

  const generateItemRenderDom = useCallback(
    (selection: any) => {
      const args: any = {};
      for (const pair of renderDomKeys) {
        const key = Object.keys(pair)[0];
        args[key] = selection[pair[key]];
      }
      return renderDom ? React.createElement(renderDom, args) : null;
    },
    [renderDom, renderDomKeys],
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    if (dropdownRef.current) {
      (dropdownRef.current as any).click(); // Simulate a click to close the dropdown
    }
  };

  return (
    <div className={compClassName}>
      {!_.isEmpty(label) && <Form.Label>{label}</Form.Label>}
      <Dropdown ref={dropdownRef}>
        <Dropdown.Toggle
          disabled={disabled}
          className='dropdown-toggle w-100 long-text-ellipsis-1'
          id={'multi-select-toggle'}
        >
          {removable && selections.length ? (
            <div className={'d-flex align-items-center'}>
              {_.map(selections, selection => (
                <RemovableItem
                  key={selection.value}
                  value={selection.label ?? selection.value}
                  renderDom={generateItemRenderDom(selection)}
                  onRemove={() => {
                    itemOnCheck(false, selection);
                  }}
                />
              ))}
            </div>
          ) : (
            value
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className={'w-100 pb-0'}>
          <div className='d-flex flex-row justify-content-between'>
            {limit === Infinity ? null : (
              <div
                className={
                  type === DropdownTypeValues.location ? 'limit-text pl-4' : 'options-header px-4'
                }
              >
                {type === DropdownTypeValues.location
                  ? `(${selections.length}/${limit}) filter selected`
                  : `Limit: ${selections.length}/${limit}`}
              </div>
            )}
            {type === DropdownTypeValues.location && (
              <div className='reset-button pl-2 pr-4' onClick={clearSelections}>
                Clear All
              </div>
            )}
          </div>
          <div className={'options-container'}>
            {oneOption && (
              <DropdownItem
                option={oneOption}
                selections={selections}
                itemOnCheck={theOneItemOnCheck}
                limit={limit}
              />
            )}
            {_.has(options, [0, 'options'])
              ? _.map(options, (option: IOptionGroup, index: number) => (
                  <div key={option.value}>
                    <div className='option-group-label px-4 pt-2'>{option.label}</div>
                    {_.map(option.options, (option: IOption, index) => (
                      <DropdownItem
                        key={index + timestamp}
                        option={option}
                        selections={selections}
                        itemOnCheck={itemOnCheck}
                        disabled={oneOption && _.some(selections, oneOption)}
                        limit={limit}
                        renderDom={generateItemRenderDom(option)}
                        defaultText={defaultText}
                      />
                    ))}
                  </div>
                ))
              : _.map(options, (option: IOption, index: number) => (
                  <DropdownItem
                    key={index + timestamp}
                    option={option}
                    selections={selections}
                    itemOnCheck={itemOnCheck}
                    disabled={(oneOption && _.some(selections, oneOption)) || option.disabled}
                    limit={limit}
                    renderDom={generateItemRenderDom(option)}
                    defaultText={defaultText}
                  />
                ))}
          </div>
          {!hideFooter && (
            <div
              className={
                'dropdown-footer-bottoms d-flex align-items-center justify-content-between'
              }
            >
              <div className={'select-buttons d-flex align-items-center'}>
                <Button variant='text' className={'mr-3 cursor-pointer'} onClick={selectAll}>
                  Select all
                </Button>
                <Button variant='text' className={'cursor-pointer'} onClick={deselectAll}>
                  Deselect all
                </Button>
              </div>

              <Button variant='contained' onClick={onUpdate}>
                Update
              </Button>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

interface IDropdownItem {
  option: IOption;
  selections: IOption[];
  itemOnCheck: (checked: boolean, option: IOption) => void;
  disabled?: boolean;
  defaultText?: string;
  limit?: number;
  renderDom?: any;
}

const DropdownItem: FunctionComponent<IDropdownItem> = ({
  option,
  selections,
  itemOnCheck,
  disabled,
  defaultText = '',
  limit = Infinity,
  renderDom = null,
}) => {
  const isChecked = _.map(selections, s => s.value).indexOf(option.value) !== -1;
  return (
    <div className={'dropdown-item d-flex align-items-center'} key={option.value}>
      <Checkbox
        isStateless
        defaultChecked={isChecked}
        onChange={checked => {
          itemOnCheck(checked, option);
        }}
        disabled={disabled || (!isChecked && limit <= selections.length)}
      />
      <div
        className={`dropdown-item-label ml-2 ${
          disabled || (!isChecked && limit <= selections.length) ? 'disabled' : ''
        }`}
      >
        {renderDom ? renderDom : option.label || option.value}
        {option.isDefault && defaultText !== '' ? (
          <span className='pl-1 dropdown-item-default'>({defaultText})</span>
        ) : null}
      </div>
    </div>
  );
};

export { DropdownMultiSelect };
