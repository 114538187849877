import React from 'react';
import './PlanChip.scss';
import { PRICING_PLANS } from '../types';
import _ from 'lodash';

export type PlanChipProps = {
  planName: PRICING_PLANS;
  isTransparent?: boolean;
  planChipText?: string;
  customClass?: string;
};

const PlanChip = ({
  planName,
  isTransparent = false,
  planChipText,
  customClass = '',
}: PlanChipProps) => {
  return (
    <div
      className={`plan-chip ${isTransparent ? 'transparent' : planName} ${customClass}`}
      data-testid='plan-chip'
    >
      {_.isEmpty(planChipText) ? planName : planChipText}
    </div>
  );
};

export default PlanChip;
