import React, { useContext } from 'react';
import _ from 'lodash';
import './PageTitle.scss';
import BackIcon from '../../assets/icons/Back.svg';
import BackWhiteIcon from '../../assets/icons/BackWhite.svg';
import { LoadingWrapper } from './LoadingWrapper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ActiveRoutesContext from '../../context/ActiveRoutesContext';
import { useAppDispatch } from '../../helpers/hooks';
import { setShouldRestorePageNumber } from '../../reducers/table.reducer';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

interface IComponentProps {
  title: string;
  className?: string;
  tools?: any;
  toolsLeft?: any;
  backFn?: any;
  isLoading?: boolean;
}

export const PageTitleInsights = ({
  title,
  backFn = _.noop,
  className = '',
  tools = null,
  toolsLeft = null,
  isLoading = false,
}: IComponentProps): any => {
  const [isDarkMode] = useIsDarkMode();
  const { getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  let pageTitleLeftClassName = 'page-title-left';
  let icon = null;

  const dispatch = useAppDispatch();

  if (backFn !== _.noop && window.history.length >= 1) {
    pageTitleLeftClassName += ' pointer';
    icon = (
      <img
        className={'cursor-pointer'}
        src={isDarkMode ? BackWhiteIcon : BackIcon}
        alt={'back'}
        onClick={() => {
          backFn();
          getParentRouteViaFindingStatus('');
          dispatch(setShouldRestorePageNumber(true));
        }}
      />
    );
  }

  return (
    <div className={'page-title-component page-title-insights-component ' + className}>
      <div className={pageTitleLeftClassName}>
        <div className={'title-info d-flex align-items-center'}>
          {icon}
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip-title'>{title}</Tooltip>}
          >
            <div
              data-testid='page-title-wrapper'
              className={'page-title long-text-ellipsis-1 pointer'}
              onClick={backFn}
            >
              <LoadingWrapper isLoading={isLoading} className='page-title-spinner'>
                {title}
              </LoadingWrapper>
            </div>
          </OverlayTrigger>
        </div>
        {toolsLeft}
      </div>
      {!_.isEmpty(tools) && !isLoading && <div className={'page-title-right'}>{tools}</div>}
    </div>
  );
};
