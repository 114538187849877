import React from 'react';
import './ThreatTypeInsights.scss';

interface ThreatTypesReportCountProps {
  reportCount: number;
}

export const ThreatTypesReportCount: React.FC<ThreatTypesReportCountProps> = ({ reportCount }) => {
  return (
    <div className='abuse-mailBox-report-count-container'>
      <span className='abuse-mailBox-report-count'>Report Count: {reportCount}</span>
    </div>
  );
};
