import fetchApi from '../../services/api.service';
import { handleResponse } from '../../services/serviceWorker';
import { AdvancedFilterParams } from './AdvancedFiltersUtils';

export interface IGlobalSearchDto {
  searchTerm: string;
  searchType: string;
  searchParams?: { moduleType: string; fieldName: string }[];
  timeZone?: string;
}
export const getAllGlobalSearchAttributes = async () => {
  const rawResponse = await fetchApi('/platform-api/global-search/v1/attributes').then(
    handleResponse,
  );
  return rawResponse;
};

export const fetchGlobalFindings = async (globalSearchDto: IGlobalSearchDto) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(globalSearchDto),
  };

  const rawResponse = await fetchApi(
    `/platform-api/global-search/v1/findings`,
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const fetchGlobalSearchRelatedUrls = async (globalSearchDto: IGlobalSearchDto) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(globalSearchDto),
  };

  const rawResponse = await fetchApi(
    `/platform-api/global-search/v1/findings/related-urls`,
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const fetchAdvancedFilterFindingsAPI = async (advancedSearchDto: AdvancedFilterParams) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(advancedSearchDto),
  };

  const rawResponse = await fetchApi(
    `/platform-api/global-search/v1/findings/advanced-search`,
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};

export const fetchAdvancedFilterRelatedUrls = async (advancedSearchDto: AdvancedFilterParams) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(advancedSearchDto),
  };

  const rawResponse = await fetchApi(
    `/platform-api/global-search/v1/findings/advanced-search/related-urls`,
    requestOptions,
  ).then(handleResponse);
  return rawResponse;
};
