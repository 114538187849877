import React, { useMemo } from 'react';
import { UrlAnalysisParams } from '../constants';
import { Stack } from '@mui/material';
import LableValue from '../Insights/LableValue';
import _ from 'lodash';
import DispositionInsightsBlock, { InsightsAnalysisData } from './DispositionInsightsBlock';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
import { sortDispositionByPriority } from '../helpers';

type AggByDispotion = {
  [key: string]: Array<InsightsAnalysisData>;
};

interface IAllEmailUrlAnalysisSection {
  urlAnalysisData: UrlAnalysisParams;
}

function AllEmailUrlAnalysisSection({ urlAnalysisData }: IAllEmailUrlAnalysisSection) {
  const aggByDispotion: AggByDispotion = useMemo(() => {
    const data: AggByDispotion = {};
    if (_.has(urlAnalysisData, 'urls')) {
      (urlAnalysisData as UrlAnalysisParams).urls.forEach(url => {
        const { initialDisposition, status, source } = url;
        const insights: InsightsAnalysisData = {
          label: '',
          value: url.name || '',
          timestamp: url.timestamp,
          sha256: url.sha256 || '',
          status: status || '',
          source: source || '--',
        };
        if (data[initialDisposition as string]) {
          data[initialDisposition as string].push(insights);
        } else {
          data[initialDisposition as string] = [insights];
        }
      });
    }
    return data;
  }, [urlAnalysisData]);

  const renderNotAvailableText = () => {
    return <span className='not-available-text'>Not available</span>;
  };

  return (
    <div className='all-email-submissions-section-details-wrap url-details'>
      <Stack direction={'row'} spacing={1}>
        <div className='stack-item url-details-header'>
          <span
            className='section-header'
            data-testid={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL}
            id={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL}
          >
            URL
          </span>
          <LableValue
            lable={`Number of URL`}
            value={urlAnalysisData.urlCounts}
            className='label-text-color'
          />
        </div>
        <div className='stack-item url-details-content'>
          {!urlAnalysisData.urlCounts && renderNotAvailableText()}
          {sortDispositionByPriority(Object.keys(aggByDispotion)).map(key => (
            <DispositionInsightsBlock
              type={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL}
              initialDispotion={key}
              insightsAnalysisData={aggByDispotion[key]}
              key={key}
            />
          ))}
        </div>
      </Stack>
    </div>
  );
}

export default AllEmailUrlAnalysisSection;
