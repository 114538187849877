import React, { useCallback, useMemo } from 'react';
import { appConstants, featureIsAvailable } from '../../../constants';
import { useAppSelector } from '../../../helpers/hooks';

export default function useOnRedirectToModulePage() {
  const user = useAppSelector(state => state.dashboardReducer.user);

  const mapFeatureCodeToModuleType = useMemo(
    () => ({
      [appConstants.CONTENT_TYPE.WEB]: appConstants.FEATURE_CODE.WEB,
      [appConstants.CONTENT_TYPE.DARK_WEB]: appConstants.FEATURE_CODE.DARK_WEB_V2,
      [appConstants.CONTENT_TYPE.SOCIAL]: appConstants.FEATURE_CODE.SOCIAL_MEDIA,
      [appConstants.CONTENT_TYPE.APP_STORE]: appConstants.FEATURE_CODE.APP_STORE,
    }),
    [],
  );

  const shouldShowTeaserPage = useCallback(
    moduleType => {
      if (!featureIsAvailable(user, [mapFeatureCodeToModuleType[moduleType]])) {
        return true;
      }
      return false;
    },
    [mapFeatureCodeToModuleType, user],
  );

  return { shouldShowTeaserPage };
}
