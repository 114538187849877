import React, { useEffect, useState } from 'react';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import NotificationServices from '../../services/notifications.service';
import { LoadingWrapper } from './LoadingWrapper';
import { history } from '../../helpers';
import { setLocalStorageValue } from '../../constants';
import { appActions } from '../../actions';
import { TYPOSQUATTING_DASHBOARD_PATHNAME } from '../DomainMonitoring/constant';
import { trackPendoEvent } from '../../services/pendo-requests';
import moment from 'moment';

const notificationServics = new NotificationServices();
const DigestInsights = () => {
  const [isLoading, setIsLoading] = useState(true);

  const urlSha256: string = window.location.pathname.split('/').pop() || '';
  const insightLink = window.location.pathname
    .replace('/digest', '/insights')
    .split('/')
    .slice(0, 3)
    .join('/');

  const isCommunity = appActions.runByClientApp({
    onBolster: () => false,
    onCheckPhish: () => true,
  });

  useEffect(() => {
    trackPendoEvent('Redirect To Insights', {
      pageUrl: window.location.pathname,
      timestamp: moment().valueOf(),
    });
    setLocalStorageValue('isNotFromTableCol', true);
    setIsLoading(true);
    notificationServics
      .getLatestUrlInfo(urlSha256)
      .then((result: { latestTimestamp: number }) => {
        setIsLoading(false);
        if (isCommunity()) {
          setLocalStorageValue(
            'currentActiveUrl',
            `${insightLink}/${result.latestTimestamp}/${urlSha256}`,
          );
        }
        history.push(`${insightLink}/${result.latestTimestamp}/${urlSha256}`);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        history.push(isCommunity() ? TYPOSQUATTING_DASHBOARD_PATHNAME : '/web/dashboard');
      });
  }, []);

  return (
    <AuthenticationWrapper>
      <LoadingWrapper isLoading={isLoading}>Redirecting ...</LoadingWrapper>
    </AuthenticationWrapper>
  );
};

export default DigestInsights;
