import { useState } from 'react';
import _ from 'lodash';

type UseHandleBlurProps = {
  searchValue: string;
  formCtrlRef: React.RefObject<HTMLElement>;
  searchIconRef: React.RefObject<HTMLElement>;
  advancedSearchRef?: React.RefObject<HTMLElement>;
  popoverRef?: React.RefObject<HTMLElement>;
};

export const useOnHandleBlur = ({
  searchValue,
  formCtrlRef,
  searchIconRef,
  advancedSearchRef,
  popoverRef,
}: UseHandleBlurProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = (event: any) => {
    if (
      !_.isEmpty(searchValue) ||
      formCtrlRef?.current?.contains(event.relatedTarget) ||
      searchIconRef?.current?.contains(event.relatedTarget) ||
      advancedSearchRef?.current?.contains(event.relatedTarget) ||
      popoverRef?.current?.contains(event.relatedTarget)
    ) {
      return;
    }
    setIsFocused(false);
  };

  return {
    isFocused,
    setIsFocused,
    handleBlur,
  };
};
