import React from 'react';
import { Form } from 'react-bootstrap';
import { Dropdown } from '../../Common/Dropdown';
import ModalOverlay from '../../Common/ModalOverlay';
import { TextInput } from '../../Common/TextInput';
import { UploadFiles } from '../../Common/UploadFiles';
import { EPropertyTypeLabel, InputType, PropertyType } from '../PropertyTypes';
import '../Style/PropertyModalStyle.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useAddPhoneNumberAssets } from './useAddPhoneNumberAssets';

interface PropertyModalProps {
  fields: any[];
  show: boolean;
  onFormSubmit: () => void;
  onCloseModal: () => void;
  isLoading: boolean;
  type: { value: PropertyType };
  updateFormValues: (field: string, value: any) => void;
  formValues: Record<string, any>;
}

const PropertyModal = ({
  fields,
  show,
  onFormSubmit,
  onCloseModal,
  isLoading,
  type,
  updateFormValues,
  formValues,
}: PropertyModalProps) => {
  const isPhoneNumber = type.value === PropertyType.OFFICIAL_PHONE_NUMBER;
  const {
    phoneNumber,
    error,
    isPhoneNumberPossible,
    handlePhoneNumberChange,
    handleCountryChange,
  } = useAddPhoneNumberAssets({
    isPhoneNumber,
    updateFormValues,
  });

  const submitButtonDisabled = React.useMemo(() => {
    if (isPhoneNumber) {
      return !isPhoneNumberPossible;
    }
    return fields.filter((field: any) => field.isRequired && !formValues[field.id]).length > 0;
  }, [isPhoneNumber, fields, isPhoneNumberPossible, formValues]);

  return (
    <ModalOverlay
      show={show}
      title={'Add ' + EPropertyTypeLabel[type.value as keyof typeof EPropertyTypeLabel]}
      isLoading={isLoading}
      onSubmit={() => {
        if (isPhoneNumberPossible) {
          onFormSubmit();
        }
      }}
      onCancel={onCloseModal}
      submitButtonDisabled={submitButtonDisabled}
      size={isPhoneNumber ? 'sm' : 'lg'}
    >
      {!isPhoneNumber ? (
        <Form className='add-brand-property-logo-form row'>
          {fields?.map((field: any) => {
            switch (field.type) {
              case InputType.FILES:
                return (
                  <Form.Group className='col-12' key='files'>
                    <UploadFiles
                      onChangeHandler={(files, controlId) => updateFormValues(controlId, files)}
                      dragAndDrop
                      controlId='files'
                      filesLimit={10}
                      fileType={field.fileType}
                    />
                  </Form.Group>
                );
              case InputType.TEXT:
                return (
                  <Form.Group className='col-12' key={field.id}>
                    <Form.Label>
                      <div className='field-label-wrapper'>
                        {field.isRequired && <div className='asterisk'>* </div>}
                        <div className='field-value'>{field.label}</div>
                      </div>
                    </Form.Label>
                    <TextInput
                      controlId={field.id}
                      onChange={(event, controlId) =>
                        updateFormValues(controlId, event.target.value)
                      }
                    />
                  </Form.Group>
                );
              case InputType.DROPDOWN:
                return (
                  <Form.Group className='col-12' key={field.id}>
                    <Form.Label>
                      <div className='field-label-wrapper'>
                        {field.isRequired && <div className='asterisk'>* </div>}
                        <div className='field-value'>{field.label}</div>
                      </div>
                    </Form.Label>
                    <Dropdown
                      options={field.options}
                      onChange={(selected: any) => {
                        updateFormValues(field.id, selected?.docValue);
                      }}
                    />
                  </Form.Group>
                );
            }
            return null;
          })}
        </Form>
      ) : (
        <>
          <PhoneInput
            placeholder='123 456 7890'
            defaultCountry='US'
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onCountryChange={handleCountryChange}
          />
          <div className='text-danger'>{error}</div>
          <div className='text-muted mt-2'>Select your country code from the dropdown</div>
        </>
      )}
    </ModalOverlay>
  );
};

export default PropertyModal;
