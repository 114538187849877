import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { RowClassParams } from 'ag-grid-community';
import { setPreviousUrlSha } from '../../../../reducers/table.reducer';
import _ from 'lodash';

export default function useOnAgRowHighlight(
  showCustomClass: boolean = false,
  disableRowFeild: string = '',
  rowId: string[] = [],
) {
  const previousUrlSha = useAppSelector(state => state.tableReducer.previousUrlSha);
  const dispatch = useAppDispatch();

  const getRowClass = useCallback(
    (params: RowClassParams) => {
      if (_.has(params.data, disableRowFeild) && !params?.data[disableRowFeild])
        return 'custom-row-background disabled';
      if (
        params.data?.url_sha256 === previousUrlSha ||
        params.data?.sha256 === previousUrlSha ||
        showCustomClass ||
        rowId.includes(params?.node?.field as string)
      ) {
        return 'custom-row-background';
      }
    },
    [previousUrlSha, showCustomClass, disableRowFeild],
  );

  useEffect(() => {
    return () => {
      dispatch(setPreviousUrlSha(''));
    };
  }, [dispatch]);

  return { getRowClass };
}
