import { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import {
  exportAbuseMailBoxAllUrlAnalysisData,
  exportAbuseMailBoxTargetedMaliciousData,
} from './ag-requests';
import {
  abuseMailBoxTargetedMaliciousParams,
  convertToFetchAbuseMailBoxTargetedMaliciousParams,
} from '../../../AbuseMailbox/TargetedMalicious/TargetedMaliciousUtils';
import {
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
  ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID,
} from '../../../AbuseMailbox/constants';
import _ from 'lodash';
import { ALL_URL_ANALYSIS_COLUMNS } from '../../../AbuseMailbox/AllEmailSubmissions/AllUrlAnalysis/AllUrlAnalysisColDefs';

export interface UseExportCSVProps {
  agRef: React.RefObject<AgGridReact>;
  cachedAgRequest: IServerSideGetRowsRequest | undefined;
  tableId:
    | typeof ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID
    | typeof ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID;
  isTileView?: boolean;
  tileViewApiParams?: abuseMailBoxTargetedMaliciousParams;
  total?: number;
}

export const useAgServerSideExportCSV = ({
  agRef,
  cachedAgRequest,
  tableId,
  isTileView = false,
  tileViewApiParams,
  total = 0,
}: UseExportCSVProps) => {
  const [exportError, setExportError] = useState<string | null>(null);
  const [isExporting, setIsExporting] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const onExportCSV = useCallback(async () => {
    if (isTileView && !_.isEmpty(tileViewApiParams) && total) {
      const rowCount = total;
      if (rowCount >= 10000) {
        setExportError(
          'Too many records for export, current limit is 10000. Please filter results and try again.',
        );
        return;
      }
      setRowCount(rowCount);
      setExportError(null);
      setIsExporting(true);
      const apiParams = { ...tileViewApiParams };

      const displayedColumns = ALL_URL_ANALYSIS_COLUMNS.map(col => ({
        header: col.header,
        field: col.fieldForExport || col.accessor,
      }));

      apiParams['pageNumber'] = 0;
      apiParams['pageSize'] = 10000;
      apiParams['columns'] = displayedColumns;
      try {
        tableId === ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID
          ? await exportAbuseMailBoxTargetedMaliciousData(apiParams)
          : await exportAbuseMailBoxAllUrlAnalysisData(apiParams);
      } catch (error) {
        setExportError('Error occurred processing the csv file.');
      } finally {
        setIsExporting(false);
      }
    } else if (agRef.current && agRef.current.api && cachedAgRequest) {
      const gridApi = agRef.current.api;
      const rowCount = gridApi.getDisplayedRowCount();

      if (rowCount >= 10000) {
        setExportError(
          'Too many records for export, current limit is 10000. Please filter results and try again.',
        );
        return;
      }
      setRowCount(rowCount);
      setExportError(null);
      setIsExporting(true);
      const apiParams = convertToFetchAbuseMailBoxTargetedMaliciousParams(
        cachedAgRequest as IServerSideGetRowsRequest,
        tableId,
      );

      const displayedColumns = gridApi.getAllDisplayedColumns().map(col => ({
        header: col.getColDef().headerName,
        field: col.getColDef().field,
      }));

      apiParams['pageNumber'] = 0;
      apiParams['pageSize'] = 10000;
      apiParams['columns'] = displayedColumns;
      try {
        tableId === ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID
          ? await exportAbuseMailBoxTargetedMaliciousData(apiParams)
          : await exportAbuseMailBoxAllUrlAnalysisData(apiParams);
      } catch (error) {
        setExportError('Error occurred processing the csv file.');
      } finally {
        setIsExporting(false);
      }
    }
  }, [cachedAgRequest, agRef, isTileView, tileViewApiParams, total]);

  const closeExportLoader = () => {
    setIsExporting(false);
    setExportError(null);
  };

  return {
    exportError,
    isExporting,
    rowCount,
    onExportCSV,
    closeExportLoader,
  };
};
