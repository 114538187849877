import React, { useState, useCallback } from 'react';
import { IFindingsTableItemProps } from '../../../Types/ugc.types';
import DotDotDot from '../../../../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../../../../assets/icons/DotDotDotWhite.svg';
import SocDeleteDetectionModal from '../../Common/SocDeleteDetectionModal';
import SocApproveDetectionModal from '../../Common/SocApproveDetectionModal';
import SocEditDetectionModal from '../../Common/SocEditDetectionModal';
import SocUpdateDetectionStatusModal from '../../Common/SocUpdateDetectionStatusModal';
import { SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME } from '../../../../SocialMedia/constants';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useReadOnlyUser } from '../../../../../basic-hooks/useUserRoles';
import useIsDarkMode from '../../../../Common/CustomHooks/useIsDarkMode';
interface ISocActionColRenderProps {
  data: IFindingsTableItemProps;
  onActionSuccess: (message?: string, data?: any) => void;
  type: string;
}

const SocActionColRender = ({ data, onActionSuccess, type }: ISocActionColRenderProps) => {
  const [deleteDetectionModalShown, setDeleteDetectionModalShown] = useState(false);
  const [approveDetectionModalShown, setApproveDetectionModalShown] = useState(false);
  const [editDetectionModalShown, setEditDetectionModalShown] = useState(false);
  const [updateDetectionStatusModalShown, setUpdateDetectionStatusModalShown] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDarkMode] = useIsDarkMode();
  const open = Boolean(anchorEl);
  const toggleDeleteFiningModal = () => {
    setDeleteDetectionModalShown(!deleteDetectionModalShown);
  };
  const toggleApproveFiningModal = () => {
    setApproveDetectionModalShown(!approveDetectionModalShown);
  };
  const toggleEditFiningModal = () => {
    setEditDetectionModalShown(!editDetectionModalShown);
  };
  const toggleUpdateFiningStatusModal = () => {
    setUpdateDetectionStatusModalShown(!updateDetectionStatusModalShown);
  };

  const onAction = useCallback((action: string, data: IFindingsTableItemProps) => {
    switch (action) {
      case 'delete':
        toggleDeleteFiningModal();
        break;
      case 'approve':
        toggleApproveFiningModal();
        break;
      case 'edit':
        toggleEditFiningModal();
        break;
      case 'updateStatus':
        toggleUpdateFiningStatusModal();
        break;
      default:
        break;
    }
  }, []);

  const deleteFindingsSuccess = (data?: any) => {
    setDeleteDetectionModalShown(false);
    onActionSuccess(undefined, data);
  };
  const approveFindingsSuccess = (data?: any) => {
    setApproveDetectionModalShown(false);
    onActionSuccess();
  };
  const editFindingsSuccess = (data?: any) => {
    setEditDetectionModalShown(false);
    onActionSuccess(undefined, data);
  };
  const updateFindingStatusSuccess = (data?: any) => {
    setUpdateDetectionStatusModalShown(false);
    onActionSuccess(undefined, data);
  };

  const actionOptions = [
    {
      label: 'Update Status',
      value: 'updateStatus',
    },
  ];
  if (type !== 'app-store') {
    actionOptions.push({
      label: 'Edit',
      value: 'edit',
    });
  }

  if (window.location.pathname.indexOf(SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME) === 0) {
    actionOptions.push({
      label: 'Approve',
      value: 'approve',
    });
  }

  if (data.source === 'Bolster' || data.scanSource === 'Bolster') {
    actionOptions.push({
      label: 'Delete',
      value: 'delete',
    });
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) {
    return null;
  }

  return (
    <>
      <Button
        id='basic-button-soc-actions'
        aria-controls={open ? 'basic-menu-soc-actions' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={isDarkMode ? DotDotDotWhiteIcon : DotDotDot} alt={'dotdotdot'} />{' '}
      </Button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actionOptions.map((actionOption, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onAction(actionOption.value, data);
              }}
            >
              {actionOption.label}
            </MenuItem>
          );
        })}
      </Menu>

      <SocApproveDetectionModal
        data={[data]}
        show={approveDetectionModalShown}
        onCancel={toggleApproveFiningModal}
        onSubmit={approveFindingsSuccess}
        type={type}
      />
      <SocDeleteDetectionModal
        data={data ? [data] : []}
        show={deleteDetectionModalShown}
        onCancel={toggleDeleteFiningModal}
        onSubmit={deleteFindingsSuccess}
        type={type}
      />
      <SocEditDetectionModal
        data={data}
        show={editDetectionModalShown}
        onCancel={toggleEditFiningModal}
        onSubmit={editFindingsSuccess}
        type={type}
      />
      <SocUpdateDetectionStatusModal
        data={[data]}
        show={updateDetectionStatusModalShown}
        onCancel={toggleUpdateFiningStatusModal}
        onSubmit={updateFindingStatusSuccess}
        type={type}
      />
    </>
  );
};

export default SocActionColRender;
