import {
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
  ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID,
  dispositionPriority,
} from './constants';

export const sortOnDisposition = (arr: Array<any>) => {
  return arr.sort(
    (a, b) =>
      dispositionPriority[a.initialDisposition?.toLocaleLowerCase() || 'unknown'] -
      dispositionPriority[b.initialDisposition?.toLocaleLowerCase() || 'unknown'],
  );
};

export const sortDispositionByPriority = (arr: Array<any>) => {
  return arr.sort(
    (a, b) =>
      dispositionPriority[a?.toLocaleLowerCase() || 'unknown'] -
      dispositionPriority[b?.toLocaleLowerCase() || 'unknown'],
  );
};

export const downloadImg = (imgPath: string) => {
  const a = document.createElement('a');
  a.href = imgPath;
  a.download = ''; // The browser will try to use the correct extension
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
