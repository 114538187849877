import React from 'react';
import _ from 'lodash';
import './auth.scss';

import { UserResetPassDto, Alert } from '../../constants';
import { AppState } from '../../helpers';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PASSWORD_LENGTH, PasswordEye, PASSWORD_LIMIT } from '../Common/PasswordEye';
import { CompanyIcon } from '../Common/CompanyIcon';

interface RouteParams {
  token: string;
}

interface IUserResetPassProps {
  alert: Alert;
  resetpass: (password: string, token: string) => any;
}

interface IUserResetPassState {
  user: UserResetPassDto;
  submitted: boolean;
  apiSubmitted: boolean;
  idx: number;
}

type Props = IUserResetPassProps & RouteComponentProps<RouteParams>;

class ResetPass extends React.Component<Props, IUserResetPassState> {
  private passwordDom: any = '';
  private errMsg: any = [
    '',
    'Password should not be empty.',
    'Please enter a password with ' +
      PASSWORD_LENGTH +
      ' or more characters and less than ' +
      PASSWORD_LIMIT +
      ' letters.',
    'You may not click the reset password link for twice. Please re-enter email address.',
  ];
  constructor(props: Props) {
    super(props);

    this.state = {
      user: {
        password: '',
      },
      submitted: false,
      apiSubmitted: false,
      idx: 0,
    };
    window.document.title = 'Reset Password | Bolster Platform';
  }

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.currentTarget.value;
    const { user } = this.state;
    let tempIdx = 0;
    if (!_.isEmpty(password) && password.length > PASSWORD_LIMIT) {
      tempIdx = 2;
    }
    this.setState({
      user: {
        ...user,
        password,
      },
      idx: tempIdx,
    });
  };

  handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    // const token: string = this.props.match.params.token;
    const { password } = this.state.user;
    if (password === '') {
      this.setState({ submitted: true, idx: 1 });
    } else if (password.length < PASSWORD_LENGTH) {
      this.setState({ submitted: true, idx: 2 });
    } else {
      this.setState({ apiSubmitted: true });
      this.props.resetpass(password, this.props.match.params.token).then((res: any) => {
        if (!res['result']) {
          this.setState({
            submitted: true,
            idx: 3,
          });
        }
      });
    }
  };

  render() {
    const { user, submitted, idx, apiSubmitted } = this.state;
    return (
      <div>
        {/* <AlertComponent type={alert.type} message={alert.message} /> */}
        <div className={'auth-title '}>
          <CompanyIcon withName className='checkphish-logo-login' />
        </div>
        <div className={'auth-page-wrapper'}>
          <form name='form' onSubmit={this.handleSubmit}>
            <h3>Reset Password</h3>
            <PasswordEye
              isError={idx > 0}
              label={'New Password'}
              name={'password'}
              onChange={this.onChangePassword}
            />
            <div className={'help-block' + (submitted || idx === 2 ? '' : ' hidden')}>
              {this.errMsg[idx]}
            </div>
            <div className={'helper-bottom'}>
              <button
                disabled={
                  _.isEmpty(user.password) || user.password.length > PASSWORD_LIMIT || apiSubmitted
                }
                type='submit'
                className='btn btn-primary'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return { user };
};

const mapDispatchToProps = {
  resetpass: userActions.resetpass,
};

const connectedResetPass = connect(mapStateToProps, mapDispatchToProps)(ResetPass);
export { connectedResetPass as ResetPass };
