import React, { useState, useEffect } from 'react';
import ExpandIconLight from '../../assets/icons/ExpandIconLight.svg';
import ExpandIconDark from '../../assets/icons/ExpandIconDark.svg';
import CollapseIconLight from '../../assets/icons/CollapseIconLight.svg';
import CollapseIconDark from '../../assets/icons/CollapseIconDark.svg';
import DisabledCollapseLight from '../../assets/icons/DisabledCollapseIconLight.svg';
import DisabledCollapseDark from '../../assets/icons/DisabledCollapseIconDark.svg';
import './ExpandCollapseIcon.scss';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

type Props = {
  isExpanded: boolean;
  onClick?: any;
  isDisabled?: boolean;
};

const ExpandCollapseIcon = ({ isExpanded, onClick = () => {}, isDisabled = false }: Props) => {
  const [isDarkMode] = useIsDarkMode();

  const [icon, setIcon] = useState<any>();

  const setExpandCollapsedIcon = () => {
    if (isDisabled) {
      return isDarkMode ? DisabledCollapseDark : DisabledCollapseLight;
    }
    if (isExpanded) {
      return isDarkMode ? CollapseIconDark : CollapseIconLight;
    }
    return isDarkMode ? ExpandIconDark : ExpandIconLight;
  };

  useEffect(() => {
    const expandCollapseIcon = setExpandCollapsedIcon();
    setIcon(expandCollapseIcon);
  }, [isDarkMode, isExpanded]);

  const onIconClick = () => {
    if (isDisabled) {
      return;
    }
    onClick();
  };

  return (
    <>
      <img src={icon} alt='expand-collapse-icon' onClick={onIconClick} />
    </>
  );
};

export default ExpandCollapseIcon;
