import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { PageTitle } from '../Common/PageTitle';
import './typosquat.scss';
import TypoSquatTable from './TyposquatTable';
import DashboardService from '../../services/dashboard.service';
import { Subscription } from 'rxjs';
import { MONITORING_TYPE, TypoJobStatus } from './constant';
import { Dropdown } from '../Common/Dropdown';
import {
  TypoDispositionScan,
  TyposquattingDomain,
  UserTypoSquatFetchResults,
} from '../../types/typosquat-dashboard.interface';
import CheckPhishContactUsContext, {
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import ActiveRoutesContext from '../../context/ActiveRoutesContext';
import { ETyposquattingStatus } from './constant';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { IReasonDropdownValues } from '../../constants';
import EditIcon from '../../assets/icons/Edit.svg';
import TyposquatProgressPage from './TyposquatProgressPage';
import { Card } from 'react-bootstrap';
import { parseDomain, ParseResultType } from 'parse-domain';
import { TyposquatSearchBar } from './TyposquatSearchBar';
import { StepStatus } from './constant';
import { setLocalStorageValue } from '../../constants';
import { FIRST_LOAD_ACTION_VALUES_TYPES, FIRST_LOAD_ACTION_VALUES } from '../Drawer/AppWrapper';
import { extractDomain } from '../../helpers/url-helpers';
import { hasTld } from '../../helpers/url-helpers';
import { specialChars } from '../../helpers/url-helpers';
import { useReadOnlyUser } from '../../basic-hooks/useUserRoles';
import PopUpBg from '../../assets/paywallbg.svg';
import { SCROLL_TO_COMPARISION } from '../Pricing/constants';
import { PRICING_PAGE_PATH } from '../Pricing/constants';
import { HUBSPOT_FORM_TYPE } from '../Pricing/types';

export interface IErrorMsg {
  status: boolean;
  message: string;
  isSuccessMsg: boolean;
}

export type LimitStatusValue = 'inlimit' | 'offlimit';

type pageStateType = 'first-time' | 'in-progress' | 'typosquat-data';

const dashboardService = new DashboardService();
let subscription: Subscription;

interface ITyposquatPageProps {
  typosquatJobStatus: boolean;
  changeTyposquatJobStatus: (status: boolean) => void;
  firstTimeLoad: boolean;
  changeFirstLoad: (action: FIRST_LOAD_ACTION_VALUES_TYPES, updatedVal: boolean) => any;
}

const TyposquatPage: React.FC<ITyposquatPageProps> = ({
  changeTyposquatJobStatus,
  firstTimeLoad,
  changeFirstLoad,
}: ITyposquatPageProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [typosquatData, setTyposquatData] = useState<any[]>([]);
  // initial state will be -2,
  // at step 6 typosquat pooling stops.
  const [step, setStep] = useState<number>(-2);
  const [totalVarients, settotalVarients] = useState(0);
  const [totalResolvedVariants, setTotalResolvedVariants] = useState(0);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [domainName, setDomainName] = useState('');
  const [lastScannedTs, setLastScannedTs] = useState<string | null>(null);
  const [nextScannedTs, setNextScannedTs] = useState<string | null>(null);
  const [pageState, setPageState] = useState<pageStateType>('first-time');
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [showTyposquatBar, setShowTyposquatBar] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [scanLimit, setScanLimit] = useState<number>(1);
  const [usedScans, setUsedScans] = useState<number>(0);
  const [limitStatus, setLimitStatus] = useState<LimitStatusValue>('inlimit'); //assuming the the user starts with zero count.
  const [triggerExportBtn, setTriggerExportBtn] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [extractedDomain, setExtractedDomain] = useState<string>('');
  const [showScanBarBanner, setShowScanBarBanner] = useState<boolean>(false);
  const [showTypoBlurr, setShowTypoBlurrBar] = useState<boolean>(false);

  const [userTyposquatDataList, setUserTyposquatDataList] = useState<
    Record<string, UserTypoSquatFetchResults> | undefined
  >(undefined);

  window.document.title = 'Typosquat | Checkphish Platform';

  const {
    setContactUsMesage,
    setShowContactUsPage,
    setSelectedUrls,
    setReason,
    setTriggerContactUsModal,
    setSelectedUrlsSha,
    formSubmitted,
    setFormSubmitted,
    selectedDomain,
    setSelectedDomain,
    setDomainList,
    setPaidUser,
    paidUser,
    setTriggerMultiTyposquatDomainContactUsModal,
    setTriggerSubtext,
    setTriggerBackgroundImg,
    setTriggerPopUpButtonData,
    setIsSmallPopup,
    hubspotFormType,
    setHubspotFormType,
  } = useContext(CheckPhishContactUsContext);
  const { currentTyposquatSubscription } = useContext(CheckphishSubscriptionContext);
  const currenDomainRef = useRef<TyposquattingDomain>(selectedDomain);

  const triggerPaywallPopUp = (): void => {
    //set uptext
    setHubspotFormType(HUBSPOT_FORM_TYPE.TAKEDOWN);
    setIsSmallPopup(true);
    setTriggerSubtext(
      <span style={{ fontSize: '14px' }}>
        <span style={{ fontWeight: 'bold' }}>Upgrade to premium</span> for AI auto-takedown and fast
        brand/phishing protection with direct host and platform integrations
      </span>,
    );
    setTriggerBackgroundImg(PopUpBg);
    setTriggerPopUpButtonData([
      {
        text: 'Compare All Plans',
        onClick: () => {
          setTriggerMultiTyposquatDomainContactUsModal(false);
          setLocalStorageValue(SCROLL_TO_COMPARISION, true);
          history.push(PRICING_PAGE_PATH);
        },
        variant: 'outline-secondary',
        className: 'compare-all-plans',
      },
      {
        text: 'Contact Us',
        onClick: () => {
          setReason(IReasonDropdownValues.TAKEDOWN);
          setTriggerContactUsModal(true);
        },
        variant: 'primary',
      },
    ]);
    setTriggerMultiTyposquatDomainContactUsModal(true);
  };

  useEffect(() => {
    currenDomainRef.current = selectedDomain;
  }, [selectedDomain]);

  useEffect(() => {
    if (
      userTyposquatDataList &&
      selectedDomain.value &&
      userTyposquatDataList[selectedDomain.value]
    ) {
      if (userTyposquatDataList[selectedDomain.value].status === TypoJobStatus.DONE) {
        handleUserData(userTyposquatDataList[selectedDomain.value]);
      } else {
        if (subscription && subscription.closed) {
          fetchTyposquatData('Changing Domain whose status is not done');
        } else {
          handleUserData(userTyposquatDataList[selectedDomain.value]);
        }
      }
    } else {
      if (!subscription || subscription.closed) {
        fetchTyposquatData('Start');
      }
    }
  }, [selectedDomain, userTyposquatDataList]);

  const handleUserData = (scanResult: UserTypoSquatFetchResults) => {
    if (scanResult) {
      const { monitoredDomain, status, metadata, typosquattingResult, lastScanTs, nextScanTs } =
        scanResult;
      const { scanPercentage, totalVarients, totalResolvedVariants } = metadata;
      scanPercentage && setProgress(scanPercentage);
      totalVarients && settotalVarients(totalVarients);
      totalResolvedVariants && setTotalResolvedVariants(totalResolvedVariants);
      //set current status and progress;
      updateStepVal(status);
      monitoredDomain && setDomainName(monitoredDomain);
      lastScanTs && setLastScannedTs(lastScanTs);
      nextScanTs && setNextScannedTs(nextScanTs);
      const { result } = typosquattingResult;
      const data = result
        .map((item: TypoDispositionScan, index: number) => ({
          ...item,
          _index: item?.id || index,
          srcUrl: item?.src_url,
          ip: item?.ipv4,
          ip_data: item?.ipv4?.join(' ').replaceAll('0.0.0.0', '--') || '--',
          nameserver: item?.ns ? item?.ns.filter(n => !n?.includes('0.0.0.0')).join(' ') : ['--'],
          mailserver: item?.mx ? item?.mx.filter(n => !n?.includes('0.0.0.0')).join(' ') : ['--'],
          registrar: item?.registrar || '--',
          ssl_cert_provider: item?.ssl_cert_provider || '--',
          live_scan_verdict: item?.disposition || '--',
          takedown_enquiry: item?.takedown_enquiry ? 'Contacted' : '--',
          matrix_algorithm: item?.matrix_algorithm,
          has_mx_records: Boolean(item?.has_mx_records),
          domain_sha256: item?.url_sha,
        }))
        .sort((a, b) => {
          if (a.disposition === null || a.disposition === undefined) {
            return 1;
          }
          if (b.disposition === null || b.disposition === undefined) {
            return -1;
          }
          return 0;
        });
      setTyposquatData(data);
      if (status === 'done' || status === 'monitored_done') {
        setStep(StepStatus.DONE);
        subscription && subscription.unsubscribe();
        if (firstTimeLoad) {
          changeFirstLoad(FIRST_LOAD_ACTION_VALUES.TYPOSQUAT, false);
        }
        if (!history.location.state || !(history.location.state as any)?.showTyposquatBar) {
          setShowTyposquatBar(false);
        }
        setShowProgressBar(false);
      } else {
        setShowProgressBar(true);
        setShowTyposquatBar(true);
      }
    }
  };

  const toggleScanBarBanner = () => {
    setShowScanBarBanner(oldState => !oldState);
  };

  const showBlurrPopup = () => totalResolvedVariants >= 300 && step === 6;

  window.onresize = () => {
    const { innerWidth } = window;
    if (innerWidth < 1268) {
      setContactUsMesage(
        'Access up to 300 Typosquat variants and 100  Live Scan results. Upgrade for unlimited results',
      );
    } else {
      setContactUsMesage(
        `With the Free Plan, you can access up to 300 Typosquat variants and auto Live Scan on 100 of them. Upgrade for unlimited results and multi-domain monitoring.`,
      );
    }
  };

  const triggerCSVExport = (newState: boolean) => {
    setTriggerExportBtn(newState);
  };

  useEffect(() => {
    if (showTyposquatBar && !_.isEmpty(domainName)) {
      setExportModalDisplay(true);
    }
  }, [showTyposquatBar]);

  useEffect(() => {
    if (scanLimit === -1) {
      setLimitStatus('inlimit');
      return;
    }
    usedScans >= scanLimit ? setLimitStatus('offlimit') : setLimitStatus('inlimit');
  }, [usedScans, scanLimit]);

  useEffect(() => {
    if (step === -1 && _.isEmpty(typosquatData)) {
      setLimitStatus('inlimit');
    }
  }, [step, typosquatData]);

  const { getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  const [errMsg, setErrMsg] = useState<IErrorMsg>({
    status: false,
    message: '',
    isSuccessMsg: false,
  });
  const [showLinkToLiveScan, setShowLinkToLiveScan] = useState<{
    status: boolean;
    message: any;
    includeDomain: boolean;
  }>({
    status: false,
    message: '',
    includeDomain: false,
  });
  const setError = (newState: IErrorMsg) => {
    setErrMsg(newState);
  };

  useEffect(() => {
    if (showLinkToLiveScan.status) {
      setShowScanBarBanner(false);
    }
  }, [showLinkToLiveScan.status]);

  const onDomainChange = useCallback(
    (pathName: string) => {
      const path = pathName.replace('hxxp', 'http');
      //reset error message
      setErrMsg({ status: false, message: '', isSuccessMsg: false });
      setShowLinkToLiveScan({ status: false, message: '', includeDomain: false });

      if (
        (path.length === 1 && path === 'w') ||
        (path.length === 2 && path === 'ww') ||
        (path.length === 3 && path === 'www') ||
        (path.length < 6 && /www\./.test(path))
      ) {
        setErrMsg({ status: false, message: '', isSuccessMsg: false });
        setShowLinkToLiveScan({ status: false, message: '', includeDomain: false });
        return;
      }
      if (_.isEmpty(path)) {
        return setError({
          status: false,
          message: '',
          isSuccessMsg: false,
        });
      }

      if (_.toString(path).split('').indexOf(' ') !== -1) {
        return setError({
          status: true,
          message: 'Please enter a valid domain',
          isSuccessMsg: false,
        });
      }
      //now more forward if its not empty
      //check if the orignalDomain is a full path or something
      try {
        const url = new URL(path);
        //1. case one user has entered a url;
        if (!_.isEmpty(url.host))
          return setShowLinkToLiveScan({
            status: true,
            message: 'The domain is invalid. Did you mean to do a Live URL scan?',
            includeDomain: false,
          });
        else throw new Error('invalid url');
      } catch (err) {
        //the domain is not a url.
      }
      try {
        const extractedDomain = extractDomain(path);
        if (_.isEmpty(extractedDomain)) {
          setExtractedDomain('');
          return setError({
            status: true,
            message: 'Please enter a valid domain',
            isSuccessMsg: false,
          });
        }
        let validDomain = true;
        specialChars.forEach((character: string) => {
          if (extractedDomain.includes(character)) {
            validDomain = false;
          }
        });
        if (!validDomain) {
          return setError({
            status: true,
            message: 'Please enter a valid domain',
            isSuccessMsg: false,
          });
        }
        setExtractedDomain(extractedDomain);
        const parseResult = parseDomain(extractedDomain);
        const paths = path.split(extractedDomain)[1].split('/');
        const pathname = paths.pop();
        if (!_.isEmpty(pathname) || paths.length > 1) {
          setShowLinkToLiveScan({
            status: true,
            message: `Invalid Domain Format. Please enter a valid domain to monitor ${extractedDomain} If you want to do a URL Check, `,
            includeDomain: true,
          });
        } else if (hasTld(extractedDomain.replace('www.', ''))) {
          const domainPartsFormDot = path.split('.');
          if (domainPartsFormDot.length > 3) {
            return setError({
              status: true,
              message: 'Please enter a valid domain',
              isSuccessMsg: false,
            });
          }
          return setError({
            status: true,
            message: 'The domain is validated, you can generate typosquat now.',
            isSuccessMsg: true,
          });
        } else {
          setError({
            status: false,
            message: '',
            isSuccessMsg: false,
          });
        }
        if (parseResult.type === ParseResultType.Listed) {
          const { domain } = parseResult;
          // check if domain part is too long
          if (domain && domain.length > 15) {
            setError({
              status: true,
              message: 'The max length of domain we can help monitor is 15 characters',
              isSuccessMsg: false,
            });
          } else {
            setError({
              status: false,
              message: '',
              isSuccessMsg: false,
            });
          }
        }
      } catch (err) {
        // invalid domain name
        setError({
          status: true,
          message: 'Please enter a valid domain',
          isSuccessMsg: false,
        });
      }
    },
    [firstLoad],
  );
  const history = useHistory();

  const setExportModalDisplay = (newState: boolean) => {
    setShowExportModal(newState);
  };

  const setUrlsForContactUs = () => {
    const domains = selectedItems.map((item: any) => item.domain);
    const urlSha = selectedItems.map((item: any) => item.url_sha);
    domains.length && setSelectedUrls(domains);
    urlSha.length && setSelectedUrlsSha(urlSha);
  };

  useEffect(() => {
    setUrlsForContactUs();
  }, [selectedItems]);

  const onItemCheck = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  const generateCurrentStep = (step: any) => {
    if (step < 3) return 1;
    if (step === 3) return 2;
    return 3;
  };

  useEffect(() => {
    if (step === -1) return setPageState('first-time');
    if (typosquatData.length) return setPageState('typosquat-data');
    setPageState('in-progress');
  }, [step, typosquatData]);

  const changeSearchText = (searchText: string) => {
    setSearchText(searchText);
  };

  useEffect(() => {
    domainName.length && setSearchText(domainName);
  }, [domainName]);

  useEffect(() => {
    if (_.isEmpty(typosquatData)) return;
    setPageState('typosquat-data');
    if (step === 6) {
      changeTyposquatJobStatus(true);
    }
  }, [typosquatData, step]);

  const onTyposquatSubmit = () => {
    setStep(StepStatus.CHANGING_DOMAIN);
    setProgress(0);
    setShowProgressBar(true);
    //resetting domainName and
    setDomainName('');
    setTyposquatData([]);
    setLastScannedTs(null);
    setNextScannedTs(null);
    changeTyposquatJobStatus(false);

    //generate report;
    dashboardService
      .generateReport(searchText)
      .then(() => {
        changeFirstLoad(FIRST_LOAD_ACTION_VALUES.RESET, true);
        setFirstLoad(true);
        currenDomainRef.current = { value: searchText, status: TypoJobStatus.JOB_CREATED };
      })
      .catch((err: any) => {
        setDomainName('');
        setSearchText('');
        setShowProgressBar(false);
        setShowTyposquatBar(true);
        setError({
          status: true,
          message: _.isArray(err) ? err[0] : err,
          isSuccessMsg: false,
        });
      })
      .finally(() => {
        fetchTyposquatData('On Submit of new Typosquat');
      });
  };

  const updateStepVal = (status: any) => {
    if (_.isEmpty(status) && typeof status !== 'string') return;
    switch (status) {
      case TypoJobStatus.NO_DOMAIN:
        return setStep(StepStatus.NO_DOMAIN);
      case TypoJobStatus.CHANGING_DOMAIN:
        return setStep(StepStatus.CHANGING_DOMAIN);
      case TypoJobStatus.START_SEARCH:
        return setStep(StepStatus.START_SEARCH);
      case TypoJobStatus.DOMAINS_GENERATED:
        return setStep(StepStatus.DOMAINS_GENERATED);
      case TypoJobStatus.DNS_SEARCH:
        return setStep(StepStatus.DNS_SEARCH);
      case TypoJobStatus.PUBLISHED:
        return setStep(StepStatus.PUBLISHED);
      case TypoJobStatus.DOMAIN_SCAN:
        return setStep(StepStatus.DOMAIN_SCAN);
      case TypoJobStatus.DOMAIN_PURCHASE:
        return setStep(StepStatus.DOMAIN_PURCHASE);
      case TypoJobStatus.MONITORED_DONE:
        return setStep(StepStatus.MONITORED_DONE);
      case TypoJobStatus.DONE:
        return setStep(StepStatus.DONE);
    }
  };

  const disableGenerateButton = () => limitStatus === 'offlimit' || showLinkToLiveScan.status;

  //polling for typosquat.
  const fetchTyposquatData = (callingReason: string) => {
    // Only for debugging purpose
    console.log(`Subscription Calling Reason: ${callingReason}.`);
    //
    setLastScannedTs(null);
    setNextScannedTs(null);
    setTyposquatData([]);
    setStep(StepStatus.START_SEARCH);
    if (subscription) {
      subscription.unsubscribe();
    }
    const options = {
      searchType: MONITORING_TYPE.typosquat,
    };
    subscription = dashboardService.getDomainMonitoringDataPolling(options).subscribe({
      next: (resp: any) => {
        const { metadata, scanResults } = resp;
        const { totalDomainMonitoringScanLimit, usedDomainMonitoringScanLimit, paidUser } =
          metadata;
        setPaidUser(paidUser);
        setScanLimit(totalDomainMonitoringScanLimit); //total scan limit
        setUsedScans(usedDomainMonitoringScanLimit); //number of scan used
        const availableDomains = Object.values(scanResults)
          .sort((a: any, b: any) => b.triggerTs - a.triggerTs)
          .map((m: any) => m.monitoredDomain);
        const isNotDoneDomain = availableDomains.filter(
          availableDomain => scanResults[availableDomain].status !== TypoJobStatus.DONE,
        )[0];
        let tempSelectedDomain = currenDomainRef.current;
        const index = tempSelectedDomain?.value
          ? availableDomains.indexOf(tempSelectedDomain.value)
          : -1;

        if (index === -1) {
          if (isNotDoneDomain) {
            tempSelectedDomain = {
              value: isNotDoneDomain,
              status: scanResults[isNotDoneDomain].status,
            };
          } else {
            tempSelectedDomain = {
              value: availableDomains[0],
              status: scanResults[availableDomains[0]].status,
            };
          }
        } else {
          if (
            tempSelectedDomain.status !== scanResults[tempSelectedDomain.value as string].status
          ) {
            tempSelectedDomain = {
              ...tempSelectedDomain,
              status: scanResults[tempSelectedDomain.value as string].status,
            };
          }
        }
        setSelectedDomain(tempSelectedDomain);
        setDomainList(
          availableDomains.map(d => {
            return {
              value: d,
              status: scanResults[d].status,
            };
          }),
        );
        setUserTyposquatDataList(scanResults);
        handleUserData(scanResults[tempSelectedDomain.value as string]);
        getParentRouteViaFindingStatus(ETyposquattingStatus.TYPOSQUAT_TABLE);
      },
      error: (error: any) => {
        //no typo is set yet
        console.log('error', error);
        setDomainList([]);
        setSelectedDomain({});
        setStep(StepStatus.NO_DOMAIN);
        setShowTyposquatBar(true);
        setShowProgressBar(false);
        setErrMsg({ status: false, message: '', isSuccessMsg: false });
        subscription && subscription.unsubscribe();
      },
    });
  };

  useEffect(() => {
    if ((history.location.state as any)?.showTyposquatBar) {
      setShowTyposquatBar((history.location.state as any).showTyposquatBar);
    }
    if ((history.location.state as any)?.error) {
      setError((history.location.state as any).error);
    }
    if ((history.location.state as any)?.showLinkToLiveScan) {
      setShowLinkToLiveScan((history.location.state as any).showLinkToLiveScan);
    }
    if ((history.location.state as any)?.searchText) {
      setSearchText((history.location.state as any).searchText);
    }
    if ((history.location.state as any)?.firstLoad) {
      setFirstLoad((history.location.state as any).firstLoad);
    }
    if ((history.location.state as any)?.scanLimit) {
      setScanLimit((history.location.state as any).scanLimit);
    }
    if ((history.location.state as any)?.usedScans) {
      setUsedScans((history.location.state as any).usedScans);
    }
    const unlisten = history.listen(() => {
      setContactUsMesage('');
      setShowContactUsPage(false);
      setLocalStorageValue(['tableSetting', 'typosquat'], {});
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      fetchTyposquatData('On Form Submission');
      setSelectedItems([]);
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  useEffect(() => {
    const showTypoBlurrBar = showBlurrPopup();
    setShowTypoBlurrBar(!currentTyposquatSubscription && showTypoBlurrBar);

    if (showTypoBlurrBar) {
      setShowContactUsPage(false);
    }
  }, [typosquatData, step]);

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <div className={'acquisitions-page no-padding'}>
      <PageTitle
        title={`Typosquat ${domainName && `${domainName}`}`}
        className='analytics-dashboard-page-title'
        tools={
          domainName &&
          !showTyposquatBar && (
            <button
              className={`edit-domain-btn`}
              onClick={() => {
                setShowTyposquatBar(true);
                setError({ status: false, message: '', isSuccessMsg: false });
                setShowLinkToLiveScan({ status: false, message: '', includeDomain: false });
                setSearchText('');
                setFirstLoad(true);
              }}
            >
              {paidUser ? (
                <span className='mr-3' style={{ padding: '0 5px' }}>
                  Add Domain(s)
                </span>
              ) : (
                <div className='edit-domain-wrap'>
                  <img src={EditIcon} alt='edit button' />
                  <span className='mr-3' style={{ padding: '0 5px' }}>
                    Monitor New Domain
                  </span>
                </div>
              )}
            </button>
          )
        }
      />
      {/* scan bar */}
      {showTyposquatBar && (
        <div className='typosquat-scan-wrap'>
          <TyposquatSearchBar
            isLoading={showProgressBar}
            changeSearchText={changeSearchText}
            searchText={searchText}
            onTyposquatSubmit={onTyposquatSubmit}
            progress={progress}
            totalVarients={totalVarients}
            totalResolvedVariants={totalResolvedVariants}
            activeStep={generateCurrentStep(step)}
            showCancelBtn={typosquatData.length > 0}
            onCancel={() => setShowTyposquatBar(false)}
            onDomainChange={onDomainChange}
            errMsg={errMsg}
            typosquatData={typosquatData}
            disabledGenerateButton={disableGenerateButton()}
            limitStatus={limitStatus}
            showLiveScanLink={showLinkToLiveScan}
            showExportPopup={showExportModal}
            domainName={domainName}
            triggerCSVExport={triggerCSVExport}
            setExportModalDisplay={setExportModalDisplay}
            scanLimit={scanLimit}
            extractedDomain={extractedDomain}
            showScanBarBanner={showScanBarBanner}
            toggleScanBarBanner={toggleScanBarBanner}
          />
        </div>
      )}
      {/* typosquat table */}
      <div className='typosquat-table-wrap'>
        {pageState !== 'typosquat-data' ? (
          <div
            className={`table-page ${pageState === 'first-time' && searchText.length && 'hide'}`}
          >
            <Card className={`table-container ${pageState === 'in-progress' && 'typosquat-table'}`}>
              <TyposquatProgressPage
                isProgressState={pageState === 'in-progress'}
                searchText={searchText}
              />
            </Card>
          </div>
        ) : (
          <>
            <div className='scan-ts'>
              {lastScannedTs &&
                nextScannedTs &&
                `Last Scanned ${moment(lastScannedTs).format('L')}, Next Scan ${moment(
                  nextScannedTs,
                ).format('L')}`}
            </div>
            <TypoSquatTable
              tableData={typosquatData}
              customToolBar={[
                <Dropdown
                  boxStyle
                  disabled={!selectedItems.length || isReadOnlyUser}
                  fixedPlaceholder={'Bulk Action'}
                  key={'bulk-action'}
                  options={[
                    { label: 'Request Takedown', value: 'request_takedown' },
                    { label: 'Share (coming soon)', value: 'share', disabled: true },
                  ]}
                  onChange={triggerPaywallPopUp}
                />,
              ]}
              enableCheckbox={true}
              onItemCheck={onItemCheck}
              selectedItems={selectedItems}
              isAnnonymous={false}
              showLoader={false}
              triggerState={triggerExportBtn}
              triggerCSVExport={triggerCSVExport}
              showUpgradeModal={showTypoBlurr}
            />
          </>
        )}
      </div>
    </div>
  );
};

export { TyposquatPage };
