import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import '../../Styles/OtherEntityWidget.scss';
import { useState } from 'react';
import { Table } from '../../../Common/Table/table';
import ExpandTileIcon from '../../../../assets/icons/ExpandTileIcon.svg';
import CollapseTile from '../../../../assets/icons/CollapseTile.svg';
import { IOtherEntityWidget } from '../../types';
import { DARK_WEB_OTHER_ENTITY_TABLE_ID } from '../../../DarkWeb/DarkWebConstants';
import _ from 'lodash';
import {
  SEARCH_TERM_COLUMN,
  CATEGORY_COLUMN,
  TIMES_DETECTED_COLUMN,
  ADDED_COLUMN,
  ENTITY_COLUMN,
} from '../../DarkWebColumnDefs';
import WidgetHeader from '../Common/WidgetHeader';
import { ENTITY_TYPE } from '../../DarkWebConstants';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

const TableColumns = [
  SEARCH_TERM_COLUMN,
  CATEGORY_COLUMN,
  TIMES_DETECTED_COLUMN,
  ADDED_COLUMN,
  ENTITY_COLUMN,
];

interface ITableData {
  search_term: string;
  category: string;
  times: number;
  added: string;
  entity: string;
}

const OtherEntityWidget = ({ entityLables, widgetData, expandByDefault }: IOtherEntityWidget) => {
  const [expandedState, setExpandedState] = useState(expandByDefault);
  const [columns, setColumns] = useState<any[]>(TableColumns);
  const [data, setData] = useState<ITableData[]>([]);
  const [, selectedTheme] = useIsDarkMode();

  useEffect(() => {
    const tableData = widgetData.entityData.map((item: any) => ({
      search_term: item.searchTerm,
      category: item.category,
      times: item.times,
      added: item.created_ts,
      entity: item.entity,
    }));
    setData(tableData);
  }, [widgetData]);

  useEffect(() => {
    if (expandedState) {
      setColumns([...TableColumns]);
    } else {
      setColumns(TableColumns.slice(0, 4));
    }
  }, [expandedState]);

  const toggleExpandedState = () => setExpandedState(oldState => !oldState);

  useEffect(() => {
    setExpandedState(expandByDefault);
  }, []);

  return (
    <Card
      className={
        expandedState
          ? `enitity-widget-wrap other-entity widget-expanded other-entity-enpanded`
          : `enitity-widget-wrap other-entity widget-collapsed`
      }
      data-testid={'entity-widget'}
    >
      <div className='inner-wrap'>
        <WidgetHeader
          headingText={entityLables.entityHeading}
          headingSubtext={
            data.length > 5 ? `${entityLables.subHeading}` : `${data.length} Search Terms`
          }
          icon={entityLables.icon}
          entityType={ENTITY_TYPE.OTHERS}
        />
        <div
          className={`widget-body ${selectedTheme === 'light' ? 'light-shadow' : 'dark-shadow'}`}
        >
          <div className={expandedState ? 'expand-tile-wrap' : 'expand-tile-wrap end-box-shadow'}>
            <img
              src={expandedState ? CollapseTile : ExpandTileIcon}
              alt='Expand Tile'
              className='expand-collapse-icon'
              role='button'
              onClick={toggleExpandedState}
            />
          </div>
          <Table
            id={DARK_WEB_OTHER_ENTITY_TABLE_ID}
            tableIndex={'search_term'}
            data={data.slice(0, 5)}
            columns={columns}
            indexBy={'search_terms'}
            disableToolsBar
          />
        </div>
      </div>
    </Card>
  );
};

export default OtherEntityWidget;
