import reportsReducer from './reports.reducer';
import { combineReducers } from 'redux';
import { appReducer } from './app.reducer';

import { login, ELoggedIn } from './login.reducer';
import { registration } from './signup.reducer';
import { alert } from './alert.reducer';
import { verifyReducer } from './verify.reducer';
import { forgotpassReducer } from './forgotpass.reducer';
import { resetReducer } from './reset.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { confirmRegistrationReducer } from './confirmregistration.reducer';
import { addUserReducer } from './adduser.reducer';
import darkWebReducer from './darkWeb.reducer';
import tableReducer from './table.reducer';
import tagsReducer from './tags.reducer';
import timelineReducer from './timeline.reducer';
import insightsContainerReducer from './insightsContainer.reducer';
import playbookReducer from './playbook.reducer';
import globalSearchReducer from './globalSearch.reducer';

const rootReducer = combineReducers({
  addUserReducer,
  alert,
  appReducer,
  darkWebReducer,
  confirmRegistrationReducer,
  dashboardReducer,
  forgotpassReducer,
  login,
  registration,
  resetReducer,
  verifyReducer,
  reportsReducer,
  tableReducer,
  tagsReducer,
  timelineReducer,
  insightsContainerReducer,
  playbookReducer,
  globalSearchReducer,
});

export default rootReducer;
export { ELoggedIn };
