import React from 'react';
import _ from 'lodash';
import {
  DOMAIN_COLUMN,
  REGISTRATION_DATE_COLUMN,
} from '../../MonitorAndTakedown/ColDefinition/malicious.columns';
import {
  customAgGridDateComparator,
  getDisplayTimeFromTimeStamp,
  ipv4SortingFunction,
  numberRangeColumnValidator,
} from '../../../constants';
import { IP_COLUMN } from './ManagedByOrgCols';
import { ITableDataColumn } from '../../Common/Table/table.data';
import { customSortingComparator } from '../../Playbook/playbook-util';

export const DOMAIN_COL: ITableDataColumn = {
  ...DOMAIN_COLUMN,
  header: 'Domain',
  accessor: 'domain_name',
  agFilterType: 'agTextColumnFilter',
  comparator: customSortingComparator,
  disableForSelection: true,
};

export const IP_ADDRESS_COL: ITableDataColumn = {
  ...IP_COLUMN,
  accessor: 'ip_address',
  sortingFn: (a: any, b: any) => {
    return ipv4SortingFunction(a, b);
  },
  agFilterType: 'agTextColumnFilter',
};

export const REGISTRATION_DATE_COL: ITableDataColumn = {
  ...REGISTRATION_DATE_COLUMN,
  id: 'registration_date',
  accessor: 'registration_date',
  filterDisabled: true,
  render: ({ registration_date }: any) => {
    return registration_date;
  },
  comparator: (a: any, b: any) => {
    return customAgGridDateComparator(a, b, 'DD-MMM-YYYY');
  },
};
export const RENEWAL_DATE_COL: ITableDataColumn = {
  id: 'renewal_date',
  accessor: 'renewal_date',
  header: 'Renewal Date',
  filterDisabled: true,
  comparator: (a: any, b: any) => {
    return customAgGridDateComparator(a, b, 'DD-MMM-YYYY');
  },
};

export const ACQUISITION_STATUS_COL: ITableDataColumn = {
  id: 'acquisition_status',
  header: 'Acquisition Status',
  accessor: 'acquisition_status',
  type: 'options',
  filterOptions: [
    { label: 'Purchased', value: 'Purchased' },
    { label: 'Registered', value: 'Registered' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Unavailable', value: 'unavailable' },
  ],
  agFilterType: 'agTextColumnFilter',
};
export const NOTES_COL: ITableDataColumn = {
  id: 'notes',
  header: 'Notes',
  accessor: 'notes',
  agFilterType: 'agTextColumnFilter',
};
export const PRICE_COL: ITableDataColumn = {
  id: 'price',
  header: 'Price',
  headerTooltip: 'Domain Price',
  accessor: 'price',
  type: 'number',
  validator: numberRangeColumnValidator,
  render: ({ price }: any) => {
    return price !== '--' ? `$${price}` : price;
  },
  agFilterType: 'agNumberColumnFilter',
};
