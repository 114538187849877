import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button, Card, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Layouts } from 'react-grid-layout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { alertActions, dashboardActions } from '../../actions';
import MonitoringPic from '../../assets/dashboard-monitoring.png';
import TablePic from '../../assets/dashboard-table.png';
import WidgetsPic from '../../assets/dashboard-widgets.png';
import AddWhite from '../../assets/icons/AddWhite.svg';
import AddWidgetIcon from '../../assets/icons/AddWidget.svg';
import CheckCircleGreenSmall from '../../assets/icons/CheckCircleGreenSmall.svg';
import DotDotDot from '../../assets/icons/DotDotDot.svg';
import Hourglass from '../../assets/icons/Hourglass.svg';
import HourglassBlue from '../../assets/icons/HourglassBlue.svg';
import User from '../../assets/icons/User.svg';
import UserBlue from '../../assets/icons/UserBlue.svg';
import ThumbnailBrands from '../../assets/thumbnail-brands.png';
import ThumbnailCountries from '../../assets/thumbnail-countries.png';
import ThumbnailDetection from '../../assets/thumbnail-detection.png';
import ThumbnailDomains from '../../assets/thumbnail-domains.png';
import ThumbnailHosting from '../../assets/thumbnail-hosting.png';
import ThumbnailIp from '../../assets/thumbnail-ip.png';
import { AuthenticationWrapper } from '../../components/AuthenticationWrapper';
import {
  AlertActionsTypes,
  capitalize,
  DashBoardConfigDto,
  DashBoardDto,
  DashBoardWidgetConfigDto,
  duplicateObject,
  generateWidgetId,
  getDisplayTimeFromTimeStamp,
  getLocalStorageValue,
  isMobile,
  saveDashboardTableParameters,
  saveDashboardTimeRangeParameters,
  numberRangeColumnValidator,
  GRAPH_HEIGHT_INDEX,
} from '../../constants';
import { AppState } from '../../helpers';
import DashboardService from '../../services/dashboard.service';
import Country from '../Common/Country';
import { DispositionStatus } from '../Common/DispositionStatus';
import { DisputeStatus } from '../Common/DisputeStatus';
import { Dropdown, IDropdownOption } from '../Common/Dropdown';
import { DropdownDateRangePicker } from '../Common/DropdownDateRangePicker';
import { PageTitle } from '../Common/PageTitle';
import { Status } from '../Common/Status';
import { IFilter, TExportTable } from '../Common/Table/constant';
import { Table } from '../Common/Table/table';
import { DashboardAddSection } from './dashboard.add.section';
import { DashboardBrands } from './dashboard.brands';
import { DashboardCountries } from './dashboard.countires';
import { DashboardDetection } from './dashboard.detection';
import { DashboardDomain } from './dashboard.domain';
import { HideButton } from './dashboard.hide.button';
import { DashboardHosting } from './dashboard.hosting';
import { DashboardIp } from './dashboard.ip';
import { DashboardMonitoring } from './dashboard.monitoring';
import { ReplaceButton } from './dashboard.replace.button';
import './dashboard.scss';
import './dashboard.scss';
import { DashboardSources } from './dashboard.sources';
import { DashboardTile } from './dashboard.tile';
import { ModulePermissionTeaserPage } from '../Ugc/Components/Common/ModulePermissionTeaserPage';
import { getIsWebModuleAvailable } from '../../helpers/permissions';

const ALL_WIDGETS = [
  {
    id: 'detection',
    image: ThumbnailDetection,
    title: 'Counterfeit Site Detection',
    description: 'New counterfeit sites detected by time.',
    widgetType: ['brand', 'hybrid'],
  },
  {
    id: 'hosting',
    image: ThumbnailHosting,
    title: 'Counterfeit Site Hosting',
    description: 'New counterfeit sites detected by hosting provider.',
    widgetType: ['brand', 'hybrid'],
  },
  {
    id: 'domains',
    image: ThumbnailDomains,
    title: 'Top Level Domains',
    description: 'New counterfeit sites detected by Domain.',
  },
  {
    id: 'ip',
    image: ThumbnailIp,
    title: 'Top IP Address Detected',
    description: 'New counterfeit sites detected by IP.',
  },
  {
    id: 'countries',
    image: ThumbnailCountries,
    title: 'Top Originating Countries',
    description: 'New counterfeit sites detected in countries.',
  },
  {
    id: 'brands',
    image: ThumbnailBrands,
    title: 'Top Brands',
    description: 'New counterfeit sites detected by Brand.',
    widgetType: ['hybrid'],
  },
];

const calculateLayout = (widgets: DashBoardWidgetConfigDto[]): any => {
  return _.map(widgets, (widget, i: number) => {
    return {
      i: widget,
      x: i % 2,
      y: Math.floor(i / 2) * GRAPH_HEIGHT_INDEX,
      w: 1,
      h: GRAPH_HEIGHT_INDEX,
      static: true,
    };
  });
};

export const URL_COLUMN = (disabled = false, filterDisabled = false) => ({
  id: 'Source URL',
  fieldForExport: 'src_url',
  fieldForPlaybook: 'src_url',
  header: 'Source URL',
  headerTooltip: 'Link to URL Details View',
  accessor: 'url',
  isDragDisabled: true,
  filterDisabled,
  render: (data: any) => {
    const { url } = data;
    if (url) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + url} className={'table-source-url-tooltip'}>
              {url}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            {data.isLoading && (
              <Spinner className='spinner' animation='border' variant='primary' size='sm' />
            )}
            {disabled ? (
              <div>{url}</div>
            ) : (
              <Link to={'/bulk-scan/insights/' + data.timestamp + '/' + data.urlSha256}>{url}</Link>
            )}
          </div>
        </OverlayTrigger>
      );
    }
    return null;
  },
});
export const IP_COLUMN = (disabled = false) => ({
  id: 'IP Address',
  fieldForExport: 'ip',
  dateRangeFilterForPlaybook: 'ipAddressStart',
  header: 'IP Address',
  headerTooltip: 'Link to IP Details View',
  accessor: 'ipAddress',
  isDragDisabled: true,
  type: 'ipv4',
  columnClassName: 'hide-below-sm',
  render: (data: any) => {
    const { ipAddress } = data;
    if (_.isEmpty(ipAddress) || ipAddress === '0.0.0.0') {
      return '--';
    }
    if (disabled) {
      return <div>{ipAddress}</div>;
    }
    return (
      <div className='table-ip-column'>
        <Link to={'/bulk-scan/insights/ip/' + ipAddress}>{ipAddress}</Link>
      </div>
    );
  },
});
export const STATUS_COLUMN = {
  id: 'Status',
  header: 'Status',
  headerTooltip: 'Yellow = Active, Blue = Taken Down',
  accessor: 'status',
  type: 'options',
  filterOptions: [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Down',
      value: 'down',
    },
  ],
  render: (data: any) => {
    return <Status status={data.status} disposition={data.disposition} />;
  },
};
export const HOSTING_COLUMN = {
  id: 'Hosting Provider',
  fieldForExport: 'as_description',
  fieldForPlaybook: 'asDescription',
  header: 'Hosting Provider',
  headerTooltip: 'Entity that hosts the site and to which Bolster directs takedown requests',
  accessor: 'networkOwner',
  isLongText: true,
  hiddenOnMobile: true,
};

export const DISPOSITION_COLUMN = (sortDisabled = false) => ({
  id: 'Original Disposition',
  header: 'Original Disposition',
  headerTooltip: 'Disposition when Site was First Scanned',
  accessor: 'disposition',
  type: 'options',
  sortDisabled,
  filterOptions: [
    { label: 'Phish', value: 'phish' },
    { label: 'Suspicious', value: 'suspicious' },
    { label: 'Scam', value: 'scam' },
    { label: 'Clean', value: 'clean' },
  ],
  render: (data: any) => {
    if (_.isEmpty(data)) return null;
    return (
      <div className='table-icon'>
        <DispositionStatus status={data.disposition} />
        {!_.isEmpty(data.disputeDetail) && (
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-dispute-${data.urlSha256}`} className={'table-icon-tooltip'}>
                <DisputeStatus
                  disposition={data.disputeDetail.reporter_disposition}
                  bolsterDisposition={data.disputeDetail.bolster_disposition}
                  status={data.disputeDetail.status}
                  user={data.disputeDetail.user}
                  timestamp={data.disputeDetail.created_ts}
                  comments={data.disputeDetail.reporter_comment}
                />
              </Tooltip>
            }
          >
            <img
              src={Hourglass}
              alt={'dispute'}
              className={'icon'}
              onMouseEnter={e => (e.currentTarget.src = HourglassBlue)}
              onMouseLeave={e => (e.currentTarget.src = Hourglass)}
            />
          </OverlayTrigger>
        )}
      </div>
    );
  },
});

export const CURRENT_DISPOSITION_COLUMN = (sortDisabled = false) => ({
  id: 'Current Disposition',
  header: 'Current Disposition',
  headerTooltip: 'Disposition when Site was Last Scanned',
  accessor: 'currentDisposition',
  fieldForExport: 'current_disposition',
  fieldForPlaybook: 'current_disposition',
  type: 'options',
  sortDisabled,
  filterOptions: [
    { label: 'Phish', value: 'phish' },
    { label: 'Clean', value: 'clean' },
    { label: 'Suspicious', value: 'suspicious' },
    { label: 'Scam', value: 'scam' },
  ],
  render: (data: any) => {
    if (_.isEmpty(data)) {
      return null;
    }
    return <DispositionStatus status={data.currentDisposition} />;
  },
});

export const DISPUTE_STATUS_COLUMN = {
  id: 'Dispute Status',
  header: 'Dispute Status',
  headerTooltip: 'Dispute Status',
  accessor: 'disputeStatus',
  type: 'options',
  filterOptions: [
    { label: 'Never Disputed', value: 'none' },
    { label: 'Under Dispute', value: 'under_review' },
    { label: 'Dispute Resolved', value: 'accepted|rejected' },
  ],
  hidden: true,
};

export const FIRST_SEEN_COLUMN = {
  id: 'First seen',
  fieldForExport: 'first_seen_ts',
  fieldForPlaybook: 'first_seen_ts',
  dateRangeFilterForPlaybook: 'firstSeen',
  header: 'First seen',
  headerTooltip: 'Time of First Scan',
  columnClassName: 'hide-below-lg',
  accessor: 'firstSeen',
  type: 'date',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.firstSeen);
  },
};
export const LAST_SCANNED_COLUMN = {
  id: 'Last scanned',
  fieldForExport: 'created_ts',
  fieldForPlaybook: 'created_ts',
  dateRangeFilterForPlaybook: 'create',
  header: 'Last scanned',
  headerTooltip: 'Time of Last Scan',
  columnClassName: 'hide-below-lg',
  accessor: 'lastScanned',
  type: 'date',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.lastScanned);
  },
};
export const DETECTION_DATE_COLUMN = {
  id: 'Detection Date',
  header: 'Detection Date',
  headerTooltip: 'Time of Scan',
  columnClassName: 'hide-below-lg',
  accessor: 'createdTs',
  fieldForPlaybook: 'created_ts',
  dateRangeFilterForPlaybook: 'create',
  type: 'date',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.createdTs);
  },
};

export const SOURCE_COLUMN = {
  id: 'Source',
  fieldForExport: 'scan_source',
  fieldForPlaybook: 'scan_source',
  header: 'Scan Source',
  headerTooltip: 'Initiating entity of scan',
  columnClassName: 'hide-below-sm',
  accessor: 'scanSource',
  isLongText: true,
  type: 'options',
  filterOptions: [{ label: 'Bolster', value: 'bolster' }],
};

export const TAKE_DOWN_TIME_COLUMN = {
  id: 'Takedown Time',
  header: 'Takedown Time',
  headerTooltip: 'Time of Taken down',
  columnClassName: 'hide-below-lg',
  fieldForExport: 'takedown_ts',
  fieldForPlaybook: 'takedown_ts',
  dateRangeFilterForPlaybook: 'takedown',
  accessor: 'takedownTs',
  type: 'date',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return _.isEmpty(data.takedownTs) ? '--' : getDisplayTimeFromTimeStamp(data.takedownTs);
  },
};

export const TLD_COLUMN = {
  id: 'TLD',
  header: 'TLD',
  headerTooltip: 'Top Level Domain',
  accessor: 'tld',
};

export const COUNTRY_COLUMN = {
  id: 'Country',
  fieldForExport: 'country_code',
  header: 'Country',
  headerTooltip: 'Country Code',
  accessor: 'countryCode',
  render: (data: any) => {
    return <Country countryCode={data.countryCode} />;
  },
};

const ASN_COLUMN = {
  id: 'ASN',
  header: 'ASN',
  headerTooltip: 'ASN',
  accessor: 'asn',
};

export const PAST_PHISH_ON_HOST_COLUMN = {
  id: 'Past Phish on Host',
  header: 'Past Phish on Host',
  headerTooltip: 'Past Phish on Host',
  fieldForPlaybook: 'host_phish_count',
  filterDisabled: true,
  filterDisabledOnPlaybook: true,
  sortDisabled: true,
  accessor: 'pastPhishCountOnHost',
};

export const PAST_PHISH_ON_IP_COLUMN = {
  id: 'Past Phish on IP',
  header: 'Past Phish on IP',
  headerTooltip: 'Past Phish on IP',
  fieldForPlaybook: 'ip_phish_count',
  filterDisabled: true,
  filterDisabledOnPlaybook: true,
  sortDisabled: true,
  accessor: 'pastPhishCountOnIP',
};

export const USER_REQUESTED_TAKEDOWN_COLUMN = {
  id: 'User Requested Takedown',
  header: 'User Requested Takedown',
  headerTooltip: 'User Requested Takedown',
  fieldForPlaybook: 'user_requested_takedown',
  accessor: 'userRequestedTakedown',
  type: 'options',
  filterOptions: [
    { label: 'true', value: 'true' },
    { label: 'false', value: 'false' },
  ],
  hidden: true,
};

export const TAKEDOWN_REQUEST_DATE_COLUMN = {
  id: 'Takedown Request Date',
  header: 'Takedown Request Date',
  headerTooltip: 'Takedown Request Date',
  fieldForPlaybook: 'user_takedown_ts',
  dateRangeFilterForPlaybook: 'userTakedown',
  accessor: 'userTakedownTs',
  type: 'date',
  hidden: true,
};

export const TAKEDOWN_REQUESTER_EMAIL_COLUMN = {
  id: 'Takedown Requester Email',
  header: 'Takedown Requester Email',
  headerTooltip: 'Takedown Requester Email',
  fieldForPlaybook: 'user_takedown_email',
  accessor: 'userTakedownEmail',
  hidden: true,
};

export const LOGO_DETECTION_COLUMN = {
  id: 'Logo Detected',
  header: 'Logo Detected',
  headerTooltip: 'Logo Detected',
  fieldForExport: 'brand_logo_detected',
  fieldForPlaybook: 'brand_logo_detected',
  accessor: 'brandLogoDetected',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
};

export const LOGO_DETECTION_COUNT_COLUMN = {
  id: 'Logo Detected Count',
  header: '# Logo Detected',
  headerTooltip: 'Logo Detected Count',
  accessor: 'logoDetectedCount',
  type: 'number',
  validator: numberRangeColumnValidator,
};

export const BRAND_SCAN_COLUMN = {
  id: 'Brand Scan Count',
  header: '# Customer Scans',
  headerTooltip: 'Brand Scan Count',
  fieldForExport: 'brand_scan_count',
  fieldForPlaybook: 'brand_scan_count',
  dateRangeFilterForPlaybook: 'brandScanCountStart',
  accessor: 'brandScanCount',
  type: 'number',
  validator: numberRangeColumnValidator,
};

export const BOLSTER_SCAN_COLUMN = {
  id: 'Bolster Scan Count',
  header: '# Bolster Scans',
  headerTooltip: 'Bolster Scan Count',
  fieldForExport: 'bolster_scan_count',
  fieldForPlaybook: 'bolster_scan_count',
  dateRangeFilterForPlaybook: 'bolsterScanCountStart',
  accessor: 'bolsterScanCount',
  type: 'number',
  validator: numberRangeColumnValidator,
};

export const DISPOSITION_TIMESTAMP_COLUMN = {
  id: 'current_disposition_ts',
  header: 'Disposition Change',
  accessor: 'current_disposition_ts',
  dateRangeFilterForPlaybook: 'dispositionChange',
  type: 'date',
  // hidden: true,
  filterDisabled: true,
  filterDisabledOnPlaybook: false,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.current_disposition_ts);
  },
};

const getDefaultColumns = (isNonBrand: boolean) => {
  // return array of column ids.
  return isNonBrand
    ? [
        'Source URL',
        'IP Address',
        'Original Disposition',
        'Logo Detected Count',
        'Detection Date',
        'Brand',
        'Source',
        'Job ID',
      ]
    : [
        'Source URL',
        'IP Address',
        'Status',
        'Original Disposition',
        'Logo Detected',
        'First seen',
        'Takedown Time',
        'Source',
        'Brand Scan Count',
        'Bolster Scan Count',
        'Country',
        'Hosting Provider',
      ];
};

export const SCAN_HISTORY_COLUMNS = (disabled = false, sortDisabled = false): any[] => [
  URL_COLUMN(disabled),
  IP_COLUMN(disabled),
  STATUS_COLUMN,
  HOSTING_COLUMN,
  DISPOSITION_COLUMN(sortDisabled),
  CURRENT_DISPOSITION_COLUMN(sortDisabled),
  FIRST_SEEN_COLUMN,
  LAST_SCANNED_COLUMN,
  USER_REQUESTED_TAKEDOWN_COLUMN,
  TAKEDOWN_REQUEST_DATE_COLUMN,
  TAKEDOWN_REQUESTER_EMAIL_COLUMN,
  SOURCE_COLUMN,
  TAKE_DOWN_TIME_COLUMN,
  TLD_COLUMN,
  // ASN_COLUMN,
  COUNTRY_COLUMN,
  PAST_PHISH_ON_HOST_COLUMN,
  PAST_PHISH_ON_IP_COLUMN,
  LOGO_DETECTION_COLUMN,
  BRAND_SCAN_COLUMN,
  BOLSTER_SCAN_COLUMN,
  DISPUTE_STATUS_COLUMN,
  DISPOSITION_TIMESTAMP_COLUMN,
];

export const SCAN_HISTORY_COLUMNS_NON_BRAND = (disabled = false, sortDisabled = false): any[] => [
  URL_COLUMN(disabled, true),
  IP_COLUMN(disabled),
  DISPOSITION_COLUMN(sortDisabled),
  DETECTION_DATE_COLUMN,
  {
    id: 'Brand',
    header: 'Brand',
    headerTooltip: 'Brand',
    accessor: 'brandId',
  },
  {
    id: 'Source',
    header: 'Source',
    headerTooltip: 'Source',
    fieldForPlaybook: 'source',
    accessor: 'source',
    type: 'options',
    filterOptions: [
      { label: 'API', value: 'api_' },
      { label: 'Bulk Scan', value: 'bulk_' },
    ],
  },
  {
    id: 'Job ID',
    header: 'Job ID',
    headerTooltip: 'Job ID',
    accessor: 'jobId',
    columnClassName: 'hide-below-sm',
    isLongText: true,
    hiddenOnMobile: true,
  },
  TLD_COLUMN,
  COUNTRY_COLUMN,
  PAST_PHISH_ON_HOST_COLUMN,
  PAST_PHISH_ON_IP_COLUMN,
  LOGO_DETECTION_COUNT_COLUMN,
  DISPUTE_STATUS_COLUMN,
];

const getTileCompProperties = (isNonBrand: boolean) => {
  return isNonBrand
    ? [
        { id: 'Original Disposition' },
        { id: 'Detection Date' },
        { id: 'Brand' },
        { id: 'Source' },
        { id: 'TLD' },
        { id: 'Country' },
        { id: 'Past Phish on Host' },
        { id: 'Past Phish on IP' },
        { id: 'Job ID', copyButton: true },
      ]
    : [
        { id: 'Hosting Provider' },
        { id: 'Original Disposition' },
        { id: 'Last scanned' },
        { id: 'First seen' },
        { id: 'Logo Detected' },
        { id: 'TLD' },
        { id: 'Takedown Time' },
        { id: 'Number Takedowns' },
        { id: 'Source' },
        { id: 'Brand Scan Count' },
        { id: 'Bolster Scan Count' },
        { id: 'Country' },
        { id: 'Past Phish on Host' },
        { id: 'Past Phish on IP' },
      ];
};

interface ILinkStateProp {
  user: DashBoardDto;
  dashboardConfig: DashBoardConfigDto[];
  selectedDashboard: DashBoardConfigDto;
}

interface ILinkDispatchProps {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  getDashboardConfig: (name?: string) => void;
  onDeleteDashboard: (dashboard: DashBoardConfigDto) => any;
  onSetDefaultDashboard: (
    selectedDashboard: DashBoardConfigDto,
    currentDefaultDashboard: DashBoardConfigDto | undefined,
  ) => any;
  onChangeDashboard: (dashboard: DashBoardConfigDto) => any;
  onSaveDashboard: (config: any) => any;
  onDeleteWidgets: (data: any) => any;
}

type Props = ILinkStateProp & ILinkDispatchProps;

interface IDashboardState {
  isEditingMode: boolean;
  deleteModalShown: boolean;
  sectionPickerShown: boolean;
  widgetsPickerShown: boolean;
  replaceWidgetPickerShown: boolean;
  selectedWidgets: string[];
  layouts: Layouts;
  currentBreakpoint: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  countriesMap: any;
  ipMap: any;
  domainMap: any;
  brandInfo: any;
}

class Dashboard extends React.Component<Props, IDashboardState> {
  private readonly dashboardService: DashboardService;
  private originalDashboard: DashBoardConfigDto;
  private dashboardNameInput: any = null;
  private tablePageNumber = 1;
  private tableSort: any = undefined;
  private tableFilters: IFilter[] = [];
  private replacedIdx = -1;
  private lastXDay = 0;
  private _isMounted = false;

  constructor(props: Props) {
    super(props);
    const tableParameters = getLocalStorageValue(['dashboard', 'table']);
    const dashboardTimeRangeParameters = getLocalStorageValue(['dashboard', 'time']);
    const defaultDayRange = 90;
    const startDateStr = _.get(dashboardTimeRangeParameters, ['startDate']);
    let startDate = startDateStr ? moment(startDateStr) : moment().subtract(defaultDayRange, 'day');
    let endDate = moment(_.get(dashboardTimeRangeParameters, ['endDate']));
    this.lastXDay = _.get(dashboardTimeRangeParameters, ['lastXDay'], 0);
    if (this.lastXDay > 0) {
      endDate = moment();
      startDate = moment().subtract(this.lastXDay, 'day');
    } else {
      this.lastXDay = defaultDayRange;
    }
    const INITIAL_LAYOUT = calculateLayout(props.selectedDashboard.widgets);
    this.state = {
      isEditingMode: false,
      deleteModalShown: false,
      sectionPickerShown: false,
      widgetsPickerShown: false,
      replaceWidgetPickerShown: false,
      selectedWidgets: [],
      layouts: { lg: INITIAL_LAYOUT },
      currentBreakpoint: 'lg',
      startDate,
      endDate,
      countriesMap: {},
      ipMap: {},
      domainMap: {},
      brandInfo: null,
    };
    this.dashboardService = new DashboardService();
    this.originalDashboard = {
      id: JSON.stringify(moment().valueOf()),
      label: '',
      name: '',
      href: '/dashboard',
      parent: 'dashboard',
      isDefault: false,
      widgets: [],
      dashboardType: 'brand',
    };

    this.tablePageNumber = _.get(tableParameters, ['query', 'pageNumber'], 1);
    this.tableSort = _.get(tableParameters, ['sortBy'], {
      sortBy: 'firstSeen',
      sortDirection: 'desc',
    });
    this.tableSort.sortBy = this.adjustTableSortBy(this.tableSort.sortBy);
    this.tableFilters = _.get(tableParameters, ['filters'], []);
    window.document.title = 'Dashboard | Bolster Platform';
  }

  componentDidMount() {
    this._isMounted = true;
    if (
      !_.isEmpty(this.props.user.email) &&
      !_.isEmpty(this.props.selectedDashboard.table) &&
      this.props.selectedDashboard.dashboardType === 'brand'
    ) {
      this.dashboardService.getBrandInfo('brand').then((info: any) => {
        this.setState({ brandInfo: info });
        this.setSourceColumnOptions(info.brand.brandName);
      });
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  resetOriginalDashboard = () => {
    this.originalDashboard = {
      id: JSON.stringify(moment().valueOf()),
      label: '',
      name: '',
      href: '/dashboard',
      parent: 'dashboard',
      isDefault: false,
      widgets: [],
      dashboardType: 'brand',
    };
  };

  toggleEditMode = () => {
    const { selectedDashboard } = this.props;
    const { layouts, currentBreakpoint, isEditingMode } = this.state;

    let newLayouts = { ...layouts };
    const newIsEditingMode = !isEditingMode;

    if (isEditingMode) {
      newLayouts = { ...layouts, [currentBreakpoint]: calculateLayout(selectedDashboard.widgets) };
    }

    this.originalDashboard = selectedDashboard;

    this.setCompState({
      isEditingMode: newIsEditingMode,
      layouts: newLayouts,
    });
  };

  cancelEditMode = () => {
    const { selectedDashboard, getDashboardConfig } = this.props;
    getDashboardConfig(selectedDashboard.name);
    this.resetOriginalDashboard();
    this.setCompState({
      isEditingMode: false,
    });
  };

  saveEditMode = () => {
    const {
      dashboardConfig,
      selectedDashboard,
      onSaveDashboard,
      onDeleteWidgets,
      getDashboardConfig,
      alertError,
    } = this.props;
    const oldName = this.originalDashboard.name;
    const newName = this.dashboardNameInput.value;
    let nameRepeated = false;
    for (let i = 0; i < dashboardConfig.length; i++) {
      if (newName !== oldName && newName === dashboardConfig[i].name) {
        nameRepeated = true;
        break;
      }
    }
    if (nameRepeated) {
      alertError(`${newName} already exists.`);
      return;
    }

    const nonChangedWidgets = selectedDashboard.isNew
      ? []
      : _.intersectionBy(this.originalDashboard.widgets, selectedDashboard.widgets, 'name');
    const deletedWidgets = selectedDashboard.isNew
      ? []
      : _.filter(this.originalDashboard.widgets, widget => !_.some(nonChangedWidgets, widget));
    const newWidgets = _.filter(
      selectedDashboard.widgets,
      widget => !_.some(nonChangedWidgets, widget),
    );

    if (this.originalDashboard.monitoring && !selectedDashboard.monitoring) {
      deletedWidgets.push(this.originalDashboard.monitoring);
    } else if (!this.originalDashboard.monitoring && selectedDashboard.monitoring) {
      newWidgets.push(selectedDashboard.monitoring);
    }

    if (this.originalDashboard.table && !selectedDashboard.table) {
      deletedWidgets.push(this.originalDashboard.table);
    } else if (!this.originalDashboard.table && selectedDashboard.table) {
      newWidgets.push(selectedDashboard.table);
    }

    onSaveDashboard({
      dashboard: {
        name: oldName || newName,
        newName: newName,
        isDefault: selectedDashboard.isDefault,
        dashboardType: selectedDashboard.dashboardType || 'brand',
        widgets: newWidgets,
      },
    }).then(() => {
      if (!selectedDashboard.isNew && deletedWidgets.length) {
        onDeleteWidgets({
          dashboard: {
            name: newName,
            widgets: deletedWidgets,
          },
        }).then(() => {
          this.resetOriginalDashboard();
          setTimeout(() => {
            getDashboardConfig(newName);
          }, 500);
        });
      } else {
        this.resetOriginalDashboard();
        setTimeout(() => {
          getDashboardConfig(newName);
        }, 500);
      }
    });

    this.setCompState({
      isEditingMode: false,
    });
  };

  toggleDeleteDashboardModal = () => {
    this.setCompState({
      deleteModalShown: !this.state.deleteModalShown,
    });
  };

  onDashboardActionChange = (selection: IDropdownOption) => {
    switch (selection.value) {
      case 'edit':
        this.toggleEditMode();
        break;
      case 'setDefault':
        this.onSetDefaultDashboard();
        break;
      case 'delete':
        this.toggleDeleteDashboardModal();
        break;
      default:
        break;
    }
  };

  onDeleteDashboard = () => {
    const { getDashboardConfig, onDeleteDashboard, selectedDashboard } = this.props;
    onDeleteDashboard(selectedDashboard).then(() => {
      getDashboardConfig();
    });
    this.toggleDeleteDashboardModal();
  };

  onSetDefaultDashboard = () => {
    const { onSetDefaultDashboard, selectedDashboard, dashboardConfig } = this.props;
    const currentDefaultDashboard = _.find(
      dashboardConfig,
      (dashboard: DashBoardConfigDto) => dashboard.isDefault,
    );
    onSetDefaultDashboard(selectedDashboard, currentDefaultDashboard);
  };

  toggleMonitoring = () => {
    const { onChangeDashboard, selectedDashboard } = this.props;
    let newMonitoring: DashBoardWidgetConfigDto | undefined;
    if (!selectedDashboard.monitoring) {
      const idx = generateWidgetId();
      newMonitoring = { name: 'monitoring_' + idx, idx, widgetType: 'monitoring' };
    }
    onChangeDashboard({ ...selectedDashboard, monitoring: newMonitoring });
    this.setCompState({
      sectionPickerShown: false,
    });
  };

  toggleSectionPicker = () => {
    this.setCompState({
      sectionPickerShown: !this.state.sectionPickerShown,
    });
  };

  toggleWidgetsPicker = () => {
    let { sectionPickerShown, widgetsPickerShown } = this.state;
    widgetsPickerShown = !widgetsPickerShown;
    if (widgetsPickerShown) {
      sectionPickerShown = false;
    }
    this.setCompState({
      sectionPickerShown,
      widgetsPickerShown,
      selectedWidgets: [],
    });
  };

  toggleReplaceWidgetsPicker = () => {
    let { replaceWidgetPickerShown } = this.state;
    replaceWidgetPickerShown = !replaceWidgetPickerShown;
    if (!replaceWidgetPickerShown) {
      this.replacedIdx = -1;
    }
    this.setCompState({
      replaceWidgetPickerShown,
      selectedWidgets: [],
    });
  };

  toggleTable = () => {
    const { onChangeDashboard, selectedDashboard } = this.props;
    const isNonBrand = selectedDashboard.dashboardType === 'non_brand';
    let newTable: DashBoardWidgetConfigDto | undefined;
    if (!selectedDashboard.table) {
      const idx = generateWidgetId();
      newTable = {
        name: 'table_' + idx,
        idx,
        widgetType: 'table',
        columns: getDefaultColumns(isNonBrand).join(','),
      };
    }
    onChangeDashboard({ ...selectedDashboard, table: newTable });
    this.setCompState({
      sectionPickerShown: false,
    });
  };

  onWidgetSelected = (widgetName: string, isSelected: boolean) => {
    let { selectedWidgets } = this.state;
    if (isSelected) {
      selectedWidgets = _.filter(selectedWidgets, w => {
        return w !== widgetName;
      });
    } else {
      selectedWidgets = selectedWidgets.concat(widgetName);
    }
    this.setCompState({
      selectedWidgets,
    });
  };

  addWidgetSection = () => {
    const { onChangeDashboard, selectedDashboard } = this.props;
    const { selectedWidgets } = this.state;
    const widgets = selectedDashboard.widgets.concat();
    _.forEach(selectedWidgets, (widget, index: number) => {
      const idx = generateWidgetId() + index;
      widgets.push({
        name: widget + '_' + idx,
        idx,
        widgetType: 'chart',
      });
    });
    onChangeDashboard({ ...selectedDashboard, widgets });
    this.setCompState({
      widgetsPickerShown: false,
    });
  };

  removeWidgetSection = (section: DashBoardWidgetConfigDto[]) => {
    const { onChangeDashboard, selectedDashboard } = this.props;
    const widgets: any[] = _.filter(selectedDashboard.widgets, (widget: string) => {
      return !_.some(section, widget);
    });
    onChangeDashboard({ ...selectedDashboard, widgets });
  };

  replaceWidget = () => {
    const { onChangeDashboard, selectedDashboard } = this.props;
    const widgets = duplicateObject(selectedDashboard.widgets);

    const index = _.findIndex(widgets, (widget: DashBoardWidgetConfigDto) => {
      return widget.idx === this.replacedIdx;
    });
    widgets.splice(index, 1, {
      name: this.state.selectedWidgets[0] + '_' + this.replacedIdx,
      idx: this.replacedIdx,
      widgetType: 'chart',
    });
    onChangeDashboard({ ...selectedDashboard, widgets });
    this.toggleReplaceWidgetsPicker();
  };

  onDateChange = (startDate: moment.Moment, endDate: moment.Moment, lastXDay: number) => {
    this.lastXDay = lastXDay;
    this.setCompState({
      startDate,
      endDate,
    });
    this.tablePageNumber = 1;
    saveDashboardTimeRangeParameters({
      lastXDay,
      startDate,
      endDate,
    });
  };

  generateWidgetSection(section: DashBoardWidgetConfigDto[], index: number) {
    const { selectedDashboard } = this.props;
    const { isEditingMode, startDate, endDate } = this.state;
    const brandType = selectedDashboard.dashboardType;
    const getDom = (widget: DashBoardWidgetConfigDto) => {
      const name = widget.name.split('_')[0];
      if (name === 'detection') {
        return <DashboardDetection startDate={startDate} endDate={endDate} />;
      } else if (name === 'hosting') {
        return <DashboardHosting startDate={startDate} endDate={endDate} />;
      } else if (name === 'ip') {
        return <DashboardIp brandType={brandType} startDate={startDate} endDate={endDate} />;
      } else if (name === 'domains') {
        return (
          <DashboardDomain dashboardType={brandType} startDate={startDate} endDate={endDate} />
        );
      } else if (name === 'countries') {
        return <DashboardCountries brandType={brandType} startDate={startDate} endDate={endDate} />;
      } else if (name === 'brands') {
        return <DashboardBrands startDate={startDate} endDate={endDate} />;
      } else if (name === 'sources') {
        return <DashboardSources startDate={startDate} endDate={endDate} />;
      }
    };
    return (
      <Card className={'widgets-container'} key={'widget section ' + index}>
        <div key={'widget-left'} className='widget-item-component'>
          <div className='widget-item-inner'>
            <ReplaceButton
              shown={isEditingMode}
              onClick={() => {
                this.replacedIdx = section[0].idx;
                this.toggleReplaceWidgetsPicker();
              }}
            />
            {getDom(section[0])}
          </div>
        </div>
        <div key={'widget-right'} className='widget-item-component'>
          <div className='widget-item-inner'>
            <ReplaceButton
              shown={isEditingMode}
              onClick={() => {
                this.replacedIdx = section[1].idx;
                this.toggleReplaceWidgetsPicker();
              }}
            />
            {getDom(section[1])}
          </div>
        </div>
      </Card>
    );
  }

  adjustTableSortBy(sortBy: string) {
    if (this.props.selectedDashboard.dashboardType === 'non_brand' && sortBy === 'lastScanned') {
      sortBy = 'createdTs';
    } else if (this.props.selectedDashboard.dashboardType === 'brand' && sortBy === 'createdTs') {
      sortBy = 'lastScanned';
    }
    return sortBy;
  }

  getScanHistory = (
    query: any,
    filters: IFilter[],
    sort = { sortBy: 'lastScanned', sortDirection: 'desc' },
  ) => {
    const brandType = this.props.selectedDashboard.dashboardType;
    query.pageNumber = query.pageNumber ? query.pageNumber - 1 : 0;
    query.startDate = query.startDate.format('YYYY-MM-DD');
    query.endDate = query.endDate.format('YYYY-MM-DD');
    sort.sortBy = this.adjustTableSortBy(sort.sortBy);

    return this.dashboardService
      .getScanHistory(brandType, query, filters, sort)
      .then((res: any) => {
        const { total = 0, urlInfo } = res;
        let data = [];
        if (urlInfo) {
          data = urlInfo.map((source: any) => {
            const status = source.status;
            const disposition = source.disposition;
            const brandLogoDetected = JSON.stringify(source.brandLogoDetected);
            return {
              url: source.srcUrl,
              imageUrl: source.imagePath,
              status,
              takedowns: source.takedowns,
              userTakedown: source.userTakedown,
              scanSource: source.scanSource,
              source: source.source,
              jobId: source.jobId || '--',
              ipAddress: source.ip,
              networkOwner: source.as_description,
              disposition,
              currentDisposition: source.currentDisposition,
              current_disposition_ts: source.current_disposition_ts,
              firstSeen: source.firstSeenTs,
              lastScanned: source.createdTs,
              createdTs: source.createdTs,
              urlSha256: source.urlSha256,
              timestamp: source.timestamp,
              countryCode: source.country_code !== 'Unknown' && source.country_code,
              takedownTs: source.takedownTs,
              tld: source.tld,
              asn: source.asn,
              pastPhishCountOnHost: source.pastPhishCountOnHost,
              pastPhishCountOnIP: source.pastPhishCountOnIP || '--',
              brandId: source.brandId,
              takedownBrandName:
                this.state.brandInfo?.brand?.displayName ||
                capitalize(this.state.brandInfo?.brand?.brandName),
              brandType,
              brandLogoDetected,
              logoDetectedCount: source.logoDetectedCount ?? '--',
              brandScanCount: source.brandScanCount,
              bolsterScanCount: source.bolsterScanCount,
              disputeDetail: source.disputeDetail,
            };
          });
        }

        // Save the query setting in local storage
        query.pageNumber++;
        saveDashboardTableParameters({ query, filters, sortBy: sort, lastXDay: this.lastXDay });

        return { data, total };
      });
  };

  exportScanHistory = (query: any, type: TExportTable, filters: IFilter[], sort = undefined) => {
    const { selectedDashboard } = this.props;
    const brandType = selectedDashboard.dashboardType;
    this.props.alertSuccess('Start downloading...');
    query.pageNumber = query.pageNumber ? query.pageNumber - 1 : 0;
    query.startDate = query.startDate.format('YYYY-MM-DD');
    query.endDate = query.endDate.format('YYYY-MM-DD');

    const columns: any[] = [];
    const isNonBrand = selectedDashboard.dashboardType === 'non_brand';
    const allColumns = isNonBrand ? SCAN_HISTORY_COLUMNS_NON_BRAND() : SCAN_HISTORY_COLUMNS();
    const tableColumns = _.get(
      selectedDashboard,
      ['table', 'columns'],
      getDefaultColumns(isNonBrand).join(','),
    );
    _.forEach(tableColumns.split(','), columnId => {
      const match = _.find(allColumns, ['id', columnId]);
      if (match) {
        columns.push({
          label: match.header,
          field: match.fieldForExport || match.accessor,
        });
      }
    });
    return this.dashboardService
      .exportScanHistoryTable(brandType, query, type, filters, sort, columns)
      .then(res => {
        if (!res.result) {
          this.props.alertError(
            _.get(res, ['err', 'message']) + '. ' + type.toUpperCase() + ' cannot be downloaded!',
          );
        } else if (!isMobile()) {
          this.props.alertSuccess(type.toUpperCase() + ' has been downloaded!');
        }
      })
      .catch(() => {
        this.props.alertError(type.toUpperCase() + ' cannot be downloaded!');
      });
  };

  onTablePageChange = (num: number) => {
    this.tablePageNumber = num;
  };

  onTableSaveColumns = (columns: string[]) => {
    const { selectedDashboard, getDashboardConfig, alertSuccess, alertError } = this.props;
    this.dashboardService
      .updateTableColumns(selectedDashboard.table!.name, columns.join(','))
      .then(() => {
        alertSuccess('Update columns succeed');
        getDashboardConfig(selectedDashboard.name);
      })
      .catch((err: any) => {
        alertError(err);
      });
  };

  setSourceColumnOptions = (brandName: string) => {
    const option = { label: capitalize(brandName), value: brandName };
    if (SOURCE_COLUMN.filterOptions[1]) {
      SOURCE_COLUMN.filterOptions[1] = option;
    } else {
      SOURCE_COLUMN.filterOptions.push(option);
    }
  };

  static getDerivedStateFromProps = (nextProps: Props, state: IDashboardState) => {
    let { currentBreakpoint, isEditingMode } = state;
    isEditingMode = isEditingMode || nextProps.selectedDashboard.isNew || false;
    const layouts = {
      [currentBreakpoint]: calculateLayout(nextProps.selectedDashboard.widgets),
    };
    return {
      layouts,
      isEditingMode: isEditingMode,
    };
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.selectedDashboard.id !== this.props.selectedDashboard.id) {
      this.setCompState({
        isEditingMode: false,
      });
    }
    if (this.props.selectedDashboard.isNew) {
      this.resetOriginalDashboard();
    }
    // get brand name then set it in source column filter.
    if (
      !_.isEmpty(this.props.user.email) &&
      !_.isEmpty(this.props.selectedDashboard.table) &&
      !_.isEqual(this.props.selectedDashboard.table, prevProps.selectedDashboard.table) &&
      this.props.selectedDashboard.dashboardType === 'brand'
    ) {
      this.dashboardService.getBrandInfo('brand').then((info: any) => {
        this.setState({ brandInfo: info });
        this.setSourceColumnOptions(info.brand.brandName);
      });
    }

    this.tableSort.sortBy = this.adjustTableSortBy(this.tableSort.sortBy);
  };

  renderHeader = () => {
    const { selectedDashboard } = this.props;
    const { isEditingMode, startDate, endDate } = this.state;
    const DASHBOARD_ACTION_OPTIONS: IDropdownOption[] = [
      {
        label: 'Edit dashboard',
        value: 'edit',
      },
      {
        label: 'Set as Home dashboard',
        value: 'setDefault',
      },
      {
        label: 'Delete dashboard',
        value: 'delete',
      },
    ];

    if (selectedDashboard.isDefault) {
      DASHBOARD_ACTION_OPTIONS.splice(1, 1);
    }

    if (isEditingMode) {
      return (
        <div
          className={
            'dashboard-page-editing-header d-flex align-items-center justify-content-space-between'
          }
        >
          <input
            key={selectedDashboard.id}
            ref={r => (this.dashboardNameInput = r)}
            className={'dashboard-name-input'}
            placeholder={'Dashboard Name'}
            defaultValue={selectedDashboard.label}
          />
          <div className={'dashboard-page-editing-header-buttons d-flex align-items-center'}>
            <div
              className={'add-section-button d-flex align-items-center'}
              onClick={this.toggleSectionPicker}
            >
              <img src={AddWhite} alt={'Add'} />
              Add Section
            </div>
            <Button variant='outline-light' onClick={this.cancelEditMode}>
              Cancel
            </Button>
            <Button
              variant='light'
              onClick={this.saveEditMode}
              disabled={
                selectedDashboard.widgets.length === 0 &&
                !selectedDashboard.table &&
                !selectedDashboard.monitoring
              }
            >
              Save
            </Button>
          </div>
        </div>
      );
    }
    return (
      <PageTitle
        title={selectedDashboard.label}
        className={'dashboard-page-title'}
        tools={
          <div className={'d-flex align-items-center'}>
            <DropdownDateRangePicker
              key={'DropdownDateRangePicker'}
              startDate={startDate}
              endDate={endDate}
              onChange={this.onDateChange}
            />
            <Dropdown
              options={DASHBOARD_ACTION_OPTIONS}
              onChange={this.onDashboardActionChange}
              btnClassName={'dashboard-action-dropdown web-only'}
              hideArrow
              fixedPlaceholder={<img src={DotDotDot} alt={'email sent'} />}
            />
          </div>
        }
      />
    );
  };

  renderMonitoring = () => {
    const { selectedDashboard } = this.props;
    const { isEditingMode, startDate, endDate } = this.state;
    let dom;
    if (!_.isEmpty(selectedDashboard.monitoring)) {
      dom = (
        <Card className={'monitoring-container'}>
          <DashboardMonitoring
            brandType={selectedDashboard.dashboardType}
            startDate={startDate}
            endDate={endDate}
          />
          <HideButton shown={isEditingMode} color={'white'} onClick={this.toggleMonitoring} />
        </Card>
      );
    }
    return dom;
  };

  renderSectionPicker = () => {
    const { sectionPickerShown } = this.state;
    const { selectedDashboard } = this.props;

    return (
      <Modal
        className={'widgets-picker-modal'}
        show={sectionPickerShown}
        centered
        size='lg'
        onHide={this.toggleSectionPicker}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'dashboard-modal-header-title'}>Add Section</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'section-picker-modal-body'}>
          <DashboardAddSection
            hasAdded={!!selectedDashboard.monitoring}
            image={MonitoringPic}
            title={'High-level Statistics Bar'}
            onClick={this.toggleMonitoring}
          />
          <DashboardAddSection
            image={WidgetsPic}
            title={'Data Visualization Widgets'}
            onClick={this.toggleWidgetsPicker}
          />
          <DashboardAddSection
            hasAdded={!!selectedDashboard.table}
            imgBorder
            image={TablePic}
            title={'Counterfeit Sites Table'}
            onClick={this.toggleTable}
          />
        </Modal.Body>
      </Modal>
    );
  };

  renderWidgetsPickerModalBody = (limit: number = ALL_WIDGETS.length, text = 'Add') => {
    const { selectedDashboard } = this.props;
    const { selectedWidgets } = this.state;
    return (
      <Modal.Body className={'widgets-picker-modal-body'}>
        <div className={'widgets-picker-contents'}>
          {_.map(ALL_WIDGETS, widget => {
            if (
              widget.widgetType &&
              widget.widgetType.indexOf(selectedDashboard.dashboardType) === -1
            ) {
              return null;
            }
            const isSelected = selectedWidgets.indexOf(widget.id) > -1;
            return (
              <div key={widget.id} className='widget-picker-candidate'>
                <div className='widget-picker-candidate-inner'>
                  <img src={widget.image} alt={'Widget Thumbnail'} />
                  <div className='candidate-info'>
                    <div className='candidate-title'>{widget.title}</div>
                    <div className='candidate-description'>{widget.description}</div>
                    <Button
                      disabled={selectedWidgets.length >= limit && !isSelected}
                      variant={'outline-secondary'}
                      className={isSelected ? 'widget-selected' : 'widget-add'}
                      onClick={() => {
                        this.onWidgetSelected(widget.id, isSelected);
                      }}
                    >
                      {isSelected && <img src={CheckCircleGreenSmall} alt={'Widget Added'} />}
                      {isSelected ? 'Selected' : text}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
    );
  };

  renderWidgetsPicker = () => {
    const { widgetsPickerShown, selectedWidgets } = this.state;
    const count = selectedWidgets.length;
    const isValid = count === 2;

    return (
      <Modal
        className={'widgets-picker-modal'}
        show={widgetsPickerShown}
        size='lg'
        onHide={this.toggleWidgetsPicker}
        centered
      >
        <Modal.Header>
          <Modal.Title className={'dashboard-modal-header-title'}>
            <div className={'dashboard-modal-header-main'}>Add Data Visualization Widgets</div>
            <div className={'dashboard-modal-header-selection-count'}>
              {' '}
              {`${count} of 2 selected`}
            </div>
          </Modal.Title>
          <div className={'dashboard-modal-header-buttons'}>
            <Button variant='outline-secondary' onClick={this.toggleWidgetsPicker}>
              Cancel
            </Button>
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                isValid ? (
                  <div />
                ) : (
                  <Tooltip id={'tooltip-add-widgets'} className={'table-source-url-tooltip'}>
                    Please select 2 widgets
                  </Tooltip>
                )
              }
            >
              <span className='d-inline-block button'>
                <Button
                  disabled={!isValid}
                  style={{ pointerEvents: isValid ? 'auto' : 'none' }}
                  onClick={this.addWidgetSection}
                >
                  Add Widgets
                </Button>
              </span>
            </OverlayTrigger>
          </div>
        </Modal.Header>
        {this.renderWidgetsPickerModalBody(2)}
      </Modal>
    );
  };

  renderReplaceWidgetPicker = () => {
    const { replaceWidgetPickerShown, selectedWidgets } = this.state;
    const count = selectedWidgets.length;

    return (
      <Modal
        className={'widgets-picker-modal'}
        show={replaceWidgetPickerShown}
        size='lg'
        onHide={this.toggleReplaceWidgetsPicker}
        centered
      >
        <Modal.Header>
          <Modal.Title className={'dashboard-modal-header-title'}>
            <div className={'dashboard-modal-header-main'}>Replace Widget</div>
          </Modal.Title>
          <div className={'dashboard-modal-header-buttons'}>
            <Button variant='outline-secondary' onClick={this.toggleReplaceWidgetsPicker}>
              Cancel
            </Button>
            <Button variant='primary' disabled={count !== 1} onClick={this.replaceWidget}>
              Replace Widget
            </Button>
          </div>
        </Modal.Header>
        {this.renderWidgetsPickerModalBody(1, 'Select')}
      </Modal>
    );
  };

  renderWidgets = () => {
    const { selectedDashboard } = this.props;
    const { isEditingMode } = this.state;
    const widgets: DashBoardWidgetConfigDto[] = selectedDashboard.widgets;
    const widgetDoms = [];

    for (let i = 0; i < widgets.length; i = i + 2) {
      const section = [widgets[i], widgets[i + 1]];
      if (isEditingMode) {
        widgetDoms.push(
          <Card key={'widget-section' + i} className={'widget-section-container'}>
            <HideButton
              shown={isEditingMode}
              className={'wedget-section-hide-button'}
              onClick={this.removeWidgetSection.bind(this, section)}
            />
            {this.generateWidgetSection(section, i)}
          </Card>,
        );
      } else {
        widgetDoms.push(this.generateWidgetSection(section, i));
      }
    }
    return widgetDoms;
  };

  renderTable = () => {
    const { selectedDashboard } = this.props;
    const { isEditingMode, startDate, endDate } = this.state;
    let dom = null;
    const isNonBrand = selectedDashboard.dashboardType === 'non_brand';
    const columns = isNonBrand
      ? SCAN_HISTORY_COLUMNS_NON_BRAND(isEditingMode, true)
      : SCAN_HISTORY_COLUMNS(isEditingMode);

    let sortBy = _.get(this, ['tableSort', 'sortBy'], isNonBrand ? 'createdTs' : 'lastScanned');
    sortBy = this.adjustTableSortBy(sortBy);

    if (selectedDashboard.table) {
      dom = (
        <Card className={'dashboard-table-container'}>
          <HideButton
            shown={isEditingMode}
            className={'table-hide-button'}
            color={'grey'}
            onClick={this.toggleTable}
          />
          <Table
            loadingWrapperClassName={'dashboard-table-loading-wrapper'}
            disableDatePicker
            title={'Counterfeit Sites'}
            titleTooltip={
              'List of all detected counterfeit sites in the selected time-frame and filters'
            }
            fetchApi={this.getScanHistory}
            disableFilter={isEditingMode}
            filterAppliedOnFetchApi
            initialFilters={this.tableFilters}
            exportTableApi={isEditingMode ? undefined : this.exportScanHistory}
            columns={columns}
            displayedColumnsIDs={
              selectedDashboard.table.columns
                ? selectedDashboard.table.columns.split(',')
                : getDefaultColumns(isNonBrand)
            }
            sortBy={sortBy}
            sortDirection={_.get(this, ['tableSort', 'sortDirection'], 'desc')}
            startDate={startDate}
            endDate={endDate}
            tileComp={!isEditingMode && DashboardTile}
            tileCompProperties={getTileCompProperties(isNonBrand)}
            initialPageNumber={this.tablePageNumber}
            onPageChange={this.onTablePageChange}
            enableEditColumns={!isEditingMode}
            onSaveColumns={this.onTableSaveColumns}
          />
        </Card>
      );
    }
    return dom;
  };

  render() {
    const { user, selectedDashboard } = this.props;
    const { isEditingMode, deleteModalShown } = this.state;

    if (_.isEmpty(user.email)) {
      return <AuthenticationWrapper />;
    }

    const isWebModuleAvailable = getIsWebModuleAvailable(user);
    if (!isWebModuleAvailable) {
      return <ModulePermissionTeaserPage featureName='Web' />;
    }

    return (
      <AuthenticationWrapper>
        <Modal show={deleteModalShown} size='sm' onHide={this.toggleDeleteDashboardModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Dashboard</Modal.Title>
          </Modal.Header>
          <Modal.Body className={'modal-body'}>
            {`Are you sure you want to delete ${selectedDashboard.label}?`}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-secondary' onClick={this.toggleDeleteDashboardModal}>
              Cancel
            </Button>
            <Button variant='danger' onClick={this.onDeleteDashboard}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {this.renderSectionPicker()}
        {this.renderWidgetsPicker()}
        {this.renderReplaceWidgetPicker()}
        {this.renderHeader()}
        <div className={'page-content dashboard-page'}>
          {isEditingMode &&
            selectedDashboard.widgets.length === 0 &&
            !selectedDashboard.table &&
            !selectedDashboard.monitoring && (
              <div className='zero-section-help-text'>
                Please add a section before saving a new dashboard.
              </div>
            )}
          {this.renderMonitoring()}
          {this.renderWidgets()}
          {this.renderTable()}
          {isEditingMode && (
            <Card className='add-section-component no-border-or-background'>
              <div className='widget-item-inner add-new-widget-component'>
                <img src={AddWidgetIcon} alt={'Add Widget'} />
                <Button variant='primary' onClick={this.toggleSectionPicker}>
                  Add Section
                </Button>
              </div>
            </Card>
          )}
        </div>
      </AuthenticationWrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user, dashboardConfig, selectedDashboard } = state.dashboardReducer;
  return {
    user,
    dashboardConfig,
    selectedDashboard,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  getDashboardConfig: dashboardActions.getDashboardConfig,
  onDeleteDashboard: dashboardActions.onDeleteDashboard,
  onSetDefaultDashboard: dashboardActions.onSetDefaultDashboard,
  onChangeDashboard: dashboardActions.onChangeDashboard,
  onSaveDashboard: dashboardActions.onSaveDashboard,
  onDeleteWidgets: dashboardActions.onDeleteWidgets,
};

const connectedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { connectedDashboard as Dashboard };
