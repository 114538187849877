import React, { useState } from 'react';
import DashboardService from '../../services/dashboard.service';

import { IFilter, TExportTable } from '../Common/Table/constant';
import { MaliciousComponent } from './malicious.component';
import {
  SOURCE_URL_COLUMN,
  IP_COLUMN,
  BRAND_COLUMN,
  PAST_DISPOSITION_COLUMN,
  CURRENT_DISPOSITION_COLUMN,
  DISPOSITION_CHANGE_TIMESTAMP_COLUMN,
  DISPUTE_STATUS_COLUMN,
  SCAN_SOURCE_COLUMN,
  TAKEDOWN_TIME_COLUMN,
  USER_REQUESTED_TAKEDOWN_COLUMN,
  FIRST_SEEN_COLUMN,
  LAST_SCANNED_COLUMN,
  HOSTING_COLUMN,
  LOGO_DETECTION_COLUMN,
  TLD_COLUMN,
  COUNTRY_COLUMN,
  PAST_PHISH_ON_HOST_COLUMN,
  PAST_PHISH_ON_IP_COLUMN,
  CUSTOMER_SCANS_COLUMN,
  BOLSTER_SCANS_COLUMN,
  REGISTRATION_DATE_COLUMN,
  CATEGORY_COLUMN,
  MX_RECORDS_COLUMN,
  SFB_DETECTED_COLUMN,
  SEARCH_ENGINE_COLUMN,
  REGISTRAR_COLUMN,
  DOMAIN_COLUMN,
  REDIRECTED_URL_COL,
  SCAN_SOURCE_CATEGORY_COLUMN,
  LAST_UPDATED_COLUMN,
  DISPUTE_INITIATED,
} from './ColDefinition/malicious.columns';

import {
  EMatFetchTypes,
  IMatFetchOptions,
  IMatQuery,
  IMatSort,
  POST_MALICIOUS_TABLE_ID,
  mapTableIdToEnum,
} from './constants';
import { TableTile } from '../../components/Common/Table/table.tile';
import {
  appConstants,
  featureIsAvailable,
  getLocalStorageValue,
  IBrandInfo,
  IDashboardDispatchProps,
  makeDefaultCol,
} from '../../constants';
import { AppState } from '../../helpers';
import { connect } from 'react-redux';
import { dashboardActions } from '../../actions';
import { ExportScanHistoryModal } from './ExportScanHistoryModal';
import { ExportScanDetails } from './types/export-scan-details.interface';
import { Nullable } from '../../types/common';
import { transformWebTableFilterToApiParams } from '../Common/Table/ag-table/ag-filter-to-api-params';
import { fetchWebTableData } from '../Common/Table/ag-table/ag-requests';
import { useAppSelector } from '../../helpers/hooks';

const COLUMNS = [
  BRAND_COLUMN,
  SOURCE_URL_COLUMN(POST_MALICIOUS_TABLE_ID.replace('_', '-')),
  IP_COLUMN(POST_MALICIOUS_TABLE_ID.replace('_', '-')),
  PAST_DISPOSITION_COLUMN,
  DISPUTE_INITIATED,
  CATEGORY_COLUMN,
  TAKEDOWN_TIME_COLUMN,
  REGISTRATION_DATE_COLUMN,
  FIRST_SEEN_COLUMN,
  HOSTING_COLUMN,
  LOGO_DETECTION_COLUMN,
  MX_RECORDS_COLUMN,
  COUNTRY_COLUMN,
  CURRENT_DISPOSITION_COLUMN,
  DISPOSITION_CHANGE_TIMESTAMP_COLUMN,
  LAST_SCANNED_COLUMN,
  SCAN_SOURCE_COLUMN,
  PAST_PHISH_ON_HOST_COLUMN,
  PAST_PHISH_ON_IP_COLUMN,
  TLD_COLUMN,
  BOLSTER_SCANS_COLUMN,
  CUSTOMER_SCANS_COLUMN,
  DISPUTE_STATUS_COLUMN,
  USER_REQUESTED_TAKEDOWN_COLUMN,
  SFB_DETECTED_COLUMN,
  SEARCH_ENGINE_COLUMN,
  REGISTRAR_COLUMN,
  { ...DOMAIN_COLUMN, accessor: 'primary_domain' },
  REDIRECTED_URL_COL,
  SCAN_SOURCE_CATEGORY_COLUMN,
  LAST_UPDATED_COLUMN,
];

const dashboardService = new DashboardService();

interface IPostMaliciousProps {
  brandInfo: IBrandInfo;
  currentFetchOptions: IMatFetchOptions;
}

const PostMalicious = (
  props: IPostMaliciousProps & Pick<IDashboardDispatchProps, 'setCurrentFetchOptions'>,
) => {
  window.document.title = 'Monitor Post-Malicious | Web';
  const DEFAULT_COLUMN_IDS = [
    'src_url',
    'first_seen_ts',
    'takedown_ts',
    'last_updated_ts',
    'current_disposition',
    'dispositions',
    'takedowns',
    'final_category',
    'matrix_algorithm',
    'has_mx_records',
    'brand_logo_detected',
    'ip',
    'as_description',
    'sourceFeed',
    'tags',
  ];

  const { brandInfo, setCurrentFetchOptions } = props;
  const [exportQuery, setExportQuery] = useState<Nullable<ExportScanDetails>>(null);
  const user = useAppSelector(state => state.dashboardReducer.user);
  const isWebTablesGroupView = useAppSelector(state => state.tableReducer.isWebTablesGroupView);

  const isOldTable = !user || !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);
  if (brandInfo?.brand?.brandMapping) {
    makeDefaultCol(DEFAULT_COLUMN_IDS, 'sub_brand_id', brandInfo?.brand?.brandMapping);
  }

  const fetchForExport = async (pageNumber: number, pageSize: number) => {
    //For new table
    if (!isOldTable) {
      const agGridFilter = getLocalStorageValue(['tableFilterModel', POST_MALICIOUS_TABLE_ID]);
      const convertedFilterFromAgGrid = transformWebTableFilterToApiParams(agGridFilter);
      const { sort } = props.currentFetchOptions;
      const { must, mustNot, query } = convertedFilterFromAgGrid;
      const apiParams = {
        must,
        mustNot,
        query: { ...query, pageNumber, pageSize, type: POST_MALICIOUS_TABLE_ID },
        sortBy: sort,
      };
      const res = await fetchWebTableData(apiParams, isWebTablesGroupView);
      const response = { data: [], total: 0 };
      response.data = res.result?.urlInfo?.map((item: any) => {
        return dashboardService.massageDataCommon(item, mapTableIdToEnum[POST_MALICIOUS_TABLE_ID]);
      });
      response.total = res.result?.total;
      return response;
    } else {
      const { filters, sort, query } = props.currentFetchOptions;
      const options = {
        type: EMatFetchTypes.POST_MALICIOUS,
        filters: filters,
        sort: sort,
        query: {
          ...query,
          isGroupViewEnabled: isWebTablesGroupView,
          pageNumber,
          pageSize,
        },
      };
      const res = await dashboardService.getMassagedMatData(options);
      return res;
    }
  };
  const fetchApi = (query: IMatQuery, filters: IFilter[], sort: IMatSort) => {
    const options = {
      type: EMatFetchTypes.POST_MALICIOUS,
      filters,
      sort,
      query,
    };
    setCurrentFetchOptions(options);
    return dashboardService.getMassagedMatData(options);
  };

  const exportApi = (
    query: any,
    filters: IFilter[],
    sort = undefined,
    type: TExportTable,
    columns: any[],
  ) => {
    query.type = POST_MALICIOUS_TABLE_ID;
    setExportQuery({
      fetchType: EMatFetchTypes.POST_MALICIOUS,
      query,
      filters,
      sort,
      columns,
      fileName: 'Bolster_Takedowns_Completed',
    });
  };

  return (
    <React.Fragment>
      <MaliciousComponent
        {...props}
        type={appConstants.CONTENT_TYPE.WEB}
        id={POST_MALICIOUS_TABLE_ID}
        title='Monitor Post-Malicious'
        columns={COLUMNS}
        tileComp={TableTile}
        fetchApi={fetchApi}
        exportApi={exportApi}
        exportOptions={[{ label: 'CSV', value: 'csv' }]}
        tableIndex={'brand_analytics'}
        tileTopThreeProps={['src_url', 'ip']}
        defaultColumnIds={DEFAULT_COLUMN_IDS}
        tableType={EMatFetchTypes.POST_MALICIOUS}
      />
      <ExportScanHistoryModal
        show={exportQuery != null}
        details={exportQuery}
        onComplete={() => setExportQuery(null)}
        onCancel={() => setExportQuery(null)}
        fetchForExport={fetchForExport}
        type={appConstants.CONTENT_TYPE.WEB}
        tableId={POST_MALICIOUS_TABLE_ID}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  const { currentFetchOptions } = state.dashboardReducer;
  return {
    currentFetchOptions,
  };
};
const mapDispatchToProps = {
  setCurrentFetchOptions: dashboardActions.setCurrentFetchOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMalicious);
