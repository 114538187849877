import React from 'react';
import { ITableApiColumn } from '../Common/Table/table.api';
import { Link } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { getDisplayTimeFromTimeStamp } from '../../constants';
import {
  DISPOSITION_FILTER_OPTIONS,
  CATEGORY_FILTER_OPTIONS,
} from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import { DispositionStatus } from '../Common/DispositionStatus';
import { numberRangeColumnValidator } from '../../constants';
import Country from '../Common/Country';
import './typosquat.scss';
import { TYPOSQUATTING_PATHNAME } from './constant';
import { Risk } from '../Common/Risk';
import ColorDot from './Dashboard_v2/widgets/common/ColorDot';
import { history } from '../../helpers';
import {
  CHANGE_TYPES,
  DeltaChangeFromItem,
  WhatsGoneItem,
} from '../../types/typosquat-dashboard.interface';
import AgGridDateRangeFilter from '../Common/Table/ag-table/AgGridDateRangeFilter';

export const TAKEDOWN_TIME_COLUMN: ITableApiColumn = {
  id: 'takedown_ts',
  accessor: 'takedown_ts',
  header: 'Takedown Time',
  headerTooltip: 'Time of Taken down',
  type: 'date',
  isLongText: true,
  filterDisabled: true,
  render: (data: any) => {
    return _.isEmpty(data.takedown_ts)
      ? '--'
      : getDisplayTimeFromTimeStamp(data.takedown_ts, 'DD-MMM-YYYY, h:mm a');
  },
};

export const USER_REQUESTED_TAKEDOWN_COLUMN: ITableApiColumn = {
  id: 'User Requested Takedown',
  header: 'User Requested Takedown',
  headerTooltip: 'User Requested Takedown',
  accessor: 'userRequestedTakedown',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  hidden: true,
};

export const LAST_SCANNED_COLUMN: ITableApiColumn = {
  id: 'created_ts',
  accessor: 'created_ts',
  header: 'Last Scanned',
  headerTooltip: 'Last Scanned',
  isLongText: true,
  filterDisabled: true,
  isDefaultSort: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.created_ts);
  },
};

export const HOSTING_COLUMN: ITableApiColumn = {
  id: 'as_description',
  accessor: 'networkOwner',
  header: 'Hosting Provider',
  headerTooltip: 'Entity that hosts the site and to which Bolster directs takedown requests',
  fieldForExport: 'as_description',
  isLongText: true,
};

export const LOGO_DETECTION_COLUMN: ITableApiColumn = {
  id: 'brand_logo_detected',
  accessor: 'brand_logo_detected',
  fieldForExport: 'brand_logo_detected',
  header: 'Logo Detected',
  headerTooltip: 'Logo Detected',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  render: (data: any) => {
    return data?.brand_logo_detected ? 'True' : 'False';
  },
};

export const PAST_PHISH_ON_HOST_COLUMN: ITableApiColumn = {
  id: 'host_phish_count',
  accessor: 'host_phish_count',
  fieldForExport: 'pastPhishCountOnHost',
  header: 'Past Phish on Host',
  headerTooltip: 'Past Phish on Host',
  type: 'number',
  filterDisabled: true,
  sortDisabled: true,
};

export const PAST_PHISH_ON_IP_COLUMN: ITableApiColumn = {
  id: 'ip_phish_count',
  accessor: 'ip_phish_count',
  fieldForExport: 'pastPhishCountOnIP',
  header: 'Past Phish on IP',
  headerTooltip: 'Past Phish on IP',
  type: 'number',
  filterDisabled: true,
  sortDisabled: true,
};

export const CUSTOMER_SCANS_COLUMN: ITableApiColumn = {
  id: 'brand_scan_count',
  accessor: 'brand_scan_count',
  header: '# Customer Scans',
  headerTooltip: 'Number of Customer Scans',
  type: 'number',
};

export const BOLSTER_SCANS_COLUMN: ITableApiColumn = {
  id: 'bolster_scan_count',
  accessor: 'bolster_scan_count',
  header: '# Bolster Scans',
  headerTooltip: 'Number of Bolster Scans',
  type: 'number',
};

export const REGISTRATION_DATE_COLUMN: ITableApiColumn = {
  id: 'domain_registration_date',
  accessor: 'domain_registration_date',
  headerTooltip: 'Registration Date',
  header: 'Registration Date',
  isLongText: true,
  filterDisabled: true,
  type: 'date',
  agFilterType: 'agDateColumnFilter',
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.domain_registration_date);
  },
};

export const TLD_COLUMN: ITableApiColumn = {
  id: 'TLD',
  header: 'TLD',
  headerTooltip: 'Top Level Domain',
  accessor: 'domain_tld',
  agFilterType: 'agTextColumnFilter',
  render: ({ domain_tld }: any) => {
    return `${domain_tld}`;
  },
};

export const COUNTRY_COLUMN: ITableApiColumn = {
  id: 'country_code',
  accessor: 'countryCode',
  header: 'Country',
  headerTooltip: 'Country Code',
  fieldForExport: 'country_code',
  render: (data: any) => {
    return <Country countryCode={data.countryCode} />;
  },
};

export const FIRST_SEEN_COLUMN: ITableApiColumn = {
  id: 'first_seen_ts',
  accessor: 'first_seen_ts',
  header: 'First Seen',
  headerTooltip: 'Time of First Scan',
  type: 'date',
  isLongText: true,
  filterDisabled: true,
  isDefaultSort: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.first_seen_ts);
  },
};

export const CURRENT_DISPOSITION_COLUMN: ITableApiColumn = {
  id: 'current_disposition',
  accessor: 'current_disposition',
  fieldForExport: 'current_disposition',
  header: 'Current Disposition',
  headerTooltip: 'Disposition when Site was Last Scanned',
  type: 'options',
  filterOptions: DISPOSITION_FILTER_OPTIONS,
  render: (data: any) => {
    const { current_disposition } = data;
    if (_.isEmpty(current_disposition)) {
      return '--';
    }
    return <DispositionStatus className='domain-monitoring-wrapper' status={current_disposition} />;
  },
};

export const LIVE_SCAN_VERDICT_COLUMN: ITableApiColumn = {
  id: 'live_scan_verdict',
  accessor: 'live_scan_verdict',
  fieldForExport: 'live_scan_verdict',
  header: 'Live Scan Verdict',
  type: 'options',
  agFilterType: 'agSetColumnFilter',
  filterOptions: DISPOSITION_FILTER_OPTIONS,
  render: (data: any) => {
    const { live_scan_verdict } = data;
    if (_.isEmpty(live_scan_verdict)) {
      return '--';
    }

    return (
      <DispositionStatus
        className='domain-monitoring-wrapper'
        displayedStyle={'pill'}
        status={live_scan_verdict}
      />
    );
  },
};

export const TAKEDOWN_ENQUIRY_COLUMN: ITableApiColumn = {
  id: 'takedown_enquiry',
  header: 'Takedown Inquiry',
  accessor: 'takedown_enquiry',
  type: 'options',
  filterOptions: [
    {
      label: 'Contacted',
      value: 'true',
    },
  ],
  agFilterType: 'agSetColumnFilter',
};

export const DISPOSITION_CHANGE_TIMESTAMP_COLUMN: ITableApiColumn = {
  id: 'current_disposition_ts',
  header: 'Disposition Change',
  accessor: 'current_disposition_ts',
  filterDisabled: true,
  type: 'date',
  isLongText: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.current_disposition_ts);
  },
};

export const DISPUTE_STATUS_COLUMN: ITableApiColumn = {
  id: 'Dispute Status',
  header: 'Dispute Status',
  headerTooltip: 'Dispute Status',
  accessor: 'disputeStatus',
  type: 'options',
  filterOptions: [
    { label: 'Never Disputed', value: 'none' },
    { label: 'Under Dispute', value: 'under_review' },
    { label: 'Dispute Resolved', value: 'accepted|rejected' },
  ],
  optionsOrdersPersist: true,
  hidden: true,
};

export const SCAN_SOURCE_COLUMN: ITableApiColumn = {
  id: 'scan_source',
  accessor: 'scanSource',
  fieldForExport: 'scan_source',
  header: 'Source',
  headerTooltip: 'Initiating entity of scan',
  type: 'options',
  filterOptions: [{ label: 'Bolster', value: 'bolster' }],
};

export const TAKEDOWNS_COLUMN: ITableApiColumn = {
  id: 'takedowns',
  accessor: 'takedown_enquiry',
  header: 'Takedown Requests',
  headerTooltip: 'Number of issued takedown requests',
  columnClassName: 'hide-below-md',
  type: 'number',
  validator: numberRangeColumnValidator,
  render: ({ takedown_enquiry }: any) => (
    <div className='takedown-enqueries'>{takedown_enquiry}</div>
  ),
};

export const SOURCE_URL_COLUMN: ITableApiColumn = {
  id: 'src_url',
  accessor: 'src_url',
  fieldForExport: 'src_url',
  header: 'Source URL',
  headerTooltip: 'Link to URL Details View',
  render: (data: any) => {
    const { src_url, url_sha, timestamp, initialPageNumber, filters } = data;
    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + src_url} className={'table-source-url-tooltip'}>
            {src_url}
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          <Link
            to={{
              pathname: `${TYPOSQUATTING_PATHNAME}/insights/${timestamp}/${url_sha}`,
              state: {
                prevPath: window.location.pathname,
                initialPageNumber,
                filters,
              },
            }}
          >
            {src_url}
          </Link>
        </div>
      </OverlayTrigger>
    );
  },
  hiddenInDragDrop: true,
};

export const buildSourceUrlPermutationColumn: (hideInsight: boolean) => ITableApiColumn = (
  hideInsight = false,
) => ({
  id: 'domain',
  accessor: 'domain',
  fieldForExport: 'domain',
  header: 'Source URL',
  headerTooltip: 'Link to URL Details View',
  agFilterType: 'agTextColumnFilter',
  valueGetter: ({ domain }) => {
    return domain;
  },
  render: (data: any) => {
    const { domain, status, timestamp, url_sha, initialPageNumber, filters, disposition } = data;
    const activeInsight = !hideInsight ? status === 'monitored' && disposition : false;

    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + domain} className={'table-source-url-tooltip'}>
            {domain}
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          {activeInsight ? (
            <Link
              to={{
                pathname: `${TYPOSQUATTING_PATHNAME}/insights/${timestamp}/${url_sha}`,
                state: {
                  prevPath: window.location.pathname,
                  initialPageNumber,
                  filters,
                },
              }}
            >
              {domain}
            </Link>
          ) : (
            <div className='disabled-link'>{domain}</div>
          )}
        </div>
      </OverlayTrigger>
    );
  },
});

export const NAMESERVERS_COLUMN: ITableApiColumn = {
  id: 'nameserver',
  header: 'Name Servers',
  headerTooltip: 'Name Servers',
  accessor: 'nameserver',
  agFilterType: 'agTextColumnFilter',
  render: (data: any) => {
    const { nameserver } = data;
    if (_.isEmpty(nameserver)) {
      return '--';
    }
    return nameserver;
  },
};

export const MAILSERVERS_COLUMN: ITableApiColumn = {
  id: 'mailserver',
  header: 'Mail Servers',
  headerTooltip: 'Name Servers',
  accessor: 'mailserver',
  agFilterType: 'agTextColumnFilter',
  render: (data: any) => {
    const { mailserver } = data;
    if (_.isEmpty(mailserver)) {
      return '--';
    }
    return mailserver;
  },
};

export const RISK_COLUMN: ITableApiColumn = {
  id: 'risk_score',
  accessor: 'risk_score',
  header: 'Risk',
  headerTooltip: 'Risk',
  type: 'number',
  agFilterType: 'agNumberColumnFilter',
  render: (data: any) => {
    if (data.risk_score) {
      return <Risk risk={data.risk_score} trending={data.risk_trending} />;
    }
    return '--';
  },
};

export const IP_COLUMN: ITableApiColumn = {
  id: 'ip_data',
  accessor: 'ip_data',
  fieldForExport: 'ip_data',
  header: 'IP Address',
  headerTooltip: 'Link to IP Details View',
  type: 'ipv4',
  render: (data: any) => {
    const { ip_data, initialPageNumber, filters } = data;
    const ip = ip_data.split(' ')[0];
    if (_.isEmpty(ip) || ip === '0.0.0.0') {
      return '--';
    }
    return (
      <div className={'table-ip-column'}>
        <Link
          to={{
            pathname: `${TYPOSQUATTING_PATHNAME}/ip/${ip}`,
            state: {
              prevPath: window.location.pathname,
              initialPageNumber,
              filters,
            },
          }}
        >
          {ip}
        </Link>
      </div>
    );
  },
};

export const buildIpAddressColumn: (hideInsight: boolean) => ITableApiColumn = (
  hideInsight = false,
) => ({
  id: 'ip_data',
  accessor: 'ip_data',
  fieldForExport: 'ip_data',
  header: 'IP Address',
  headerTooltip: 'Link to IP Details View',
  type: 'ipv4',
  agFilterType: 'agTextColumnFilter',
  render: (data: any) => {
    const { ip_data, status, initialPageNumber, filters } = data;
    const activeInsight = !hideInsight ? status === 'monitored' : false;
    const ip = ip_data.split(' ')[0];
    if (_.isEmpty(ip) || ip === '0.0.0.0') {
      return '--';
    }
    return (
      <div className={'table-ip-column'}>
        {activeInsight ? (
          <Link
            to={{
              pathname: `${TYPOSQUATTING_PATHNAME}/ip/${ip}`,
              state: {
                prevPath: window.location.pathname,
                initialPageNumber,
                filters,
              },
            }}
          >
            {ip}
          </Link>
        ) : (
          <div className='disabled-link'>{ip}</div>
        )}
      </div>
    );
  },
});

export const CATEGORY_COLUMN: ITableApiColumn = {
  id: 'final_category',
  accessor: 'final_category',
  header: 'Category',
  headerTooltip: 'Category',
  isLongText: true,
  type: 'options',
  columnClassName: 'typo_category_column',
  filterOptions: CATEGORY_FILTER_OPTIONS,
  agFilterType: 'agSetColumnFilter',
  render: data => {
    const OPTION = CATEGORY_FILTER_OPTIONS.filter(option => {
      if (!data.final_category) {
        return option.value === 'unknown';
      } else {
        return option.value === data.final_category;
      }
    });
    const label: string = OPTION[0]?.label || '';
    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + label} className={'table-source-url-tooltip'}>
            {label}
          </Tooltip>
        }
      >
        <div>
          <div className='table-long-text long-text-ellipsis-1'>{label}</div>
        </div>
      </OverlayTrigger>
    );
  },
};

export const URL_CONSTRUCTION_COLUMN: ITableApiColumn = {
  id: 'matrix_algorithm',
  accessor: 'matrix_algorithm',
  header: 'URL Construction',
  headerTooltip: 'URL Construction',
  type: 'options',
  filterOptions: [],
  agFilterType: 'agSetColumnFilter',
};

export const REGISTRAR_COLUMN: ITableApiColumn = {
  id: 'registrar',
  accessor: 'registrar',
  header: 'Registrar',
  headerTooltip: 'Registrar',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
  render: ({ registrar }) => {
    if (!registrar) {
      return '--';
    }
    return registrar;
  },
};

export const MX_RECORDS_COLUMN: ITableApiColumn = {
  id: 'has_mx_records',
  accessor: 'has_mx_records',
  headerTooltip: 'Number of MX Records is more than zero',
  header: 'MX Records',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'has_mx_records') ? (data.has_mx_records ? 'True' : 'False') : '--';
  },
  agFilterType: 'agSetColumnFilter',
};

export const REDIRECTED_URL_COL: ITableApiColumn = {
  id: 'dst_url',
  accessor: 'dst_url',
  fieldForExport: 'dst_url',
  header: 'Redirected URL',
  headerTooltip: 'URL Details View',
  render: ({ dst_url }: any) => {
    if (dst_url) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + dst_url} className={'table-source-url-tooltip'}>
              {dst_url}
            </Tooltip>
          }
        >
          <div>
            <div className='table-long-text long-text-ellipsis-1'>{dst_url}</div>
          </div>
        </OverlayTrigger>
      );
    }
    return '--';
  },
};

export const SCAN_SOURCE_CATEGORY_COLUMN: ITableApiColumn = {
  id: 'sourceFeed',
  accessor: 'sourceFeed',
  fieldForExport: 'sourceFeed',
  header: 'Scan Source Category',
  type: 'options',
  sortDisabled: true,
  filterOptions: [],
};

export const SSL_COLUMN: ITableApiColumn = {
  id: 'ssl_cert_provider',
  accessor: 'ssl_cert_provider',
  headerTooltip: 'SSL Certificate',
  header: 'SSL Certificate',
  agFilterType: 'agTextColumnFilter',
};

export const BRAND_COLUMN: ITableApiColumn = {
  id: 'sub_brand_id_typo',
  accessor: 'sub_brand_display_name',
  header: 'Brand',
  headerTooltip: 'Brand',
  isLongText: true,
  type: 'options',
  filterOptions: [],
};

export const PAST_DISPOSITION_COLUMN: ITableApiColumn = {
  id: 'dispositions',
  accessor: 'dispositions',
  header: 'Past Dispositions',
  headerTooltip: 'All the Past Dispositions',
  type: 'options',
  filterOptions: DISPOSITION_FILTER_OPTIONS,
  sortDisabled: true,
  render: (data: any) => {
    return <div className='table-icon'>{data.dispositions ? data.dispositions : '--'}</div>;
  },
};

export const SFB_DETECTED_COLUMN: ITableApiColumn = {
  id: 'sfb_detected',
  accessor: 'sfb_detected',
  header: 'Google Safe Browsing',
  headerTooltip: 'Google Safe Browsing',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'sfb_detected') ? (data.sfb_detected ? 'True' : 'False') : '--';
  },
};

export const SEARCH_ENGINE_COLUMN: ITableApiColumn = {
  id: 'source',
  accessor: 'source',
  header: 'Google/Bing Searches',
  headerTooltip: 'Google/Bing Searches',
  type: 'options',
  filterOptions: [
    { label: 'True', value: '*_searchengine' },
    { label: 'False', value: 'NOT (*_searchengine)' },
  ],
  sortDisabled: true,
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'search_engine') ? (data.search_engine ? 'True' : 'False') : '--';
  },
  agFilterType: 'agTextColumnFilter',
};

// Delta Columns

export const DELTA_DOMAIN: ITableApiColumn = {
  id: 'primary_domain',
  accessor: 'primary_domain',
  fieldForExport: 'primary_domain',
  header: 'Domain',
  agFilterType: 'agTextColumnFilter',
  headerTooltip: 'Link to URL Details View',
  disableForSelection: true,
  render: (data: DeltaChangeFromItem) => {
    const { primary_domain, latestlastScannedTs, urlSHA256, findingsCount, newDisposition } = data;
    const activeInsight = newDisposition !== undefined;
    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + primary_domain} className={'table-source-url-tooltip'}>
            {primary_domain}
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          {activeInsight ? (
            <Link
              to={{
                pathname: `${TYPOSQUATTING_PATHNAME}/insights/${latestlastScannedTs}/${urlSHA256}`,
                state: {
                  prevPath: window.location.pathname,
                },
              }}
            >
              {findingsCount ? `${primary_domain} (${findingsCount})` : primary_domain}
            </Link>
          ) : (
            <div className='disabled-link'>
              {findingsCount ? `${primary_domain} (${findingsCount})` : primary_domain}
            </div>
          )}
        </div>
      </OverlayTrigger>
    );
  },
};

export const changeTypeMapper = {
  [CHANGE_TYPES.NEW_SUSPICIOUS]: 'Disposition',
  [CHANGE_TYPES.NEW_SCAM]: 'Disposition',
  [CHANGE_TYPES.NEW_PHISH]: 'Disposition',

  [CHANGE_TYPES.NEW_TYPOSQUAT]: 'New Typosquats',
  [CHANGE_TYPES.MX_RECORD_CHANGE]: 'MX Record',
  [CHANGE_TYPES.IP_CHANGES]: 'A Record',
  [CHANGE_TYPES.NS_CHANGES]: 'NS Record',
  [CHANGE_TYPES.IP_NOT_FOUND]: 'A Record',
  [CHANGE_TYPES.NS_NOT_FOUND]: 'NS Record',
  [CHANGE_TYPES.MX_NOT_FOUND]: 'MX Record',
};

export const DELTA_CHANGE_TYPE: ITableApiColumn = {
  id: 'delta_change_type',
  accessor: 'type',
  fieldForExport: 'type',
  header: 'Change Type',
  agFilterType: 'agTextColumnFilter',
  valueGetter: (params: any) => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { type } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    return changeTypeMapper[type] || '--';
  },
  render: (data: DeltaChangeFromItem) => {
    if (!data) return;
    const { type } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    return (
      <div
        className='status-wrap'
        style={{
          display: 'flex',
          alignContent: 'center',
        }}
      >
        <ColorDot type={type} />
        {changeTypeMapper[type] || '--'}
      </div>
    );
  },
};

export const DELTA_CHANGE_PREVIOUS: ITableApiColumn = {
  id: 'delta_change_previous',
  accessor: 'type',
  fieldForExport: 'previous',
  header: 'Previously',
  agFilterType: 'agTextColumnFilter',
  valueGetter: (params: any) => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { type, oldDisposition, oldMx, oldIp, oldNs } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    if (
      type === CHANGE_TYPES.NEW_SUSPICIOUS ||
      type === CHANGE_TYPES.NEW_SCAM ||
      type === CHANGE_TYPES.NEW_PHISH
    ) {
      if (!oldDisposition) {
        return '--';
      }
      return oldDisposition;
    }
    if (type === CHANGE_TYPES.MX_RECORD_CHANGE || type === CHANGE_TYPES.MX_NOT_FOUND) {
      if (!oldMx || oldMx.length === 0) {
        return '--';
      }
      return oldMx.join(', ');
    }
    if (type === CHANGE_TYPES.IP_CHANGES || type === CHANGE_TYPES.IP_NOT_FOUND) {
      if (!oldIp || oldIp.length === 0) {
        return '--';
      }
      return oldIp;
    }
    if (type === CHANGE_TYPES.NS_CHANGES || type === CHANGE_TYPES.NS_NOT_FOUND) {
      if (!oldNs || oldNs.length === 0) {
        return '--';
      }
      return oldNs.join(', ');
    }
    if (type === CHANGE_TYPES.NEW_TYPOSQUAT) {
      return '--';
    }
    return '--';
  },
  render: (data: DeltaChangeFromItem) => {
    const { type, oldDisposition, oldMx, oldIp, oldNs } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    if (
      type === CHANGE_TYPES.NEW_SUSPICIOUS ||
      type === CHANGE_TYPES.NEW_SCAM ||
      type === CHANGE_TYPES.NEW_PHISH
    ) {
      if (!oldDisposition) {
        return '--';
      }
      return (
        <DispositionStatus
          className='domain-monitoring-wrapper'
          displayedStyle={'pill'}
          status={oldDisposition}
          isNewPillColor={true}
        />
      );
    }
    if (type === CHANGE_TYPES.MX_RECORD_CHANGE || type === CHANGE_TYPES.MX_NOT_FOUND) {
      if (!oldMx || oldMx.length === 0) {
        return '--';
      }
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + oldMx?.join(', ')} className={'table-source-url-tooltip'}>
              {oldMx?.join(', ')}
            </Tooltip>
          }
        >
          <span>{oldMx?.join(', ')}</span>
        </OverlayTrigger>
      );
    }
    if (type === CHANGE_TYPES.IP_NOT_FOUND || type === CHANGE_TYPES.IP_CHANGES) {
      if (!oldIp || oldIp.length === 0) {
        return '--';
      }
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + oldIp} className={'table-source-url-tooltip'}>
              {oldIp}
            </Tooltip>
          }
        >
          <span>{oldIp}</span>
        </OverlayTrigger>
      );
    }
    if (type === CHANGE_TYPES.NS_CHANGES || type === CHANGE_TYPES.NS_NOT_FOUND) {
      if (!oldNs || oldNs.length === 0) {
        return '--';
      }
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + oldNs?.join(', ')} className={'table-source-url-tooltip'}>
              {oldNs?.join(', ')}
            </Tooltip>
          }
        >
          <span>{oldNs?.join(', ')}</span>
        </OverlayTrigger>
      );
    }
    if (type === CHANGE_TYPES.NEW_TYPOSQUAT) {
      return '--';
    }
    return '--';
  },
};

export const DELTA_CHANGE_CURRENT: ITableApiColumn = {
  id: 'delta_change_current',
  accessor: 'type',
  fieldForExport: 'current',
  header: 'Currently',
  agFilterType: 'agTextColumnFilter',
  valueGetter: (params: any) => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { type, newDisposition, newMx, newIp, newNs } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    if (
      type === CHANGE_TYPES.NEW_SUSPICIOUS ||
      type === CHANGE_TYPES.NEW_SCAM ||
      type === CHANGE_TYPES.NEW_PHISH
    ) {
      if (!newDisposition) {
        return '--';
      }
      return newDisposition;
    }
    if (type === CHANGE_TYPES.MX_RECORD_CHANGE || type === CHANGE_TYPES.MX_NOT_FOUND) {
      if (!newMx || newMx.length === 0) {
        return '--';
      }
      return newMx.join(', ');
    }
    if (type === CHANGE_TYPES.IP_CHANGES || type === CHANGE_TYPES.IP_NOT_FOUND) {
      if (!newIp || newIp.length === 0) {
        return '--';
      }
      return newIp;
    }
    if (type === CHANGE_TYPES.NS_CHANGES || type === CHANGE_TYPES.NS_NOT_FOUND) {
      if (!newNs || newNs.length === 0) {
        return '--';
      }
      return newNs.join(', ');
    }
    if (type === CHANGE_TYPES.NEW_TYPOSQUAT) {
      if (!_.isEmpty(newIp)) {
        return newIp;
      }
      if (!_.isEmpty(newMx)) {
        return newMx?.join(', ');
      }
      if (!_.isEmpty(newNs)) {
        return newNs?.join(', ');
      }
      return '--';
    }

    return '--';
  },
  render: (data: DeltaChangeFromItem) => {
    const { type, newDisposition, newMx, newIp, newNs } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    if (
      type === CHANGE_TYPES.NEW_SUSPICIOUS ||
      type === CHANGE_TYPES.NEW_SCAM ||
      type === CHANGE_TYPES.NEW_PHISH
    ) {
      if (!newDisposition) {
        return '--';
      }
      return (
        <DispositionStatus
          className='domain-monitoring-wrapper'
          displayedStyle={'pill'}
          status={newDisposition}
          isNewPillColor={true}
        />
      );
    }
    if (type === CHANGE_TYPES.MX_RECORD_CHANGE || type === CHANGE_TYPES.MX_NOT_FOUND) {
      if (!newMx || newMx.length === 0) {
        return '--';
      }
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + newMx?.join(', ')} className={'table-source-url-tooltip'}>
              {newMx?.join(', ')}
            </Tooltip>
          }
        >
          <span>{newMx?.join(', ')}</span>
        </OverlayTrigger>
      );
    }
    if (type === CHANGE_TYPES.IP_CHANGES || type === CHANGE_TYPES.IP_NOT_FOUND) {
      if (!newIp || newIp.length === 0) {
        return '--';
      }
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + newIp} className={'table-source-url-tooltip'}>
              {newIp}
            </Tooltip>
          }
        >
          <span>{newIp}</span>
        </OverlayTrigger>
      );
    }
    if (type === CHANGE_TYPES.NS_CHANGES || type === CHANGE_TYPES.NS_NOT_FOUND) {
      if (!newNs || newNs.length === 0) {
        return '--';
      }
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + newNs?.join(', ')} className={'table-source-url-tooltip'}>
              {newNs?.join(', ')}
            </Tooltip>
          }
        >
          <span>{newNs?.join(', ')}</span>
        </OverlayTrigger>
      );
    }
    if (type === CHANGE_TYPES.NEW_TYPOSQUAT) {
      if (!_.isEmpty(newIp)) {
        return newIp;
      }
      if (!_.isEmpty(newMx)) {
        return newMx?.join(', ');
      }
      if (!_.isEmpty(newNs)) {
        return newNs?.join(', ');
      }
      return '--';
    }
    return '--';
  },
};

export const DELTA_LAST_SCAN: ITableApiColumn = {
  id: 'delta_last_scan',
  accessor: 'delta_last_scan',
  headerTooltip: 'Last Scan At',
  header: 'Last Scan At',
  type: 'date',
  agFilterType: 'agDateColumnFilter',
  comparator: (valueA: any, valueB: any) => {
    if (valueA === valueB) return 0;
    if (valueA < valueB) return -1;
    return 1;
  },
  valueGetter: params => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { oldlastScannedTs } = data;
    if (!oldlastScannedTs) {
      return 0;
    }
    return new Date(oldlastScannedTs).getTime();
  },
  render: (data: DeltaChangeFromItem) => {
    const { oldlastScannedTs } = data;
    if (!oldlastScannedTs) {
      return '--';
    }
    return getDisplayTimeFromTimeStamp(oldlastScannedTs, 'MM/DD/YYYY');
  },
};

export const DELTA_CHANGE_AT: ITableApiColumn = {
  id: 'delta_change_at',
  accessor: 'delta_change_at',
  headerTooltip: 'Change At',
  header: 'Change At',
  type: 'date',
  agFilterType: 'agDateColumnFilter',
  comparator: (valueA: any, valueB: any) => {
    if (valueA === valueB) return 0;
    if (valueA < valueB) return -1;
    return 1;
  },
  valueGetter: params => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { latestlastScannedTs } = data;
    if (!latestlastScannedTs) {
      return 0;
    }
    return new Date(latestlastScannedTs).getTime();
  },
  render: (data: DeltaChangeFromItem) => {
    const { latestlastScannedTs } = data;
    if (!latestlastScannedTs) {
      return '--';
    }
    return getDisplayTimeFromTimeStamp(latestlastScannedTs, 'MM/DD/YYYY');
  },
};

//whats gone table columns

export const INACTIVE_TYPOSQAUT_COLUMN: ITableApiColumn = {
  id: 'primary_domain',
  accessor: 'primary_domain',
  fieldForExport: 'primary_domain',
  header: 'Inactive Typosquats',
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
  render: (data: DeltaChangeFromItem) => {
    const { primary_domain, latestlastScannedTs, urlSHA256, findingsCount, newDisposition } = data;
    const activeInsight = newDisposition !== undefined;
    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + primary_domain} className={'table-source-url-tooltip'}>
            {primary_domain}
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          {activeInsight ? (
            <Link
              onClick={() =>
                history.push(
                  `${TYPOSQUATTING_PATHNAME}/insights/${latestlastScannedTs}/${urlSHA256}`,
                )
              }
              to={{
                pathname: `${TYPOSQUATTING_PATHNAME}/insights/${latestlastScannedTs}/${urlSHA256}`,
                state: {
                  prevPath: window.location.pathname,
                },
              }}
            >
              {findingsCount ? `${primary_domain} (${findingsCount})` : primary_domain}
            </Link>
          ) : (
            <div className='disabled-link'>
              {findingsCount ? `${primary_domain} (${findingsCount})` : primary_domain}
            </div>
          )}
        </div>
      </OverlayTrigger>
    );
  },
};

export const STATUS_COLUMN: ITableApiColumn = {
  id: 'type',
  accessor: 'type',
  fieldForExport: 'type',
  header: 'Status',
  agFilterType: 'agTextColumnFilter',
  valueGetter: (params: any) => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { type } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    return changeTypeMapper[type];
  },
  render: (data: DeltaChangeFromItem) => {
    if (!data) return;
    const { type } = data;
    if (_.isEmpty(type)) {
      return '--';
    }
    return (
      <div
        className='status-wrap'
        style={{
          display: 'flex',
          alignContent: 'center',
        }}
      >
        <ColorDot type={type} />
        {changeTypeMapper[type]}
      </div>
    );
  },
};

export const STATUS_CHANGED_AT_COLUMN: ITableApiColumn = {
  id: 'delta_last_scan',
  accessor: 'delta_last_scan',
  headerTooltip: 'Status Changed At',
  header: 'Status Changed At',
  type: 'date',
  agFilterType: 'agDateColumnFilter',
  comparator: (valueA: any, valueB: any) => {
    if (valueA === valueB) return 0;
    if (valueA < valueB) return -1;
    return 1;
  },
  valueGetter: params => {
    const data: DeltaChangeFromItem = params.data;
    if (!data) return;
    const { latestlastScannedTs } = data;
    if (!latestlastScannedTs) {
      return 0;
    }
    return new Date(latestlastScannedTs).getTime();
  },
  render: (data: DeltaChangeFromItem) => {
    const { latestlastScannedTs } = data;
    if (!latestlastScannedTs) {
      return '--';
    }
    return getDisplayTimeFromTimeStamp(latestlastScannedTs, 'MM-DD-YYYY');
  },
};
