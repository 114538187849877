import React, { useMemo } from 'react';
import './ColorDot.scss';
import { changeTypeMapper } from '../../../columns';
import _ from 'lodash';

export type ColorDotProps = {
  type: string;
};

const ColorDot = ({ type }: ColorDotProps) => {
  const colorClass = useMemo(() => {
    if (!_.has(changeTypeMapper, type)) return 'no-change';
    return type;
  }, [type]);
  return (
    <div
      data-testid='color-dot'
      className={`color-dot ${colorClass}`}
      style={{
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        margin: '10px',
        alignSelf: 'center',
      }}
    ></div>
  );
};

export default ColorDot;
