import React, { useContext, useEffect, useRef, useState } from 'react';
import SourceFeedsContainer from './SourceFeedsContainer/SourceFeedsContainer';
import './MonitoredContainerV2.scss';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import {
  appConstants,
  capitalize,
  DashBoardDto,
  generateId,
  IBrand,
  IOrgUnits,
  IRunByClientAppType,
  IScanSourceFeeds,
} from '../../../../constants';
import DashboardService from '../../../../services/dashboard.service';
import moment from 'moment';
import { ISourceFeedTypes, ITopFeedsScannedForBrand } from '../../Types/dashboard.types';
import { IFilter } from '../../../Common/Table/constant';
import { MonitoredFunnelContainer } from './FunnelContanier/MonitoredFunnelContainer';
import { connect } from 'react-redux';
import { appActions } from '../../../../actions';
import { TypoSquatDashboardAnalytics } from '../../../../types/typosquat-dashboard.interface';
import { Overlay } from 'react-bootstrap';
import { ChevronDownIcon } from '../../../../assets/SVGIcons';
import { useOnClickOutside } from '../../../Common/CustomHooks/useOnClickOutside';
import ActiveOUContext from '../../../../context/ActiveOUContext';
import CheckPhishContactUsContext from '../../../../context/CheckPhishContactUsContext';
import { useFeatureIsAvailable } from '../../../../basic-hooks/useFeatures';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';
import { Tab, Tabs } from '@mui/material';

interface IMonitoredContainerV2Props {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onDateRangeChange: (startDate: moment.Moment, endDate: moment.Moment, lastXDay: number) => void;
  user: DashBoardDto;
  domainCategories: any;
  scanSourceCategories: IScanSourceFeeds[];
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
  typosquatDashboardAnalytics?: TypoSquatDashboardAnalytics;
  sendFilters?: (query: any) => any;
  sendMonitoredDomains?: (query: any) => any;
  loadFunnelData?: boolean;
}

export enum ETabTypes {
  MONITORED_ORG = 'topFeedsScannedForBrand',
  MONITORED_GLOBALLY = 'topFeedsScannedForGlobal',
  MONITORED_USER = 'topFeedsScannedForUser',
}

export interface IDefaultSourceFeeds {
  name?: string;
  label: string;
  displayOrder?: number;
  icon?: string;
  colorCodingLight?: string;
  colorCodingDark?: string;
  count: number;
  iconDarkUrl?: string;
  iconLightUrl?: string;
}

function MonitoredContainerV2(props: IMonitoredContainerV2Props) {
  const dashboardService = new DashboardService();
  const DEFAULT_SOURCE_FEEDS: IDefaultSourceFeeds[] = [];
  const [, selectedTheme] = useIsDarkMode();

  const isDemo = window.location.pathname.includes('premium');

  const { activeOUId, setActiveOUId } = useContext(ActiveOUContext);
  const {
    user,
    startDate,
    endDate,
    domainCategories,
    scanSourceCategories,
    runByClientApp,
    typosquatDashboardAnalytics,
    sendFilters,
    loadFunnelData,
  } = props;
  //Default source feeds
  props.scanSourceCategories.forEach((category: IScanSourceFeeds) => {
    DEFAULT_SOURCE_FEEDS.push({
      label: category.label,
      count: 0,
    });
  });
  const tabRefs = useRef(null);

  const getCurrentOUId = () => {
    if (activeOUId === null) {
      if (user.ouId === null) {
        return user.orgInfo?.brands.find((brand: IBrand) => user?.brandId === brand.id);
      } else {
        return user.orgInfo?.orgUnits.find((unit: IOrgUnits) => unit.id === user.ouId);
      }
    } else {
      return user.orgInfo?.orgUnits.find((unit: IOrgUnits) => unit.id === activeOUId);
    }
  };
  const getDefaultTab: any = runByClientApp({
    onCheckPhish: () => (isDemo ? ETabTypes.MONITORED_GLOBALLY : ETabTypes.MONITORED_USER),
    onBolster: () => ETabTypes.MONITORED_ORG,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFunnelLoading, setIsFunnelLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(getDefaultTab);
  const [enableCommunity, setEnableCommunity] = useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState<string>('');
  const [activeFilters, setActiveFilters] = useState<IFilter[]>([]);
  const [currentOrgUnit, setCurrentOrgUnit] = useState<IOrgUnits | IBrand | undefined>(
    getCurrentOUId(),
  );
  const [sourceFeeds, setSourceFeeds] = useState<ISourceFeedTypes>();
  const [ouDropdownOptions, setOuDropdownOptions] = useState<IOrgUnits[]>([]);
  const [activeSourceFeeds, setActiveSourceFeeds] = useState<
    ITopFeedsScannedForBrand[] | IDefaultSourceFeeds[]
  >(DEFAULT_SOURCE_FEEDS);
  const [show, setShow] = useState(false);
  const [funnelQueryParams, setFunnelQueryParams] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [monitoredDomains, setMonitoredDomains] = useState<any[]>([]);
  const [value, setValue] = useState(0);
  const checkphishContactUsContext = useContext(CheckPhishContactUsContext);

  useOnClickOutside(tabRefs, (e: any) => {
    if (!e.target.classList.contains('label-wrapper')) {
      setShow(false);
    }
  });

  // Need to refactor
  useEffect(() => {
    if (user.orgInfo?.brands && user.orgInfo?.orgUnits?.length > 1) {
      const filteredBrand: IOrgUnits[] = [];

      // Mapping primary org details for the OU dropdown
      const primaryOrgUnit = user.orgInfo.brands
        .filter((brand: IBrand) => brand.ouId === user.ouId)
        .map((brand: IBrand): IOrgUnits => {
          return {
            name: brand.name,
            id: brand.ouId,
            brandIds: 'orgInfo' in user ? user.orgInfo?.brands.map((b: any) => b.id) : [],
          };
        });

      filteredBrand.push(...primaryOrgUnit);
      setOuDropdownOptions(
        user.ouId === null && user.orgInfo.orgUnits.length === 0
          ? []
          : [...filteredBrand, ...user.orgInfo?.orgUnits],
      );
    }

    runByClientApp({
      onCheckPhish: () => {
        setEnableCommunity(true);
        if (isDemo) {
          fetchSourceFeeds();
          fetchMonitoredDomians();
        }
      },
      onBolster: () => {
        fetchSourceFeeds();
        fetchMonitoredDomians();
      },
    });
  }, [startDate, endDate, user, activeOUId]);

  useEffect(() => {
    if (!enableCommunity || isDemo) return;
    if (typosquatDashboardAnalytics) {
      const sourceFeed: ISourceFeedTypes = {
        topFeedsScannedForBrand: [],
        topFeedsScannedForGlobal: [],
        topFeedsScannedForUser: typosquatDashboardAnalytics.topFeedsScannedForUser,
      };
      setSourceFeeds(sourceFeed);
      setActiveSourceFeeds(sourceFeed[activeTab]);
    } else {
      setActiveSourceFeeds(DEFAULT_SOURCE_FEEDS);
    }
  }, [enableCommunity]);

  const onTabClick = (tab: ETabTypes) => {
    setActiveTab(tab);
    setActiveSourceFeeds(DEFAULT_SOURCE_FEEDS);
    setActiveFilters([]);

    setTimeout(() => {
      setActiveSourceFeeds(sourceFeeds?.[tab] || DEFAULT_SOURCE_FEEDS);
    }, 1000);

    setSelectedLabel('');
  };

  const createSourceFilter = (selectedTab: ITopFeedsScannedForBrand | IDefaultSourceFeeds) => {
    const { label, count } = selectedTab;
    if (
      label === '' ||
      label === undefined ||
      label.length === 0 ||
      count === 0 ||
      label === selectedLabel
    ) {
      setActiveFilters([]);
      return;
    }

    const filterValue = scanSourceCategories.find(
      (category: IScanSourceFeeds) => category.label === label,
    );

    const soureFeedFilter: IFilter = {
      id: generateId(10),
      filterBy: { label: 'Scan Source Category', value: 'sourceFeed' },
      filterErr: '',
      filterLabel: label,
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'options',
      filterValue: filterValue?.value || '',
    };

    setActiveFilters([soureFeedFilter]);
  };

  const onSubTabClick = (selectedTab: ITopFeedsScannedForBrand | IDefaultSourceFeeds) => {
    if (enableCommunity) {
      if (selectedTab.name !== 'typosquat') {
        const { monitoredDomain, setContactUsMesage, setShowContactUsPage } =
          checkphishContactUsContext;
        setContactUsMesage(
          isDemo
            ? 'For access to additional features, contact us'
            : `For access to additional features, and to further monitor ${monitoredDomain}, contact us.`,
        );
        setShowContactUsPage(true);
      }
    } else {
      const { label } = selectedTab;
      setSelectedLabel(selectedLabel === label ? '' : label);
      createSourceFilter(selectedTab);
    }
  };

  const isWebCrowdSourceEnabled = useFeatureIsAvailable(appConstants.FEATURE_CODE.WEB_CROWD_SOURCE);
  const updateSourceFeeds = (sourceFeeds: ISourceFeedTypes, key: string) =>
    isWebCrowdSourceEnabled
      ? sourceFeeds
      : {
          ...sourceFeeds,
          [key]: sourceFeeds[key].filter(
            (item: ITopFeedsScannedForBrand) => item.name !== 'crowd_sourced',
          ),
        };

  const fetchSourceFeeds = async () => {
    try {
      setIsLoading(true);
      const query = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (activeOUId) {
        query['ouId'] = activeOUId;
      }

      const [sourceFeedsForBrand, sourceFeedsGlobally] = await Promise.all([
        dashboardService.getAllSourceFeedsForBrand(query),
        dashboardService.getAllSourceFeedsGlobally(query),
      ]);
      const updatedSourceFeedsForBrand = updateSourceFeeds(
        sourceFeedsForBrand,
        'topFeedsScannedForBrand',
      );
      const updatedSourceFeedsGlobally = updateSourceFeeds(
        sourceFeedsGlobally,
        'topFeedsScannedForGlobal',
      );
      const response = { ...updatedSourceFeedsForBrand, ...updatedSourceFeedsGlobally };
      if (response) {
        setSourceFeeds(response);
        setActiveSourceFeeds(response[activeTab]);
      }
    } catch (error) {
      console.log(error);
      setActiveSourceFeeds(DEFAULT_SOURCE_FEEDS);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMonitoredDomians = async () => {
    try {
      setIsFunnelLoading(true);

      if (typosquatDashboardAnalytics && !isDemo) {
        setMonitoredDomains(typosquatDashboardAnalytics.domainCounts);
      } else {
        const query = {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const sourceFeed = activeFilters.length > 0 ? activeFilters[0].filterValue : undefined;
        const monitoredDomainCounts = await dashboardService.getMonitoredDomains(
          query.startDate,
          query.endDate,
          funnelQueryParams,
          sourceFeed,
          activeOUId,
        );

        if (monitoredDomainCounts) {
          const { counts } = monitoredDomainCounts;
          setMonitoredDomains(counts);
        }
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsFunnelLoading(false);
    }
  };

  // fetch monitored domains on filter change
  useEffect(() => {
    fetchMonitoredDomians();
  }, [funnelQueryParams, activeFilters]);

  const onOUnitClick = (unit: IOrgUnits) => {
    setCurrentOrgUnit(unit);
    setActiveOUId(unit.id);
    setShow(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='monitiored-container-v2'>
      <Tabs className='tabs-wrapper' value={value} onChange={handleTabChange}>
        {typosquatDashboardAnalytics && !isDemo ? (
          <>
            <div className={`tabs active`}>
              {`Monitored for ${typosquatDashboardAnalytics.monitoredDomain}`}{' '}
            </div>
            <div className='tab-line'></div>
            <div className={`tabs disabled-tabs `}> Monitored Globally </div>
          </>
        ) : (
          [
            <Tab
              key='monitored-org'
              className={`tabs`}
              onClick={() => onTabClick(ETabTypes.MONITORED_ORG)}
              label={`Monitored for ${capitalize(currentOrgUnit?.name || '') || '--'} `}
              id='source-feed-by-org'
            />,
            ouDropdownOptions.length > 0 && (
              <div ref={tabRefs} className='tabs-dropdown-container'>
                <div
                  onClick={() => {
                    setShow(true);
                  }}
                  className='tabs-dropdown'
                >
                  {' '}
                  <ChevronDownIcon width='16' height='10' />
                </div>{' '}
                <Overlay target={tabRefs.current} show={show} placement={'left-start'} flip={false}>
                  {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                      {...props}
                      className={`${selectedTheme} sub-tabs-wrapper`}
                      style={{
                        ...props.style,
                      }}
                    >
                      <div className='list-wrapper'>
                        {ouDropdownOptions?.map((unit: IOrgUnits, index: number) => {
                          return (
                            <div
                              className='label-wrapper'
                              key={index}
                              onClick={() => onOUnitClick(unit)}
                            >
                              {unit.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </Overlay>
              </div>
            ),
            <Tab
              key='monitored-org-globally'
              className={`tabs`}
              onClick={() => onTabClick(ETabTypes.MONITORED_GLOBALLY)}
              label='Monitored Globally'
              id='source-feed-by-globally'
            />,
          ]
        )}
      </Tabs>
      {typosquatDashboardAnalytics && !isDemo ? (
        <>
          <SourceFeedsContainer
            activeSourceFeeds={activeSourceFeeds}
            activeTab={activeTab}
            selectedLabel={selectedLabel}
            onSubTabClick={onSubTabClick}
          />
          <MonitoredFunnelContainer
            startDate={startDate}
            endDate={endDate}
            domainCategories={domainCategories}
            user={user}
            activeFilters={activeFilters}
            typosquatDashboardAnalytics={typosquatDashboardAnalytics}
            sendFilters={sendFilters}
            loadFunnelData={loadFunnelData}
            isFunnelLoading={isFunnelLoading}
            setFunnelQueryParams={setFunnelQueryParams}
            isError={isError}
            monitoredDomains={monitoredDomains}
          />
        </>
      ) : (
        <LoadingWrapper isLoading={isLoading}>
          <SourceFeedsContainer
            activeSourceFeeds={activeSourceFeeds}
            activeTab={activeTab}
            selectedLabel={selectedLabel}
            onSubTabClick={onSubTabClick}
          />
          {/* Do no set trigger sendFilters since its been used only in checkphish and not in platform */}
          <MonitoredFunnelContainer
            startDate={startDate}
            endDate={endDate}
            domainCategories={domainCategories}
            user={user}
            activeFilters={activeFilters}
            isFunnelLoading={isFunnelLoading}
            setFunnelQueryParams={setFunnelQueryParams}
            isError={isError}
            monitoredDomains={monitoredDomains}
            currentOrgUnit={currentOrgUnit}
          />
        </LoadingWrapper>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  runByClientApp: appActions.runByClientApp,
};

const connectedMonitoredContainerV2 = connect(undefined, mapDispatchToProps)(MonitoredContainerV2);
export { connectedMonitoredContainerV2 as MonitoredContainerV2 };
