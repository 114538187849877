import React from 'react';
import _ from 'lodash';
import { ITableApiColumn } from '../../Common/Table/table.api';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AgGridDateRangeFilter from '../../Common/Table/ag-table/AgGridDateRangeFilter';
import { getDisplayTimeFromTimeStamp, setLocalStorageValue } from '../../../constants';
import {
  getLabelByValue,
  TARGETED_MALICIOUS_CATEGORIES,
  TARGETED_MALICIOUS_DISPOSITIONS,
  TARGETED_MALICIOUS_STATUS,
  TARGETED_MALICIOUS_THREAT_TYPES,
} from '../constants';
import { StatusLabels } from '../../Common/statusLabels';
import { CATEGORY_FILTER_OPTIONS } from '../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { DispositionStatus } from '../../Common/DispositionStatus';
import { getInsightsPathBasedOnThreatType } from '../Insights/InsightsUtils';
import { TARGETED_MALICIOUS_EXPANDED_ROWS } from '../../Common/Table/ag-table/AgGridAbuseMailBoxTargetedMalicious';
export type ITargetedMaliciousColumn = Omit<ITableApiColumn, 'accessor'>;

export const ABUSE_MAILBOX_INSIGHTS_PARAMS = 'abuse_mailbox_insights_params';

export enum INSIGHTS_TYPE {
  // which api to call for insights
  TARGETED_MALICIOUS = 'targeted_malicious',
  ALL_URL_ANALYSIS = 'all_url_analysis',
}

export const TARGETED_MALICIOUS_COLUMNS: ITargetedMaliciousColumn[] = [
  {
    id: 'sourceUrl',
    fieldForExport: 'sourceUrl',
    header: 'Threat',
    headerTooltip: 'Link to Threat Details View',
    sortDisabled: true,
    render: (data: any) => {
      const { sourceUrl, disposition, threatType } = data;
      const insightsPath = getInsightsPathBasedOnThreatType(data);

      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + sourceUrl} className={'table-source-url-tooltip'}>
              {sourceUrl}
            </Tooltip>
          }
        >
          <div className='table-source-url-column d-flex align-items-center'>
            <Link
              to={insightsPath}
              onClick={() => {
                setLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS, {
                  insightsType: INSIGHTS_TYPE.TARGETED_MALICIOUS,
                  disposition,
                  threatType,
                });
                setLocalStorageValue(TARGETED_MALICIOUS_EXPANDED_ROWS, data.threatType);
              }}
            >
              {sourceUrl}
            </Link>
          </div>
        </OverlayTrigger>
      );
    },
    hiddenInDragDrop: true,
    agFilterType: 'agTextColumnFilter',
    disableForSelection: true,
  },
  {
    id: 'threatType',
    header: 'Threat Type',
    type: 'options',
    filterOptions: _.sortBy(TARGETED_MALICIOUS_THREAT_TYPES, 'label'),
    agFilterType: 'agTextColumnFilter',
    render: (data: any) => {
      if (_.isEmpty(data.threatType)) {
        return '--';
      }
      return getLabelByValue(data?.threatType, TARGETED_MALICIOUS_THREAT_TYPES);
    },
  },
  {
    id: 'count',
    header: 'Report Count',
    type: 'number',
    agFilterType: 'agNumberColumnFilter',
    render: (data: any) => {
      if (!data.count) {
        return '--';
      }
      return data.count;
    },
  },
  {
    id: 'disposition',
    header: 'Initial Disposition',
    render: (data: any) => {
      if (!data.disposition) {
        return '--';
      }
      return <DispositionStatus status={data.disposition} />;
    },
    type: 'options',
    filterOptions: _.sortBy(TARGETED_MALICIOUS_DISPOSITIONS, 'label'),
    agFilterType: 'agTextColumnFilter',
  },
  {
    id: 'category',
    header: 'Category',
    render: (data: any) => {
      if (!data.category) {
        return '--';
      }
      return getLabelByValue(data.category, [
        ...CATEGORY_FILTER_OPTIONS,
        ...TARGETED_MALICIOUS_CATEGORIES,
      ]);
    },
    type: 'options',
    filterOptions: _.sortBy([...CATEGORY_FILTER_OPTIONS, ...TARGETED_MALICIOUS_CATEGORIES], item =>
      item.label.toLowerCase(),
    ),
    agFilterType: 'agTextColumnFilter',
  },
  {
    id: 'firstSeenTargeted',
    header: 'First Seen',
    fieldForExport: 'firstSeen',
    type: 'date',
    isLongText: true,
    render: (data: any) => {
      return getDisplayTimeFromTimeStamp(data.firstSeenTargeted, 'MMMM D, YYYY h:mm a');
    },
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'lastSeenTargeted',
    header: 'Last Seen',
    fieldForExport: 'lastSeen',
    type: 'date',
    isLongText: true,
    render: (data: any) => {
      return getDisplayTimeFromTimeStamp(data.lastSeenTargeted, 'MMMM D, YYYY h:mm a');
    },
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'status',
    header: 'Status',
    filterOptions: _.sortBy(TARGETED_MALICIOUS_STATUS, 'label'),
    type: 'options',
    agFilterType: 'agTextColumnFilter',
    render: (data: any) => {
      if (_.isEmpty(data.status)) {
        return '--';
      }
      return <StatusLabels status={data?.status}></StatusLabels>;
    },
  },
];
