import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Route, useHistory } from 'react-router';
import { alertActions, appActions } from '../../actions';
import {
  AlertActionsTypes,
  appConstants,
  getLocalStorageValue,
  getNavPathnameForInsightPage,
  getSourceTrigger,
  getTimestampAndUrlSHA256,
  IAppDispatchProps,
  IBrandInfo,
  InsightsDto,
  isBrandReadOnlyUser,
  setLocalStorageValue,
  unsetLocalStorageValue,
} from '../../constants';
import { AppState, history } from '../../helpers';
import { ELoggedIn } from '../../reducers';
import DashboardService from '../../services/dashboard.service';
import { PageTitleInsights } from '../Common/PageTitleInsights';
import { DomTree } from '../DomTree/domTree.component';
import SubNavigationBar, { ISubBarItem } from './Common/SubNavigationBar';
import { Insights } from '../Insights/insights.component';
import {
  POST_MALICIOUS_PATHNAME,
  POST_MALICIOUS_TABLE_ID_DASH,
  PRE_MALICIOUS_PATHNAME,
  PRE_MALICIOUS_TABLE_ID_DASH,
  TAKEDOWN_MALICIOUS_PATHNAME,
  TAKEDOWN_MALICIOUS_TABLE_ID_DASH,
  USER_GLOBAL_TAKEDOWN_PATHNAME,
  WEB_DASHBOARD_PATHNAME,
} from '../MonitorAndTakedown/constants';
import { Timeline } from '../Timeline/timeline.component';
import { Whois } from '../WhoIsInfo/whois.component';
import { Link } from 'react-router-dom';
import { Button as ButtonRB, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InsightsDisputeModal from './Components/InsightsDisputeModal';
import moment from 'moment';
import {
  DisputeStatus,
  DISPUTE_DISPOSITION_OPTIONS,
  DISPUTE_STATUS,
} from '../Common/DisputeStatus';
import { DispositionStatus } from '../Common/DispositionStatus';
import { ClockIcon } from '../../assets/SVGIcons';
import '../Insights/insights.scss';
import RequestTakedownContainer from './Common/RequestTakedownContainer';
import ThreatIntelligenceComponent from '../ThreatIntelligence/ThreatIntelligenceComponent';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import ActiveRoutesContext from '../../context/ActiveRoutesContext';
import {
  TYPOSQUATTING_PATHNAME,
  TYPOSQUAT_TABLE_ID_DASH,
  TYPO_PRE_MALICIOUS_TABLE_ID_DASH,
  TYPO_MALICIOUS_TABLE_ID_DASH,
  TYPOSQUAT_DASHBOARD_TABLE_ID_DASH,
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_PREMALICIOUS_PATHNAME,
  TYPOSQUATTING_MALICIOUS_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
  ETyposquattingStatus,
} from '../DomainMonitoring/constant';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import DeleteCustomTagsModal from '../Common/Tags/DeleteCustomTagsModal';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import EditCustomTagsModal from '../Common/Tags/EditCustomTagsModal';
import {
  setCurrentGroupParentKey,
  setCurrentTableId,
  setGoBackUrl,
  setShouldFetchInsightsOnNext,
} from '../../reducers/insightsContainer.reducer';
import { EMenuType } from '../Drawer/RouteOptions';
import { setPreviousUrlSha } from '../../reducers/table.reducer';
import { GLOBAL_TAKEDOWN_TABLE_ID } from '../GlobalTakedown/GlobalTakedown';
import {
  ABUSE_MAIL_BOX_MODULE_PATH_NAME,
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
} from '../AbuseMailbox/constants';
import { EmailDetails } from '../AbuseMailbox/Insights/EmailDetails';
import '../AbuseMailbox/Insights/ThreatTypeInsights.scss';
import { ThreatTypesReportCount } from '../AbuseMailbox/Insights/ThreatTypesReportCount';

import './InsightsContainer.scss';
import { Button } from '@mui/material';
import { useThreatDetailsBasedOnSha } from '../AbuseMailbox/Insights/useThreatDetailsBasedOnSha';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';
import { ALL_EMAIL_SUBMISSIONS_SUBPATH } from '../AbuseMailbox/AllEmailSubmissions/constants';
import { IUrlDetails } from '../BulkScan/BulkScanConstants';
import { ABUSE_MAILBOX_INSIGHTS_PARAMS } from '../AbuseMailbox/TargetedMalicious/TargetedMaliciousColDefs';
import { getLastVisitedPage } from '../../helpers/url-helpers';

export const DEFAULT_INSIGHT_TEMPLATE = {
  scanResults: {
    status: '--',
    srcURL: '--',
    redirectURL: '--',
    location: '--',
    tld: '--',
    brand: '--',
    brandId: '--',
    subBrandId: '--',
    subBrandName: '--',
    networkOwner: '--',
    asn: '--',
    ip: '--',
    takedownTS: '--',
    takedownCount: '--',
    timestamp: '--',
    createdTS: '--',
    imagePath: '',
    cert: '--',
    abuseContact: '--',
    disposition: '',
    host: '',
    primaryDomain: '',
    countryCode: '',
    firstSeenTS: '--',
    jobId: '--',
    hostPhishCount: '--',
    ipPhishCount: '--',
    phishingKitsCountOnHost: '--',
    scanSource: '--',
    annotations: [],
    brandScanCount: '0',
    bolsterScanCount: '0',
    brandInfo: null,
    finalCategory: '--',
    mxRecords: '--',
    hasMxRecords: '--',
    allMXRecords: [],
    registrationDate: '--',
    registrant: '--',
    nameservers: '--',
    sfbDetected: '--',
    scanSettings: {},
    scanContext: '',
    scanContentType: '',
    sourceFeed: '',
    redirectedChains: [],
  },
  markerPosition: {
    lat: 0,
    lng: 0,
  },
  certList: [],
};

export interface IDisputeData {
  attachments: string[];
  disposition: string;
  bolsterDisposition: string;
  status: string;
  user: string;
  timestamp: string;
  comments: string;
}
const dashboardService = new DashboardService();

interface IProps {
  type: any;
  tableId: any;
  loggedIn: ELoggedIn;
  user: any;
  alertError: (message: string) => AlertActionsTypes;
  alertSuccess: (message: string) => AlertActionsTypes;
}

interface IInsightsContainer {
  changeTyposquatJobStatus?: (status: boolean) => void;
}

const InsightsContainer = (props: IProps & IAppDispatchProps & IInsightsContainer) => {
  const { type, user, loggedIn, alertError, alertSuccess, tableId, runByClientApp } = props;
  const { REACT_APP_BOLSTER_APP_TYPE } = process.env;
  window.document.title = 'URL Insight | Dashboard | Bolster Platform';
  const { setContactUsMesage, setShowContactUsPage, userRequestSubmitted } = useContext(
    CheckPhishContactUsContext,
  );

  const historyRouter = useHistory();
  const triggerType = getSourceTrigger();

  const getIsLoggedIn = () => {
    const isLoggedIn = getLocalStorageValue('isLoggedIn');
    return loggedIn === ELoggedIn.true || true === isLoggedIn;
  };

  const currentTableId = useAppSelector(state => state.insightsContainerReducer.currentTableId);
  const goBackUrl = useAppSelector(state => state.insightsContainerReducer.goBackUrl);
  const dispatch = useAppDispatch();

  const { activeRoute, getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  const [, selectedTheme] = useIsDarkMode();
  const [timestamp, sha256] = getTimestampAndUrlSHA256();

  const [isPublic, setIsPublic] = useState<boolean>(!getIsLoggedIn());

  const [insightsData, setInsightsData] = useState<any>(DEFAULT_INSIGHT_TEMPLATE);
  const [disputeData, setDisputeData] = useState<any>({});
  const [disputeError, setDisputeError] = useState('');
  const [sendingDisputeRequest, setSendingDisputeRequest] = useState<boolean>(false);
  const [isDisputeModalShown, setIsDisputeModalShown] = useState<boolean>(false);
  const [isDisputeDetailShown, setIsDisputeDetailShown] = useState<boolean>(false);
  const [insightsGoBack, changeGoBackDestination] = useState('/bulk-scan');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialState, setInitialState] = useState<any>({});

  let webDashboardRoute = runByClientApp({
    onBolster: () => WEB_DASHBOARD_PATHNAME,
    onCheckPhish: () => TYPOSQUATTING_PATHNAME,
  });

  const hideEmailAndCount = useMemo(() => {
    const hideEmail = window.location.pathname.includes(ALL_EMAIL_SUBMISSIONS_SUBPATH);
    if (hideEmail) {
      unsetLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS);
    }
    return hideEmail;
  }, []);

  if (type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX) {
    webDashboardRoute = hideEmailAndCount
      ? `${ABUSE_MAIL_BOX_MODULE_PATH_NAME}/${ALL_EMAIL_SUBMISSIONS_SUBPATH}`
      : ABUSE_MAIL_BOX_MODULE_PATH_NAME;
  }

  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);
  const { threatTypeInsightsDetails } = useThreatDetailsBasedOnSha(
    sha256,
    type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX
      ? ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID
      : '',
  );

  useEffect(() => {
    const unlisten = historyRouter.listen(() => {
      setContactUsMesage('');
      setShowContactUsPage(false);
    });

    return () => {
      unlisten();
      dispatch(setCurrentTableId(''));
      setLocalStorageValue('isNotFromTableCol', false);
    };
  }, [historyRouter, dispatch]);

  const enableCommunity = runByClientApp({
    onBolster: () => false,
    onCheckPhish: () => true,
  });

  useEffect(() => {
    setIsPublic(!getIsLoggedIn());
    dispatch(setPreviousUrlSha(sha256));
    fetchScanResults();
  }, [loggedIn]);

  useEffect(() => {
    if (enableCommunity && userRequestSubmitted) fetchScanResults();
  }, [userRequestSubmitted]);

  useEffect(() => {
    const isLoggedIn = getIsLoggedIn();
    setIsPublic(!isLoggedIn);

    if (isLoggedIn && history.location?.pathname.includes(TYPOSQUATTING_PATHNAME)) {
      setContactUsMesage(
        `Bolster's automated search and analysis technology find, analyzes, and removes sites within a few minutes. Contact Us to take-down malicious results.`,
      );
      setShowContactUsPage(true);
    }
    //enable side bars when on typosquat page;
    _.isFunction(props.changeTyposquatJobStatus) && props.changeTyposquatJobStatus(true);
  }, []);

  useEffect(() => {
    if (triggerType === 'OUTLOOK') {
      changeGoBackDestination('/checkphish/allscans');
    } else {
      history.location?.state?.prevPath.split('/')[2] === 'allscans'
        ? changeGoBackDestination('/checkphish/allscans')
        : changeGoBackDestination('/bulk-scan');
      if (history.location?.state) setInitialState(history.location?.state);
    }
  }, []);

  // TODO : convert this into a custom hook
  const shouldFetchInsightsOnNext = useAppSelector(
    state => state.insightsContainerReducer.shouldFetchInsightsOnNext,
  );
  useEffect(() => {
    if (shouldFetchInsightsOnNext) {
      fetchScanResults();
      dispatch(setShouldFetchInsightsOnNext(false));
    }
  }, [dispatch, shouldFetchInsightsOnNext]);

  const isNonBrandCustomer = () => {
    return triggerType === 'OUTLOOK' ? true : user.type_name === 'non_brand';
  };

  const fetchScanResults = () => {
    setIsLoading(true);
    Promise.allSettled([
      dashboardService.getInsights(timestamp, sha256, isPublic),
      isPublic ? Promise.resolve() : dashboardService.getDisputeVerdict(sha256),
      isPublic ? Promise.resolve() : dashboardService.getInsightsBrandAnalytics(sha256, tableId),
      isPublic
        ? Promise.resolve()
        : dashboardService.getTagsByUrlSha(
            sha256,
            props.type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX ? 'web' : props.type,
          ),
    ])
      .then(async allResponses => {
        const [insightRawData, disupteRawData, brandAnalyticData, tagsData] = allResponses.map(
          (result: any) => result?.value ?? {},
        );
        const scan = insightRawData['scanResults'];
        const srcUrl = _.get(scan, ['srcUrl'], '--');
        const scanSource = _.get(scan, ['scanSource'], '--');
        const ip = _.get(scan, ['ip'], '--');
        const host = _.get(scan, ['host'], '--');
        const scanContext = _.get(scan, ['scan_context']);
        const scanCategoryType = _.get(scan, ['scan_category']);
        const geoData = isPublic ? {} : await dashboardService.getGeoLocation(ip);
        const hostCountInfo: { hostPhishCount?: number; phishingKitsCountOnHost?: number } = {};
        dispatch(setCurrentGroupParentKey(scan.primary_domain));
        runByClientApp({
          onBolster: () => getParentRouteViaFindingStatus(scan.tableStatus || ''),
          onCheckPhish: () => {
            const { pathname } = history.location;
            if (pathname.includes(TYPOSQUATTING_PATHNAME)) {
              return getParentRouteViaFindingStatus(ETyposquattingStatus.TYPOSQUAT_TABLE);
            }
          },
        });
        if (host && host !== '--') {
          const hostPhishCount = isPublic ? {} : await dashboardService.getHostPhishCount(host);
          const phishingKitsCountHost = await dashboardService.getPhishingKitsCountOnHost(host);

          hostCountInfo['hostPhishCount'] = _.get(hostPhishCount, ['result']);
          hostCountInfo['phishingKitsCountOnHost'] = _.get(phishingKitsCountHost, ['result']);
        }

        const abuseAndScanSettingInfo: {
          scanSource?: string;
          brandScanCount?: string | number;
          bolsterScanCount?: string | number;
          firstSeenTS?: string;
          abuseContact?: string;
          brandInfo?: IBrandInfo;
        } = {};
        if (!isPublic) {
          if (!isNonBrandCustomer()) {
            const abuseContact = await dashboardService.getAbuseContact(srcUrl);
            const scanSourceInfo = await dashboardService.getScanSourceInfo(sha256);
            const brandInfo =
              REACT_APP_BOLSTER_APP_TYPE === 'PLATFORM'
                ? await dashboardService.getBrandInfo('brand')
                : null;
            abuseAndScanSettingInfo['scanSource'] = _.get(
              scanSourceInfo,
              ['result', 'scanSource'],
              '--',
            );
            abuseAndScanSettingInfo['brandScanCount'] = _.get(
              scanSourceInfo,
              ['result', 'brandScanCount'],
              '--',
            );
            abuseAndScanSettingInfo['bolsterScanCount'] = _.get(
              scanSourceInfo,
              ['result', 'bolsterScanCount'],
              '--',
            );
            abuseAndScanSettingInfo['firstSeenTS'] = _.get(
              scanSourceInfo,
              ['result', 'firstSeenTS'],
              '--',
            );
            abuseAndScanSettingInfo['abuseContact'] = _.get(abuseContact, ['abuse_email'], '--');
            abuseAndScanSettingInfo['brandInfo'] = brandInfo;
          } else {
            if (enableCommunity) {
              const scanSourceInfo = await dashboardService.getCommunityScanSourceInfo(
                sha256,
                history.location?.pathname.includes(TYPOSQUATTING_PATHNAME),
              );
              abuseAndScanSettingInfo['scanSource'] = _.get(
                scanSourceInfo,
                ['result', 'scanSource'],
                '--',
              );
              abuseAndScanSettingInfo['brandScanCount'] = _.get(
                scanSourceInfo,
                ['result', 'brandScanCount'],
                '--',
              );
              abuseAndScanSettingInfo['bolsterScanCount'] = _.get(
                scanSourceInfo,
                ['result', 'bolsterScanCount'],
                '--',
              );
              abuseAndScanSettingInfo['firstSeenTS'] = _.get(
                scanSourceInfo,
                ['result', 'firstSeenTS'],
                '--',
              );
            }
          }
        }

        const mappedScanResults = {
          ...insightsData.scanResults,

          status: _.get(scan, ['status'], '--'),
          srcURL: srcUrl,
          redirectURL: _.get(scan, ['dstUrl'], '--'),
          tld: _.get(scan, ['tld'], '--'),
          brand: _.get(scan, ['brandName'], '--'),
          brandId: _.get(scan, ['brandId'], '--'),
          subBrandId: _.get(brandAnalyticData, ['result', 'urlInfo', 0, 'sub_brand_id'], '--'),
          subBrandName: _.get(
            brandAnalyticData,
            ['result', 'urlInfo', 0, 'sub_brand_display_name'],
            '--',
          ),
          networkOwner: _.get(scan, ['networkOwner'], '--'),
          asn: scan['asn'] || '--',
          ip: ip,
          timestamp: _.get(scan, ['timestamp'], '--'),
          createdTS: _.get(scan, ['createdTS'], '--'),
          imagePath: _.get(scan, ['imagePath'], '--'),
          cert: _.get(scan, ['cert', 'issuer'], '--'),
          disposition: _.get(scan, ['disposition'], '--'),
          host: _.get(scan, ['host'], '--'),
          primaryDomain: _.get(scan, ['primary_domain'], '--'),
          jobId: _.get(scan, ['jobId'], '--'),
          takedownTS: _.get(scan, ['takedownTS'], '--'),
          takedownCount: _.get(scan, ['takedownCount'], '--'),
          annotations: _.get(scan, ['logoDetection', 'annotations'], []),
          finalCategory: _.get(scan, ['finalCategory'], '--'),
          mxRecords: _.get(scan, ['mxRecords'], '--'),
          hasMxRecords: _.get(scan, ['hasMxRecords'], '--'),
          allMXRecords: _.get(scan, ['allMXRecords'], []),
          registrationDate: _.get(scan, ['registrationDate'], '--'),
          registrant: _.get(scan, ['registrant'], '--'),
          nameservers: _.get(scan, ['nameservers'], '--'),
          sfbDetected: _.has(brandAnalyticData?.result?.urlInfo[0], 'sfb_detected')
            ? _.get(brandAnalyticData, ['result', 'urlInfo', 0, 'sfb_detected'])
              ? 'True'
              : 'False'
            : '--',
          scanSettings: _.has(scan, ['scan_settings'])
            ? {
                scanLocation: scan.scan_settings?.scan_location || '--',
                timeout: scan.scan_settings?.wait || '--',
                userAgent: scan.scan_settings?.user_agent || '--',
              }
            : {},
          url_sha256: sha256,
          tags: tagsData?.tags || [],
          countryCode: _.get(
            geoData?.insights,
            ['countryCode'],
            _.get(scan, ['countryCode'] || '--'),
          ),
          location: _.get(geoData?.insights, ['country'], _.get(scan, ['country']) || '--'),
          ...abuseAndScanSettingInfo,
          ...hostCountInfo,
          scanCategoryType,
          scanContext,
          sourceFeed: _.get(scan, ['sourceFeed'], '--'),
          ...(scan.takedownEnquiry && {
            takedownEnquiry: scan.takedownEnquiry,
          }),
          firstSeenTS: _.get(scan, ['firstSeenTS'], abuseAndScanSettingInfo.firstSeenTS),
          redirectedChains: _.get(scan, ['redirectedChains'], []),
        };

        const mappedInsightsData = {
          scanResults: mappedScanResults,
          markerPosition: {
            lat: _.get(geoData?.insights, ['location', 'latitude'], 0),
            lng: _.get(geoData?.insights, ['location', 'longitude'], 0),
          },
          certList: _.get(scan, ['cert', 'hosts'], '--'),
        };

        setInsightsData(mappedInsightsData);
        setDisputeData({
          attachments: disupteRawData.attachments,
          disposition: disupteRawData.reporter_disposition,
          bolsterDisposition: disupteRawData.bolster_disposition,
          status: disupteRawData.status,
          user: disupteRawData.user,
          timestamp: disupteRawData.created_ts,
          comments: disupteRawData.reporter_comment,
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderBackPages = () => {
    if (goBackUrl) {
      history.push(goBackUrl);
      dispatch(setGoBackUrl(''));
      return;
    }
    // TODO: refactor this to use the goBackUrl, and remove the switch statement
    if (type === appConstants.CONTENT_TYPE.WEB) {
      if (activeRoute && currentTableId.length === 0) {
        switch (activeRoute) {
          case PRE_MALICIOUS_TABLE_ID_DASH:
            history.push(PRE_MALICIOUS_PATHNAME);
            break;
          case POST_MALICIOUS_TABLE_ID_DASH:
            history.push(POST_MALICIOUS_PATHNAME);
            break;
          case TAKEDOWN_MALICIOUS_TABLE_ID_DASH:
            history.push(TAKEDOWN_MALICIOUS_PATHNAME);
            break;
          case TYPOSQUAT_DASHBOARD_TABLE_ID_DASH:
            history.push(TYPOSQUATTING_DASHBOARD_PATHNAME);
            break;
          case TYPO_PRE_MALICIOUS_TABLE_ID_DASH:
            history.push(TYPOSQUATTING_PREMALICIOUS_PATHNAME, initialState);
            break;
          case TYPO_MALICIOUS_TABLE_ID_DASH:
            history.push(TYPOSQUATTING_MALICIOUS_PATHNAME, initialState);
            break;
          case TYPOSQUAT_TABLE_ID_DASH:
            history.push(TYPOSQUATTING_MASTER_PATHNAME, initialState);
            break;
          default:
            break;
        }
      } else if (currentTableId === GLOBAL_TAKEDOWN_TABLE_ID) {
        history.push(USER_GLOBAL_TAKEDOWN_PATHNAME);
      }
    } else if (type === appConstants.CONTENT_TYPE.BULK_SCAN) {
      isNonBrandCustomer() ? history.push(insightsGoBack) : history.push('/bulk-scan');
    } else if (type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX) {
      const lastVisitedPage = getLastVisitedPage();
      history.push(lastVisitedPage);
    }
  };

  //Disposition/Dispute related fucntions
  const toggleDisputeModal = () => {
    setIsDisputeModalShown(!isDisputeModalShown);
  };
  const submitDisputeVerdict = (formValues: any) => {
    const scanResults: InsightsDto = insightsData.scanResults;
    const { disposition, comments, attachments } = formValues;

    if (!disposition) {
      setDisputeError('Please select a disposition.');
      return;
    }

    const urlDetails: IUrlDetails[] = [
      {
        urlSHA256: sha256,
        srcUrl: scanResults.srcURL,
        currentDisposition: scanResults.disposition,
        currentBrand: scanResults.brand,
        primaryDomain: scanResults.primaryDomain,
        ccEmailList: scanResults.abuseContact,
        reporter: scanResults.brand,
        abuseContactEmailList: scanResults.abuseContact,
        reporterEmail: user.email,
        status: scanResults.status,
        reportingEntity: 'user',
        googleSafeBrowsing: false,
        apwgBlocklist: false,
        tld: scanResults.tld,
        createdTS: scanResults.createdTS,
        dstUrl: scanResults.dstUrl,
      },
    ];

    const disputeFormData = new FormData();
    disputeFormData.append('customerDisposition', disposition);
    disputeFormData.append('comments', comments || '--');

    //New Properties for dispute verdict submission
    disputeFormData.append('brandMnemonic', formValues.brandMnemonic);
    disputeFormData.append('urlDetails', JSON.stringify(urlDetails));

    for (let i = 0; i < attachments.length; i++) {
      disputeFormData.append('files', attachments[i]);
    }

    setSendingDisputeRequest(true);
    dashboardService
      .submitDisputeVerdict(disputeFormData)
      .then(dispute => {
        setIsDisputeModalShown(false);
        setDisputeData({
          disposition,
          comments,
          status: dispute.status,
          user: dispute.user,
          timestamp: dispute.created_ts,
          attachments: dispute.attachments,
        });

        dispatch(setShouldFetchInsightsOnNext(true));
      })
      .catch(err => {
        alertError(err);
      })
      .finally(() => {
        setSendingDisputeRequest(false);
      });
  };

  const renderDomWithTooltip = (children: any, tooltip: string) => {
    return (
      <OverlayTrigger
        placement={'bottom'}
        overlay={
          <Tooltip id={'tooltip-' + Math.random()} className={'ask-for-sign-up-tooltip'}>
            {tooltip} (available on Bolster Platform)
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    );
  };

  const renderHistory = (
    isResolved: boolean,
    disputeDispositionLabel: string,
    bolsterDisposition: string,
    disputeDisposition: string,
    attachedFiles: {
      name: string;
      path: string;
      createdTS: string;
    }[],
  ) => {
    return (
      <div className={'dispute'}>
        <div
          className={`dispute-submitted-text d-flex align-items-center ${
            isDisputeDetailShown ? 'open' : ''
          } ${isResolved ? 'history' : ''}`}
          onClick={() => setIsDisputeDetailShown(!isDisputeDetailShown)}
        >
          {isResolved ? (
            <div className='history-wrapper'>
              <div className='icon'>
                <ClockIcon color='black' />
              </div>
              History
            </div>
          ) : (
            <span>
              Disputed as: <span className='dispute-disposition'>{disputeDispositionLabel}</span> -{' '}
              {DISPUTE_STATUS.under_review}
            </span>
          )}
        </div>
        <DisputeStatus
          bolsterDisposition={bolsterDisposition}
          disposition={disputeDisposition}
          status={disputeData.status}
          user={disputeData.user}
          timestamp={disputeData.timestamp}
          comments={disputeData.comments}
          hideHeader={!isResolved}
          attachedFiles={attachedFiles}
        />
      </div>
    );
  };
  const renderDisputeDom = () => {
    const currentDisposition = insightsData.scanResults.disposition;
    const bolsterDisposition = disputeData.bolsterDisposition;
    const disputeDisposition = disputeData.disposition;
    const disputeSubmitted = !_.isEmpty(disputeData.status);
    const isResolved = disputeData.status !== 'under_review';
    const dispositionStatusClassName = 'disposition-status';
    let disputeDispositionLabel = '';
    const attachedFiles = disputeData?.attachments?.map((fileName: string) => {
      return {
        name: fileName,
        path: `/api/v1/dispute-verdict/${sha256}/attachment/${fileName}`,
        createdTS: moment(disputeData.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      };
    });

    if (disputeDisposition) {
      disputeDispositionLabel = DISPUTE_DISPOSITION_OPTIONS[disputeDisposition];
    }

    return (
      <div className={'dispute-dom-container d-flex align-items-center'}>
        {insightsData.scanResults.sfbDetected === 'True' && (
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Tooltip id={'tooltip-' + Math.random()} className={'ask-for-sign-up-tooltip'}>
                Blocked by Google Safebrowsing
              </Tooltip>
            }
          >
            <div className={`${dispositionStatusClassName} sfb-blocked pill`}>SFB Blocked</div>
          </OverlayTrigger>
        )}
        <DispositionStatus
          status={currentDisposition}
          className={dispositionStatusClassName}
          displayedStyle={'pill'}
        />
        {runByClientApp({
          onBolster: () => {
            if (user.type_name === 'brand' && isPublic) {
              return renderDomWithTooltip(
                <div className={'disabled dispute-button'}>Dispute</div>,
                'Dispute Verdict',
              );
            } else {
              if (!disputeSubmitted) {
                return !isBrandReadOnlyUser(user) ? (
                  <Button onClick={toggleDisputeModal}>Dispute</Button>
                ) : null;
              } else {
                return renderHistory(
                  isResolved,
                  disputeDispositionLabel,
                  bolsterDisposition,
                  disputeDisposition,
                  attachedFiles,
                );
              }
            }
          },
          onCheckPhish: () => {
            if (isPublic || !disputeSubmitted) {
              return <></>;
            } else {
              return renderDomWithTooltip(
                <div className={'disabled dispute-button'}>Dispute</div>,
                'Dispute Verdict',
              );
            }
          },
        })}
        {type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX && !hideEmailAndCount && (
          <ThreatTypesReportCount reportCount={threatTypeInsightsDetails?.count || 0} />
        )}
      </div>
    );
  };

  const subBarItems: ISubBarItem[] = [
    {
      href: `${getNavPathnameForInsightPage()}/insights/${timestamp}/${sha256}`,
      label: 'Insights',
      type: EMenuType.MENU_HEADER,
      id: '/insights',
      disabled: false,
    },
    ...(type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX && !hideEmailAndCount
      ? [
          {
            href: `${getNavPathnameForInsightPage()}/insights/emailDetails/${timestamp}/${sha256}`,
            label: 'Email Details',
            type: EMenuType.MENU_HEADER,
            id: '/emailDetails',
            disabled: isPublic,
          },
        ]
      : []),
    {
      href: `${getNavPathnameForInsightPage()}/insights/threat-intelligence/${timestamp}/${sha256}`,
      label: 'Threat Intelligence',
      type: EMenuType.MENU_HEADER,
      id: '/insights/threat-intelligence',
      disabled: false,
    },
    ...(user.type_name === 'brand' && !enableCommunity
      ? [
          {
            href: `${getNavPathnameForInsightPage()}/insights/timeline/${timestamp}/${sha256}`,
            label: 'TimeLine',
            type: EMenuType.MENU_HEADER,
            id: '/timeline',
            disabled: isPublic ? true : false,
          },
        ]
      : []),
    {
      href: `${getNavPathnameForInsightPage()}/insights/dom/${timestamp}/${sha256}`,
      label: 'Dom Tree',
      type: EMenuType.MENU_HEADER,
      id: '/dom',
      disabled: isPublic ? true : false,
    },
    {
      href: `${getNavPathnameForInsightPage()}/insights/whois/${timestamp}/${sha256}`,
      label: 'Whois Info',
      type: EMenuType.MENU_HEADER,
      id: '/whois',
      disabled: isPublic ? true : false,
    },
  ];

  return (
    <div className='insights-parent-container'>
      <AuthenticationWrapper skipAuthentication={isPublic}>
        <PageTitleInsights
          title={insightsData.scanResults.srcURL}
          backFn={isPublic ? _.noop : renderBackPages}
          toolsLeft={renderDisputeDom()}
          tools={
            isPublic ? (
              <div className={'timeline-and-whois d-flex justify-content-space-between'}>
                {
                  <React.Fragment>
                    <Link
                      to={{
                        pathname: `/sign-up`,
                        search: `?brand=${insightsData.scanResults.brand}`,
                      }}
                    >
                      <ButtonRB variant='primary' className='mr-3'>
                        {REACT_APP_BOLSTER_APP_TYPE === 'CHECKPHISH'
                          ? 'SIGN UP TO CHECKPHISH'
                          : 'SIGN UP FOR COMPLETE BOLSTER PLATFORM'}
                      </ButtonRB>
                    </Link>
                    <Link
                      to={{
                        pathname: `/sign-in`,
                        search: `?brand=${insightsData.scanResults.brand}`,
                      }}
                    >
                      <ButtonRB variant='primary' className='mr-3'>
                        LOGIN
                      </ButtonRB>
                    </Link>
                  </React.Fragment>
                }
              </div>
            ) : (
              <RequestTakedownContainer
                status={insightsData.scanResults.status}
                abuseContact={insightsData.scanResults.abuseContact}
                disposition={insightsData.scanResults.disposition}
                type={type}
                user={user}
                brandId={insightsData.scanResults.brandId}
                subBrandId={insightsData.scanResults.subBrandId}
                brandInfo={insightsData.scanResults.brandInfo}
                urlSha256={sha256}
                loggedIn={loggedIn}
                alertError={alertError}
                alertSuccess={alertSuccess}
                insightsData={insightsData.scanResults}
                userEmail={user.email}
                enableCommunity={enableCommunity as boolean}
              />
            )
          }
        />
        {enableCommunity && (
          <div className='community-link-container'>
            <a href='https://community.bolster.ai/' target='_blank' rel='noreferrer'>
              Visit our community
            </a>{' '}
            to discuss all things phishing: from best practices to step by step guides on how to
            take down a site
          </div>
        )}
        <SubNavigationBar
          isPublic={isPublic}
          subBarItems={subBarItems}
          moduleType={type}
          hideEmailAndCount={hideEmailAndCount}
        />

        <LoadingWrapper isLoading={isLoading}>
          {!isLoading && (
            <>
              <Route
                path={`/bulk-scan/insights/${timestamp}/${sha256}`}
                render={() => (
                  <Insights isLoading={isLoading} insightsData={insightsData} type={type} />
                )}
              />
              <Route
                path={`/bulk-scan/insights/timeline/${timestamp}/${sha256}`}
                render={() => <Timeline urlSHA256={sha256} type={type} />}
              />{' '}
              <Route
                path={`/bulk-scan/insights/whois/${timestamp}/${sha256}`}
                render={() => (
                  <Whois
                    ip={insightsData.scanResults.ip}
                    host={insightsData.scanResults.host}
                    domain={insightsData.scanResults.primaryDomain}
                    type={type}
                  />
                )}
              />{' '}
              <Route
                path={`/bulk-scan/insights/dom/${timestamp}/${sha256}`}
                render={() => (
                  <DomTree timestamp={timestamp.toString()} urlSHA256={sha256} type={type} />
                )}
              />
              <Route
                path={`/bulk-scan/insights/threat-intelligence/${timestamp}/${sha256}`}
                render={() => (
                  <ThreatIntelligenceComponent
                    ip={insightsData.scanResults.ip}
                    host={insightsData.scanResults.host}
                    scanResults={insightsData.scanResults}
                    urlSHA256={sha256}
                  />
                )}
              />
              <Route
                path={`${webDashboardRoute}/insights/${timestamp}/${sha256}`}
                render={() => (
                  <Insights
                    isLoading={isLoading}
                    insightsData={insightsData}
                    type={type}
                    isPublic={isPublic}
                    webDashboardRoute={webDashboardRoute}
                  />
                )}
              />
              {!isPublic && (
                <>
                  <Route
                    path={`${webDashboardRoute}/insights/timeline/${timestamp}/${sha256}`}
                    render={() => <Timeline urlSHA256={sha256} type={type} />}
                  />
                  <Route
                    path={`${webDashboardRoute}/insights/emailDetails/${timestamp}/${sha256}`}
                    render={() => (
                      <EmailDetails
                        isLoading={isLoading}
                        sha256={sha256}
                        totalEmailsCount={threatTypeInsightsDetails?.count || 0}
                      />
                    )}
                  />
                  <Route
                    path={`${webDashboardRoute}/insights/dom/${timestamp}/${sha256}`}
                    render={() => (
                      <DomTree
                        timestamp={timestamp.toString()}
                        urlSHA256={sha256}
                        type={type === appConstants.CONTENT_TYPE.ABUSE_MAILBOX ? 'web' : type}
                      />
                    )}
                  />
                  <Route
                    path={`${webDashboardRoute}/insights/whois/${timestamp}/${sha256}`}
                    render={() => (
                      <Whois
                        ip={insightsData.scanResults.ip}
                        host={insightsData.scanResults.host}
                        domain={insightsData.scanResults.primaryDomain}
                        type={type}
                      />
                    )}
                  />
                </>
              )}
              <Route
                path={`${webDashboardRoute}/insights/threat-intelligence/${timestamp}/${sha256}`}
                render={() => (
                  <ThreatIntelligenceComponent
                    ip={insightsData.scanResults.ip}
                    host={insightsData.scanResults.host}
                    scanResults={insightsData.scanResults}
                    urlSHA256={sha256}
                    isPublic={isPublic}
                  />
                )}
              />
            </>
          )}

          <InsightsDisputeModal
            isDisputeModalShown={isDisputeModalShown}
            toggleDisputeModal={toggleDisputeModal}
            disputeData={disputeData}
            disputeError={disputeError}
            sendingDisputeRequest={sendingDisputeRequest}
            selectedTheme={selectedTheme}
            submitDisputeVerdict={submitDisputeVerdict}
          />
          {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
          {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
        </LoadingWrapper>
      </AuthenticationWrapper>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  const { loggedIn } = state.login;
  return {
    user,
    loggedIn,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  runByClientApp: appActions.runByClientApp,
};
export default connect(mapStateToProps, mapDispatchToProps)(InsightsContainer);
