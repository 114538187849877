export const bankOfBolsterDashboardPocData = {
  abuseMailBoxId: 'test-Bank of Bolster@0day.com',
  emptyState: false,
  threatDistribution: {
    'Phishing Links': {
      email: 3140,
      sms: 0,
    },
    'Scam Links': {
      email: 1480,
      sms: 0,
    },
    'Spam Links': {
      email: 4000,
      sms: 0,
    },
    'Malicious Sender Domains': {
      email: 1200,
      sms: 0,
    },
    'Malicious Intent': {
      email: 10427,
      sms: 0,
    },
    'Malware Attachments': {
      email: 2380,
      sms: 0,
    },
    'Malicious Phone Number': {
      email: 1012,
      sms: 0,
    },
  },
  userDomains: {
    'gmail.com': 2779,
    'aol.com': 1153,
    'yahoo.com': 1010,
    'free.fr': 735,
    'icloud.com': 674,
    'bigpond.com': 655,
    'hotmail.com': 586,
    'att.net': 487,
    'web.de': 462,
    'bellsouth.net': 355,
    'gmx.de': 351,
    'yahoo.co.uk': 282,
    'verizon.net': 275,
    'yahoo.com.sg': 248,
    'lists.torproject.org': 245,
    'charter.net': 240,
    'sbcglobal.net': 194,
    'yahoo.de': 191,
    'comcast.net': 176,
    'outlook.com': 155,
    'orange.fr': 120,
    'btinternet.com': 112,
    'juno.com': 102,
    'yahoo.es': 102,
    'Bank of Bolster.com': 92,
    'sky.com': 78,
    'bibliophilegroup.com': 76,
    'gmx.net': 74,
    'yahoo.gr': 64,
    'bluewin.ch': 59,
    'modimining.co.za': 55,
    'yahoo.fr': 55,
    'free.frCc': 53,
    't-online.hu': 52,
    'zpr.uni-koeln.de': 52,
    'mac.com': 49,
    'hotmail.co.uk': 47,
    'jcom.home.ne.jp': 44,
    'bigpond.net.au': 43,
    'me.com': 42,
    'i.softbank.jp': 38,
    'munichile.cl': 38,
    'earthlink.net': 36,
    'msn.com': 36,
    'intgovforum.org': 31,
    'caneweb.com': 30,
    'cox.net': 28,
    'lists.squeakfoundation.org': 28,
    'googlemail.com': 27,
    'failed.com': 26,
  },
  threatActorDomains: {
    'account.Bank of Bolster.com': 1550,
    'icloud.com': 1268,
    'gmail.com': 921,
    'Bank of Bolster.fr': 667,
    'eumail.docusign.net': 247,
    'mailer.kw.com': 214,
    'promote.weebly.com': 208,
    'event.eventbrite.com': 171,
    'gitlab.torproject.org': 156,
    'notectacle.com': 145,
    'interia.eu': 139,
    'solutioninstitution.com': 124,
    'Bank of Bolster.com': 120,
    'movies.com': 119,
    'bigpond.com': 115,
    'parkguard.ch': 94,
    'join.Bank of Bolster.com': 84,
    'wfglobal.org': 84,
    'efolio.auburn.edu': 79,
    'ghfhgfhgf.duckdns.org': 74,
    'emails.macys.com': 70,
    'members.Bank of Bolster.com': 68,
    'sportpark-vital.de': 67,
    'aksaramaya.com': 64,
    'bober.com': 64,
    'bobr.com': 64,
    'fastermeet.net': 59,
    'ac-creteil.fr': 58,
    'hss.de': 58,
    'contabilix.com.br': 56,
    'p2bi.com': 56,
    'yahoo.com': 55,
    'smartbarrel.io': 50,
    'mailer.Bank of Bolster.com': 49,
    'aphp.fr': 48,
    'computingmi.co.uk': 47,
    'gmx.de': 47,
    'fuso-inc.co.jp': 46,
    'chpter.co': 45,
    'owlpractice.ca': 43,
    'top.msqstamp.com': 43,
    'vicinity.com.au': 43,
    'torproject.org': 41,
    'secure.Bank of Bolster.com': 40,
    'gigaprize.co.jp': 39,
    'drewry.co.uk': 38,
    'caetanoretail.es': 37,
    'email.b12.io': 37,
    'Bank of Bolster.frDate': 36,
    'grupoviamaquinas.com.br': 35,
  },
  threatActorDomainsV2: {
    'Bank of Bolster.com': {
      count: 1550,
      type: 'malicious',
    },
    'bankofbolster.co.uk': {
      count: 158,
      type: 'malicious',
    },
    'secure-bankofbolster.com': {
      count: 142,
      type: 'malicious',
    },
    'bankofbolster-secure.com': {
      count: 138,
      type: 'malicious',
    },
    'bankofbolster-verify.com': {
      count: 125,
      type: 'malicious',
    },
    'bankofbolster-online.com': {
      count: 112,
      type: 'malicious',
    },
    'Bank of Bolster.fr': {
      count: 667,
      type: 'malicious',
    },
    'icloud.com': {
      count: 1268,
      type: 'suspicious',
    },
    'gmail.com': {
      count: 921,
      type: 'suspicious',
    },
    'eumail.docusign.net': {
      count: 247,
      type: 'suspicious',
    },
    'mailer.kw.com': {
      count: 214,
      type: 'suspicious',
    },
    'promote.weebly.com': {
      count: 208,
      type: 'suspicious',
    },
    'event.eventbrite.com': {
      count: 171,
      type: 'suspicious',
    },
    'mail.bankofbolster.com': {
      count: 165,
      type: 'suspicious',
    },
    'secure.bankofbolster.net': {
      count: 98,
      type: 'suspicious',
    },
    'mail.bankofbolster.net': {
      count: 87,
      type: 'suspicious',
    },
  },
  emailSubmissions: {
    total: 40869,
    users: 11461,
    trendsChart: {
      '2024-10-03': {
        total: 607,
        malicious: 60,
      },
      '2024-10-04': {
        total: 529,
        malicious: 50,
      },
      '2024-10-05': {
        total: 106,
        malicious: 30,
      },
      '2024-10-06': {
        total: 1019,
        malicious: 300,
      },
      '2024-10-07': {
        total: 1370,
        malicious: 355,
      },
      '2024-10-08': {
        total: 1381,
        malicious: 584,
      },
      '2024-10-09': {
        total: 1195,
        malicious: 379,
      },
      '2024-10-10': {
        total: 1197,
        malicious: 428,
      },
      '2024-10-11': {
        total: 1145,
        malicious: 381,
      },
      '2024-10-12': {
        total: 728,
        malicious: 260,
      },
      '2024-10-13': {
        total: 828,
        malicious: 388,
      },
      '2024-10-14': {
        total: 1349,
        malicious: 532,
      },
      '2024-10-15': {
        total: 1400,
        malicious: 554,
      },
      '2024-10-16': {
        total: 1262,
        malicious: 480,
      },
      '2024-10-17': {
        total: 1408,
        malicious: 604,
      },
      '2024-10-18': {
        total: 1513,
        malicious: 595,
      },
      '2024-10-19': {
        total: 1423,
        malicious: 478,
      },
      '2024-10-20': {
        total: 1091,
        malicious: 419,
      },
      '2024-10-21': {
        total: 1452,
        malicious: 644,
      },
      '2024-10-22': {
        total: 1597,
        malicious: 632,
      },
      '2024-10-23': {
        total: 1230,
        malicious: 429,
      },
      '2024-10-24': {
        total: 1544,
        malicious: 544,
      },
      '2024-10-25': {
        total: 1389,
        malicious: 548,
      },
      '2024-10-26': {
        total: 988,
        malicious: 331,
      },
      '2024-10-27': {
        total: 1140,
        malicious: 392,
      },
      '2024-10-28': {
        total: 1566,
        malicious: 622,
      },
      '2024-10-29': {
        total: 1153,
        malicious: 449,
      },
      '2024-10-30': {
        total: 1069,
        malicious: 274,
      },
      '2024-10-31': {
        total: 1108,
        malicious: 246,
      },
      '2024-11-01': {
        total: 1157,
        malicious: 283,
      },
      '2024-11-02': {
        total: 1054,
        malicious: 318,
      },
      '2024-11-03': {
        total: 929,
        malicious: 214,
      },
      '2024-11-04': {
        total: 1420,
        malicious: 440,
      },
      '2024-11-05': {
        total: 1443,
        malicious: 431,
      },
      '2024-11-06': {
        total: 1079,
        malicious: 331,
      },
    },
  },
  emailCategories: {
    'Bank of Bolster Targeted Malicious': 13569,
    'Generic Malicious': 1596,
    'Bank of Bolster Official Emails': 209,
    Other: 25495,
  },
  takedownTimeAnalytics: {
    phish: {
      avg: '5 days',
      median: '4 days',
      dayAggregations: {
        '1': 9800,
        '2': 5900,
        '3': 7200,
        '4': 3100,
        '5': 3800,
        '6': 2500,
        '7': 1200,
        '8': 980,
        '9': 750,
        '10': 520,
        '11': 290,
        '12': 60,
        '13': 30,
        '14': 300,
        '15': 370,
        '16': 140,
        '17': 120,
        '18': 90,
        '19': 80,
        '20': 70,
        '21': 60,
        '22': 50,
        '23': 39,
        '24': 28,
        '25': 27,
        '26': 26,
        '27': 25,
        '28': 24,
        '29': 230,
        '30': 220,
        '31': 210,
      },
    },
    scam: {
      avg: '4 days',
      median: '3 days',
      dayAggregations: {
        '1': 8800,
        '2': 2400,
        '3': 7800,
        '4': 2900,
        '5': 3500,
        '6': 2300,
        '7': 1100,
        '8': 890,
        '9': 680,
        '10': 480,
        '11': 270,
        '12': 55,
        '13': 28,
        '14': 80,
        '15': 140,
        '16': 30,
        '17': 110,
        '18': 85,
        '19': 75,
        '20': 65,
        '21': 55,
        '22': 45,
        '23': 35,
        '24': 25,
        '25': 24,
        '26': 23,
        '27': 22,
        '28': 21,
        '29': 210,
        '30': 200,
        '31': 190,
      },
    },
    maliciousSenderDomain: {
      avg: '15 hours',
      median: '7.5 hours',
      dayAggregations: {
        '1': 2347,
        '2': 1893,
        '3': 4156,
        '4': 2901,
        '5': 3642,
        '6': 1576,
        '7': 4289,
        '8': 2134,
        '9': 3867,
        '10': 1945,
        '11': 4512,
        '12': 2678,
        '13': 3291,
        '14': 1734,
        '15': 4023,
        '16': 2456,
        '17': 3789,
        '18': 1823,
        '19': 4367,
        '20': 2589,
        '21': 3912,
        '22': 1678,
        '23': 4145,
        '24': 2234,
        '25': 3856,
        '26': 1967,
        '27': 4478,
        '28': 2745,
        '29': 3123,
        '30': 1845,
        '31': 4256,
      },
    },
  },
  //   [
  //     {
  //         "sha256": "edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c",
  //         "timestamp": "1725723497162",
  //         "sourceUrl": "http://mybolbankmoney.com/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c",
  //         "timestamp": "1725723497461",
  //         "sourceUrl": "http://oblbank.com/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921",
  //         "timestamp": "1725723497936",
  //         "sourceUrl": "http://mybolbankcard.net/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c",
  //         "timestamp": "1725723497692",
  //         "sourceUrl": "http://belbank.ru/",
  //         "hostingCountry": "NL",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2",
  //         "timestamp": "1725723496306",
  //         "sourceUrl": "http://bolbank.org/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c",
  //         "timestamp": "1725723496521",
  //         "sourceUrl": "http://blobank.ru/",
  //         "hostingCountry": "US",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f",
  //         "timestamp": "1725723497393",
  //         "sourceUrl": "http://bolbankcard.com/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6",
  //         "timestamp": "1725723496794",
  //         "sourceUrl": "http://boblank.com/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e",
  //         "timestamp": "1725723496216",
  //         "sourceUrl": "http://bankofbolsterscam.com/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "d60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba",
  //         "timestamp": "1725723496728",
  //         "sourceUrl": "http://bankofbolstermoney.com/",
  //         "hostingCountry": "",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     },
  //     {
  //         "sha256": "24c7c8b7d506af17be5ee21599b7aff759ce36ae2514fc482012d65d7af034e3",
  //         "timestamp": "1725723496880",
  //         "sourceUrl": "http://bombank.com/",
  //         "hostingCountry": "US",
  //         "firstSeen": "2024-07-09T23:08:14.858Z"
  //     }
  // ]
  topSuspiciousThreats: {
    phish: [
      {
        sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-05T15:50:29.190Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://bolbank.org/',
        submissionTs: '2024-11-03T18:33:54.000Z',
        hostingProvider: 'AliCloud',
        count: 34,
        hostingCountry: 'US',
        timestamp: 1725723496306,
      },
      {
        sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-10-22T17:03:01.969Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://blobank.ru/',
        submissionTs: '2024-10-19T03:40:40.000Z',
        hostingProvider: 'Amazon.com, Inc.',
        count: 28,
        hostingCountry: 'US',
        timestamp: 1725723496521,
      },
      {
        sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-06T10:00:27.442Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://bolbankcard.com/',
        submissionTs: '2024-11-04T05:40:25.000Z',
        hostingProvider: 'Cloudflare, Inc.',
        count: 26,
        hostingCountry: 'CA',
        timestamp: 1725723497393,
      },
      {
        sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-10-26T23:07:31.941Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://boblank.com/',
        submissionTs: '2024-10-24T16:05:02.000Z',
        hostingProvider: 'IPV6 Internet Ltda',
        count: 20,
        hostingCountry: 'BR',
        timestamp: 1725723496794,
      },
      {
        sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-06T14:49:46.460Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://bankofbolstermoney.com/',
        submissionTs: '2024-11-06T14:49:19.000Z',
        hostingProvider: 'Cloudflare, Inc.',
        count: 19,
        hostingCountry: 'US',
        timestamp: 1725723496728,
      },
    ],
    scam: [
      {
        sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-01T08:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bankofbolsterscam.com/',
        submissionTs: '2024-10-21T10:00:00.000Z',
        hostingProvider: 'Example Hosting Provider',
        count: 15,
        hostingCountry: 'CA',
        timestamp: 1725723496216,
      },
      {
        sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-02T09:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://blobank.ru/',
        submissionTs: '2024-10-23T11:00:00.000Z',
        hostingProvider: 'Another Hosting Provider',
        count: 10,
        hostingCountry: 'US',
        timestamp: 1725723496521,
      },
      {
        sha256: '3a6a773936b7e1c479f98d85641b3b4e69cf00db9d61ec7e570ce74007f70ead',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-03T10:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bobank.org/',
        submissionTs: '2024-10-25T12:00:00.000Z',
        hostingProvider: 'Yet Another Hosting Provider',
        count: 8,
        hostingCountry: 'GB',
        timestamp: 1725723496306,
      },
      {
        sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-04T11:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bolbankcard.com/',
        submissionTs: '2024-10-27T13:00:00.000Z',
        hostingProvider: 'Scam Hosting Provider',
        count: 12,
        hostingCountry: 'AU',
        timestamp: 1725723497393,
      },
      {
        sha256: '24c7c8b7d506af17be5ee21599b7aff759ce36ae2514fc482012d65d7af034e3',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-05T12:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bombank.com/',
        submissionTs: '2024-10-29T14:00:00.000Z',
        hostingProvider: 'Fake Hosting Provider',
        count: 9,
        hostingCountry: 'US',
        timestamp: 1725723496880,
      },
    ],
    spam: [
      {
        sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-05T15:50:29.190Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://bolbank.org/',
        submissionTs: '2024-11-03T18:33:54.000Z',
        hostingProvider: 'AliCloud',
        count: 34,
        hostingCountry: 'US',
        timestamp: 1725723496306,
      },
      {
        sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-10-22T17:03:01.969Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://blobank.ru/',
        submissionTs: '2024-10-19T03:40:40.000Z',
        hostingProvider: 'Amazon.com, Inc.',
        count: 28,
        hostingCountry: 'US',
        timestamp: 1725723496521,
      },
      {
        sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-06T10:00:27.442Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://bolbankcard.com/',
        submissionTs: '2024-11-04T05:40:25.000Z',
        hostingProvider: 'Cloudflare, Inc.',
        count: 26,
        hostingCountry: 'CA',
        timestamp: 1725723497393,
      },
      {
        sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-10-26T23:07:31.941Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://boblank.com/',
        submissionTs: '2024-10-24T16:05:02.000Z',
        hostingProvider: 'IPV6 Internet Ltda',
        count: 20,
        hostingCountry: 'BR',
        timestamp: 1725723496794,
      },
      {
        sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-06T14:49:46.460Z',
        status: 'active',
        disposition: 'phish',
        sourceUrl: 'http://bankofbolstermoney.com/',
        submissionTs: '2024-11-06T14:49:19.000Z',
        hostingProvider: 'Cloudflare, Inc.',
        count: 19,
        hostingCountry: 'US',
        timestamp: 1725723496728,
      },
    ],
    senderDomain: [
      {
        sha256: 'b74fa7d299272c835dc5a5ee2f109dfc7d43dab080b5a9a003196233f1a67c61',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-06T13:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bobank.net/',
        submissionTs: '2024-10-31T15:00:00.000Z',
        hostingProvider: 'Another Scam Hosting Provider',
        count: 11,
        hostingCountry: 'US',
        timestamp: 1725723496306,
      },
      {
        sha256: '98d7b8a16583151541e8fffb420282308486c7aacf85b79023d9adc0ff24d051',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-07T14:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://boba.bolster.io/',
        submissionTs: '2024-11-02T16:00:00.000Z',
        hostingProvider: 'Yet Another Fake Hosting Provider',
        count: 7,
        hostingCountry: 'US',
        timestamp: 1725723496521,
      },
      {
        sha256: '929575c8185eaa123cf9ef32a49aa392d86ced5b7ed1141a68cbfbd38a1e8d1b',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-08T15:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bolbank.com/',
        submissionTs: '2024-11-04T17:00:00.000Z',
        hostingProvider: 'Fake Phishing Hosting Provider',
        count: 10,
        hostingCountry: 'GB',
        timestamp: 1725723497393,
      },
      {
        sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-09T16:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://bolbank.org/',
        submissionTs: '2024-11-06T18:00:00.000Z',
        hostingProvider: 'Another Fake Phishing Hosting Provider',
        count: 8,
        hostingCountry: 'AU',
        timestamp: 1725723496306,
      },
      {
        sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
        threatType: 'url',
        firstSeen: '2024-07-09T23:08:14.858Z',
        lastScan: '2024-11-10T17:00:00.000Z',
        status: 'active',
        disposition: 'scam',
        sourceUrl: 'http://mybolbankmoney.com/',
        submissionTs: '2024-11-08T19:00:00.000Z',
        hostingProvider: 'Yet Another Fake Phishing Hosting Provider',
        count: 9,
        hostingCountry: 'DE',
        timestamp: 1725723496216,
      },
    ],
    phoneNumber: [
      {
        threatType: 'phone_number',
        sha256: '259791204602301df6088f261d8689da8bc1dbfbce71ecf183e9fee2aca7d622',
        status: 'not_applicable',
        name: '+1 844 505 2993',
        carrier: 'AT&T',
        location: 'unavailable',
        count: 238,
        submissionTs: '2024-11-06T22:00:54.000Z',
      },
      {
        threatType: 'phone_number',
        sha256: '7534af18ac1c24be4515a611390100139fc476deb575b9b7e63c8d33996bd8d3',
        status: 'not_applicable',
        name: '+1 803 265 5663',
        carrier: 'AT&T',
        location: 'South Carolina',
        count: 150,
        submissionTs: '2024-10-28T14:50:41.000Z',
      },
      {
        threatType: 'phone_number',
        sha256: 'ed56a2bbec9bb17f11a1705b44c89cab91347bf3d373720b36b1c870a7b3fd53',
        status: 'not_applicable',
        name: '+44 800 591 3517',
        carrier: 'T-Mobile',
        location: 'unavailable',
        count: 94,
        submissionTs: '2024-11-06T07:45:16.000Z',
      },
      {
        threatType: 'phone_number',
        sha256: '734be22b88172b356f168f4d6735de292e0aeac6bd94391056a75d79bb1692ad',
        status: 'not_applicable',
        name: '+1 801 528 4405',
        carrier: 'T-Mobile',
        location: 'Arizona',
        count: 88,
        submissionTs: '2024-11-06T19:34:14.000Z',
      },
      {
        threatType: 'phone_number',
        sha256: '404c4b209be8c317dcfbf32816a8cdf512eaf15862e0dad6dab51a2046787571',
        status: 'not_applicable',
        name: '+1 812 619 4603',
        carrier: 'AT&T',
        location: 'Indiana',
        count: 50,
        submissionTs: '2024-10-29T12:32:55.000Z',
      },
    ],
  },
  threatSourcesBreakdown: {
    maliciousPhoneNumber: {
      'Email Body': 902,
      'Email Image': 75,
      'Email Attachment': 35,
    },
    maliciousUrl: {
      'Email Body': 3420,
      'Email Attachment': 595,
      'Email Image': 370,
      'Email QR Code': 235,
    },
  },
  emailDisposition: {
    suspicious: 18929,
    malicious: 15165,
    clean: 6775,
  },
};
