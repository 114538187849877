import React, { useState, useRef, useMemo, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DropdownMultiSelect, IOption } from '../../../Common/DropdownMultiSelect';
import { IAddSearchTermProps, ugcType } from '../../Types/ugc.types';
import { Dropdown } from '../../../Common/Dropdown';
import { TextInput } from '../../../Common/TextInput';
import { Button, CircularProgress } from '@mui/material';

const AddSearchTerm = ({
  ugcPlatforms,
  ugcCategories,
  onSubmit,
  darkWebEntityOptions,
  type,
}: IAddSearchTermProps) => {
  const [timestamp, setTimestamp] = useState<number>(moment().valueOf());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [platformOriginOptions, setPlatformOriginOptions] = useState<any[]>([]);
  const selectedPlatforms = useRef<any[]>([]);
  const selectedCategory = useRef<IOption | undefined>();
  const selectedEntity = useRef<IOption | undefined>();
  const searchTerm = useRef('');
  const logoDetection = useRef(false);

  useEffect(() => {
    const combinedPlatformsOrigins: any = [];
    ugcPlatforms.forEach((platform: any) => {
      platform.origins.forEach((origin: any) => {
        const platformAndOrigin = { ...platform };
        platformAndOrigin.label = `${platform.label} ${origin.label}`;
        platformAndOrigin.originId = origin.id;
        platformAndOrigin.originLabel = origin.Label;
        platformAndOrigin.value = `${platform.label}-${origin.label}`;
        combinedPlatformsOrigins.push(platformAndOrigin);
      });
    });
    setPlatformOriginOptions(
      combinedPlatformsOrigins.sort((a: any, b: any) => a.label.localeCompare(b.label)),
    );
  }, [ugcPlatforms]);

  const PLATFORM_ONE_OPTION: IOption = useMemo(() => {
    return {
      value: '',
      label: 'All platforms',
    };
  }, [ugcPlatforms]);

  const onPlatformSelected = (selections: IOption[]) => {
    selectedPlatforms.current = selections;
  };

  const onSearchTermChange = (e: React.FormEvent<HTMLInputElement>) => {
    searchTerm.current = e.currentTarget.value;
  };

  const resetForm = () => {
    selectedPlatforms.current = [];
    selectedCategory.current = undefined;
    selectedEntity.current = undefined;
    searchTerm.current = '';
    logoDetection.current = false;
    setTimestamp(moment().valueOf());
  };

  const submitAddSearchTerm = () => {
    if (type !== ugcType.Dark_Web && type !== ugcType.App_Store) {
      //TODO: Need to refactor the logic of showing apporpritate errors on invalid field
      if (selectedPlatforms.current.length === 0 || !selectedCategory || !searchTerm.current) {
        return;
      }
    }
    setIsLoading(true);

    const searchTermData: any = {};

    const mapOrigins: any = {};

    selectedPlatforms.current.forEach(item => {
      if (item.id !== undefined) {
        if (!mapOrigins[item.id]) {
          mapOrigins[item.id] = {
            id: item.id,
            originIds: [],
          };
        }
        mapOrigins[item.id].originIds.push(item.originId);
      }
    });

    searchTermData['platforms'] = Object.values(mapOrigins);
    searchTermData['categoryId'] =
      selectedCategory.current?.value && ugcCategories.length > 1
        ? selectedCategory.current?.value
        : ugcCategories[0].value;
    searchTermData['optionId'] = selectedEntity.current?.id;
    searchTermData['searchTerm'] = searchTerm.current;
    searchTermData['isLogoDetection'] = logoDetection.current;

    onSubmit(searchTermData, searchTerm.current)
      .then(() => {
        resetForm();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={'add-search-term-section'} key={timestamp}>
      <div>Enter search terms below to improve monitoring results.</div>
      <div className={'add-search-term-form'}>
        <div className={'form-fields'}>
          <div className={'form-block'}>Monitor results</div>
          {type !== ugcType.Dark_Web && type !== ugcType.App_Store && (
            <>
              <div className={'form-block'}>from</div>
              <div className={'form-block'}>
                <DropdownMultiSelect
                  placeholder='select platform(s)'
                  oneOption={PLATFORM_ONE_OPTION}
                  options={platformOriginOptions}
                  onSubmit={onPlatformSelected}
                  updateOnChange
                  hideFooter
                />
              </div>
            </>
          )}
          {ugcCategories?.length > 1 && (
            <>
              <div className={'form-block'}>in</div>
              <div className={'form-block'}>
                <Dropdown
                  btnClassName='font-weight-normal bg-transparent'
                  emptyText='select category'
                  options={ugcCategories}
                  onChange={selection => (selectedCategory.current = selection)}
                />
              </div>
            </>
          )}
          {type === ugcType.Dark_Web ? (
            <>
              <div className={'form-block'}> that includes</div>
              <div className={'form-block'}>
                <Dropdown
                  btnClassName='font-weight-normal bg-transparent'
                  emptyText='select entity'
                  options={darkWebEntityOptions}
                  onChange={selection => (selectedEntity.current = selection)}
                />
              </div>
              <div>with value</div>
              <div className={'form-block'}>
                <TextInput onChange={onSearchTermChange} />
              </div>
            </>
          ) : (
            <>
              <div className={'form-block'}>that include value</div>
              <div className={'form-block'}>
                <TextInput onChange={onSearchTermChange} />
              </div>
            </>
          )}
        </div>
        <div className={'form-buttons'}>
          <Button variant='text' className={'text-button'} onClick={resetForm}>
            Reset
          </Button>
          <Button
            variant='contained'
            disabled={isLoading}
            onClick={submitAddSearchTerm}
            style={{
              marginLeft: '24px',
            }}
          >
            {isLoading ? <CircularProgress size={16} /> : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddSearchTerm };
