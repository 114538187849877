export const DARK_WEB_TABLE_NAME = 'darkWeb';
export const DARK_WEB_FINDING_DETECTION_TABLE_ID = 'darkWebActiveDetections';
export const DARK_WEB_OTHER_ENTITY_TABLE_ID = 'darkWebOtherEntityDetection';
export const DARK_WEB_MITIGATED_DETECTION_TABLE_ID = 'darkWebMitigatedDetections';
export const DARK_WEB_IGNORED_DETECTION_TABLE_ID = 'darkWebIgnoredDetections';
export const DARK_WEB_THREAT_ACTOR_TABLE_ID = `darkWebThreatActors`;

export const DARK_WEB_PATHNAME = '/dark-web';
export const DARK_WEB_DASHBOARD_PATHNAME = `${DARK_WEB_PATHNAME}/dashboard`;
export const DARK_WEB_PATHNAME_FINDINGS_DETECTION_PATHNAME = `${DARK_WEB_PATHNAME}/active`;
export const DARK_WEB_PATHNAME_FINDINGS_MITIGATED_PATHNAME = `${DARK_WEB_PATHNAME}/mitigated`;
export const DARK_WEB_PATHNAME_FINDINGS_IGNORED_PATHNAME = `${DARK_WEB_PATHNAME}/ignored`;
export const DARK_WEB_PATHNAME_WEB_ACTORS_PATHNAME = `${DARK_WEB_PATHNAME}/threat-actors`;
export const DARK_WEB_PATHNAME_SEARCH_TERMS = `${DARK_WEB_PATHNAME}/search`;

export const DARK_WEB_ACTOR_CHATTERS_TABLE_NAME = 'darkWebActorChatters';

export const DARK_WEB_TABLE_IDS = [
  DARK_WEB_FINDING_DETECTION_TABLE_ID,
  DARK_WEB_MITIGATED_DETECTION_TABLE_ID,
  DARK_WEB_IGNORED_DETECTION_TABLE_ID,
];

export type DARK_WEB_TABLE_IDS =
  | typeof DARK_WEB_FINDING_DETECTION_TABLE_ID
  | typeof DARK_WEB_MITIGATED_DETECTION_TABLE_ID
  | typeof DARK_WEB_IGNORED_DETECTION_TABLE_ID
  | typeof DARK_WEB_THREAT_ACTOR_TABLE_ID;

export const DEFAULT_SEARCH_FIELDS = [
  'brand_id',
  'category_id',
  'category',
  'content_sha_256',
  'created_ts',
  'highlights',
  'keywords',
  'network',
  'platform_published_ts',
  'platform',
  'risk_level',
  'risk_score',
  'search_term_id',
  'search_term',
  'sha256',
  'source_specific_info.has_sensitive_data',
  'source_specific_info.description',
  'source',
  'status',
  'sub_category_id',
  'sub_category',
  'tags',
  'threat_actor',
  'title',
  'updated_ts',
  'source_specific_info.breach_description',
];
