import fetch from './api.service';
import { handleResponse } from './serviceWorker';
import { TimelineResponse } from '../types/timeline-response.interface';
import { SocRequestMessageDto } from '../components/SocTakedown/types/soc-request-documents';
import { Base64Img } from '../types/base-64-img.interface';
import { SocTakedownStatusDto } from '../types/soc-takedown-status.dto';

export interface TakedownEmailDto {
  toEmails: string[];
  ccEmails: string[];
  bccEmails: string[];
  subject: string;
  urlSha256s: string[];
  bodyHtml: string;
  brandName?: string;
}
export interface SocCustomerMessageDto {
  urlSha256s: string[];
  message: string;
}

export class SocTakedownService {
  async getTimeline(urlSHA256: string): Promise<TimelineResponse> {
    return await handleResponse(
      await fetch('/platform-api/v1/intel/timeline/' + urlSHA256, {
        credentials: 'include',
      }),
    );
  }

  async getCurrentTakedownStatus(urlSha256: string): Promise<SocTakedownStatusDto> {
    const res = await handleResponse(
      await fetch(`/platform-api/v1/takedown/current-status/${urlSha256}`),
    );
    return res;
  }

  async socRequestDocuments(saveDto: SocRequestMessageDto): Promise<{ result: string }> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(saveDto),
    };
    const res = await handleResponse<{ result: string }>(
      await fetch(`/platform-api/v1/takedown/soc-requested-document`, requestOptions),
    );
    return res;
  }

  async getAttachment(urlSha256: string, s3Url: string): Promise<Base64Img> {
    const attachmentKey = s3Url.split('.com/')[1];
    return await handleResponse<Base64Img>(
      await fetch(
        `/platform-api/v1/takedown/attachment/${urlSha256}/${encodeURIComponent(attachmentKey)}`,
      ),
    );
  }
}
