import React from 'react';
import dashboardTeaserPageDark from '../../../assets/abuseMailBox/dashboardTeaserPageDark.svg';
import dashboardTeaserPageLight from '../../../assets/abuseMailBox/dashboardTeaserPageLight.svg';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';

export const AbuseMailBoxNoPermissionPage = () => {
  const [isDarkMode] = useIsDarkMode();

  return (
    <div className='abuse-mailbox-no-permission-page'>
      <div className='abuse-mailbox-no-permission-page-content'>
        <div className='abuse-mailbox-no-permission-page-content-title'>
          Abuse Mailbox Dashboard
        </div>
        <div className='abuse-mailbox-no-permission-page-content-description'>
          You do not have permission to access Abuse Mail module. Please contact your administrator
          to request access.
        </div>
        <img
          className='abuse-mailbox-no-permission-page-content-image'
          src={isDarkMode ? dashboardTeaserPageDark : dashboardTeaserPageLight}
          alt='No Permission'
        />
      </div>
    </div>
  );
};
