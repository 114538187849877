export const mockInsightData = {
  scanResults: {
    status: 'down',
    host: 'mybolbankmoney.com',
    siteTitle: '',
    disposition: 'spam',
    dstUrl: `http://0376dfppk2.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ`,
    createdTS: '2024-09-07T15:38:17.162Z',
    primary_domain: 'mybolbankmoney.com',
    firstSeenTS: '2021-12-10T05:11:40.199Z',
    imageKey:
      '20240907/edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c_1725723497162.png',
    imagePath:
      'https://bst-prod-screenshots.s3-us-west-2.amazonaws.com/20240907/629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2_1725723496306.png',
    domKey:
      '20240907/edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c_1725723497162.txt',
    ip: '0.0.0.0',
    timestamp: 1725723497162,
    srcUrl: 'http://mybolbankmoney.com/',
    networkOwner: '',
    asn: '',
    brandId: 'bankofbolster',
    brandName: 'Bankofbolster',
    urlSHA256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
    tld: 'com',
    logoDetection: {
      model_version: '',
      annotations: [],
      threshold: 0,
    },
    scan_category: '',
    scan_settings: {
      scan_location: 'US',
      user_agent: '',
    },
    finalCategory: 'Unknown',
    mxRecords: 0,
    hasMxRecords: false,
    allMXRecords: [],
    tableStatus: 'pre-malicious',
    takedownCount: 0,
    sourceFeed: 'Bolster Proprietary',
    scanSource: 'Bolster',
    domSourcePath:
      'https://s3-us-west-2.amazonaws.com/bst-prod-dom-source/20240907/edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c_1725723497162.txt',
    redirectedChains: [
      'http://0376dfppk2.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://0l937jfx1e.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://hi716v593m.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://njopn07id9.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://e86qdj877h.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://0a546j350n.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://4c51568zgl.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://nx2sj5eoen.novasagetech.info/fwd/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
      'http://d3f379j50g.novasagetech.info/sunsub/P2Q9NDEmZWk9MTE1MjMyNiZpZj01NjQmbGk9OQ',
    ],
  },
};

export const mockDisputeVerdictData = {
  updated_ts: 1679497365047,
  reporter_comment: '--',
  attachments: [],
  reporter_ip: '47.146.83.92',
  bolster_disposition: 'suspicious',
  scan_ts: 1679345855596,
  reporter_disposition: 'scam',
  url_sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
  source: 'platform_77l35li2tshi71lshooh2b3o8ikq4mr8ts64tnxgvbzl0i5aafvx1vt9k5o295ik',
  created_ts: 1679497365047,
  status: '',
  user: {
    id: 19597,
    firstName: 'Bolster',
    lastName: 'SOC Team',
    createdTs: '2023-02-22T22:39:46.872Z',
    updatedTs: '2023-02-22T22:42:30.887Z',
    sessionStart: '2023-02-22T22:39:46.879Z',
    relBrandId: 14385,
    termsAndConditionAccepted: true,
    userSource: null,
    applicationType: 'PLATFORM',
    acceptedTs: '2023-02-22T22:42:30.883Z',
    userAttribute: {
      userId: 19597,
      roleId: 3,
      typeId: 1,
      isInternal: true,
      createdTs: '2023-02-22T22:39:46.907Z',
      updatedTs: '2023-02-22T22:39:46.907Z',
    },
  },
};
