import React, { useContext, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { ChevronDownIcon } from '../../../assets/SVGIcons';
import { Checkbox } from '../Checkbox';
import { WebUrlDataHeader } from '../../../types/web-url-data.interface';
import { getSingleOrPluralForm, appConstants } from '../../../constants';
import DotDotDot from '../../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../../assets/icons/DotDotDotWhite.svg';
import { Dropdown } from '../../Common/Dropdown';
import DashboardService from '../../../services/dashboard.service';
import { IMatFetchOptions } from '../../MonitorAndTakedown/constants';
import { TableContext } from './table.context';
import Tags from '../Tags/Tags';
import { useReadOnlyUser } from '../../../basic-hooks/useUserRoles';
import useIsDarkMode from '../CustomHooks/useIsDarkMode';

const dashboardService = new DashboardService();
interface IGroupedRowProps {
  item: WebUrlDataHeader;
  rowExpanded: boolean;
  tableId: string | undefined;
  enableCheckbox: boolean;
  editing: boolean;
  onCheck: (checked: boolean, item: any) => void;
  checked: boolean;
  selectedCount: number;
  isNew: boolean;
  loadingRelated?: boolean;
  currentFetchOptions?: IMatFetchOptions;
  columns: object[];
  domainOnHover: string;
  onTagsChanged?: (value: boolean) => void;
  getRelatedFindings?: (item: any) => void;
  user?: any;
}
function GroupedHeaderRow(props: IGroupedRowProps) {
  const {
    item,
    rowExpanded,
    onCheck,
    enableCheckbox,
    selectedCount,
    checked,
    loadingRelated,
    currentFetchOptions,
    columns,
    domainOnHover,
    onTagsChanged,
    getRelatedFindings,
    user,
  } = props;
  const [showTagsOverlay, setShowTagsOverlay] = useState(false);
  const [curFindings, setCurFindings] = useState<any>([]);
  const [checkedTags, setCheckedTags] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { domain, related_findings_count } = item;
  const [isDarkMode] = useIsDarkMode();
  const tableContext = useContext(TableContext);
  const { setFindings, disputeModalShown, takedownModalShown } = tableContext;
  const check = (checked: boolean, item: WebUrlDataHeader): void => {
    onCheck(checked, item);
  };
  enum DropdownType {
    DISPUTE = 'dispute-all',
    TAKEDOWN = 'takedown-all',
    TAGGING = 'tag-all',
  }
  const onClickGetRelated = async () => {
    if (currentFetchOptions) {
      setIsLoading(true);

      try {
        const relatedfindings = await dashboardService.getRelatedFindings(
          item.domain,
          currentFetchOptions.type,
          currentFetchOptions?.query,
          currentFetchOptions?.filters,
          {
            sortBy: 'first_seen_ts',
            sortDirection: 'desc',
          },
        );
        if (setFindings) {
          setFindings(relatedfindings.findings);
        }
        const prevCheckedTags = relatedfindings?.findings?.filter(
          finding => finding.tags.length > 0,
        );
        setCurFindings(relatedfindings.findings);
        setCheckedTags(prevCheckedTags && prevCheckedTags?.length > 0);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onHandleBulkActions = (item: { label: string; value: string }, controlId: string) => {
    if (item.value === DropdownType.DISPUTE) {
      if (disputeModalShown) {
        disputeModalShown(true);
      }
    } else if (item.value === DropdownType.TAGGING) {
      showTagsOverlayHandler();
    } else {
      if (takedownModalShown) {
        takedownModalShown(true);
      }
    }
  };

  const showTagsOverlayHandler = () => {
    setShowTagsOverlay(true);
  };

  const hideTagsOverlayHandler = () => {
    setShowTagsOverlay(false);
    setCurFindings([]);
  };

  const getUpdatedTags = () => {
    if (getRelatedFindings) {
      getRelatedFindings(item);
    }
  };

  const isReadOnlyUser = useReadOnlyUser();

  const DotOptions = [
    { label: related_findings_count > 1 ? 'Dispute All' : 'Dispute', value: DropdownType.DISPUTE },
    { label: 'Request Takedown', value: DropdownType.TAKEDOWN },
    { label: checkedTags ? 'Edit Tag' : 'Add Tag', value: DropdownType.TAGGING },
  ];

  return (
    <>
      {enableCheckbox && (
        <td
          onClick={e => {
            // prevent weirdness when clicking near the checkbox
            e.stopPropagation();
          }}
        >
          <div className='d-flex'>
            <div>
              <Checkbox
                isStateless={true}
                defaultChecked={checked}
                disabled={loadingRelated}
                onChange={(checked: boolean) => {
                  check(checked, item);
                }}
              />
            </div>

            {loadingRelated && (
              <div className='ml-2'>
                <Spinner className='block' animation='border' variant='light' size='sm' />
              </div>
            )}
          </div>
        </td>
      )}
      <td>
        <div className='grouped-header-wrapper'>
          <div>
            {' '}
            <ChevronDownIcon
              color={'grey'}
              className={`chevron-icon ml-1 ${rowExpanded ? 'chevron-up' : ''}`}
            />
          </div>
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip id={'tooltip-' + domain} className={'table-source-url-tooltip'}>
                {domain}
              </Tooltip>
            }
          >
            <div className={'source-url-column d-flex align-items-center'}>
              <div>{domain}</div>
            </div>
          </OverlayTrigger>
        </div>
      </td>
      <td>
        <div className='grouped-header-wrapper column-extension'>
          <div className='counts-wrapper'>
            {`${related_findings_count || 0} ${getSingleOrPluralForm(
              related_findings_count || 0,
              'Finding',
            )}`}
            <div
              className='d-flex align-items-center'
              onClick={e => {
                onClickGetRelated();
                e.stopPropagation();
              }}
            >
              {domainOnHover === domain && !isReadOnlyUser && (
                <Dropdown
                  options={DotOptions}
                  btnClassName={'bg-transparent grouped-view-dots'}
                  hideArrow
                  fixedPlaceholder={
                    <img src={isDarkMode ? DotDotDotWhiteIcon : DotDotDot} alt={'dotdotdot'} />
                  }
                  onChange={onHandleBulkActions}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
          {selectedCount > 0 && <div className='counts-wrapper ml-2'>{selectedCount} Selected</div>}
          {showTagsOverlay && (
            <Tags
              rowData={curFindings}
              type={appConstants.CONTENT_TYPE.WEB}
              bulkOption
              showTagsOverlay={showTagsOverlay}
              hideTagsOverlayHandler={hideTagsOverlayHandler}
              onTagsChanged={onTagsChanged}
              getUpdatedTags={getUpdatedTags}
            />
          )}
        </div>
      </td>
      {[...Array(columns.length - 2)].map((item, index) => {
        return <td key={index}></td>;
      })}
    </>
  );
}

export default GroupedHeaderRow;
