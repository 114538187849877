import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IFindingInsightDataProps, IScanCountInfo, ugcType } from '../../../Types/ugc.types';
import {
  SCAN_COUNT_INIT_DATA,
  UGC_INSIGHT_INIT_DATA,
  massagedAppStoreScanSourceData,
  massagedUgcInsightData,
  massagedAppStoreSafelistData,
  massagedAppStoreTakedownData,
} from './Insights-util';
import ActiveRoutesContext from '../../../../../context/ActiveRoutesContext';
import {
  getInsightDetails,
  getSafeListDetails,
  getScanSourceInfo,
  getTagsByUrlSha,
  getTakedownDetails,
} from './UgcInsights-requests';
import { appConstants, setLocalStorageValue } from '../../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/hooks';
import { setPreviousUrlSha } from '../../../../../reducers/table.reducer';
import { setShouldFetchInsightsOnNext } from '../../../../../reducers/insightsContainer.reducer';

export function useOnFetchUgcInsightData(sha256: string, moduleType: string) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPreviousUrlSha(sha256 as string));

    return () => {
      setLocalStorageValue('isNotFromTableCol', false);
    };
  }, [dispatch, sha256]);

  const { getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [findingDetails, setFindingDetails] =
    useState<IFindingInsightDataProps>(UGC_INSIGHT_INIT_DATA);
  const [safelist, setSafelist] = useState(null);
  const [takedown, setTakedown] = useState(null);
  const [scanCountInfo, setScanCountInfo] = useState<IScanCountInfo>(SCAN_COUNT_INIT_DATA);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    Promise.allSettled([
      getInsightDetails(sha256 as string, moduleType),
      getSafeListDetails(sha256 as string, moduleType),
      getTakedownDetails(sha256 as string, moduleType),
      getTagsByUrlSha(sha256 as string, moduleType),
    ])
      .then((allResponses: any) => {
        const [finding, safeListDetails, takedownDetails, tagsData] = allResponses.map(
          (result: any) => result.value,
        );
        getParentRouteViaFindingStatus(finding?.status);

        setFindingDetails(massagedUgcInsightData(finding, tagsData));

        if (moduleType !== appConstants.CONTENT_TYPE.APP_STORE) {
          setSafelist(safeListDetails);
          setTakedown(takedownDetails);
        }
        if (moduleType === appConstants.CONTENT_TYPE.APP_STORE) {
          if (takedownDetails) {
            setTakedown(massagedAppStoreTakedownData(takedownDetails));
          }
          if (safeListDetails && 'safeListInfo' in safeListDetails) {
            setSafelist(massagedAppStoreSafelistData(safeListDetails));
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getParentRouteViaFindingStatus, moduleType, sha256]);

  const getAppStoreScanCount = useCallback(async () => {
    const scanCount = await getScanSourceInfo(sha256);
    setScanCountInfo(massagedAppStoreScanSourceData(scanCount));
  }, [sha256]);

  const shouldFetchInsightsOnNext = useAppSelector(
    state => state.insightsContainerReducer.shouldFetchInsightsOnNext,
  );
  // Fetch data on next or prev indights page
  useEffect(() => {
    if (shouldFetchInsightsOnNext) {
      fetchData();
      if (moduleType === ugcType.App_Store) {
        void getAppStoreScanCount();
      }
    }
    dispatch(setShouldFetchInsightsOnNext(false));
  }, [dispatch, fetchData, getAppStoreScanCount, moduleType, shouldFetchInsightsOnNext]);

  // Fetch data on initial load
  useEffect(() => {
    fetchData();
    if (moduleType === ugcType.App_Store) {
      void getAppStoreScanCount();
    }

    dispatch(setShouldFetchInsightsOnNext(false));
  }, [sha256]);

  return { isLoading, setIsLoading, findingDetails, takedown, safelist, scanCountInfo };
}
