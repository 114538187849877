import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ITableApiColumn } from '../table.api';
import { AgGridReact } from 'ag-grid-react';
import { convertToFetchBrandPropertyApiParams, getAgGridThemeClassName } from './ag-utils';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { setTableTotalRows } from '../../../../reducers/table.reducer';
import { alertActions, dashboardActions } from '../../../../actions';
import { useOnSelectionChangeEvent } from './useOnSelectionChangeEvent';
import { useAgGridEvents } from './useAgGridEvents';
import {
  ColDef,
  FilterChangedEvent,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { useAgGridCurrentPageRows } from './useAgGridCurrentPageRows';
import { TableContext } from '../table.context';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import { generateBrandPropertyColumnDefs } from './ag-col-defs';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { TABLE_EMPTY_RESULTS_MESSAGE } from './constants';
import { fetchBrandProperties } from '../../../Assets_v2/brand-property-requests';
import { AgGridColumnType } from './interfaces';
import _ from 'lodash';
import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';
import { massagedBrandPropertyAssets } from '../../../Assets_v2/util';
import useAgGridFilterChange from './useAgGridFilterChange';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';

const AgGridBrandPropertyTable = ({
  columns,
  tableId,
  moduleType,
  brandPropertyId,
}: {
  columns: ITableApiColumn[];
  tableId: any;
  moduleType: string;
  brandPropertyId?: number;
}) => {
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const agRef = useRef<AgGridReact>(null);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs, onTableSaveColumns } = useContext(TableContext);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);
  const dispatch = useAppDispatch();

  const {
    handleFirstDataRendered,
    handleOnPaginationChanged,
    currentPageNumber,
    currentPaginationSize,
  } = usePersistentTablePageNumber(tableId);

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({ tableId });

  const colDefs: ColDef[] = useMemo<ColDef[]>(() => {
    return generateBrandPropertyColumnDefs(columns, firstRenderDisplayColumnIdSet, tableId);
    // We have to add displayedColumnsIDs here, to update colDefs with latest visible columns
  }, [columns, moduleType, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);
  // console.log('fetching brand properties');

  const isFilterModelCacheUsed = useRef(false);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        // console.log('[Datasource] - rows requested by grid: ', params);
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };
        try {
          const apiParams = convertToFetchBrandPropertyApiParams(
            agRequest,
            brandPropertyId as number,
          );
          const rawResponse = await fetchBrandProperties(apiParams);
          response.data = massagedBrandPropertyAssets(rawResponse.properties);
          response.total = rawResponse.metadata?.total;
          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }
          setAllRowData(response.data);
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: response.total,
            });
          }, 0);
        } catch (error) {
          console.log('Error in fetching ugc :', error);
          params.fail();
        }
      },
    };
  }, [dispatch, brandPropertyId, setAllRowData]);

  const { currentRowsOfPage } = useAgGridCurrentPageRows({
    agRef,
    allRowData,
    currentPageNumber,
    currentPaginationSize,
  });

  const { isFilterFromDashboard } = useAppSelector(state => state.tableReducer);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      // first render, only initiate filter model from local storage.
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource, tableId, handleFilterClick],
  );

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data.id}`;
  }, []);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData,
  });

  useEffect(() => {
    setLocalStorageValue(['savedSelectionState', tableId], []);
  }, []);

  const { handleOnSelectionChange } = useOnSelectionChangeEvent({
    agRef,
    tableId: tableId,
    allRowData: allRowData,
    moduleType: moduleType,
    currentRowsOfPage,
    currentPageNumber,
    currentPaginationSize,
  });

  useOnAgGridRefresh(agRef);

  // setFindingsTableUrls to empty when unmount
  useEffect(() => {
    return () => {
      dispatch(dashboardActions.setFindingsTableUrls(tableId, [], moduleType));
      dispatch(setTableTotalRows(0));
      setLocalStorageValue(['savedSelectionState', tableId], []);
    };
  }, [dispatch, tableId, moduleType]);

  const { handleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={false}
        cacheBlockSize={15}
        blockLoadDebounceMillis={1000}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        rowSelection={'multiple'}
        onSelectionChanged={handleOnSelectionChange}
        onFilterChanged={handleFilterChanged}
        suppressRowClickSelection={true}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
      />
    </div>
  );
};
export default AgGridBrandPropertyTable;
