import React, { Fragment, useEffect, useMemo } from 'react';
import {
  appConstants,
  getLocalStorageValue,
  saveBulkscanTableParameters,
  setLocalStorageValue,
} from '../../constants';
import './GlobalSearchFindings.scss';
import _ from 'lodash';
import {
  DarkWebFindingStatusAction,
  DarkwebFindingStatusToTableId,
  EDarkWebFindingStatus,
  EDarkWebFindingStatusTable,
} from '../DarkWeb/Components/Types/DarkWeb.types';
import GlobalSearchFindingView from './GlobalSearchFindingView';
import {
  EDstUrlTableId,
  EGlobalSearchTableIds,
  EWebAssetsModuleFilterFields,
  mapAssetsTypeToTableId,
  mapAssetsTypeToTableRoute,
  mappingModuleTypeToInsightsRoute,
  mappingModuleTypeToLabel,
  mappingModuleTypeToPlatformRoute,
  MAX_SEARCH_RESULTS,
  MAX_SEARCH_RESULTS_LIMIT,
  MODULE_ICON,
} from './GlobalSearchConstants';
import {
  constructFilterForAgGridAssetFilterModel,
  constructFilterForAgGridFilterModel,
  constructFilterForBulkScanTable,
  constructFltrForAgGridFltrModelWithAdvancedFltrParams,
  constructFltrForBulkScanWithAdvancedFltrParams,
} from '../Common/Table/ag-table/ag-utils';
import { history } from '../../helpers';
import useOnGlobalSearchSubTabChange from './useOnGlobalSearchSubTabChange';
import { IFilter } from '../Common/Table/constant';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import {
  setCurrentAppliedPersistentFilter,
  setShouldRefreshClientSideTable,
  setShouldTriggerAgGrigRefresh,
} from '../../reducers/table.reducer';
import { DispositionStatus } from '../Common/DispositionStatus';
import { GLOBAL_TAKEDOWN_TABLE_ID } from '../GlobalTakedown/GlobalTakedown';
import { EFindingStatus } from '../Ugc/Types/ugc.types';
import useOnRedirectToModuleTeaserPage from '../Common/CustomHooks/useOnRedirectToModuleTeaserPage';
import { ESelectedFilterTypeOptions } from './GlobalSearchConstants';
import {
  setSelectedEntityActive,
  setSelectedEntityIgnored,
  setSelectedEntityMitigated,
} from '../../reducers/darkWeb.reducer';
import useOnToggleTeamView from '../BulkScan/Common/useOnToggleTeamView';
import { AdvancedFilterParams } from './AdvancedFiltersUtils';

interface IGlobalSearchFindingProps {
  globalSearchFindingData: { [key: string]: any };
  searchTerm: string;
  count?: { [key: string]: number };
  agFilterFields: { fieldName: string; moduleType: string }[];
  handleClose: () => void;
  selectFilterType: string;
  fetchRelatedUrls: () => void;
  advancedFilterParams?: AdvancedFilterParams;
}

export default function GlobalSearchFindings({
  globalSearchFindingData,
  searchTerm,
  agFilterFields,
  handleClose,
  selectFilterType,
  fetchRelatedUrls,
  advancedFilterParams,
}: IGlobalSearchFindingProps) {
  const { webFindings, tvcFindings, webDstFindings, tvcDstFindings } =
    useOnGlobalSearchSubTabChange({
      globalSearchFindingData,
    });
  const { shouldShowTeaserPage } = useOnRedirectToModuleTeaserPage();

  const { onSaveTeamViewHandler } = useOnToggleTeamView();

  const smFindings = useMemo(() => {
    const massagedData = globalSearchFindingData[appConstants.CONTENT_TYPE.SOCIAL] || [];

    const socialData = _.groupBy(massagedData, 'tableStatus');
    const inProgressSMData = socialData[EFindingStatus.IN_PROGRESS] || [];
    const pausedSMData = socialData[EFindingStatus.PAUSED] || [];
    return {
      [EFindingStatus.LIVE]: socialData[EFindingStatus.LIVE] || [],
      [EFindingStatus.IN_PROGRESS]: [...inProgressSMData, ...pausedSMData],
      [EFindingStatus.DOWN]: socialData[EFindingStatus.DOWN] || [],
      [EFindingStatus.SAFELIST]: socialData[EFindingStatus.SAFELIST] || [],
    };
  }, [globalSearchFindingData]);

  const appStoreFindings = useMemo(() => {
    const massagedData =
      globalSearchFindingData[appConstants.CONTENT_TYPE.APP_STORE]?.filter((data: any) => {
        return data.appStoreStatus !== 'HIDDEN';
      }) || [];

    const appStoreData = _.groupBy(massagedData, 'appStoreStatus');

    return {
      [EFindingStatus.LIVE]: appStoreData[EFindingStatus.LIVE] || [],
      [EFindingStatus.IN_PROGRESS]: appStoreData[EFindingStatus.IN_PROGRESS] || [],
      [EFindingStatus.DOWN]: appStoreData[EFindingStatus.DOWN] || [],
      [EFindingStatus.SAFELIST]: appStoreData[EFindingStatus.SAFELIST] || [],
    };
  }, [globalSearchFindingData]);

  const darkWebFindings = useMemo(() => {
    const response = globalSearchFindingData[appConstants.CONTENT_TYPE.DARK_WEB] || [];
    const massagedData = _.groupBy(response, 'status');
    return massagedData;
  }, [globalSearchFindingData]);

  const bulkScanFindings = useMemo(() => {
    return globalSearchFindingData['bulk-scan'];
  }, [globalSearchFindingData]);

  // Ugc assets data sm and app store
  // managed by org and bolster for web assets
  const assetsData = useMemo(() => {
    const ugcAssets = _.groupBy(globalSearchFindingData[EGlobalSearchTableIds.UGC_ASSETS], 'type');
    const managedByOrg = globalSearchFindingData[EGlobalSearchTableIds.MANAGED_BY_ORG]?.filter(
      (data: any) => {
        return data.isAffiliated === false;
      },
    );

    const managedByAffilates = globalSearchFindingData[
      EGlobalSearchTableIds.MANAGED_BY_ORG
    ]?.filter((data: any) => {
      return data.isAffiliated === true;
    });

    const managedByBolster = globalSearchFindingData[EGlobalSearchTableIds.MANAGED_BY_BOLSTER];

    // only add the data if it is not empty
    const massagedData = {};
    if (ugcAssets && Object.keys(ugcAssets).length > 0) {
      Object.keys(ugcAssets).forEach((key: string) => {
        massagedData[key.replace('_', '-')] = ugcAssets[key];
      });
    }
    if (managedByOrg && managedByOrg.length > 0) {
      massagedData[EGlobalSearchTableIds.MANAGED_BY_ORG] = managedByOrg;
    }
    if (managedByAffilates && managedByAffilates.length > 0) {
      massagedData[EGlobalSearchTableIds.MANAGED_BY_AFFILIATES] = managedByAffilates;
    }
    if (managedByBolster && managedByBolster.length > 0) {
      massagedData[EGlobalSearchTableIds.MANAGED_BY_BOLSTER] = managedByBolster;
    }

    return massagedData;
  }, [globalSearchFindingData]);

  const dispatch = useAppDispatch();
  const currentGlobalSearchSubTab = useAppSelector(
    state => state.globalSearchReducer.currentGlobalSearchSubTab,
  );
  const handleFilterWithNavigation = (
    moduleType: string,
    moduleTableId: string,
    moduleRoute: string,
  ) => {
    if (shouldShowTeaserPage(moduleType)) {
      history.push(`/${mappingModuleTypeToPlatformRoute[moduleType]}/dashboard`);
      handleClose();
    } else {
      const isTags = selectFilterType === ESelectedFilterTypeOptions.TAGS;
      const fieldName = !isTags
        ? agFilterFields.find(field => field.moduleType === moduleType)?.fieldName
        : moduleType === appConstants.CONTENT_TYPE.WEB
        ? 'tags'
        : 'tags.id';
      const agFilterModel = advancedFilterParams
        ? constructFltrForAgGridFltrModelWithAdvancedFltrParams(
            searchTerm,
            moduleType,
            currentGlobalSearchSubTab,
            advancedFilterParams,
          )
        : constructFilterForAgGridFilterModel(
            searchTerm,
            fieldName as string,
            moduleType,
            currentGlobalSearchSubTab,
          );
      setLocalStorageValue(['tableFilterModel', moduleTableId], agFilterModel);
      dispatch(setCurrentAppliedPersistentFilter(agFilterModel));

      history.push(`/${mappingModuleTypeToPlatformRoute[moduleType]}/${moduleRoute}`);
      handleClose();
    }
  };

  //old table filter
  const handleFilterBulkScanNavigation = (moduleType: string) => {
    const fieldName = agFilterFields.find(field => field.moduleType === moduleType)?.fieldName;
    const initialFilters: IFilter[] = advancedFilterParams
      ? constructFltrForBulkScanWithAdvancedFltrParams(searchTerm, moduleType, advancedFilterParams)
      : constructFilterForBulkScanTable(searchTerm, fieldName as string);
    const bulkScanLocalData = getLocalStorageValue('bulkscan');
    if (bulkScanLocalData && bulkScanLocalData.table && bulkScanLocalData.table.filters) {
      bulkScanLocalData.table.filters.push(...initialFilters);
      saveBulkscanTableParameters(bulkScanLocalData);
    }
    dispatch(setShouldTriggerAgGrigRefresh(true));
    void onSaveTeamViewHandler(true);
    history.push(`/bulk-scan`);
    handleClose();
  };

  const handleFilterDarkWebWithNavigation = (moduleType: string, moduleTableId: string) => {
    if (shouldShowTeaserPage(moduleType)) {
      history.push(`/${moduleType}/dashboard`);
      handleClose();
    } else {
      const fieldName = advancedFilterParams
        ? advancedFilterParams?.searchParams?.find(field => field.moduleType === moduleType)
            ?.fieldName
        : agFilterFields.find(field => field.moduleType === moduleType)?.fieldName;
      const agFilterModel = advancedFilterParams
        ? constructFltrForAgGridFltrModelWithAdvancedFltrParams(
            searchTerm,
            moduleType,
            currentGlobalSearchSubTab,
            advancedFilterParams,
          )
        : constructFilterForAgGridFilterModel(
            searchTerm,
            fieldName as string,
            moduleType,
            currentGlobalSearchSubTab,
          );
      const agDarkwebTableId =
        DarkwebFindingStatusToTableId[moduleTableId as EDarkWebFindingStatus]?.[
          fieldName as string
        ];

      setLocalStorageValue(['tableFilterModel', agDarkwebTableId], agFilterModel);
      dispatch(setCurrentAppliedPersistentFilter(agFilterModel));
      setLocalStorageValue('currentTableId', moduleTableId);

      const dwCurrentTableStatus = moduleTableId as EDarkWebFindingStatus;
      const selectedEntity = agDarkwebTableId?.split('-')?.[1] ?? null;

      switch (dwCurrentTableStatus) {
        case EDarkWebFindingStatus.ACTIVE:
          dispatch(setSelectedEntityActive(selectedEntity));
          break;
        case EDarkWebFindingStatus.MITIGATED:
          dispatch(setSelectedEntityMitigated(selectedEntity));
          break;
        case EDarkWebFindingStatus.IGNORED:
          dispatch(setSelectedEntityIgnored(selectedEntity));
          break;
      }

      history.push(
        `/dark-web/${DarkWebFindingStatusAction[moduleTableId as EDarkWebFindingStatus]}`,
      );
      handleClose();
    }
  };

  const redirectToBulkScanInsightsPage = (finding: any) => {
    setLocalStorageValue('isNotFromTableCol', true);
    history.push(`${mappingModuleTypeToInsightsRoute['bulk-scan']}/${finding.urlSha256}`);
    handleClose();
  };

  const handleAssetsFilterWithNavigation = (moduleType: string) => {
    //TODO : Hotfix for assets type to redirect to the correct table with correct filter
    // Currently we don't have a way to differentiate the fields for the web asset's tables as in BE we store per module type
    const isWebAssetsType = {
      [EGlobalSearchTableIds.MANAGED_BY_ORG]: EGlobalSearchTableIds.MANAGED_BY_ORG,
      [EGlobalSearchTableIds.MANAGED_BY_AFFILIATES]: EGlobalSearchTableIds.MANAGED_BY_AFFILIATES,
      [EGlobalSearchTableIds.MANAGED_BY_BOLSTER]: EGlobalSearchTableIds.MANAGED_BY_BOLSTER,
    };
    let fieldName: string | undefined = '';

    if (isWebAssetsType[moduleType]) {
      switch (moduleType) {
        case EGlobalSearchTableIds.MANAGED_BY_ORG:
        case EGlobalSearchTableIds.MANAGED_BY_AFFILIATES:
          fieldName = EWebAssetsModuleFilterFields.ASSET_NAME;
          break;
        case EGlobalSearchTableIds.MANAGED_BY_BOLSTER:
          fieldName = EWebAssetsModuleFilterFields.DOMAIN_NAME;
          break;
      }
    } else {
      fieldName = agFilterFields.find(field => field.moduleType === moduleType)?.fieldName;
    }
    const agFilterModel = constructFilterForAgGridAssetFilterModel(
      searchTerm,
      fieldName as string,
      moduleType,
    );
    setLocalStorageValue(['tableFilterModel', mapAssetsTypeToTableId[moduleType]], agFilterModel);
    dispatch(setCurrentAppliedPersistentFilter(agFilterModel));
    dispatch(setShouldRefreshClientSideTable(true));
    history.push(mapAssetsTypeToTableRoute[moduleType]);
    handleClose();
  };

  const handleTVCFilterWithNavigation = (moduleType: string, moduleTableId: string) => {
    const fieldName = agFilterFields.find(field => field.moduleType === moduleType)?.fieldName;

    const agFilterModel = advancedFilterParams
      ? constructFltrForAgGridFltrModelWithAdvancedFltrParams(
          searchTerm,
          moduleType,
          currentGlobalSearchSubTab,
          advancedFilterParams,
        )
      : constructFilterForAgGridFilterModel(
          searchTerm,
          fieldName as string,
          moduleType,
          currentGlobalSearchSubTab,
        );
    setLocalStorageValue(['tableFilterModel', moduleTableId], agFilterModel);
    dispatch(setCurrentAppliedPersistentFilter(agFilterModel));
    history.push(`/takedown-visibility-center`);
    handleClose();
  };

  const redirectToTVCInsightsPage = (finding: any) => {
    setLocalStorageValue('isNotFromTableCol', true);
    setLocalStorageValue('currentTableId', GLOBAL_TAKEDOWN_TABLE_ID);
    history.push(
      `${mappingModuleTypeToInsightsRoute[appConstants.CONTENT_TYPE.WEB]}/${finding.urlSha256}`,
    );
  };

  const showMoreTvcResults = useMemo(() => {
    return globalSearchFindingData.count?.[appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER];
  }, [globalSearchFindingData, currentGlobalSearchSubTab]);

  const showMoreTvcDstResults = useMemo(() => {
    return globalSearchFindingData.count?.[
      EDstUrlTableId[appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]
    ];
  }, [globalSearchFindingData, currentGlobalSearchSubTab]);

  return (
    <div className='global-search-findings-wrapper'>
      {!_.isEmpty(tvcFindings) && (
        <div className='global-search-findings-container'>
          <div className='module-header'>
            <img
              src={MODULE_ICON[appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]}
              alt='bulk-scan-module-logo'
            />
            <div className='module-label'>Takedown Visibility Center</div>
          </div>
          <div className='findings-detail'>
            {tvcFindings.slice(0, 5).map((finding: any) => {
              return (
                <div className='finding-wrapper' key={finding.urlSha256}>
                  <div
                    onClick={() => redirectToTVCInsightsPage(finding)}
                    className='finding-title'
                    data-testid={`tvc-finding-${finding.urlSha256}`}
                  >
                    {finding.srcUrl}
                  </div>
                  <DispositionStatus
                    key={finding.srcUrl}
                    status={finding.currentDisposition}
                    displayedStyle={'pill'}
                  />
                </div>
              );
            })}
            {showMoreTvcResults > MAX_SEARCH_RESULTS_LIMIT && (
              <div className='findings-more-description'>
                <div className='description'>
                  {showMoreTvcResults > MAX_SEARCH_RESULTS
                    ? `Showing 5 of 10000+ results`
                    : `Showing 5 of ${showMoreTvcResults} results`}
                </div>
                <div
                  onClick={() => {
                    handleTVCFilterWithNavigation('web', GLOBAL_TAKEDOWN_TABLE_ID);
                  }}
                  className='see-all'
                  data-testid='see-all-tvc'
                >
                  See All
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!_.isEmpty(webFindings) && (
        <GlobalSearchFindingView
          moduleData={webFindings}
          moduleLabel='Web'
          moduleType={appConstants.CONTENT_TYPE.WEB}
          handleFilterWithNavigation={handleFilterWithNavigation}
          handlePopUpClose={handleClose}
          webCounts={globalSearchFindingData.count?.[appConstants.CONTENT_TYPE.WEB]}
        />
      )}
      {!_.isEmpty(tvcDstFindings) && (
        <div className='global-search-findings-container'>
          <div className='module-header'>
            <img
              src={MODULE_ICON[appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]}
              alt='bulk-scan-module-logo'
            />
            <div className='module-label'>Takedown Visibility Center</div>
          </div>
          <div className='findings-detail'>
            {tvcDstFindings.slice(0, 5).map((finding: any) => {
              return (
                <div className='finding-wrapper' key={finding.urlSha256}>
                  <div
                    onClick={() => redirectToTVCInsightsPage(finding)}
                    className='finding-title'
                    data-testid={`tvc-finding-${finding.urlSha256}`}
                  >
                    {finding.srcUrl}
                  </div>
                  <DispositionStatus
                    key={finding.srcUrl}
                    status={finding.currentDisposition}
                    displayedStyle={'pill'}
                  />
                </div>
              );
            })}
            {showMoreTvcDstResults > MAX_SEARCH_RESULTS_LIMIT && (
              <div className='findings-more-description'>
                <div className='description'>
                  {showMoreTvcDstResults > MAX_SEARCH_RESULTS
                    ? `Showing 5 of 10000+ results`
                    : `Showing 5 of ${showMoreTvcDstResults} results`}
                </div>
                <div
                  onClick={() => {
                    handleTVCFilterWithNavigation('web', GLOBAL_TAKEDOWN_TABLE_ID);
                  }}
                  className='see-all'
                  data-testid='see-all-tvc'
                >
                  See All
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!_.isEmpty(webDstFindings) && (
        <GlobalSearchFindingView
          moduleData={webDstFindings}
          moduleLabel='Web'
          moduleType={appConstants.CONTENT_TYPE.WEB}
          handleFilterWithNavigation={handleFilterWithNavigation}
          handlePopUpClose={handleClose}
          webCounts={globalSearchFindingData.count?.[EDstUrlTableId[appConstants.CONTENT_TYPE.WEB]]}
        />
      )}
      {!_.every(smFindings, _.isEmpty) && (
        <GlobalSearchFindingView
          moduleData={smFindings}
          moduleLabel='Social Media'
          moduleType={appConstants.CONTENT_TYPE.SOCIAL}
          handleFilterWithNavigation={handleFilterWithNavigation}
          handlePopUpClose={handleClose}
          webCounts={globalSearchFindingData.count?.[appConstants.CONTENT_TYPE.SOCIAL]}
        />
      )}
      {!_.every(appStoreFindings, _.isEmpty) && (
        <GlobalSearchFindingView
          moduleData={appStoreFindings}
          moduleLabel='App Store'
          moduleType={appConstants.CONTENT_TYPE.APP_STORE}
          handleFilterWithNavigation={handleFilterWithNavigation}
          handlePopUpClose={handleClose}
          webCounts={globalSearchFindingData.count?.[appConstants.CONTENT_TYPE.APP_STORE]}
        />
      )}
      {!_.isEmpty(bulkScanFindings) && (
        <div className='global-search-findings-container'>
          <div className='module-header'>
            <img src={MODULE_ICON['bulk-scan']} alt='bulk-scan-module-logo' />
            <div className='module-label'>Bulk Scan</div>
          </div>
          <div className='findings-detail'>
            {bulkScanFindings.slice(0, 5).map((finding: any) => {
              return (
                <div className='finding-wrapper' key={finding.urlSha256}>
                  <div
                    onClick={() => redirectToBulkScanInsightsPage(finding)}
                    className='finding-title'
                    data-testid={`bulk-scan-finding-${finding.urlSha256}`}
                  >
                    {finding.srcUrl}
                  </div>
                </div>
              );
            })}
            {globalSearchFindingData.count?.['bulk-scan'] > MAX_SEARCH_RESULTS_LIMIT && (
              <div className='findings-more-description'>
                <div className='description'>
                  {globalSearchFindingData.count?.['bulk-scan'] > MAX_SEARCH_RESULTS
                    ? `Showing 5 of 10000+ results`
                    : `Showing 5 of ${globalSearchFindingData.count?.['bulk-scan']} results`}
                </div>
                <div
                  onClick={() => {
                    handleFilterBulkScanNavigation('bulk-scan');
                  }}
                  className='see-all'
                  data-testid='see-all-bulk-scan'
                  id='see-all-bulk-scan'
                >
                  See All
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!_.isEmpty(darkWebFindings) && (
        <div className='global-search-findings-container'>
          <div className='module-header'>
            <img src={MODULE_ICON[appConstants.CONTENT_TYPE.DARK_WEB]} alt='dark-web-module-logo' />
            <div className='module-label'>Dark Web</div>
          </div>
          <>
            {Object.keys(darkWebFindings).map((key: any) => {
              return (
                <Fragment key={key}>
                  <div className='finding-table-label'>{EDarkWebFindingStatusTable[key]}</div>
                  <div className='findings-detail'>
                    <>
                      {darkWebFindings[key].slice(0, 5).map((finding: any, index: number) => {
                        return (
                          <div className='finding-wrapper' key={index}>
                            <div className='finding-title title-with-no-insights'>
                              {globalSearchFindingData?.searchType === 'Email Address'
                                ? finding.email
                                : finding.ccn}
                            </div>
                          </div>
                        );
                      })}

                      <div className='findings-more-description'>
                        <div className='description'>
                          {`Showing ${Math.min(
                            globalSearchFindingData.count?.[appConstants.CONTENT_TYPE.DARK_WEB][
                              key
                            ],
                            MAX_SEARCH_RESULTS_LIMIT,
                          )} of ${
                            globalSearchFindingData.count?.[appConstants.CONTENT_TYPE.DARK_WEB][key]
                          } results`}
                        </div>
                        <div
                          onClick={() => {
                            handleFilterDarkWebWithNavigation(
                              'dark-web',
                              EDarkWebFindingStatus[key],
                            );
                          }}
                          className='see-all'
                          data-testid='see-all-dark-web'
                          id={`see-all-${key}`}
                        >
                          See All
                        </div>
                      </div>
                    </>
                  </div>
                </Fragment>
              );
            })}
          </>
        </div>
      )}

      {!_.isEmpty(assetsData) && (
        <div className='global-search-findings-container'>
          <div className='module-header'>
            <img src={MODULE_ICON['bulk-scan']} alt='ugc-assets-module-logo' />
            <div className='module-label'>Assets</div>
          </div>
          <>
            {Object.keys(assetsData).map((key: any) => {
              return (
                <Fragment key={key}>
                  <div className='finding-table-label'>{mappingModuleTypeToLabel[key]}</div>
                  <div className='findings-detail'>
                    <>
                      {assetsData[key].slice(0, 5).map((finding: any) => {
                        return (
                          <div className='finding-wrapper' key={finding.assetName}>
                            <div className='finding-title title-with-no-insights'>
                              {finding.assetName}
                            </div>
                          </div>
                        );
                      })}
                      <div className='findings-more-description'>
                        <div className='description'>
                          {`Showing ${assetsData[key].length} of ${assetsData[key].length} results`}
                        </div>
                        <div
                          className='see-all'
                          data-testid='see-all-assets'
                          onClick={() => handleAssetsFilterWithNavigation(key)}
                          id={`see-all-${key}`}
                        >
                          See All
                        </div>
                      </div>
                    </>
                  </div>
                </Fragment>
              );
            })}
          </>
        </div>
      )}
    </div>
  );
}
