import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TableContext } from '../table.context';

import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes } from '../../../../constants';
import DotDotDotWhiteIcon from '../../../../assets/icons/DotDotDotWhite.svg';
import DotDotDot from '../../../../assets/icons/DotDotDot.svg';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';

export default function BasicMenu({
  openOneGroupTagsOverlay,
  getRelatedFindings,
  setTagsAnchorEl,
}: {
  openOneGroupTagsOverlay: (value: boolean) => void;
  getRelatedFindings: () => Promise<void>;
  setTagsAnchorEl: (value: HTMLElement | null) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    void getRelatedFindings();
    setAnchorEl(event.currentTarget);
    setTagsAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { disputeModalShown, takedownModalShown } = React.useContext(TableContext);

  const handleDispute = React.useCallback(() => {
    disputeModalShown?.(true);
    setAnchorEl(null);
  }, [disputeModalShown]);

  const handleTakedown = React.useCallback(() => {
    takedownModalShown?.(true);
    setAnchorEl(null);
  }, [takedownModalShown]);

  const handleEditTags = React.useCallback(() => {
    openOneGroupTagsOverlay(true);
    setAnchorEl(null);
  }, [openOneGroupTagsOverlay]);

  const { selectedTheme } = React.useContext(ThemeContext);

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) {
    return null;
  }

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          src={selectedTheme === ThemeModes.DARK.toLowerCase() ? DotDotDotWhiteIcon : DotDotDot}
          alt={'dotdotdot'}
        />{' '}
      </Button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleDispute}>Dispute All</MenuItem>
        <MenuItem onClick={handleEditTags}>Edit Tags</MenuItem>
        <MenuItem onClick={handleTakedown}>Request Takedown</MenuItem>
      </Menu>
    </div>
  );
}
