import React, { useMemo } from 'react';
import { phoneNumberAnalysisParams } from '../constants';
import { Stack } from '@mui/material';
import LableValue from '../Insights/LableValue';
import DispositionInsightsBlock, { InsightsAnalysisData } from './DispositionInsightsBlock';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
import { sortDispositionByPriority } from '../helpers';

type AggByDispotion = {
  [key: string]: Array<InsightsAnalysisData>;
};

interface IAllEmailPhoneAnalysis {
  phoneNumberAnalysis: phoneNumberAnalysisParams;
}

const AllEmailPhoneAnalysis = ({ phoneNumberAnalysis }: IAllEmailPhoneAnalysis) => {
  const aggByDispotion: AggByDispotion = useMemo(() => {
    const data: AggByDispotion = {};
    phoneNumberAnalysis.phoneNumbers.forEach(phone => {
      const { initialDisposition } = phone;
      const formatedData: InsightsAnalysisData = {
        label: 'Phone Number',
        value: phone.phoneNumber || '',
        source: phone.source,
        sha256: phone?.sha256 || '',
      };
      if (data[initialDisposition as string]) {
        data[initialDisposition as string].push(formatedData);
      } else {
        data[initialDisposition as string] = [formatedData];
      }
    });
    return data;
  }, [phoneNumberAnalysis]);

  const renderNotAvailableText = () => {
    return <span className='not-available-text'>Not available</span>;
  };

  return (
    <div className='all-email-submissions-section-details-wrap phone-attachment'>
      <Stack direction={'row'} spacing={1}>
        <div className='stack-item'>
          <span
            className='section-header'
            data-testid={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.PHONE_NUMBER}
            id={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.PHONE_NUMBER}
          >
            Phone Number
          </span>
          <LableValue
            lable={`Number of Phone Number`}
            value={phoneNumberAnalysis.phoneNumberCounts}
            className='label-text-color'
          />
        </div>

        <div className='stack-item'>
          {!phoneNumberAnalysis.phoneNumberCounts && renderNotAvailableText()}
          {sortDispositionByPriority(Object.keys(aggByDispotion)).map(key => (
            <DispositionInsightsBlock
              type={ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.PHONE_NUMBER}
              initialDispotion={key}
              insightsAnalysisData={aggByDispotion[key]}
              key={key}
            />
          ))}
        </div>
      </Stack>
    </div>
  );
};

export default AllEmailPhoneAnalysis;
