import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PersistentFilters from '../../PersistentFilters/PersistentFilters';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { useAgGridEvents } from './useAgGridEvents';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { getAgGridThemeClassName } from './ag-utils';

import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';
import { TABLE_EMPTY_RESULTS_MESSAGE } from './constants';
import { alertActions } from '../../../../actions';
import useOnAgRowHighlight from './useOnAgRowHighlight';
import {
  fetchAbuseMailBoxTargetedMaliciousData,
  fetchAbuseMailBoxTargetedMaliciousThreatType,
} from './ag-requests';
import useOnGridReady from './useOnGridReady';
import {
  abuseMailBoxTargetedMaliciousParamsFilter,
  convertToFetchAbuseMailBoxTargetedMaliciousParams,
  generateAbuseMailBoxTargetedMaliciousColDefs,
} from '../../../AbuseMailbox/TargetedMalicious/TargetedMaliciousUtils';
import { ITargetedMaliciousColumn } from '../../../AbuseMailbox/TargetedMalicious/TargetedMaliciousColDefs';
import useAgGridFilterChange from './useAgGridFilterChange';
import {
  setIsPersistentFilterSaveButtonVisible,
  setIsWebTablesGroupView,
  setOutGoingPersistentFilterString,
} from '../../../../reducers/table.reducer';
import { setCurrentTableId } from '../../../../reducers/insightsContainer.reducer';
import {
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
  ABUSE_MAIL_BOX_MODULE_TYPE,
  getLabelByValue,
  TARGETED_MALICIOUS_THREAT_TYPES,
} from '../../../AbuseMailbox/constants';
import { ExportCSV } from '../../Export/ExportCSV';
import { ExportLoader } from '../../Export/ExportLoader';
import { useAgServerSideExportCSV } from './useAgServerSideExportCSV';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';
import '../../../AbuseMailbox/TargetedMalicious/TargetedMalicious.scss';
import { usePersistanceDate } from '../../../../basic-hooks/usePersistanceDate';
import {
  appConstants,
  DEFAULT_DATE_RANGE_V2,
  getLocalStorageValue,
  setLocalStorageValue,
  unsetLocalStorageValue,
} from '../../../../constants';
import _ from 'lodash';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);
export const TARGETED_MALICIOUS_DATE_PERSISTANCE_ID = `${appConstants.CONTENT_TYPE.ABUSE_MAILBOX}_targeted_malicious`;
export const ABUSE_MAILBOX_ALL_URL_ANALYSIS_DATE_PERSISTANCE_ID = `${appConstants.CONTENT_TYPE.ABUSE_MAILBOX}_all_email_submission`;
export const TARGETED_MALICIOUS_EXPANDED_ROWS = 'targeted-malicious-current-expanded-rows';
const BLOCK_SIZE = 50;

const AgGridAbuseMailBoxTargetedMalicious = ({
  columns,
  tableId,
  style,
  moduleType,
}: {
  columns: ITargetedMaliciousColumn[];
  tableId: typeof ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID;
  moduleType: typeof ABUSE_MAIL_BOX_MODULE_TYPE;
  style?: React.CSSProperties;
}) => {
  const agRef = useRef<AgGridReact>(null);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs } = useContext(TableContext);
  const dispatch = useAppDispatch();
  const [cachedAgRequest, setCachedAgRequest] = useState<IServerSideGetRowsRequest>();
  const { startDate, endDate, onDateChange } = usePersistanceDate(
    DEFAULT_DATE_RANGE_V2,
    TARGETED_MALICIOUS_DATE_PERSISTANCE_ID,
  );
  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({
      tableId,
    });

  const colDefs = useMemo<ColDef[]>(() => {
    return generateAbuseMailBoxTargetedMaliciousColDefs({
      columns,
      firstRenderDisplayColumnIdSet,
      tableId,
    });
  }, [columns, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const groupRowRendererParams = useMemo(() => {
    return {
      checkbox: true,
      innerRenderer: (params: ICellRendererParams) => {
        const { node } = params;

        return (
          <div className={`threat-type-group-rows ${node?.data.threatTypeCount === 0}`}>
            {getLabelByValue(params.node.data.threatType, TARGETED_MALICIOUS_THREAT_TYPES)} (
            {node?.data.threatTypeCount})
          </div>
        );
      },
    };
  }, []);

  useEffect(() => {
    agRef.current?.api?.onFilterChanged();
  }, [startDate, endDate]);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const agRequest: IServerSideGetRowsRequest = params.request;
        const { endRow, startRow } = agRequest;
        if (endRow === undefined || startRow === undefined) {
          return;
        }

        try {
          const apiParams = convertToFetchAbuseMailBoxTargetedMaliciousParams(agRequest, tableId);
          apiParams['pageSize'] = BLOCK_SIZE;
          apiParams['pageNumber'] = Math.ceil(endRow / BLOCK_SIZE) - 1;
          const isGroupDetailsRequest = agRequest.groupKeys.length > 0;
          if (!isGroupDetailsRequest) {
            const rawResponse = await fetchAbuseMailBoxTargetedMaliciousThreatType(apiParams);
            const threatTypeFindings = Object.entries(rawResponse)?.map(
              ([threatType, threatTypeCount]) => ({
                threatType,
                threatTypeCount,
              }),
            );
            if (threatTypeFindings.length === 0) {
              dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
            }
            setCachedAgRequest(agRequest);
            setTimeout(async () => {
              params.success({
                rowData: threatTypeFindings,
                rowCount: threatTypeFindings.length,
              });
              await expandRowGroups();
            }, 0);
          } else {
            const threatFilter: abuseMailBoxTargetedMaliciousParamsFilter = {
              field: 'threatType',
              value: agRequest.groupKeys[0],
              isNot: false,
              operator: 'eq',
            };
            apiParams.filter.push(threatFilter);
            const rawResponse = await fetchAbuseMailBoxTargetedMaliciousData(apiParams);
            setCachedAgRequest(agRequest);
            if (rawResponse.total === 0) {
              dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
            }

            params.success({
              rowData: rawResponse.threatFindings,
              rowCount: rawResponse.total,
            });
          }
        } catch (error) {
          console.log('Error fetching data :', error);
          params.fail();
        }
      },
    };
  }, [dispatch]);

  const { onGridReady } = useOnGridReady(datasource, tableId, agRef);

  const getRowId = useCallback((params: GetRowIdParams) => {
    if (params.data.timestamp && params.data.sha256 && params.data.disposition)
      return `row-id-${params.data.timestamp}-${params.data.sha256}-${params.data.threatType}-${params.data.disposition}`;
    return `row-id-${params.data.threatType}`;
  }, []);

  const { getRowClass } = useOnAgRowHighlight(false, 'threatTypeCount', ['threatType']);

  const { handleFirstDataRendered, handleOnPaginationChanged } =
    usePersistentTablePageNumber(tableId);

  const { handleUGCModuleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData: [],
  });

  const expandRowGroups = useCallback(async () => {
    const expandedRow = await getLocalStorageValue(TARGETED_MALICIOUS_EXPANDED_ROWS);
    if (_.isEmpty(expandedRow)) return;
    agRef?.current?.api?.forEachNode(node => {
      if (expandedRow === node.key) {
        unsetLocalStorageValue(TARGETED_MALICIOUS_EXPANDED_ROWS);
        node.setExpanded(true);
      }
    });
  }, []);

  useOnAgGridRefresh(agRef);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
      dispatch(setIsWebTablesGroupView(false));
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  const { exportError, isExporting, onExportCSV, closeExportLoader, rowCount } =
    useAgServerSideExportCSV({
      agRef,
      cachedAgRequest,
      tableId,
    });

  return (
    <>
      <div
        id='ag-table-container'
        data-testid='ag-table-container'
        className={getAgGridThemeClassName(selectedTheme)}
        style={style}
      >
        <div
          className='targeted-malicious-header-wrap'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* <DropdownDateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            type={appConstants.CONTENT_TYPE.ABUSE_MAILBOX}
            currentPersistantDatePickerPage={`${appConstants.CONTENT_TYPE.ABUSE_MAILBOX}_targeted_malicious`}
          /> */}
        </div>
        <div className='ag-grid-abuse-mailbox-table-tool-bar'>
          <PersistentFilters tableId={tableId} moduleType={moduleType} />
          <ExportCSV onClick={() => void onExportCSV()} style={{ marginLeft: '8px' }} />
        </div>
        <AgGridReact<AgGridColumnType>
          ref={agRef}
          getRowId={getRowId}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          localeText={localeText}
          columnMenu={'new'}
          onGridReady={onGridReady}
          rowModelType='serverSide'
          blockLoadDebounceMillis={300}
          onColumnVisible={handleColumnVisible}
          onColumnMoved={handleColumnMoved}
          onColumnResized={handleColumnResized}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onFirstDataRendered={handleFirstDataRendered}
          onPaginationChanged={handleOnPaginationChanged}
          onSortChanged={handleOnSortChanged}
          sideBar={sideBarConfigs}
          onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
          overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
          reactiveCustomComponents
          onGridSizeChanged={onHandleGridSizeChanged}
          getRowClass={getRowClass}
          enableCellTextSelection={true}
          onFilterChanged={handleUGCModuleFilterChanged}
          cacheBlockSize={BLOCK_SIZE}
          groupDisplayType={'groupRows'}
          groupSelectsChildren={true}
          showOpenedGroup={true}
          groupRowRendererParams={groupRowRendererParams}
          suppressExpandablePivotGroups={true}
          suppressContextMenu={true}
          animateRows={true}
        />

        <ExportLoader
          show={isExporting || exportError !== null}
          error={exportError || ''}
          totalCount={rowCount}
          closeExportLoader={closeExportLoader}
        />
      </div>
    </>
  );
};

export default AgGridAbuseMailBoxTargetedMalicious;
