import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import BarChart from '../../../Common/DashboardWidgets/BarChart';
import {
  IBarChartDataProps,
  IChartPlaceholderProps,
  TColorMap,
} from '../../../Common/DashboardWidgets/Types/chart.type';
import { getColors } from '../../../../constants';
import NoHosting from '../../../../assets/icons/NoHosting.svg';
import UgcService from '../../../../services/ugc.service';
import { ugcType } from '../../Types/ugc.types';
import { socialMediaMockPlatformWidgetData } from '../../../../constants';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

interface IComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  type: string;
  widgetsTitle: any;
  isCustomCalendarRange: boolean;
}

const ugcService = new UgcService();

const LIVE_LABEL = 'Live';
const DOWN_LABEL = 'Taken Down';
const LABEL_MAP = {
  LIVE: LIVE_LABEL,
  DOWN: DOWN_LABEL,
};

const convert2BarChartDataFormat = (rawData: any): IBarChartDataProps[] => {
  const result: IBarChartDataProps[] = [];
  _.forEach(rawData, detections => {
    const categories: { label: string; count: number }[] = [];
    const total = detections.count;
    _.forEach(detections.status, status => {
      categories.push({
        label: LABEL_MAP[status.label],
        count: status.count,
      });
    });
    result.push({
      label: detections.label,
      total,
      categories,
    });
  });
  return result;
};

const placeholder: IChartPlaceholderProps = {
  icon: NoHosting,
  title: 'No Detection found',
  description:
    'We haven’t detected any phish and scam site hosting for this period. Please select another time frame.',
};

function UgcPlatform({
  startDate,
  endDate,
  type,
  widgetsTitle,
  isCustomCalendarRange,
}: IComponentProps) {
  const [, selectedTheme] = useIsDarkMode();
  const { title, tooltipDescription } = widgetsTitle;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IBarChartDataProps[]>([]);

  const colorMap: TColorMap = {
    [LIVE_LABEL]: getColors(selectedTheme).socialLive,
    [DOWN_LABEL]: getColors(selectedTheme).socialTakenDown,
  };

  const isDemo = window.location.pathname.includes('premium');

  const setDetectionsData = (data: any) => {
    setData(convert2BarChartDataFormat(data.counts));
    setIsLoading(false);
  };

  const getDetectionData = () => {
    setIsLoading(true);
    const query = {
      field: type === ugcType.App_Store ? 'domain_label' : 'platform_label',
      subfield: 'status',
      startDate: startDate,
      endDate: endDate,
      limit: 5,
      isCustomCalendarRange: isCustomCalendarRange,
    };
    isDemo && type === 'social'
      ? setDetectionsData(socialMediaMockPlatformWidgetData)
      : ugcService
          .getDetectionsByFieldAndSubField(query, type)
          .then((res: any) => {
            setDetectionsData(res);
          })
          .catch(error => {
            console.log(error);
          });
  };

  useEffect(() => {
    getDetectionData();
  }, [startDate, endDate]);

  return (
    <BarChart
      title={title}
      isLoading={isLoading}
      description={tooltipDescription}
      data={data}
      colorMap={colorMap}
      placeholder={placeholder}
    />
  );
}

export { UgcPlatform };
