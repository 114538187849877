import _ from 'lodash';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ITableApiColumn } from '../../../../Common/Table/table.api';
import { COUNTRY_COLUMN } from '../../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import {
  ACTION_NEEDED_COLUMN,
  FIRST_FOUND_COLUMN,
  IS_LOGO_DETECTION_COL,
  SOURCE_COLUMN,
  TAKEDOWN_STATUS_COLUMN,
  TAKEDOWN_TIME_COLUMN,
} from '../../../../Ugc/Components/Findings/TableColDef/FindingsTableCols';
import { EFindingStatus } from '../../../../Ugc/Types/ugc.types';
import AgGridDateRangeFilter from '../../../../Common/Table/ag-table/AgGridDateRangeFilter';

export const APP_NAME: ITableApiColumn = {
  id: 'appName',
  accessor: 'site_title',
  header: 'App Name',
  fieldForExport: 'siteTitle',
  copyFieldValue: ({ siteTitle }) => {
    return siteTitle;
  },
  render: ({ siteTitle }) => {
    if (!siteTitle) {
      return '--';
    }
    return siteTitle;
  },
  agFilterType: 'agTextColumnFilter',
};

export const APP_STORE: ITableApiColumn = {
  id: 'domainLabel',
  accessor: 'domain_label',
  header: 'App Store',
  fieldForExport: 'domainLabel',
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ domainLabel }) => {
    return domainLabel;
  },
};

export const APP_STORE_IP_COLUMN = (urlPath: string): ITableApiColumn => ({
  id: 'ip',
  accessor: 'ip',
  header: 'IP Address',
  headerTooltip: 'Link to IP Details View',
  type: 'ipv4',
  copyFieldValue: ({ ip }) => {
    return ip;
  },
  render: (data: any) => {
    const { ip } = data;
    if (_.isEmpty(ip) || ip === '0.0.0.0') {
      return '--';
    }
    return (
      <div className={'table-ip-column'}>
        <Link to={`${urlPath}/ip/${ip}`}>{ip}</Link>
      </div>
    );
  },
  agFilterType: 'agTextColumnFilter',
});

export const APP_STORE_SOURCE_COLUMN: ITableApiColumn = {
  ...SOURCE_COLUMN,
  id: 'scanSource',
  header: 'Scan Source',
  accessor: 'scan_source',
  fieldForExport: 'scanSource',
  copyFieldValue: ({ scanSource }) => {
    return scanSource;
  },
  render: ({ scanSource }) => {
    if (!scanSource) {
      return '--';
    }
    return scanSource;
  },
  agFilterType: 'agTextColumnFilter',
};

export const APP_STORE_URL_COLUMN = (urlPath: string): ITableApiColumn => ({
  id: 'srcUrl',
  accessor: 'src_url',
  header: 'URL',
  fieldForExport: 'srcUrl',
  copyFieldValue: ({ srcUrl }) => {
    return srcUrl;
  },
  render: (data: any) => {
    const { srcUrl, urlSha256 } = data;
    if (srcUrl) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + srcUrl} className={'table-source-url-tooltip'}>
              {srcUrl}
            </Tooltip>
          }
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            <Link to={`${urlPath}/insights/${urlSha256}`}>{srcUrl}</Link>
          </div>
        </OverlayTrigger>
      );
    }
    return null;
  },
  hiddenInDragDrop: true,
  agFilterType: 'agTextColumnFilter',
});

// Hide this Cols as per ZION-5309
// export const APP_STORE_LAST_UPDATED: ITableApiColumn = {
//   id: 'appLastUpdated',
//   accessor: 'app_last_updated',
//   header: 'App Last Updated',
//   filterDisabled: true,
//   type: 'date',
//   render: ({ app_last_updated }) => {
//     if (!app_last_updated) return '--';
//     return app_last_updated;
//   },
// };

export const APP_STORE_ACTION_NEEDED_COLUMN: ITableApiColumn = {
  ...ACTION_NEEDED_COLUMN,
};

export const APP_STORE_IS_LOGO_DETECTION_COL: ITableApiColumn = {
  ...IS_LOGO_DETECTION_COL,
  id: 'brandLogoDetected',
  accessor: 'brand_logo_detected',
  copyFieldValue: ({ brand_logo_detected }) => {
    return brand_logo_detected;
  },
};

export const FIRST_SEEN_COLUMN: ITableApiColumn = {
  id: 'firstSeenTs',
  accessor: 'first_seen_ts',
  header: 'First Seen',
  fieldForExport: 'displayFirstSeenTime',
  isDefaultSort: true,
  type: 'date',
  isLongText: true,
  render: (data: any) => {
    return data.displayFirstSeenTime;
  },
  agFilterType: AgGridDateRangeFilter,
};

export const APP_STORE_COUNTRY_COL: ITableApiColumn = {
  ...COUNTRY_COLUMN,
  accessor: 'country_code',
  fieldForExport: 'countryCode',
};

export const APP_STORE_LAST_SCANNED: ITableApiColumn = {
  ...FIRST_FOUND_COLUMN,
  header: 'Last Scanned',
  fieldForExport: 'displayLastScannedTime',
  render: ({ displayLastScannedTime }) => {
    return displayLastScannedTime;
  },
  filterDisabled: false,
};

export const APP_STORE_TAKENDOWN_COL: ITableApiColumn = {
  ...TAKEDOWN_TIME_COLUMN,
};

export const APP_STORE_CATEGORY_COL: ITableApiColumn = {
  id: 'platform',
  accessor: 'platform_label',
  header: 'Category',
  fieldForExport: 'platformLabel',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  copyFieldValue: ({ platform_label }) => {
    return platform_label;
  },
};
export const APP_STORE_TAKEDOWN_STATUS_COL: ITableApiColumn = {
  ...TAKEDOWN_STATUS_COLUMN,
  filterDisabled: true,
};

export const MATCH_SEARCH_TERMS_COLUMN: ITableApiColumn = {
  id: 'searchTerm',
  accessor: 'search_term',
  header: 'Matched Search Terms',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
  copyFieldValue: ({ search_term }) => {
    return search_term;
  },
};
