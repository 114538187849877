import React from 'react';

interface RectangleBarProps {
  max: number;
  email: number;
  sms: number;
  category: string;
  color?: string;
}

export const MaliciousReportDistributionByDeliveryMethod: React.FC<RectangleBarProps> = ({
  max,
  email,
  sms,
  category,
  color,
}) => {
  const total = email + sms;
  const totalPercentage = (total / max) * 100 || 0;
  const emailPercentage = (email / total) * 100 || 0;
  const smsPercentage = (sms / total) * 100 || 0;

  return (
    <div className='by-delivery-method-bar-container'>
      <div className='category-label'>{category}</div>

      <div
        className='rectangle-bar'
        style={{
          width: `${totalPercentage}%`,
          maxWidth: `${totalPercentage}%`,
        }}
      >
        <div
          className={`email-bar ${emailPercentage ? 'email-bar-has-value' : ''}`}
          style={{ width: `${emailPercentage}%`, backgroundColor: color }}
        ></div>
        <div
          className={`sms-bar ${smsPercentage ? 'sms-bar-has-value' : ''}`}
          style={{ width: `${smsPercentage}%` }}
        ></div>
        <div className='total-number'>{total}</div>
      </div>
    </div>
  );
};
