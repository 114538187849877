import webModuleLogo from '../../assets/logo/WebModuleLogo.svg';
import smModuleLogo from '../../assets/logo/SocialModuleLogo.svg';
import appStorebModuleLogo from '../../assets/logo/AppStoreModuleLogo.svg';
import darkwebModuleLogo from '../../assets/logo/DarkwebModuleLogo.svg';
import bulkScanModuleLogo from '../../assets/logo/BulkScanModule.svg';
import tvcModuleLogo from '../../assets/logo/TVCModuleLogo.svg';

import { EFindingStatus } from '../Ugc/Types/ugc.types';
import { appConstants } from '../../constants';
import {
  MANAGED_BY_ORGANIZATION,
  MANAGED_BY_BOLSTER,
  MANAGED_BY_AFFILIATED,
  SOCIAL_MEDIA_TABLE_ID,
  APP_STORE_TABLE_ID,
} from '../Assets_v2/constants';
import {
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
} from '../SocialMedia/constants';
import {
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
} from '../AppStore/constants';
import {
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
} from '../MonitorAndTakedown/constants';

export const MAX_SEARCH_RESULTS_LIMIT = 5;
export const MAX_SEARCH_RESULTS = 10000;

export const MODULE_ICON = {
  [appConstants.CONTENT_TYPE.WEB]: webModuleLogo,
  [appConstants.CONTENT_TYPE.SOCIAL]: smModuleLogo,
  [appConstants.CONTENT_TYPE.APP_STORE]: appStorebModuleLogo,
  [appConstants.CONTENT_TYPE.DARK_WEB]: darkwebModuleLogo,
  ['bulk-scan']: bulkScanModuleLogo,
  [appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]: tvcModuleLogo,
};

export const moduleToLabel = {
  [appConstants.CONTENT_TYPE.WEB]: 'Web',
  [appConstants.CONTENT_TYPE.SOCIAL]: 'Social Media',
  [appConstants.CONTENT_TYPE.APP_STORE]: 'App Store',
  [appConstants.CONTENT_TYPE.DARK_WEB]: 'Dark Web',
  ['bulk-scan']: 'Bulk Scan',
  [appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]: 'Takedown Visibility Center',
};

export enum EGlobalSearchTableIds {
  UGC_ASSETS = 'ugc_assets',
  MANAGED_BY_ORG = 'managed-by-organization',
  MANAGED_BY_BOLSTER = 'managed-by-bolster',
  MANAGED_BY_AFFILIATES = 'managed-by-affiliates',
}

export const mappingModuleTypeToLabel = {
  ['pre-malicious']: 'Pre Malicious',
  ['post-malicious']: 'Post Malicious',
  ['takedown-malicious']: 'Takedown Malicious',
  [EFindingStatus.LIVE]: 'Live Detections',
  [EFindingStatus.IN_PROGRESS]: 'Takedown in Progress',
  [EFindingStatus.PAUSED]: 'Takedown in Progress',
  [EFindingStatus.DOWN]: 'Takendown Detections',
  [EFindingStatus.SAFELIST]: 'Ignored Detections',
  [appConstants.CONTENT_TYPE.APP_STORE]: 'App Store',
  [appConstants.CONTENT_TYPE.SOCIAL]: 'Social Media',
  [EGlobalSearchTableIds.MANAGED_BY_ORG]: 'Managed by Organization',
  [EGlobalSearchTableIds.MANAGED_BY_BOLSTER]: 'Managed by Bolster',
  [EGlobalSearchTableIds.MANAGED_BY_AFFILIATES]: 'Managed by Affiliates',
};

export const mappingModuleTypeToPlatformRoute = {
  [appConstants.CONTENT_TYPE.WEB]: 'web',
  [appConstants.CONTENT_TYPE.SOCIAL]: 'social-media',
  [appConstants.CONTENT_TYPE.APP_STORE]: 'app-store',
};

export const mappingModuleTypeIdToTableId = {
  [appConstants.CONTENT_TYPE.WEB]: {
    ['pre-malicious']: PRE_MALICIOUS_TABLE_ID,
    ['post-malicious']: POST_MALICIOUS_TABLE_ID,
    ['takedown-malicious']: TAKEDOWN_MALICIOUS_TABLE_ID,
  },
  [appConstants.CONTENT_TYPE.SOCIAL]: {
    [EFindingStatus.LIVE]: SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
    [EFindingStatus.IN_PROGRESS]: SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
    [EFindingStatus.DOWN]: SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
    [EFindingStatus.SAFELIST]: SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  },
  [appConstants.CONTENT_TYPE.APP_STORE]: {
    [EFindingStatus.LIVE]: APP_STORE_FINDING_DETECTION_TABLE_ID,
    [EFindingStatus.IN_PROGRESS]: APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
    [EFindingStatus.DOWN]: APP_STORE_TAKEN_DOWN_TABLE_ID,
    [EFindingStatus.SAFELIST]: APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
  },
};
export const mappingModuleTypeIdToTableRouteId = {
  ['pre-malicious']: 'pre-malicious',
  ['post-malicious']: 'post-malicious',
  ['takedown-malicious']: 'takedown-malicious',
  [EFindingStatus.LIVE]: 'detection',
  [EFindingStatus.IN_PROGRESS]: 'takedown-in-progress',
  [EFindingStatus.DOWN]: 'takendown',
  [EFindingStatus.SAFELIST]: 'ignored',
};

export const mappingModuleTypeToInsightsRoute = {
  [appConstants.CONTENT_TYPE.WEB]: '/web/digest',
  [appConstants.CONTENT_TYPE.SOCIAL]: '/social-media/insights',
  [appConstants.CONTENT_TYPE.APP_STORE]: '/app-store/insights',
  ['bulk-scan']: '/bulk-scan/digest',
};

export enum EWebModuleTabFields {
  'src_url' = 'srcUrl',
  'dst_url' = 'dstUrl',
}
export enum EWebModuleTabFilterFields {
  'src_url' = 'src_url',
  'dst_url' = 'dst_url',
}
export enum EWebAssetsModuleFilterFields {
  ASSET_NAME = 'asset_name',
  DOMAIN_NAME = 'domain_name',
}
export const mapAssetsTypeToTableRoute = {
  [EGlobalSearchTableIds.MANAGED_BY_ORG]: '/assets/managed-by-organization',
  [EGlobalSearchTableIds.MANAGED_BY_BOLSTER]: '/assets/managed-by-bolster',
  [EGlobalSearchTableIds.MANAGED_BY_AFFILIATES]: '/assets/managed-by-affiliates',
  [appConstants.CONTENT_TYPE.APP_STORE]: '/assets/official-account/app-store',
  [appConstants.CONTENT_TYPE.SOCIAL]: '/assets/official-account/social',
};

export const mapAssetsTypeToTableId = {
  [EGlobalSearchTableIds.MANAGED_BY_ORG]: MANAGED_BY_ORGANIZATION,
  [EGlobalSearchTableIds.MANAGED_BY_BOLSTER]: MANAGED_BY_BOLSTER,
  [EGlobalSearchTableIds.MANAGED_BY_AFFILIATES]: MANAGED_BY_AFFILIATED,
  [appConstants.CONTENT_TYPE.APP_STORE]: APP_STORE_TABLE_ID,
  [appConstants.CONTENT_TYPE.SOCIAL]: SOCIAL_MEDIA_TABLE_ID,
};

export enum ESelectedFilterTypeOptions {
  URL = 'URL',
  DOMAIN = 'Domain',
  IP_ADDRESS = 'IP Address',
  EMAIL_ADDRESS = 'Email Address',
  CCN = 'Credit Card Number',
  TAGS = 'Tags',
  ADVANCED_FILTERS = 'Advanced Filters',
}
export const EDstUrlTableId = {
  [appConstants.CONTENT_TYPE.WEB]: `${appConstants.CONTENT_TYPE.WEB}-dst-url`,
  [appConstants.CONTENT_TYPE
    .TAKEDOWN_VISIBILITY_CENTER]: `${appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER}-dst-url`,
};
