import moment from 'moment';
import { IDropdownOption } from '../Dropdown';
import {
  FINDINGS_TABLE_IDS,
  IMatFetchOptions,
  MALICIOUS_TABLE_IDS,
} from '../../MonitorAndTakedown/constants';
import { IFindingsTableUrls } from '../../../constants/dashboard.constants';
import { WebUrlData } from '../../../types/web-url-data.interface';
import { ITags } from '../Tags/Tags';
import { AlertActionsTypes, ThemeModes } from '../../../constants';
import { EFindingStatus, IFindingsTableItemProps } from '../../Ugc/Types/ugc.types';
import { EDarkWebFindingStatus } from '../../DarkWeb/Components/Types/DarkWeb.types';
import { DarkWebTableApiV2Params } from './ag-table/ag-utils';

export type TypeSortDirection = 'asc' | 'des' | 'desc' | undefined;
export type TExportTable = 'pdf' | 'csv';
export type TFilterAction = 'add' | 'remove' | 'edit';

export interface IFilter {
  isChecked?: boolean;
  id: string;
  filterType: string | undefined;
  filterBy: IDropdownOption;
  filterMethod: IDropdownOption;
  filterValue: any;
  filterLabel?: string;
  filterErr?: string;
  isCustomFilter?: boolean;
  hideFilter?: boolean;
  width?: number;
  filterSavedType?: 'persistent' | 'standard';
  isExcluded?: boolean;
  otherFilterCategories?: object[];
}

export interface ICustomFilterSystemProps {
  onFiltersApplied: (filters: IFilter[]) => void;
  appliedFilters: IFilter[];
}
export interface ITableProps {
  apiTs?: number;
  customTheme?: ThemeModes;
  userType?: any;
  tableClassName?: string;
  loadingWrapperClassName: string;
  fetchApi: boolean | ((query: object, filters: IFilter[], sort: any) => any);
  exportOptions: IDropdownOption[];
  exportTableApi: (query: object, type: TExportTable, filters: IFilter[], sort: any) => any;
  exportDataFn: (data: any, type: TExportTable) => any;
  deleteFn?: (items: any[]) => any;
  deleteModalConfig?: any;
  deleteUserModalConfig?: any;
  onRowDblClick: (row: object) => any;
  title: string;
  titleTooltip?: string;
  titleComp?: any;
  data: any[];
  indexBy: string;
  total?: number;
  rowId?: string;
  columns: object[];
  displayedColumnsIDs?: string[];
  filterAppliedOnFetchApi: boolean;
  onFilterApplied: (filters: IFilter[]) => void;
  customFilterSystem?: any;
  customFilterSystemProps?: any;
  initialFilters: IFilter[];
  initialPageSize: number;
  initialPageNumber: number;
  onPageChange: (page: number) => void;
  onSortChange: (sort: any) => void;
  enableCheckbox: boolean;
  onAllCheck: (checkbox: boolean, data?: any) => void;
  onItemCheck: (checkbox: boolean, data: any, allSelectedItems: any) => void;
  selectedItems?: any[];
  disableToolsBar: boolean;
  disableDatePicker: boolean;
  disableFilter: boolean;
  disablePagination: boolean;
  enableEditColumns: boolean;
  onSaveColumns?: (columnNames: string[]) => void;
  addText: string;
  addItemFn: any;
  editItemFn: any;
  deleteItemFn: any;
  sortBy: string;
  sortDirection: TypeSortDirection;
  startDate: moment.Moment;
  endDate: moment.Moment;
  onDateChange?: (startDate: moment.Moment, endDate: moment.Moment, lastXDays: number) => void;
  tileComp: any;
  tileCompProperties: any[];
  tileTopThreeProps: string[];
  tileCompEvents?: any;
  hideHeader?: boolean;
  customTools?: any;
  customToolsDirection?: boolean;
  showLoader: boolean;
  summaryWidget?: any;
  defaultLayout?: string;
  allUserAgents?: any;
  refreshOnTag?: boolean;
  onLayoutChanged?: () => void;
  type?: string;
  id?: any;
  user?: any;
  tagsAddedPerSha?: any;
  providedTags?: ITags[];
  tagOptions?: { label: 'True'; value: 'true' }[];
  findingsTableUrls?: IFindingsTableUrls;
  handleDisplayLayout?: (layout: string) => void;
  setFindingsTableUrls: (
    tableId: FINDINGS_TABLE_IDS,
    selected: IFindingsTableItemProps[],
    type: any,
  ) => void;
  hideDeleteBin?: boolean;
  alertSuccess?: (message: string) => AlertActionsTypes;
  alertError?: (message: string) => AlertActionsTypes;
  isOldTable?: boolean;
  isAgGridClientMode?: boolean;
  ugcStatus?: EFindingStatus;
  isWebTablesGroupView?: boolean;
  isDarkWebTablesGroupView?: boolean;
  setIsWebTablesGroupView: (isWebTablesGroupView: boolean) => void;
  setIsDarkWebTablesGroupView: (isDarkWebTablesGroupView: boolean) => void;
  darkwebStatus?: EDarkWebFindingStatus;
  setIsExportCSVCalled: (isExportCSVCalled: boolean) => void;
  setShouldRestorePageNumber: (shouldRestorePageNumber: boolean) => void;
  getUserInfo: () => void;
  brandPropertyId?: number;
  hideColumnHeader?: boolean;
}

export interface IHeaderProps {
  columns: object[];
  enableCheckbox: boolean;
  onAllCheck: (checkbox: boolean) => void;
  isAllSelected: boolean;
  sortBy: string;
  sortDirection: TypeSortDirection;
  onSortChange: (sortBy: string, sortDirection: TypeSortDirection) => void;
  editItemFn: any;
  deleteItemFn: any;
  type?: string;
  user?: any;
}

export interface IBodyProps {
  data: object[];
  rowId?: string;
  enableCheckbox: boolean;
  disablePagination: boolean;
  selectedItems: any;
  onCheck: (checked: boolean, item: any) => void;
  columns: object[];
  onRowDblClick: (row: object) => any;
  pageSize: number;
  addItemFn: any;
  editItemFn: any;
  deleteItemFn: any;
  toggleDeleteModal: any;
  type?: string;
  id?: string;
  user?: any;
  currentFetchOptions?: IMatFetchOptions;
  setSelectedWebUrls: (tableId: MALICIOUS_TABLE_IDS, selected: WebUrlData[]) => void;
  addingItemRowShown: boolean;
  onCloseAddItemRowShown: () => void;
}

export interface IRowProps {
  item: any;
  isNew: boolean;
  editing: boolean;
  enableCheckbox: boolean;
  selectedItems: any;
  onCheck: (checked: boolean, item: any) => void;
  columns: object[];
  onRowDblClick: (row: object) => any;
  addItemFn: any;
  editItemFn: any;
  deleteItemFn: any;
  toggleDeleteModal: any;
  onClose?: any;
  onRowSingleClick?: (idx: any) => void;
  idx?: any;
  type?: string;
  user?: any;
}

export interface IFilterProps {
  columns: object[];
  filters: IFilter[];
  autoAddFirstFilter: boolean;
  playbookFilterIds?: any[];
  onFiltersApplied: (filters: IFilter[]) => void;
  onFiltersCancelled: (filters: IFilter[]) => void;
  onToggleFilter: (toExpand: boolean) => void;
  alwaysExpanded?: boolean;
  afterFilterChange: (filters: IFilter[]) => void;
  selectedTheme?: string;
  tableLoading?: boolean;
  addPlaybookFilterIds?: (filterString: string) => void;
  removePlaybookFilterId?: (filterValue: string) => void;
  dataClass?: any;
  tagsOptions?: any[];
  type?: string;
}

export interface IControllerProps {
  total: number;
  pageSize: number;
  currentPageNumber: number;
  firstRowIndex: number;
  lastRowIndex: number;
  onPageChange: (page: number) => void;
  isLoading?: boolean;
  disableInput?: boolean;
}

export interface ITableRowDto {
  _id: string;
  _version: number;
  logo_detected_count: number;
  src_url: string;
  bolster_scan_count: number;
  current_brand_id: string;
  dst_url: string;
  cert: object;
  source: string;
  scan_location: string;
  scan_source: string;
  current_disposition_ts: string;
  latest_as_description: string;
  created_ts: string;
  primary_domain_risk_score: {
    human_readable_comments: string[];
    risk_score: number;
    risk_score_trending: number;
  };
  host: string;
  brand_scan_count: number;
  brand_logo_detected: boolean;
  final_category: string;
  primary_domain: string;
  matrix_brand_id: string;
  sub_brand_id: string;
  primary_domain_whois: {
    registrar_abuse_contact_email: string;
    domain_name: string;
    nameservers: string;
    registrar: string;
    registry_expiration_date: string;
    registrant_state: string;
    registrant_country: string;
    domain_registration_update_date: string;
    registrant_organization: string;
    domain_registration_date: string;
    domain_sha256: string;
    registrar_whois_server: string;
  };
  primary_domain_sha256: string;
  brand_scan_ts: string;
  sfb_verdict: string;
  ip: string;
  as_description: string;
  matrix_algorithm: string;
  current_disposition: string;
  has_mx_records: boolean;
  url_sha256: string;
  dispositions: string[];
  tld: string;
  scan_category: string;
  brand_id: string;
  tags: string[];
  country_code: string;
  disposition: string;
  matrix_job_id: string;
  first_seen_ts: string;
  takedowns: number;
  sfb_detected: boolean;
  last_updated_ts: string;
  site_title: string;
  status: string;
  timestamp: string;
  search_engine: boolean;
  sub_brand_display_name: string;
  pastPhishCountOnHost: {
    result: number;
  };
  pastPhishCountOnIP: {
    result: number;
  };
  image_path: string;
  disputeDetail: object;
  userTakedown: null | object;
  sourceFeed: string;
}

export enum WebScanSourceFilterOptions {
  BOLSTER = 'bolster',
  NOT_BOLSTER = 'not_bolster',
}
