import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Typeahead } from 'react-bootstrap-typeahead';

import { dashboardActions } from '../../actions';
import { AppState } from '../../helpers';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { PageTitle } from '../Common/PageTitle';
import './SuperAdmin.scss';
import {
  AlertActionsTypes,
  DashBoardDto,
  platformUserRolesMap,
  setLocalStorageValue,
} from '../../constants';
import { alertActions } from '../../actions';
import SuperAdminService from '../../services/super-admin.service';
import { Dropdown, DropdownOptionDefaultValue, IDropdownOption } from '../Common/Dropdown';
import { LabelAndValue } from '../Common/LabelAndValue';
import { Button } from '@mui/material';

interface IOrg {
  id: number;
  name: string;
  brands: IBrand[];
}

interface IBrand {
  brand_id: number;
  brand_name: string;
  brand_display_name: string;
}

interface ISuperAdminProps {
  user: DashBoardDto;
  getUserInfo: () => void;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

interface ISuperAdminState {
  orgs: IOrg[];
  orgOptions: IDropdownOption[];
  brandOptions: IDropdownOption[];
  defaultBrand: IDropdownOption;
  selectedOrgId: number | null | undefined;
  selectedBrand: string | null | undefined;
}

class SuperAdmin extends React.Component<ISuperAdminProps, ISuperAdminState> {
  private readonly superAdminService: SuperAdminService;
  private _isMounted = false;

  constructor(props: ISuperAdminProps) {
    super(props);
    this.state = {
      orgs: [],
      orgOptions: [],
      brandOptions: [],
      defaultBrand: DropdownOptionDefaultValue,
      selectedOrgId: null,
      selectedBrand: null,
    };

    this.props.getUserInfo();
    this.superAdminService = new SuperAdminService();
    window.document.title = 'Users | Bolster Platform';
  }

  componentDidMount() {
    this.superAdminService.loadAllBrands().then(data => {
      const orgOptions = data?.orgs?.map((org: IOrg) => ({ label: org.name, value: org.id }));
      this.setState({ orgs: data?.orgs, orgOptions });
    });
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  render() {
    return (
      <AuthenticationWrapper>
        <PageTitle title={'Super Admin'} titleAlwaysShown className={'background-white'} />
        <div className={'admin-page'}>
          {this.props.user && (
            <Row className={'top-container section-container'}>
              <Row className={'section-row'}>
                <Col xs={6}>
                  <LabelAndValue
                    label={'Email'}
                    value={this.props.user.email}
                    direction={'column'}
                  />
                </Col>
                <Col xs={6}>
                  <LabelAndValue
                    label={'Type of User'}
                    value={platformUserRolesMap[this.props.user.role_name]}
                    direction={'column'}
                  />
                </Col>
              </Row>
              <Row className={'section-row'}>
                <Col xs={6}>
                  <LabelAndValue
                    label={'Current Orgnization'}
                    value={this.props.user.organization}
                    direction={'column'}
                  />
                </Col>
                <Col xs={6}>
                  <LabelAndValue
                    label={'Current Brand'}
                    value={this.props.user.brand}
                    direction={'column'}
                  />
                </Col>
              </Row>
            </Row>
          )}
          <h6 className={'section-title'}>Switch Brand</h6>
          <Row className={'section-container'}>
            <Col xs={6} className='px-start--0 px-end--0'>
              <LabelAndValue
                direction={'column'}
                label={'Organization'}
                renderDom={
                  <Typeahead
                    className='type-head-container'
                    id={'brand-selector'}
                    labelKey={'label'}
                    placeholder={'Select a Organization'}
                    options={this.state.orgOptions}
                    onChange={(selected: any) => {
                      const { selectedOrgId } = this.state;
                      const selection = selected && selected[0];
                      if (selection) {
                        if (selection.value !== selectedOrgId) {
                          const selectedOrg = this.state.orgs.find(
                            org => org.id === selection.value,
                          );
                          const brandOptions =
                            selectedOrg?.brands?.map((brand: IBrand) => ({
                              label: brand.brand_name,
                              value: brand.brand_name,
                            })) || [];
                          let selectedBrand = null;
                          let defaultBrand = DropdownOptionDefaultValue;
                          if (brandOptions.length == 1) {
                            selectedBrand = brandOptions[0].value;
                            defaultBrand = brandOptions[0];
                          }
                          this.setState({
                            selectedOrgId: selectedOrg?.id,
                            selectedBrand,
                            defaultBrand,
                            brandOptions,
                          });
                        }
                      }
                    }}
                  />
                }
              />
            </Col>
            <Col xs={6}>
              <LabelAndValue
                direction={'column'}
                label={'Brand'}
                renderDom={
                  <Dropdown
                    disabled={this.state.brandOptions.length <= 0}
                    emptyText={'Select a Brand'}
                    boxStyle
                    options={this.state.brandOptions}
                    onChange={selection => {
                      this.setState({ selectedBrand: selection.value });
                    }}
                    defaultSelection={this.state.defaultBrand}
                    key={this.state.selectedOrgId}
                  />
                }
              />
            </Col>
            <Button
              disabled={!this.state.selectedBrand || this.state.selectedBrand.length <= 0}
              variant='contained'
              onClick={() => {
                if (this.state.selectedBrand && this.state.selectedBrand.length > 0) {
                  this.superAdminService.switchBrand(this.state.selectedBrand);
                  setLocalStorageValue('tableFilterModel', '');
                }
              }}
            >
              Switch
            </Button>
          </Row>
        </div>
      </AuthenticationWrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return { user };
};

const mapDispatchToProps = {
  getUserInfo: dashboardActions.getUserInfo,
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedAdmin = connect(mapStateToProps, mapDispatchToProps)(SuperAdmin);
export { connectedAdmin as SuperAdmin };
