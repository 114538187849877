import React, { useEffect, useMemo } from 'react';
import './NavItem.scss';
import { Link } from 'react-router-dom';
import { ThemeModes } from '../../../constants';
import { useState } from 'react';
import NavItemV2 from './NavItemV2';
import _ from 'lodash';
import { navItemIsActive } from '../../../helpers/url-helpers';
import LockIcon from '../../../assets/icons/LockGray.svg';
import LockIconDark from '../../../assets/icons/LockGrayDark.svg';
import ExpandCollapseIcon from '../../Common/ExpandCollapseIcon';
import { useContext } from 'react';
import ActiveAppContext from '../../../context/CheckphishActiveAppContext';
import { PRICING_PAGE_PATH } from '../../Pricing/constants';
import { useGetUser } from '../../../hooks/useAvailableModules';

type Props = {
  navItem: any;
  selectedTheme: string;
  triggerPaywall: any;
  isExpanded: boolean;
};

const ExpandableNavItem = ({ navItem, selectedTheme, triggerPaywall, isExpanded }: Props) => {
  const {
    id,
    label,
    href,
    subItems,
    iconDarkTheme,
    iconLightTheme,
    iconActiveDarkTheme,
    iconActiveLightTheme,
    disabled = false,
    isNew = false,
    hasIcon = false,
    isLocked = false,
    lockExpand = false,
    isSubMenuText = false,
    expandItem = false,
    onClick = _.noop,
  } = navItem;
  const [expandNavItem, setExpandNavItem] = useState<boolean>(false);
  const [icon, setIcon] = useState<any>(null);
  const { selectedApp } = useContext(ActiveAppContext);
  const user = useGetUser();

  const onClickFn = useMemo(() => {
    if (onClick) {
      const value = onClick();
      if (value?.type === 'hubspot_form') {
        return () => triggerPaywall(value.formType);
      }
    }
    return _.noop;
  }, [onClick]);

  useEffect(() => {
    if (selectedApp.value === 'checkphish' || isSubMenuText) {
      return setExpandNavItem(true);
    }
  }, [selectedApp]);

  const expandNav = (newState: boolean | null = null, mannualToggle: boolean = false) => {
    if (subItems.length === 0) {
      return setExpandNavItem(false);
    }
    if (newState !== null) return setExpandNavItem(newState);
    //send newState for normal button click.
    //set mannual toggle for dropdown buttons
    if (mannualToggle && !isLocked && hasIcon) return setExpandNavItem(oldState => !oldState);
    if (window.location.pathname === PRICING_PAGE_PATH && label === 'Domain Monitoring') {
      return setExpandNavItem(true);
    }
    if (!user.monitoredDomainPresent && label === 'Domain Monitoring') {
      return setExpandNavItem(true);
    }
    if (!hasIcon) return;
    if (lockExpand) return setExpandNavItem(true);
    const subItemIsActive = isSubItemActive();
    const isActive = navItemIsActive(href);
    setExpandNavItem(isActive || subItemIsActive);
  };

  const onLinkClick = (event: any) => {
    if (disabled) {
      event?.preventDefault();
    }
    onClickFn();
  };

  const showExpandedLine = subItems.reduce((accumulativeValue: boolean, currentItem: any) => {
    return accumulativeValue && _.isEmpty(currentItem.subItems);
  }, true);

  const isSubItemActive = () => {
    let isActive = false;
    if (_.isEmpty(subItems)) {
      return false;
    }
    let updatedSubMenu: any[] = [];
    subItems.forEach((subItem: any) => {
      if (subItem.isSubMenuText) {
        updatedSubMenu = [...updatedSubMenu, ...subItem.subItems];
      } else {
        updatedSubMenu = [...updatedSubMenu, subItem];
      }
    });
    updatedSubMenu.forEach((subItem: any) => {
      if (navItemIsActive(subItem.href)) {
        isActive = true;
      }
    });
    return isActive;
  };

  const setLockIcon = () => {
    return selectedTheme === ThemeModes.LIGHT.toLocaleLowerCase() ? LockIcon : LockIconDark;
  };

  useEffect(() => {
    expandNav();
  }, []);

  useEffect(() => {
    expandNav();
  }, [window.location.pathname, selectedTheme]);

  const selectNavIcon = useMemo(() => {
    if (isSubItemActive() || navItemIsActive(href, expandItem, expandNavItem)) {
      return selectedTheme.toLocaleLowerCase() === ThemeModes.LIGHT.toLocaleLowerCase()
        ? iconActiveLightTheme
        : iconActiveDarkTheme;
    }
    return selectedTheme.toLowerCase() === ThemeModes.LIGHT.toLocaleLowerCase()
      ? iconLightTheme
      : iconDarkTheme;
  }, [window.location.pathname, selectedTheme, expandItem, expandNavItem]);

  return (
    <div className={`expandable-item-wrap ${isSubMenuText ? ' no-spacing' : ''}`}>
      <div
        className={`expandable-item nav-item ${
          navItemIsActive(href, expandItem, expandNavItem) ? 'active' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        <button className='lable-wrap' onClick={() => expandNav(true)}>
          {hasIcon ? (
            <Link to={href}>
              <img className='item-icon' src={selectNavIcon} />
            </Link>
          ) : (
            <ExpandCollapseIcon
              isExpanded={expandNavItem}
              onClick={() => expandNav()}
              isDisabled={isSubMenuText}
            />
          )}
          <Link
            to={href}
            className={`sidebar-label item-label nav-link ${disabled ? 'disabled-link' : ''}`}
            onClick={onLinkClick}
          >
            {label}
          </Link>
          {isExpanded && (
            <div className={`tags-wrap ${isNew && isLocked && ' position-relative'}`}>
              {isNew && <span className='is-new-tag'>New!</span>}
              {isLocked && <img className='lock-img' src={setLockIcon()} />}
            </div>
          )}
        </button>
        {hasIcon && !lockExpand && (
          <button
            onClick={() => expandNav(null, true)}
            data-testid='expand-collapse-icon'
            className='expand-icon'
          >
            <ExpandCollapseIcon isExpanded={expandNavItem} isDisabled={isSubMenuText} />
          </button>
        )}
      </div>
      {expandNavItem && (
        <div className={`subitem-wrap ${showExpandedLine ? 'expanded-line' : ''}`}>
          {subItems.map((item: any, index: number) => {
            return (
              <NavItemV2
                key={index}
                navItem={item}
                selectedTheme={selectedTheme}
                triggerPaywall={triggerPaywall}
                isExpanded={isExpanded}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandableNavItem;
