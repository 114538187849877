import React, { FunctionComponent, useState } from 'react';
import './Image.scss';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

export const ON_ERROR_IMAGE = 'https://bst-web-assets.s3-us-west-2.amazonaws.com/misc/no.png';

interface IComponentProps {
  imagePath: string;
  alt?: string;
  className?: string;
  disableShowingFull?: boolean;
  onLoad?: (success: boolean) => void; // returns if the image was loaded successfully, if false the error image is used
}

const Image: FunctionComponent<IComponentProps> = ({
  imagePath = ON_ERROR_IMAGE,
  className = '',
  alt = 'image',
  disableShowingFull = false,
  onLoad,
}) => {
  const [fullImageShown, setFullImageShown] = useState<boolean>(false);
  const [isDarkMode, selectedTheme] = useIsDarkMode();

  const toggleFullImageShown = () => {
    setFullImageShown(!fullImageShown);
  };

  const compClassName = 'image-component ' + className;

  const cardImage = (
    <Card.Img
      onClick={toggleFullImageShown}
      className={`full-screen-image ${isDarkMode ? 'add-white-background' : ''} `}
      src={imagePath}
      alt={alt}
      onLoad={(e: React.SyntheticEvent<HTMLImageElement> & { target: HTMLImageElement }) => {
        if (e.target.src !== ON_ERROR_IMAGE) {
          onLoad?.(true);
        }
      }}
      onError={(e: any) => {
        e.target.src = ON_ERROR_IMAGE;
        onLoad?.(false);
      }}
    />
  );

  return (
    <div className={compClassName}>
      {cardImage}
      <Modal
        className={`image-modal ${selectedTheme}`}
        show={fullImageShown && !disableShowingFull}
        size='xl'
        centered={true}
        onHide={toggleFullImageShown}
      >
        <div className={'image-modal-content'}>{cardImage}</div>
      </Modal>
    </div>
  );
};

export { Image };
