import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { appConstants, getLocalStorageValue } from '../../../constants';
import {
  MALICIOUS_TABLE_IDS,
  mapTableIdToGroupedTableId,
} from '../../MonitorAndTakedown/constants';
import {
  setGroupedPaginationControls,
  setPaginationControls,
} from '../../../reducers/insightsContainer.reducer';

interface useOnPaginationControlsRefreshedProps {
  currentTableId: string;
  sha256: string;
  setCurrentUrlIndex: React.Dispatch<React.SetStateAction<number>>;
  moduleType: string;
}

export default function useOnPaginationControlsRefreshed({
  currentTableId,
  sha256,
  setCurrentUrlIndex,
  moduleType,
}: useOnPaginationControlsRefreshedProps) {
  const dispatch = useAppDispatch();
  const paginationControls = useAppSelector(
    state => state.insightsContainerReducer.paginationControls,
  );
  const groupedPaginationControls = useAppSelector(
    state => state.insightsContainerReducer.groupedPaginationControls,
  );

  const user = useAppSelector(state => state.dashboardReducer.user);

  const currentGroupedKey = useAppSelector(
    state => state.insightsContainerReducer.currentGroupParentKey,
  );

  const isGroupedViewEnabled = useMemo(
    () =>
      !!user?.userAppSetting?.find(
        (setting: any) =>
          setting.setting_name === 'group_findings' && setting.setting_value === 'true',
      ),
    [user],
  );

  // On Refresh the insight page we need to restore the pagination controls from the local storage
  useEffect(() => {
    if (getLocalStorageValue('isNotFromTableCol')) return;

    if (moduleType === appConstants.CONTENT_TYPE.WEB && isGroupedViewEnabled) {
      if (!groupedPaginationControls[currentTableId as string]) {
        const tableId = mapTableIdToGroupedTableId[currentTableId as MALICIOUS_TABLE_IDS];
        const localStorageGroupedPaginationControls = getLocalStorageValue([
          'groupedPaginationControls',
          tableId,
        ]);

        dispatch(
          setGroupedPaginationControls({
            [tableId as string]: localStorageGroupedPaginationControls,
          }),
        );
        if (
          localStorageGroupedPaginationControls?.[currentGroupedKey]?.urlShaAndTimestamps &&
          localStorageGroupedPaginationControls?.[currentGroupedKey]?.urlShaAndTimestamps.length > 0
        ) {
          const index = localStorageGroupedPaginationControls?.[
            currentGroupedKey
          ]?.urlShaAndTimestamps.findIndex((item: any) => item.urlSha256 === sha256);
          setCurrentUrlIndex(index);
        }
      }
    } else if (!paginationControls[currentTableId as string]) {
      const localStoragePaginationControls = getLocalStorageValue([
        'paginationControls',
        currentTableId as MALICIOUS_TABLE_IDS,
      ]);

      dispatch(
        setPaginationControls({ [currentTableId as string]: localStoragePaginationControls }),
      );
      if (
        localStoragePaginationControls?.urlShaAndTimestamps &&
        localStoragePaginationControls?.urlShaAndTimestamps.length > 0
      ) {
        const index = localStoragePaginationControls?.urlShaAndTimestamps.findIndex(
          (item: any) => item.urlSha256 === sha256,
        );
        setCurrentUrlIndex(index);
      }
    }
  }, [
    dispatch,
    currentTableId,
    sha256,
    paginationControls,
    setCurrentUrlIndex,
    isGroupedViewEnabled,
    groupedPaginationControls,
    currentGroupedKey,
    moduleType,
  ]);
}
