import React from 'react';
import _ from 'lodash';
import './Dropdown.scss';
import { Button, ListSubheader, MenuItem, Select } from '@mui/material';

export interface IDropdownOption {
  label: string;
  value: string;
  disabled?: boolean;
  docValue?: string;
}

export const DropdownOptionDefaultValue = {
  label: '',
  value: '',
};

interface IComponentProps {
  boxStyle: boolean;
  hideArrow: boolean;
  splitButton: boolean;
  disabled: boolean;
  variant: 'filled' | 'standard' | 'outlined';
  emptyText: any;
  fixedPlaceholder: any;
  btnClassName?: string;
  optionsHeader?: any;
  options: IDropdownOption[] | Record<string, IDropdownOption[]>;
  ordersPersist?: boolean;
  onChange: (value: any, controlId: string) => void;
  defaultSelection: IDropdownOption;
  controlId?: string;
  isLoading?: boolean;
  onSplitToggle?: (isToggled: boolean) => void;
  onClick?: () => void;
  className?: string;
}

interface IComponentState {
  selectedOption: IDropdownOption;
}

class Dropdown extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    boxStyle: false,
    hideArrow: false,
    splitButton: false,
    disabled: false,
    variant: 'outlined',
    emptyText: 'Please Select',
    fixedPlaceholder: '',
    btnClassName: '',
    onChange: _.noop,
    defaultSelection: {},
    controlId: '',
    className: '',
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      selectedOption: DropdownOptionDefaultValue,
    };
  }

  onSelected = (option: any) => {
    this.setState({
      selectedOption: option,
    });
    this.props.onChange(option, this.props.controlId || '');
  };
  renderFlatDropdown = (options: IDropdownOption[]) => {
    const { isLoading } = this.props;

    return [
      options.map((option: IDropdownOption) => {
        return (
          <MenuItem
            key={option.label}
            disabled={option.disabled}
            onClick={e => {
              e.stopPropagation();
              this.onSelected(option);
            }}
            onMouseDown={e => e.stopPropagation()}
            className={isLoading ? 'loading-skeleton' : ''}
            value={option.value}
          >
            {isLoading ? (
              <a href='#' className='btn'>
                {option.label}
              </a>
            ) : (
              option.label
            )}
          </MenuItem>
        );
      }),
    ];
  };

  renderGroupedDropdown = (options: Record<string, IDropdownOption[]>) => {
    return [
      options &&
        Object.keys(options).map((k, index: number) => {
          return [
            <ListSubheader key={k}>{k}</ListSubheader>,
            options[k].map((option: IDropdownOption) => {
              return (
                <MenuItem
                  key={option.label}
                  disabled={option.disabled}
                  onClick={() => {
                    this.onSelected({ ...option, value: option.label });
                  }}
                  value={option.label}
                >
                  {option.label}
                </MenuItem>
              );
            }),
          ];
        }),
    ];
  };

  onCLoseMenuHandler = () => {
    this.props.fixedPlaceholder && this.setState({ selectedOption: DropdownOptionDefaultValue });
  };

  onSaveFilterHandler = () => {
    this.props.onClick && this.props.onClick();
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.defaultSelection.label !== this.props.defaultSelection.label) {
      this.setState({
        selectedOption: { label: '', value: '' },
      });
    }
  }

  render() {
    const {
      boxStyle,
      hideArrow,
      splitButton,
      disabled,
      variant,
      emptyText,
      fixedPlaceholder,
      ordersPersist,
      defaultSelection,
      btnClassName = '',
      optionsHeader,
      onClick,
      className,
    } = this.props;
    const { selectedOption } = this.state;

    let compClassName = 'dropdown-component ' + btnClassName;
    if (boxStyle) {
      compClassName += ' box-style';
    }
    if (hideArrow) {
      compClassName += ' dropdown-hide-arrow';
    }

    let placeholder = emptyText;
    if (!_.isEmpty(fixedPlaceholder)) {
      placeholder = fixedPlaceholder;
    } else if (selectedOption && !_.isEmpty(selectedOption.label)) {
      placeholder = selectedOption.label;
    } else if (defaultSelection && !_.isEmpty(defaultSelection.label)) {
      placeholder = defaultSelection.label;
    }

    let { options } = this.props;

    if (options) {
      if (_.isArray(options)) {
        if (!ordersPersist) {
          options = _.sortBy(options, 'label');
        }
      } else {
        if (!ordersPersist) {
          Object.keys(options).forEach(k => {
            options[k] = _.sortBy(options[k], 'label');
          });
        }
      }
    }

    if (splitButton) {
      return (
        <>
          <Button
            variant='contained'
            onClick={this.onSaveFilterHandler}
            className='button-type--split'
          >
            {placeholder}
          </Button>

          <Select
            value={selectedOption.label}
            renderValue={() => ''}
            variant='outlined'
            size='small'
            sx={{
              minWidth: 32,
              marginInlineStart: 0,
              borderRadius: 0,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              '& .MuiSelect-select': {
                padding: '6px 12px',
              },
            }}
          >
            {_.isArray(options) && this.renderFlatDropdown(options)}
          </Select>
        </>
      );
    }

    return (
      <div className={compClassName}>
        <Select
          id='dropdown-basic-button'
          data-testid='dropdown-basic-button'
          renderValue={() => placeholder}
          value={this.state.selectedOption.value}
          displayEmpty
          MenuProps={{
            MenuListProps: {
              autoFocusItem: false,
            },
          }}
          onClose={this.onCLoseMenuHandler}
          disabled={disabled}
          variant={this.props.variant}
          className={className}
        >
          {_.isArray(options)
            ? this.renderFlatDropdown(options)
            : this.renderGroupedDropdown(options)}
        </Select>
      </div>
    );
  }
}

export { Dropdown };
