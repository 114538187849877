import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import '../../Insights/takedown.request.modal.scss';
import { Form } from 'react-bootstrap';
import AttachFile from '../../Common/AttachFile';
import ModalOverlay from '../../Common/ModalOverlay';
import DashboardService from '../../../services/dashboard.service';
import { DashBoardDto, appConstants, featureIsAvailable } from '../../../constants';
import { Checkbox } from '../../Common/Checkbox';
import { BulkScanSelectedItem } from '../BulkScanConstants';
import { WebUrlData } from '../../../types/web-url-data.interface';
import { useReadOnlyUser } from '../../../basic-hooks/useUserRoles';

const dashboardService = new DashboardService();

export interface ITakedownRequestModalProps {
  data: (BulkScanSelectedItem | WebUrlData)[];
  show: boolean;
  onCloseModal: () => void;
  onSuccess: (res: any, msg: string) => void;
  onFailure?: (message: string) => void;
  user?: DashBoardDto;
  itemOnSelect?: any;
}

const BulkScanTakedownRequestModal = ({
  data,
  show,
  onSuccess = _.noop,
  onFailure = _.noop,
  onCloseModal,
  user,
  itemOnSelect,
}: ITakedownRequestModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const [googleSafeBrowsing, setGoogleSafeBrowsing] = useState<boolean>(false);
  const [apwgBlocklist, setApwgBlocklist] = useState<boolean>(false);
  const [validSelections, setValidSelections] = useState<(BulkScanSelectedItem | WebUrlData)[]>([]);
  const [inValidSelections, setInValidSelections] = useState<(BulkScanSelectedItem | WebUrlData)[]>(
    [],
  );

  const attachedFiles = useRef<File[]>([]);

  useEffect(() => {
    // clear comments when hide/show
    setComments('');
  }, [show]);

  useEffect(() => {
    const brandInfo = user?.brand_info.brand;
    if (brandInfo) {
      const isValidDetections = data.filter((selected: BulkScanSelectedItem | WebUrlData) => {
        if ('brandId' in selected) {
          return (
            ((!brandInfo?.brandMapping && selected.brandId === user?.brand) ||
              (brandInfo?.brandMapping && brandInfo.subBrandNames.includes(selected.brandId))) &&
            selected.disposition !== 'clean'
          );
        } else {
          return (
            ((!brandInfo?.brandMapping && selected.brand_id === user?.brand) ||
              (brandInfo?.brandMapping && brandInfo.subBrandNames.includes(selected.brand_id))) &&
            selected.disposition !== 'clean'
          );
        }
      });
      const isInValidDetections = data.filter((selected: BulkScanSelectedItem | WebUrlData) => {
        if ('brandId' in selected) {
          return (
            (!brandInfo?.brandMapping && selected.brandId !== user?.brand) ||
            (brandInfo?.brandMapping && !brandInfo?.subBrandNames.includes(selected.brandId)) ||
            selected.disposition === 'clean'
          );
        } else {
          return (
            (!brandInfo?.brandMapping && selected.brand_id !== user?.brand) ||
            (brandInfo?.brandMapping && !brandInfo?.subBrandNames.includes(selected.brand_id)) ||
            selected.disposition === 'clean'
          );
        }
      });
      setValidSelections(isValidDetections);
      setInValidSelections(isInValidDetections);
    }
  }, [data, user?.brand, user?.brand_info?.brand]);

  const handleRequestTakedown = () => {
    setIsLoading(true);
    const formData = new FormData();
    const urlScans = _.map(validSelections, i => ({
      urlSha256: i.url_sha256,
      timestamp: i.timestamp,
      currentDisposition: i.disposition,
    }));
    formData.append('urlScansRaw', JSON.stringify(urlScans));
    formData.append('comments', comments);
    formData.append('googleSafeBrowsing', String(googleSafeBrowsing));
    formData.append('apwgBlocklist', String(apwgBlocklist));
    for (let i = 0; i < attachedFiles.current.length; i++) {
      formData.append('files', attachedFiles.current[i]);
    }
    dashboardService
      .bulkActionTakedownRequest(formData)
      .then((res: any) => {
        setIsLoading(false);
        onSuccess(res, 'Takedown Request Sent Successfully!');
        onCloseModal();
      })
      .catch(() => {
        setIsLoading(false);
        onFailure('Takedown Request Failure. Please contact support@bolster.ai.');
      })
      .finally(() => {
        itemOnSelect && itemOnSelect(null, null, []);
      });
  };

  const onExclude = () => {
    itemOnSelect(null, null, validSelections);
  };
  const isOldTable = !user || !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

  const isReadOnlyUser = useReadOnlyUser();
  if (isReadOnlyUser) {
    return null;
  }

  return (
    <ModalOverlay
      show={isOldTable ? show : true}
      title={'Request Takedown'}
      submitButtonLabel={'Confirm'}
      onCancel={onCloseModal}
      onSubmit={handleRequestTakedown}
      isLoading={isOldTable ? isLoading : isLoading || !show}
      submitButtonDisabled={inValidSelections.length > 0}
      hideSubmitBtn={inValidSelections.length > 0 && validSelections.length === 0}
    >
      <div className={'takedown-modal'}>
        {validSelections.length > 0 && (
          <>
            <div className={'takedown-modal-desc'}>
              <div className='takedown-modal-text'>
                Are you sure you want to request a takedown for the following addresses:
              </div>
              <div className='takedown-modal-desc-wrapper'>
                {validSelections.map((selection, index) => (
                  <div key={index} className='long-text-ellipsis-1 mt-1'>
                    {'url' in selection ? selection.url : selection.srcUrl}
                  </div>
                ))}
              </div>
            </div>
            <AttachFile
              label='Attach File'
              onChangeHandler={files => (attachedFiles.current = files)}
            />
            <Form className={'takedown-modal-form'}>
              <Form.Group>
                <Form.Label className={'takedown-modal-form-label'}>Comments</Form.Label>
                <Form.Control
                  className={'takedown-modal-form-input'}
                  as='textarea'
                  rows={3}
                  maxLength={1000}
                  value={comments}
                  onChange={e => setComments(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group className={'align-items-center d-flex flex-row '}>
                <Form.Label className={'takedown-modal-form-label'}>Report URLs to:</Form.Label>
                <Checkbox
                  label='Google SafeBrowsing'
                  isStateless={false}
                  onChange={checked => {
                    setGoogleSafeBrowsing(checked);
                  }}
                  defaultChecked={googleSafeBrowsing}
                />
                <Checkbox
                  label='APWG Blocklist '
                  isStateless={false}
                  onChange={checked => {
                    setApwgBlocklist(checked);
                  }}
                  defaultChecked={apwgBlocklist}
                />
              </Form.Group>
            </Form>
          </>
        )}
        {inValidSelections.length > 0 && (
          <div className='takedown-invalid-section'>
            <div className='pb-3 takedown-modal-error-desc'>
              The following URLs are either not related to your brand or have Disposition = Clean
              and in both these cases are not eligible for takedowns. If you have evidence that they
              need to be taken down, then you need to dispute their disposition first.
            </div>
            <div className='pb-3'>
              Please exclude the following addresses before request takedown.
            </div>
            <div className={'takedown-modal-desc'}>
              {inValidSelections.map((selection, index) => (
                <b key={index} className='long-text-ellipsis-1'>
                  {'url' in selection ? selection.url : selection.srcUrl}
                </b>
              ))}
            </div>
            {validSelections.length > 0 && inValidSelections.length > 0 && (
              <div className='takedown-exclude-btn' onClick={onExclude}>
                Exclude All
              </div>
            )}
          </div>
        )}
      </div>
    </ModalOverlay>
  );
};

export default BulkScanTakedownRequestModal;
