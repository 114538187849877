import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BULK_SCAN_TABLE_ID } from '../components/BulkScan/BulkScanConstants';
import {
  FINDINGS_TABLE_IDS,
  GROUPED_MALICIOUS_TABLE_IDS,
  MALICIOUS_TABLE_IDS,
} from '../components/MonitorAndTakedown/constants';

export interface IPaginationControls {
  [key: string]: {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    tableId:
      | MALICIOUS_TABLE_IDS
      | FINDINGS_TABLE_IDS
      | typeof BULK_SCAN_TABLE_ID
      | Omit<GROUPED_MALICIOUS_TABLE_IDS, 'grouped'>;
    totalPages: number;
    urlShaAndTimestamps: { urlSha256: string; timestamp: number }[];
  };
}
export interface IGroupedPaginationControls {
  [key: string]: {
    [key: string]: {
      currentPage: number;
      pageSize: number;
      totalItems: number;
      tableId: MALICIOUS_TABLE_IDS | GROUPED_MALICIOUS_TABLE_IDS;
      totalPages: number;
      urlShaAndTimestamps: { urlSha256: string; timestamp: number }[];
    };
  };
}
export interface IInsightsContainerState {
  currentTableId: string;
  goBackUrl: string;
  paginationControls: IPaginationControls;
  groupedPaginationControls: IGroupedPaginationControls;
  shouldFetchInsightsOnNext: boolean;
  currentGroupParentKey: string;
}
const initialState: IInsightsContainerState = {
  currentTableId: '',
  goBackUrl: '',
  paginationControls: {},
  groupedPaginationControls: {},
  shouldFetchInsightsOnNext: false,
  currentGroupParentKey: '',
};

export const insightsContainerSlice = createSlice({
  name: 'insightsContainer',
  initialState,
  reducers: {
    setCurrentTableId: (state, action: PayloadAction<string>) => {
      state.currentTableId = action.payload;
    },
    setGoBackUrl: (state, action: PayloadAction<string>) => {
      state.goBackUrl = action.payload;
    },
    setPaginationControls: (
      state,
      action: PayloadAction<IInsightsContainerState['paginationControls']>,
    ) => {
      state.paginationControls = action.payload;
    },
    setGroupedPaginationControls: (
      state,
      action: PayloadAction<IInsightsContainerState['groupedPaginationControls']>,
    ) => {
      state.groupedPaginationControls = action.payload;
    },
    setShouldFetchInsightsOnNext: (state, action: PayloadAction<boolean>) => {
      state.shouldFetchInsightsOnNext = action.payload;
    },
    setCurrentGroupParentKey: (state, action: PayloadAction<string>) => {
      state.currentGroupParentKey = action.payload;
    },
  },
});

export const {
  setCurrentTableId,
  setGoBackUrl,
  setPaginationControls,
  setShouldFetchInsightsOnNext,
  setGroupedPaginationControls,
  setCurrentGroupParentKey,
} = insightsContainerSlice.actions;

export default insightsContainerSlice.reducer;
