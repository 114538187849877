import React, { useState, useRef, createContext, useEffect } from 'react';
import {
  DarkMUIThemeOptions,
  IRunByClientAppType,
  LightMUIThemeOptions,
  ThemeModes,
  UserAppSettings,
} from '../constants';
import { connect } from 'react-redux';
import { AppState } from '../helpers';
import _ from 'lodash';
import UserService from '../services/user.service';
import { appActions, dashboardActions } from '../actions';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material';

const muiTheme = createTheme(LightMUIThemeOptions as ThemeOptions);
const darkMuiTheme = createTheme(DarkMUIThemeOptions as ThemeOptions);

export const defaultThemeContextValue = {
  themeName: '',
  selectedTheme: '',
  toggle: (value: string) => {},
};
const ThemeContext = createContext(defaultThemeContextValue);

export default ThemeContext;

interface IThemeProps {
  value: any;
  children: any;
  user?: any;
}
interface ILinkDispatchProps {
  getUserInfo: () => void;
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
}
type Props = IThemeProps & ILinkDispatchProps;

const ThemeContextProvider = (props: Props): any => {
  const userService = new UserService();

  const [themeName, setThemeName] = useState('');
  const [selectedTheme, setSelectedTheme] = useState<string>('');
  const [themeToggled, setThemeToggled] = useState(false);
  const themePreferences = useRef<any>();

  useEffect(() => {
    themePreferences.current = _.first(
      props.user.userAppSetting.filter((item: any) => item.setting_name === UserAppSettings.Theme),
    );
  }, [props.user]);

  useEffect(() => {
    // need to set the theme on the body, some bootstrap elements get created directly as children of the body.
    if (selectedTheme === ThemeModes.DARK.toLowerCase()) {
      window.document.body.classList.remove(ThemeModes.LIGHT.toLowerCase());
      window.document.body.classList.add(ThemeModes.DARK.toLowerCase());
    } else {
      window.document.body.classList.remove(ThemeModes.DARK.toLowerCase());
      window.document.body.classList.add(ThemeModes.LIGHT.toLowerCase());
    }
  }, [selectedTheme]);

  useEffect(() => {
    const autoSwitchTheme = (event: any) => {
      if (themePreferences.current !== undefined) {
        if (
          themePreferences.current?.setting_value === ThemeModes.AUTO.toLowerCase() &&
          event.matches
        ) {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.DARK.toLowerCase());
        } else if (themePreferences.current?.setting_value === ThemeModes.AUTO.toLowerCase()) {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.LIGHT.toLowerCase());
        }
      } else {
        if (event.matches) {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.DARK.toLowerCase());
        } else {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.LIGHT.toLowerCase());
        }
      }
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', autoSwitchTheme);
    // cleanup this component
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', autoSwitchTheme);
    };
  }, []);

  useEffect(() => {
    const autoSwitchTheme = (event: any) => {
      const themePreferences: any = _.first(
        props.user.userAppSetting.filter(
          (item: any) => item.setting_name === UserAppSettings.Theme,
        ),
      );
      if (themePreferences !== undefined) {
        if (themePreferences?.setting_value === ThemeModes.AUTO.toLowerCase() && event.matches) {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.DARK.toLowerCase());
        } else if (themePreferences?.setting_value === ThemeModes.AUTO.toLowerCase()) {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.LIGHT.toLowerCase());
        }
      } else {
        if (event.matches) {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.DARK.toLowerCase());
        } else {
          setThemeName(ThemeModes.AUTO);
          setSelectedTheme(ThemeModes.LIGHT.toLowerCase());
        }
      }
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      autoSwitchTheme(e);
    });

    // cleanup this component
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        autoSwitchTheme(e);
      });
    };
  }, [props.user]);

  useEffect(() => {
    updateLocalThemeState();
  }, [props.user.userAppSetting]);

  useEffect(() => {
    if (themeToggled) {
      updateThemeSettings();
    }
  }, [themeToggled]);

  const selectedThemeCheck = (theme: string) => {
    return theme === ThemeModes.DARK.toLowerCase()
      ? ThemeModes.DARK.toLowerCase()
      : theme === ThemeModes.AUTO.toLowerCase()
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
        ? ThemeModes.DARK.toLowerCase()
        : ThemeModes.LIGHT.toLowerCase()
      : ThemeModes.LIGHT.toLowerCase();
  };
  const updateLocalThemeState = () => {
    const isCommunity = props.runByClientApp({
      onCheckPhish: () => true,
      onBolster: () => false,
    });
    if (props.user.first_name === '' && isCommunity) {
      setThemeName(ThemeModes.AUTO);
      setSelectedTheme(ThemeModes.LIGHT.toLowerCase());
    } else {
      const isBrandedUser = props.user.type_name === 'brand';

      const themePreferences: any = _.first(
        props.user.userAppSetting.filter(
          (item: any) => item.setting_name === UserAppSettings.Theme,
        ),
      );
      if (themePreferences !== undefined) {
        setThemeName(
          themePreferences?.setting_value === ThemeModes.DARK.toLowerCase()
            ? ThemeModes.DARK
            : themePreferences?.setting_value === ThemeModes.AUTO.toLowerCase()
            ? ThemeModes.AUTO
            : ThemeModes.LIGHT,
        );
        setSelectedTheme(selectedThemeCheck(themePreferences?.setting_value));
      } else {
        setThemeName(ThemeModes.AUTO);
        if (isBrandedUser) {
          setSelectedTheme(
            window.matchMedia('(prefers-color-scheme: dark)').matches
              ? ThemeModes.DARK.toLowerCase()
              : ThemeModes.LIGHT.toLowerCase(),
          );
        } else {
          setSelectedTheme(ThemeModes.DARK.toLowerCase());
        }
      }
    }
  };

  const updateThemeSettings = () => {
    userService
      .updateUserSettings({
        setting_name: UserAppSettings.Theme,
        setting_value: themeName.toLowerCase(),
      })
      .then(res => {
        props.getUserInfo();
        setThemeToggled(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const toggle = (value: string) => {
    setThemeName(value);
    setSelectedTheme(selectedThemeCheck(themeName));
    setThemeToggled(true);
  };

  return (
    <ThemeContext.Provider value={{ themeName, toggle, selectedTheme }}>
      <ThemeProvider
        theme={selectedTheme === ThemeModes.DARK.toLowerCase() ? darkMuiTheme : muiTheme}
      >
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};
const mapDispatchToProps = {
  getUserInfo: dashboardActions.getUserInfo,
  runByClientApp: appActions.runByClientApp,
};

const connectedThemeContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeContextProvider);
export { connectedThemeContextProvider as ThemeContextProvider };
