import {
  ColDef,
  FilterModel,
  ICellRendererParams,
  IServerSideGetRowsRequest,
  ValueFormatterParams,
} from 'ag-grid-community';
import React from 'react';
import { getAgFilterParams } from '../../Common/Table/ag-table/ag-col-defs';
import { transformToGlobalWebTakeDownFilterToApiParams } from '../../Common/Table/ag-table/ag-filter-to-api-params';
import { getDefaultEndDate, getDefaultStartDate } from '../../Common/Table/ag-table/constants';
import {
  ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
  ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID,
} from '../constants';
import { ITargetedMaliciousColumn } from './TargetedMaliciousColDefs';
import _ from 'lodash';
import { getPersistanceDate } from '../../../basic-hooks/usePersistanceDate';
import {
  ABUSE_MAILBOX_ALL_URL_ANALYSIS_DATE_PERSISTANCE_ID,
  TARGETED_MALICIOUS_DATE_PERSISTANCE_ID,
} from '../../Common/Table/ag-table/AgGridAbuseMailBoxTargetedMalicious';
import { IAllUrlAnalysisColumns } from '../AllEmailSubmissions/AllUrlAnalysis/AllUrlAnalysisColDefs';

export interface abuseMailBoxTargetedMaliciousParamsFilter {
  field: string;
  operator: string;
  value: string | Date | number | string[] | number[];
  isNot?: boolean;
  timeZone?: string;
}

export interface abuseMailBoxTargetedMaliciousParams {
  filter: Array<abuseMailBoxTargetedMaliciousParamsFilter>;
  pageSize: number;
  pageNumber: number;
  after?: any;
  excludeThreatShas?: string[];
  order: {
    field: string;
    order: 'asc' | 'desc';
  };
  columns?: {
    header?: string;
    field?: string;
  }[];
}

export const convertToFetchAbuseMailBoxTargetedMaliciousParams = (
  params: IServerSideGetRowsRequest,
  tableId:
    | typeof ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID
    | typeof ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID,
): abuseMailBoxTargetedMaliciousParams => {
  const persistanceDateID =
    tableId === ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID
      ? TARGETED_MALICIOUS_DATE_PERSISTANCE_ID
      : ABUSE_MAILBOX_ALL_URL_ANALYSIS_DATE_PERSISTANCE_ID;
  const dateFilter = getPersistanceDate(persistanceDateID);

  const apiParams: abuseMailBoxTargetedMaliciousParams = {
    filter: [
      {
        field: 'submissionTs',
        operator: 'gte',
        value: getDefaultStartDate(dateFilter?.startDate),
        isNot: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        field: 'submissionTs',
        operator: 'lte',
        value: getDefaultEndDate(dateFilter?.endDate),
        isNot: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    ],
    pageSize: 0,
    pageNumber: 0,
    order: {
      field:
        tableId === ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID ? 'lastSeenTargeted' : 'lastSeen',
      order: 'desc',
    },
  };
  if (params.sortModel && params.sortModel.length > 0) {
    const sort = params.sortModel[0];
    apiParams.order.field = sort.colId;
    apiParams.order.order = sort.sort as 'asc' | 'desc';
  }
  if (
    params.filterModel &&
    typeof params.filterModel === 'object' &&
    Object.keys(params.filterModel).length !== 0
  ) {
    const apiFilterParams = transformAbuseMailBoxFilterToApiParams(params.filterModel);
    apiParams.filter.push(...apiFilterParams);
  }

  return apiParams;
};

export interface TargetedMaliciousFilter {
  field: string;
  operator: string;
  value: string;
  isNot: boolean;
}

export function transformAbuseMailBoxFilterToApiParams(
  original: FilterModel,
): TargetedMaliciousFilter[] {
  const filterParams: TargetedMaliciousFilter[] = [];
  const apiFilterParams: TargetedMaliciousFilter[] = transformToGlobalWebTakeDownFilterToApiParams(
    original,
    ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID,
  );
  filterParams.push(...apiFilterParams);
  Object.keys(original).forEach(key => {
    const item = original[key];
    if (item.filterType === 'number' && item.type === 'inRange') {
      const rangeFilters = [
        { operator: 'gte', filter: 'filter' },
        { operator: 'lte', filter: 'filterTo' },
      ];

      rangeFilters.forEach(({ operator, filter }) => {
        filterParams.push({
          field: key,
          operator: operator,
          value: item[filter],
          isNot: false,
        });
      });
    }
  });
  return filterParams;
}

const getColumnRendereTargetedMalicious = (column: ITargetedMaliciousColumn) => {
  if (column['id'] === 'threatType') return 'agGroupCellRenderer';
  return function CustomColRenderer(params: ICellRendererParams) {
    if (typeof column['render'] === 'function') {
      return column['render'](params.data || {}) || '--';
    }
    return typeof params.value === 'object' || params.value === undefined ? (
      '--'
    ) : (
      <span>{params.value || '--' + ''}</span>
    );
  };
};

export const generateAbuseMailBoxTargetedMaliciousColDefs = ({
  columns,
  firstRenderDisplayColumnIdSet,
  tableId,
}: {
  columns: ITargetedMaliciousColumn[] | IAllUrlAnalysisColumns[];
  firstRenderDisplayColumnIdSet: Set<string>;
  tableId: string;
}) => {
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any, index: number): ColDef => {
      return {
        field: columnObj['id'],
        hide: columnObj['id'] === 'threatType',
        headerName: columnObj['header'],
        cellRenderer: getColumnRendereTargetedMalicious(columnObj),
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          }
          return params.value;
        },
        suppressColumnsToolPanel:
          columnObj['id'] === 'threatType' || columnObj['id'] === 'sourceUrl',
        autoHeight: true,
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj),
        floatingFilter: true,
        sortable: !columnObj['sortDisabled'],
        rowGroup: columnObj['id'] === 'threatType',
        lockPosition: columnObj['id'] === 'threatType' || columnObj['id'] === 'sourceUrl',
        checkboxSelection: columnObj['id'] === 'sourceUrl',
      };
    });

  return res;
};
