import { useState, useEffect, useMemo } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import type { CountryCode } from 'libphonenumber-js/types';

interface UseAddPhoneNumberAssetsProps {
  isPhoneNumber: boolean;
  updateFormValues: (field: string, value: any) => void;
}

export const useAddPhoneNumberAssets = ({
  isPhoneNumber,
  updateFormValues,
}: UseAddPhoneNumberAssetsProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [country, setCountry] = useState<CountryCode>('US');
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isPhoneNumber) {
      updateFormValues('country', 'US');
    }
  }, [isPhoneNumber, updateFormValues]);

  const isPhoneNumberPossible = useMemo(() => {
    return isPhoneNumber && phoneNumber ? isPossiblePhoneNumber(phoneNumber || '', country) : true;
  }, [phoneNumber, isPhoneNumber, country]);

  useEffect(() => {
    setError(isPhoneNumberPossible ? undefined : 'Invalid phone number');
  }, [isPhoneNumberPossible]);

  const handlePhoneNumberChange = (value: string | undefined) => {
    updateFormValues('phoneNumber', value);
    setPhoneNumber(value);
  };

  const handleCountryChange = (value: CountryCode | undefined) => {
    if (value) {
      setCountry(value);
      updateFormValues('country', value);
    }
  };

  return {
    phoneNumber,
    country,
    error,
    isPhoneNumberPossible,
    handlePhoneNumberChange,
    handleCountryChange,
  };
};
