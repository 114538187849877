import { WebScanSourceFilterOptions } from '../Common/Table/constant';

export enum globalTakeDownActionDescription {
  AUTO = 'Initiated Auto Takedown',
  USER = 'Takedown Initiated By User',
  FIRST_NOTICE = 'Takedown Notice Sent',
  FOLLOW_UP = 'Follow-up Sent',
  REPLIED = 'Replied',
}

export enum globalTakeDownAccountActions {
  LOA = 'LOA Request Sent to',
  EMAIL_HEADER = 'Email Header Request Sent to',
  ADDITIONAL_INFORMATION = 'Additional Information Request Sent to',
}

export const globalTakeDownStatus = {
  IN_PROGRESS: {
    label: 'In Progress',
    value: 'in_progress',
  },
  TAKEN_DOWN: {
    label: 'Takendown',
    value: 'taken_down',
  },
  EXHAUSTED: {
    label: 'Exhausted',
    value: 'exhausted',
  },
  AWAITING_DOCUMENTS: {
    label: 'Awaiting Documents',
    value: 'awaiting_documents',
  },
  CANCELLED: {
    label: 'Cancelled',
    value: 'cancelled',
  },
};

export const TakedownSubmittedByOptions = [
  { label: 'Bolster Auto Takedown', value: 'auto' },
  { label: 'Bolster SOC', value: 'soc' },
];

export const ScanSourceOptions = [
  { label: 'Bolster', value: WebScanSourceFilterOptions.BOLSTER },
  { label: 'Not Bolster', value: WebScanSourceFilterOptions.NOT_BOLSTER },
];

export const latestGtdActions = [
  { label: 'Hosting Provider', value: 'hosting_provider' },
  { label: 'Registrar', value: 'registrar' },
  { label: 'Registrant', value: 'registrant' },
  { label: 'Registry', value: 'registry' },
  { label: 'Other', value: 'other' },
  { label: 'LOA', value: 'loa' },
  { label: 'Email Header', value: 'email_header' },
  { label: 'Additional Information', value: 'additional_information' },
  { label: 'Auto', value: 'auto' },
  { label: 'User', value: 'user' },
];

export const getMappedValues = (items: { label: string; value: string }[]) => {
  return Object.fromEntries(items.map(item => [item.value, item.label]));
};
