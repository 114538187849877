import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  appConstants,
  DashBoardConfigDto,
  DashBoardDto,
  featureIsAvailable,
  getUserInitial,
  isInternalUser,
  setLocalStorageValue,
  SIDEBAR_ICONS,
} from '../../constants';
import ContactUsV2 from '../ContactUs/ContactUsV2';
import { CompanyIcon } from '../Common/CompanyIcon';
import { INavItem, INavSubItem } from './AppWrapper';
import _ from 'lodash';
import TyposquatFullScan from '../../assets/icons/TyposquatFullScan.svg';
import DotGreen from '../../assets/icons/DotGreen.svg';
import DotRed from '../../assets/icons/DotRed.svg';
import './SideBar_v2.scss';
import './TopNavigationBar.scss';
import { history } from '../../helpers';
import AccountDrawer from './AccountDrawer';
import {
  ACCOUNT_SUB_ITEMS,
  CHECKPHISH_DRAWER_SUBITEM,
  DRAWER_SUB_ITEMS,
  EMenuType,
  MOBILE_L1_DRAWER,
} from './RouteOptions';
import { HamburgerIcon, LockIcon, ChevronDownIcon } from '../../assets/SVGIcons';
import FlyoutMenus from './FlyoutMenus';
import AppSettingContext from '../../context/AppSettingContext';
import {
  TYPOSQUATTING_DOMAIN_MONITORING,
  TYPOSQUATTING_DOMAIN_MONITORING_MALICIOUS,
  TYPOSQUATTING_DOMAIN_MONITORING_PREMALICIOUS,
  TYPOSQUATTING_DOMAIN_MONITORING_TYPOSQUAT,
  TYPOSQUAT_DASHBOARD,
  TypoJobStatus,
} from '../DomainMonitoring/constant';
import { CHECKPHISH_TOP_NAV_ITEMS } from './RouteOptions';
import { HubspotContactForm } from '../ContactUs/HubspotContactForm';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import ThemeContext from '../../context/ThemeContext';
import ActiveAppContext from '../../context/CheckphishActiveAppContext';
import LockIconLight from '../../assets/icons/LockGray.svg';
import LockIconDark from '../../assets/icons/LockGrayDark.svg';
import { appOptions } from './CheckPhishV2/Constants';
import { Col, Dropdown, DropdownButton, Button, Modal } from 'react-bootstrap';
import { TyposquattingDomain } from '../../types/typosquat-dashboard.interface';
import { hubspotFormId, PRICING_PAGE_PATH, SCROLL_TO_COMPARISION } from '../Pricing/constants';
import UserFeedbackPopup from '../UserFeedback/UserFeedbackPopUp';
import ActiveRoutesContext from '../../context/ActiveRoutesContext';
import { GetIconImage } from '../Common/GetIconImage';
import UpgradePopup from '../Pricing/UpgradePopup';
import { HUBSPOT_FORM_TYPE } from '../Pricing/types';
import OrgSwitcherContainer from '../Common/OrgSwitcherDropdown/OrgSwitcherContainer';
import { getLastVisitedPage } from '../../helpers/url-helpers';
import GlobalSearchContainer from '../GlobalSearch/GlobalSearchContainer';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

interface ISideBarWebProps {
  user: DashBoardDto;
  items: INavItem[];
  onDashboardSelected: (item: INavSubItem) => void;
  selectedDashboard: DashBoardConfigDto;
  onSelectedPage: (item: any, header: string) => void;
  navHeader: string;
  isCommunity: boolean;
}
export default function TopNavigationBar({
  items,
  user,
  onSelectedPage,
  navHeader,
  isCommunity,
}: ISideBarWebProps) {
  const appSetting = useContext(AppSettingContext);
  const checkphishContactUsContext = useContext(CheckPhishContactUsContext);
  const { selectedApp } = useContext(ActiveAppContext);
  const setTopNavItems = () => {
    return !isCommunity ? items : CHECKPHISH_TOP_NAV_ITEMS(selectedApp);
  };
  const [navItems, setNavItems] = useState<any>(setTopNavItems());
  const {
    contactUsMessage,
    setContactUsMesage,
    setShowContactUsPage,
    showContactUsPage,
    setTriggerContactUsModal,
    showContactUsV2,
    triggerContactUsModal,
    setExploreDemoPersistance,
    showExploreDemoPage,
    setShowExploreDemoPage,
    domainList,
    paidUser,
    setSelectedDomain,
    selectedDomain,
    triggerMultiTyposquatDomainContactUsModal,
    setTriggerMultiTyposquatDomainContactUsModal,
    triggerSubText,
    setTriggerSubtext,
    tiggerPopUpButtonData,
    setTriggerPopUpButtonData,
    triggerBackgroundImg,
    setTriggerBackgroundImg,
    setIsSmallPopup,
    isSmallPopup,
    hubspotFormType,
    setHubspotFormType,
  } = checkphishContactUsContext;
  const userAvatar = getUserInitial(user);
  const [selected, setSelected] = useState(navHeader);
  const [activeFlyoutItemId, setActiveFlyoutItemId] = useState('');
  const [accountOpen, setAccountOpen] = useState(false);
  const [isDarkMode, selectedTheme] = useIsDarkMode();
  const theme = useContext(ThemeContext);
  const isDemo = useMemo(
    () => window.location.pathname.includes('premium'),
    [window.location.href],
  );

  const triggerPaywallPopUp = (): void => {
    setIsSmallPopup(false);
    setHubspotFormType(HUBSPOT_FORM_TYPE.TAKEDOWN);
    //set uptext
    setTriggerSubtext(
      <span>
        Upgrade your plan to unlock comprehensive monitoring capabilities across multiple domains
        and their potential typosquats.
      </span>,
    );
    setTriggerBackgroundImg(TyposquatFullScan);
    setTriggerPopUpButtonData([
      {
        text: 'Compare All Plans',
        onClick: () => {
          setTriggerMultiTyposquatDomainContactUsModal(false);
          setLocalStorageValue(SCROLL_TO_COMPARISION, true);
          history.push(PRICING_PAGE_PATH);
        },
        variant: 'outline-secondary',
        className: 'compare-all-plans',
      },
      {
        text: 'Upgrade My Plan',
        onClick: () => {
          setTriggerMultiTyposquatDomainContactUsModal(false);
          history.push(PRICING_PAGE_PATH);
        },
        variant: 'primary',
      },
    ]);
    setTriggerMultiTyposquatDomainContactUsModal(true);
  };

  useEffect(() => {
    setExploreDemoPersistance(true);
    setShowExploreDemoPage(false);
    return () => {
      setExploreDemoPersistance(false);
      setShowExploreDemoPage(false);
    };
  }, []);

  useEffect(() => {
    setNavItems(setTopNavItems());
    if (selectedApp.value === appOptions[1].value) {
      setShowContactUsPage(isDemo);
      setContactUsMesage(
        'You are current in the Premium Plan’s Demo Workspace, explore around with demo data.',
      );
    }
  }, [items, selectedApp]);

  const toggleFlyoutMenus = useCallback(
    (item?: INavItem) => {
      if (item && activeFlyoutItemId === '') {
        setActiveFlyoutItemId(item.id);
      } else {
        setActiveFlyoutItemId('');
      }
    },
    [activeFlyoutItemId, user],
  );

  const activeFlyoutItem = _.find(navItems, ['id', activeFlyoutItemId]);

  let flyoutMenus: INavSubItem[] = [];
  if (activeFlyoutItemId) {
    flyoutMenus = activeFlyoutItem!.subItems!;
  }

  const showNotificationDot = () => {
    if (!isCommunity) {
      return false;
    }
    const { isPrimaryEmailWorkEmail, workEmail, workEmailVerified } = user;
    if (isPrimaryEmailWorkEmail) {
      return false;
    }
    if (workEmail && workEmailVerified) {
      return false;
    }
    return true;
  };

  const showContactUsBanner = () => {
    if (showContactUsPage) {
      if (selectedApp.value === appOptions[1].value) {
        return (
          <div className={'checkphish-ribbon blue-wrap'}>
            <div className='ribbon-wrap'>
              <p className={'checkphish-ribbon-info'}>{contactUsMessage}</p>
              <Button
                variant=''
                className={'checkphish-ribbon-button'}
                onClick={() => history.push(PRICING_PAGE_PATH)}
              >
                Upgrade
              </Button>
              <span>or</span>
              <Button
                variant=''
                className={'checkphish-ribbon-button'}
                onClick={() => history.push('/domain-monitoring/dashboard')}
              >
                Exit
              </Button>
            </div>
            <button className='close-btn ml-1' onClick={() => setShowContactUsPage(false)}>
              &times;
            </button>
          </div>
        );
      }
      return (
        <div className={'checkphish-ribbon'}>
          <div className='ribbon-wrap'>
            <p className={'checkphish-ribbon-info'}>{contactUsMessage}</p>
            <Button
              variant=''
              className={'checkphish-ribbon-button'}
              onClick={() => history.push(PRICING_PAGE_PATH)}
            >
              Contact Us
            </Button>
          </div>
          <button className='close-btn ml-1' onClick={() => setShowContactUsPage(false)}>
            &times;
          </button>
        </div>
      );
    }
    return null;
  };

  const renderDropdownItem = ({ value, status }: TyposquattingDomain) => {
    return (
      <Col className='d-flex no-padding'>
        <Col md={2}>
          <img
            src={
              status === TypoJobStatus.DONE || status === TypoJobStatus.MONITORED_DONE
                ? DotGreen
                : DotRed
            }
          />
        </Col>
        <Col md={10}>{value}</Col>
      </Col>
    );
  };

  const buildDropdownForTyposquat = () => {
    if (!isCommunity) {
      return null;
    }
    if (domainList.length === 0) {
      return null;
    }
    const activeUrl = window.location.pathname.split('/')[1];
    if (activeUrl !== 'domain-monitoring') {
      return null;
    }
    return (
      <div className='dropdown-component box-style'>
        <DropdownButton
          id='dropdown-basic-button'
          title={renderDropdownItem(selectedDomain)}
          className='domain-dropdown bg--transparent'
        >
          {domainList.map(d => (
            <Dropdown.Item
              onClick={e => {
                e.stopPropagation();
                setSelectedDomain(d);
                if (d.status !== TypoJobStatus.DONE) {
                  history.push('/domain-monitoring/typosquat');
                }
              }}
              key={d.value}
            >
              {renderDropdownItem(d)}
            </Dropdown.Item>
          ))}
          {!paidUser && (
            <Dropdown.Item onClick={triggerPaywallPopUp}>
              <Col md={12} className='no-padding add-domain-text'>
                Add domain(s)
              </Col>
            </Dropdown.Item>
          )}
        </DropdownButton>
      </div>
    );
  };

  return (
    <>
      {showContactUsBanner()}
      <UserFeedbackPopup />
      <div className={'top-navigation-bar-container'}>
        <div className={'top-navigation-wrapper'}>
          {flyoutMenus.length ? (
            <FlyoutMenus items={flyoutMenus} closeFlyoutMenus={toggleFlyoutMenus} />
          ) : null}
          <div
            className={'hamburger-icon-wrapper'}
            onClick={() => {
              onSelectedPage(MOBILE_L1_DRAWER(user, isCommunity)['Main'], 'Main');
            }}
          >
            <div className={'hamburger-icon'}>
              <HamburgerIcon color='black' />
            </div>
          </div>
          <div className={'left-navigation-wrapper'}>
            <div className={'company-logo-container'}>
              <div
                className={'company-logo-wrapper'}
                onClick={() =>
                  selectedApp.value === appOptions[1].value
                    ? null
                    : history.push(appSetting.landingPage)
                }
              >
                <CompanyIcon
                  theme={selectedTheme}
                  isDemo={selectedApp.value === appOptions[1].value}
                />
                {!isCommunity && <div className={'company-title-container'}>Bolster</div>}
              </div>
            </div>
            <div className='nav-header-container'>
              {navItems.map((item: INavItem, index: number) => {
                return (
                  <div
                    className={
                      'navitem-wrapper' + (activeFlyoutItemId === item.id ? ' active' : '')
                    }
                    key={index}
                  >
                    <NavItems
                      user={user}
                      navItem={item}
                      setSelected={setSelected}
                      onSelectedPage={onSelectedPage}
                      displaySubItems={toggleFlyoutMenus}
                      isCommunity={isCommunity}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {buildDropdownForTyposquat()}

          {!isCommunity && featureIsAvailable(user, appConstants.FEATURE_CODE.GLOBAL_SEARCH) && (
            <GlobalSearchContainer />
          )}
          {user.mspDetails && <OrgSwitcherContainer />}
          <div className={'right-navigation-wrapper'}>
            {!_.isEmpty(userAvatar) && (
              <div
                className={`profile-wrapper ${selected}`}
                onMouseEnter={() => {
                  setAccountOpen(true);
                }}
                onMouseLeave={() => {
                  setAccountOpen(false);
                }}
              >
                <div className={'user-profile-avatar'}>{userAvatar}</div>
                {showNotificationDot() && <NotificationDot />}
                <div className={'chevron-down-wrapper'}>
                  <ExpandMoreRoundedIcon />
                </div>
                {accountOpen && (
                  <AccountDrawer
                    user={user}
                    navItems={ACCOUNT_SUB_ITEMS(user)}
                    setSelected={setSelected}
                    onSelectedPage={onSelectedPage}
                    showNotificationDot={showNotificationDot()}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className={
          showContactUsV2 || showExploreDemoPage ? 'max-width-content modal-wrap' : 'modal-wrap'
        }
        size='lg'
        show={triggerContactUsModal}
        onHide={() => setTriggerContactUsModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {showExploreDemoPage ? (
            <div className='explore-demo-wrap'>
              <h3 className='main-head'>Explore Premium Features with Our Interactive Demo!</h3>
              <p className='subhead'>
                Want a fully customized demo with your brand? Talk to one of our digital risk
                protection experts!
              </p>
              <Button
                variant='primary'
                className={'contact-us-button'}
                onClick={() => setShowExploreDemoPage(false)}
              >
                Contact Us
              </Button>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: 'calc(56.25% + 41px)',
                  height: '0px',
                  width: '100%',
                }}
                className='iframe-container-bolster-demo'
              >
                <iframe
                  src='https://demo.arcade.software/cuKnNMEBAgm64pEtUWGg?embed&show_copy_link=true '
                  title='Try Bolster Platform Demo'
                  frameBorder={0}
                  loading='lazy'
                  allowFullScreen
                  allow='clipboard-write'
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    colorScheme: 'light',
                  }}
                ></iframe>
              </div>
            </div>
          ) : (
            <>
              {showContactUsV2 ? (
                <ContactUsV2 />
              ) : (
                <div className='hb-contact-form'>
                  <HubspotContactForm
                    region='na1'
                    portalId='24174425'
                    formId={hubspotFormId[hubspotFormType]}
                    prefillData={true}
                    user={user}
                    darkTheme={isDarkMode}
                  />
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <UpgradePopup
        showPopup={triggerMultiTyposquatDomainContactUsModal}
        onHide={() => setTriggerMultiTyposquatDomainContactUsModal(false)}
        subText={triggerSubText}
        backgroundImg={triggerBackgroundImg}
        buttons={tiggerPopUpButtonData}
        isSmallPopup={isSmallPopup}
      />
    </>
  );
}

export const navItemIsActive = (navItem: INavItem, isCommunity: boolean = false): boolean => {
  let isActive = false;
  const activeUrl = window.location.pathname.split('/')[1];
  const isIntegrationPage = window.location.pathname.includes('integrations');
  if (isCommunity && navItem.label === 'Dashboard' && !isIntegrationPage) return true; //TODO CORRECT THIS LOGIC
  const { href, subItems } = navItem;
  if (activeUrl.includes('bulk-scan') && href === '/checkphish/dashboard') {
    return true;
  }
  if (href?.split('/').includes(activeUrl)) {
    isActive = true;
  } else if (subItems?.length) {
    for (let i = 0; i < subItems?.length; i++) {
      if (subItems[i].href?.split('/').includes(activeUrl)) {
        isActive = true;
        break;
      }
    }
  }
  return isActive;
};

export const NavItems = ({
  navItem,
  subItemClassName = '',
  displaySubItems,
  setSelected,
  onSelectedPage,
  onAfterClick,
  user,
  prevUrl = '',
  isCommunity = false,
  showNotificationDot = false,
  iconForSideBar = false,
}: any) => {
  const appSetting = useContext(AppSettingContext);
  const [isDarkMode] = useIsDarkMode();
  const { landingPage } = appSetting;
  const {
    id,
    subItems,
    href,
    label,
    hidden,
    parentLabel,
    featureCode = [],
    disabled = false,
    type,
    isNew = false,
    isExternalLink = false,
    isLocked = false,
  } = navItem;

  const { activeRoute, getParentRouteViaFindingStatus } = useContext(ActiveRoutesContext);
  const featureAvailable = featureIsAvailable(user, featureCode);
  const activeEndPoint = window.location.pathname.split('/').pop();
  const isInsightsUrl = window.location.pathname.includes('insights');
  const isDomainMonitoringInsights = window.location.pathname.includes(
    '/domain-monitoring/insights/',
  );
  let subItemIsActive = false;
  const parentIsActive = navItemIsActive(navItem, isCommunity);
  const lastVisitedPage = getLastVisitedPage();
  if (isDomainMonitoringInsights) {
    subItemIsActive = label === 'Typosquat';
  } else if (isInsightsUrl && prevUrl?.includes('allscans')) {
    label === 'All Scans' ? (subItemIsActive = true) : (subItemIsActive = false);
  } else if (isInsightsUrl && prevUrl?.includes(TYPOSQUATTING_DOMAIN_MONITORING)) {
    if (label === 'Dashboard' && prevUrl?.includes(TYPOSQUAT_DASHBOARD)) {
      subItemIsActive = true;
    } else if (
      label === 'Pre-Malicious' &&
      prevUrl?.includes(TYPOSQUATTING_DOMAIN_MONITORING_PREMALICIOUS)
    ) {
      subItemIsActive = true;
    } else if (
      label === 'Malicious' &&
      prevUrl?.includes(TYPOSQUATTING_DOMAIN_MONITORING_MALICIOUS)
    ) {
      subItemIsActive = true;
    } else if (
      label === 'Typosquat' &&
      prevUrl?.includes(TYPOSQUATTING_DOMAIN_MONITORING_TYPOSQUAT)
    ) {
      subItemIsActive = true;
    }
  } else if (href && href.split('/').pop() === activeEndPoint) {
    subItemIsActive = true;
  }

  // auto select parent side bar if we are in insights page
  // ActiveRoute is drived from the tableStatu property

  if (!!activeRoute && id.includes(activeRoute) && isInsightsUrl && parentIsActive) {
    subItemIsActive = true;
  }

  if (id.includes('abuse-mailbox') && isInsightsUrl && parentIsActive) {
    //NOTE - cannot use active route logic for abuse mailbox insights as diffent tableStatus is returned for different insights
    //also some insights does not have tableStatus (example - phone number).
    subItemIsActive = lastVisitedPage === id;
  }

  let navItemClassName = '';
  if (subItemClassName) {
    subItemClassName = `${subItemClassName} ${subItemIsActive ? ' selected' : ''}`;
  }
  navItemClassName = `first-level-nav-item ${parentIsActive ? ' selected' : ''} `;
  if (hidden) {
    return null;
  }
  if (
    type === EMenuType.SUB_MENU_HEADER &&
    !featureAvailable &&
    label.toLowerCase() !== 'dashboard'
  ) {
    return (
      <div key={id} className={'disabled-label'}>
        {label}
      </div>
    );
  }

  const isFlyoutNavItem = subItems && subItems.length > 0;
  const userDoesNotHasTyposquatData = !user.monitoredDomainPresent;
  const onClick = isFlyoutNavItem
    ? () => {
        displaySubItems(navItem);
        onAfterClick && onAfterClick();
        getParentRouteViaFindingStatus('');
      }
    : () => {
        getParentRouteViaFindingStatus('');
        setSelected && setSelected(parentLabel);
        if (typeof onSelectedPage === 'function') {
          if (isCommunity) {
            onSelectedPage(CHECKPHISH_DRAWER_SUBITEM(userDoesNotHasTyposquatData), parentLabel);
          } else {
            onSelectedPage(DRAWER_SUB_ITEMS(user, isCommunity)[parentLabel], parentLabel);
          }
        }
        history.push(disabled ? '' : href || window.location.pathname);
        onAfterClick && onAfterClick();
      };

  const setLockIcon = () => {
    return !isDarkMode ? LockIconLight : LockIconDark;
  };
  return (
    <div
      key={id}
      className={
        'd-flex align-items-center justify-items-center ' +
        (subItemClassName || navItemClassName) +
        (isFlyoutNavItem ? ' flyout-nav-item' : '') +
        (isLocked ? 'locked-menu disabled' : '') +
        (isCommunity ? ' checkphish-top-nav' : '')
      }
      onClick={onClick}
    >
      {type === EMenuType.MENU_HEADER && !featureAvailable ? (
        <div className={'lock-icon-wrapper'}>{<LockIcon color='black' />}</div>
      ) : null}
      {isCommunity ? (
        <div className={'text-wrap-top-nav'}>
          {isNew && <span className='is-new-banner'>New</span>}
          {isExternalLink ? (
            <a href={href} className='external-link-tab' target='_blank' rel='nofollow noreferrer'>
              {isCommunity && isLocked && (
                <img src={setLockIcon()} alt='lock-icon' className='lock-icon' />
              )}
              {label}
            </a>
          ) : (
            <span>
              {isCommunity && isLocked && (
                <img src={setLockIcon()} alt='lock-icon' className='lock-icon' />
              )}
              {label}
            </span>
          )}
        </div>
      ) : (
        <>
          {iconForSideBar ? (
            <div className='side-bar--container'>
              {getSideBarIcons(navItem.href, subItemIsActive)}
              <span
                style={{
                  marginInlineStart: '16px',
                }}
                className='sidebar-label'
              >
                {label} {showNotificationDot && <NotificationDot />}
              </span>
            </div>
          ) : (
            <>
              {label} {showNotificationDot && <NotificationDot />}
            </>
          )}
        </>
      )}
      {isFlyoutNavItem ? <ChevronDownIcon className='chevron-icon black flyout-nav-item' /> : null}
    </div>
  );
};

export const NotificationDot = () => {
  return (
    <div className={'notification-wrapper'}>
      <img src={DotRed} alt='notification-dot' />
    </div>
  );
};

function getSideBarIcons(href: string, active: boolean = false): React.JSX.Element {
  const imageObj = SIDEBAR_ICONS[href];
  return <GetIconImage {...imageObj} isActive={active} />;
}
