import React, { useState } from 'react';
import { MaliciousReportDistributionByDeliveryMethod } from './MaliciousReportDistributionByDeliveryMethod';
import { LinksBreakdown } from './LinksBreakdown';
import { DashboardData } from '../../constants';
import { useAppSelector } from '../../../../helpers/hooks';

export function TargetedMaliciousEmailAnalytics({
  dashboardData,
}: {
  dashboardData: DashboardData;
}) {
  const threatDistribution = Object.entries(dashboardData.threatDistribution).map(
    ([category, { email, sms }]) => ({
      email,
      sms,
      category,
    }),
  );
  const maxReportDistribution = Math.max(...threatDistribution.map(item => item.email + item.sms));

  const [selectedLinksBreakdown, setSelectedLinksBreakdown] = useState<
    'maliciousUrl' | 'maliciousPhoneNumber'
  >('maliciousUrl');

  const linksBreakdown = Object.entries(
    dashboardData.threatSourcesBreakdown?.[selectedLinksBreakdown],
  ).map(([category, value]) => ({
    category,
    value,
  }));
  const maxLinksBreakdown = Math.max(...linksBreakdown.map(item => item.value));
  const breakDowncolors = ['#2368BC', '#0A89FF', '#66BFFF', '#99DAFF', '#35C759', '#AAE2A1'];
  const { brandInfo } = useAppSelector(state => state.dashboardReducer);

  const linksBreakdownLabelMap = {
    maliciousUrl: 'Malicious URL',
    maliciousPhoneNumber: 'Malicious Phone Number',
  };

  const deliveryMethodColors = ['#0D3D78', '#2368BC', '#0A89FF', '#66BFFF', '#99DAFF', '#C9EBFF'];

  return (
    <div className='targeted-malicious-email-analytics'>
      <div className='abuse-mailbox-dashboard-section-titles'>
        {`${brandInfo?.brand.displayName} Targeted Malicious Emails Analytics`}
      </div>
      <div className='targeted-malicious-email-analytics-section-container'>
        <div className='targeted-malicious-email-analytics-section'>
          <div className='section-header'>
            <div className='shared-titles-for-cards'>
              Malicious Report Distribution Across Email and SMS
            </div>
            {/* TODO: when SMS data is available */}
            {/* <div className='legend'>
              <div className='legend-item'>
                <div className='legend-color email-color'></div>
                <span>Email</span>
              </div>
              <div className='legend-item'>
                <div className='legend-color sms-color'></div>
                <span>SMS Messages</span>
              </div>
            </div> */}
          </div>
          {threatDistribution.map((item, index) => (
            <MaliciousReportDistributionByDeliveryMethod
              key={index}
              {...item}
              max={maxReportDistribution}
              color={deliveryMethodColors[index]}
            />
          ))}
        </div>
        <div className='targeted-malicious-email-analytics-section'>
          <div className=' section-header-breakdown'>
            <div className='shared-titles-for-cards'>
              Breakdown of Where Malicious Links and Phone Numbers Were Found in Emails
            </div>
            <div className='phish-scam-header-buttons'>
              {(['maliciousUrl', 'maliciousPhoneNumber'] as const).map((label, index) => (
                <button
                  key={index}
                  className={`phish-scam-buttons ${
                    selectedLinksBreakdown === label ? 'active' : ''
                  }`}
                  onClick={() => setSelectedLinksBreakdown(label)}
                >
                  {linksBreakdownLabelMap[label]}
                </button>
              ))}
            </div>
          </div>

          {linksBreakdown.map((item, index) => (
            <LinksBreakdown
              key={index}
              {...item}
              max={maxLinksBreakdown}
              color={breakDowncolors[index]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
