import React, { useState, useRef, useCallback, useEffect } from 'react';
import _ from 'lodash';
import './UploadFiles.scss';
import { AttachedFileObject } from './DisputeStatus';
import { convertToAttachedFileObjects } from './AttachFile';
import AttachedFileViewer from './AttachedFileViewer';
import { getSingleOrPluralForm } from '../../constants';
import UploadFilesButton from './UploadFilesButton';
import {
  EFileSizeInMB,
  EFileTypes,
  FILES_LIMIT,
  FileTypeDescription,
  UploadFileErrors,
} from '../../types/upload-files';

interface UploadFilesProps {
  onChangeHandler: (files: File[], controlId: string) => void;
  dragAndDrop?: boolean;
  label?: string;
  controlId?: string;
  filesLimit?: number;
  initialFilePaths?: string[];
  fileType?: string;
  customHelpText?: string;
  hideError?: boolean;
  onError?: (errorType: UploadFileErrors, message: string) => void;
  customSubHeadingText?: string;
  accept?: any;
  onFileRemove?: any;
  fileDropped?: boolean; //clear selected file if this is true
  disabled?: boolean;
  onFileDropped?: any;
}

export function UploadFiles({
  onChangeHandler,
  dragAndDrop,
  label,
  controlId = '',
  filesLimit = FILES_LIMIT,
  initialFilePaths = [],
  fileType = 'IMAGE',
  customHelpText,
  customSubHeadingText,
  hideError,
  onError,
  accept = null,
  onFileRemove = null,
  fileDropped = false,
  disabled = false,
  onFileDropped = null,
}: UploadFilesProps) {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [fileIsOnInDropArea, setFileIsOnInDropArea] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState<AttachedFileObject[]>([]);
  const [error, setError] = useState<string | null>();
  const files = useRef<File[]>([]);

  const maxFileSizeInBytes = Number(EFileSizeInMB[fileType]) * 1000000;

  useEffect(() => {
    const validPaths = _.filter(initialFilePaths, f => !_.isEmpty(f));
    if (validPaths.length) {
      setAttachedFiles(convertToAttachedFileObjects(validPaths));
    }
  }, [initialFilePaths]);

  const openFileSelect = () => {
    uploadInputRef.current!.click();
  };

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    setFileIsOnInDropArea(true);
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    setFileIsOnInDropArea(false);
  };

  const handleDragOver = (event: any) => {
    // preventing the browser from downloading the files.
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    onFilesAdded(event);
  };

  const handleError = (errorType: UploadFileErrors): void => {
    let message: string | null = null;
    switch (errorType) {
      case UploadFileErrors.MAX_COUNT:
        message = `No more than ${filesLimit} files.`;
        break;
      case UploadFileErrors.MAX_FILE_SIZE:
        message = `Files size should not exceeds ${EFileSizeInMB[fileType]} MB`;
        break;
    }

    setError(message);
    onError?.(errorType, message);
    setFileIsOnInDropArea(false);
  };

  const onFilesAdded = useCallback(
    (event: any) => {
      const selectedFiles = event.target.files || event.dataTransfer.files;
      if (files.current.length + selectedFiles.length > filesLimit) {
        handleError(UploadFileErrors.MAX_COUNT);
        return;
      }
      let currentFilesSize = 0;
      [...files.current, ...selectedFiles].forEach(file => {
        currentFilesSize += file.size;
      });
      if (currentFilesSize > maxFileSizeInBytes) {
        handleError(UploadFileErrors.MAX_FILE_SIZE);
        return;
      }
      for (let i = 0; i < selectedFiles.length; i++) {
        files.current.push(selectedFiles[i]);
      }
      setAttachedFiles(convertToAttachedFileObjects(files.current));
      setError(null);
      onChangeHandler(files.current, controlId);
      setFileIsOnInDropArea(false);
    },
    [onChangeHandler],
  );

  const onFileRemoved = useCallback(
    (e): void => {
      files.current.splice(e.currentTarget.dataset.fileIndex, 1);
      setAttachedFiles(convertToAttachedFileObjects(files.current));
      onChangeHandler(files.current, controlId);
      _.isFunction(onFileRemove) && onFileRemove();
    },
    [onChangeHandler],
  );

  useEffect(() => {
    if (!fileDropped) return;
    setAttachedFiles([]);
    files.current = [];
    _.isFunction(onFileDropped) && onFileDropped();
  }, [fileDropped]);

  return (
    <div className={'upload-files-container' + (disabled ? ' disabled' : '')}>
      <input
        type='file'
        name='file'
        id='attach-file'
        accept={accept || EFileTypes[fileType]}
        ref={uploadInputRef}
        onChange={onFilesAdded}
        style={{ display: 'none' }}
        multiple
        data-testid='upload-image'
        disabled={disabled}
      />
      <div>
        {label && <div className={'label'}>{label}</div>}
        <div
          className={
            'd-flex align-items-center ' +
            (dragAndDrop ? 'drag-and-drop-area' : '') +
            (fileIsOnInDropArea ? ' is-dragging' : '')
          }
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <UploadFilesButton onClick={openFileSelect} />
          {dragAndDrop && <span className='drop-files-text'>or Drop Files</span>}
        </div>
        <div className={'mt-2 text-dark-grey-2 help-text'}>
          {customSubHeadingText != null && (
            <h6 className='custom-sub-head'>{customSubHeadingText}</h6>
          )}
          {customHelpText != null && <div>{customHelpText}</div>}

          {customHelpText == null && (
            <div>
              Maximum {filesLimit} {getSingleOrPluralForm(filesLimit, 'file')}.{' '}
              {FileTypeDescription[fileType]}
            </div>
          )}
        </div>

        {!hideError && error && <div className={'mt-2 text-danger'}>{error}</div>}
        {attachedFiles?.length > 0 && (
          <AttachedFileViewer
            attachedFiles={attachedFiles}
            horizontal
            theme={'sm'}
            handleFileRemove={onFileRemoved}
          />
        )}
      </div>
    </div>
  );
}

export default UploadFiles;
