import { SelectionChangedEvent } from 'ag-grid-community';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import {
  setCurrentGroupedSelectedFindings,
  setCurrentSingleSelectedFindings,
} from '../../../../reducers/darkWeb.reducer';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { getSearchTermFindings } from './ag-requests';
import { DarkWebTableApiV2Params } from './ag-utils';
import { AgGridColumnType } from './interfaces';

export interface IUseOnDarkwebFindingSelectionProps {
  darkwebStatus: EDarkWebFindingStatus;
  extraFilters: any;
  widgetDetails: any;
}
export default function useOnDarkwebFindingSelection({
  darkwebStatus,
  extraFilters,
  widgetDetails,
}: IUseOnDarkwebFindingSelectionProps) {
  const dispatch = useAppDispatch();
  const currentGroupedSelectedFindings = useAppSelector(
    state => state.darkWebReducer.currentGroupedSelectedFindings,
  );
  const currentSingleSelectedFindings = useAppSelector(
    state => state.darkWebReducer.currentSingleSelectedFindings,
  );
  const onSelectionChanged = useCallback(
    async (event: SelectionChangedEvent<AgGridColumnType, any>) => {
      // get selected nodes
      const selectedNodes: any = event.api.getSelectedNodes();
      const groupedRows = selectedNodes.filter((row: any) => row.group);
      const selectedRows = selectedNodes.filter((row: any) => !row.group);
      if (!groupedRows.length && !selectedRows.length) {
        dispatch(setCurrentGroupedSelectedFindings([]));
        dispatch(setCurrentSingleSelectedFindings([]));
        return;
      }
      if (groupedRows.length > 0) {
        const promise = groupedRows.map(async (node: any) => {
          const { domain, related_findings_count } = node.data;
          let searchTermId: string[] = [];
          Object.keys(widgetDetails.searchterms).map(k => {
            if (k === domain) {
              searchTermId = widgetDetails.searchterms[domain].searchTermId;
            }
          });

          const response = await getSearchTermFindings(
            darkwebStatus as EDarkWebFindingStatus,
            { ...extraFilters, searchTermIds: [searchTermId] } as DarkWebTableApiV2Params,
            domain,
            related_findings_count,
          );
          const urlShaSetExist: Set<string> = new Set();

          response.findings.forEach((m: { sha256: string }) => {
            if (m.sha256 !== undefined) {
              urlShaSetExist.add(m.sha256);
            }
          });
          return Array.from(urlShaSetExist);
        });

        const result = await Promise.all(promise);
        const allSelectedShas = result.flat();
        dispatch(setCurrentGroupedSelectedFindings([...allSelectedShas]));
      }

      if (selectedRows.length > 0) {
        const selectedRowsShas: string[] = [];
        selectedRows.map((m: any) => {
          if (m.data.sha256 !== undefined) {
            selectedRowsShas.push(m.data.sha256);
          }
        });
        dispatch(setCurrentSingleSelectedFindings([...selectedRowsShas]));
      }
    },
    [currentGroupedSelectedFindings, currentSingleSelectedFindings, dispatch],
  );
  return {
    onSelectionChanged,
  };
}
