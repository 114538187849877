import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { AgGridColumnType } from './interfaces';
import { LicenseManager } from 'ag-grid-enterprise';
import { TableContext } from '../table.context';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { ITableApiColumn } from '../table.api';
import { REPORTS_TABLE_ID } from '../../../Reports/ReportsConstant';
import { useAgGridEvents } from './useAgGridEvents';
import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import { convertToFetchApiReportsParams, getAgGridThemeClassName } from './ag-utils';
import { getReports } from '../../../Reports/report-requests';
import { massageReportsData } from '../../../Reports/reports-utils';
import { alertActions } from '../../../../actions';
import { TABLE_EMPTY_RESULTS_MESSAGE, paginationPageSizeSelector } from './constants';
import { generateReportsColumnDefs } from './ag-col-defs';
import useAgGridFilterChange from './useAgGridFilterChange';
import useOnGridReadyForReports from './useOnGridReadyForReports';
import { getAvailableModules } from '../../../../helpers/get-available-modules';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);
const AgGridReportsTable = ({
  columns,
  tableId,
}: {
  columns: ITableApiColumn[];
  tableId: typeof REPORTS_TABLE_ID;
}) => {
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const agRef = useRef<AgGridReact>(null);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs } = useContext(TableContext);
  const newlyGeneratedReport = useAppSelector(state => state.reportsReducer.newlyGeneratedReport);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const dispatch = useAppDispatch();

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({ tableId });

  const colDefs: ColDef[] = useMemo<ColDef[]>(() => {
    return generateReportsColumnDefs(columns, firstRenderDisplayColumnIdSet, tableId);
    // We have to add displayedColumnsIDs here, to update colDefs with latest visible columns
  }, [columns, displayedColumnsIDs]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };

        try {
          const apiParams = convertToFetchApiReportsParams(agRequest);
          const rawResponse = await getReports(apiParams);
          response.data = massageReportsData(rawResponse.data, newlyGeneratedReport);
          response.total = rawResponse.total;
          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }
          // Need to filter out available modules
          const availableModuleNames = getAvailableModules().map(module => module.name);

          const filteredData = response.data.filter((item: any) =>
            availableModuleNames.includes(item.ugcType?.label),
          );

          setAllRowData(filteredData);
          setTimeout(() => {
            params.success({
              rowData: filteredData,
              rowCount: filteredData.length,
            });
          }, 0);
        } catch (error) {
          console.log('Error in fetching ugc :', error);
          params.fail();
        }
      },
    };
  }, []);

  // use onGridReady for reports
  const { onGridReady } = useOnGridReadyForReports(datasource, tableId);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data.id}-${params.data.report_name}`;
  }, []);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData,
  });

  //use to save the filter model in local storage
  const { handleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  //use to refresh the table when the triggerAgGridRefresh is true
  useOnAgGridRefresh(agRef);

  const { handleFirstDataRendered, handleOnPaginationChanged, currentPaginationSize } =
    usePersistentTablePageNumber(tableId);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={paginationPageSizeSelector}
        cacheBlockSize={currentPaginationSize}
        blockLoadDebounceMillis={1000}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        onFilterChanged={handleFilterChanged}
        suppressRowClickSelection={true}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridReportsTable;
