import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EMenuType } from '../../Drawer/RouteOptions';
import './SubNavigation.scss';
import { history } from '../../../helpers/history';
import _ from 'lodash';
import InsightsPaginatonControls from '../../Common/InsightsPagination/InsightsPaginatonControls';
import { useAppSelector } from '../../../helpers/hooks';
import { getIsFeatureAvailable } from '../../../basic-hooks/useFeatures';
import { appConstants, getLocalStorageValue } from '../../../constants';
import { GLOBAL_TAKEDOWN_TABLE_ID } from '../../GlobalTakedown/GlobalTakedown';
import InsightsPaginationGroupedViewControls from '../../Common/InsightsPagination/InsightsPaginationGroupedViewControls';
import Tab from '@mui/material/Tab';
import { Tabs } from '@mui/material';
interface ISubNavigationTabs {
  isPublic: boolean;
  subBarItems: ISubBarItem[];
  moduleType: string;
  hideEmailAndCount?: boolean;
}

export interface ISubBarItem {
  href: string;
  id: string;
  type: EMenuType;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
}
type Props = ISubNavigationTabs;

export default function SubNavigationBar(props: Props) {
  const { isPublic, subBarItems, moduleType, hideEmailAndCount = false } = props;
  const [value, setValue] = useState(0);

  const user = useAppSelector(state => state.dashboardReducer.user);

  const subTabIsActive = useCallback(() => {
    //Hotfix for bulkscan type
    const activeUrl = window.location.pathname.split('/')[hideEmailAndCount ? 4 : 3];
    const newItems = subBarItems.map((route: ISubBarItem) => {
      const activeRoute = route.href?.split('/')?.[hideEmailAndCount ? 4 : 3];
      if (activeRoute === activeUrl) {
        route.isActive = true;
      } else {
        route.isActive = false;
      }

      return route;
    });

    setTabItems(newItems);
  }, [subBarItems]);

  useEffect(() => {
    subTabIsActive();
    if (
      isPublic &&
      (history.location.pathname.includes('timeline/') ||
        history.location.pathname.includes('dom/') ||
        history.location.pathname.includes('emailDetails/') ||
        history.location.pathname.includes('whois/'))
    ) {
      history.push(`/sign-in`);
    }
  }, [isPublic, subTabIsActive]);

  const [tabItems, setTabItems] = useState<ISubBarItem[]>([]);

  const paginationEnabledModules = [
    appConstants.CONTENT_TYPE.SOCIAL,
    appConstants.CONTENT_TYPE.APP_STORE,
    appConstants.CONTENT_TYPE.BULK_SCAN,
  ];

  const shouldShowPagination = () => {
    const isNotFromTableCol = getLocalStorageValue('isNotFromTableCol');
    if (isNotFromTableCol) return false;

    const currentTableId = getLocalStorageValue('currentTableId');

    if (currentTableId !== GLOBAL_TAKEDOWN_TABLE_ID) {
      paginationEnabledModules.push(appConstants.CONTENT_TYPE.WEB);
    }

    const isFeatureAvailable = getIsFeatureAvailable(appConstants.FEATURE_CODE.INSIGHTS_PAGINATION);
    const isModuleEnabled = paginationEnabledModules.includes(moduleType);

    if (!isFeatureAvailable || !isModuleEnabled) return false;

    return true;
  };

  const isGroupedViewEnabled = useMemo(
    () =>
      !!user?.userAppSetting?.find(
        (setting: any) =>
          setting.setting_name === 'group_findings' && setting.setting_value === 'true',
      ),
    [user],
  );

  const onTabClicked = (item: ISubBarItem) => {
    history.push(item?.href);
    subTabIsActive();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const activeSubTabIndex = subBarItems.findIndex(subNavItem => subNavItem.isActive);
    setValue(activeSubTabIndex);
  }, [subBarItems]);

  return (
    <div className='sub-menu-tab-container '>
      <Tabs className='sub-menu-tab-wrapper' value={value} onChange={handleTabChange}>
        {tabItems.map((item: ISubBarItem, index: number) => {
          return (
            <Tab
              key={index}
              className={`tab-wrapper`}
              label={item.label}
              onClick={() => !item.disabled && !item.isActive && onTabClicked(item)}
              disabled={item.disabled}
              id={item.id}
            />
          );
        })}
      </Tabs>
      {moduleType === appConstants.CONTENT_TYPE.WEB &&
      isGroupedViewEnabled &&
      shouldShowPagination() ? (
        <InsightsPaginationGroupedViewControls moduleType={appConstants.CONTENT_TYPE.WEB} />
      ) : shouldShowPagination() ? (
        <InsightsPaginatonControls moduleType={moduleType} />
      ) : null}
    </div>
  );
}
