import React, { useEffect, useState } from 'react';
import { ALL_EMAIL_SUBMISSION_SECTIONS_TYPES } from './constants';
import { Stack, Tooltip } from '@mui/material';
import LableValue from '../Insights/LableValue';
import { DISPOSITION_TO_ICON_MAPPER } from '../AbuseMailboxDashboard/components/MostReportedDomains';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';
import { Link } from 'react-router-dom';
import CopyButton from '../../Common/CopyButton';
import { LabelAndValue } from '../../Common/LabelAndValue';
import { StatusLabels } from '../../Common/statusLabels';
import _ from 'lodash';

export interface InsightsAnalysisData {
  label: string;
  value: string | number;
  sha256: string;
  timestamp?: number;
  source?: string;
  status?: string;
  name?: string;
}

interface IDispositionInsightsBlock {
  type: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES;
  initialDispotion: string;
  insightsAnalysisData: Array<InsightsAnalysisData>;
}

function DispositionInsightsBlock({
  type,
  initialDispotion,
  insightsAnalysisData,
}: IDispositionInsightsBlock) {
  const [, currentTheme] = useIsDarkMode();

  const [, setIsScrolling] = useState(false);

  const getInsightsPath = (insights: InsightsAnalysisData) => {
    let insightsPath = '';
    if (type === ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL && insights.sha256 && insights.timestamp) {
      insightsPath = `/abuse-mailbox/all-email-submissions/insights/${insights.timestamp}/${insights.sha256}`;
    } else if (type !== ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL && !_.isEmpty(insights.sha256)) {
      insightsPath = `/abuse-mailbox/insights/threatTypes/${type}/${insights.sha256}`;
    }
    return insightsPath;
  };
  let className = '';
  if (type === ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT) {
    className = 'attachement-text-clip remove-link';
  }

  if (type === ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.PHONE_NUMBER) {
    className = 'remove-link';
  }

  const renderInsightsDom = (insights: InsightsAnalysisData) => {
    const path = getInsightsPath(insights);
    if (type === ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL) {
      return (
        <>
          {!_.isEmpty(path) ? (
            <>
              <Tooltip title={insights.value}>
                <Link
                  className='abuse-mailbox-url-insights-link'
                  style={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '200px',
                  }}
                  target={'_blank'}
                  to={path}
                >
                  {insights?.value}
                </Link>
              </Tooltip>
              <CopyButton
                value={insights.value.toString()}
                copyButton={true}
                className='custom-copy-button'
              />
              {insights.status && ['phish', 'scam'].includes(initialDispotion.toLowerCase()) && (
                <StatusLabels status={insights.status} />
              )}
            </>
          ) : (
            <div className='clip-url'>{insights.value}</div>
          )}
        </>
      );
    } else {
      return (
        <>
          {!_.isEmpty(path) ? (
            <>
              <LabelAndValue
                label={insights.label}
                value={insights.value.toString()}
                className='abuse-mailbox-url-insights-link'
                renderDom={
                  <Tooltip title={insights.value}>
                    <span className={className}>{insights?.value}</span>
                  </Tooltip>
                }
              />
              <CopyButton
                value={insights.value.toString()}
                copyButton={true}
                className='custom-copy-button'
              />
            </>
          ) : (
            <div className='abuse-mailbox-url-insights-link'>
              <LableValue lable={insights.label} value={insights.value} />
            </div>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    const scrollTimeout: any = null;
    const handleScroll = () => {
      setIsScrolling(true);

      scrollTimeout && clearTimeout(scrollTimeout);

      setTimeout(() => setIsScrolling(false), 200);
    };

    document.querySelector('.section-wrap')?.addEventListener('scroll', handleScroll);

    return () => {
      document.querySelector('.section-wrap')?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='disposition-insights-block-wrap'>
      <Stack>
        <span className='stack-item disposition-row'>
          <LableValue
            lable='Initial Disposition'
            value={
              <span className='disposition-wrap'>
                <img
                  src={
                    DISPOSITION_TO_ICON_MAPPER[
                      `${initialDispotion.toLocaleLowerCase()}-${currentTheme}`
                    ]
                  }
                />
                {initialDispotion} {type === 'url' && `(${insightsAnalysisData.length})`}
              </span>
            }
          />
        </span>
        <span className='stack-item data-row'>
          {insightsAnalysisData.map(insights => {
            return (
              <div className='url-wrap link-wrap' key={insights.sha256}>
                {renderInsightsDom(insights)}
                {ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT === type && (
                  <LableValue
                    lable='Attachment Name'
                    value={_.capitalize(insights?.name)}
                    className='source-data'
                    showTooltip={true}
                  />
                )}
                <LableValue
                  lable={
                    type === ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT ? 'Type' : 'Source'
                  }
                  value={_.capitalize(insights.source)}
                  className='source-data'
                />
              </div>
            );
          })}
        </span>
      </Stack>
    </div>
  );
}

export default DispositionInsightsBlock;
