import React, { memo, useCallback, useEffect, useState } from 'react';
import './bulkscan.scss';
import _ from 'lodash';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { appConstants, DashBoardDto, featureIsAvailable } from '../../constants';
import {
  BFScanLocation,
  BulkScanUrlInfoParams,
  IScanLocations,
  ScanClass,
} from './BulkScanConstants';
import {
  DropdownMultiSelect,
  DropdownTypeValues,
  IOption,
  IOptionGroup,
} from '../Common/DropdownMultiSelect';
import Country from '../Common/Country';
import { Dropdown } from '../Common/Dropdown';
import BulkScanUrlInfoRedirectContainer from './BulkScanUrlInfoRedirectContainer';
import { Button, CircularProgress } from '@mui/material';
import {
  getBrandScanPreference,
  getCountryCodeTlds,
  getPremiumScanLocations,
  getScanLocations,
} from './bulk-scan-requests';
import useOnGroupingAndSortingLocations from './Common/useOnGroupingAndSortingLocations';
import ScanLocationInfo, { ICountryCodeTlds } from './ScanLocationInfo.poc';

const SCAN_LIMIT = 10;
const MULTI_LOC_SCAN_LIMIT = 1;
const MIN_WAIT = 5000;
const MAX_WAIT = 60000;
const STEP_SIZE = 5000;
const DEFAULT_WAIT = MIN_WAIT - STEP_SIZE;

interface IScanLocation extends IOption {
  continentCode: string;
  continentName: string;
}

interface IUserAgent {
  id: number;
  userAgent: string;
  description: string;
  label: string;
}

interface IBulkScanFormProps {
  isLoading: boolean;
  submitScans: (scanInfo: any) => void;
  user: DashBoardDto;
  allUserAgents: IUserAgent[];
  userEntedredUrls: string[];
  bulkScanStatus: string;
  updateBulkScanStatus: () => void;
  bulkScanUrlInfo: BulkScanUrlInfoParams[];
  isMultiLocScanChecked?: boolean;
}

interface IBulkScanFormState {
  urls: string[];
  selectedScanLocations: IOption[];
  locationOptions: IOptionGroup[];
  wait: number;
  selectedUserAgent: string;
  renderTimestamp: number;
  textAreaValue: string;
  errorMessageFlag: boolean;
  isPremiumScanEnabled: boolean;
  selectedPremiumScanLocations: IOption[];
  scanClass: string;
}

interface IBrandScanPreference {
  countryCode: string;
  scanUserAgent: string;
  scanUserAgentId: number | null;
  scanTimeout: number;
}

const caughtValidationError: string[] = [];

let defaultScanLocations: IOption[] = [];
let brandScanPreference = {} as IBrandScanPreference;
const BulkScanForm = (props: IBulkScanFormProps) => {
  const initialUrls = _.union(props.userEntedredUrls, []).join('\n');

  const [bulkScanState, setBulkScanState] = useState<IBulkScanFormState>({
    urls: [],
    selectedScanLocations: [],
    locationOptions: [],
    wait: DEFAULT_WAIT,
    selectedUserAgent: '',
    renderTimestamp: moment().valueOf(),
    textAreaValue: initialUrls,
    errorMessageFlag: false,
    isPremiumScanEnabled: false,
    selectedPremiumScanLocations: [],
    scanClass: 'default',
  });
  const [premiumScanLocations, setPremiumScanLocations] = useState<any[]>([]);
  const [scanLimit, setScanLimit] = useState<number>(SCAN_LIMIT);
  const [brandScanPref, setBrandScanPref] = useState<any>({});
  const [countryCodeTlds, setCountryCodeTlds] = useState<ICountryCodeTlds>({});

  const { onGroupingAndSortingLocations } = useOnGroupingAndSortingLocations();
  const isDuplicateEntryPresent = (arr: string[]) => {
    return arr.length !== _.uniq(arr).length;
  };

  const resetScanSettings = () => {
    setBulkScanState(prevState => ({
      ...prevState,
      selectedScanLocations: defaultScanLocations,
      wait: brandScanPreference?.scanTimeout || DEFAULT_WAIT,
      selectedUserAgent: brandScanPreference?.scanUserAgent || '',
    }));
  };

  const onWaitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const wait = parseInt(e.currentTarget.value, 10);
    setBulkScanState(prevState => ({
      ...prevState,
      wait,
    }));
  };

  const onTextAreaChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const textAreaValue = event.target.value;
    let urls = textAreaValue.split('\n');
    urls = _.filter(urls, url => !_.isEmpty(url));
    setBulkScanState(prevState => ({
      ...prevState,
      urls,
      textAreaValue,
      errorMessageFlag: false,
    }));
    caughtValidationError.length = 0;
  };

  const validateURLEntries = () => {
    const { urls } = bulkScanState;
    const isLengthError = _.every(urls, url => url.length < 32000);
    if (!isLengthError) {
      caughtValidationError.push(
        "The URL exceeds our 32,000 characters limit and can't be scanned. Please try a shorter version.",
      );
    }

    const duplicateURLError = isDuplicateEntryPresent(urls);
    if (duplicateURLError) {
      caughtValidationError.push(
        "Duplicate URL Detected. You've entered a URL that's already on the list. Please enter unique URLs to scan up to 10 at once.",
      );
    }

    if (bulkScanState.scanClass === ScanClass.HEADFUL_MAC_MULTI && urls.length > 1) {
      caughtValidationError.push(
        'You can only scan one URL at a time with the multi-location feature.',
      );
    }

    if (caughtValidationError.length > 0) {
      setBulkScanState(prevState => ({ ...prevState, errorMessageFlag: true }));
    }

    return Boolean(caughtValidationError.length);
  };

  const submitScans = () => {
    const { selectedScanLocations, wait, selectedUserAgent, urls } = bulkScanState;
    props.submitScans({
      urls,
      scanLocations: _.map(
        selectedScanLocations.length > 0 ? selectedScanLocations : defaultScanLocations,
        l => l.value,
      ),
      wait: wait >= MIN_WAIT ? wait : undefined,
      selectedUserAgent: selectedUserAgent,
      onSubmitType: 'bulkscan',
      scanClass: bulkScanState.scanClass,
      scanClassOptions: bulkScanState.selectedPremiumScanLocations,
    });
  };

  const onScanClickHandler = () => {
    if (!validateURLEntries()) {
      submitScans();
    }
  };

  const onClearUrls = () => {
    setBulkScanState(prevState => ({
      ...prevState,
      renderTimestamp: moment().valueOf(),
      urls: [],
      textAreaValue: '',
      errorMessageFlag: false,
    }));
    caughtValidationError.length = 0;
  };

  const updateBulkScanStatus = (btnClicked: any) => {
    props.updateBulkScanStatus();
    if (btnClicked === 'newscan') {
      setBulkScanState(prevState => ({
        ...prevState,
        urls: [],
        textAreaValue: '',
      }));
    }
  };

  const onLocationSelect = (option: any) => {
    setBulkScanState(prevState => ({
      ...prevState,
      selectedScanLocations: option,
    }));
  };

  const onUserAgentSelect = (selection: IUserAgent) => {
    setBulkScanState(prevState => ({
      ...prevState,
      selectedUserAgent: selection.userAgent,
    }));
  };

  const loadAllScanLocations = async () => {
    const promises = [getBrandScanPreference(), getScanLocations(), getCountryCodeTlds()];

    //POC code for premium scan location
    if (featureIsAvailable(props.user, [appConstants.FEATURE_CODE.PREMIUM_SCAN_LOCATION])) {
      promises.push(getPremiumScanLocations());
    }

    await Promise.all(promises).then(res => {
      const [brandScanDefaultPreference, scanLocations, countryCodeTldsRes, premiumScanLocations] =
        res;
      brandScanPreference = brandScanDefaultPreference;

      setBrandScanPref(brandScanDefaultPreference);
      setCountryCodeTlds(countryCodeTldsRes);

      if (brandScanPreference && brandScanPreference?.countryCode) {
        defaultScanLocations = [];
        scanLocations?.forEach((sl: any) => {
          if (sl.countryCode === brandScanPreference.countryCode) {
            defaultScanLocations.push({
              label: sl.locationName,
              value: sl.locationCode,
            });
          }
        });
      }

      const isLocationBasedFeatureAvailable: boolean = featureIsAvailable(props.user, [
        appConstants.FEATURE_CODE.LOCATION_BASED,
      ]);

      // If the brand's location preference is not in the scanLocations list, set the US as the default again with the updated US locationCode.
      // Also if location based feature is not enabled, set the US as the default with locationCode as US even if they have a preference.
      if (defaultScanLocations.length === 0 || !isLocationBasedFeatureAvailable) {
        const defaultUSLocation = scanLocations?.find((sl: any) => sl.countryCode === 'US');

        // If a location for US is found and feature is enabled, update defaultScanLocations with the correct locationCode
        if (defaultUSLocation && isLocationBasedFeatureAvailable) {
          defaultScanLocations = [
            {
              ...IScanLocations.US, // Keep the existing fields
              value: defaultUSLocation.locationCode, // Update the value with the correct locationCode
            },
          ];
        } else {
          defaultScanLocations = [IScanLocations.US];
        }
      }

      // all scan locations
      let scanLocationsOptions: IScanLocation[] = [];
      if (isLocationBasedFeatureAvailable) {
        scanLocationsOptions = _.chain(scanLocations)
          .map(location => {
            const {
              locationCode,
              locationName,
              continent: { continentCode, continentName },
            } = location;
            return {
              label: locationName,
              value: locationCode,
              continentCode,
              continentName,
            };
          })
          .valueOf();
      }
      //POC code for premium scan location
      if (premiumScanLocations?.length > 0) {
        const premiumScanLocaltionOptions = _.chain(premiumScanLocations)
          .map(location => {
            const {
              locationCode,
              locationName,
              countryCode,
              continent: { continentCode, continentName },
            } = location;
            return {
              label: locationName,
              value: locationCode,
              continentCode,
              countryCode,
              continentName,
            };
          })
          .valueOf();
        const locations = onGroupingAndSortingLocations(premiumScanLocaltionOptions);
        setPremiumScanLocations(locations);
      }

      // Change Tor continentName to 'Tor' from 'All' if it exists
      const torLocation = scanLocationsOptions.find(location => location.value === 'TOR');
      if (torLocation) {
        torLocation.continentName = 'Tor';
      }

      setBulkScanState(prevState => ({
        ...prevState,
        locationOptions: onGroupingAndSortingLocations(scanLocationsOptions as BFScanLocation[]),
        selectedScanLocations: defaultScanLocations,
        selectedUserAgent: brandScanPreference?.scanUserAgent || '',
        wait: brandScanPreference?.scanTimeout || DEFAULT_WAIT,
      }));
    });
  };

  useEffect(() => {
    const combinedUrls = _.union(
      props.userEntedredUrls,
      textAreaValue.split('\n').filter(url => !_.isEmpty(url)),
    ).join('\n');

    setBulkScanState(prevState => ({
      ...prevState,
      textAreaValue: combinedUrls,
    }));
  }, [props.userEntedredUrls]);

  useEffect(() => {
    void loadAllScanLocations();
  }, []);

  const { isLoading, allUserAgents } = props;
  const groupedUserAgents: any = {};
  if (
    !brandScanPreference?.scanUserAgentId &&
    brandScanPreference?.scanUserAgent &&
    bulkScanState.selectedUserAgent &&
    allUserAgents.length > 0
  ) {
    groupedUserAgents['Custom'] = [
      {
        id: 0,
        userAgent: brandScanPreference?.scanUserAgent,
        description: 'Custom User Agent',
        label: brandScanPreference?.scanUserAgent,
      },
    ];
  }
  if (allUserAgents?.length > 0) {
    allUserAgents.forEach((element: IUserAgent) => {
      if (groupedUserAgents[element.label]) {
        groupedUserAgents[element.label].push({
          ...element,
          label: element.userAgent,
        });
      } else {
        groupedUserAgents[element.label] = [
          {
            ...element,
            label: element.userAgent,
          },
        ];
      }
    });
  }

  const {
    urls,
    selectedScanLocations,
    locationOptions,
    wait,
    renderTimestamp,
    textAreaValue,
    errorMessageFlag,
  } = bulkScanState;
  const counts = urls.length;

  const onPremimunScanChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setBulkScanState(prevState => ({
      ...prevState,
      isPremiumScanEnabled: e.target.checked,
    }));
  }, []);

  const onPremimunScanLocationChange = useCallback((option: any[]) => {
    const selectedLocation: any = {
      country: option[0]?.label,
      scanLocation: option[0]?.value,
      continentCode: option[0]?.continentCode,
      countryCode: option[0]?.countryCode,
    };
    setBulkScanState(prevState => ({
      ...prevState,
      selectedPremiumScanLocations: selectedLocation,
    }));
  }, []);

  useEffect(() => {
    if (bulkScanState.isPremiumScanEnabled) {
      setBulkScanState(prevState => ({
        ...prevState,
        scanClass: ScanClass.HEADFUL_MAC,
      }));
    } else {
      setBulkScanState(prevState => ({
        ...prevState,
        scanClass: ScanClass.DEFAULT,
      }));
    }

    if (props.isMultiLocScanChecked) {
      setBulkScanState(prevState => ({
        ...prevState,
        scanClass: ScanClass.HEADFUL_MAC_MULTI,
      }));
      setScanLimit(MULTI_LOC_SCAN_LIMIT);
    } else {
      setScanLimit(SCAN_LIMIT);
    }
  }, [bulkScanState.isPremiumScanEnabled, props.isMultiLocScanChecked]);

  return (
    <Form className='bulk-scan-form' key={renderTimestamp}>
      {bulkScanState.locationOptions.length > 0 ? (
        // TODO: add setting components
        <>
          <div>Filter </div>
          <div className='bulk-scan-settings d-flex align-items-center'>
            <div className='drop-down-fields-wrapper'>
              <div className='form-label'>Scanner IP Location</div>
              <div className={'form-block'}>
                <DropdownMultiSelect
                  options={locationOptions}
                  onSubmit={onLocationSelect}
                  initialSelections={selectedScanLocations}
                  renderDom={Country}
                  renderDomKeys={[{ countryCode: 'value' }, { countryName: 'label' }]}
                  updateOnChange
                  hideFooter
                  removable
                  limit={2}
                  defaultText='Default'
                  type={DropdownTypeValues.location}
                  disabled={bulkScanState.isPremiumScanEnabled || props.isMultiLocScanChecked}
                />
              </div>
            </div>
            <div className='drop-down-fields-wrapper'>
              <div className='form-label'>User Agent</div>
              <div className={'form-block'}>
                <Dropdown
                  defaultSelection={{
                    label: bulkScanState.selectedUserAgent,
                    value: bulkScanState.selectedUserAgent,
                  }}
                  options={groupedUserAgents}
                  onChange={onUserAgentSelect}
                  boxStyle
                  disabled={bulkScanState.isPremiumScanEnabled || props.isMultiLocScanChecked}
                />
              </div>
            </div>
            <div className='drop-down-fields-wrapper'>
              <div className='form-label'>Timeout for Page Scan</div>
              <div className={'form-block wait-range-container d-flex align-items-center'}>
                <input
                  type='range'
                  min={DEFAULT_WAIT}
                  max={MAX_WAIT}
                  className={'wait-range'}
                  step={STEP_SIZE}
                  value={wait}
                  onChange={onWaitChange}
                  disabled={bulkScanState.isPremiumScanEnabled || props.isMultiLocScanChecked}
                />
                <span className={'pl-2'}>{wait === DEFAULT_WAIT ? 'Auto' : wait + 'ms'}</span>
              </div>
            </div>
            <div className='drop-down-fields-wrapper'>
              <div
                id='bulk-scan-reset-scan-setting'
                className='reset-button'
                onClick={resetScanSettings}
              >
                Reset All
              </div>
            </div>
          </div>
          <div className='bulk-scan-settings d-flex flex-column align-items-left'>
            {featureIsAvailable(props.user, [appConstants.FEATURE_CODE.PREMIUM_SCAN_LOCATION]) && (
              <>
                <Form.Check
                  className='mb-3'
                  type='switch'
                  label='Premium Scan'
                  id='premium-scan-switch'
                  onChange={onPremimunScanChange}
                  checked={bulkScanState.isPremiumScanEnabled}
                  disabled={props.isMultiLocScanChecked}
                />
                <div className='drop-down-fields-wrapper'>
                  <div className='form-label'>Premium Scan Locations</div>
                  <div className={'form-block'}>
                    <DropdownMultiSelect
                      options={premiumScanLocations}
                      onSubmit={onPremimunScanLocationChange}
                      initialSelections={[]}
                      renderDom={Country}
                      renderDomKeys={[{ countryCode: 'value' }, { countryName: 'label' }]}
                      updateOnChange
                      hideFooter
                      removable
                      limit={1}
                      defaultText='Default'
                      type={DropdownTypeValues.location}
                      disabled={!bulkScanState.isPremiumScanEnabled || props.isMultiLocScanChecked}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
      {props.bulkScanStatus === 'fulfilled' && (
        <BulkScanUrlInfoRedirectContainer
          urls={props.bulkScanUrlInfo}
          updateBulkScanStatus={updateBulkScanStatus}
        />
      )}

      {_.isEmpty(props.bulkScanStatus) && (
        <div className='bulk-scan-textArea-btn-container'>
          <Form.Group controlId='bulkScan.scanUrls'>
            <Form.Label>Please enter a new url on each line</Form.Label>
            <Form.Label className={'limit'}>
              (Limit {counts}/{scanLimit})
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={scanLimit}
              className='bulk-scan-text-area'
              onChange={onTextAreaChange}
              value={textAreaValue}
              placeholder={`Enter website URL${
                scanLimit > 1 ? 's' : ''
              } to scan for phishing checks`}
            />
          </Form.Group>
          {featureIsAvailable(props.user, [appConstants.FEATURE_CODE.PREMIUM_SCAN_LOCATION]) &&
            props.isMultiLocScanChecked && (
              <ScanLocationInfo
                url={bulkScanState?.urls?.[0]}
                brandScanPref={brandScanPref}
                countryCodeTlds={countryCodeTlds}
              />
            )}
          {!_.isEmpty(caughtValidationError) && (
            <div className='bulkscan-error-message-block'>
              {caughtValidationError.map((err, indx) => (
                <>
                  <span key={indx + 1} className='bulkscan-error-message-text'>
                    {err}
                  </span>
                  <br />
                </>
              ))}
            </div>
          )}
          <div className='button-containers'>
            <Button
              id='bulk-scan-submit-button'
              variant='contained'
              onClick={onScanClickHandler}
              disabled={
                counts < 1 ||
                counts > scanLimit ||
                isLoading ||
                selectedScanLocations.length === 0 ||
                errorMessageFlag
              }
            >
              {isLoading ? <CircularProgress size={16} /> : 'Scan'}
            </Button>
            <Button
              variant='text'
              id='bulk-scan-clear-all-button'
              className='clear-button margin-start-10'
              onClick={onClearUrls}
            >
              Clear All
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
export default BulkScanForm;
