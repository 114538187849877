import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import TypoSquatTable from '../DomainMonitoring/TyposquatTable';
import ApiService from '../../services/api.service.checkphish';
import { useContext } from 'react';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { useHistory } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { TypoDispositionScan } from '../../types/typosquat-dashboard.interface';
import { history } from '../../helpers';
import _ from 'lodash';
import { setLocalStorageValue, validateDomain } from '../../constants';
import WebSideMenu from '../Drawer/WebSideMenu';
import { TAB_ITEMS_HEADER } from '../Drawer/TabItems';
import { WebHeader } from '../Drawer/WebHeader';
import { WebFooter } from '../Drawer/WebFooter';
import { TAB_ITEMS_FOOTER, TAB_ITEMS_FOOTER_L2 } from '../Drawer/TabItems';
import { DEFAULT_SCAN_TYPE } from '../BulkScan/bulkscan.component';
import { ScanProgress } from '../Scan/ScanProgress';
import { ILoginError } from '../../App';
import './AnnonymousScans.scss';
import { TYPOSQUATTING_DASHBOARD_PATHNAME, TypoJobStatus } from '../DomainMonitoring/constant';
import { redirectToCheckphish } from '../../constants';
import TyposquatContext from '../../context/TyposquatContext';
import { LoadingSpinner } from '../Common/LoadingSpinner';
import { parseDomain, ParseResultType } from 'parse-domain';

interface IAnnonymousScanPageProps {
  changeLoginError: (newState: ILoginError) => void;
}

const apiService = new ApiService();

const AnnonymousScanPage = ({ changeLoginError }: IAnnonymousScanPageProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isWebSideMenuOpen, changeWebSideMenuOpen] = useState(false);
  const [customUserAgent, setCustomerUserAgent] = useState('');

  const { identifier } = useContext(CheckPhishContactUsContext);
  const {
    typosquatResult,
    typosquatMetadata,
    typosquatReportGenerated,
    setDomain,
    setTyposquatResult,
    setTyposquatMetadata,
    setTyposquatReportGenerated,
  } = useContext(TyposquatContext);

  const privateScan: boolean = false;

  let subscription: Subscription;

  const historyRouterDom = useHistory();

  const goToSignIn = (params: string = '') => {
    history.push(`/sign-in${params}`);
  };

  const toggleWebSideMenu = () => {
    changeWebSideMenuOpen(oldState => !oldState);
  };

  const liveScanCheck = (url: string, customUserAgent: string) => {
    if (_.isEmpty(url)) {
      goToSignIn();
    } else {
      setSearchText(url);
      setCustomerUserAgent(customUserAgent);
      startScan(url, customUserAgent);
    }
  };

  const startScan = (searchText: string, customUserAgent: string) => {
    if (!isLoading) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'scanInProgress',
        category: 'Page',
        action: 'clicked',
        label: 'scanInProgress',
      });
      apiService
        .bulkScan(
          searchText,
          `cp_${identifier ?? ''}`,
          DEFAULT_SCAN_TYPE,
          privateScan,
          customUserAgent,
        )
        .then((res: any) => {
          if (res.msg) {
            throw new Error(res.msg);
          }
          // Adding delay till the data gets pushed to s3, elastic etc
          setTimeout(() => {
            history.push(`/public/insights/${res.timestamp}/${res.urlSHA256}`);
          }, 2000);
        })
        .catch((err: any) => {
          if (err?.status === 401) {
            setLocalStorageValue('skip-annonymous-scan-page', true);
            history.push(`sign-up?errMsg=bulk_scan_${err.error}`);
          } else {
            console.log(err);
            history.push('/');
          }
        });
    }
  };

  useEffect(() => {
    if (!identifier.toString().length) return;
    const { search, pathname } = history.location;
    if (!search.length) goToSignIn();
    const query = new URLSearchParams(search);
    switch (pathname) {
      case '/live-scan': {
        const url = query.getAll('url')[0];
        const agent = query.getAll('agent')[0] ?? '';
        liveScanCheck(url, agent);
        return;
      }
      case '/domain-monitor': {
        const domain = query.getAll('domain')[0];
        if (typosquatResult.length) return;
        onDomainChange(domain);
        onTyposquatSubmit(domain);
        return;
      }
      default:
        goToSignIn();
        break;
    }
  }, [identifier]);

  useEffect(() => {
    const unlisten = historyRouterDom.listen(() => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    return () => {
      unlisten();
    };
  }, [historyRouterDom]);

  useEffect(() => {
    if (!typosquatReportGenerated || typosquatResult.length) return;
    fetchTypoSquattingResult();
  }, [typosquatReportGenerated]);

  const onDomainChange = (originalDomain: string) => {
    let originalDomainName = originalDomain;
    if (_.isEmpty(originalDomainName) || !validateDomain(originalDomain)) {
      return redirectToCheckphish(
        '/domain-monitoring',
        false,
        'typosquat_error=Please enter a valid domain',
      );
    } else {
      try {
        const url = new URL(originalDomain);
        originalDomainName = url.hostname;
      } catch (error) {
        console.error(error);
      }

      try {
        const parseResult = parseDomain(originalDomainName);
        if (parseResult.type === ParseResultType.Listed) {
          const { domain } = parseResult;
          // check if domain part is too long
          if (domain && domain.length > 15) {
            redirectToCheckphish(
              '/domain-monitoring',
              false,
              'typosquat_error=The max length of domain we can help monitor is 15 characters',
            );
          }
        }
      } catch (error) {
        // invalid domain name
        redirectToCheckphish(
          '/domain-monitoring',
          false,
          'typosquat_error=Please enter a valid domain',
        );
      }
    }
  };

  const fetchTypoSquattingResult = () => {
    if (identifier !== '') {
      setIsLoading(true);
      subscription = apiService.fetchAnonymousTyposquatResult(identifier).subscribe((resp: any) => {
        const { monitoredDomain, status, metadata, typosquattingResult } = resp;
        setTyposquatMetadata(_.cloneDeep(metadata));
        const data = typosquattingResult.map((item: TypoDispositionScan) => ({
          ...item,
          ip: item?.ipv4,
          ip_data: item?.ipv4?.join(' ').replaceAll('0.0.0.0', '--') || '',
          nameserver: item?.ns ? item?.ns.filter(n => !n?.includes('0.0.0.0')).join(' ') : [],
          mailserver: item?.mx ? item?.mx.filter(n => !n?.includes('0.0.0.0')).join(' ') : [],
        }));
        setDomain(monitoredDomain);
        setTyposquatResult(data);
        if (
          status === TypoJobStatus.DOMAIN_SCAN ||
          status === TypoJobStatus.PUBLISHED ||
          status === TypoJobStatus.MONITORED_DONE ||
          status === TypoJobStatus.DONE
        ) {
          setIsLoading(false);
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('Identifier not found');
    }
  };

  const onTyposquatSubmit = (text: string) => {
    setIsLoading(true);
    setSearchText(text);
    apiService
      .generateAnonymousReport(text, identifier as string)
      .then(() => setTyposquatReportGenerated(true))
      .catch((err: any) => {
        if (err === 'You have reached your daily limit.') {
          changeLoginError({
            status: true,
            message:
              'You have reached your daily limit. Sign up for free to monitor your domain against domain threats.',
          });
          setLocalStorageValue('skip-annonymous-scan-page', true);
          history.push(`sign-up?errMsg=typosquat_daily_limit_reached`);
        } else {
          history.go(-1);
        }
      })
      .finally(() => setIsLoading(false));
  };

  //rendering contents of typosquat page
  const renderTypoSquat = () => (
    <div className={'home-page'}>
      <div
        className={
          'bg-white scanner-section d-flex flex-column align-items-center typosquat-container'
        }
      >
        <div className='status-bar'>
          <div className={'back-btn-inline'}>
            <button
              className='btn transparent-btn'
              onClick={() => {
                redirectToCheckphish();
              }}
            >
              <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
              Back
            </button>
          </div>
          {!isLoading && typosquatMetadata.totalVariants && (
            <div className='monitored-domain-helper-text'>
              <h6 className={'align-items-center'} style={{ textTransform: 'capitalize' }}>
                {typosquatMetadata?.totalResolvedVariants} of registered domain typosquats found for{' '}
                {searchText}.
              </h6>
              <h6 className={'align-items-center'} style={{ textTransform: 'capitalize' }}>
                Sign up for a free account to scan these typosquats for suspicious activity and
                monitor any potential risks.
              </h6>
            </div>
          )}
        </div>
        {isLoading && (
          <>
            <div className='loading-wrapper-component'>
              <h5 className='loader-text'>Generating Typosquats For {searchText}</h5>
              <LoadingSpinner />
            </div>
            <span className='message'>Generating {typosquatMetadata?.topTldVariants}</span>
          </>
        )}
        <div className='progress-bar-wrap'>
          {!isLoading && (
            <div className={'results'}>
              Here is the list of typosquats. For phishing URL checks,
              <a href={redirectToCheckphish('/live-url-scan', true)}> scan here.</a>
            </div>
          )}
        </div>

        <TypoSquatTable
          customToolBar={customToolBar}
          tableData={typosquatResult}
          enableCheckbox={false}
          isAnnonymous={true}
          showLoader={false}
        />
      </div>
    </div>
  );

  const customToolBar = (
    <div className='custom-toolbar' style={{ marginLeft: '.5rem' }}>
      <Link
        to={'/sign-up'}
        onClick={() => {
          setLocalStorageValue('currentActiveUrl', TYPOSQUATTING_DASHBOARD_PATHNAME);
        }}
      >
        <Button variant={'primary'} className={'web-header-button sign-up-button-box px-4'}>
          <div className={'start-monitoring-btn'}>Sign Up to Monitor</div>
        </Button>
      </Link>
    </div>
  );

  //render logic for bulkscan page
  const renderBulkScanPage = () => {
    return <ScanProgress searchText={searchText} customUserAgent={customUserAgent} />;
  };

  return (
    <>
      {isWebSideMenuOpen && (
        <WebSideMenu
          isOpen={isWebSideMenuOpen}
          items={TAB_ITEMS_HEADER}
          close={toggleWebSideMenu}
        />
      )}
      <WebHeader tabItems={TAB_ITEMS_HEADER} toggle={toggleWebSideMenu} />
      {
        {
          '/live-scan': renderBulkScanPage(),
          '/domain-monitor': renderTypoSquat(),
        }[history.location.pathname]
      }
      <WebFooter tabItems={TAB_ITEMS_FOOTER} tabItemsL2={TAB_ITEMS_FOOTER_L2} />
    </>
  );
};

export default AnnonymousScanPage;
