import { IServerSideSelectionState } from 'ag-grid-community';
import { useCallback, useContext, useEffect } from 'react';
import { dashboardActions } from '../../../../actions';
import { FINDINGS_TABLE_IDS, MALICIOUS_TABLE_IDS } from '../../../MonitorAndTakedown/constants';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { appConstants, getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { TableContext } from '../table.context';
import { setCurrentSingleSelectedFindings } from '../../../../reducers/darkWeb.reducer';

export const useOnSelectionChangeEvent = ({
  agRef,
  tableId,
  allRowData,
  moduleType,
  currentRowsOfPage,
  currentPageNumber,
  currentPaginationSize,
}: {
  agRef: React.MutableRefObject<any>;
  tableId: string;
  allRowData: any;
  moduleType?: string;
  currentRowsOfPage: any[];
  currentPageNumber: number;
  currentPaginationSize: number;
}) => {
  const { onItemCheck } = useContext(TableContext);
  const dispatch = useAppDispatch();

  const findingsDetection = useAppSelector(state => state.dashboardReducer.findingsTableUrls)[
    moduleType as string
  ]?.[tableId];
  const findingsWebUrls = useAppSelector(state => state.dashboardReducer.selectedWebUrls)[tableId];

  const getUpdatedLocalStorageValue = () => {
    return getLocalStorageValue(['savedSelectionState', tableId]);
  };

  useEffect(() => {
    if (allRowData.length && agRef?.current?.api && currentRowsOfPage) {
      const savedSelectionState = getUpdatedLocalStorageValue()[currentPageNumber]?.pageSelection;
      agRef.current?.api.setServerSideSelectionState({
        selectAll: savedSelectionState ? savedSelectionState.selectAll : false,
        toggledNodes: savedSelectionState ? savedSelectionState.toggledNodes : [],
      });
    }
  }, [
    agRef.current,
    allRowData,
    currentRowsOfPage,
    currentPageNumber,
    findingsDetection?.length,
    findingsWebUrls?.length,
  ]);

  useEffect(() => {
    setLocalStorageValue(['savedSelectionState', tableId], []);
    dispatch(dashboardActions.setSelectedWebUrls(tableId as MALICIOUS_TABLE_IDS, []));
    dispatch(
      dashboardActions.setFindingsTableUrls(
        tableId as FINDINGS_TABLE_IDS,
        [],
        moduleType as string,
      ),
    );
  }, [currentPaginationSize]);

  const saveRowsState = (filteredSelectedRows: any) => {
    const existingData = getUpdatedLocalStorageValue() || {};
    const saveRowNodesState = agRef.current?.api?.getServerSideSelectionState();
    const dataToSave = {
      [currentPageNumber]: {
        pageSelection: saveRowNodesState,
        filteredSelectedRows: filteredSelectedRows,
      },
    };
    const updatedData = { ...existingData, ...dataToSave };
    setLocalStorageValue(['savedSelectionState', tableId], updatedData);
  };

  const getSavedRows = () => {
    const savedSelectionState = getUpdatedLocalStorageValue();
    const selectedRowsAccrossPages = Object.keys(savedSelectionState).map((ele: any) => {
      return savedSelectionState[ele].filteredSelectedRows;
    });
    if (moduleType === appConstants.CONTENT_TYPE.WEB) {
      dispatch(
        dashboardActions.setSelectedWebUrls(
          tableId as MALICIOUS_TABLE_IDS,
          selectedRowsAccrossPages.flat(),
        ),
      );
    } else if (
      moduleType === appConstants.CONTENT_TYPE.SOCIAL ||
      moduleType === appConstants.CONTENT_TYPE.APP_STORE
    ) {
      dispatch(
        dashboardActions.setFindingsTableUrls(
          tableId as FINDINGS_TABLE_IDS,
          selectedRowsAccrossPages.flat(),
          moduleType as string,
        ),
      );
    } else {
      const allSha256 = selectedRowsAccrossPages.flat().map((row: any) => row.sha256);
      dispatch(setCurrentSingleSelectedFindings(allSha256));
    }
  };

  //https://www.ag-grid.com/react-data-grid/server-side-model-selection/#checkbox-selection
  const handleOnSelectionChange = useCallback(
    event => {
      if (event.source?.includes('api')) {
        return;
      }
      const selectedRows: IServerSideSelectionState = event.api.getServerSideSelectionState();
      const { selectAll, toggledNodes } = selectedRows;
      const toggleNodeUrlSet = new Set(
        toggledNodes.map((id: string) => event.api.getRowNode(id)?.data.url_sha256),
      );
      const filteredSelectedRows = (
        selectAll
          ? currentRowsOfPage.filter(
              row => !toggleNodeUrlSet.has(row['url_sha256'] || row['sha256']),
            )
          : toggledNodes.map((id: string) => event.api.getRowNode(id)?.data)
      ).filter((row: any) => row.url_sha256 || row.sha256);

      saveRowsState(filteredSelectedRows);

      getSavedRows();
    },
    [allRowData, dispatch, moduleType, tableId, currentRowsOfPage],
  );

  return { handleOnSelectionChange };
};
