import React from 'react';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Box,
  DialogTitle,
} from '@mui/material';

export const ExportLoader = ({
  show,
  error,
  totalCount,
  closeExportLoader,
}: {
  show: boolean;
  error: string;
  totalCount: number;
  closeExportLoader: () => void;
}) => {
  return (
    <Dialog open={show} maxWidth='xs' onClose={closeExportLoader}>
      <DialogTitle>Exporting to CSV</DialogTitle>
      <DialogContent>
        <Box>
          {error ? (
            <Typography color='error'>{error}</Typography>
          ) : (
            <React.Fragment>
              <Box display='flex' alignItems='center' gap={1}>
                <Typography>Building the csv export</Typography>
                {totalCount ? (
                  <Typography>(0 / {totalCount})</Typography>
                ) : (
                  <Typography>...</Typography>
                )}
                <Box display='flex' justifyContent='center' data-testid='exporting-spinner'>
                  <CircularProgress size={20} />
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
