//TODO :  Need to revisit the public insights page

import _ from 'lodash';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { Button as ButtonRB } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { alertActions } from '../../actions';
import { ClockIcon } from '../../assets/SVGIcons';
import WebSideMenu from '../Drawer/WebSideMenu';
import { TAB_ITEMS_HEADER } from '../Drawer/TabItems';
import { TAB_ITEMS_FOOTER } from '../Drawer/TabItems';
import { TAB_ITEMS_FOOTER_L2 } from '../Drawer/TabItems';
import { WebHeader } from '../Drawer/WebHeader';
import { WebFooter } from '../Drawer/WebFooter';
import { IRunByClientAppType } from '../../constants';
import {
  AlertActionsTypes,
  appConstants,
  DashBoardDto,
  DISPLAYED_NULL,
  featureIsAvailable,
  getDispositionIcon,
  getNavPathnameForInsightPage,
  getTimestampAndUrlSHA256,
  InsightsDto,
} from '../../constants';
import { appActions } from '../../actions';
import ThemeContext from '../../context/ThemeContext';
import { AppState, history } from '../../helpers';
import DashboardService from '../../services/dashboard.service';
import UgcService from '../../services/ugc.service';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { Annotation } from '../Common/Annotation';
import AttachFile from '../Common/AttachFile';
import { DispositionStatus } from '../Common/DispositionStatus';
import {
  DISPUTE_DISPOSITION_OPTIONS,
  DISPUTE_STATUS,
  DisputeStatus,
} from '../Common/DisputeStatus';
import { Dropdown, IDropdownOption } from '../Common/Dropdown';
import { LabelAndValue } from '../Common/LabelAndValue';
import { PageTitleInsights } from '../Common/PageTitleInsights';
import { RadioButtons } from '../Common/RadioButtons';
import { Table } from '../Common/Table/table';
import Tags from '../Common/Tags/Tags';
import {
  SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME,
  SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME,
} from '../SocialMedia/constants';
import { EFindingStatus } from '../Ugc/Types/ugc.types';
import './insights.scss';
import Map from './map';
import ScanSettings from './ScanSettings';
import { ThreatIntelligence } from '../ThreatIntelligence/threat.component';
import { ELoggedIn } from '../../reducers';
import { getScreenshotUrl } from '../../helpers/screenshotUrl';
import { mapNetworkProviderToLink } from '../../constants/insights.constants';
import ToolTip from '../Common/ToolTip';
import moreInfoIcon from '../../assets/icons/Info.svg';
import { Button } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const ugcService = new UgcService();

export const PASSIVE_DNS_COLUMNS = [
  {
    header: 'Domains hosted on the same IP address',
    accessor: 'domains',
    isLongText: true,
    render: (data: any) => {
      const { domains } = data;
      if (_.isEmpty(domains)) {
        return '--';
      }
      return (
        <div className={'ip-url-value-wrapper'}>
          <Link to={`${getNavPathnameForInsightPage()}/domain/` + domains}>{domains}</Link>
        </div>
      );
    },
  },
];

export const SIMILAR_URLS_COLUMNS = [
  {
    header: 'URLs hosted on the same domain',
    accessor: 'urls',
    isLongText: true,
    render: (data: any) => {
      const { urls } = data;
      if (_.isEmpty(urls)) {
        return '--';
      }
      return (
        <a
          href={
            `${getNavPathnameForInsightPage()}/insights/` + data.timestamp + '/' + data.urlSHA256
          }
        >
          {urls}
        </a>
      );
    },
  },
];

const COMMENT_MAX_LENGTH = 1000;

export const DEFAULT_FILTER = {
  label: 'All',
  value: 'all',
};

export const DISPOSITION_FILTERS = [
  DEFAULT_FILTER,
  {
    label: 'Phish',
    value: 'phish',
  },
  {
    label: 'Clean',
    value: 'clean',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const renderDispositionFilter = (currentValue: any, onChange: any) => {
  return (
    <Dropdown
      boxStyle
      key={'dns-filter'}
      options={DISPOSITION_FILTERS}
      onChange={onChange}
      defaultSelection={currentValue}
    />
  );
};

interface IInsightsState {
  isLoading: boolean;
  sendingDisputeRequest: boolean;
  isWebSideMenuOpen: boolean;
  disputeError: string;
  scanResults: InsightsDto;
  markerPosition: {
    lat: number;
    lng: number;
  };
  passiveDNS: object[];
  passiveDNSFilter: IDropdownOption;
  similarURL: object[];
  similarURLFilter: IDropdownOption;
  certList: object[];
  isDisputeDetailShown: boolean;
  isDisputeModalShown: boolean;
  dispute: {
    attachments: string[];
    disposition: string;
    bolsterDisposition: string;
    status: string;
    user: any;
    timestamp: number;
    comments: string;
  };
  socialMedia: {
    url: string;
    link: string;
    isLoading: boolean;
  };
}

type Props = {
  user: DashBoardDto;
  loggedIn: ELoggedIn;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
  type?: string;
};

export enum UgcScanCategoryType {
  'Social Media Scan' = 'social',
  'App Store Scan' = 'app-store',
}
class PublicInsights extends React.Component<Props, IInsightsState> {
  private readonly isPublic: boolean = window.location.pathname.indexOf('/public/insights') !== -1;
  private readonly dashboardService: DashboardService;
  private readonly timestamp: string;
  private readonly urlSHA256: string;
  private originPassiveDNS: any = [];
  private originSimilarURL: any = [];
  private uploadedAttachedFilesDispute: any = [];
  private _isMounted = false;

  static contextType = ThemeContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      sendingDisputeRequest: false,
      isWebSideMenuOpen: false,
      disputeError: '',
      isLoading: true,
      scanResults: {
        status: '--',
        srcURL: '--',
        redirectURL: '--',
        location: '--',
        tld: '--',
        brand: '--',
        brandId: '--',
        subBrandId: '--',
        subBrandName: '--',
        networkOwner: '--',
        asn: '--',
        ip: '--',
        takedownTS: '--',
        takedownCount: '--',
        timestamp: '--',
        createdTS: '--',
        imagePath: '',
        cert: '--',
        abuseContact: '--',
        disposition: '',
        host: '',
        primaryDomain: '',
        countryCode: '',
        firstSeenTS: '--',
        jobId: '--',
        hostPhishCount: '--',
        ipPhishCount: '--',
        phishingKitsCountOnHost: '--',
        scanSource: '--',
        annotations: [],
        brandScanCount: '0',
        bolsterScanCount: '0',
        brandInfo: null,
        finalCategory: '--',
        mxRecords: '--',
        hasMxRecords: '--',
        allMXRecords: [],
        registrationDate: '--',
        registrant: '--',
        nameservers: '--',
        sfbDetected: '--',
        scanSettings: {},
      },
      markerPosition: {
        lat: 0,
        lng: 0,
      },
      passiveDNS: [],
      passiveDNSFilter: DEFAULT_FILTER,
      similarURL: [],
      similarURLFilter: DEFAULT_FILTER,
      certList: [],
      isDisputeDetailShown: false,
      isDisputeModalShown: false,
      dispute: {
        attachments: [],
        disposition: '',
        bolsterDisposition: '',
        status: '',
        user: null,
        timestamp: 0,
        comments: '',
      },
      socialMedia: { url: '--', link: '--', isLoading: true },
    };
    this.dashboardService = new DashboardService();
    [this.timestamp, this.urlSHA256] = getTimestampAndUrlSHA256();

    window.document.title = 'URL Insight | Dashboard | Bolster Platform';
  }

  componentDidMount() {
    this._isMounted = true;

    Promise.allSettled([
      this.dashboardService.getInsights(this.timestamp, this.urlSHA256, this.isPublic),
      this.isPublic ? Promise.resolve() : this.dashboardService.getDisputeVerdict(this.urlSHA256),
      this.props.loggedIn === ELoggedIn.true
        ? this.dashboardService.getInsightsBrandAnalytics(this.urlSHA256)
        : Promise.resolve(),
      this.props.loggedIn === ELoggedIn.true
        ? this.dashboardService.getTagsByUrlSha(this.urlSHA256, this.props.type)
        : Promise.resolve(),
    ])
      .then(allResponses => {
        const [insightData, disputeData, brandAnalyticData, tagsData] = allResponses.map(
          (result: any) => result?.value ?? {},
        );
        const scan = insightData['scanResults'];
        const srcUrl = _.get(scan, ['srcUrl'], '--');
        const ip = _.get(scan, ['ip'], '--');
        const host = _.get(scan, ['host'], '--');
        const scanContext = _.get(scan, ['scan_context']);
        const scanCategoryType = _.get(scan, ['scan_category']);
        this.setCompState({
          isLoading: false,
          scanResults: {
            ...this.state.scanResults,
            status: _.get(scan, ['status'], '--'),
            srcURL: srcUrl,
            redirectURL: _.get(scan, ['dstUrl'], '--'),
            location: _.get(scan, ['country'], '--'),
            tld: _.get(scan, ['tld'], '--'),
            brand: _.get(scan, ['brandName'], '--'),
            brandId: _.get(scan, ['brandId'], '--'),
            subBrandId: _.get(brandAnalyticData, ['result', 'urlInfo', 0, 'sub_brand_id'], '--'),
            subBrandName: _.get(
              brandAnalyticData,
              ['result', 'urlInfo', 0, 'sub_brand_display_name'],
              '--',
            ),
            networkOwner: _.get(scan, ['networkOwner'], '--'),
            asn: scan['asn'] || '--',
            ip: ip,
            timestamp: _.get(scan, ['timestamp'], '--'),
            createdTS: _.get(scan, ['createdTS'], '--'),
            imagePath: _.get(scan, ['imagePath'], '--'),
            cert: _.get(scan, ['cert', 'issuer'], '--'),
            disposition: _.get(scan, ['disposition'], '--'),
            host: _.get(scan, ['host'], '--'),
            primaryDomain: _.get(scan, ['primary_domain'], '--'),
            countryCode: _.get(scan, ['countryCode'], '--'),
            jobId: _.get(scan, ['jobId'], '--'),
            takedownTS: _.get(scan, ['takedownTS'], '--'),
            takedownCount: _.get(scan, ['takedownCount'], '--'),
            annotations: _.get(scan, ['logoDetection', 'annotations'], []),
            finalCategory: _.get(scan, ['finalCategory'], '--'),
            mxRecords: _.get(scan, ['mxRecords'], '--'),
            hasMxRecords: _.get(scan, ['hasMxRecords'], '--'),
            allMXRecords: _.get(scan, ['allMXRecords'], []),
            registrationDate: _.get(scan, ['registrationDate'], '--'),
            registrant: _.get(scan, ['registrant'], '--'),
            nameservers: _.get(scan, ['nameservers'], '--'),
            sfbDetected: _.has(brandAnalyticData?.result?.urlInfo[0], 'sfb_detected')
              ? _.get(brandAnalyticData, ['result', 'urlInfo', 0, 'sfb_detected'])
                ? 'True'
                : 'False'
              : '--',
            scanSettings: _.has(scan, ['scan_settings'])
              ? {
                  scanLocation: scan.scan_settings?.scan_location || '--',
                  timeout: scan.scan_settings?.wait || '--',
                  userAgent: scan.scan_settings?.user_agent || '--',
                }
              : {},
            url_sha256: this.urlSHA256,
            tags: tagsData?.tags || [],
          },
          certList: _.get(scan, ['cert', 'hosts'], '--'),
          dispute: {
            attachments: disputeData.attachments,
            disposition: disputeData.reporter_disposition,
            bolsterDisposition: disputeData.bolster_disposition,
            status: disputeData.status,
            user: disputeData.user,
            timestamp: disputeData.created_ts,
            comments: disputeData.reporter_comment,
          },
        });

        // if (ip && ip !== '0.0.0.0' && ip !== '--') {
        //   this.dashboardService
        //     .getPassiveDNS(ip)
        //     .then((data: any) => {
        //       this.originPassiveDNS = this.getPassiveDNS(data['result']);
        //       this.setCompState({
        //         passiveDNS: this.originPassiveDNS,
        //       });
        //     })
        //     .catch((err: any) => {
        //       console.log(JSON.stringify(err));
        //     });

        //   this.dashboardService
        //     .getIpPhishCount(ip)
        //     .then((data: any) => {
        //       this.setCompState({
        //         scanResults: {
        //           ...this.state.scanResults,
        //           ipPhishCount: _.get(data, ['result']),
        //         },
        //       });
        //     })
        //     .catch((err: any) => {
        //       console.log(JSON.stringify(err));
        //     });
        // }

        // if (host && host !== '--') {
        //   this.dashboardService
        //     .getRelatedURLsAndIP(host)
        //     .then((data: any) => {
        //       this.originSimilarURL = this.getSimilarURL(_.get(data, ['result', 'uniqURLMap']));
        //       this.setCompState({
        //         similarURL: this.originSimilarURL,
        //       });
        //     })
        //     .catch((err: any) => {
        //       console.log(JSON.stringify(err));
        //     });

        //   this.dashboardService
        //     .getHostPhishCount(host)
        //     .then((data: any) => {
        //       this.setCompState({
        //         scanResults: {
        //           ...this.state.scanResults,
        //           hostPhishCount: _.get(data, ['result']),
        //         },
        //       });
        //     })
        //     .catch((err: any) => {
        //       console.log(JSON.stringify(err));
        //     });

        //   this.dashboardService
        //     .getPhishingKitsCountOnHost(host)
        //     .then((data: any) => {
        //       this.setCompState({
        //         scanResults: {
        //           ...this.state.scanResults,
        //           phishingKitsCountOnHost: _.get(data, ['result']),
        //         },
        //       });
        //     })
        //     .catch((err: any) => {
        //       console.log(JSON.stringify(err));
        //     });
        // } //make it public

        if (!this.isNonBrandCustomer()) {
          this.dashboardService
            .getAbuseContact(srcUrl)
            .then((data: any) => {
              this.setCompState({
                scanResults: {
                  ...this.state.scanResults,
                  abuseContact: _.get(data, ['abuse_email'], '--'),
                },
              });
            })
            .catch(err => {
              console.log(err);
            });
        }

        if (scanContext) {
          ugcService
            .getFinding(scanContext, UgcScanCategoryType[scanCategoryType]) //TODO: update path as per the ugcType eg. social, app_store
            .then(finding => {
              let path = '';
              switch (finding.status) {
                case EFindingStatus.PENDING:
                case EFindingStatus.UNDER_REVIEW:
                  path = SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME;
                  break;
                case EFindingStatus.LIVE:
                  path = SOCIAL_MEDIA_FINDINGS_DETECTION_PATHNAME;
                  break;
                case EFindingStatus.IN_PROGRESS:
                case EFindingStatus.PAUSED:
                  path = SOCIAL_MEDIA_FINDINGS_TAKEDOWN_IN_PROGRESS_PATHNAME;
                  break;
                case EFindingStatus.DOWN:
                  path = SOCIAL_MEDIA_FINDINGS_TAKENDOWN_PATHNAME;
                  break;
                case EFindingStatus.SAFELIST:
                  path = SOCIAL_MEDIA_FINDINGS_SAFE_LIST_PATHNAME;
                  break;
                default:
                  path = SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME;
                  break;
              }
              this.setCompState({
                socialMedia: {
                  url: finding.url,
                  link: `${path}/insights/${scanContext}`,
                  isLoading: false,
                },
              });
            })
            .catch(err => {
              console.log(err);
              this.setCompState({
                socialMedia: {
                  url: DISPLAYED_NULL,
                  link: '',
                  isLoading: false,
                },
              });
            });
        } else {
          this.setCompState({
            socialMedia: {
              url: DISPLAYED_NULL,
              link: '',
              isLoading: false,
            },
          });
        }

        // this.dashboardService
        //   .getGeoLocation(ip)
        //   .then((geoData: any) => {
        //     this.setCompState({
        //       markerPosition: {
        //         lat: _.get(geoData, ['location', 'latitude'], 0),
        //         lng: _.get(geoData, ['location', 'longitude'], 0),
        //       },
        //       scanResults: {
        //         ...this.state.scanResults,
        //         countryCode: _.get(
        //           geoData,
        //           ['country_code'],
        //           this.state.scanResults.countryCode || '--',
        //         ),
        //         location: _.get(geoData, ['country'], this.state.scanResults.location || '--'),
        //       },
        //     });
        //   })
        //   .catch(err => {
        //     console.log(err);
        //   });
      })
      .catch(err => {
        console.log(err);
      });

    if (!this.isNonBrandCustomer()) {
      this.dashboardService
        .getScanSourceInfo(this.urlSHA256)
        .then((data: any) => {
          this.setCompState({
            scanResults: {
              ...this.state.scanResults,
              scanSource: _.get(data, ['result', 'scanSource'], '--'),
              brandScanCount: _.get(data, ['result', 'brandScanCount'], '--'),
              bolsterScanCount: _.get(data, ['result', 'bolsterScanCount'], '--'),
              firstSeenTS: _.get(data, ['result', 'firstSeenTS'], '--'),
            },
          });
        })
        .catch(err => {
          console.log(err);
        });

      this.dashboardService.getBrandInfo('brand').then((info: any) => {
        this.setCompState({
          scanResults: {
            ...this.state.scanResults,
            brandInfo: info,
          },
        });
      });
    }
  }

  setCompState = (newState: any) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  getPassiveDNS(data: any) {
    return Object.keys(data).map(k => ({ domains: k, dispostion: data[k]['disposition'] }));
  }

  toggleWebSideMenu = () => {
    this.setState(oldState => ({
      ...oldState,
      isWebSideMenuOpen: !oldState.isWebSideMenuOpen,
    }));
  };

  getSimilarURL(data: any) {
    if (_.isEmpty(data)) {
      return [];
    }
    return Object.keys(data).map(k => ({
      urls: data[k]['srcUrl'],
      urlSHA256: k,
      disposition: data[k]['disposition'],
      timestamp: data[k]['scanTS'],
    }));
  }

  toggleDisputeModal = () => {
    this.setCompState({
      isDisputeModalShown: !this.state.isDisputeModalShown,
    });
  };

  setDisputeState = (key: string, value: string) => {
    this.setCompState({
      disputeError: '',
      dispute: {
        ...this.state.dispute,
        [key]: value,
      },
    });
  };

  submitDisputeVerdict = () => {
    const scanResults: InsightsDto = this.state.scanResults;
    const { disposition, comments } = this.state.dispute;

    if (!disposition) {
      this.setCompState({ disputeError: 'Please select a disposition.' });
      return;
    }

    const disputeData = new FormData();
    disputeData.append('srcUrl', scanResults.srcURL);
    disputeData.append('urlSHA256', this.urlSHA256);
    disputeData.append('createdTS', scanResults.createdTS);
    disputeData.append('currentDisposition', scanResults.disposition);
    disputeData.append('customerDisposition', disposition);
    disputeData.append('comments', comments || '--');
    for (let i = 0; i < this.uploadedAttachedFilesDispute.length; i++) {
      disputeData.append('files', this.uploadedAttachedFilesDispute[i]);
    }

    this.setCompState({ sendingDisputeRequest: true });
    this.dashboardService
      .submitDisputeVerdict(disputeData)
      .then(dispute => {
        this.setCompState({
          isDisputeModalShown: false,
          dispute: {
            disposition,
            comments,
            status: dispute.status,
            user: dispute.user,
            timestamp: dispute.created_ts,
            attachments: dispute.attachments,
          },
        });
      })
      .catch(err => {
        this.props.alertError(err);
      })
      .finally(() => {
        this.setCompState({
          sendingDisputeRequest: false,
        });
      });
  };

  renderDomWithTooltip = (children: any, tooltip: string) => {
    return (
      <OverlayTrigger
        placement={'bottom'}
        overlay={
          <Tooltip id={'tooltip-' + Math.random()} className={'ask-for-sign-up-tooltip'}>
            {tooltip} (available on Bolster Platform)
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    );
  };

  renderHistory = (
    isResolved: boolean,
    disputeDispositionLabel: string,
    bolsterDisposition: string,
    disputeDisposition: string,
    attachedFiles: {
      name: string;
      path: string;
      createdTS: string;
    }[],
  ) => {
    return (
      <div className={'dispute'}>
        <div
          className={`dispute-submitted-text d-flex align-items-center ${
            this.state.isDisputeDetailShown ? 'open' : ''
          } ${isResolved ? 'history' : ''}`}
          onClick={() =>
            this.setCompState({ isDisputeDetailShown: !this.state.isDisputeDetailShown })
          }
        >
          {isResolved ? (
            <div className='history-wrapper'>
              <div className='icon'>
                <ClockIcon color='black' />
              </div>
              History
            </div>
          ) : (
            <span>
              Disputed as: <span className='dispute-disposition'>{disputeDispositionLabel}</span> -{' '}
              {DISPUTE_STATUS.under_review}
            </span>
          )}
        </div>
        <DisputeStatus
          bolsterDisposition={bolsterDisposition}
          disposition={disputeDisposition}
          status={this.state.dispute.status}
          user={this.state.dispute.user}
          timestamp={this.state.dispute.timestamp}
          comments={this.state.dispute.comments}
          hideHeader={!isResolved}
          attachedFiles={attachedFiles}
        />
      </div>
    );
  };

  renderDisputeDom = () => {
    const { runByClientApp } = this.props;
    const currentDisposition = this.state.scanResults.disposition;
    const bolsterDisposition = this.state.dispute.bolsterDisposition;
    const disputeDisposition = this.state.dispute.disposition;
    const disputeSubmitted = !_.isEmpty(this.state.dispute.status);
    const isResolved = this.state.dispute.status !== 'under_review';
    const icon = getDispositionIcon(currentDisposition);
    const iconContainerClassName = 'dispute-icon-container';
    const dispositionStatusClassName = 'disposition-status';
    let disputeDispositionLabel = '';
    const attachedFiles = this.state.dispute?.attachments?.map((fileName: string) => {
      return {
        name: fileName,
        path: `/api/v1/dispute-verdict/${this.urlSHA256}/attachment/${fileName}`,
        createdTS: moment(this.state.dispute.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      };
    });

    if (disputeDisposition) {
      disputeDispositionLabel = DISPUTE_DISPOSITION_OPTIONS[disputeDisposition];
    }

    return (
      <div className={'dispute-dom-container d-flex align-items-center'}>
        {this.state.scanResults.sfbDetected === 'True' && (
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Tooltip id={'tooltip-' + Math.random()} className={'ask-for-sign-up-tooltip'}>
                Blocked by Google Safebrowsing
              </Tooltip>
            }
          >
            <div className={`${dispositionStatusClassName} sfb-blocked pill`}>SFB Blocked</div>
          </OverlayTrigger>
        )}
        <DispositionStatus
          status={currentDisposition}
          className={dispositionStatusClassName}
          displayedStyle={'pill'}
        />
        {this.isPublic ? (
          this.renderDomWithTooltip(
            <div className={'disabled dispute-button'}>Dispute</div>,
            'Dispute Verdict',
          )
        ) : !disputeSubmitted ? (
          <Button onClick={this.toggleDisputeModal}>Dispute</Button>
        ) : (
          runByClientApp({
            onBolster: () =>
              this.renderHistory(
                isResolved,
                disputeDispositionLabel,
                bolsterDisposition,
                disputeDisposition,
                attachedFiles,
              ),
            onCheckPhish: () => {
              return <div className={'disabled dispute-button'}>Disputed</div>;
            },
          })
        )}
      </div>
    );
  };

  onChangeDnsFilter = (filter: IDropdownOption) => {
    let newPassiveDNS = this.originPassiveDNS.concat();
    if (filter.value === 'other') {
      const blacklist = ['clean', 'phish'];
      newPassiveDNS = _.filter(newPassiveDNS, (item: any) => {
        return blacklist.indexOf(item['dispostion']) === -1;
      });
    } else if (filter.value === 'phish' || filter.value === 'clean') {
      newPassiveDNS = _.filter(newPassiveDNS, (item: any) => {
        return filter.value === item['dispostion'];
      });
    }
    this.setCompState({
      passiveDNSFilter: filter,
      passiveDNS: newPassiveDNS,
    });
  };

  onChangeUrlFilter = (filter: IDropdownOption) => {
    let newSimilarURL = this.originSimilarURL.concat();
    if (filter.value === 'other') {
      const blacklist = ['clean', 'phish'];
      newSimilarURL = _.filter(newSimilarURL, (item: any) => {
        return blacklist.indexOf(item['dispostion']) === -1;
      });
    } else if (filter.value === 'phish' || filter.value === 'clean') {
      newSimilarURL = _.filter(newSimilarURL, (item: any) => {
        return filter.value === item['dispostion'];
      });
    }

    this.setCompState({
      similarURLFilter: filter,
      similarURL: newSimilarURL,
    });
  };

  isNonBrandCustomer() {
    return this.props.user.type_name === 'non_brand' || this.props.loggedIn !== ELoggedIn.true;
  }

  isUpperTierUser() {
    return featureIsAvailable(this.props.user, appConstants.FEATURE_CODE.WEB);
  }

  getDateFormat(date: string | number) {
    if (date === '--') return '--';
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }

  render() {
    const isNonBrand = this.isNonBrandCustomer();
    const isUpperTier = this.isUpperTierUser();

    const {
      isLoading,
      sendingDisputeRequest,
      disputeError,
      scanResults,
      markerPosition,
      passiveDNS,
      passiveDNSFilter,
      similarURL,
      similarURLFilter,
      certList,
      socialMedia,
    } = this.state;
    const ip = scanResults.ip;
    const countryClassName =
      'country-icon flag-icon flag-icon-' + scanResults.countryCode.toLowerCase();
    const ipDom =
      _.isEmpty(ip) || ip === '0.0.0.0' || ip === '--' ? (
        <LabelAndValue label={'IP Address'} value={'--'} direction={'column'} />
      ) : (
        <LabelAndValue
          label={'IP Address'}
          renderDom={
            <Link
              to={getNavPathnameForInsightPage() + '/insights/ip/' + ip}
              className={'height-20 ip-url-value-wrapper'}
            >
              {ip}
            </Link>
          }
          direction={'column'}
        />
      );
    const certDom =
      scanResults.cert === '--' ? (
        <LabelAndValue
          label={'Certificate Details'}
          renderDom={
            <div className='card-value card-text long-text-ellipsis-2'>
              <div>{scanResults.cert}</div>
              <div>{'  '}</div>
            </div>
          }
          direction={'column'}
        />
      ) : (
        <LabelAndValue
          label={'Certificate Details'}
          value={scanResults.cert + ': ' + certList.toString()}
          direction={'column'}
          longTextLineNumberLimit={2}
        />
      );

    const DISPUTE_OPTIONS = [];
    for (const value in DISPUTE_DISPOSITION_OPTIONS) {
      DISPUTE_OPTIONS.push({ label: DISPUTE_DISPOSITION_OPTIONS[value], value });
    }
    const renderAllMXRecords = (allMXRecords: any[]) => {
      const result = _.map(allMXRecords, record => {
        return record.exchange;
      });
      return result.join('; ') || '--';
    };

    const { selectedTheme } = this.context;

    const renderNetworkProvider = (networkProvider: any) => {
      const isCommunity = this.props.runByClientApp({
        onBolster: () => false,
        onCheckPhish: () => true,
      });
      const { link } = mapNetworkProviderToLink(networkProvider);
      if (isCommunity && link) {
        return (
          <div className='network-wrapper'>
            <a href={link}>{networkProvider}</a>
            <ToolTip
              id='network-provider-tooltip'
              tooltip={'Visit CheckPhish community to get step by step guides take-down'}
              customClassName='blue-content-tooltip'
            >
              <img src={moreInfoIcon} alt='more info' className='more-info-icon' />
            </ToolTip>
          </div>
        );
      }
      return <p>{networkProvider}</p>;
    };
    return (
      <>
        {this.state.isWebSideMenuOpen && (
          <WebSideMenu
            isOpen={this.state.isWebSideMenuOpen}
            items={TAB_ITEMS_HEADER}
            close={this.toggleWebSideMenu}
          />
        )}
        <WebHeader tabItems={TAB_ITEMS_HEADER} toggle={this.toggleWebSideMenu} />

        <AuthenticationWrapper skipAuthentication={this.isPublic}>
          <Modal
            show={this.state.isDisputeModalShown}
            onHide={this.toggleDisputeModal}
            className={`${selectedTheme}`}
          >
            <Modal.Header closeButton={false}>
              <Modal.Title>Dispute Disposition</Modal.Title>
              <div className='close' onClick={this.toggleDisputeModal}>
                <ClearRoundedIcon />
              </div>
            </Modal.Header>
            <Modal.Body>
              {disputeError && <div className={'mb-2 text-danger'}>{disputeError}</div>}
              <RadioButtons
                label='Please select what you believe to be the correct disposition.'
                defaultValue={this.state.dispute.disposition}
                options={DISPUTE_OPTIONS}
                onChange={disposition => this.setDisputeState('disposition', disposition)}
                direction={'column'}
              />
              <AttachFile
                label='Attach File'
                onChangeHandler={files => (this.uploadedAttachedFilesDispute = files)}
              />
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as='textarea'
                defaultValue={this.state.dispute.comments}
                maxLength={COMMENT_MAX_LENGTH}
                className={'playbook-description-input'}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  this.setDisputeState('comments', e.target.value);
                }}
              />
              <Form.Text>
                {COMMENT_MAX_LENGTH - (this.state.dispute.comments?.length || 0)} characters
                remaining.
              </Form.Text>
            </Modal.Body>
            <Modal.Footer>
              <ButtonRB variant='outline-secondary' onClick={this.toggleDisputeModal}>
                Cancel
              </ButtonRB>
              <ButtonRB
                variant='primary'
                onClick={this.submitDisputeVerdict}
                disabled={sendingDisputeRequest}
              >
                {sendingDisputeRequest ? (
                  <Spinner className='spinner' animation='border' variant='light' size='sm' />
                ) : (
                  'Submit'
                )}
              </ButtonRB>
            </Modal.Footer>
          </Modal>
          <PageTitleInsights
            title={scanResults.srcURL}
            toolsLeft={this.renderDisputeDom()}
            backFn={this.isPublic ? _.noop : history.goBack}
            tools={
              this.isPublic ? (
                <div className={'timeline-and-whois d-flex justify-content-space-between'}>
                  {this.props.loggedIn === ELoggedIn.false && (
                    <React.Fragment>
                      <Link
                        to={{
                          pathname: `/sign-up`,
                          search: `?brand=${scanResults.brand}`,
                        }}
                      >
                        <ButtonRB variant='primary' className='mr-3'>
                          SIGN UP FOR COMPLETE BOLSTER PLATFORM
                        </ButtonRB>
                      </Link>
                      <Link
                        to={{
                          pathname: `/sign-in`,
                          search: `?brand=${scanResults.brand}`,
                        }}
                      >
                        <ButtonRB variant='primary' className='mr-3'>
                          LOGIN
                        </ButtonRB>
                      </Link>
                    </React.Fragment>
                  )}
                  {this.renderDomWithTooltip(
                    <div className='disabled btn btn-light whois-info-button web-only'>
                      DOM TREE
                    </div>,
                    'Dom Tree',
                  )}
                  {this.renderDomWithTooltip(
                    <div className='disabled btn btn-light whois-info-button web-only'>
                      TIMELINE
                    </div>,
                    'Timeline',
                  )}
                  {this.renderDomWithTooltip(
                    <div className='disabled btn btn-light whois-info-button web-only'>
                      VIEW WHOIS INFORMATION
                    </div>,
                    'Whois Information',
                  )}
                </div>
              ) : (
                <div className={'timeline-and-whois d-flex justify-content-between'}>
                  <Link
                    to={{
                      pathname: `${getNavPathnameForInsightPage()}/dom`,
                      state: {
                        timestamp: this.timestamp,
                        urlSHA256: this.urlSHA256,
                        type: 'web',
                      },
                    }}
                    className='btn btn-light whois-info-button'
                  >
                    DOM TREE
                  </Link>
                  {!isNonBrand && (
                    <Link
                      to={{
                        pathname: `${getNavPathnameForInsightPage()}/timeline`,
                        state: {
                          ip: scanResults.ip,
                          host: scanResults.primaryDomain,
                          urlSHA256: this.urlSHA256,
                        },
                      }}
                      className='btn btn-light whois-info-button'
                    >
                      TIMELINE
                    </Link>
                  )}
                  <Link
                    to={{
                      pathname: `${getNavPathnameForInsightPage()}/whois`,
                      state: { ip: scanResults.ip, host: scanResults.primaryDomain },
                    }}
                    className='btn btn-light whois-info-button web-only'
                  >
                    VIEW WHOIS INFORMATION
                  </Link>
                  <Link
                    to={{
                      pathname: '/dashboard/whois',
                      state: { ip: scanResults.ip, host: scanResults.primaryDomain },
                    }}
                    className='btn btn-light whois-info-button mobile-only'
                  >
                    WHOIS INFO
                  </Link>
                </div>
              )
            }
          />
          {isLoading ? (
            <div className={'insight-spinner-container'}>
              <Spinner animation='border' />
            </div>
          ) : (
            <div className='page-content insights-container'>
              {/* ----START: Scan Results---- */}
              <Row>
                <Col md={6} className='self-6'>
                  <div>
                    <Card bg='light' className='card-between'>
                      {this.props.loggedIn === ELoggedIn.true && (
                        <Tags rowData={scanResults} type={this.props.type} />
                      )}
                      <Card.Header className='self-card-header'>Scan Results</Card.Header>
                      <Card.Body className='self-card-body'>
                        <Row>
                          <Col sm={6}>
                            <LabelAndValue
                              label={'Source URL'}
                              value={scanResults.srcURL}
                              direction={'column'}
                              longTextLineNumberLimit={1}
                              copyButton
                            />
                            <LabelAndValue
                              label={'Redirected URL'}
                              value={scanResults.redirectURL}
                              direction={'column'}
                              longTextLineNumberLimit={1}
                            />
                            {ipDom}
                            {!isNonBrand && (
                              <LabelAndValue
                                label={'Scan Source'}
                                value={scanResults.scanSource}
                                direction={'column'}
                              />
                            )}
                            <LabelAndValue
                              label={'Insight Page of Social Media Finding'}
                              value={socialMedia.url}
                              copyButton={
                                !socialMedia.isLoading && socialMedia.url !== DISPLAYED_NULL
                              }
                              renderDom={
                                socialMedia.isLoading ? (
                                  <Spinner
                                    className='spinner'
                                    animation='border'
                                    variant='primary'
                                    size='sm'
                                  />
                                ) : socialMedia.url === DISPLAYED_NULL ? (
                                  '--'
                                ) : (
                                  <Link
                                    to={socialMedia.link}
                                    className='card-value ip-url-value-wrapper long-text-ellipsis-1'
                                  >
                                    {socialMedia.url}
                                  </Link>
                                )
                              }
                              direction={'column'}
                              longTextLineNumberLimit={1}
                            />
                            {!isNonBrand && (
                              <LabelAndValue
                                label={'# Customer Scans'}
                                value={scanResults.brandScanCount}
                                direction={'column'}
                              />
                            )}
                            {!isNonBrand && (
                              <LabelAndValue
                                label={'# Bolster Scans'}
                                value={scanResults.bolsterScanCount}
                                direction={'column'}
                              />
                            )}
                            {isNonBrand && (
                              <LabelAndValue
                                label='Detection Date'
                                value={this.getDateFormat(scanResults.createdTS)}
                                direction={'column'}
                                longTextLineNumberLimit={1}
                              />
                            )}
                            {isNonBrand && (
                              <LabelAndValue
                                copyButton
                                label={'Job ID'}
                                value={scanResults.jobId}
                                direction={'column'}
                                longTextLineNumberLimit={1}
                              />
                            )}
                            {certDom}
                            {isUpperTier && (
                              <>
                                <LabelAndValue
                                  label={'Category'}
                                  value={scanResults.finalCategory}
                                  direction={'column'}
                                  longTextLineNumberLimit={1}
                                />
                                <LabelAndValue
                                  label={'# MX Records'}
                                  value={scanResults.mxRecords}
                                  direction={'column'}
                                />
                                <LabelAndValue
                                  label={'List of MX Records'}
                                  value={renderAllMXRecords(scanResults.allMXRecords)}
                                  direction={'column'}
                                />
                              </>
                            )}
                            {}
                          </Col>
                          <Col sm={6}>
                            <LabelAndValue
                              label={'Brand'}
                              value={
                                scanResults.brand.toLowerCase() === 'unknown' ||
                                scanResults.brand === '--'
                                  ? scanResults.subBrandName
                                  : scanResults.brand
                              }
                              direction={'column'}
                            />
                            <LabelAndValue
                              label={'TLD'}
                              value={scanResults.tld}
                              direction={'column'}
                            />
                            {scanResults.location === '--' ? (
                              <LabelAndValue
                                label={'Location'}
                                value={scanResults.location}
                                direction={'column'}
                              />
                            ) : (
                              <LabelAndValue
                                label={'Location'}
                                renderDom={
                                  <div className='dom-value'>
                                    <span className={countryClassName} />
                                    <span className='card-value card-text long-text-ellipsis-1'>
                                      {scanResults.location}
                                    </span>
                                  </div>
                                }
                                direction={'column'}
                              />
                            )}
                            <LabelAndValue
                              label={'Hosting Provider'}
                              renderDom={renderNetworkProvider(scanResults.networkOwner)}
                              direction={'column'}
                              longTextLineNumberLimit={1}
                            />
                            <LabelAndValue
                              label={'ASN'}
                              value={scanResults.asn}
                              direction={'column'}
                              longTextLineNumberLimit={1}
                            />
                            {isUpperTier && (
                              <>
                                <LabelAndValue
                                  label={'Registration Date'}
                                  value={this.getDateFormat(scanResults.registrationDate)}
                                  direction={'column'}
                                  longTextLineNumberLimit={1}
                                />
                                <LabelAndValue
                                  label={'Registrant'}
                                  value={scanResults.registrant}
                                  direction={'column'}
                                />
                                <LabelAndValue
                                  label={'Nameservers'}
                                  value={scanResults.nameservers}
                                  direction={'column'}
                                />
                                <LabelAndValue
                                  label={'Safe Browsing Blocked'}
                                  value={scanResults.sfbDetected}
                                  direction={'column'}
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>

                  <div>
                    <Card bg='light' className='card-between'>
                      <Card.Header className='self-card-header'>Scan Settings</Card.Header>
                      <Card.Body className='self-card-body'>
                        <ScanSettings
                          scanInfo={scanResults}
                          scanSettings={scanResults.scanSettings}
                          user={this.props.user}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                  <div>
                    <Card bg='light' className='card-between'>
                      <Card.Header>Threat Intelligence</Card.Header>
                      <div className='insight-threat-component'>
                        <ThreatIntelligence
                          hostPhishCount={scanResults.hostPhishCount}
                          ipPhishCount={scanResults.ipPhishCount}
                          phishingKitsCountOnHost={scanResults.phishingKitsCountOnHost}
                          urlSHA256={this.urlSHA256}
                          ip={ip}
                        />
                      </div>
                    </Card>
                  </div>
                  {/* ----START: Passive DNS---- */}
                  <Card bg='light'>
                    <Table
                      tableClassName='tool-bar-container-padding'
                      disableDatePicker={true}
                      disableFilter={true}
                      data={ip === '0.0.0.0' || ip === '--' || ip === '' ? undefined : passiveDNS}
                      title={'Passive DNS'}
                      columns={PASSIVE_DNS_COLUMNS}
                      customTools={renderDispositionFilter(
                        passiveDNSFilter,
                        this.onChangeDnsFilter,
                      )}
                    />
                  </Card>
                  {/* ----END: Passive DNS--- */}

                  {/* ----START: Similar URLs---- */}
                  <Card bg='light'>
                    <Table
                      tableClassName='tool-bar-container-padding'
                      disableDatePicker={true}
                      disableFilter={true}
                      data={ip === '0.0.0.0' || ip === '--' || ip === '' ? undefined : similarURL}
                      title={'Similar URLs'}
                      columns={SIMILAR_URLS_COLUMNS}
                      customTools={renderDispositionFilter(
                        similarURLFilter,
                        this.onChangeUrlFilter,
                      )}
                    />
                  </Card>
                  {/* ----END: Similar URLs---- */}
                </Col>
                <Col md={6} className='self-6'>
                  <div>
                    <Annotation
                      className={'img-fluid ' + (isNonBrand ? 'non-brand' : '')}
                      imagePath={getScreenshotUrl(scanResults.imagePath)}
                      annotations={scanResults.annotations}
                      brandMnemonic={isNonBrand ? '' : scanResults.brandId}
                      alt='scan result screenshot'
                    />
                  </div>
                  <div>
                    {/* ----START: Geo Location---- */}
                    <Card bg='light' className='card-between geo-card'>
                      <Card.Header>Geo Location</Card.Header>
                      <Map
                        markerPosition={markerPosition}
                        location={scanResults.location}
                        className={isNonBrand ? 'non-brand' : ''}
                      />
                    </Card>
                    {/* ----END: Geo Location---- */}
                  </div>
                </Col>
              </Row>
              {/* ----END: Scan Results---- */}
            </div>
          )}
        </AuthenticationWrapper>
        <WebFooter tabItems={TAB_ITEMS_FOOTER} tabItemsL2={TAB_ITEMS_FOOTER_L2} />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  const { loggedIn } = state.login;
  return {
    user,
    loggedIn,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  runByClientApp: appActions.runByClientApp,
};

const connectedInsights = connect(mapStateToProps, mapDispatchToProps)(PublicInsights);
export { connectedInsights as PublicInsights };
