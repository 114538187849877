import { useAppSelector } from '../../helpers/hooks';

import {
  getIsAppStoreModuleAvailable,
  getIsDarkWebModuleAvailable,
  getIsSocialMediaModuleAvailable,
  getIsWebModuleAvailable,
} from '../../helpers/permissions';

export default function useOnReportsModuleOptions() {
  const user = useAppSelector(state => state.dashboardReducer.user);
  const ouDefaultBrandOptions: { value: string; label: string }[] = [
    {
      value: '',
      label: `${user?.brand_info?.brand?.displayName}`,
    },
  ];
  const orgBrandOptions: { value: string; label: string }[] | undefined =
    user?.orgInfo?.orgUnits?.map((item: any): { value: string; label: string } => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const moduleTypesOptions = [];

  if (getIsWebModuleAvailable(user)) {
    moduleTypesOptions.push({ label: 'Web', value: 'web' });
  }
  if (getIsSocialMediaModuleAvailable(user)) {
    moduleTypesOptions.push({ label: 'Social Media', value: 'social' });
  }
  if (getIsDarkWebModuleAvailable(user)) {
    moduleTypesOptions.push({ label: 'Dark Web', value: 'dark-web' });
  }
  if (getIsAppStoreModuleAvailable(user)) {
    moduleTypesOptions.push({ label: 'App Store', value: 'app-store' });
  }
  const selectedBrandGroupOptions =
    orgBrandOptions && orgBrandOptions?.length > 0
      ? [...ouDefaultBrandOptions, ...orgBrandOptions]
      : ouDefaultBrandOptions;
  return {
    moduleTypesOptions,
    selectedBrandGroupOptions,
  };
}
