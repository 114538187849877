import moment from 'moment';
import { appConstants } from '../../constants';
import { getDefaultEndDate } from '../Common/Table/ag-table/constants';
import { ISelectedOptions } from './AdvancedFilters';
import {
  advancedSearchCategories,
  firstSeenFieldTypeMapping,
  moduleTypeAndFieldNameAttributes,
  moduleTypeMapping,
  statusModuleFieldNameAttributes,
} from './AdvancedFiltersConstants';

interface statusFieldTypeParams {
  moduleType: string;
  fieldName: string;
  value: string;
}

interface firstSeenFieldTypeParams {
  moduleType: string;
  fieldName: string;
}

export interface AdvancedFilterParams {
  searchTerm: string;
  searchType: string;
  searchParams?: { moduleType: string; fieldName: string }[];
  fieldName?: string;
  disposition?: string;
  scanSource?: string;
  status?: string;
  firstSeenStartDate?: string;
  firstSeenEndDate?: string;
  timeZone?: string;
  moduleType?: string[];
  statusFieldType?: statusFieldTypeParams[];
  firstSeenFieldType?: firstSeenFieldTypeParams[];
}

export const transformAdvancedSearchOptionsToParams = (
  searchValue: string,
  selectedOptions: ISelectedOptions,
) => {
  const selectedOptionsMap: { [key: string]: string } = {
    'What are you searching for': 'fieldName',
    'Scan Source': 'scanSource',
    Module: 'moduleType',
  };

  const selectedParams: AdvancedFilterParams = {
    searchTerm: searchValue,
    searchType: selectedOptions[advancedSearchCategories.COLUMNS] as string,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  Object.keys(selectedOptions).forEach(ele => {
    if (ele === advancedSearchCategories.FIRST_SEEN_DATE) {
      const value = selectedOptions[ele] as string;
      const [firstValue, secondValue] = value?.split(' ');
      selectedParams['firstSeenStartDate'] = moment()
        .subtract(parseInt?.(firstValue), secondValue as moment.unitOfTime.DurationConstructor)
        .format('YYYY-MM-DD');
      selectedParams['firstSeenEndDate'] = getDefaultEndDate();
      const selectedModule = selectedOptions[advancedSearchCategories.MODULE];
      selectedParams['firstSeenFieldType'] =
        firstSeenFieldTypeMapping[selectedModule] ||
        Object.values(firstSeenFieldTypeMapping).flat();
    } else if (ele === advancedSearchCategories.STATUS) {
      selectedParams['status'] = selectedOptions[ele];
      const statusFieldTypes = getStatusModuleFieldNames(selectedOptions);
      selectedParams['statusFieldType'] = statusFieldTypes;
      selectedParams['moduleType'] = statusFieldTypes?.map(item => item.moduleType);
    } else if (
      ele === advancedSearchCategories.MODULE &&
      !selectedOptions[advancedSearchCategories.STATUS]
    ) {
      selectedParams['moduleType'] = getModuleTypes(selectedOptions);
    } else {
      const searchParamsBasedOnCol = getSearchParamsBasedOnColSeletion(selectedOptions);
      selectedParams['searchParams'] = searchParamsBasedOnCol;
      const selectedCategory = selectedOptionsMap[ele] || ele.toLowerCase();
      selectedParams[selectedCategory] = selectedOptions[ele];
    }
  });
  return selectedParams;
};

export const getSearchParamsBasedOnColSeletion = (selectedOptions: ISelectedOptions) => {
  const selectedModule = selectedOptions[advancedSearchCategories.MODULE];
  const selectedFieldName = selectedOptions[advancedSearchCategories.COLUMNS] as string;
  const searchParams: { moduleType: string; fieldName: string }[] = [];
  const moduleTypes = getModuleTypes(selectedOptions);
  Object.keys(moduleTypeAndFieldNameAttributes).forEach(key => {
    const options = moduleTypeAndFieldNameAttributes[key];
    options.forEach((item: { moduleType: string; fieldName: string }) => {
      const matchesFieldName = selectedFieldName === key;
      const matchesModule = moduleTypes.includes(item.moduleType);

      if (matchesFieldName && (selectedModule ? matchesModule : true)) {
        searchParams.push({
          moduleType: item.moduleType,
          fieldName: item.fieldName,
        });
      }
    });
  });
  return searchParams;
};

export const getModuleTypes = (selectedOptions: ISelectedOptions) => {
  const selectedModule = selectedOptions[advancedSearchCategories.MODULE];
  const selectedFieldName = selectedOptions[advancedSearchCategories.COLUMNS] as string;
  const moduleTypes: string[] = moduleTypeMapping[selectedFieldName]?.[selectedModule] || [
    selectedModule,
  ];
  return moduleTypes;
};

export const getStatusModuleFieldNames = (selectedOptions: ISelectedOptions) => {
  const selectedStatus = selectedOptions[advancedSearchCategories.STATUS];
  const selectedModuleType = selectedOptions[advancedSearchCategories.MODULE];
  const moduleAndstatusData = statusModuleFieldNameAttributes[selectedStatus];
  if (selectedModuleType) {
    const statusFields = moduleAndstatusData?.[selectedModuleType];
    return [
      {
        ...statusFields,
        moduleType:
          selectedModuleType === appConstants.CONTENT_TYPE.WEB
            ? appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER
            : selectedModuleType,
      },
    ];
  } else {
    return Object.keys(moduleAndstatusData).map(moduleType => ({
      ...moduleAndstatusData[moduleType],
      moduleType:
        moduleType === appConstants.CONTENT_TYPE.WEB
          ? appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER
          : moduleType,
    }));
  }
};
