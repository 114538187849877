import { appConstants } from '../../constants';
import { AgDateRangeFilterRanges } from '../Common/Table/ag-table/constants';
import { EGTDTakedownStatus } from '../GlobalTakedown/GlobalTakeDownUtils';
import { ESelectedFilterTypeOptions } from './GlobalSearchConstants';

export enum advancedSearchCategories {
  COLUMNS = 'What are you searching for',
  MODULE = 'Module',
  DISPOSITION = 'Disposition',
  SCAN_SOURCE = 'Scan Source',
  STATUS = 'Status',
  FIRST_SEEN_DATE = 'First Seen Date',
}

export enum advancedSearchOptions {
  PHISH = 'phish',
  SCAM = 'scam',
  SUSPICIOUS = 'suspicious',
  CLEAN = 'clean',
  EXHAUSTED = 'exhausted',
  AWATING_DOCUMENTS = 'awaiting_documents',
  ACTIVE = 'active',
  SCAN_SOURCE_BOLSTER = 'bolster',
  ONE_DAY = '1 day',
  SEVEN_DAYS = '7 days',
  THIRTY_DAYS = '30 days',
  NINTY_DAYS = '90 days',
  ONE_EIGHTY_DAYS = '180 days',
  TWELVE_MONTHS = '12 months',
}

export const advancedSearchColumns = [
  { label: 'URL', value: ESelectedFilterTypeOptions.URL },
  { label: 'IP Address', value: ESelectedFilterTypeOptions.IP_ADDRESS },
  { label: 'Domain', value: ESelectedFilterTypeOptions.DOMAIN },
  { label: 'Email Address', value: ESelectedFilterTypeOptions.EMAIL_ADDRESS },
  { label: 'Credit Card Number', value: ESelectedFilterTypeOptions.CCN },
];

export const advancedSearchModules = [
  { label: 'Web', value: appConstants.CONTENT_TYPE.WEB },
  { label: 'Social Media', value: appConstants.CONTENT_TYPE.SOCIAL },
  { label: 'App Store', value: appConstants.CONTENT_TYPE.APP_STORE },
  { label: 'Dark Web', value: appConstants.CONTENT_TYPE.DARK_WEB },
];

export const advancedSearchDispositions = [
  { label: 'Phish', value: advancedSearchOptions.PHISH },
  { label: 'Scam', value: advancedSearchOptions.SCAM },
  { label: 'Suspicious', value: advancedSearchOptions.SUSPICIOUS },
  { label: 'Clean', value: advancedSearchOptions.CLEAN },
];

export const advancedSearchFirstSeenDates = [
  { label: 'Last 1 Day', value: '1 day' },
  { label: 'Last 7 Days', value: '7 days' },
  { label: 'Last 30 Days', value: '30 days' },
  { label: 'Last 90 Days', value: '90 days' },
  { label: 'Last 180 Days', value: '180 days' },
  { label: 'Last 12 Months', value: '12 months' },
];

export const advancedScanSources = [
  { label: 'By Bolster', value: advancedSearchOptions.SCAN_SOURCE_BOLSTER },
];

export const advancedScanStatus = [
  { label: 'In Progress', value: EGTDTakedownStatus.IN_PROGRESS },
  { label: 'Takendown', value: EGTDTakedownStatus.TAKEN_DOWN },
  { label: 'Exhausted', value: advancedSearchOptions.EXHAUSTED },
  { label: 'Awaiting Documents', value: advancedSearchOptions.AWATING_DOCUMENTS },
  { label: 'Active', value: advancedSearchOptions.ACTIVE },
];

export const advancedSearchCategoriesAndOptions: Record<
  string,
  { label: string; value: string }[]
> = {
  [advancedSearchCategories.COLUMNS]: advancedSearchColumns,
  [advancedSearchCategories.MODULE]: advancedSearchModules,
  [advancedSearchCategories.DISPOSITION]: advancedSearchDispositions,
  [advancedSearchCategories.FIRST_SEEN_DATE]: advancedSearchFirstSeenDates,
  [advancedSearchCategories.SCAN_SOURCE]: advancedScanSources,
  [advancedSearchCategories.STATUS]: advancedScanStatus,
};

//DISABLED OPTIONS MAPPING - options which are not included are automatically considered present and are enabled
export const columnOptionsDisabledMapping = {
  [ESelectedFilterTypeOptions.URL]: [appConstants.CONTENT_TYPE.DARK_WEB],
  [ESelectedFilterTypeOptions.IP_ADDRESS]: [
    appConstants.CONTENT_TYPE.SOCIAL,
    appConstants.CONTENT_TYPE.DARK_WEB,
  ],
  [ESelectedFilterTypeOptions.DOMAIN]: [
    appConstants.CONTENT_TYPE.SOCIAL,
    appConstants.CONTENT_TYPE.APP_STORE,
    appConstants.CONTENT_TYPE.DARK_WEB,
  ],
  [ESelectedFilterTypeOptions.EMAIL_ADDRESS]: [
    appConstants.CONTENT_TYPE.WEB,
    appConstants.CONTENT_TYPE.SOCIAL,
    appConstants.CONTENT_TYPE.APP_STORE,
    appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
    advancedSearchOptions.PHISH,
    advancedSearchOptions.SCAM,
    advancedSearchOptions.SUSPICIOUS,
    advancedSearchOptions.CLEAN,
    advancedSearchOptions.SCAN_SOURCE_BOLSTER,
    EGTDTakedownStatus.IN_PROGRESS,
    EGTDTakedownStatus.TAKEN_DOWN,
    advancedSearchOptions.EXHAUSTED,
    advancedSearchOptions.AWATING_DOCUMENTS,
  ],
  [ESelectedFilterTypeOptions.CCN]: [
    appConstants.CONTENT_TYPE.WEB,
    appConstants.CONTENT_TYPE.SOCIAL,
    appConstants.CONTENT_TYPE.APP_STORE,
    appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
    advancedSearchOptions.PHISH,
    advancedSearchOptions.SCAM,
    advancedSearchOptions.SUSPICIOUS,
    advancedSearchOptions.CLEAN,
    advancedSearchOptions.SCAN_SOURCE_BOLSTER,
    EGTDTakedownStatus.IN_PROGRESS,
    EGTDTakedownStatus.TAKEN_DOWN,
    advancedSearchOptions.EXHAUSTED,
    advancedSearchOptions.AWATING_DOCUMENTS,
  ],
};

//DISABLED OPTIONS MAPPING - options which are not included are automatically considered present and are enabled
export const moduleOptionsDisabledMapping = {
  [appConstants.CONTENT_TYPE.WEB]: [advancedSearchOptions.ACTIVE],
  [appConstants.CONTENT_TYPE.SOCIAL]: [
    advancedSearchOptions.EXHAUSTED,
    advancedSearchOptions.AWATING_DOCUMENTS,
    advancedSearchOptions.ACTIVE,
    advancedSearchOptions.PHISH,
    advancedSearchOptions.SCAM,
    advancedSearchOptions.SUSPICIOUS,
    advancedSearchOptions.CLEAN,
  ],
  [appConstants.CONTENT_TYPE.APP_STORE]: [
    advancedSearchOptions.EXHAUSTED,
    advancedSearchOptions.AWATING_DOCUMENTS,
    advancedSearchOptions.ACTIVE,
    advancedSearchOptions.PHISH,
    advancedSearchOptions.SCAM,
    advancedSearchOptions.SUSPICIOUS,
    advancedSearchOptions.CLEAN,
  ],
  [appConstants.CONTENT_TYPE.DARK_WEB]: [
    advancedSearchOptions.PHISH,
    advancedSearchOptions.SCAM,
    advancedSearchOptions.SUSPICIOUS,
    advancedSearchOptions.CLEAN,
    EGTDTakedownStatus.IN_PROGRESS,
    EGTDTakedownStatus.TAKEN_DOWN,
    advancedSearchOptions.EXHAUSTED,
    advancedSearchOptions.AWATING_DOCUMENTS,
  ],
};

export const moduleTypeAndFieldNameAttributes = {
  [ESelectedFilterTypeOptions.URL]: [
    {
      moduleType: appConstants.CONTENT_TYPE.WEB,
      fieldName: 'src_url',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.SOCIAL,
      fieldName: 'url',
    },
    {
      moduleType: 'bulk-scan',
      fieldName: 'src_url',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.APP_STORE,
      fieldName: 'src_url',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.ASSESTS,
      fieldName: 'url',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
      fieldName: 'src_url',
    },
  ],
  [ESelectedFilterTypeOptions.IP_ADDRESS]: [
    {
      moduleType: appConstants.CONTENT_TYPE.WEB,
      fieldName: 'ip',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.ASSESTS,
      fieldName: 'ip_address',
    },
    {
      moduleType: 'bulk-scan',
      fieldName: 'ip',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.APP_STORE,
      fieldName: 'ip',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
      fieldName: 'ip',
    },
  ],
  [ESelectedFilterTypeOptions.DOMAIN]: [
    {
      moduleType: appConstants.CONTENT_TYPE.WEB,
      fieldName: 'primary_domain',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
      fieldName: 'primary_domain',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.ASSESTS,
      fieldName: 'asset_name',
    },
    {
      moduleType: appConstants.CONTENT_TYPE.ASSESTS,
      fieldName: 'domain_name',
    },
  ],
  [ESelectedFilterTypeOptions.EMAIL_ADDRESS]: [
    {
      moduleType: appConstants.CONTENT_TYPE.DARK_WEB,
      fieldName: 'email',
    },
  ],
  [ESelectedFilterTypeOptions.CCN]: [
    {
      moduleType: appConstants.CONTENT_TYPE.DARK_WEB,
      fieldName: 'ccn',
    },
  ],
};

export const statusModuleFieldNameAttributes = {
  [EGTDTakedownStatus.IN_PROGRESS]: {
    [appConstants.CONTENT_TYPE.WEB]: {
      fieldName: 'global_takedown_status',
      value: 'pending',
    },
    [appConstants.CONTENT_TYPE.SOCIAL]: {
      fieldName: 'status',
      value: 'IN_PROGRESS',
    },
    [appConstants.CONTENT_TYPE.APP_STORE]: {
      fieldName: 'status',
      value: 'IN_PROGRESS',
    },
  },
  [EGTDTakedownStatus.TAKEN_DOWN]: {
    [appConstants.CONTENT_TYPE.WEB]: {
      fieldName: 'global_takedown_status',
      value: 'complete',
    },
    [appConstants.CONTENT_TYPE.SOCIAL]: {
      fieldName: 'status',
      value: 'DOWN',
    },
    [appConstants.CONTENT_TYPE.APP_STORE]: {
      fieldName: 'status',
      value: 'DOWN',
    },
  },
  [advancedSearchOptions.EXHAUSTED]: {
    [appConstants.CONTENT_TYPE.WEB]: {
      fieldName: 'global_takedown_status',
      value: 'exhausted',
    },
  },
  [advancedSearchOptions.AWATING_DOCUMENTS]: {
    [appConstants.CONTENT_TYPE.WEB]: {
      fieldName: 'global_takedown_status',
      value: 'awaiting_documents',
    },
  },
  [advancedSearchOptions.ACTIVE]: {
    [appConstants.CONTENT_TYPE.DARK_WEB]: {
      fieldName: 'status',
      value: 'ACTIVE',
    },
  },
};

export const moduleTypeMapping = {
  [ESelectedFilterTypeOptions.URL]: {
    [appConstants.CONTENT_TYPE.WEB]: [
      appConstants.CONTENT_TYPE.WEB,
      'bulk-scan',
      appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
    ],
  },
  [ESelectedFilterTypeOptions.IP_ADDRESS]: {
    [appConstants.CONTENT_TYPE.WEB]: [
      appConstants.CONTENT_TYPE.WEB,
      'bulk-scan',
      appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
    ],
  },
  [ESelectedFilterTypeOptions.DOMAIN]: {
    [appConstants.CONTENT_TYPE.WEB]: [
      appConstants.CONTENT_TYPE.WEB,
      appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER,
    ],
  },
};

export const redirectUrlModuleTypeMap = {
  [appConstants.CONTENT_TYPE.WEB]: 'web-dst-url',
  [appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]: 'takedown-visibility-center-dst-url',
};

export const firstSeenFieldTypeMapping = {
  [appConstants.CONTENT_TYPE.WEB]: [
    {
      moduleType: appConstants.CONTENT_TYPE.WEB,
      fieldName: 'first_seen_ts',
    },
    {
      moduleType: 'bulk-scan',
      fieldName: 'first_seen_ts',
    },
  ],
  [appConstants.CONTENT_TYPE.SOCIAL]: [
    {
      moduleType: appConstants.CONTENT_TYPE.SOCIAL,
      fieldName: 'effective_ts',
    },
  ],
  [appConstants.CONTENT_TYPE.APP_STORE]: [
    {
      moduleType: appConstants.CONTENT_TYPE.APP_STORE,
      fieldName: 'first_seen_ts',
    },
  ],
  [appConstants.CONTENT_TYPE.DARK_WEB]: [
    {
      moduleType: appConstants.CONTENT_TYPE.DARK_WEB,
      fieldName: 'platform_published_ts',
    },
  ],
};
