import React from 'react';
import { Grid } from '@mui/material';
import { LabelAndValue } from '../../Common/LabelAndValue';
import './ThreatTypeInsights.scss';
import { MuiCardWrapper } from '../../Common/MuiCardWrapper';
import { IThreatTypeDetails } from '../constants';

interface AttachmentDetailsAnalysisProps {
  attachmentDetails: IThreatTypeDetails;
}

export const AttachmentAnalysis: React.FC<AttachmentDetailsAnalysisProps> = ({
  attachmentDetails,
}) => {
  return (
    <div data-testid='attachment-analysis'>
      <MuiCardWrapper cardTitle='Threat Overview'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
            <LabelAndValue
              label={'Attachment SHA'}
              value={attachmentDetails.sourceUrl}
              direction={'column'}
              longTextLineNumberLimit={1}
              copyButton
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <LabelAndValue label={'Size'} value={attachmentDetails.size} direction={'column'} />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <LabelAndValue
              label={'Attachment Type'}
              value={attachmentDetails.fileType?.toUpperCase()}
              direction={'column'}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <LabelAndValue
              label={'Mime'}
              value={attachmentDetails.fileTypeMime || '--'}
              direction={'column'}
            />
          </Grid>
        </Grid>
      </MuiCardWrapper>
    </div>
  );
};
