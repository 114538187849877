import landingPageLight from './Reports - light mode.png';
import landingPageDark from './Reports - dark mode.png';
import React, { useContext } from 'react';
import ThemeContext from '../../../context/ThemeContext';
import './landingPage.scss';

export function ReportsLandingPage() {
  const theme = useContext(ThemeContext);
  const landingPageImage = theme.selectedTheme === 'light' ? landingPageLight : landingPageDark;
  return (
    <div className='reports-landing-page'>
      <div className='landing-page-title'>Reports</div>
      <div className='landing-page-tips'>
        You do not have permission to access any modules to view or generate reports. Please contact
        your administrator to request access.
      </div>
      <img src={landingPageImage} alt='Reports Landing Page' width={540} />
    </div>
  );
}
