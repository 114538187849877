import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Switch, Tooltip as MUToolTip } from '@mui/material';
import './typosquat.scss';
import '../Dashboard_v2/SubComponents/MonitoredDomainsV2/MonitoredContainerV2.scss';
import DashboardService from '../../services/dashboard.service';
import { PageTitle } from '../Common/PageTitle';
import { IBarChartDataProps, TColorMap } from '../Common/DashboardWidgets/Types/chart.type';
import BarChart from '../Common/DashboardWidgets/BarChart';
import { connect } from 'react-redux';
import { alertActions } from '../../actions';
import {
  AlertActionsTypes,
  convertToThousand,
  generateId,
  getColors,
  setLocalStorageValue,
} from '../../constants';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { Alert, Badge, Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import CheckPhishContactUsContext, {
  CheckphishSubscriptionContext,
} from '../../context/CheckPhishContactUsContext';
import FilledRightArrow from '../../assets/icons/RightArrowFilled.svg';
import WarningRedRoundIcon from '../../assets/icons/Warning-round-red-border.svg';
import _ from 'lodash';
import { IFilter } from '../Common/Table/constant';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
  TYPOSQUAT_TABLE_ID_DASH,
  TypoJobStatus,
} from './constant';
import { history } from '../../helpers';
import TyposquatBlurredModal from './TyposquatBlurredModal';
import TyposquatReminderModal from './TyposquatReminderModal';
import {
  AggregatedTyposquatFetchResultsDashboard,
  DeltaChangeFromAPI,
  DeltaChangeFromItem,
} from '../../types/typosquat-dashboard.interface';
import {
  DELTA_DOMAIN,
  DELTA_CHANGE_TYPE,
  DELTA_CHANGE_PREVIOUS,
  DELTA_CHANGE_CURRENT,
  DELTA_CHANGE_AT,
} from './columns';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../Common/Table/ag-table/custom.ag-grid.scss';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { getAgGridThemeClassName } from '../Common/Table/ag-table/ag-utils';
import {
  generateTyposquattingDeltaColumnDefs,
  generateTyposquattingDeltaFlatColumnDefs,
} from '../Common/Table/ag-table/ag-col-defs';
import { PRICING_PAGE_PATH } from '../Pricing/constants';
import CriticalAlertWidget from './Dashboard_v2/widgets/CriticalAlertWidget';
import { TopRiskWidgetProps } from './Dashboard_v2/widgets/TopRiskWidget';
import TopRiskWidget from './Dashboard_v2/widgets/TopRiskWidget';
import { RISK_WIDGET_TYPE } from '../../constants';
import moment from 'moment';
import RiskLevelIndicator from './Dashboard_v2/common/RiskLevelIndicator';
import NoDataWidget from './Dashboard_v2/widgets/NoDataWidget';
import CardWrapper from './Dashboard_v2/common/CardWrapper';
import { useLocation } from 'react-router';
import LookAlikeWidget from './Dashboard_v2/widgets/LookAlikeWidget';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const dashboardService = new DashboardService();

const barChartMapper: Record<string, string> = {
  hostMatrix: 'Top Hosting Provider by Risk',
  tldMatrix: 'Top TLDs by Risk',
};

interface ITypoSquattingDashboardProps {
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
  typosquatJobStatus: boolean;
  changeTyposquatJobStatus: (status: boolean) => void;
}

const TypoSquattingDashboard = ({
  alertError,
  changeTyposquatJobStatus,
}: ITypoSquattingDashboardProps) => {
  const [, selectedTheme] = useIsDarkMode();
  const checkphishContactUsContext = useContext(CheckPhishContactUsContext);
  const [data, setData] = useState<any>({});
  const [topRiskWidgetData, setTopRiskWidgetData] = useState<TopRiskWidgetProps[]>([]);
  const [showAfterTrialModal, setShowAfterTrialModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [scanLimit, setScanLimit] = useState<number>(1);
  const [usedScans, setUsedScans] = useState<number>(0);
  const { currentTyposquatSubscription } = useContext(CheckphishSubscriptionContext);
  const agRef = useRef<AgGridReact>(null);
  const [userTyposquatDataList, setUserTyposquatDataList] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [deltaData, setDeltaData] = useState<Record<string, DeltaChangeFromAPI>>({});
  const [deltaFlatData, setDeltaFlatData] = useState<object[]>([]);
  const [deltaTableGroupedView, setDeltaTableGroupedView] = useState<boolean>(false);

  const deltaColumns = [
    { ...DELTA_DOMAIN, header: 'Typosquat' },
    { ...DELTA_CHANGE_TYPE, header: 'Update' },
    { ...DELTA_CHANGE_CURRENT, header: 'Current' },
    { ...DELTA_CHANGE_PREVIOUS, header: 'Previous' },
    // DELTA_LAST_SCAN,
    { ...DELTA_CHANGE_AT, header: 'Updated on' },
  ];

  const newColorMap: TColorMap = useMemo(() => {
    return {
      5: getColors(selectedTheme).newTyposquattingRiskScore5,
      4: getColors(selectedTheme).newTyposquattingRiskScore4,
      3: getColors(selectedTheme).newTyposquattingRiskScore3,
      2: getColors(selectedTheme).newTyposquattingRiskScore2,
      1:
        selectedTheme === 'light'
          ? getColors(selectedTheme).newTyposquattingRiskScore1
          : getColors(selectedTheme).newTyposquattingRiskScore1DM,
    };
  }, [selectedTheme]);

  const {
    setShowExploreDemoPopup,
    selectedDomain,
    setSelectedDomain,
    setDomainList,
    setPaidUser,
    paidUser,
  } = checkphishContactUsContext;

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === TYPOSQUATTING_DASHBOARD_PATHNAME) {
      window.document.title = 'Domain Monitoring Dashboard | CheckPhish Platform';
      return;
    }
  }, []);

  useEffect(() => {
    if (userTyposquatDataList && selectedDomain.value) {
      setData(userTyposquatDataList[selectedDomain.value]);
      handleDeltaData(userTyposquatDataList[selectedDomain.value]?.deltaChanges?.results);
    }
  }, [selectedDomain, userTyposquatDataList]);

  useEffect(() => {
    if (_.isEmpty(data)) return;
    extractTopRiskWidgetData(data);
  }, [data]);

  useEffect(() => {
    setShowExploreDemoPopup(true);
    return () => setShowExploreDemoPopup(false);
  });

  const extractTopRiskWidgetData = (data: any) => {
    const {
      scanInsights,
      domainWithIpTopRiskiest,
      domainWithMailServerTopRiskiest,
      domainWithNameServerTopRiskiest,
      activeDomainTopRiskiest,
      deltaChanges,
    } = data;

    const topRiskWidgetData: TopRiskWidgetProps[] = Object.values(RISK_WIDGET_TYPE).map(
      (item: any) => {
        switch (item) {
          case RISK_WIDGET_TYPE.ACTIVE_DOMAIN: {
            return {
              type: item,
              deltaVal: {
                heading: 'Active Domains',
                total: scanInsights?.activeDomains,
                positive:
                  deltaChanges?.scanInsights?.activeDomains.total - scanInsights?.activeDomains > 0,
                delta: Math.abs(
                  deltaChanges?.scanInsights?.activeDomains.total - scanInsights?.activeDomains ||
                    0,
                ),
              },
              topRiskVal: {
                heading: `Top ${Object.keys(activeDomainTopRiskiest).length} high risk live domain`,
                isLink: true,
                data: Object.keys(activeDomainTopRiskiest).map(item => ({
                  href: `/domain-monitoring/insights/${activeDomainTopRiskiest[item]?.scanTs}/${activeDomainTopRiskiest[item]?.urlSha}`,
                  value: item,
                })),
              },
            };
          }
          case RISK_WIDGET_TYPE.MX_RECORD: {
            return {
              type: item,
              deltaVal: {
                heading: 'MX Records',
                total: scanInsights?.mx,
                positive: deltaChanges?.scanInsights?.mx.total - scanInsights?.mx > 0,
                delta: Math.abs(deltaChanges?.scanInsights?.mx.total - scanInsights?.mx || 0),
              },
              topRiskVal: {
                heading: `Top ${
                  Object.keys(domainWithMailServerTopRiskiest).length
                } high risk domains with MX records`,
                isLink: true,
                data: Object.keys(domainWithMailServerTopRiskiest).map(item => ({
                  href: `/domain-monitoring/insights/${domainWithMailServerTopRiskiest[item]?.scanTs}/${domainWithMailServerTopRiskiest[item]?.urlSha}`,
                  value: item,
                })),
              },
            };
          }
          case RISK_WIDGET_TYPE.A_RECORD: {
            return {
              type: item,
              deltaVal: {
                heading: 'A Records',
                total: scanInsights?.ip,
                positive: deltaChanges?.scanInsights?.ip.total - scanInsights?.ip > 0,
                delta: Math.abs(deltaChanges?.scanInsights?.ip.total - scanInsights?.ip || 0),
              },
              topRiskVal: {
                heading: `Top ${
                  Object.keys(domainWithIpTopRiskiest).length
                } high risk domains with A records`,
                isLink: true,
                data: Object.keys(domainWithIpTopRiskiest).map(item => ({
                  href: `/domain-monitoring/insights/${domainWithIpTopRiskiest[item]?.scanTs}/${domainWithIpTopRiskiest[item]?.urlSha}`,
                  value: item,
                })),
              },
            };
          }
          default: {
            return {
              type: item,
              deltaVal: {
                heading: 'NS Records',
                total: scanInsights?.ns,
                positive: deltaChanges?.scanInsights?.ns.total - scanInsights?.ns > 0,
                delta: Math.abs(deltaChanges?.scanInsights?.ns.total - scanInsights?.ns || 0),
              },
              topRiskVal: {
                heading: `Top ${
                  Object.keys(domainWithNameServerTopRiskiest).length
                } high risk domains with NS records`,
                isLink: true,
                data: Object.keys(domainWithNameServerTopRiskiest).map(item => ({
                  href: `/domain-monitoring/insights/${domainWithNameServerTopRiskiest[item]?.scanTs}/${domainWithNameServerTopRiskiest[item]?.urlSha}`,
                  value: item,
                })),
              },
            };
          }
        }
      },
    );
    setTopRiskWidgetData(topRiskWidgetData);
  };

  const handleUserData = (resp: AggregatedTyposquatFetchResultsDashboard) => {
    const {
      metadata: { paidUser, totalDomainMonitoringScanLimit, usedDomainMonitoringScanLimit },
      scanResults,
    } = resp;
    setPaidUser(paidUser);
    setScanLimit(totalDomainMonitoringScanLimit); //total scan limit
    setUsedScans(usedDomainMonitoringScanLimit); //number of scan used
    const availableDomains = Object.values(scanResults)
      .sort((a, b) => b.triggerTs - a.triggerTs)
      .map(m => m.monitoredDomain);
    let tempSelectedDomain = selectedDomain;

    const index =
      tempSelectedDomain?.value && tempSelectedDomain.status === TypoJobStatus.DONE
        ? availableDomains.indexOf(tempSelectedDomain.value)
        : -1;
    const isDoneDomain = availableDomains.filter(
      availableDomain => scanResults[availableDomain].status === TypoJobStatus.DONE,
    )[0];
    if (index === -1) {
      if (isDoneDomain) {
        tempSelectedDomain = {
          value: isDoneDomain,
          status: scanResults[isDoneDomain].status,
        };
      } else {
        tempSelectedDomain = {
          value: availableDomains[0],
          status: scanResults[availableDomains[0]].status,
        };
      }
    } else {
      if (tempSelectedDomain.status !== scanResults[tempSelectedDomain.value as string].status) {
        tempSelectedDomain = {
          ...tempSelectedDomain,
          status: scanResults[tempSelectedDomain.value as string].status,
        };
      }
    }
    setSelectedDomain(tempSelectedDomain);
    setUserTyposquatDataList(scanResults);
    setDomainList(
      availableDomains.map(d => {
        return {
          value: d,
          status: scanResults[d].status,
        };
      }),
    );
    changeTyposquatJobStatus(true);
  };

  const fetchDashboardStats = () => {
    setLoading(true);
    dashboardService
      .getTyposquattingDashboardAnalyticsV2()
      .then((resp: AggregatedTyposquatFetchResultsDashboard) => handleUserData(resp))
      .catch(err => {
        console.error(err);
        setDomainList([]);
        setSelectedDomain({});
        const [errPrefix, _errMessage] = err?.split('.');
        if (errPrefix === 'Your free trial is over') {
          setShowAfterTrialModal(true);
        } else {
          const onDashboardPage = window.location.pathname === '/domain-monitoring/dashboard';
          alertError(
            onDashboardPage
              ? 'Error in fetching dashboard stats. Redirecting to typosquat page'
              : 'Error in fetching dashboard stats.',
          );
          changeTyposquatJobStatus(false);
          window.location.pathname === '/domain-monitoring/dashboard' &&
            history.push('/domain-monitoring/typosquat');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  const sendForTakedown = () => {
    const filters: IFilter[] = [
      {
        id: generateId(10),
        filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
        filterErr: '',
        filterLabel: 'Clean',
        filterMethod: { label: 'Is Not', value: 'isNot' },
        filterType: 'options',
        filterValue: 'clean',
      },
      {
        id: generateId(10),
        filterBy: { label: 'Live Scan Verdict', value: 'disposition' },
        filterErr: '',
        filterLabel: 'Suspicious',
        filterMethod: { label: 'Is Not', value: 'isNot' },
        filterType: 'options',
        filterValue: 'suspicious',
      },
    ];

    setLocalStorageValue(['tableSetting', TYPOSQUAT_TABLE_ID_DASH], {
      filters,
    });
    history.push({
      pathname: TYPOSQUATTING_MASTER_PATHNAME,
      state: {
        defaultLayout: 'tile',
      },
    });
  };

  // Ag Grid Implementation

  const colDefs = useMemo<ColDef[]>(() => {
    return deltaTableGroupedView
      ? generateTyposquattingDeltaColumnDefs({
          columns: deltaColumns,
          lockColumnId: DELTA_CHANGE_TYPE.id,
        })
      : generateTyposquattingDeltaFlatColumnDefs({
          columns: deltaColumns,
          lockColumnId: DELTA_DOMAIN.id,
        });
  }, [deltaData, deltaTableGroupedView]);

  const handleDeltaData = (deltaChanges: DeltaChangeFromAPI[]) => {
    if (deltaChanges && deltaChanges.length > 0) {
      const tempDeltaData: Record<string, DeltaChangeFromAPI> = {};
      const tempDeltaChanges: DeltaChangeFromItem[] = [];
      deltaChanges.forEach((deltaChange: DeltaChangeFromAPI) => {
        tempDeltaData[deltaChange.domain] = deltaChange;
        deltaChange.type.forEach(type => {
          tempDeltaChanges.push({
            ...deltaChange,
            type,
            primary_domain: deltaChange.domain,
          });
        });
      });
      setDeltaData(tempDeltaData);
      setDeltaFlatData(tempDeltaChanges);
    } else {
      setDeltaData({});
      setDeltaFlatData([]);
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: [],
      resizable: true,
      flex: 1,
      hide: false,
    };
  }, []);

  const renderDeltaTable = () => {
    return (
      <div
        id='ag-table-container'
        data-testid='ag-table-container'
        className={`${getAgGridThemeClassName(selectedTheme)} table-container border-rounded-sm`}
      >
        {deltaTableGroupedView ? (
          <AgGridReact
            ref={agRef}
            columnDefs={colDefs}
            rowData={deltaFlatData}
            groupDisplayType={'groupRows'}
            autoGroupColumnDef={{ headerName: DELTA_DOMAIN.header, field: DELTA_DOMAIN.accessor }}
          />
        ) : (
          <AgGridReact columnDefs={colDefs} rowData={deltaFlatData} defaultColDef={defaultColDef} />
        )}
      </div>
    );
  };

  // End Ag Grid Implementation

  return (
    <AuthenticationWrapper>
      {showAfterTrialModal ? (
        <TyposquatBlurredModal />
      ) : (
        <LoadingWrapper isLoading={loading}>
          <Row>
            <Col sm={12}>
              <PageTitle
                title={`Dashboard ${data.monitoredDomain && `${data.monitoredDomain}`}`}
                className='analytics-dashboard-page-title'
                tools={
                  paidUser && (
                    <button
                      className={`edit-domain-btn`}
                      onClick={() => {
                        history.push({
                          pathname: TYPOSQUATTING_MASTER_PATHNAME,
                          state: {
                            showTyposquatBar: true,
                            error: { status: false, message: '', isSuccessMsg: false },
                            showLinkToLiveScan: {
                              status: false,
                              message: '',
                              includeDomain: false,
                            },
                            searchText: '',
                            firstLoad: false,
                            scanLimit,
                            usedScans,
                          },
                        });
                      }}
                    >
                      <span className='mr-3' style={{ padding: '0 5px' }}>
                        Add Domain(s)
                      </span>
                    </button>
                  )
                }
              />
            </Col>
            {!currentTyposquatSubscription && (
              <Col sm={12} className='upgrade-strip'>
                <p>
                  This dashboard displays the results of {data.domainGenerationLimit ?? 0}/
                  {convertToThousand(
                    Math.max(data.metadata?.totalResolvedVariants, data.domainGenerationLimit) ?? 0,
                    true,
                  )}{' '}
                  typosquatting domain summary. Need more insights?
                  <Button variant='link' onClick={() => history.push(PRICING_PAGE_PATH)}>
                    Upgrade now.
                  </Button>
                </p>
              </Col>
            )}
          </Row>
          {data.daysToExpire !== -1 && (
            <Row className='alert-container'>
              <Col sm={12}>
                <Alert className='expiry-alert d-flex' key={'expiry-alert'} variant='danger'>
                  <img className='work-email-err-msg-logo' src={WarningRedRoundIcon} alt={'logo'} />
                  <p className='mb-0 mt-2'>
                    The domain monitoring feature access will{' '}
                    <span className='darker'>expire in {data.daysToExpire} days </span>. Please{' '}
                    <span
                      className='darker capitalise'
                      onClick={() => history.push('/account/profile')}
                    >
                      add your work email
                    </span>{' '}
                    to continue access.
                  </p>
                </Alert>
              </Col>
            </Row>
          )}
          {/* top risk section */}
          <div className='dashboard-wrapper'>
            <div className='risk-section-wrap'>
              {!_.isEmpty(data?.criticalAlerts) ? (
                <CriticalAlertWidget
                  criticalAlerts={data?.criticalAlerts}
                  icon={FilledRightArrow}
                  onClick={sendForTakedown}
                />
              ) : (
                <LookAlikeWidget data={data} isPaidUser={paidUser} />
              )}
              <div className='top-risk-wrap'>
                {topRiskWidgetData.map((item: TopRiskWidgetProps, index: number) => (
                  <TopRiskWidget
                    key={`risk-${index}`}
                    type={item.type}
                    deltaVal={item.deltaVal}
                    topRiskVal={item.topRiskVal}
                  />
                ))}
              </div>
            </div>
          </div>

          {!_.isEmpty(deltaData) ? (
            <>
              <div className='whats-new-wrapper mx-6'>
                <Row className='mx-0'>
                  <Col>
                    <h6>Recent Updates</h6>
                  </Col>
                  <Col className='delta-switch-container no-padding'>
                    <MUToolTip title='Switch to group view of results'>
                      <Switch
                        checked={deltaTableGroupedView}
                        onChange={() => setDeltaTableGroupedView(!deltaTableGroupedView)}
                        color='primary'
                      />
                    </MUToolTip>
                  </Col>
                </Row>
              </div>
              <Row className='delta-table delta-table-v2'>{renderDeltaTable()}</Row>
              <Col
                className='upgrade-strip next-scan-strip d-flex'
                style={{
                  marginLeft: 0,
                }}
              >
                <Col
                  className='px-md-3'
                  style={{
                    marginBlockEnd: '1rem',
                  }}
                >
                  <p className='next-scan-description'>
                    * Next scan on{' '}
                    <span className='next-scan-date'>
                      {moment(data.nextScanTs).format('MM/DD/YYYY')}
                    </span>
                    . Check back here for updates or watch for email notifications.
                  </p>
                </Col>
              </Col>
            </>
          ) : (
            <div className='no-data-wrap' style={{ width: '100%', padding: '24px' }}>
              <h6 style={{ marginBottom: '10px 0' }}>Recent Updates</h6>
              <NoDataWidget
                heading={'We are continuous monitoring'}
                subHeading={
                  paidUser ? (
                    <span>
                      No new malicious activity found since the last digest. Next scan on{' '}
                      <span style={{ fontWeight: 'bold', margin: '0 1px' }}>
                        {moment(data.nextScanTs).format('MM/DD/YYYY')}
                      </span>{' '}
                      Check back for updates or watch for an email notification.
                    </span>
                  ) : (
                    <>
                      <span>Want to see full results and stay updated on recent changes?</span>
                      <Button onClick={() => history.push(PRICING_PAGE_PATH)}>Upgrade</Button>
                    </>
                  )
                }
              />
            </div>
          )}
          <div className='breakdown-data-wrap'>
            <Breakdown data={data} />
            <div className='container-widget-got--row mb-end-6'>
              {Object.keys(barChartMapper).map((k, i) => (
                <TopRisk
                  key={`${i}-${barChartMapper[k]}`}
                  title={barChartMapper[k]}
                  loading={loading}
                  data={data[k] as IBarChartDataProps[]}
                  colorMap={newColorMap}
                />
              ))}
            </div>
          </div>
        </LoadingWrapper>
      )}
      {data?.daysToExpire && data.daysToExpire <= 5 && data.daysToExpire > 0 && (
        <TyposquatReminderModal domain={data.monitoredDomain} daysToExpire={data.daysToExpire} />
      )}
    </AuthenticationWrapper>
  );
};

const Breakdown = (props: any) => {
  const [, selectedTheme] = useIsDarkMode();

  const newColorMap: TColorMap = useMemo(() => {
    return {
      5: getColors(selectedTheme).newTyposquattingRiskScore5,
      4: getColors(selectedTheme).newTyposquattingRiskScore4,
      3: getColors(selectedTheme).newTyposquattingRiskScore3,
      2: getColors(selectedTheme).newTyposquattingRiskScore2,
      1:
        selectedTheme === 'light'
          ? getColors(selectedTheme).newTyposquattingRiskScore1
          : getColors(selectedTheme).newTyposquattingRiskScore1DM,
    };
  }, [selectedTheme]);

  const routeToTyposquat = (riskScore: string) => {
    const filters: IFilter[] = [
      {
        id: generateId(10),
        filterBy: { label: 'Risk', value: 'risk_score' },
        filterErr: '',
        filterMethod: { label: 'In range', value: 'numberRange' },
        filterType: 'number',
        filterValue: `${riskScore},${riskScore}`,
      },
    ];

    setLocalStorageValue(['tableSetting', TYPOSQUAT_TABLE_ID_DASH], {
      filters,
    });
    history.push(TYPOSQUATTING_MASTER_PATHNAME);
  };

  const dottedBorderClassName = {
    '5': 'red',
    '4': 'pink',
    '3': 'orange',
    '2': 'yellow',
    '1': 'blue',
  };

  const renderRiskLevelBreakdown = (): JSX.Element => {
    const { riskScoreMatrix } = props.data;
    if (!riskScoreMatrix) {
      return <></>;
    }
    const totalRiskScoreMatrix: any = Object.values(riskScoreMatrix).reduce(
      (sum: any, value: any) => sum + value,
    );
    const sortedKeys = Object.keys(riskScoreMatrix).sort((a, b) => parseInt(b) - parseInt(a));
    return (
      <>
        <Col sm={12}>
          <div className='risk-level-bar'>
            {sortedKeys.map((k, i) => (
              <OverlayTrigger
                placement={'bottom'}
                key={i}
                overlay={
                  <Tooltip id={'tooltip-risk-score-' + k}>
                    Priority Count for Risk Score-{k}: {riskScoreMatrix[k]}
                  </Tooltip>
                }
              >
                <div
                  className='risk-level-bar-length'
                  style={{
                    width: `${(riskScoreMatrix[k] / totalRiskScoreMatrix) * 100}%`,
                    background: `${newColorMap[k]}`,
                  }}
                  onClick={() => routeToTyposquat(k)}
                >
                  <div className={`legend-bubble ${dottedBorderClassName[k]}`}>
                    <Badge
                      pill
                      variant='primary'
                      style={{
                        backgroundColor: newColorMap[k],
                        color: k === '1' ? '#2360bc' : undefined,
                      }}
                    >
                      {k}
                    </Badge>
                  </div>
                </div>
              </OverlayTrigger>
            ))}
          </div>
        </Col>
        <Col sm={12} style={{ paddingTop: '10px' }}>
          <span className='risk-legend-high-text-color'>High</span>
          <span className='risk-legend-low-text-color' style={{ float: 'right' }}>
            Low
          </span>
        </Col>
      </>
    );
  };

  return (
    <div className='monitiored-container-v2' style={{ margin: '24px', padding: '0', border: '0' }}>
      <CardWrapper>
        <div className='widget-card'>
          <Row className='risk-level-breakdown' style={{ border: '0' }}>
            <Col sm={5} className='header'>
              <h5 className='widget-card--title'>Risk Level Breakdown</h5>
              <span className='content widget-card--description'>
                This score measures the potential risk a typosquat domain poses to a brand. The risk
                score for each domain is calculated based on factors like its category, popularity,
                age, and other relevant criteria.
              </span>
            </Col>
            <Col sm={7}>{renderRiskLevelBreakdown()}</Col>
          </Row>
          <RiskLevelIndicator colorMap={newColorMap} />
        </div>
      </CardWrapper>
    </div>
  );
};

const TopRisk = (props: any) => {
  return (
    <div
      style={{
        width: '100%',
        overflow: 'visible !important',
        margin: '0 24px',
      }}
      className='widget-card top-risk-widget-wrap-cp'
      data-testid='risk-card'
    >
      <CardWrapper customClassName='overflow-visible'>
        <div className='top-risk-wrap' style={{ width: '100%', padding: '24px' }}>
          <BarChart
            title={props.title}
            isLoading={props.loading}
            data={props.data}
            colorMap={props.colorMap}
            hideLegend={true}
            plotOptionsHeight={'100%'}
            xaxisShow={false}
            showTotalData={true}
            alignLabel={'right'}
          />
          <div style={{ marginBlockStart: '35px' }}></div>
          <RiskLevelIndicator colorMap={props.colorMap} />
        </div>
      </CardWrapper>
    </div>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
const connectedTypoSquattingDashboard = connect(
  undefined,
  mapDispatchToProps,
)(TypoSquattingDashboard);
export { connectedTypoSquattingDashboard as TypoSquattingDashboard };
