import React from 'react';
import { Modal, Col, Button } from 'react-bootstrap';

enum PopupSize {
  SMALL = '600px',
  LARGE = '500px',
}

export interface TriggerButtonData {
  text: string;
  onClick: Function;
  variant?: string;
  className?: string;
}

export interface IUpgragePopUp {
  showPopup: boolean;
  onHide: () => void;
  backgroundImg: string;
  subText: React.ReactElement;
  buttons: TriggerButtonData[];
  isSmallPopup?: boolean;
}

const UpgradePopup = ({
  showPopup,
  onHide,
  backgroundImg,
  buttons,
  subText,
  isSmallPopup = false,
}: IUpgragePopUp) => {
  return (
    <div>
      {' '}
      <Modal
        className={`modal-wrap explore-demo-wrap-paid ${isSmallPopup && 'small-popup'}`}
        size='lg'
        show={showPopup}
        onHide={onHide}
      >
        <Modal.Header className='no-padding' closeButton></Modal.Header>
        <Modal.Body className='no-padding'>
          <div>
            <div className='background-wrapper' style={{ height: '220px' }}>
              <img
                src={backgroundImg}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            <Col md={12} className='text-wrapper'>
              {subText}
            </Col>
            <Col md={12} className='no-padding button-wrapper'>
              {buttons.map((buttonItem: any, index: number) => {
                return (
                  <Button
                    key={index + 1}
                    variant={buttonItem.variant || 'primary'}
                    onClick={buttonItem.onClick}
                    className={buttonItem.className || ''}
                  >
                    {buttonItem.text}
                  </Button>
                );
              })}
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpgradePopup;
