import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { WEB_DASHBOARD_PATHNAME } from '../MonitorAndTakedown/constants';
import { BulkScanUrlInfoParams, bulkScanInfoRedirectHeaders } from './BulkScanConstants';

interface BulkScanUrlInfoRedirectContainerProps {
  urls: BulkScanUrlInfoParams[];
  updateBulkScanStatus: (status: string) => void;
}

interface filteredDataParams {
  header: string;
  className: string;
  urls: BulkScanUrlInfoParams[];
  keyVal: string;
}

const BulkScanUrlInfoRedirectContainer: React.FC<BulkScanUrlInfoRedirectContainerProps> = ({
  urls,
  updateBulkScanStatus,
}) => {
  const bulkScanHeaderAndFltrData = [
    {
      header: bulkScanInfoRedirectHeaders.EXISTS_HEADER,
      filterDataVal: 'isAlreadyExisted',
    },
    {
      header: bulkScanInfoRedirectHeaders.NEWLYADDED_HEADER,
      filterDataVal: 'isNewScan',
    },
    {
      header: bulkScanInfoRedirectHeaders.TAKENDOWN_HEADER,
      filterDataVal: 'isUrlTakenDown',
    },
  ];

  const [filteredData, setFilteredData] = useState<filteredDataParams[]>([]);

  useEffect(() => {
    getFilteredUrlData();
  }, [urls]);

  const getFilteredUrlData = () => {
    const fltrData: filteredDataParams[] = [];
    bulkScanHeaderAndFltrData.forEach(({ header, filterDataVal }) => {
      const fltrVal = filterDataVal as keyof BulkScanUrlInfoParams;
      const filteredUrls = urls.filter(url => url[fltrVal]);

      if (filteredUrls.length > 0) {
        fltrData.push({
          header,
          className: `bulk-scan-${fltrVal}-container`,
          urls: filteredUrls,
          keyVal: fltrVal,
        });
      }
    });
    console.log('BulkScanUrlInfoRedirectContainer fltrData::', fltrData);
    setFilteredData(fltrData);
  };

  return (
    <div className='bulk-scan-info-redirect-container'>
      {filteredData.map(({ header, className, urls, keyVal }) => (
        <div key={keyVal} className={className}>
          <div className='bulk-scan-urls-list-header'>{header}</div>
          <ul className='bulk-scan-urls-list-container'>
            {urls.map(item => {
              const route = item.isNewScan
                ? `/bulk-scan/insights/${item.timestamp}/${item.urlSHA256}`
                : `${WEB_DASHBOARD_PATHNAME}/insights/${item.timestamp}/${item.urlSHA256}`;

              return (
                <li key={item.urlSHA256}>
                  <Link to={route} target='_blank'>
                    {item.url}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
      <div className='bulk-scan-status-button-container'>
        <Button
          id='bulk-scan-startNewScan-btn'
          variant='outline-primary'
          onClick={() => updateBulkScanStatus('newscan')}
        >
          Start New Scan
        </Button>
        <Button
          id='bulk-scan-rescan-btn'
          variant='outline-primary'
          className='bulk-scan-rescan-btn'
          onClick={() => updateBulkScanStatus('rescan')}
        >
          Rescan
        </Button>
      </div>
    </div>
  );
};

export default BulkScanUrlInfoRedirectContainer;
