import { ABUSE_MAIL_BOX_MODULE_PATH_NAME, IThreatTypeDetails, SENDER_DOMAIN } from '../constants';

export function getInsightsPathBasedOnThreatType(threatData: IThreatTypeDetails) {
  const basePath = ABUSE_MAIL_BOX_MODULE_PATH_NAME;
  const { threatType, disposition, sha256, timestamp } = threatData;

  if (threatType === 'url' || (threatType === SENDER_DOMAIN && disposition !== 'malicious')) {
    return `${basePath}/insights/${timestamp}/${sha256}`;
  }

  return `${basePath}/insights/threatTypes/${threatType}/${sha256}`;
}
