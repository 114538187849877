import React, { useCallback, useState } from 'react';
import { Top5SuspiciousURLs } from './components/Top5SuspiciousURLs';
import { OverallEmailSubmission } from './components/OverallEmailSubmission';
import { OverallEmailSubmissionBreakdown } from './components/OverallEmailSubmissionBreakdown';
import { TargetedMaliciousEmailAnalytics } from './components/TargetedMaliciousEmailAnalytics';
import { TakedownTimeAnalytics } from './components/TakedownTimeAnalytics';
import './index.scss';
import { MostReportedDomains } from './components/MostReportedDomains';
import { useFeatureIsAvailable } from '../../../basic-hooks/useFeatures';
import { appConstants, DEFAULT_DATE_RANGE_V2 } from '../../../constants';
import { AbuseMailBoxTeaserPage } from './AbuseMailBoxTeaserPage';
import { useAMDashboardData } from './useAMDashboardData';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { useAppSelector } from '../../../helpers/hooks';
import { getIsAbuseMailboxModuleAvailable } from '../../../helpers/permissions';
import { AbuseMailBoxNoPermissionPage } from './AbuseMailBoxNoPermissionPage';
import { dashboardMockData } from './dashboard-mock-data';
import { AbuseMailBoxDashboardNoDataView } from './AbuseMailBoxDashboardNoDataView';
import { DropdownDateRangePicker } from '../../Common/DropdownDateRangePicker';
import { PageTitle } from '../../Common/PageTitle';
import { usePersistanceDate } from '../../../basic-hooks/usePersistanceDate';

export function AbuseMailboxDashboard() {
  const { startDate, endDate, onDateChange } = usePersistanceDate(
    DEFAULT_DATE_RANGE_V2,
    `${appConstants.CONTENT_TYPE.ABUSE_MAILBOX}_dashboard`,
  );

  window.document.title = 'Dashboard | Abuse Mailbox';

  const isAbuseMailBoxFeatureEnabled = useFeatureIsAvailable(
    appConstants.FEATURE_CODE.ABUSE_MAILBOX,
  );

  const { user } = useAppSelector(state => state.dashboardReducer);

  const { dashboardData } = useAMDashboardData(
    undefined,
    startDate,
    endDate,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  //https://redmarlin.atlassian.net/browse/ORCA-292
  const dashboardDataToRender = dashboardData?.emptyState ? dashboardMockData : dashboardData;

  // Priorities:
  // 1. If the feature is not enabled, show the teaser page
  // 2. If the user does not have permission to access the module, show the no permission page
  // 3. If the user has permission to access the module, show the dashboard
  if (!isAbuseMailBoxFeatureEnabled) {
    return <AbuseMailBoxTeaserPage />;
  }

  if (!getIsAbuseMailboxModuleAvailable(user)) {
    return <AbuseMailBoxNoPermissionPage />;
  }

  if (!dashboardDataToRender) {
    return <LoadingWrapper isLoading={true} />;
  }

  return (
    <div className='abuse-mailbox-dashboard-container'>
      {dashboardDataToRender?.emptyState && (
        <AbuseMailBoxDashboardNoDataView
          abuseMailboxEmailId={dashboardData?.abuseMailBoxId as string}
        />
      )}
      <div className='abuse-mailbox-dashboard'>
        <div className='abuse-mailbox-dashboard-section-titles'>
          <PageTitle
            title='Overall Email Report Overview'
            tools={
              <DropdownDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={onDateChange}
                type={appConstants.CONTENT_TYPE.ABUSE_MAILBOX}
                currentPersistantDatePickerPage={`${appConstants.CONTENT_TYPE.ABUSE_MAILBOX}_dashboard`}
              />
            }
          />
        </div>
        <div className='overall-email-report-overview-container'>
          <OverallEmailSubmission dashboardData={dashboardDataToRender} />
          <OverallEmailSubmissionBreakdown dashboardData={dashboardDataToRender} />
        </div>
        <TargetedMaliciousEmailAnalytics dashboardData={dashboardDataToRender} />
        <div className='d-flex takedown-top5-urls-most-reported-domains-container'>
          <div className='d-flex-col takedown-top5-urls-container'>
            <TakedownTimeAnalytics dashboardData={dashboardDataToRender} />
            <Top5SuspiciousURLs dashboardData={dashboardDataToRender} />
          </div>
          <MostReportedDomains dashboardData={dashboardDataToRender} />
        </div>
      </div>
    </div>
  );
}
