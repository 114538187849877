import _ from 'lodash';
import {
  CATEGORY_COLUMN,
  DISCOVERY_DATE_COLUMN,
  PLATFORM_COLUMN,
  RISK_COLUMN,
  SEARCH_TERM_COLUMN,
  SENSITIVE_DATA_EXISTS_COLUMN,
  STATUS_COLUMN,
  THREAT_ACTOR_COLUMN,
  TITLE_COLUMN,
  DARK_WEB_SSN,
  DARK_WEB_IP,
  DARK_WEB_CRYPTO,
  PASSWORD_COLUMN,
  PASSWORD_TYPE_COLUMN,
  EXP_DATE_COLUMN,
  CCN_COLUMN,
  CVV_COLUMN,
  DARK_WEB_LAST_SCANNED_COLUMN,
} from '../../DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';
import {
  EDarkWebFindingStatus,
  EDarkWebRiskLevel,
} from '../../DarkWeb/Components/Types/DarkWeb.types';
import {
  DISPOSITION_COLUMN,
  LOGO_DETECTION_COLUMN,
  TAKEDOWN_REQUESTER_EMAIL_COLUMN,
  TAKEDOWN_REQUEST_DATE_COLUMN,
  TAKE_DOWN_TIME_COLUMN,
  TLD_COLUMN,
} from '../../Dashboard/dashboard.component';
import {
  ACTIVE_SINCE,
  EXTERNAL_LINKS_COL,
  FIRST_FOUND_COLUMN,
  IS_LOGO_DETECTION_COL,
  MATCH_SEARCH_TERMS_COLUMN,
  CATEGORY_COLUMN as UGC_CATEGORY_COLUMN,
  SOURCE_COLUMN as UGC_SOURCE_COLUMN,
  TITLE_COLUMN as UGC_TITLE_COLUMN,
  PLATFORM_COLUMN as UGC_PLATFORM_COLUMN,
  ORIGIN_COLUMN as UGC_ORIGIN_COLUMN,
  TAKEDOWN_TIME_COLUMN,
  TAKEDOWN_STATUS_COLUMN,
} from '../../Ugc/Components/Findings/TableColDef/FindingsTableCols';
import {
  BRAND_COLUMN,
  IP_COLUMN as WEB_IP_COLUMN,
  CATEGORY_COLUMN as WEB_CATEGORY_COLUMN,
  MX_RECORDS_COLUMN,
  REGISTRATION_DATE_COLUMN,
  REGISTRAR_COLUMN,
  REGISTRANT_COLUMN,
  NAMESERVERS_COLUMN,
  LAST_UPDATED_COLUMN,
  SFB_DETECTED_COLUMN,
  TAGS_AUTHOR_COLUMN,
  TAGS_COLUMN,
  WEB_PLAYBOOK_RECENT_CHANGES_COL,
  RISK_COLUMN as WEB_RISK_COLUMN,
  SOURCE_URL_COLUMN,
  SCAN_SOURCE_COLUMN,
  FIRST_SEEN_COLUMN as WEB_FIRST_SEEN_COLUMN,
  HOSTING_COLUMN,
  CURRENT_DISPOSITION_COLUMN,
  USER_REQUESTED_TAKEDOWN_COLUMN,
  COUNTRY_COLUMN,
  PAST_PHISH_ON_HOST_COLUMN,
  PAST_PHISH_ON_IP_COLUMN,
  BOLSTER_SCANS_COLUMN,
  DISPUTE_STATUS_COLUMN,
  DISPOSITION_CHANGE_TIMESTAMP_COLUMN,
} from '../../MonitorAndTakedown/ColDefinition/malicious.columns';
import {
  APP_NAME,
  APP_STORE,
  APP_STORE_CATEGORY_COL,
  APP_STORE_COUNTRY_COL,
  APP_STORE_IP_COLUMN,
  APP_STORE_IS_LOGO_DETECTION_COL,
  APP_STORE_LAST_SCANNED,
  APP_STORE_SOURCE_COLUMN,
  APP_STORE_TAKENDOWN_COL,
  APP_STORE_URL_COLUMN,
  APP_STORE_TAKEDOWN_STATUS_COL,
  FIRST_SEEN_COLUMN as APP_STORE_FIRST_SEEN_COLUMN,
} from '../../AppStore/Components/Findings/TableColDefs/AppStoreColDefs';
import { EFindingStatus } from '../../Ugc/Types/ugc.types';
import { CUSTOMER_SCANS_COLUMN } from '../../DomainMonitoring/columns';
export const WEB_PLAYBOOK_TABLE_ID = 'web_playbook_automation';
export const DARKWEB_PLAYBOOK_TABLE_ID = 'dark_web_playbook_automation';
export const APP_STORE_PLAYBOOK_TABLE_ID = 'app_store_playbook_automation';
export const SOCIAL_PLAYBOOK_TABLE_ID = 'social_playbook_automation';

const UGC_PLAYBOOK_FILTERS_COL = [
  {
    id: 'url',
    accessor: 'url',
    header: 'URL',
    fieldForPlaybook: 'url',
  },
  UGC_SOURCE_COLUMN,
  { ...MATCH_SEARCH_TERMS_COLUMN, id: 'search_term', accessor: 'search_term_id' },
  { ...UGC_ORIGIN_COLUMN, accessor: 'origin_id' },
  IS_LOGO_DETECTION_COL,
  { ...UGC_PLATFORM_COLUMN, accessor: 'platform_id' },
  { ...UGC_CATEGORY_COLUMN, accessor: 'category_id' },
  { ...FIRST_FOUND_COLUMN, dateRangeFilterForPlaybook: 'create' },
  { ...TAKEDOWN_TIME_COLUMN, dateRangeFilterForPlaybook: 'takedown_ts' },
  {
    ...TAKEDOWN_STATUS_COLUMN,
    filterOptions: [
      { label: 'Live Detections', value: EFindingStatus.LIVE },
      { label: 'Takedown In Progress', value: EFindingStatus.IN_PROGRESS },
      { label: 'Takedown Paused', value: EFindingStatus.PAUSED },
      { label: 'Taken Down', value: EFindingStatus.DOWN },
      { label: 'Ignored', value: EFindingStatus.SAFELIST },
    ],
  },
  { ...TAGS_COLUMN, playbookFilterId: 'tags_label' },
];
export const PLAYBOOK_PROPERTY_TYPE = {
  web: 'WEB',
  'dark-web': 'DARK_WEB',
  social: 'SOCIAL',
  marketplace: 'MARKET_PLACE',
  'app-store': 'APP_STORE',
};

export const Playbook_Filter_Controller: any = {
  WEB: [
    { ...SOURCE_URL_COLUMN?.(''), playbookFilterId: 'srcUrl' },
    { ...BRAND_COLUMN, accessor: 'sub_brand_id', playbookFilterId: 'subBrandId' },
    WEB_IP_COLUMN?.(''),
    { ...HOSTING_COLUMN, fieldForPlaybook: 'asDescription' },
    DISPOSITION_COLUMN?.(false),
    CURRENT_DISPOSITION_COLUMN,
    WEB_FIRST_SEEN_COLUMN,
    USER_REQUESTED_TAKEDOWN_COLUMN,
    TAKEDOWN_REQUEST_DATE_COLUMN,
    TAKEDOWN_REQUESTER_EMAIL_COLUMN,
    TAKE_DOWN_TIME_COLUMN,
    TLD_COLUMN,
    COUNTRY_COLUMN,
    PAST_PHISH_ON_HOST_COLUMN,
    PAST_PHISH_ON_IP_COLUMN,
    LOGO_DETECTION_COLUMN,
    CUSTOMER_SCANS_COLUMN,
    BOLSTER_SCANS_COLUMN,
    DISPOSITION_CHANGE_TIMESTAMP_COLUMN,
    WEB_RISK_COLUMN,
    LAST_UPDATED_COLUMN,
    WEB_PLAYBOOK_RECENT_CHANGES_COL,
    { ...WEB_CATEGORY_COLUMN, playbookFilterId: 'finalCategory' },
    { ...MX_RECORDS_COLUMN, playbookFilterId: 'hasMxRecords' },
    { ...REGISTRATION_DATE_COLUMN, dateRangeFilterForPlaybook: 'domain_registration_date' },
    { ...REGISTRAR_COLUMN, playbookFilterId: 'primaryDomainWhois.registrar' },
    {
      ...REGISTRANT_COLUMN,
      playbookFilterId: 'primaryDomainWhois.registrantOrganization',
    },
    {
      ...NAMESERVERS_COLUMN,
      playbookFilterId: 'primaryDomainWhois.nameservers',
    },
    { ...SFB_DETECTED_COLUMN, playbookFilterId: 'sfbDetected' },
    { ...SCAN_SOURCE_COLUMN, header: 'Scan Source' },
    {
      ...TAGS_COLUMN,
      playbookFilterId: 'tags_label',
      fieldForExport: 'tags_label',
    },
    {
      ...TAGS_AUTHOR_COLUMN,
      playbookFilterId: 'tags_updated_by',
    },
  ],
  APP_STORE: [
    APP_NAME,
    APP_STORE,
    { ...APP_STORE_CATEGORY_COL, fieldForExport: 'platform_id', accessor: 'platform_id' },
    {
      id: 'srcUrl',
      accessor: 'src_url',
      header: 'Source URL',
      fieldForPlaybook: 'src_url',
    },
    {
      id: 'ip',
      accessor: 'ip',
      header: 'IP Address',
      type: 'ipv4',
      fieldForPlaybook: 'ip',
      dateRangeFilterForPlaybook: 'ipAddressStart',
    },
    APP_STORE_SOURCE_COLUMN,
    APP_STORE_FIRST_SEEN_COLUMN,
    {
      ...APP_STORE_IS_LOGO_DETECTION_COL,
      header: 'Logo Detected',
      id: 'brand_logo_detected',
      fieldForPlaybook: 'brand_logo_detected',
      type: 'options',
      filterOptions: [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ],
    },
    {
      ...APP_STORE_LAST_SCANNED,
      dateRangeFilterForPlaybook: 'create',
      fieldForPlaybook: 'created_ts',
    },
    { ...APP_STORE_COUNTRY_COL, fieldForPlaybook: 'country_code', accessor: 'country_code' },
    { ...APP_STORE_TAKENDOWN_COL, type: 'date', dateRangeFilterForPlaybook: 'takedown' },
    {
      ...TAKEDOWN_STATUS_COLUMN,
      accessor: 'app_store_status',
      filterOptions: [
        { label: 'Live Detections', value: EFindingStatus.LIVE },
        { label: 'Takedown In Progress', value: EFindingStatus.IN_PROGRESS },
        { label: 'Takedown Paused', value: EFindingStatus.PAUSED },
        { label: 'Taken Down', value: EFindingStatus.DOWN },
        { label: 'Ignored', value: EFindingStatus.SAFELIST },
      ],
    },
    { ...TAGS_COLUMN, playbookFilterId: 'tags_label' },
  ],
  DARK_WEB: [
    {
      ...TITLE_COLUMN,
      fieldForPlaybook: 'title',
    },
    {
      ...PLATFORM_COLUMN,
      header: 'Data Leak Source',
      fieldForPlaybook: 'platform',
    },
    {
      ...RISK_COLUMN,
      fieldForPlaybook: 'risk_level',
      filterOptions: _.map(EDarkWebRiskLevel, riskLevel => ({
        value: riskLevel.toUpperCase(),
        label: riskLevel,
      })),
    },
    {
      ...CATEGORY_COLUMN,
      fieldForPlaybook: 'category',
      type: 'options',
      filterDisabled: false,
    },
    {
      ...STATUS_COLUMN,
      filterDisabled: false,
      fieldForPlaybook: 'status',
      type: 'options',
      filterOptions: [
        {
          label: EDarkWebFindingStatus.ACTIVE,
          value: EDarkWebFindingStatus.ACTIVE,
        },
        {
          label: EDarkWebFindingStatus.MITIGATED,
          value: EDarkWebFindingStatus.MITIGATED,
        },
        {
          label: EDarkWebFindingStatus.IGNORED,
          value: EDarkWebFindingStatus.IGNORED,
        },
      ],
    },
    {
      ...SEARCH_TERM_COLUMN,
      id: 'search_term',
      fieldForPlaybook: 'search_term_id',
      accessor: 'search_term_id',
    },
    {
      ...THREAT_ACTOR_COLUMN,
      fieldForPlaybook: 'threat_actor',
    },
    {
      ...DISCOVERY_DATE_COLUMN,
      type: 'date',
      fieldForPlaybook: 'platform_published_ts',
      dateRangeFilterForPlaybook: 'platform_published_ts_start',
    },
    {
      ...SENSITIVE_DATA_EXISTS_COLUMN,
      accessor: 'has_sensitive_data',
    },
    DARK_WEB_SSN,
    DARK_WEB_IP,
    DARK_WEB_CRYPTO,
    {
      ...TAGS_COLUMN,
      playbookFilterId: 'user_tags_label',
      fieldForPlaybook: 'user_tags_label',
    },
    {
      header: 'Email',
      id: 'email',
      accessor: 'email',
      type: 'string',
      fieldForPlaybook: 'email',
    },
    PASSWORD_COLUMN,
    { ...PASSWORD_TYPE_COLUMN, header: 'Password Type' },
    // { TODO: Uncomment when exp_date filter is discussed properly
    //   ...EXP_DATE_COLUMN,
    //   dateRangeFilterForPlaybook: 'exp_date',
    //   header: 'Expired card',
    //   filterOptions: [
    //     { label: 'True', value: 'true' },
    //     { label: 'False', value: 'false' },
    //   ],
    // },
    CCN_COLUMN,
    CVV_COLUMN,
    DARK_WEB_LAST_SCANNED_COLUMN,
  ],
  SOCIAL: [...UGC_PLAYBOOK_FILTERS_COL],
  MARKET_PLACE: [...UGC_PLAYBOOK_FILTERS_COL],
};
