import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGlobalSeARCHState {
  currentGlobalSearchSubTab: string;
}
const initialState: IGlobalSeARCHState = {
  currentGlobalSearchSubTab: '',
};

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setCurrentGlobalSearchSubTab: (state, action: PayloadAction<string>) => {
      state.currentGlobalSearchSubTab = action.payload;
    },
  },
});

export const { setCurrentGlobalSearchSubTab } = globalSearchSlice.actions;
export default globalSearchSlice.reducer;
