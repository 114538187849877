import React from 'react';
import { IThreatTypeDetails } from '../constants';
import { AttachmentAnalysis } from './AttachmentAnalysis';
import { IntentAnalysis } from './IntentAnalysis';
import { PhoneNumberAnalysis } from './PhoneNumberAnalysis';
import { SenderDomainAnalysis } from './SenderDomainAnalysis';

interface threatTypeInsightsProps {
  savedAbuseMailBoxThreatData: IThreatTypeDetails;
}

export const ThreatTypeInsights: React.FC<threatTypeInsightsProps> = ({
  savedAbuseMailBoxThreatData,
}) => {
  const { threatType } = savedAbuseMailBoxThreatData;

  switch (threatType) {
    case 'attachment':
      return <AttachmentAnalysis attachmentDetails={savedAbuseMailBoxThreatData} />;
    case 'phone_number':
      return <PhoneNumberAnalysis phone_numberDetails={savedAbuseMailBoxThreatData} />;
    case 'intent':
      return <IntentAnalysis intentDetails={savedAbuseMailBoxThreatData} />;
    case 'sender_domain':
      return <SenderDomainAnalysis senderDomainDetails={savedAbuseMailBoxThreatData} />;
    default:
      return null;
  }
};
