import React, { useEffect, useMemo, useState } from 'react';
import { LabelAndValue } from './LabelAndValue';
import { UpgradeV1, UpgradeV2 } from './Upgrade';
import moment from 'moment';
import { withCommas } from '../../constants';
import { Button, Modal, Spinner } from 'react-bootstrap';
import StripeService from '../../services/stripe.service';
import TogglePlan from '../Pricing/TogglePlan';
import { PRICING_PAGE_PATH, TogglePlanType } from '../Pricing/constants';
import { useContext } from 'react';
import CheckPhishContactUsContext, {
  CheckphishSubscriptionContext,
  ICheckPhishSubscription,
} from '../../context/CheckPhishContactUsContext';
import _ from 'lodash';
import { ISubsciptionPlan, PlanTerm, PRICING_PLANS } from '../Pricing/types';
import { generatePrice } from '../Pricing/helpers';
import { IPricingPlan } from '../Pricing/types';
import { LoadingWrapper } from './LoadingWrapper';
import { history } from '../../helpers';
import PricingPlan from '../Pricing/PricingPlan';

export enum EPlanProduct {
  LIVE_SCAN = 'Live Scan',
  TYPOSQUATTING = 'Typosquatting',
}

interface ISubscriptionProps {
  user: any;
  callbackUserInfo: () => void;
  showMultipleSubs?: boolean;
}

const Subscription = ({ user, callbackUserInfo, showMultipleSubs = false }: ISubscriptionProps) => {
  const [cancelModalShown, setCancelModalShown] = useState<boolean>(false);
  const [subscriptionDone, setSubscriptionDone] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<string>('');
  let perDay,
    perMonth,
    subPrice,
    subTerm,
    startedPSTDate,
    billingStartedPSTDate,
    renewalStartedPSTDate;
  if (user && user.subscription) {
    perDay = `${withCommas(user.subscription.scansPerDay)} scans per day`;
    perMonth = `${withCommas(user.subscription.scansPerMonth)} scans per month`;
    subPrice = `$${withCommas(user.subscription.price)}`;
    subTerm = user.subscription.term === 'yearly' ? ' per year' : ' per month';
    startedPSTDate = moment(user.subscription.activatedTs).format('MM/DD/YY');
    billingStartedPSTDate = moment(user.subscription.subscription_start_date).format('MM/DD/YY');
    renewalStartedPSTDate = moment(user.subscription.subscription_end_date).format('MM/DD/YY');
  }

  const cancelSubscription = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setProcessing(true);

    const stripeService: StripeService = new StripeService();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cancelSubscriptionClicked',
      category: 'Page',
      action: 'clicked',
      label: 'cancelSubscriptionClicked',
    });

    stripeService
      .cancelSubscription()
      .then(() => {
        setSubscriptionDone(true);
        setModalBody('You have successfully cancelled your subscription');
        setTimeout(() => {
          callbackUserInfo();
        }, 300);
      })
      .catch(() => {
        setModalBody('There was an error cancelling your subscription. Please try again later.');
      });
  };

  const showCancelModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    toggleCancelModal();
    setModalBody('Are you sure you want to cancel the subscription?');
  };

  const toggleCancelModal = () => {
    if (cancelModalShown === true && subscriptionDone) {
      setSubscriptionDone(false);
    }
    setCancelModalShown(!cancelModalShown);
    setProcessing(false);
  };

  return (
    <div className={'section-container'}>
      <h6 className={'section-title'}>Subscription</h6>
      <br />
      {user && user.subscription && (
        <>
          <LabelAndValue
            label={'Plan'}
            renderDom={
              <div>
                <div className='card-value card-text'>Professional API</div>
                <div className='card-value card-text'>
                  {perDay}, {perMonth}
                </div>
                <div className='card-value card-text'>
                  {subPrice} {subTerm}
                </div>
              </div>
            }
            direction={'column'}
          />
          {startedPSTDate && (
            <LabelAndValue label={'Enrollment Date'} value={startedPSTDate} direction={'column'} />
          )}
          {billingStartedPSTDate && (
            <LabelAndValue
              label={'Billing Cycle Start Date'}
              value={billingStartedPSTDate}
              direction={'column'}
            />
          )}
          {renewalStartedPSTDate && (
            <LabelAndValue
              label={'Renewal Date'}
              value={renewalStartedPSTDate}
              direction={'column'}
            />
          )}
          <div className='label-and-value-component'>
            <UpgradeV1 className={'mb-3'} />
          </div>
          <div className='label-and-value-component'>
            <div className='card-label card-text'>
              <a href='' onClick={showCancelModal}>
                Cancel Subscription
              </a>
            </div>
          </div>
        </>
      )}
      {user && !user.subscription && (
        <>
          <LabelAndValue
            label={'Plan'}
            renderDom={
              <div>
                <div className='card-value card-text'>Essential API</div>
                <div className='card-value card-text'>25 scans per day, 250 scans per month</div>
              </div>
            }
            direction={'column'}
          />
          {billingStartedPSTDate && (
            <LabelAndValue
              label={'Start Date'}
              value={billingStartedPSTDate}
              direction={'column'}
            />
          )}
          <div className='label-and-value-component'>
            <UpgradeV1 className={'mb-3'} />
          </div>
        </>
      )}
      <Modal show={cancelModalShown} onHide={toggleCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          {!subscriptionDone && (
            <Button disabled={processing} variant='primary mr-3' onClick={cancelSubscription}>
              Yes, unsubscribe me
            </Button>
          )}

          <Button variant='outline-secondary' onClick={toggleCancelModal}>
            {subscriptionDone ? 'Close' : 'No'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const SubscriptionV2 = ({
  user,
  callbackUserInfo,
  showMultipleSubs = false,
}: ISubscriptionProps) => {
  const [cancelModalShown, setCancelModalShown] = useState<boolean>(false);
  const [subscriptionDone, setSubscriptionDone] = useState<boolean>(false);
  const [liveScanSubscriptionPlan, setLiveScanSubscriptionPlan] = useState<any>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<string>('');
  const [label, setLabel] = useState<any>(undefined);
  const [startedPSTDate, setStartedPSTDate] = useState<any>(undefined);
  const [billingStartedPSTDate, setBillingStartedPSTDate] = useState<any>(undefined);
  const [renewalStartedPSTDate, setRenewalStartedPSTDate] = useState<any>(undefined);
  const [perDay, setPerDay] = useState<any>(undefined);
  const [subPrice, setSubPrice] = useState<any>(undefined);
  const [subTerm, setSubTerm] = useState<any>(undefined);
  const { setShowFeedbackPopup } = useContext(CheckPhishContactUsContext);
  const {
    currentLiveScanSubscription,
    currentTyposquatSubscription,
    selectedPlans,
    selectedTerm,
    getCurrentPlanName,
    subscription,
  } = useContext<ICheckPhishSubscription>(CheckphishSubscriptionContext);
  const stripeService: StripeService = new StripeService();
  const [allPlans, setAllPlans] = useState<IPricingPlan[]>([]);

  useEffect(() => {
    if (user && user.subscription) {
      const tempLiveScanSubscriptionPlan = user.subscription.plans.filter(
        (plan: any) => plan.product === EPlanProduct.LIVE_SCAN,
      )[0];
      setLiveScanSubscriptionPlan(tempLiveScanSubscriptionPlan);
      if (tempLiveScanSubscriptionPlan) {
        setLabel('Professional API');
        setPerDay(
          `${withCommas(
            tempLiveScanSubscriptionPlan.limit * tempLiveScanSubscriptionPlan.quantity,
          )} scans per day`,
        );
        setSubPrice(
          `$${withCommas(
            tempLiveScanSubscriptionPlan.limit *
              tempLiveScanSubscriptionPlan.quantity *
              tempLiveScanSubscriptionPlan.price,
          )}`,
        );
        setSubTerm(tempLiveScanSubscriptionPlan.term === 'yearly' ? ' per year' : ' per month');
        setStartedPSTDate(moment(user.subscription.activatedTs).format('MM/DD/YY'));
        setBillingStartedPSTDate(
          moment(tempLiveScanSubscriptionPlan.subscriptionStartDate).format('MM/DD/YY'),
        );
        setRenewalStartedPSTDate(
          moment(tempLiveScanSubscriptionPlan.subscriptionEndDate).format('MM/DD/YY'),
        );
      }
    } else {
      setLabel('Essential API');
      setPerDay('25 scans per day');
      setLiveScanSubscriptionPlan(undefined);
      setSubPrice(undefined);
      setSubTerm(undefined);
      setStartedPSTDate(undefined);
      setBillingStartedPSTDate(undefined);
      setRenewalStartedPSTDate(undefined);
    }
  }, [user]);

  useEffect(() => {
    stripeService.getPlans('v2').then(({ plans, productBundles }) => {
      const generatedPlans = plans.map((item: any) => ({
        priceId: item.id,
        name: item.name,
        description: item.description,
        term: item.term,
        limit: item.limit,
        price: item.price,
        discounts: item.discounts || null,
        product: item.product,
      }));
      setAllPlans(generatedPlans);
    });
  }, [user?.subscription?.plans]);

  const cancelSubscription = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setProcessing(true);
    const stripeService: StripeService = new StripeService();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cancelSubscriptionClicked',
      category: 'Page',
      action: 'clicked',
      label: 'cancelSubscriptionClicked',
    });

    stripeService
      .cancelSubscription('v2')
      .then(() => {
        setSubscriptionDone(true);
        setModalBody('You have successfully cancelled your subscription');
        setTimeout(() => {
          setCancelModalShown(false);
          callbackUserInfo();
        }, 300);
      })
      .catch(() => {
        setModalBody('There was an error cancelling your subscription. Please try again later.');
      });
  };

  const getPlan = (id: number) => {
    return allPlans.find((plan: any) => plan.priceId === id);
  };

  const showCancelModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    toggleCancelModal();
    setModalBody('Are you sure you want to cancel the subscription?');
  };

  const toggleCancelModal = () => {
    if (cancelModalShown === true && subscriptionDone) {
      setSubscriptionDone(false);
    }
    setCancelModalShown(!cancelModalShown);
    setProcessing(false);
  };

  const [selectedTypoSquatPlan, selectedLiveScanPlan] = useMemo(() => {
    const selectedTypoSquatPlan = selectedPlans.find(
      (planItem: ISubsciptionPlan) =>
        planItem.product === EPlanProduct.TYPOSQUATTING && planItem.term === selectedTerm.value,
    );

    const selectedLiveScanPlan = selectedPlans.find(
      (planItem: ISubsciptionPlan) =>
        planItem.product === EPlanProduct.LIVE_SCAN && planItem.term === selectedTerm.value,
    );

    return [selectedTypoSquatPlan, selectedLiveScanPlan];
  }, [selectedPlans]);

  const getSlectedPlanPricing = () => {
    let starterTotal = 0;
    let typoTotal = 0;
    if (selectedTypoSquatPlan) {
      typoTotal = generatePrice(selectedTypoSquatPlan, selectedTypoSquatPlan.quantity);
    }
    if (selectedLiveScanPlan) {
      starterTotal = generatePrice(selectedLiveScanPlan, selectedLiveScanPlan.quantity);
    }
    return typoTotal + starterTotal;
  };

  const getPlanterm = (): string => {
    if (selectedTerm.value === PlanTerm.MONTHLY) {
      return 'month';
    }
    return 'year';
  };

  return (
    <div className={'section-container'} data-testid='subscription-v2'>
      <h6 className={'section-title'}>Subscription</h6>
      <LoadingWrapper isLoading={_.isEmpty(allPlans)} size='sm'>
        <br />
        {showMultipleSubs ? (
          <>
            {!_.isEmpty(user?.subscription?.plans) && !_.isEmpty(allPlans) ? (
              <div className='plans-wrapper'>
                {!_.isEmpty(currentLiveScanSubscription) ? (
                  <div className='plan-item'>
                    <div className='plan-name'>Professional API</div>
                    <div className='plan-details'>
                      {currentLiveScanSubscription?.quantity || 0} Scans per{' day'}
                    </div>
                    {!_.isEmpty(getPlan(currentLiveScanSubscription.id)) && (
                      <div className='plan-price'>
                        {'$ '}
                        {generatePrice(
                          getPlan(currentLiveScanSubscription.id),
                          currentLiveScanSubscription.quantity,
                        )}
                        {' per '}
                        {currentLiveScanSubscription?.term === PlanTerm.YEARLY ? 'year' : 'month'}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className='plan-item'>
                      <div className='plan-name'>Professional API {` ( Free Plan ) `}</div>
                      <div className='plan-details'>
                        25 Scans per{' Day'} <br />{' '}
                      </div>
                    </div>
                  </>
                )}
                {!_.isEmpty(currentTyposquatSubscription) && (
                  <div className='plan-item'>
                    <div className='plan-name'>
                      {_.capitalize(getCurrentPlanName(EPlanProduct.TYPOSQUATTING)) + ' Plan'}
                    </div>
                    <div className='plan-details'>
                      {currentTyposquatSubscription?.quantity || 0}{' '}
                      {currentTyposquatSubscription?.quantity == 1 ? 'Domain' : 'Domains'}
                    </div>
                    {!_.isEmpty(getPlan(currentTyposquatSubscription.id)) && (
                      <div className='plan-price'>
                        {'$ '}
                        {generatePrice(
                          getPlan(currentTyposquatSubscription.id),
                          currentTyposquatSubscription.quantity,
                        )}
                        {' per '}
                        {currentLiveScanSubscription?.term === PlanTerm.YEARLY ? 'year' : 'month'}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <>
                <>
                  <div className='plan-item' style={{ marginBottom: '10px' }}>
                    <div className='plan-name'>Professional API {` ( Free Plan ) `}</div>
                    <div className='plan-details'>
                      25 Scans per{' Day'} <br />{' '}
                    </div>
                  </div>
                </>
              </>
            )}
          </>
        ) : (
          <LabelAndValue
            label={'Plan'}
            renderDom={
              <div>
                <div className='card-value card-text' data-testid='subscription-v2-label'>
                  {label}
                </div>
                <div className='card-value card-text' data-testid='subscription-v2-perDay'>
                  {perDay}
                </div>
                {liveScanSubscriptionPlan && (
                  <div className='card-value card-text'>
                    {subPrice} {subTerm}
                  </div>
                )}
              </div>
            }
            direction={'column'}
          />
        )}

        {!_.isEmpty(subscription?.subscriptionStartDate) && (
          <LabelAndValue
            label={'Billing Cycle Start Date'}
            value={moment(subscription.subscriptionStartDate).format('YYYY-MMM-DD')}
            direction={'column'}
          />
        )}
        {!_.isEmpty(subscription?.subscriptionEndDate) && (
          <LabelAndValue
            label={'Renewal Date'}
            value={moment(subscription.subscriptionEndDate).format('YYYY-MMM-DD')}
            direction={'column'}
          />
        )}
      </LoadingWrapper>
      <div className='label-and-value-component'>
        <Button onClick={() => history.push(PRICING_PAGE_PATH)}>Upgrade</Button>
      </div>
      {!_.isEmpty(user?.subscription?.plans) && (
        <div className='label-and-value-component'>
          <div className='card-label card-text'>
            <a href='' data-testid='subscription-v2-cancel-modal' onClick={showCancelModal}>
              Cancel Subscription
            </a>
          </div>
        </div>
      )}

      {cancelModalShown && (
        <TogglePlan
          popUpType={TogglePlanType.CANCEL}
          cardTitle='Cancel your plan'
          actionButtonText={'Cancel plan'}
          afterSuccessFn={callbackUserInfo}
          onGoBackActionHandler={() => {
            setCancelModalShown(false);
          }}
        />
      )}
    </div>
  );
};

export { Subscription, SubscriptionV2 };
