import React, { useCallback, useState, useRef, useMemo } from 'react';
import AttachFile from '../../Common/AttachFile';
import { RadioButtons } from '../../Common/RadioButtons';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import DashboardService from '../../../services/dashboard.service';
import { AlertActionsTypes, appConstants, featureIsAvailable } from '../../../constants';
import { DISPUTE_DISPOSITION_OPTIONS } from '../../Common/DisputeStatus';
import TextArea from '../../Common/TextArea';
import { IBulkDisputePayload, BulkScanSelectedItem, IUrlDetails } from '../BulkScanConstants';
import { WebUrlData } from '../../../types/web-url-data.interface';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { Button } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';
import { Form } from 'react-bootstrap';
import { Dropdown, IDropdownOption } from '../../Common/Dropdown';
import { setShouldTriggerAgGrigRefresh } from '../../../reducers/table.reducer';

const dashboardService = new DashboardService();

interface IDisputeDispositionModalProps {
  data: BulkScanSelectedItem[] | WebUrlData[];
  show: boolean;
  submitButtonLabel?: string;
  onCloseModal: () => void;
  onSuccess: (res: any, msg: string) => void;
  onFailure: (message: string) => AlertActionsTypes;
  bulkScanPage: boolean;
}

interface IDispute {
  disposition?: string;
  comments?: string;
}

const DisputeDispositionModal = ({
  data,
  show,
  onCloseModal,
  onSuccess,
  onFailure,
  bulkScanPage,
}: IDisputeDispositionModalProps) => {
  const [dispute, setDispute] = useState<IDispute>({});
  const [sendingDisputeRequest, setSendingDisputeRequest] = useState(false);
  const [disputeError, setDisputeError] = useState<string>('');

  const uploadedAttachedFilesDispute = useRef<File[]>([]);
  const [, selectedTheme] = useIsDarkMode();
  const { user } = useAppSelector(state => state.dashboardReducer);
  const brandInfo = useAppSelector(state => state.dashboardReducer.user)?.brand_info.brand;
  const [selectedBrandMnemonic, setSelectedBrandMnemonic] = useState<string>(
    brandInfo?.subBrands?.length === 1 ? brandInfo?.subBrandNames[0] : '',
  );

  const dispatch = useAppDispatch();

  const setDisputeState = useCallback(
    (key: string, value: string) => {
      setDispute({ ...dispute, [key]: value });
      setDisputeError('');
    },
    [dispute],
  );

  const submitDisputeVerdict = useCallback(() => {
    const { disposition, comments } = dispute;

    if (!disposition) {
      setDisputeError('Please select a disposition.');
      return;
    }

    const disputeData = new FormData();
    const urlDetails: IUrlDetails[] = data.map((item: any) => {
      return {
        srcUrl: item.url || item.src_url,
        urlSHA256: item.url_sha256 || item.urlSHA256,
        currentDisposition: item.disposition,
        currentBrand: item.brandId,
        primaryDomain: item.primaryDomain || item.primary_domain,
        abuseContactEmailList: 'platform@mail.bolster.ai',
        ccEmailList: 'platform@mail.bolster.ai',
        reporter: selectedBrandMnemonic,
        tld: item.tld,
        reporterEmail: user.email,
        status: item.status,
        reportingEntity: 'user',
        googleSafeBrowsing: false,
        apwgBlocklist: false,
        createdTS: item.created_ts || item.createdTs,
        dstUrl: item.dstUrl ?? '',
      };
    });

    disputeData.append('customerDisposition', disposition);
    disputeData.append('comments', comments || '--');

    //New Properties for dispute verdict submission
    disputeData.append('brandMnemonic', selectedBrandMnemonic);
    disputeData.append('urlDetails', JSON.stringify(urlDetails));

    for (let i = 0; i < uploadedAttachedFilesDispute.current.length; i++) {
      disputeData.append('files', uploadedAttachedFilesDispute.current[i]);
    }
    setSendingDisputeRequest(true);
    dashboardService
      .submitDisputeVerdict(disputeData)
      .then(res => {
        setSendingDisputeRequest(false);
        dispatch(setShouldTriggerAgGrigRefresh(true));
        setDispute({});
        onSuccess(res, 'Dispute Request Sent Successfully!');
        onCloseModal();
      })
      .catch(err => {
        setSendingDisputeRequest(false);
        onFailure(err);
      });
  }, [
    dispatch,
    dispute,
    data,
    selectedBrandMnemonic,
    onSuccess,
    onFailure,
    onCloseModal,
    user.email,
  ]);

  const disputeOptions = useMemo(() => {
    const disputeLabelAndValue: { label: string; value: string }[] = [];
    for (const value in DISPUTE_DISPOSITION_OPTIONS) {
      disputeLabelAndValue.push({ label: DISPUTE_DISPOSITION_OPTIONS[value], value });
    }
    return disputeLabelAndValue;
  }, []);

  const isOldTable = !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

  const handleBrandInputChange = (selection: IDropdownOption) => {
    setSelectedBrandMnemonic(selection.value);
  };

  const brandOptions = useMemo(() => {
    return brandInfo?.subBrands?.map((brand: any) => {
      return {
        label: brand.brandDisplayName,
        value: brand.brandName,
      };
    });
  }, [brandInfo]);

  return (
    <Modal show={isOldTable ? show : true} className={`${selectedTheme}`}>
      <Modal.Header closeButton={false}>
        <Modal.Title>Dispute Disposition</Modal.Title>
        <div className='close' onClick={onCloseModal}>
          <ClearRoundedIcon />
        </div>
      </Modal.Header>
      <LoadingWrapper isLoading={isOldTable ? false : !show}>
        <Modal.Body>
          {disputeError && <div className={'mb-2 text-danger'}>{disputeError}</div>}
          <div className='pb-3'>
            Please be sure you only selected ULRs that impact your own brand
          </div>
          <RadioButtons
            label='Please select what you believe to be the correct disposition.'
            options={disputeOptions}
            onChange={disposition => setDisputeState('disposition', disposition)}
            direction={'row'}
          />
          {brandInfo?.brandMapping === true && brandInfo?.subBrands?.length > 1 ? (
            <Form.Group>
              <Form.Label>Select Brand:</Form.Label>
              <Dropdown
                boxStyle
                options={brandOptions as IDropdownOption[]}
                onChange={handleBrandInputChange}
                key={'select-brand'}
                data-testid='select-brand'
              />
            </Form.Group>
          ) : null}
          <AttachFile
            label='Attach File'
            onChangeHandler={files => (uploadedAttachedFilesDispute.current = files)}
          />
          <TextArea
            label='Comments'
            onChange={value => {
              setDisputeState('comments', value);
            }}
          />
        </Modal.Body>
      </LoadingWrapper>
      <Modal.Footer>
        <Button variant='outlined' onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={submitDisputeVerdict}
          disabled={isOldTable ? sendingDisputeRequest : sendingDisputeRequest || !show}
        >
          {sendingDisputeRequest ? (
            <Spinner className='spinner' animation='border' variant='light' size='sm' />
          ) : (
            'Submit'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisputeDispositionModal;
