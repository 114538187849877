import { history } from '../../../../helpers';
import { useAppSelector } from '../../../../helpers/hooks';
import {
  getIsAppStoreModuleAvailable,
  getIsDarkWebModuleAvailable,
  getIsSocialMediaModuleAvailable,
} from '../../../../helpers/permissions';

import { MARKET_PLACE_FINDING_REVIEW_TABLE_ID } from '../../../Marketplace/constants';
import {
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
} from '../../../SocialMedia/constants';

export const redirectToTable = (pathname: string, key: string, values: string) => {
  history.push({
    pathname,
    search: `?targetKey=${key}&targetValues=${values}`,
  });
};

// AS per ZION-4354
export const enableSocCheckbox = (id: string) => {
  return (
    id === SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID ||
    id === SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID ||
    id === MARKET_PLACE_FINDING_REVIEW_TABLE_ID
  );
};

export const useModulePermissionTeaserPage = (featureName: string) => {
  const user = useAppSelector(state => state.dashboardReducer.user);
  const isSocialMediaModuleAvailable = getIsSocialMediaModuleAvailable(user);
  const isAppStoreModuleAvailable = getIsAppStoreModuleAvailable(user);
  const isDarkWebModuleAvailable = getIsDarkWebModuleAvailable(user);
  if (
    (featureName === 'Social Media' && !isSocialMediaModuleAvailable) ||
    (featureName === 'App Store' && !isAppStoreModuleAvailable) ||
    (featureName === 'Dark Web' && !isDarkWebModuleAvailable)
  ) {
    return true;
  }
  return false;
};
