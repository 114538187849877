import React, { useEffect, useMemo, useRef, useState } from 'react';
import './AllEmailSubmissions.scss';
import {
  DEFAULT_DATE_RANGE_V2,
  getLocalStorageValue,
  setLocalStorageValue,
} from '../../../constants';
import { DropdownDateRangePicker } from '../../Common/DropdownDateRangePicker';
import { appConstants } from '../../../constants';
import { AuthenticationWrapper } from '../..';
import { Tabs, Tab } from '@mui/material';
import TabPanel from './TabPanel';
import AllUrlAnalysis from './AllUrlAnalysis/AllUrlAnalysis';
import { ALL_EMAIL_PAGE_SECTION } from './constants';
import { PageTitle } from '../../Common/PageTitle';
import _ from 'lodash';
import { usePersistanceDate } from '../../../basic-hooks/usePersistanceDate';
import { ABUSE_MAILBOX_ALL_URL_ANALYSIS_DATE_PERSISTANCE_ID } from '../../Common/Table/ag-table/AgGridAbuseMailBoxTargetedMalicious';
import AllEmailAnalysis from './AllEmailAnalysis';
import { useAppSelector } from '../../../helpers/hooks';
import { useFeatureIsAvailable } from '../../../basic-hooks/useFeatures';

export const AllEmailSubmissions = () => {
  const { startDate, endDate, onDateChange } = usePersistanceDate(
    DEFAULT_DATE_RANGE_V2,
    ABUSE_MAILBOX_ALL_URL_ANALYSIS_DATE_PERSISTANCE_ID,
  );

  const initialTab = useMemo(() => {
    const tab = getLocalStorageValue(['currentTab', 'abuse-mailbox', 'all-email-submission']);
    return _.isEmpty(tab) ? ALL_EMAIL_PAGE_SECTION.ALL_EMAIL_ANALYSIS : tab;
  }, []);

  const [activeSection, setActiveSection] = useState<ALL_EMAIL_PAGE_SECTION>(initialTab);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setLocalStorageValue(['currentTab', 'abuse-mailbox', 'all-email-submission'], newValue);
    setActiveSection(newValue);
  };

  const isDemoBrand = useAppSelector(
    state => state.dashboardReducer.brandInfo?.brand?.brandName === 'bankofbolster',
  );

  const showAllUrlAnalysis = useFeatureIsAvailable(
    appConstants.FEATURE_CODE.ABUSE_MAILBOX_ALL_URL_ANALYSIS,
  );

  return (
    <AuthenticationWrapper redirectToCompletePath={true}>
      <div className='all-email-submissions py--end-0'>
        <div className='all-email-submissions-header'>
          <PageTitle
            title='All Email Submissions'
            tools={
              <DropdownDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={onDateChange}
                type={appConstants.CONTENT_TYPE.ABUSE_MAILBOX}
                currentPersistantDatePickerPage={ABUSE_MAILBOX_ALL_URL_ANALYSIS_DATE_PERSISTANCE_ID}
              />
            }
            className='px--start-0 py--top-0'
          />
          {showAllUrlAnalysis && (
            <Tabs value={activeSection} onChange={handleChange} className='tab-wrapper'>
              <Tab
                key={ALL_EMAIL_PAGE_SECTION.ALL_EMAIL_ANALYSIS}
                id={ALL_EMAIL_PAGE_SECTION.ALL_EMAIL_ANALYSIS}
                value={ALL_EMAIL_PAGE_SECTION.ALL_EMAIL_ANALYSIS}
                label={'ALL Email Analysis'}
                className={'abuse-mailbox-tab'}
              />
              <Tab
                key={ALL_EMAIL_PAGE_SECTION.ALL_URL_ANALYSIS}
                id={ALL_EMAIL_PAGE_SECTION.ALL_URL_ANALYSIS}
                value={ALL_EMAIL_PAGE_SECTION.ALL_URL_ANALYSIS}
                label={'ALL Url Analysis'}
                className={'abuse-mailbox-tab'}
              />
            </Tabs>
          )}
        </div>
        {showAllUrlAnalysis ? (
          <>
            <TabPanel
              index={ALL_EMAIL_PAGE_SECTION.ALL_EMAIL_ANALYSIS}
              value={activeSection}
              className={'abuse-mailbox-tab-panel'}
            >
              <AllEmailAnalysis isDemoBrand={isDemoBrand} startDate={startDate} endDate={endDate} />
            </TabPanel>
            <TabPanel
              index={ALL_EMAIL_PAGE_SECTION.ALL_URL_ANALYSIS}
              value={activeSection}
              className={'abuse-mailbox-tab-panel'}
            >
              <AllUrlAnalysis startDate={startDate} endDate={endDate} />
            </TabPanel>
          </>
        ) : (
          <AllEmailAnalysis isDemoBrand={isDemoBrand} startDate={startDate} endDate={endDate} />
        )}
      </div>
    </AuthenticationWrapper>
  );
};
