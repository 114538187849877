import React from 'react';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  colors,
  getColors,
  IColors,
  numberWithCommas,
  SMALLER_WIDGET_HEIGHT,
  WIDGET_HEIGHT,
} from '../../../constants';
import { LabelAndValue } from '../LabelAndValue';
import { LoadingWrapper } from '../LoadingWrapper';
import { IBarChartProps, IChartProps, IBarChartDataProps } from './Types/chart.type';
import { renderNoDataPlaceholder } from './LineChart';
import useIsDarkMode from '../CustomHooks/useIsDarkMode';

const tickAmount = 2;

const sortData = (data: IBarChartDataProps[], orders: string[]): IBarChartDataProps[] => {
  const result: IBarChartDataProps[] = [];
  for (let i = 0; i < orders.length; i++) {
    const item = _.find(data, { label: orders[i] });
    if (item) {
      result.push(item);
    }
  }
  return result;
};

const BarChart = ({
  title,
  isLoading,
  data,
  colorMap,
  className,
  placeholder,
  description,
  limit = 10,
  highLightValue,
  orders,
  showForSingleSeries = true,
  hideLegend = false,
  xaxisShow = true,
  plotOptionsHeight = '40%',
  showTotalData = false,
  alignLabel = 'left',
}: IChartProps & IBarChartProps) => {
  const [, selectedTheme] = useIsDarkMode();
  const chartColors: IColors = getColors(selectedTheme);
  const sortedData = orders?.length
    ? sortData(data, orders)
    : _.orderBy(data, i => i.total, 'desc');
  const displayedData: any[] = [];
  for (let i = 0; i < sortedData.length && i < limit; i++) {
    displayedData.push(sortedData[i]);
  }
  let xMax = 4;
  const itemNames = _.map(displayedData, i => i.label);
  const barColors: string[] = [];
  const series: { name: string; data: number[] }[] = [];

  if (displayedData.length) {
    xMax = Math.max(displayedData[0].total, 4);
    xMax += xMax % tickAmount;

    _.map(displayedData, (data, ind) => {
      _.map(data.categories, (category, i) => {
        const color =
          (colorMap && colorMap[category.label]) ||
          getColors(selectedTheme)['barChart' + (i + 1)] ||
          colors.grey;
        if (!barColors.includes(color)) {
          barColors.push(color);
        }
      });
    });

    _.forEach(displayedData, item => {
      _.forEach(item.categories, category => {
        if (!_.some(series, ['name', category.label])) {
          series.push({
            name: category.label,
            data: [],
          });
        }
      });
    });
    _.forEach(displayedData, item => {
      _.forEach(series, s => {
        const matchCategory = _.find(item.categories, ['label', s.name]);
        if (matchCategory) {
          s.data.push(matchCategory.count);
        } else {
          s.data.push(0);
        }
      });
    });
  }

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      fontFamily: 'Fakt',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: plotOptionsHeight,
        borderRadius: 4,
        borderRadiusWhenStacked: 'all',
        borderRadiusApplication: 'around',
        ...(showTotalData && {
          dataLabels: {
            total: {
              enabled: true,
              style: {
                color: chartColors.legendText,
              },
            },
          },
        }),
      },
    },
    stroke: {
      width: 1,
      colors: [chartColors.background],
    },
    xaxis: {
      categories: itemNames,
      labels: {
        show: xaxisShow,
        style: {
          colors: chartColors.axisLabel,
        },
        formatter: function (val: string) {
          return numberWithCommas(val);
        },
      },
      tickAmount,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      max: xMax,
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        align: alignLabel,
        style: {
          colors: chartColors.axisLabel,
        },
        maxWidth: 140,
        offsetX: -6,
      },
      show: displayedData.length > 0,
    },
    grid: {
      borderColor: chartColors.chartBorderColor,
      xaxis: {
        lines: {
          show: xaxisShow,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      theme: selectedTheme,
      custom: (tooltipData: any) => {
        const { dataPointIndex } = tooltipData;
        const currentData = displayedData[dataPointIndex];
        return (
          '<div class="hosting-widget-tooltip-container">' +
          '<div class="apexcharts-tooltip-title">' +
          currentData.label +
          '</div>' +
          _.map(currentData.categories, (category: any, i: number) => {
            const dotColor =
              (colorMap && colorMap[category.label]) || colors['barChart' + (i + 1)] || colors.grey;
            return (
              `<div class="d-flex align-items-center">` +
              `<div class="dot" style="background-color: ${dotColor};"></div>` +
              `${category.label}: <b class="pl-1">${numberWithCommas(category.count)}</b>` +
              '</div>'
            );
          }).join('') +
          '</div>'
        );
      },
    },
    fill: {
      colors: barColors,
      opacity: 1,
    },
    colors: barColors,
    legend: {
      show: !hideLegend,
      showForSingleSeries,
      position: 'top',
      horizontalAlign: 'left',
      offsetX: -35,
      floating: false,
      markers: {
        radius: 6,
      },
      labels: {
        colors: chartColors.legendText,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      formatter: (name: string, legendData: any) => {
        const { seriesIndex } = legendData;
        const value = _.sum(series[seriesIndex].data);
        return `<div>${name}: <b>${numberWithCommas(value)}</b></div>`;
      },
    },
  };

  return (
    <div className={'dashboard-widget dashboard-bar-chart ' + className}>
      {description ? (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + description} className={'table-source-url-tooltip'}>
              {description}
            </Tooltip>
          }
        >
          <div className='dashboard-chart-title widget-card--title'>{title}</div>
        </OverlayTrigger>
      ) : (
        <div className='dashboard-chart-title widget-card--title'>{title}</div>
      )}
      <LoadingWrapper isLoading={isLoading}>
        {displayedData.length ? (
          <>
            {highLightValue && (
              <LabelAndValue
                label={highLightValue.label}
                value={numberWithCommas(highLightValue.value)}
                renderDom={highLightValue.renderDom}
                direction={'column'}
                noMarginBottom
              />
            )}
            <ReactApexChart
              options={options}
              series={series}
              height={highLightValue ? SMALLER_WIDGET_HEIGHT : WIDGET_HEIGHT}
              type='bar'
            />
          </>
        ) : placeholder ? (
          renderNoDataPlaceholder(placeholder)
        ) : null}
      </LoadingWrapper>
    </div>
  );
};

export default BarChart;
