import React, { useState, createContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AppState } from '../helpers';
import UserService from '../services/user.service';
import {
  DashBoardDto,
  IAlertDispatchProps,
  UserAppSettings,
  IAppDispatchProps,
  setLocalStorageValue,
  getLocalStorageValue,
} from '../constants';
import { alertActions, appActions } from '../actions';
import DashboardService from '../services/dashboard.service';
import { SCANTYPE } from '../components/Checkphish/AllScans/scanType.enum';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
} from '../components/DomainMonitoring/constant';

const AppSettingContext = createContext({
  landingPage: '',
  defaultLandingPage: '',

  saveLandingPage: _.noop,
});
export default AppSettingContext;

interface IAppSettingProps {
  user: DashBoardDto;
  children: React.ReactElement;
}

type Prop = IAppSettingProps & IAlertDispatchProps & IAppDispatchProps;

const AppSettingContextProvider = (props: Prop): any => {
  const userService = new UserService();
  const { user, children, alertSuccess, alertError, runByClientApp } = props;

  const getLandingPage = useCallback(
    (user?: DashBoardDto) => {
      const isCommunity = runByClientApp({
        onBolster: () => false,
        onCheckPhish: () => true,
      });
      if (isCommunity) {
        if (user) {
          if (
            user.monitoredDomainPresent &&
            user.domainMonitoringDaysToExpire &&
            user.domainMonitoringDaysToExpire > 0
          ) {
            return TYPOSQUATTING_DASHBOARD_PATHNAME;
          } else {
            return TYPOSQUATTING_MASTER_PATHNAME;
          }
        }
      } else {
        if (user) {
          const landingPageSetting = _.find(user.userAppSetting, {
            setting_name: UserAppSettings.LadingPage,
          });
          if (landingPageSetting) {
            return landingPageSetting.setting_value;
          }
        }
        const landingPageURL = getLocalStorageValue('selectedLandingPage');
        if (!_.isEmpty(landingPageURL)) {
          return landingPageURL;
        }
      }

      // fallback landing page
      const landingUrl = runByClientApp({
        onBolster: () => '/web/dashboard',
        onCheckPhish: () => '/domain-monitoring/dashboard',
      });

      return landingUrl;
    },
    [runByClientApp],
  );

  const [defaultLandingPage] = useState(getLandingPage());
  const [landingPage, setLandingPage] = useState(getLandingPage());
  const updateUserSettings = (settingName: string, settingValue: any) => {
    return userService.updateUserSettings({
      setting_name: settingName,
      setting_value: settingValue,
    });
  };
  useEffect(() => {
    setLandingPage(getLandingPage(user));
  }, [getLandingPage, user]);

  const saveLandingPage = (href: string, updateSetting: boolean = true) => {
    const oldLandingPage = landingPage;
    setLandingPage(href);
    if (updateSetting) {
      updateUserSettings(UserAppSettings.LadingPage, href)
        .then(res => {
          alertSuccess('Save App Setting Successfully');
          setLocalStorageValue('selectedLandingPage', href);
        })
        .catch(err => {
          alertError(err);
          setLandingPage(oldLandingPage);
        });
    }
  };
  return (
    <AppSettingContext.Provider
      value={{
        defaultLandingPage,
        landingPage,
        saveLandingPage,
      }}
    >
      {children}
    </AppSettingContext.Provider>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};
const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  runByClientApp: appActions.runByClientApp,
};

const connectedAppSettingContextProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppSettingContextProvider);
export { connectedAppSettingContextProvider as AppSettingContextProvider };
