import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import {
  AM_BUTTON_LABEL_MAP,
  top5SuspiciousURLsWidgetHeader,
  Top5Tabs,
  TOP_5_TABS,
} from '../../constants';
import {
  TOP_5_PHONE_NUMBER_TABLE_COL,
  TOP_5_SUSPICIOUS_URLS_TABLE_COL,
} from './Top5SuspiciousURLsColDef';
import { DashboardData } from '../../constants';
import { Top5SuspiciousURLsNoDataView } from './Top5SuspiciousURLsNoDataView';
import './Top5SuspiciousURLsNoDataView.scss';
import { useAppSelector } from '../../../../helpers/hooks';

export function Top5SuspiciousURLs({ dashboardData }: { dashboardData: DashboardData }) {
  const topSuspiciousThreats = dashboardData.topSuspiciousThreats;
  const [activated, setActivated] = useState<Top5Tabs>('phish');
  const tableData = topSuspiciousThreats[activated]?.sort((a, b) => b.count - a.count).slice(0, 5);

  const TABLE_COL =
    activated === 'phoneNumber' ? TOP_5_PHONE_NUMBER_TABLE_COL : TOP_5_SUSPICIOUS_URLS_TABLE_COL;

  const isDemoBrand = useAppSelector(
    state => state.dashboardReducer.brandInfo?.brand?.brandName === 'bankofbolster',
  );

  const top5Tabs = isDemoBrand ? TOP_5_TABS : TOP_5_TABS.filter(tab => tab !== 'spam');

  return (
    <div className='top-5-suspicious-urls'>
      <div className='phish-scam-header'>
        <div className='shared-titles-for-cards'>{top5SuspiciousURLsWidgetHeader}</div>
        <div className='phish-scam-header-buttons'>
          {top5Tabs.map((label, index) => (
            <button
              key={index}
              className={`phish-scam-buttons ${activated === label ? 'active' : ''}`}
              onClick={() => setActivated(label)}
            >
              {AM_BUTTON_LABEL_MAP[label]}
            </button>
          ))}
        </div>
      </div>
      {tableData.length === 0 ? (
        <Top5SuspiciousURLsNoDataView />
      ) : (
        <div className='top-5-suspicious-urls-table'>
          <Table size='medium'>
            <TableHead>
              <TableRow className='top-5-suspicious-urls-table-header'>
                {TABLE_COL.map((col, index) => (
                  <TableCell className='top-5-suspicious-urls-table-header-cell' key={index}>
                    {col.label === 'Source URL' && activated === 'senderDomain'
                      ? 'Domain Name'
                      : col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((data, index) => (
                <TableRow key={index} className='top-5-suspicious-urls-table-row'>
                  {TABLE_COL.map((col, index) => (
                    <TableCell
                      key={index}
                      className={`top-5-suspicious-urls-table-cell top-5-suspicious-${col.key}`}
                    >
                      {col.render
                        ? col.render((data[col.key as keyof typeof data] || '--') as string, data)
                        : data[col.key as keyof typeof data] || '--'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}
