import React from 'react';
import _ from 'lodash';
import { ITableApiColumn } from '../Common/Table/table.api';
import { Link } from 'react-router-dom';
import {
  DISPOSITION_FILTER_OPTIONS,
  DOMAIN_COLUMN,
  REDIRECTED_URL_COL,
  SCAN_SOURCE_COLUMN,
  SCAN_SOURCE_CATEGORY_COLUMN,
} from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import { DispositionStatus } from '../Common/DispositionStatus';
import moment from 'moment';
import { WEB_DASHBOARD_PATHNAME } from '../MonitorAndTakedown/constants';
import { TakedownSubmittedByOptions, globalTakeDownStatus, latestGtdActions } from './constants';
import AgGridDateRangeFilter from '../Common/Table/ag-table/AgGridDateRangeFilter';
import { StatusLabels } from '../Common/statusLabels';
import { Tooltip } from '@mui/material';

export const SUBMITTED_COLUMNS: ITableApiColumn[] = [
  {
    id: 'src_url',
    accessor: 'src_url',
    fieldForExport: 'src_url',
    header: 'Source URL',
    headerTooltip: 'Link to URL Details View',
    render: (data: any) => {
      const { src_url } = data;
      const timestamp = moment(data?.created_ts).valueOf();
      return (
        <Tooltip
          title={src_url}
          placement='top-start'
          classes={{
            tooltip: 'table-source-url-tooltip',
          }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
        >
          <div className={'table-source-url-column d-flex align-items-center'}>
            <Link to={`${WEB_DASHBOARD_PATHNAME}/insights/${timestamp}/${data.url_sha256}`}>
              {src_url}
            </Link>
          </div>
        </Tooltip>
      );
    },
    hiddenInDragDrop: true,
    agFilterType: 'agTextColumnFilter',
    disableForSelection: true,
  },
  {
    ...SCAN_SOURCE_COLUMN,
    header: 'Scan Source',
    render: (data: any) => {
      if (_.isEmpty(data.scan_source)) {
        return '--';
      }
      return data.scan_source;
    },
    sortDisabled: true,
  },
  {
    id: 'global_takedown_status',
    accessor: 'global_takedown_status',
    header: 'Status',
    filterOptions: Object.values(globalTakeDownStatus).map(({ label, value }) => ({
      label,
      value,
    })),
    type: 'options',
    agFilterType: 'agTextColumnFilter',
    render: (data: any) => {
      const GLOABL_TAKEDOWN_STATUS = Object.values(globalTakeDownStatus);
      if (_.isEmpty(data.global_takedown_status)) {
        return '--';
      }
      return <StatusLabels status={data.global_takedown_status} />;
    },
  },
  {
    id: 'takedown_eligible',
    accessor: 'takedown_eligible',
    header: 'Eligible for Takedown',
    agFilterType: 'agTextColumnFilter',
    filterOptions: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    type: 'options',
    hidden: true,
  },
  {
    id: 'gtd_submitted_ts',
    accessor: 'gtd_submitted_ts',
    header: 'Takedown Submission Timestamp',
    type: 'date',
    render: (data: any) => {
      if (!data.gtd_submitted_ts) {
        return '--';
      }
      return moment(data.gtd_submitted_ts).format('DD-MMM-YYYY hh:mm a');
    },
    filterDisabled: false,
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'latest_gtd_action',
    accessor: 'latest_gtd_action',
    header: 'Latest Actions',
    render: (data: any) => {
      const latestAction = data?.latest_gtd_action;
      if (!latestAction || latestAction === undefined) {
        return '--';
      }
      return latestAction;
    },
    type: 'options',
    filterOptions: latestGtdActions,
    agFilterType: 'agTextColumnFilter',
  },

  {
    id: 'current_disposition',
    accessor: 'current_disposition',
    fieldForExport: 'current_disposition',
    header: 'Current Disposition',
    headerTooltip: 'Disposition when Site was Last Scanned',
    filterOptions: DISPOSITION_FILTER_OPTIONS,
    render: (data: any) => {
      if (_.isEmpty(data.current_disposition)) {
        return '--';
      }
      return <DispositionStatus status={data.current_disposition} />;
    },
    type: 'options',
    agFilterType: 'agTextColumnFilter',
  },
  {
    id: 'takedown_ts',
    accessor: 'takedown_ts',
    header: 'Takedown Timestamp',
    type: 'date',
    render: (data: any) => {
      if (!data.takedown_ts) {
        return '--';
      }
      return moment(data.takedown_ts).format('DD-MMM-YYYY hh:mm a');
    },
    filterDisabled: false,
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'latest_gtd_ts',
    accessor: 'latest_gtd_ts',
    header: 'Latest Actions Timestamp',
    type: 'date',
    render: (data: any) => {
      if (!data.latest_gtd_ts) {
        return '--';
      }
      return moment(data.latest_gtd_ts).format('DD-MMM-YYYY hh:mm a');
    },
    filterDisabled: false,
    agFilterType: AgGridDateRangeFilter,
  },
  {
    id: 'gtd_submitted_by',
    accessor: 'gtd_submitted_by',
    header: 'Takedown Submitted By',
    render: (data: any) => {
      if (!data.gtd_submitted_by) {
        return 'Unknown';
      }
      return data.gtd_submitted_by;
    },
    type: 'options',
    agFilterType: 'agTextColumnFilter',
    sortDisabled: true,
  },
  {
    ...SCAN_SOURCE_CATEGORY_COLUMN,
    id: 'source_feed',
    accessor: 'source_feed',
    sortDisabled: true,
  },
  {
    ...REDIRECTED_URL_COL,
  },
  {
    id: 'ip',
    accessor: 'ipAddress',
    fieldForExport: 'ip',
    header: 'IP Address',
    headerTooltip: 'Link to IP Details View',
    type: 'ipv4',
    render: (data: any) => {
      const { ip } = data;
      if (_.isEmpty(ip) || ip === '0.0.0.0') {
        return '--';
      }
      return <div className={'table-ip-column'}>{ip}</div>;
    },
    agFilterType: 'agTextColumnFilter',
  },
  {
    ...DOMAIN_COLUMN,
  },
];
