import { Record as ImmutableRecord } from 'immutable';
import { Nullable } from '../types/common';
import { EDarkWebFindingStatus } from '../components/DarkWeb/Components/Types/DarkWeb.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IDarkWebState {
  breachDescriptions: Record<string, string>;
  selectedEntityCard: {
    [EDarkWebFindingStatus.ACTIVE]: Nullable<string>;
    [EDarkWebFindingStatus.MITIGATED]: Nullable<string>;
    [EDarkWebFindingStatus.IGNORED]: Nullable<string>;
  };
  shouldShowDataLeakModal: boolean;
  currentDataLeakParams: string;
  currentDataLeakEntityType: string;
  currentSearchTermID: string | number;
  shouldShowDataLeakTablePopup: boolean;
  currentDataLeakDescription: string;
  currentGroupedSelectedFindings: string[];
  currentSingleSelectedFindings: string[];
}

const initialStateValue: IDarkWebState = {
  breachDescriptions: {},
  selectedEntityCard: {
    [EDarkWebFindingStatus.ACTIVE]: null,
    [EDarkWebFindingStatus.MITIGATED]: null,
    [EDarkWebFindingStatus.IGNORED]: null,
  },
  shouldShowDataLeakModal: false,
  currentDataLeakParams: '',
  currentDataLeakEntityType: '',
  shouldShowDataLeakTablePopup: false,
  currentSearchTermID: '',
  currentDataLeakDescription: '',
  currentGroupedSelectedFindings: [],
  currentSingleSelectedFindings: [],
};

export const darkWebSlice = createSlice({
  name: 'darkWeb',
  initialState: initialStateValue,
  reducers: {
    setBreachDescriptions: (state, action: PayloadAction<any>) => {
      state.breachDescriptions = action.payload;
    },
    setSelectedEntityActive: (state, action: PayloadAction<Nullable<string>>) => {
      state.selectedEntityCard[EDarkWebFindingStatus.ACTIVE] = action.payload;
    },
    setSelectedEntityMitigated: (state, action: PayloadAction<Nullable<string>>) => {
      state.selectedEntityCard[EDarkWebFindingStatus.MITIGATED] = action.payload;
    },
    setSelectedEntityIgnored: (state, action: PayloadAction<Nullable<string>>) => {
      state.selectedEntityCard[EDarkWebFindingStatus.IGNORED] = action.payload;
    },
    setShouldShowDataLeakModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowDataLeakModal = action.payload;
    },
    setCurrentDataLeakParams: (state, action: PayloadAction<string>) => {
      state.currentDataLeakParams = action.payload;
    },
    setCurrentDataLeakEntityType: (state, action: PayloadAction<string>) => {
      state.currentDataLeakEntityType = action.payload;
    },
    setShouldShowDataLeakTablePopup: (state, action: PayloadAction<boolean>) => {
      state.shouldShowDataLeakTablePopup = action.payload;
    },
    setCurrentSeachTermID: (state, action: PayloadAction<string | number>) => {
      state.currentSearchTermID = action.payload;
    },
    setCurrentDataLeakDescription: (state, action: PayloadAction<string>) => {
      state.currentDataLeakDescription = action.payload;
    },
    setCurrentGroupedSelectedFindings: (state, action: PayloadAction<string[]>) => {
      state.currentGroupedSelectedFindings = action.payload;
    },
    setCurrentSingleSelectedFindings: (state, action: PayloadAction<string[]>) => {
      state.currentSingleSelectedFindings = action.payload;
    },
  },
});

export const {
  setBreachDescriptions,
  setSelectedEntityActive,
  setSelectedEntityMitigated,
  setSelectedEntityIgnored,
  setCurrentDataLeakParams,
  setShouldShowDataLeakModal,
  setCurrentDataLeakEntityType,
  setShouldShowDataLeakTablePopup,
  setCurrentSeachTermID,
  setCurrentDataLeakDescription,
  setCurrentGroupedSelectedFindings,
  setCurrentSingleSelectedFindings,
} = darkWebSlice.actions;

export default darkWebSlice.reducer;
