import React, { useContext, useEffect, useState } from 'react';
import { HomeIcon } from '../../assets/SVGIcons';
import AppSettingContext from '../../context/AppSettingContext';
import './PageTitle.scss';
import { HomeRounded } from '@mui/icons-material';

interface IComponentProps {
  title: string;
  titleAlwaysShown?: boolean;
  className?: string;
  tools?: any;
}

export const PageTitle = ({
  title,
  titleAlwaysShown = false,
  className = '',
  tools = null,
}: IComponentProps): any => {
  const [isLandingPage, setIsLandingPage] = useState(false);
  const appSetting = useContext(AppSettingContext);
  const { landingPage } = appSetting;
  useEffect(() => {
    setIsLandingPage(window.location.pathname === landingPage);
  }, [landingPage]);

  let compClassName = 'page-title-component ' + className;
  if (titleAlwaysShown) {
    compClassName += ' title-always-shown';
  }

  return (
    <div className={compClassName}>
      <div className={'page-title'}>
        <div className={'page-parent-label d-flex align-items-center'}>
          {isLandingPage && !landingPage.includes('domain-monitoring') ? (
            <HomeRounded className='home-icon-hover--none' />
          ) : null}
          {title}
        </div>
      </div>
      {tools}
    </div>
  );
};
