import React, { useContext } from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ThemeModes, getLocalTimeFromUtcTime } from '../../../constants';
import PillLabel from '../PillLabel';
import { ITags } from './Tags';
import TagWithIconLabel from './TagsWithIconLabel';
import { DoubleDownArrowIcon } from '../../../assets/SVGIcons';
import ThemeContext from '../../../context/ThemeContext';
import { useAppSelector } from '../../../helpers/hooks';
import { getTagsStyle } from './util';
import { useReadOnlyUser } from '../../../basic-hooks/useUserRoles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

interface ShowAppliedTagsProps {
  appliedTags: ITags[];
  restrictedAppliedTags: ITags[];
  bulkOption: boolean;
}
enum ETagsTypeLabel {
  TAKEWDOWN_IN_PROGRESS = 'Takedown in Progress',
  ACTION_NEEDED = 'Action Needed',
}

export default function ShowAppliedTags({
  bulkOption,
  appliedTags,
  restrictedAppliedTags,
}: ShowAppliedTagsProps): JSX.Element {
  const { selectedTheme } = useContext(ThemeContext);
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);

  const renderIcons = (tagLabel: string) => {
    if (tagLabel === ETagsTypeLabel.TAKEWDOWN_IN_PROGRESS) {
      return <DoubleDownArrowIcon />;
    }
    if (tagLabel === ETagsTypeLabel.ACTION_NEEDED) {
      return <InfoOutlinedIcon className='red-icon-color--action-needed' />;
    }
  };
  const mapTagsLabelAndValue = (tag: any) => {
    return { ...tag, ..._.find(providedTags, item => item.id === tag.id) };
  };

  const renderRestrictedAppliedTags = () => {
    return restrictedAppliedTags.map((tag: ITags, index: number) => {
      const tagColor: string =
        selectedTheme === ThemeModes.DARK.toLowerCase()
          ? tag.color?.darkColor
          : tag.color?.lightColor;
      return (
        <div key={index}>
          <TagWithIconLabel
            customClassName={'custom-tag-button'}
            label={tag.label}
            customStyle={{
              border: `1px solid ${tagColor}`,
              color: tagColor,
            }}
          >
            {renderIcons(tag.label)}
          </TagWithIconLabel>
        </div>
      );
    });
  };

  const isReadOnlyUser = useReadOnlyUser();

  return (
    <>
      {' '}
      {!bulkOption && appliedTags?.length ? (
        <React.Fragment>
          {appliedTags.map((tag: ITags, index: number) => {
            const { label, color } = mapTagsLabelAndValue(tag);
            const { updatedBy, updatedDate } = tag;

            return (
              <OverlayTrigger
                key={index}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-tag-author-${label}`} className={`tag-author-name-tooltip`}>
                    <div className='author-wrapper'>
                      <div className='tag-author-info'>
                        Added by{' '}
                        <span>
                          {updatedBy?.firstName} {updatedBy?.lastName}
                        </span>{' '}
                      </div>
                      <div className='tag-author-date'>{getLocalTimeFromUtcTime(updatedDate)}</div>
                    </div>
                  </Tooltip>
                }
              >
                <div key={index}>
                  <PillLabel label={label} customStyle={getTagsStyle(tag, selectedTheme)} />
                </div>
              </OverlayTrigger>
            );
          })}

          {!isReadOnlyUser && (
            <TagWithIconLabel customClassName={'edit-tag-button'}>
              <EditRoundedIcon className='pencil-edit-icon' />
            </TagWithIconLabel>
          )}

          {renderRestrictedAppliedTags()}
        </React.Fragment>
      ) : (
        <>
          {!isReadOnlyUser && (
            <TagWithIconLabel label={'Add Tag'} customClassName={'add-tag-button'}>
              <AddRoundedIcon className='add-plus-tag-icon' />
            </TagWithIconLabel>
          )}
          {renderRestrictedAppliedTags()}
        </>
      )}
    </>
  );
}
