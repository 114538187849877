import { ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID } from '../../../AbuseMailbox/constants';
import { ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID } from '../../../AbuseMailbox/constants';
import {
  APP_STORE_TABLE_ID,
  CONNECTOR_TABLE_ID,
  DOCUMENTS_TABLE_ID,
  LOGOS_TABLE_ID,
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_BOLSTER,
  MANAGED_BY_ORGANIZATION,
  MARKETPLACE_TABLE_ID,
  OFFICIAL_PHONE_NUMBER_TABLE_ID,
  SOCIAL_MEDIA_TABLE_ID,
  TRADEMARK_TABLE_ID,
} from '../../../Assets_v2/constants';
import {
  GROUPED_POST_MALICIOUS_TABLE_ID,
  GROUPED_PRE_MALICIOUS_TABLE_ID,
  GROUPED_TAKEDOWN_MALICIOUS_TABLE_ID,
} from '../../../MonitorAndTakedown/constants';
import {
  APP_STORE_PLAYBOOK_TABLE_ID,
  DARKWEB_PLAYBOOK_TABLE_ID,
  SOCIAL_PLAYBOOK_TABLE_ID,
  WEB_PLAYBOOK_TABLE_ID,
} from '../../../Playbook/Common/PlaybookConstants';
import { REPORTS_TABLE_ID } from '../../../Reports/ReportsConstant';
import { TEAM_MEMBER_TABLE_ID } from '../../../TeamMembers/TeamMemberConstant';

export const defaultDisplayColumns = {
  pre_malicious:
    'src_url,screenshot,first_seen_ts,last_updated_ts,risk_score,current_disposition,final_category,matrix_algorithm,has_mx_records,brand_logo_detected,ip,as_description,sourceFeed,tags',
  post_malicious:
    'src_url,screenshot,first_seen_ts,takedown_ts,last_updated_ts,current_disposition,dispositions,takedowns,final_category,matrix_algorithm,has_mx_records,brand_logo_detected,ip,as_description,sourceFeed,tags',
  takedown_malicious:
    'src_url,screenshot,first_seen_ts,last_updated_ts,current_disposition,takedowns,final_category,has_mx_records,brand_logo_detected,ip,as_description,sourceFeed,tags',
  global_takedown_table_web:
    'src_url,scan_source,source_feed,global_takedown_status,takedown_eligible,gtd_submitted_ts,latest_gtd_action,current_disposition,takedown_ts,latest_gtd_ts,latest_gtd_comment,gtd_submitted_by,ip,dst_url',
  findingReview:
    'url,screenshot,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  domain_acquisition: 'domain,acquisition_priority,estimated_price,region,TLD',
  typosquat:
    'src_url_permutation,matrix_algorithm,risk_score,live_scan_verdict,final_category,ip_data,nameservers,mailservers,has_mx_records,domain_registration_date,registrar,ssl_cert_provider,takedown_enquiry',
  typo_domain_acquisition:
    'domain,matrix_algorithm,risk_score,live_scan_verdict,final_category,ip_data,nameservers,mailservers',
  findingsDetection:
    'url,screenshot,platform_id,actions,created_ts,logos,search_term_labels,metadata->>active_since,external_links,metadata->>title,origin_label,tags.id',
  //'url,createdTs,activeSince,searchTerm,platform,origin,category,title,logos,actions,externalLinks,tags',
  findingsTakedownInProgress:
    'url,screenshot,created_ts,platform_id,category_labels,logos,search_term_labels,metadata->>active_since,metadata->>fb_page_link,metadata->>title,origin_label,tags.id,takedownInProgress,takedownStatus,externalLinks',
  //'url,createdTs,activeSince,searchTerm,platform,origin,category,title,logos,takedownInProgress,takedownStatus,externalLinks,tags',
  findingsTakenDown:
    'url,screenshot,created_ts,platform_id,category_labels,logos,search_term_labels,metadata->>active_since,metadata->>fb_page_link,metadata->>title,origin_label,tags.id,externalLinks',
  //'url,createdTs,activeSince,takedownTs,searchTerm,platform,origin,category,title,logos,externalLinks,tags',
  findingSafeList:
    'url,screenshot,created_ts,platform_id,category_labels,logos,search_term_labels,metadata->>active_since,metadata->>fb_page_link,metadata->>title,origin_label,tags.id,externalLinks',
  //'url,createdTs,activeSince,searchTerm,platform,origin,category,title,logos,actions,externalLinks,tags',
  findingsReview:
    'url,screenshot,created_ts,platform_id,actions,category_labels,logos,search_term_labels,metadata->>active_since,metadata->>fb_page_link,metadata->>title,origin_label,tags.id,externalLinks',
  // 'url,createdTs,activeSince,searchTerm,platform,origin,category,title,logos,actions,externalLinks,tags',
  appStoreDetection:
    'url,screenshot,created_ts,platform_label,actions,category_labels,logos,search_term,metadata->>active_since,metadata->>fb_page_link,metadata->>title,tags.id,externalLinks',
  //'url,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  appStoreFindingsTakedownInProgress:
    'url,screenshot,created_ts,platform_label,category_labels,logos,search_term,metadata->>active_since,metadata->>fb_page_link,metadata->>title,takedownInProgress,takedownStatus,tags.id,externalLinks',
  //'url,createdTs,activeSince,searchTerm,platform,category,title,logos,takedownInProgress,takedownStatus,externalLinks,tags',
  appStoreFindingsTakenDown:
    'url,screenshot,created_ts,platform_label,category_labels,logos,search_term,metadata->>active_since,metadata->>title,takedownStatus,tags.id,externalLinks',
  //'url,createdTs,activeSince,takedownTs,searchTerm,platform,category,title,logos,externalLinks,tags',
  appStoreFindingSafeList:
    'url,screenshot,created_ts,platform_label,category_labels,logos,search_term,actions,metadata->>active_since,metadata->>title,takedownStatus,tags.id,externalLinks',
  //'url,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  marketplaceDetection:
    'url,screenshot,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  marketplaceFindingsTakedownInProgress:
    'url,screenshot,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  marketplaceFindingsTakenDown:
    'url,screenshot,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  marketplaceFindingSafeList:
    'url,screenshot,createdTs,activeSince,searchTerm,platform,category,title,logos,actions,externalLinks,tags',
  searchesTerm:
    'url,author,times,historyCreatedTs,entity,createdTs,activeSince,searchTerm,platforms,category,title,logos,action,externalLinks,tags',
  ['darkWebActiveDetections-emaildomain']:
    'ag-Grid-AutoColumn-search_term,email,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebActiveDetections-customeremaildomain']:
    'ag-Grid-AutoColumn-search_term,email,platform_published_ts,created_ts,password,password_type,platform,status,tags',
  ['darkWebActiveDetections-creditcard']:
    'ag-Grid-AutoColumn-search_term,ccn,cvv,exp_date,title,platform_published_ts,platform,status,tags',
  ['darkWebActiveDetections-executiveMonitoring']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebActiveDetections-email']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebActiveDetections-otherSearchTerms']:
    'search_term,title,platform,platform_published_ts,category,sensitiveData,status,tags',
  ['darkWebIgnoredDetections-emaildomain']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebIgnoredDetections-customeremaildomain']:
    'ag-Grid-AutoColumn-search_term,email,platform_published_ts,created_ts,password,password_type,platform,status,tags',
  ['darkWebIgnoredDetections-otherSearchTerms']:
    'search_term,title,platform,platform_published_ts,category,sensitiveData,status,tags',
  ['darkWebIgnoredDetections-executiveMonitoring']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebIgnoredDetections-email']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebIgnoredDetections-creditcard']:
    'ag-Grid-AutoColumn-search_term,ccn,cvv,exp_date,title,platform_published_ts,platform,status,tags',
  ['darkWebMitigatedDetections-emaildomain']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebMitigatedDetections-customeremaildomain']:
    'ag-Grid-AutoColumn-search_term,email,platform_published_ts,created_ts,password,password_type,platform,status,tags',
  ['darkWebMitigatedDetections-email']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  ['darkWebMitigatedDetections-otherSearchTerms']:
    'search_term,title,platform,platform_published_ts,category,sensitiveData,status,tags',
  ['darkWebMitigatedDetections-creditcard']:
    'ag-Grid-AutoColumn-search_term,ccn,cvv,exp_date,title,platform_published_ts,platform,status,tags',
  ['darkWebMitigatedDetections-executiveMonitoring']:
    'ag-Grid-AutoColumn-search_term,title,platform_published_ts,password,password_type,platform,status,tags',
  //Dark Web Old Tables
  darkWebActiveDetections:
    'search_term,title,platform_published_ts,threat_actor,password_type,platform,status,tags.id',
  darkWebMitigatedDetections:
    'search_term,title,platform_published_ts,threat_actor,password_type,platform,status,tags.id',
  darkWebIgnoredDetections:
    'search_term,title,platform_published_ts,threat_actor,password_type,platform,status,tags.id',
  darkWebThreatActors: 'threat_actor,categories,risk,last_chatters',
  [REPORTS_TABLE_ID]: 'report_name,module,createdByCustomer,created_ts,userTools',
  [WEB_PLAYBOOK_TABLE_ID]:
    'id,name,schedule,nextRun,outputTo,author,createdTs,updatedTs,runCount,totalResultCount,lastRunCount,actionColumn',
  [SOCIAL_PLAYBOOK_TABLE_ID]:
    'id,name,schedule,nextRun,outputTo,author,createdTs,updatedTs,runCount,totalResultCount,lastRunCount,actionColumn',
  [APP_STORE_PLAYBOOK_TABLE_ID]:
    'id,name,schedule,nextRun,outputTo,author,createdTs,updatedTs,runCount,totalResultCount,lastRunCount,actionColumn',
  [DARKWEB_PLAYBOOK_TABLE_ID]:
    'id,name,schedule,nextRun,outputTo,author,createdTs,updatedTs,runCount,totalResultCount,lastRunCount,actionColumn',
  [CONNECTOR_TABLE_ID]: 'name,type,createdAt,createdBy,actionColumn',
  [MANAGED_BY_ORGANIZATION]: 'asset_name,ip_address,created_ts,brand,country,registrar',
  [MANAGED_BY_AFFILIATED]: 'asset_name,ip_address,created_ts,brand,country,registrar',
  [MANAGED_BY_BOLSTER]:
    'domain_name,ip_address,brand,,registration_date,renewal_date,acquisition_status,notes,price',
  [TEAM_MEMBER_TABLE_ID]:
    'firstName,lastName,email,brandId,verified,roleId,teamMemberModule,lastActivity,actionColumn',
  [LOGOS_TABLE_ID]: 'brand_id,value,created_ts,created_by,status,assets-action-col',
  [DOCUMENTS_TABLE_ID]: 'brand_id,value,created_ts,created_by,value_json->>notes,assets-action-col',
  [TRADEMARK_TABLE_ID]:
    'brand_id,value_json->>label,value_json->>registrationNumber,value_json->>jurisdiction,value_json->>classId,created_by,assets-action-col',
  [OFFICIAL_PHONE_NUMBER_TABLE_ID]:
    'value_json->>phoneNumber,value_json->>country,brand_id,created_ts,created_by,status,assets-action-col',
  [SOCIAL_MEDIA_TABLE_ID]:
    'brand_id,value_json->>name,value_json->>platform,value_json->>url,created_by,assets-action-col',
  [MARKETPLACE_TABLE_ID]:
    'brand_id,value_json->>name,value_json->>platform,value_json->>sellerName,created_by,assets-action-col',
  [APP_STORE_TABLE_ID]:
    'brand_id,value_json->>name,value_json->>domainLabel,value_json->>platform,value_json->>url,created_by,created_ts,assets-action-col',
  //Have to ADD Dark Web Table Default columns if missed
  [ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID]:
    'sourceUrl,threatType,disposition,count,category,firstSeenTargeted,lastSeenTargeted,status',
  [GROUPED_PRE_MALICIOUS_TABLE_ID]:
    'src_url,first_seen_ts,last_updated_ts,risk_score,current_disposition,final_category,matrix_algorithm,has_mx_records,brand_logo_detected,ip,as_description,sourceFeed,tags',
  [GROUPED_POST_MALICIOUS_TABLE_ID]:
    'src_url,first_seen_ts,takedown_ts,last_updated_ts,current_disposition,dispositions,takedowns,final_category,matrix_algorithm,has_mx_records,brand_logo_detected,ip,as_description,sourceFeed,tags',
  [GROUPED_TAKEDOWN_MALICIOUS_TABLE_ID]:
    'src_url,first_seen_ts,last_updated_ts,current_disposition,takedowns,final_category,has_mx_records,brand_logo_detected,ip,as_description,sourceFeed,tags',
  [ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID]:
    'sourceUrl, count, disposition, status, firstSeenTargeted',
};
export const accerssorTableIds = [
  'findingsDetection',
  'findingsTakedownInProgress',
  'findingsTakenDown',
  'findingReview',
  'findingSafeList',
  'appStoreDetection',
  'appStoreFindingsTakedownInProgress',
  'appStoreFindingsTakenDown',
  'appStoreFindingSafeList',
  'darkWebThreatActors',
  'darkWebActiveDetections',
  'darkWebMitigatedDetections',
  'darkWebIgnoredDetections:',
  REPORTS_TABLE_ID,
  CONNECTOR_TABLE_ID,
  MANAGED_BY_ORGANIZATION,
  MANAGED_BY_AFFILIATED,
  MANAGED_BY_BOLSTER,
  TEAM_MEMBER_TABLE_ID,
  LOGOS_TABLE_ID,
  DOCUMENTS_TABLE_ID,
  TRADEMARK_TABLE_ID,
  SOCIAL_MEDIA_TABLE_ID,
  MARKETPLACE_TABLE_ID,
  APP_STORE_TABLE_ID,
  OFFICIAL_PHONE_NUMBER_TABLE_ID,
];
