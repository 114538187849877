import React, { useCallback } from 'react';
import DotDotDot from '../../../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../../../assets/icons/DotDotDotWhite.svg';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { appConstants, featureIsAvailable } from '../../../../constants';
import { ISearchesTableItemProps, ugcType } from '../../Types/ugc.types';
import { useAppSelector } from '../../../../helpers/hooks';
import { AppState } from '../../../../helpers';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';
interface IActionColRenderProps {
  data: any;
  type: string;
  setSelectedItem: (data: any) => void;
  setStatusAction: (action: string) => void;
}

const ActionColRender = ({
  data,
  type,
  setSelectedItem,
  setStatusAction,
}: IActionColRenderProps) => {
  const [isDarkMode] = useIsDarkMode();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const user = useAppSelector((state: AppState) => state.dashboardReducer.user);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const onAction = useCallback((action: string, data: ISearchesTableItemProps) => {
    setStatusAction(action);
    setSelectedItem(data);
  }, []);

  const checkDisabled = (data: ISearchesTableItemProps) => {
    const { times } = data;
    if (type === ugcType.Dark_Web) {
      return false;
    }
    return times !== 0;
  };
  const actionOptions: any = [
    {
      label: 'Delete',
      value: 'delete',
      disabled: checkDisabled(data),
    },
    {
      label: data.isDisabled ? 'Activate' : 'Deactivate',
      value: data.isDisabled ? 'activate' : 'deactivate',
      disabled: !data.isDisabled,
    },
  ];

  const isReScanFeatureEnabled = featureIsAvailable(
    user,
    appConstants.FEATURE_CODE.SEARCH_TERM_RE_SCAN,
  );

  const isReadOnlyUser = useReadOnlyUser();

  if (type === ugcType.Dark_Web && isReScanFeatureEnabled && !isReadOnlyUser) {
    actionOptions.push({
      label: 'Rescan',
      value: 'rescan',
    });
  }

  return (
    <>
      <Button
        id='basic-button-search-actions'
        aria-controls={open ? 'basic-button-search-actions' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={isDarkMode ? DotDotDotWhiteIcon : DotDotDot} alt={'dotdotdot'} />{' '}
      </Button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actionOptions.map((actionOption: any, index: number) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onAction(actionOption.value, data);
              }}
            >
              {actionOption.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ActionColRender;
