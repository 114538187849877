import {
  dashboardConstants,
  DashBoardDto,
  DashBoardConfigDto,
  ITableConfigDto,
  userConstants,
  DashBoardWidgetConfigDto,
  IBrandInfo,
  ISelectedWebUrls,
  ISocialMediaTableUrls,
  IMarketPlaceTableUrls,
  IAppStoreTableUrls,
  appConstants,
  IFindingsTableUrls,
} from '../constants';
import { DASHBOARD_TYPE_TABLE_CONFIG } from '../components/Common/Table/table.context';
import { Record as ImmutableRecord, Record } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import {
  IMatFetchOptions,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  POST_MALICIOUS_TABLE_ID,
  MALICIOUS_TABLE_IDS,
  FINDINGS_TABLE_IDS,
} from '../components/MonitorAndTakedown/constants';
import { WebUrlData } from '../types/web-url-data.interface';
import { IUserListItemDto } from '../components/TeamMembers/TeamMembers';
import { IFindingsTableItemProps } from '../components/Ugc/Types/ugc.types';
import {
  APP_STORE_FINDING_DETECTION_TABLE_ID,
  APP_STORE_FINDING_SAFE_LIST_TABLE_ID,
  APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  APP_STORE_TAKEN_DOWN_TABLE_ID,
} from '../components/AppStore/constants';
import {
  MARKET_PLACE_FINDING_DETECTION_TABLE_ID,
  MARKET_PLACE_FINDING_REVIEW_TABLE_ID,
  MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID,
  MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  MARKET_PLACE_TAKEN_DOWN_TABLE_ID,
} from '../components/Marketplace/constants';
import {
  SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID,
  SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID,
  SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID,
  SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID,
  SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID,
  SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID,
} from '../components/SocialMedia/constants';

export interface IDashboardReducerState {
  user: DashBoardDto;
  usersList: IUserListItemDto[];
  brandInfo: IBrandInfo | undefined;
  dashboardConfig: DashBoardConfigDto[];
  selectedDashboard: any;
  tablesConfig: ITableConfigDto[];
  currentFetchOptions: IMatFetchOptions;
  timelineUpdated: string;
  selectedWebUrls: ISelectedWebUrls;
  findingsTableUrls: IFindingsTableUrls;
}

const processDashboardConfigData = (data: any): DashBoardConfigDto[] => {
  return _.chain(data)
    .filter(config => {
      return config.dashboardType !== DASHBOARD_TYPE_TABLE_CONFIG;
    })
    .map(config => {
      let widgets: DashBoardWidgetConfigDto[] = [];
      _.forEach(config.widgets, widget => {
        if (widget.type === 'chart') {
          widgets.push({
            ...widget,
            widgetType: widget.type,
            idx: widget.index,
          });
        }
      });
      widgets = _.sortBy(widgets, ['idx']);
      return {
        id: config.dashboardName,
        label: config.dashboardName,
        name: config.dashboardName,
        dashboardType: config.dashboardType,
        href: '/dashboard',
        parent: 'dashboard',
        isDefault: config.isDefault,
        monitoring: _.find(config.widgets, ['type', 'monitoring']),
        widgets,
        table: _.find(config.widgets, w => {
          return w.name.indexOf('table_') === 0;
        }),
        isNew: false,
      };
    })
    .value();
};

const processDashboardConfigDataForTableConfigs = (data: any): ITableConfigDto[] => {
  const tablesConfig: ITableConfigDto[] = [];
  _.forEach(data, config => {
    if (config.dashboardType === DASHBOARD_TYPE_TABLE_CONFIG) {
      tablesConfig.push({
        dashboardName: config.dashboardName,
        widgets: _.map(config.widgets, widget => ({
          ...widget,
          widgetType: widget.type,
          idx: widget.index,
        })),
      });
    }
  });
  return tablesConfig;
};

export const initialDashboardConfig = [
  {
    dashboardName: 'Default',
    dashboardType: 'brand',
    isDefault: true,
    widgets: [],
  },
];

const defaultDashboardConfig: DashBoardConfigDto[] =
  processDashboardConfigData(initialDashboardConfig);

const initialStateDefaultValue: IDashboardReducerState = {
  user: {
    first_name: '',
    last_name: '',
    role_name: '',
    type_name: 'brand',
    email: '',
    subscription_start_date: 0,
    subscription_end_date: 0,
    resource_info: [],
    features: [],
    brand: '',
    organization: '',
    userAppSetting: [],
    notificationSettings: {
      typosquatting: null,
    },
    monitoredDomainPresent: false,
    brand_info: {
      brand: {
        firstName: '',
        lastName: '',
        brandId: 0,
        orgName: '',
        brandName: '',
        displayName: '',
        brandMapping: false,
        subBrands: [],
        subBrandIds: [],
        subBrandNames: [],
      },
    },
  },
  usersList: [],
  brandInfo: undefined,
  dashboardConfig: defaultDashboardConfig,
  selectedDashboard: defaultDashboardConfig[0],
  tablesConfig: [],
  currentFetchOptions: {},
  timelineUpdated: moment().toISOString(),
  selectedWebUrls: {
    [PRE_MALICIOUS_TABLE_ID]: [],
    [TAKEDOWN_MALICIOUS_TABLE_ID]: [],
    [POST_MALICIOUS_TABLE_ID]: [],
  },
  findingsTableUrls: {
    [appConstants.CONTENT_TYPE.APP_STORE]: {
      [APP_STORE_FINDING_DETECTION_TABLE_ID]: [],
      [APP_STORE_TAKEDOWN_IN_PROGRESS_TABLE_ID]: [],
      [APP_STORE_TAKEN_DOWN_TABLE_ID]: [],
      [APP_STORE_FINDING_SAFE_LIST_TABLE_ID]: [],
    },
    [appConstants.CONTENT_TYPE.SOCIAL]: {
      [SOCIAL_MEDIA_FINDING_DETECTION_TABLE_ID]: [],
      [SOCIAL_MEDIA_TAKEDOWN_IN_PROGRESS_TABLE_ID]: [],
      [SOCIAL_MEDIA_TAKEN_DOWN_TABLE_ID]: [],
      [SOCIAL_MEDIA_FINDING_SAFE_LIST_TABLE_ID]: [],
      [SOCIAL_MEDIA_FINDING_REVIEW_TABLE_ID]: [],
      [SOCIAL_MEDIA_MARKED_SCAM_TABLE_ID]: [],
    },
    [appConstants.CONTENT_TYPE.MARKETPLACE]: {
      [MARKET_PLACE_FINDING_DETECTION_TABLE_ID]: [],
      [MARKET_PLACE_TAKEDOWN_IN_PROGRESS_TABLE_ID]: [],
      [MARKET_PLACE_TAKEN_DOWN_TABLE_ID]: [],
      [MARKET_PLACE_FINDING_SAFE_LIST_TABLE_ID]: [],
      [MARKET_PLACE_FINDING_REVIEW_TABLE_ID]: [],
    },
  },
};

export class InitialState extends Record(initialStateDefaultValue) implements InitialState {}

const initialState = new InitialState();

const dashboardReducer = (state: InitialState = initialState, action: any) => {
  let newDashboardConfig;
  let { dashboardConfig, tablesConfig } = state;
  let selectedDashboard = action?.payload?.selectedDashboard ?? null;

  switch (action.type) {
    case dashboardConstants.GET_USER_INFO_SUCCESS:
      return state.set('user', action.user);
    case dashboardConstants.GET_USER_INFO_FAILURE:
      return state;
    case dashboardConstants.GET_USER_LIST_SUCCESS:
      return state.set('usersList', action.payload);
    case dashboardConstants.GET_BRAND_INFO_SUCCESS:
      return state.set('brandInfo', action.payload);
    case dashboardConstants.GET_DASHBOARD_CONFIG_SUCCESS:
      dashboardConfig = _.isEmpty(action.dashboardConfigData)
        ? defaultDashboardConfig
        : processDashboardConfigData(action.dashboardConfigData);
      dashboardConfig = _.sortBy(dashboardConfig, ['name']);
      selectedDashboard = _.find(dashboardConfig, ['isDefault', true]) || dashboardConfig[0];
      if (action.selectedDashboardName) {
        selectedDashboard =
          _.find(dashboardConfig, ['name', action.selectedDashboardName]) || selectedDashboard;
      }
      tablesConfig = processDashboardConfigDataForTableConfigs(action.dashboardConfigData);
      return state
        .set('tablesConfig', tablesConfig)
        .set('dashboardConfig', dashboardConfig)
        .set('selectedDashboard', selectedDashboard);
    case dashboardConstants.GET_DASHBOARD_CONFIG_FAILURE:
      return state;
    case userConstants.LOGOUT:
      return initialState;
    case dashboardConstants.ON_DASHBOARD_SELECTED:
      return state.set('selectedDashboard', selectedDashboard);
    case dashboardConstants.ON_NEW_DASHBOARD:
      const existingLabels = _.map(dashboardConfig, dashboard => {
        return dashboard.label;
      });
      const defaultLabel = 'New Dashboard';
      let newLabel = defaultLabel;
      let index = 2;
      while (existingLabels.indexOf(newLabel) !== -1) {
        newLabel = defaultLabel + ' ' + index;
        index++;
      }
      const newDashboard = {
        id: JSON.stringify(moment().valueOf()),
        label: newLabel,
        href: '/dashboard',
        parent: 'dashboard',
        dashboardType: state.user.type_name,
        isDefault: false,
        monitoring: undefined,
        widgets: [],
        table: undefined,
        isNew: true,
      };
      return state.set('selectedDashboard', newDashboard);
    case dashboardConstants.ON_NEW_DASHBOARD_V2:
      const existingLabelsV2 = _.map(dashboardConfig, dashboard => {
        return dashboard.label;
      });
      const defaultLabelV2 = 'New Dashboard';
      let newLabelV2 = defaultLabelV2;
      let ind = 2;
      while (existingLabelsV2.indexOf(newLabelV2) !== -1) {
        newLabelV2 = defaultLabelV2 + ' ' + ind;
        ind++;
      }
      const newDashboardV2 = {
        id: JSON.stringify(moment().valueOf()),
        label: newLabelV2,
        href: '/dashboard_v2',
        parent: 'dashboard_v2',
        dashboardType: state.user.type_name,
        isDefault: false,
        monitoring: undefined,
        widgets: [],
        table: undefined,
        isNew: true,
      };
      return state.set('selectedDashboard', newDashboardV2);
    case dashboardConstants.ON_CHANGE_DASHBOARD:
      newDashboardConfig = _.map(dashboardConfig, dashboard => {
        if (dashboard.id === selectedDashboard.id) {
          return selectedDashboard;
        }
        return dashboard;
      });
      return state
        .set('dashboardConfig', newDashboardConfig)
        .set('selectedDashboard', selectedDashboard);
    case dashboardConstants.ON_SET_DEFAULT_DASHBOARD:
      newDashboardConfig = _.map(dashboardConfig, dashboard => {
        return {
          ...dashboard,
          isDefault: dashboard.id === selectedDashboard.id,
        };
      });
      return state
        .set('dashboardConfig', newDashboardConfig)
        .set('selectedDashboard', _.find(newDashboardConfig, ['isDefault', true]));
    case dashboardConstants.SET_CURRENT_QUERY:
      return state.set('currentFetchOptions', action.payload);
    case dashboardConstants.TIMELINE_UPDATED:
      return state.set('timelineUpdated', action.payload);
    case dashboardConstants.SET_SELECTED_WEB_URLS: {
      const { tableId, selected } = action.payload as {
        tableId: MALICIOUS_TABLE_IDS;
        selected: WebUrlData[];
      };
      return state.set('selectedWebUrls', {
        ...state.selectedWebUrls,
        [tableId]: selected,
      });
    }
    case dashboardConstants.SET_FINDINGS_TABLES_URLS: {
      const { tableId, selected, type } = action.payload as {
        tableId: FINDINGS_TABLE_IDS;
        selected: IFindingsTableItemProps[];
        type: string;
      };
      return state.set('findingsTableUrls', {
        ...state.findingsTableUrls,
        [type]: {
          ...state.findingsTableUrls[type],
          [tableId]: selected,
        },
      });
    }
    case dashboardConstants.SET_TYPOSQUAT_DATA: {
      const user = { ...state.user };
      user.monitoredDomainPresent = action.payload;
      return state.set('user', user);
    }
    default:
      return state;
  }
};

export { dashboardReducer };
