import React, { Component } from 'react';
import './auth.scss';
import { Link } from 'react-router-dom';
import { CompanyIcon } from '../Common/CompanyIcon';
import UserService from '../../services/user.service';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { Helmet } from 'react-helmet';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import GoogleLogo from '../../assets/logo/google_logo.svg';
import MicrosoftLogo from '../../assets/logo/microsoft_logo.svg';
import WarningRedRoundIcon from '../../assets/icons/Warning-round-red.svg';
import WarningYellowRoundIcon from '../../assets/icons/Warning-round-yellow.svg';
import PressEnterIcon from '../../assets/icons/PressEnter.svg';
import { PasswordEye, PASSWORD_LENGTH, PASSWORD_LIMIT } from '../Common/PasswordEye';
import ThemeContext from '../../context/ThemeContext';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addHubspotScript } from '../../helpers/hubspot';
import { UserSignupDto } from './signup.component';
import { AlertActionsTypes } from '../../constants';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

interface IUserSignupProps {
  identifier: string;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

interface IUserSignupState {
  remindMeLater: boolean;
  workEmailProvider: string;
  showWorkEmailErrMsg: boolean;
  showSentVerificationView: boolean;
  isWorkEmail: boolean;
  isLoading: boolean;
  errMsg: string;
  user: UserSignupDto;
  showScreenWidthData: boolean;
}

export class SignupPageV2 extends Component<IUserSignupProps, IUserSignupState> {
  static contextType = ThemeContext;
  constructor(props: IUserSignupProps) {
    super(props);
    this.state = {
      isLoading: false,
      showWorkEmailErrMsg: false,
      remindMeLater: false,
      isWorkEmail: false,
      showSentVerificationView: false,
      showScreenWidthData: true,
      workEmailProvider: '',
      errMsg: '',
      user: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
    };
  }

  componentDidMount(): void {
    window.document.title = 'Sign up | CheckPhish Platform';
    addHubspotScript();
  }

  handleSubmit = (
    event: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    sendForEmailVerification: boolean,
  ) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const userService = new UserService();
    const { firstName, lastName, email, password } = this.state.user;
    if (sendForEmailVerification) {
      userService
        .validateWorkEmail(email)
        .then(resp => {
          const updatedState: any = {};
          if (resp.providerPresent) {
            updatedState['workEmailProvider'] = resp.provider;
          }
          updatedState['isWorkEmail'] = true;
          this.setState(updatedState);
        })
        .catch(() => this.setState({ showWorkEmailErrMsg: true }))
        .finally(() => this.setState({ isLoading: false }));
    } else {
      userService
        .signUp({ firstName, lastName, email, password, fingerprint: this.props.identifier })
        .then(() => {
          this.setState({
            showSentVerificationView: true,
          });
        })
        .catch(error => {
          this.setState({
            errMsg: error?.User ? error.User.toString() : error.toString(),
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'signUpSubmitClicked',
        category: 'Page',
        action: 'clicked',
        label: 'signUpSubmitClicked',
      });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({
      user: { ...this.state.user, [name]: value },
    });
    if (name === 'email') {
      this.setState({ showWorkEmailErrMsg: false });
    }
  };

  redirectToSSO = (provider: string) => {
    window.location.href = `${window.location.origin}/platform-api/v1/sso/oauth/login/${provider}?fingerprint=${this.props.identifier}`;
  };

  pressEnterContainer = () => {
    return (
      <Form.Group className='mb-0 pt-1 d-flex enter-container'>
        <Form.Label>
          or press{' '}
          <span>
            Enter <img className='work-email-err-msg-logo' src={PressEnterIcon} alt={'logo'} />
          </span>
        </Form.Label>
      </Form.Group>
    );
  };

  emailVerificationView = () => {
    const { user, showWorkEmailErrMsg, isLoading } = this.state;
    const { email } = user;
    return (
      <div className='form-container pt-5'>
        <div className='text-container'>
          <h2 className={'title-text pb-3'}>
            {showWorkEmailErrMsg ? 'Please enter your work email' : 'Welcome to CheckPhish.ai'}
          </h2>
          <h6 className={'title-text second-text'}>
            {showWorkEmailErrMsg
              ? 'Use work email to access unlimited continuous domain monitoring feature.'
              : 'Enter your work email to continue.'}
          </h6>
        </div>
        <Form onSubmit={e => this.handleSubmit(e, true)}>
          <Form.Group>
            <Form.Label className='d-flex'>
              Work Email<div className='asterisk'>* </div>
            </Form.Label>
            <Form.Control
              type='email'
              placeholder='name@company.com'
              name='email'
              value={email}
              onChange={this.handleChange}
              className={`${showWorkEmailErrMsg ? 'error-border' : ''}`}
            />
          </Form.Group>
          {showWorkEmailErrMsg && (
            <div className='d-flex work-email-err-msg'>
              <img className='work-email-err-msg-logo' src={WarningRedRoundIcon} alt={'logo'} />
              <p>
                Continuing with a personal email will limit your access. Are you sure you want to
                proceed?
              </p>
            </div>
          )}
          <Button
            disabled={!email || showWorkEmailErrMsg}
            className={`continue-button ${showWorkEmailErrMsg && 'work-email-disable'}`}
            variant='primary'
            type='submit'
          >
            {isLoading ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Continue'
            )}
          </Button>
          {showWorkEmailErrMsg ? (
            <Form.Label
              className='d-flex remind-me-later'
              onClick={() => this.setState({ remindMeLater: true })}
            >
              Remind Me Later
            </Form.Label>
          ) : (
            this.pressEnterContainer()
          )}
        </Form>
        {!showWorkEmailErrMsg && (
          <>
            <div className='divider'>OR</div>
            <div className='sso-container d-flex' onClick={() => this.redirectToSSO('GOOGLE')}>
              <img className='logo' src={GoogleLogo} alt={'logo'} />
              <p>Continue with Google</p>
            </div>
            <div
              className='sso-container microsoft-container d-flex'
              onClick={() => this.redirectToSSO('MICROSOFT')}
            >
              <img className='logo' src={MicrosoftLogo} alt={'logo'} />
              <p>Continue with Microsoft</p>
            </div>
            <div className={'flex-center font-size-normal already-have-account'}>
              Already have an account? <Link to={'/sign-in'}>Sign in</Link>
            </div>
          </>
        )}
      </div>
    );
  };

  goBack = () => {
    this.setState({
      remindMeLater: false,
      workEmailProvider: '',
      isWorkEmail: false,
      showWorkEmailErrMsg: false,
      showSentVerificationView: false,
      user: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
    });
  };

  hasProviderView = () => {
    const { workEmailProvider } = this.state;
    return (
      <div className='form-container'>
        <div className='text-container'>
          <h2 className={'title-text flex-center pb-3'}>Sign in to CheckPhish.ai</h2>
          <h6 className={'title-text flex-center second-text'}>Get started - it's free.</h6>
        </div>
        <Button
          className='continue-button provider-button d-flex no-padding'
          variant='primary'
          type='submit'
          onClick={() => this.redirectToSSO(workEmailProvider)}
        >
          <img
            className='logo'
            src={workEmailProvider === 'MICROSOFT' ? MicrosoftLogo : GoogleLogo}
            alt={'logo'}
          />
          <p className='text'>Sign in with {workEmailProvider.toLocaleLowerCase()}</p>
        </Button>
      </div>
    );
  };

  resendVerificationMail = (email: string) => {
    this.setState({ isLoading: true });
    const { alertError, alertSuccess } = this.props;
    const userService = new UserService();
    userService
      .sendPrimaryEmailVerification({
        email,
      })
      .then(resp => alertSuccess(resp.message))
      .catch(err => alertError(err))
      .finally(() => this.setState({ isLoading: false }));
  };

  manualSignUpView = () => {
    const {
      remindMeLater,
      showWorkEmailErrMsg,
      user,
      isLoading,
      showSentVerificationView,
      errMsg,
    } = this.state;
    const { firstName, lastName, email, password } = user;
    const submitButtonDisabled =
      !firstName || !lastName || !email || !password || password.length > PASSWORD_LIMIT;
    if (showSentVerificationView) {
      return (
        <div className='form-container sent-email-verification'>
          <div className='text-container'>
            <h2 className={'title-text pb-3'}>Verify your email</h2>
            <h6 className={'title-text second-text'}>Good job! You are almost there.</h6>
          </div>
          <p>
            Please check <span className='email'>{email}</span> to verify your account and get
            started.
          </p>
          <p>
            Ensure the accuracy of your entered email and kindly check your spam folder if needed.
          </p>
          <Button
            disabled={isLoading}
            className='continue-button'
            variant='outline-secondary'
            onClick={() => this.resendVerificationMail(email)}
          >
            {isLoading ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Resend Email'
            )}
          </Button>
        </div>
      );
    }
    return (
      <div className='form-container'>
        <div className='text-container'>
          <h2 className={'title-text pb-3'}>Fill your information below</h2>
          <h6 className={'title-text second-text'}>Get started - it's free.</h6>
        </div>
        {errMsg !== '' && (
          <h5 className={'title-text title-text-err  m-auto text-center'}>
            <text>{errMsg}</text>
          </h5>
        )}
        <Form onSubmit={e => this.handleSubmit(e, false)}>
          <Form.Group>
            <Form.Label className='d-flex'>
              Work Email<div className='asterisk'>* </div>
            </Form.Label>
            <div className='form-control disabled email-container' onClick={this.goBack}>
              {email}
            </div>
            {showWorkEmailErrMsg && (
              <Form.Text id='emailHelpBlock' muted>
                Continuing with a Personal Email will limit domain monitoring and additional
                features for 2 weeks. Go back to add your work email to continue.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-flex'>
              First Name<div className='asterisk'>* </div>
            </Form.Label>
            <Form.Control
              id='firstName'
              type='text'
              placeholder='First Name'
              name='firstName'
              value={firstName}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-flex'>
              Last Name<div className='asterisk'>* </div>
            </Form.Label>
            <Form.Control
              id='lastName'
              type='text'
              placeholder='Last Name'
              name='lastName'
              value={lastName}
              onChange={this.handleChange}
            />
          </Form.Group>
          <PasswordEye
            label={'Password'}
            name={'password'}
            onChange={this.handleChange}
            helpText=''
          />
          {password && password.length > PASSWORD_LIMIT && (
            <Form.Group>
              <Form.Text id='emailHelpBlock' muted>
                Password has to be {PASSWORD_LENGTH} letters or more and less than {PASSWORD_LIMIT}{' '}
                letters
              </Form.Text>
            </Form.Group>
          )}
          <Button
            disabled={submitButtonDisabled}
            className='continue-button'
            variant='primary'
            type='submit'
          >
            {isLoading ? (
              <Spinner className='spinner' animation='border' variant='light' size='sm' />
            ) : (
              'Sign Up'
            )}
          </Button>
          {showWorkEmailErrMsg && !remindMeLater ? (
            <Form.Label
              className='d-flex remind-me-later'
              onClick={() => this.setState({ remindMeLater: true })}
            >
              Remind Me Later
            </Form.Label>
          ) : (
            this.pressEnterContainer()
          )}
        </Form>
      </div>
    );
  };

  showScreenWidthInfo = () => {
    const { showScreenWidthData } = this.state;
    if (!showScreenWidthData) {
      return null;
    }
    return (
      <Col className='screen-width-info mt-3 d-flex' md={12}>
        <Col className='no-padding' xs={1} sm={1}>
          <img className='work-email-err-msg-logo' src={WarningYellowRoundIcon} alt={'logo'} />
        </Col>
        <Col xs={10} sm={10} className='mb-0' as={'p'}>
          CheckPhish is better on desktop.
          <br />
          Switch to desktop for enhanced experience.
        </Col>
        <Col
          className='no-padding'
          xs={1}
          sm={1}
          onClick={() => this.setState({ showScreenWidthData: false })}
        >
          <ClearRoundedIcon />
        </Col>
      </Col>
    );
  };

  render() {
    const { workEmailProvider, remindMeLater, isWorkEmail } = this.state;
    const { href } = window.location;
    const isTypoSquatError = href.includes('typosquat');
    const typosquatError =
      'You have reached your daily limit. Sign-up for free to obtain your history and keep monitoring your domain against threats.';
    const title = 'Sign Up | CheckPhish';
    const url = 'https://checkphish.ai/sign-up';
    const description =
      'CheckPhish is an Artificial Intelligence powered zero-day phishing detection.';
    const type = 'website';
    const imagePath = 'https://checkphish.ai/static/media/og-image.9d427d73.png';
    const keywords =
      'url,website,phishing,scanner,analyze,cyber threat intelligence, open source threat intelligence, online fraud';
    return (
      <AuthenticationWrapper>
        <div className={'sign-up-via-brand-page sign-up-v2'}>
          <Helmet>
            <title>{title}</title>
            <meta name='robots' content='index, follow' />
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />

            <meta property='og:type' content={type} />
            <meta property='og:url' content={url} />
            <meta name='title' property='og:title' content={title} />
            <meta name='description' property='og:description' content={description} />
            <meta name='image' property='og:image' content={imagePath} />

            <meta name='twitter:description' content={description} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:image' content={imagePath} />
            <meta name='twitter:site' content='@checkphish' />
            <meta name='twitter:creator' content='@checkphish' />
          </Helmet>
          <div className={'sign-up-brands-container'} id={'sign-up-brands-container'}>
            <div className={'sign-up-container'}>
              {this.showScreenWidthInfo()}
              <CompanyIcon className='checkphish-logo-login' />
              {(remindMeLater || isWorkEmail) && (
                <div className={`back-btn ${workEmailProvider !== '' && 'pt-5'}`}>
                  <button className='btn transparent-btn' onClick={this.goBack}>
                    <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
                    Back
                  </button>
                </div>
              )}
              {isTypoSquatError && (
                <div className={'typo-error'}>
                  <div className={'err'}>{typosquatError}</div>
                </div>
              )}
              {isWorkEmail ? (
                <>{workEmailProvider === '' ? this.manualSignUpView() : this.hasProviderView()}</>
              ) : (
                <>{remindMeLater ? this.manualSignUpView() : this.emailVerificationView()}</>
              )}
            </div>
          </div>
        </div>
      </AuthenticationWrapper>
    );
  }
}
