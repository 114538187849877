import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import './reports.scss';
import { appConstants, isMobile } from '../../constants';
import { PageTitle } from '../Common/PageTitle';
import { TableApi } from '../Common/Table/table.api';
import { TableContextProvider } from '../Common/Table/table.context';
import { IFilter } from '../Common/Table/constant';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import {
  GENERATED_BY_COLUMN,
  GENERATED_ON_COLUMN,
  MODULE_COLUMN,
  REPORT_NAME_COLUMN,
  USER_TOOLS_COLUMN,
} from './ReportsColDefs';
import { alertActions } from '../../actions';
import { IItem } from './Reports.types';
import SendReportModal from './SendReportModal';
import ReportsGenerationControl from './ReportsGenerationControl';
import { downloadReportPdf, getReportViaEmail, getReports } from './report-requests';
import {
  shouldSetIsEmailLoading,
  shouldSetSelectedReport,
  shouldShowEmailModal,
} from '../../reducers/reports.reducer';
import useOnReportsModifyCols from './useOnReportsModifyCols';
import { massageReportsData } from './reports-utils';
import { REPORTS_TABLE_ID } from './ReportsConstant';
import { getAvailableModules } from '../../helpers/get-available-modules';
import { ReportsLandingPage } from './LandingPage/ReportsLandingPage';

function Reports(props: Record<string, never>) {
  window.document.title = 'Reports | Bolster Platform';

  const ReportSendErrMsg = 'Report failed to send. Please contact support@bolster.ai.';
  const ReportDownloadErrMsg = 'Report failed to download. Please contact support@bolster.ai.';

  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.dashboardReducer.user);
  const selectedReport = useAppSelector(state => state.reportsReducer.selectedReport);
  const newlyGeneratedReport = useAppSelector(state => state.reportsReducer.newlyGeneratedReport);
  const refetchOldTableTs = useAppSelector(state => state.reportsReducer.refetchOldTableTs);
  const showEmailModal = useAppSelector(state => state.reportsReducer.isEmailModalShown);

  // TODO remove this after ag grid is implemented
  const fetchApiFn = (query: any, filters: IFilter[], sort: any) => {
    const brandId = user?.brand_info && user.brand_info.brand.brandId;
    const orgId =
      user?.brand_info &&
      user.brand_info.brand.subBrands.find((item: any) => {
        return item.brandId === brandId;
      });

    if (orgId && 'relatedOrganizationId' in orgId) {
      return getReports({
        sorting: sort,
        filters: filters,
      })
        .then(res => {
          let data: any[] = [];
          let total = 0;
          if (res) {
            data = massageReportsData(res.data, newlyGeneratedReport);
            total = res.total;
          }
          return { data, total };
        })
        .catch((error: any) => {
          dispatch(alertActions.error('Failed to fetch reports.'));
        });
    }
  };

  const toggleEmailModal = (items: IItem[]) => {
    dispatch(shouldShowEmailModal(!showEmailModal));
    dispatch(shouldSetSelectedReport(items));
  };

  const sendEmail = () => {
    dispatch(shouldSetIsEmailLoading(true));
    getReportViaEmail(selectedReport[0]?.id, selectedReport[0]?.report_name, 'email', user.email)
      .then((res: any) => {
        if (!isMobile()) {
          if (res.result) {
            dispatch(alertActions.success('Report ' + selectedReport[0].report_name + ' sent.'));
          } else {
            dispatch(alertActions.error(ReportSendErrMsg));
          }
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.message ?? ReportSendErrMsg));
      })
      .finally(() => {
        dispatch(shouldSetIsEmailLoading(false));
        dispatch(shouldShowEmailModal(false));
      });
  };

  const getDownloadedReport = (item: IItem[]) => {
    downloadReportPdf(item[0].id, item[0].report_name, 'download')
      .then((res: any) => {
        if (!isMobile()) {
          if (res.result) {
            dispatch(alertActions.success('Report ' + item[0].report_name + ' downloaded.'));
          } else {
            dispatch(alertActions.error(ReportDownloadErrMsg));
          }
        }
      })
      .catch(error => {
        dispatch(alertActions.error(error.message ?? ReportDownloadErrMsg));
      });
  };
  const REPORTS_COLUMNS: any[] = [
    REPORT_NAME_COLUMN,
    MODULE_COLUMN,
    GENERATED_BY_COLUMN,
    GENERATED_ON_COLUMN,
    USER_TOOLS_COLUMN(toggleEmailModal, getDownloadedReport),
  ];
  const { modifiedColumns } = useOnReportsModifyCols(REPORTS_COLUMNS);

  const allAvailableModules = getAvailableModules();

  if (allAvailableModules.length === 0) return <ReportsLandingPage />;

  return (
    <AuthenticationWrapper>
      <PageTitle title={'Reports'} tools={<ReportsGenerationControl />} />

      <div className={'reports-page'}>
        <div className='reports-table-container card-between'>
          <TableContextProvider
            columns={modifiedColumns}
            dashboardName={'Reports'}
            tableId={REPORTS_TABLE_ID}
          >
            <TableApi
              {...props}
              id={REPORTS_TABLE_ID}
              title={''}
              indexBy={'created_ts'}
              disableDatePicker
              fetchApi={fetchApiFn}
              columns={modifiedColumns}
              tableIndex={'created_ts'}
              // refreshOnColChange={true}
              apiTs={refetchOldTableTs}
              disablePagination={true}
              type={appConstants.CONTENT_TYPE.REPORT}
            />
          </TableContextProvider>
        </div>
      </div>

      <SendReportModal toggleEmailModal={toggleEmailModal} sendEmail={sendEmail} />
    </AuthenticationWrapper>
  );
}

export { Reports };
