import React, { useEffect } from 'react';

export const useSearchTermFilters = ({
  agRef,
  columns,
  colFieldForSearchTerm = 'search_term_labels',
}: {
  agRef: React.MutableRefObject<any>;
  columns: any[];
  //   dark web uses search_term, while ugc tables use search_term_labels
  colFieldForSearchTerm?: 'search_term_labels' | 'search_term' | 'source_feed';
}) => {
  // TODO: columns dont change, while searchTerm filterOptions change inside, which could be buggy, need to refactor searchTerm filterOptions update logic.
  const searchTermFilterOptions = columns
    .find(col => col.accessor === colFieldForSearchTerm)
    ?.filterOptions?.slice();

  // update columnDefs when searchTermFilterOptions change, to privide new filterOptions for search term column

  useEffect(() => {
    const colDefs: any = agRef.current?.api?.getColumnDefs();
    if (!colDefs) return;

    const searchTermColDef = colDefs.find((col: any) => col.field === colFieldForSearchTerm);
    if (!searchTermColDef) return;

    searchTermColDef.filterParams = {
      filterOptions: [
        'empty',
        ...(searchTermFilterOptions?.map((option: { label: string; value: string }) => ({
          displayKey: option['value'],
          displayName: option['label'],
          predicate: (_: any, cellValue: string | { label: string }[]) => {
            return cellValue === option['value'];
          },
          numberOfInputs: 0,
        })) || []),
      ],
      maxNumConditions: 1,
      buttons: ['reset'],
    };

    agRef.current?.api?.setGridOption('columnDefs', colDefs);
  }, [agRef, colFieldForSearchTerm, columns, searchTermFilterOptions]);
};
