import React from 'react';
import { Modal } from 'react-bootstrap';
import { IItem, ISendEmailProps } from './Reports.types';
import Button from 'react-bootstrap/Button';
import { LoadingWrapper } from '../Common/LoadingWrapper';
import { useAppSelector } from '../../helpers/hooks';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

export default function SendReportModal({ toggleEmailModal, sendEmail }: ISendEmailProps) {
  const [, selectedTheme] = useIsDarkMode();
  const isEmailModalShown = useAppSelector(state => state.reportsReducer.isEmailModalShown);
  const selectedReport = useAppSelector(state => state.reportsReducer.selectedReport);
  const user = useAppSelector(state => state.dashboardReducer.user);
  const isEmailLoading = useAppSelector(state => state.reportsReducer.isEmailLoading);

  return (
    <Modal
      show={isEmailModalShown}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className={`${selectedTheme}`}
    >
      <Modal.Header>
        <Modal.Title id='download-via-email'>Email Report</Modal.Title>
        <div onClick={() => toggleEmailModal([])} data-testid={'close-icon-wrapper'}>
          <ClearRoundedIcon />
        </div>
      </Modal.Header>
      <Modal.Body className={'modal-body'}>
        <div className={'report-names'}>
          {selectedReport.map((item: IItem, index: number) => {
            return <div key={'selected-report-' + index}>{item.timeframe}</div>;
          })}
        </div>
        <p>
          Report will be sent to: <b>{user?.email}</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => toggleEmailModal([])}>
          Cancel
        </Button>
        <div className='email-button'>
          {!isEmailLoading ? (
            <Button variant='primary' onClick={sendEmail}>
              Send
            </Button>
          ) : (
            <Button variant='primary'>
              <LoadingWrapper isLoading={isEmailLoading} />
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
