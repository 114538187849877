import React from 'react';
import _ from 'lodash';
import './Country.scss';
import { useAppSelector } from '../../helpers/hooks';

interface ICountryProps {
  countryCode: string;
  countryName?: string;
  defaultCountryCode?: string;
  customClassName?: string;
}

export interface ICountryCode {
  locationCode: string;
  locationName: string;
  continentCode: string;
  countryCode: string;
}

const Country = ({
  countryCode,
  countryName,
  defaultCountryCode,
  customClassName = '',
}: ICountryProps) => {
  const countryCodeList: Record<string, ICountryCode> = useAppSelector(
    state => state.appReducer.countryCodes,
  );

  if (_.isEmpty(countryCode)) {
    if (!defaultCountryCode) {
      return <span>--</span>;
    }
    countryCode = defaultCountryCode;
  }

  const displayCountryCode: string =
    !_.isEmpty(countryCodeList) && countryCodeList[countryCode]
      ? countryCodeList[countryCode]['countryCode']
      : countryCode;

  const countryClassName: string =
    'mr-1 country-icon flag-icon flag-icon-' + displayCountryCode.toLowerCase();

  return (
    <>
      <span className={countryClassName} />
      <span className={customClassName}>{countryName || displayCountryCode.toUpperCase()}</span>
    </>
  );
};

export default Country;
