import React from 'react';
import { IMatrixDataSet } from '../../types';
import { colorList } from '../../DarkWebConstants';
import '../../Styles/MatrixDataSetWidget.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface IMatrixDataSetWidget {
  matrixHeading: string;
  matrixDataSet: IMatrixDataSet;
  tooltip: string;
  hideUnique: boolean;
}

const renderToolTip = (message: string) => {
  return (
    <div className='tooltip-message' data-bs-toggle='tooltip'>
      {message}
    </div>
  );
};

const MatrixDataSetWidget = ({
  matrixHeading,
  matrixDataSet,
  tooltip,
  hideUnique,
}: IMatrixDataSetWidget) => {
  return (
    <div className='matrix-dataset-wrap' data-testid='widget-item'>
      <div className='heading'>{matrixHeading}</div>
      <div className='dataset-body'>
        {!hideUnique && (
          <div
            className='matrix-data-set'
            data-testid={'unique-data-set'}
            style={{ borderLeft: `3px solid ${colorList[0]}` }}
          >
            <div className='label' data-testid={'unique-data-set-label'}>
              {matrixDataSet.unique.label}{' '}
            </div>
            <div className='value' data-testid={'unique-data-set-value'}>
              {matrixDataSet.unique.value}
            </div>
          </div>
        )}
        <div
          className='matrix-data-set'
          data-testid={'total-data-set'}
          style={{ borderLeft: `3px solid ${colorList[1]}` }}
        >
          <div className='label' data-testid={'total-data-set-label'}>
            {matrixDataSet.total.label}{' '}
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='count-tooltip' className='risk-tooltips'>
                  {renderToolTip(tooltip)}
                </Tooltip>
              }
            >
              <div style={{ marginLeft: '6px' }}>
                <InfoOutlinedIcon />
              </div>
            </OverlayTrigger>
          </div>
          <div className='value' data-testid={'total-data-set-value'}>
            {matrixDataSet.total.value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrixDataSetWidget;
