import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DashBoardDto, setLocalStorageValue, UserAppSettings } from '../../../constants';
import { SCANTYPE } from '../../Checkphish/AllScans/scanType.enum';
import UserService from '../../../services/user.service';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { alertActions, dashboardActions } from '../../../actions';
import _ from 'lodash';

export default function useOnToggleTeamView() {
  const [teamViewValue, setTeamViewValue] = useState(false);
  const [scanLevel, setScanLevel] = useState(SCANTYPE.SOLO);

  const userService = useMemo(() => new UserService(), []);

  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.dashboardReducer.user);

  const getSwitchValue = useCallback((user: DashBoardDto) => {
    const switchValue = _.find(user.userAppSetting, {
      setting_name: UserAppSettings.TeamView,
    });
    if (switchValue) {
      return switchValue.setting_value;
    }
    return false;
  }, []);

  const onSaveTeamViewHandler = useCallback(
    async (teamViewValue: boolean) => {
      try {
        const payload = {
          setting_name: UserAppSettings.TeamView,
          setting_value: teamViewValue,
        };

        await userService.updateUserSettings(payload);
        setTeamViewValue(teamViewValue);
        setScanLevel(teamViewValue ? SCANTYPE.TEAM : SCANTYPE.SOLO);
        dispatch(dashboardActions.getUserInfo());
      } catch (error: any) {
        dispatch(
          alertActions.error(error?.message ?? 'Something went wrong on enabling the team view'),
        );
      }
    },
    [dispatch, userService],
  );

  useEffect(() => {
    const switchValue = getSwitchValue(user) === 'true' ? true : false;
    setTeamViewValue(switchValue);
    setScanLevel(switchValue ? SCANTYPE.TEAM : SCANTYPE.SOLO);
    setLocalStorageValue('showTeamView', switchValue);
  }, [getSwitchValue, user]);
  return {
    onSaveTeamViewHandler,
    scanLevel,
    teamViewValue,
  };
}
