import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setCurrentGlobalSearchSubTab } from '../../reducers/globalSearch.reducer';
import { EWebModuleTabFilterFields } from './GlobalSearchConstants';
import './GlobalSearchSubTab.scss';

export interface ISubBarItem {
  id: string;
  label: string;
  isActive: boolean;
}
export default function GlobalSearchSubTab({ fetchRelatedUrls }: any) {
  const [tabItems, setTabItems] = useState<ISubBarItem[]>([
    {
      id: EWebModuleTabFilterFields.src_url,
      label: 'Source URL',
      isActive: true,
    },
    {
      id: EWebModuleTabFilterFields.dst_url,
      label: 'Redirected URL',
      isActive: false,
    },
  ]);
  const dispatch = useAppDispatch();

  const currentGlobalSearchSubTab = useAppSelector(
    state => state.globalSearchReducer.currentGlobalSearchSubTab,
  );

  useEffect(() => {
    const newItems = tabItems.map((tabItem: ISubBarItem) => {
      if (tabItem.id === currentGlobalSearchSubTab) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
      return tabItem;
    });
    setTabItems(newItems);
  }, [currentGlobalSearchSubTab]);

  const onTabItemChange = useCallback(
    (item: ISubBarItem) => {
      const srcKey = item.id;
      dispatch(setCurrentGlobalSearchSubTab(EWebModuleTabFilterFields[srcKey]));
    },
    [dispatch],
  );
  const onTabClicked = useCallback(
    (item: ISubBarItem) => {
      const newItems = tabItems.map((tabItem: ISubBarItem) => {
        if (tabItem.id === item.id) {
          tabItem.isActive = true;
        } else {
          tabItem.isActive = false;
        }
        return tabItem;
      });
      setTabItems(newItems);
    },
    [tabItems],
  );

  return (
    <div className='global-search-sub-menu-tab-container '>
      <div className='global-search-sub-menu-tab-wrapper'>
        {tabItems.map((item: ISubBarItem, index: number) => {
          return (
            <div
              className={`tab-wrapper ${item?.isActive ? 'active' : ''}`}
              key={index}
              onClick={() => {
                onTabClicked(item);
                onTabItemChange(item);
                if (item.id === EWebModuleTabFilterFields.dst_url) {
                  void fetchRelatedUrls();
                }
              }}
              data-testid={`sub-tab-item-${index}`}
            >
              <div className={`tab-label ${item?.isActive ? 'active' : ''}`}>{item.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
