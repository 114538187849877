import React, { useEffect, useMemo, useState } from 'react';
import { DashboardData } from '../../constants';
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';

//disposition svgs
import CleanIconLight from '../../../../assets/abuseMailBox/CleanIconLight.svg';
import CleanIconDark from '../../../../assets/abuseMailBox/CleanIconDark.svg';
import MaliciousIconLight from '../../../../assets/abuseMailBox/MaliciousIconLight.svg';
import MaliciousIconDark from '../../../../assets/abuseMailBox/MaliciousIconDark.svg';
import SuspiciousIconLight from '../../../../assets/abuseMailBox/SuspiciousIconLight.svg';
import SuspiciousIconDark from '../../../../assets/abuseMailBox/SuspiciousIconDark.svg';
import SpamIconLight from '../../../../assets/abuseMailBox/SpamIconLight.svg';
import SpamIconDark from '../../../../assets/abuseMailBox/SpamIconDark.svg';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

const MOST_REPORTED_DOMAINS_CATEGORIES: Record<string, string> = {
  threatActorDomains: 'Threat Actor Domains',
  userDomains: 'User Domains',
};

export const DISPOSITION_TO_ICON_MAPPER: Record<string, string> = {
  ['clean-light']: CleanIconLight,
  ['clean-dark']: CleanIconDark,
  ['suspicious-light']: SuspiciousIconLight,
  ['suspicious-dark']: SuspiciousIconDark,
  ['malicious-light']: MaliciousIconLight,
  ['malicious-dark']: MaliciousIconDark,
  ['phish-light']: MaliciousIconLight,
  ['phish-dark']: MaliciousIconDark,
  ['malware-light']: MaliciousIconLight,
  ['malware-dark']: MaliciousIconDark,
  ['scam-light']: MaliciousIconLight,
  ['scam-dark']: MaliciousIconDark,
  ['spam-light']: SpamIconLight,
  ['spam-dark']: SpamIconDark,
};

// TODO: remove this once we have a fix from ES data side
const NETFLIX_DOMAINS_TO_IGNORE = 'netflix.com';

export function MostReportedDomains({ dashboardData }: { dashboardData: DashboardData }) {
  const [selectedCategory, setSelectedCategory] = useState<string>('threatActorDomains');
  const [, selectedTheme] = useIsDarkMode();
  const data = useMemo(() => {
    return selectedCategory === 'userDomains'
      ? dashboardData.userDomains
      : dashboardData.threatActorDomainsV2;
  }, [selectedCategory, dashboardData]);

  const filteredData = useMemo(() => {
    return Object.entries(data).filter(([domain]) => !domain.endsWith(NETFLIX_DOMAINS_TO_IGNORE));
  }, [data]);

  const [isScrolling, setIsScrolling] = useState(false);

  const setSelectedIcons = (disposition: string) => {
    const key = `${disposition}-${selectedTheme}`;
    return DISPOSITION_TO_ICON_MAPPER[key] || '';
  };

  useEffect(() => {
    const scrollTimeout: any = null;
    const handleScroll = () => {
      setIsScrolling(true);
      scrollTimeout && clearTimeout(scrollTimeout);
      setTimeout(() => setIsScrolling(false), 200);
    };

    document
      .querySelector('.most-reported-domains-table-container')
      ?.addEventListener('scroll', handleScroll);

    return () => {
      document
        .querySelector('.most-reported-domains-table-container')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='most-reported-domains'>
      <div className='shared-titles-for-cards'>Most Reported Domains</div>
      <div className='phish-scam-buttons-container'>
        {Object.keys(MOST_REPORTED_DOMAINS_CATEGORIES).map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`phish-scam-buttons ${selectedCategory === category ? 'active' : ''}`}
          >
            {MOST_REPORTED_DOMAINS_CATEGORIES[category]}
          </button>
        ))}
      </div>
      <div className='most-reported-domains-table-container'>
        <Table className='most-reported-domains-table'>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Report Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(0, 50).map(([domain, reportCount], index) => (
              <TableRow key={index}>
                <>
                  <TableCell>
                    {isScrolling ? (
                      <>
                        {selectedCategory !== 'userDomains' && (
                          <img
                            src={setSelectedIcons(reportCount.type)}
                            style={{ marginRight: '10px' }}
                          />
                        )}
                        {domain}
                      </>
                    ) : (
                      <>
                        {selectedCategory !== 'userDomains' && (
                          <Tooltip title={reportCount.type}>
                            <img
                              src={setSelectedIcons(reportCount.type)}
                              style={{ marginRight: '10px' }}
                            />
                          </Tooltip>
                        )}
                        <Tooltip title={domain}>
                          <span>{domain}</span>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {selectedCategory === 'userDomains' ? reportCount : reportCount.count}
                  </TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
