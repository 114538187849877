import React from 'react';
import _ from 'lodash';
import { DateSortingFunction, getLocalTimeFromUtcTime } from '../../../../../constants';
import { ITableDataColumn } from '../../../../Common/Table/table.data';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PlusIcon from '../../../../../assets/icons/Plus.svg';
import InProgressIcon from '../../../../../assets/icons/InProgress.svg';
import StatusDotGreenIcon from '../../../../../assets/icons/StatusDotGreen.svg';
import BanGreyIcon from '../../../../../assets/icons/BanGrey.svg';
import LogoImageColRender from '../../Findings/TableColDef/LogoImageColRender';
import CustomCellRenderer from './CustomCellRenderer';

export const SEARCH_TERM_COLUMN: ITableDataColumn = {
  id: 'searchTerm',
  accessor: 'searchTerm',
  header: 'Search Term',
  render: (data: any) => {
    return <CustomCellRenderer value={data.searchTerm} isDisabled={data.isDisabled} />;
  },
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
};
export const PLATFORMS_COLUMN: ITableDataColumn = {
  id: 'platforms',
  accessor: 'platformNames',
  header: 'Platforms',
  type: 'optionsString',
  agFilterType: 'agTextColumnFilter',
  filterOptions: [],
  render: (data: any) => {
    const { searchTerm } = data;
    const allIcons = _.map(data.platforms, p => (
      <img className='platform-column-logo' src={p.logoUrl} alt={p.label} key={p.value} />
    ));
    const displayIcons = allIcons.slice(0, 3);
    if (allIcons.length > 3) {
      displayIcons.push(<img src={PlusIcon} alt={'more'} key='plus' />);
    }

    const platformOriginLabels = data?.platforms?.map((p: any) => {
      return p.origins?.map((o: any, indx: number) => {
        return (
          <div key={indx} className='d-flex align-items-center'>
            {' '}
            <img
              className='platform-column-logo'
              src={p.logoUrl}
              alt={p.label}
              key={p.value}
            />{' '}
            {p.label + ' ' + o.label}
          </div>
        );
      });
    });
    return (
      <OverlayTrigger
        placement={'top-start'}
        overlay={
          <Tooltip id={'tooltip-' + searchTerm} className={'table-source-url-tooltip'}>
            {platformOriginLabels}
          </Tooltip>
        }
      >
        <div>
          <CustomCellRenderer value={displayIcons} isDisabled={data.isDisabled} />
        </div>
      </OverlayTrigger>
    );
  },
};
export const LOGO_IMAGES_COLUMN: ITableDataColumn = {
  id: 'uploads',
  accessor: 'uploadCount',
  header: 'Logo & Images',
  render: (data: any) => {
    return <LogoImageColRender data={data} />;
  },
  filterDisabled: true,
  sortDisabled: true,
};
export const CATEGORY_COLUMN: ITableDataColumn = {
  id: 'category',
  accessor: 'category',
  header: 'Category',
  type: 'options',
  filterOptions: [],
  render: (data: any) => {
    return <CustomCellRenderer value={data.category} isDisabled={data.isDisabled} />;
  },
  agFilterType: 'agTextColumnFilter',
};
export const AUTHOR_COLUMN: ITableDataColumn = {
  id: 'author',
  accessor: 'author',
  header: 'Author',
  render: (data: any) => {
    return <CustomCellRenderer value={data.author} isDisabled={data.isDisabled} />;
  },
  agFilterType: 'agTextColumnFilter',
};
export const ADDED_COLUMN: ITableDataColumn = {
  id: 'createdTs',
  accessor: 'createdTs',
  header: 'Added',
  filterDisabled: true,
  sortingFn: (a: any, b: any) => {
    return DateSortingFunction(a, b, 'createdTs');
  },
  isDefaultSort: true,
  render: (data: any) => {
    return (
      <CustomCellRenderer
        value={getLocalTimeFromUtcTime(data.createdTs)}
        isDisabled={data.isDisabled}
      />
    );
  },
};
export const TIMES_DETECTED_COLUMN: ITableDataColumn = {
  id: 'times',
  accessor: 'times',
  header: 'Times Detected',
  type: 'number',
  agFilterType: 'agNumberColumnFilter',
  render: (data: any) => {
    return <CustomCellRenderer value={data.times} isDisabled={data.isDisabled} />;
  },
};
export const LAST_SEARCH_COLUMN: ITableDataColumn = {
  id: 'historyCreatedTs',
  accessor: 'historyCreatedTs',
  fieldForExport: 'lastSearchText',
  header: 'Last Search',
  filterDisabled: true,
  render: (data: any) => {
    let icon;
    const { isDisabled, historyCreatedTs, lastSearchText } = data;
    if (isDisabled) {
      icon = <img src={BanGreyIcon} alt={'ban'} className='pr-1' />;
    } else {
      if (historyCreatedTs) {
        icon = <img src={StatusDotGreenIcon} alt={'green'} className='pr-1' />;
      } else {
        icon = <img src={InProgressIcon} alt={'spinning'} className='pr-1' />;
      }
    }
    return <CustomCellRenderer icon={icon} value={lastSearchText} isDisabled={data.isDisabled} />;
  },
};
export const IS_DISABLED_COLUMN: ITableDataColumn = {
  id: 'statusIsDisabled',
  accessor: 'isDisabledTextValue',
  header: 'Status',
  hidden: true,
  type: 'options',
  filterOptions: [
    { label: 'Disabled', value: 'Disabled' },
    { label: 'Enabled', value: 'Enabled' },
  ],
  render: (data: any) => {
    return <CustomCellRenderer value={data.isDisabledTextValue} isDisabled={data.isDisabled} />;
  },
  agFilterType: 'agTextColumnFilter',
};
export const IS_LOGO_DETECTION_COL: ITableDataColumn = {
  id: 'isLogoDetection',
  accessor: 'isLogoDetection',
  header: 'Logo Detection',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'True' },
    { label: 'False', value: 'False' },
  ],
  render: (data: any) => {
    return <CustomCellRenderer value={data.isLogoDetection} isDisabled={data.isDisabled} />;
  },
};

export const OPTIONS_ENTITY_COL: ITableDataColumn = {
  id: 'entity',
  accessor: 'entity',
  header: 'Entity',
  type: 'options',
  agFilterType: 'agTextColumnFilter',
  filterOptions: [],
  render: (data: any) => {
    if (data?.entity) {
      return <CustomCellRenderer value={data.entity} isDisabled={data.isDisabled} />;
    }
  },
};
