export const massageReportsData = (rawData: any, newlyGeneratedReports: any[]) => {
  return rawData.map((item: any) => {
    return {
      ...item,
      isNewAdded: newlyGeneratedReports.includes(item.id),
    };
  });
};

export const selectReportValueMap: {
  [key: string]: string;
} = {
  Web: 'web',
  'Social Media': 'social',
  'App Store': 'app-store',
  'Dark Web': 'dark-web',
};
