import {
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  SortChangedEvent,
  ToolPanelVisibleChangedEvent,
} from 'ag-grid-community';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TableContext } from '../table.context';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { trackPendoEvent } from '../../../../services/pendo-requests';
import moment from 'moment';

export const useAgGridEvents = ({
  agRef,
  tableId,
  allRowData,
}: {
  agRef: React.MutableRefObject<any>;
  tableId: string;
  allRowData: any;
}) => {
  const { onTableSaveColumns } = useContext(TableContext);
  const { sizeColumnsToFitGrid } = useAgGridFeaturesConfigs({ tableId });

  const saveColumnState = () => {
    const savedState = agRef.current?.api?.getColumnState();
    setLocalStorageValue(['savedColumnState', tableId], savedState);
  };

  const handleColumnVisible = useCallback(
    (event: ColumnVisibleEvent) => {
      const allVisibleColumns = event.api.getAllDisplayedColumns();
      const visibleColumns = allVisibleColumns.map(column => column.getColId());
      if (onTableSaveColumns && event.column && event.source === 'toolPanelUi') {
        agRef.current?.api?.showLoadingOverlay();
        const sideBarToolPanel = document.getElementsByClassName('ag-side-bar-right')[0];
        sideBarToolPanel?.classList?.add('ag-side-bar-right-unselectable');
        saveColumnState();
        onTableSaveColumns(visibleColumns, () => {
          agRef.current?.api?.hideOverlay();
          sideBarToolPanel.classList.remove('ag-side-bar-right-unselectable');
        });
      }
    },
    [onTableSaveColumns],
  );

  //Column Moving - Save/Apply State - https://www.ag-grid.com/react-data-grid/column-state/#save-and-apply
  const colDefs = agRef?.current?.api?.getColumnDefs();
  useEffect(() => {
    if (agRef.current?.api?.getColumnDefs) {
      const savedColumnState = getLocalStorageValue(['savedColumnState', tableId]);
      if (colDefs && Object.keys(savedColumnState).length !== 0) {
        agRef.current?.api.applyColumnState({
          state: savedColumnState,
          applyOrder: true,
        });
      }
      const tableToolPanelState = getLocalStorageValue(['savedToolPanelState', tableId]);
      !tableToolPanelState
        ? agRef.current?.api?.closeToolPanel?.('columns')
        : agRef.current?.api?.openToolPanel?.('columns');

      sizeColumnsToFitGrid(agRef?.current?.api);
    }
  }, [colDefs, agRef.current]);

  const handleColumnMoved = useCallback(
    (event: ColumnMovedEvent) => {
      const allColumns = event.api.getAllDisplayedColumns();
      const allColumnMoved = allColumns.map(column => column.getColId());
      if (onTableSaveColumns && event.finished && event.toIndex) {
        agRef.current?.api?.showLoadingOverlay();
        const sideBarToolPanel = document.getElementsByClassName('ag-side-bar-right')[0];
        sideBarToolPanel?.classList?.add('ag-side-bar-right-unselectable');
        saveColumnState();
        onTableSaveColumns(allColumnMoved, () => {
          agRef.current?.api?.hideOverlay();
          sideBarToolPanel.classList.remove('ag-side-bar-right-unselectable');
        });
      }
    },
    [onTableSaveColumns],
  );

  const handleOnSortChanged = useCallback(
    (event: SortChangedEvent) => {
      saveColumnState();
      // This tracks the sorting event only if user change the sorting
      if (event.source === 'uiColumnSorted') {
        const colDef = event.columns?.[0].getColDef();
        trackPendoEvent('Sorting Column', {
          elementText: colDef?.headerName,
          pageUrl: window.location.pathname,
          timestamp: moment().valueOf(),
        });
      }
    },
    [tableId],
  );

  const handleToolPanelVisibleChanged = useCallback(
    (event: ToolPanelVisibleChangedEvent) => {
      if (event.source === 'sideBarButtonClicked') {
        sizeColumnsToFitGrid(event.api);
        setLocalStorageValue(['savedToolPanelState', tableId], event.visible);
      }
    },
    [tableId],
  );

  const saveFilterChangeToLocalStorage = useCallback(
    (event: FilterChangedEvent) => {
      setLocalStorageValue(['tableFilterModel', tableId], event.api.getFilterModel());
    },
    [tableId],
  );

  const handleColumnResized = useCallback(
    (event: ColumnResizedEvent) => {
      if (event.source === 'uiColumnResized' && event.type === 'columnResized' && event.finished) {
        saveColumnState();
        const colDef = event.column?.getColDef();
        trackPendoEvent('Column Resized', {
          elementText: colDef?.headerName,
          pageUrl: window.location.pathname,
          timestamp: moment().valueOf(),
        });
      }
    },
    [tableId],
  );

  return {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    saveFilterChangeToLocalStorage,
    handleColumnResized,
  };
};
