import _ from 'lodash';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import AppStoreScanResults from '../../../../AppStore/Components/Findings/common/AppStoreScanResults';
import { SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME } from '../../../../SocialMedia/constants';
import { IFindingInsightDataProps, ugcType } from '../../../Types/ugc.types';
import FindingInsightBanner from '../../Common/FindingInsightBanner';
import DetectionDetailsSection from './DetectionDetailsSection';
import ImagesSection from './ImagesSection';
import SafelistSection from './SafelistSection';
import ScanResultSection from './ScanResultSection';
import TakedownHistorySection from './TakedownHistorySection';
import { useAppSelector } from '../../../../../helpers/hooks';

export interface IFindingInsight {
  findingDetails: IFindingInsightDataProps;
  takedownDetails: any;
  safelistDetails: any;
  moduleType: string;
  scanCountInfo: any;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}
const FindingInsight = ({
  moduleType,
  findingDetails,
  takedownDetails,
  safelistDetails,
  scanCountInfo,
  setIsLoading,
  isLoading,
}: IFindingInsight) => {
  const user = useAppSelector(state => state.dashboardReducer.user);

  const reload = () => {
    setIsLoading(true);
  };
  return (
    <>
      {window.location.pathname.indexOf(SOCIAL_MEDIA_FINDINGS_REVIEW_PATHNAME) !== -1 && (
        <FindingInsightBanner
          selectedFinding={findingDetails}
          type={moduleType}
          onEditModalSubmit={reload}
        />
      )}
      {isLoading ? (
        <div className={'insight-spinner-container'} data-testid='insight-spinner-container'>
          <Spinner animation='border' />
        </div>
      ) : (
        <div data-testid='ugc-insights-container' className='page-content insights-container'>
          <Row>
            <Col md={6} className='self-6'>
              <div>
                {moduleType !== ugcType.App_Store ? (
                  <>
                    <ScanResultSection data={findingDetails} type={moduleType} />
                    <DetectionDetailsSection data={findingDetails} />
                  </>
                ) : (
                  <div data-testid='mock-app-store-scan-source'>
                    <AppStoreScanResults
                      data={findingDetails}
                      type={moduleType}
                      scanCountInfo={scanCountInfo}
                    />
                  </div>
                )}
                {takedownDetails && (
                  <TakedownHistorySection takedown={takedownDetails} key={findingDetails.sha256} />
                )}
                {safelistDetails && <SafelistSection safelist={safelistDetails} />}
              </div>
            </Col>
            <Col md={6} className='self-6'>
              <div>
                <ImagesSection
                  data={findingDetails}
                  brandMnemonic={_.get(user, ['brand'], '')}
                  type={moduleType}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default FindingInsight;
