import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import './MuiCardWrapper.scss';

interface MuiCardProps {
  children: React.ReactNode;
  cardTitle?: string;
  headerComp?: React.ReactNode;
}

//https://mui.com/material-ui/react-card/#complex-interaction
export function MuiCardWrapper({ children, cardTitle, headerComp }: MuiCardProps) {
  return (
    <Card variant='outlined' className='mui-card-wrapper'>
      <CardHeader className='mui-card-header' title={cardTitle} action={headerComp} />
      <CardContent className='mui-card-content'>{children}</CardContent>
    </Card>
  );
}
