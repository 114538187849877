import React from 'react';
import { Checkbox } from '../Common/Checkbox';
import NoDomainImageLight from '../../assets/typosquat-welcome-page.png';
import NoDomainImageDark from '../../assets/typosquat-welcome-page-dark.png';
import './typosquat.scss';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

interface ITyposquatProgressPage {
  isProgressState: boolean;
  searchText: string;
}

const TableRow: React.FC = () => {
  return (
    <tr className='typosquat-table-row'>
      <td className='table-data checkbox'>
        <Checkbox disabled={true} />
      </td>
      <td className='table-data medium block'></td>
      <td className='table-data medium block'></td>
      <td className='table-data medium block'></td>
      <td className='table-data medium block'></td>
      <td className='table-data medium block'></td>
      <td className='table-data small round block'></td>
      <td className='table-data medium round block'></td>
      <td className='table-data medium block'></td>
    </tr>
  );
};

const TyposquatProgressPage: React.FC<ITyposquatProgressPage> = ({
  isProgressState,
  searchText,
}: ITyposquatProgressPage) => {
  const [isDarkMode] = useIsDarkMode();
  return (
    <div className='progress-page-wrap'>
      {isProgressState ? (
        <>
          <div className='table-toolbar'>
            <div className='toolbar-block block'></div>
          </div>
          <div className='progress-table-wrap'>
            <table className='progress-table'>
              <tbody>
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className='no-data-page'>
          <img src={isDarkMode ? NoDomainImageDark : NoDomainImageLight} alt='' />
        </div>
      )}
    </div>
  );
};

export default TyposquatProgressPage;
