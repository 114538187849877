import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLocalStorageValue } from '../../../../constants';
import { GridApi, GridSizeChangedEvent } from 'ag-grid-community';
import { AG_GRID_LOCALE_EN } from './locale';
export const useAgGridFeaturesConfigs = ({ tableId }: { tableId: any }) => {
  const [tableToolPanelState, setTableToolPanelState] = useState(false);

  useEffect(() => {
    if (tableId) {
      const toolPanelState = getLocalStorageValue(['savedToolPanelState', tableId]);
      setTableToolPanelState(toolPanelState);
    }
  }, [tableId]);

  const defaultToolPanel = tableToolPanelState ? 'columns' : '';

  const sideBarConfigs = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: true,
        },
      },
    ],
    defaultToolPanel,
  };

  //https://www.ag-grid.com/react-data-grid/column-menu/#built-in-menu-items - Built-In Menu Items
  const columnHeaderMenuItems = (params: any) => {
    const menuItems: string[] = [];
    const itemsToExclude = [
      'separator',
      'pinSubMenu',
      'valueAggSubMenu',
      'autoSizeThis',
      'autoSizeAll',
      'resetColumns',
      'rowUnGroup',
      'expandAll',
      'contractAll',
    ];
    params.defaultItems.forEach((item: string) => {
      if (itemsToExclude.indexOf(item) < 0) {
        menuItems.push(item);
      }
    });
    return menuItems;
  };

  //https://www.ag-grid.com/react-data-grid/grid-size/ - Calculating Grids Width
  const sizeColumnsToFitGrid = (api: GridApi) => {
    const gridWidth = document.querySelector('.ag-body-viewport')?.clientWidth || 0;
    const allVisibleColumns = api?.getAllDisplayedColumns();
    let totalWidth = 0;
    allVisibleColumns?.forEach(column => {
      const width = column.getActualWidth();
      totalWidth += width;
    });

    if (api && gridWidth >= totalWidth) {
      api.sizeColumnsToFit();
    }
  };

  const onHandleGridSizeChanged = useCallback(
    (params: GridSizeChangedEvent) => {
      setTimeout(() => {
        sizeColumnsToFitGrid(params.api);
      }, 10);
    },
    [tableId],
  );

  //https://www.ag-grid.com/react-data-grid/localisation/
  const localeText = useMemo<{ [key: string]: string }>(() => {
    return AG_GRID_LOCALE_EN;
  }, []);

  return {
    sideBarConfigs,
    columnHeaderMenuItems,
    onHandleGridSizeChanged,
    sizeColumnsToFitGrid,
    localeText,
  };
};
