import React, { useCallback, useEffect, useState } from 'react';
import './CommunicationSettings.scss';
import {
  appConstants,
  featureIsAvailable,
  setLocalStorageValue,
  setSessionStorageValue,
} from '../../../constants';
import { CHECKBOX_STATES, Checkbox } from '../../Common/Checkbox';
import { PageTitle } from '../../Common/PageTitle';
import { RadioButtons } from '../../Common/RadioButtons';
import NotificationServices, {
  NotificationServicesInterface,
} from '../../../services/notifications.service';
import {
  IConnector,
  INotificationFrequency,
  INotificationGroup,
  IUpdateNotificationPayload,
  UserNotificationSettingGroup,
} from './CommunicationSettings.types';
import { LoadingWrapper } from '../../Common/LoadingWrapper';
import { connect } from 'react-redux';
import { alertActions } from '../../../actions';
import moment from 'moment';
import { history } from '../../../helpers';

const notificationServices = new NotificationServices();

const CommunicationSettings = ({ user, alertSuccess, alertError }: any) => {
  const [moduleTypes, setModuleTypes] = useState<INotificationGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDigestSentTs, setLastDigestSentTs] = useState<string>('');

  const [activeConnectorIds, setActiveConnectorIds] = useState<number[]>([]);
  const [connectors, setConnectors] = useState<IConnector[]>([]);

  const [allFrequency, setAllFrequency] = useState<INotificationFrequency[]>([]);
  const [currentFrequency, setCurrentFrequency] = useState<INotificationFrequency | undefined>();

  const [module, setModule] = useState<INotificationGroup[]>([]);

  const [isNewSettingUpdated, setIsNewSettingUpdated] = useState(false);

  window.document.title = 'Communication Settings | Bolster Platform';

  const onChangeConnectors = useCallback(
    (value: boolean, selectedId: number) => {
      if (!value) {
        const result = activeConnectorIds.filter(connectorId => connectorId !== selectedId);
        setActiveConnectorIds(result);
      }
      if (value) {
        const newConnectorIds: number[] = [...activeConnectorIds];
        const moduleToAdd = connectors.find(item => item.id === selectedId)?.id;
        if (moduleToAdd) {
          newConnectorIds.push(moduleToAdd);
        }
        setActiveConnectorIds(newConnectorIds);
      }
      setIsNewSettingUpdated(true);
    },
    [activeConnectorIds, connectors],
  );

  const onChangeModules = useCallback(
    (value: boolean, name: string) => {
      if (!value) {
        const result = module.filter(item => item.label !== name);
        setModule(result);
      }
      if (value) {
        const newModule = [...module];
        const moduleToAdd = moduleTypes.find(item => item.label === name);
        if (moduleToAdd) {
          newModule.push(moduleToAdd);
        }
        setModule(newModule);
      }
      setIsNewSettingUpdated(true);
    },
    [module, moduleTypes],
  );

  const onChangeFrequency = (value: any) => {
    const frequency = allFrequency.find(item => item.value === value);
    setCurrentFrequency(frequency);
    setIsNewSettingUpdated(true);
  };

  useEffect(() => {
    setSessionStorageValue('bolsterPreviousUrl', window.location.pathname);
    const getNotificationConfigs = async () => {
      setIsLoading(true);
      try {
        const [notificationConfig, userNotificationPreferences] = await Promise.all([
          notificationServices.getAllNotificationConfig(),
          notificationServices.getUserNotificationPrefrences(),
        ]);

        setLastDigestSentTs(userNotificationPreferences?.lastSentTs || '');
        setConnectors(notificationConfig?.connectors || []);
        setActiveConnectorIds(
          userNotificationPreferences?.notificationConnectors?.map(i => i.id) || [],
        );
        setAllFrequency(notificationConfig?.frequencies || []);
        setCurrentFrequency(userNotificationPreferences?.notificationFrequency);
        notificationConfig?.groups?.sort((a, b) => a.id - b.id);
        setModuleTypes(notificationConfig.groups || []);
        const userModules = userNotificationPreferences?.userNotificationSettingGroups || [];
        const activeModules = userModules.map(
          (item: UserNotificationSettingGroup) => item.notificationGroup,
        );
        setModule(activeModules);
        setIsLoading(false);
        setIsNewSettingUpdated(false);
      } catch (error: any) {
        console.log('Error in communication setting page - ', error);
        //Todo: API need to send 401 status code for unauthorized user
        if (error === 'Not authorized.') {
          history.push(`/sign-in?error=${error}`);
        }
      }
    };
    getNotificationConfigs();

    return () => {
      setIsLoading(false);
    };
  }, []);

  // Update module types if user's module is available
  useEffect(() => {
    setModuleTypes(currentModules => {
      return currentModules.map(module => {
        return {
          ...module,
          disabled: !featureIsAvailable(
            user,
            appConstants.FEATURE_CODE[module.value.replaceAll('-', '_').toUpperCase()],
          ),
        };
      });
    });
  }, [module, user]);

  useEffect(() => {
    if (isNewSettingUpdated) {
      onUpdateNotficationSettings();
    }
    return () => {
      setIsNewSettingUpdated(false);
    };
  }, [isNewSettingUpdated]);

  const onUpdateNotficationSettings = async () => {
    const payload: IUpdateNotificationPayload = {
      groups: module?.map(item => {
        return { id: item.id, typeIds: [] };
      }),
      frequencyId: currentFrequency?.id,
      connectorIds: activeConnectorIds,
    };

    notificationServices
      .updateUserNotificationSettings(payload)
      .then((res: any) => {
        console.log(res);
        if (res) {
          alertSuccess('Your changes have been saved successfully');
        }
      })
      .catch((error: any) => {
        console.log(error);
        alertError('Something went wrong, please try again later');
      })
      .finally(() => {
        setIsNewSettingUpdated(false);
      });
  };

  return (
    <div
      className='communication-settings-container'
      data-testid='communication-settings'
      id='communication-settings'
    >
      <LoadingWrapper isLoading={isLoading}>
        <PageTitle title='Communication Settings' titleAlwaysShown className={'background-white'} />
        <div className='text-container'>
          <div className='subheader-text'>Customize And Update Your Email Digest Preferences</div>
          <div className='last-timestamp'>
            Last digest sent{' '}
            {lastDigestSentTs ? moment(lastDigestSentTs).format('MM/DD/YYYY @h:mm A') : 'N/A'}
          </div>
        </div>
        <div className='delivery-channel-container'>
          <div className='divisor-text'> Delivery Channel</div>
          <div className='connectors-wrapper'>
            {connectors.map((connector: IConnector) => {
              return (
                <Checkbox
                  label={connector.label}
                  onChange={value => onChangeConnectors(value, connector.id)}
                  key={connector.value}
                  disabled={false}
                  defaultChecked={activeConnectorIds.includes(connector.id)}
                  defaultCheckType={CHECKBOX_STATES.Checked}
                />
              );
            })}
          </div>
        </div>
        <div className='module-container'>
          <div className='divisor-text'> Modules</div>
          <div className='module-options-container'>
            {moduleTypes
              .filter(item => {
                const featureMap = {
                  web: 'WEB',
                  social: 'SOCIAL_MEDIA',
                  'app-store': 'APP_STORE',
                  'dark-web': 'DARK_WEB_V2',
                };
                const featureCode = featureMap[item.value];
                return featureIsAvailable(user, appConstants.FEATURE_CODE[featureCode]);
              })
              .map(item => {
                return (
                  <Checkbox
                    label={item.label}
                    disabled={item.disabled}
                    onChange={value => onChangeModules(value, item.label)}
                    key={item.value}
                    defaultCheckType={CHECKBOX_STATES.Checked}
                    defaultChecked={module.some(module => module.value === item.value)}
                  />
                );
              })}
          </div>
        </div>
        <div className='frequency-container'>
          <div className='divisor-text'> Frequency</div>
          <RadioButtons
            options={allFrequency}
            defaultValue={currentFrequency?.value}
            onChange={onChangeFrequency}
          />
        </div>
      </LoadingWrapper>
    </div>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(null, mapDispatchToProps)(CommunicationSettings);
