import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useAppSelector } from '../../../helpers/hooks';
import ModalOverlay from '../../Common/ModalOverlay';
import { Form } from 'react-bootstrap';
import { Dropdown, IDropdownOption } from '../../Common/Dropdown';
import Label from '../../Common/Label';
import { TextInput } from '../../Common/TextInput';
import _, { capitalize } from 'lodash';
import {
  PlatformModule,
  appConstants,
  featureIsAvailable,
  isBrandModuleAdmin,
} from '../../../constants';
import { useAvailableModules } from '../../../hooks/useAvailableModules';

export interface INewMemberPayload {
  firstName: string;
  lastName: string;
  email: string;
  brandId: string;
  roleId: string;
  disabledModules: PlatformModule[];
}
export interface IAddTeamMemberModalProps {
  showAddTeamMembersModal: boolean;
  closeAddTeamMembersModal: () => void;
  onAddNewMember: (payload: INewMemberPayload) => Promise<void>;
  isLoading: boolean;
}

enum TeamRole {
  Admin = '3',
  User = '2',
  ReadOnly = '-1',
  BrandModuleAdmin = '-2',
}

export default function AddTeamMemberModal(props: IAddTeamMemberModalProps) {
  const DEFAULT_ROLE = { label: 'User', value: TeamRole.User };
  const { showAddTeamMembersModal, onAddNewMember, closeAddTeamMembersModal, isLoading } = props;
  const [isInvalid, setIsInvalid] = useState(true);
  const [disableModulesCheckbox, setDisableModulesCheckbox] = useState(false);

  const user = useAppSelector(state => state.dashboardReducer.user);
  const isReadOnlyFeatureOn = featureIsAvailable(
    user,
    appConstants.FEATURE_CODE.READ_ONLY_USER_PER_MODULE,
  );

  const allAvailableModules = useAvailableModules();

  const formValues = useRef<INewMemberPayload>({
    firstName: '',
    lastName: '',
    email: '',
    brandId: '',
    roleId: DEFAULT_ROLE.value,
    disabledModules: isReadOnlyFeatureOn ? allAvailableModules : [],
  });

  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);

  const checkBoxRefs = useRef<Array<HTMLInputElement | null>>([]);

  const updateFormValues = useCallback(
    (value: any, field: string) => {
      formValues.current[field] = value;
      const { firstName, lastName, email, roleId, disabledModules } = formValues.current;
      const enabledModules = allAvailableModules.filter(
        module => !disabledModules.includes(module),
      );

      setIsInvalid(
        _.isEmpty(firstName) ||
          _.isEmpty(lastName) ||
          _.isEmpty(email) ||
          _.isEmpty(roleId) ||
          _.isEmpty(enabledModules),
      );
    },
    [allAvailableModules],
  );

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateFormValues(event.target.value, controlId);
  };

  const onFormSubmit = () => {
    const disabled = formValues.current.disabledModules.map(module => ({
      code: module.disabledCode,
      enabled: true,
    }));

    const payload = { ...formValues.current, disabled };
    void onAddNewMember(payload);
    closeAddTeamMembersModal();
  };

  const handleDropdownChange = (option: IDropdownOption, controlId: string) => {
    updateFormValues(option.value, controlId);
    if (isReadOnlyFeatureOn && controlId === 'roleId') {
      setDisableModulesCheckbox(option.value === TeamRole.Admin);
    }
  };

  useEffect(() => {
    if (!isReadOnlyFeatureOn) {
      return;
    }
    checkBoxRefs.current.forEach(checkbox => {
      if (checkbox) {
        checkbox.checked = disableModulesCheckbox;
      }
    });

    updateFormValues(disableModulesCheckbox ? [] : allAvailableModules, 'disabledModules');
  }, [allAvailableModules, disableModulesCheckbox, updateFormValues, isReadOnlyFeatureOn]);

  const setBrandColumnOptions = () => {
    let brandOptions: any = [];

    if (brandInfo) {
      brandOptions = _.chain(brandInfo.brand.subBrands)
        .map(subBrand => ({
          label: capitalize(subBrand.brand_display_name),
          value: subBrand.brand_id.toString(),
        }))
        .sortBy('label')
        .value();
    }
    return brandOptions;
  };

  return (
    <ModalOverlay
      show={showAddTeamMembersModal}
      title='Add Team Member'
      isLoading={isLoading}
      onSubmit={onFormSubmit}
      onCancel={closeAddTeamMembersModal}
      submitButtonDisabled={isInvalid}
      submitButtonLabel='Submit'
    >
      <Form>
        <Form.Group>
          <Label label='First Name' isRequired />
          <TextInput controlId='firstName' onChange={handleTextFieldChange} />
        </Form.Group>
        <Form.Group>
          <Label label='Last Name' isRequired />
          <TextInput controlId='lastName' onChange={handleTextFieldChange} />
        </Form.Group>
        <Form.Group>
          <Label label='Email' isRequired />
          <TextInput controlId='email' onChange={handleTextFieldChange} />
        </Form.Group>
        <Form.Group>
          <Label label='Brand' isRequired />
          <Dropdown
            boxStyle
            options={setBrandColumnOptions()}
            onChange={handleDropdownChange}
            controlId='brandId'
            defaultSelection={setBrandColumnOptions()[0]}
          />
        </Form.Group>
        <Form.Group>
          <Label label='Role' isRequired />
          <Dropdown
            btnClassName='font-weight-normal'
            boxStyle
            options={
              [
                !isBrandModuleAdmin(user) && { label: 'Admin', value: TeamRole.Admin },
                !isBrandModuleAdmin(user) &&
                  isReadOnlyFeatureOn && {
                    label: 'Module Admin',
                    value: TeamRole.BrandModuleAdmin,
                  },
                { label: 'User', value: TeamRole.User },
                isReadOnlyFeatureOn && {
                  label: 'Read-Only',
                  value: TeamRole.ReadOnly,
                },
              ].filter(Boolean) as IDropdownOption[]
            }
            onChange={handleDropdownChange}
            controlId='roleId'
            defaultSelection={DEFAULT_ROLE}
          />
        </Form.Group>
        {isReadOnlyFeatureOn && (
          <Form.Group controlId='Modules'>
            <Label label='Modules' isRequired />
            {allAvailableModules.map((item, index) => (
              <Form.Check
                key={index}
                type='checkbox'
                disabled={disableModulesCheckbox}
                label={item.name}
                id={`${item.disabledCode}-checkbox`}
                inline
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target;
                  const disabledModules = [...formValues.current.disabledModules];
                  if (!checked) {
                    disabledModules.push(item);
                  } else {
                    _.remove(disabledModules, module => module.disabledCode === item.disabledCode);
                  }
                  updateFormValues(disabledModules, 'disabledModules');
                }}
                ref={(el: HTMLInputElement | null) => (checkBoxRefs.current[index] = el)}
              />
            ))}
          </Form.Group>
        )}
      </Form>
    </ModalOverlay>
  );
}
