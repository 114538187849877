import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FilterModel } from 'ag-grid-community';
import { IDropdownOption } from '../components/Common/Dropdown';
import { ITakedownActions } from '../components/GlobalTakedown/TakedownTimelineContainer';

export interface TagsForActiveGroup {
  tagIdsToAdd: number[];
  tagIdsToRemove: number[];
  urlShas256: Set<string>;
}

export interface IGlobalTakedownRowData {
  [key: string]: {
    viewMode: string;
    prevMode?: string;
    data: ITakedownActions;
  };
}

export interface TableState {
  isAgGridClientMode: boolean;
  isGroupRelatedDataReady: boolean;
  outGoingPersistentFilterString: string;
  currentAppliedPersistentFilter: FilterModel;
  isPersistentFilterSaveButtonVisible: boolean;
  isFilterFromDashboard: boolean;
  isWebTablesGroupView: boolean;
  isExportCSVCalled: boolean;
  isPersistentFilterEditMode: boolean;
  isPersistentFilterDeleteMode: boolean;
  isPersistentFilterSaveMode: boolean;
  isPersistentFilterEditViewClicked: boolean;
  isDarkWebTablesGroupView: boolean;
  shouldRestorePageNumber: boolean;
  shouldTriggerAgGrigRefresh: boolean;
  searchTermsOptionsToDisplay: IDropdownOption[];
  tableTotalRows: number;
  previousUrlSha: string;
  tagsFromBulkAction: TagsForActiveGroup;
  shouldRefreshClientSideTable: boolean;
  tableIdField: string;
  currentRowIndex: number;
  numOfOtherCategories: number;
  globalTakedownRowData: IGlobalTakedownRowData;
  currentSelectedMonth: string;
  gtdWebBolsterTakeDownCount: number;
  gtdWebCustomerTakeDownCount: number;
  isPersistentFilterApplied: boolean;
}

export const initialState: TableState = {
  isAgGridClientMode: false,
  isGroupRelatedDataReady: false,
  outGoingPersistentFilterString: '{}',
  currentAppliedPersistentFilter: {},
  isPersistentFilterSaveButtonVisible: false,
  isFilterFromDashboard: false,
  isWebTablesGroupView: false,
  isExportCSVCalled: false,
  isPersistentFilterEditMode: false,
  isPersistentFilterDeleteMode: false,
  isPersistentFilterSaveMode: false,
  isPersistentFilterEditViewClicked: false,
  isDarkWebTablesGroupView: true,
  shouldRestorePageNumber: false,
  shouldTriggerAgGrigRefresh: false,
  searchTermsOptionsToDisplay: [],
  tableTotalRows: 0,
  previousUrlSha: '',
  tagsFromBulkAction: { tagIdsToAdd: [], tagIdsToRemove: [], urlShas256: new Set<string>() },
  shouldRefreshClientSideTable: false,
  tableIdField: '',
  currentRowIndex: -1,
  numOfOtherCategories: 0,
  globalTakedownRowData: {},
  currentSelectedMonth: '',
  gtdWebBolsterTakeDownCount: 0,
  gtdWebCustomerTakeDownCount: 0,
  isPersistentFilterApplied: false,
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setIsAgGridClientMode: (state, action: PayloadAction<boolean>) => {
      state.isAgGridClientMode = action.payload;
    },
    setIsGroupRelatedDataReady: (state, action: PayloadAction<boolean>) => {
      state.isGroupRelatedDataReady = action.payload;
    },
    setOutGoingPersistentFilterString: (state, action: PayloadAction<string>) => {
      state.outGoingPersistentFilterString = action.payload;
    },
    setCurrentAppliedPersistentFilter: (state, action: PayloadAction<FilterModel>) => {
      state.currentAppliedPersistentFilter = action.payload;
    },
    setIsPersistentFilterSaveButtonVisible: (state, action: PayloadAction<boolean>) => {
      state.isPersistentFilterSaveButtonVisible = action.payload;
    },
    setIsFilterFromDashboard: (state, action: PayloadAction<boolean>) => {
      state.isFilterFromDashboard = action.payload;
    },
    setIsWebTablesGroupView: (state, action: PayloadAction<boolean>) => {
      state.isWebTablesGroupView = action.payload;
    },
    setIsExportCSVCalled: (state, action: PayloadAction<boolean>) => {
      state.isExportCSVCalled = action.payload;
    },
    setIsPersistentFilterEditMode: (state, action: PayloadAction<boolean>) => {
      state.isPersistentFilterEditMode = action.payload;
    },
    setIsPersistentFilterDeleteMode: (state, action: PayloadAction<boolean>) => {
      state.isPersistentFilterDeleteMode = action.payload;
    },
    setIsPersistentFilterSaveMode: (state, action: PayloadAction<boolean>) => {
      state.isPersistentFilterSaveMode = action.payload;
    },
    setIsPersistentFilterEditViewClicked: (state, action: PayloadAction<boolean>) => {
      state.isPersistentFilterEditViewClicked = action.payload;
    },
    setIsDarkWebTablesGroupView: (state, action: PayloadAction<boolean>) => {
      state.isDarkWebTablesGroupView = action.payload;
    },
    setShouldRestorePageNumber: (state, action: PayloadAction<boolean>) => {
      state.shouldRestorePageNumber = action.payload;
    },
    setShouldTriggerAgGrigRefresh: (state, action: PayloadAction<boolean>) => {
      state.shouldTriggerAgGrigRefresh = action.payload;
    },
    setSearchTermsOptionsToDisplay: (state, action: PayloadAction<IDropdownOption[]>) => {
      state.searchTermsOptionsToDisplay = action.payload;
    },
    setTableTotalRows: (state, action: PayloadAction<number>) => {
      state.tableTotalRows = action.payload;
    },
    setPreviousUrlSha: (state, action: PayloadAction<string>) => {
      state.previousUrlSha = action.payload;
    },
    setTagsFromBulkAction: (state, action: PayloadAction<TagsForActiveGroup>) => {
      state.tagsFromBulkAction = action.payload;
    },
    setShouldRefreshClientSideTable: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshClientSideTable = action.payload;
    },
    setTableIdField(state, action: PayloadAction<string>) {
      state.tableIdField = action.payload;
    },
    setCurrentRowIndex(state, action: PayloadAction<number>) {
      state.currentRowIndex = action.payload;
    },
    setNumOfOtherCategories(state, action: PayloadAction<number>) {
      state.numOfOtherCategories = action.payload;
    },
    setGlobalTakedownRowData(state, action: PayloadAction<IGlobalTakedownRowData>) {
      state.globalTakedownRowData = action.payload;
    },
    setCurrentSelectedMonth(state, action: PayloadAction<string>) {
      state.currentSelectedMonth = action.payload;
    },
    setGtdWebBolsterTakeDownCount(state, action: PayloadAction<number>) {
      state.gtdWebBolsterTakeDownCount = action.payload;
    },
    setGtdWebCustomerTakeDownCount(state, action: PayloadAction<number>) {
      state.gtdWebCustomerTakeDownCount = action.payload;
    },
    setIsPersistentFilterApplied(state, action: PayloadAction<boolean>) {
      state.isPersistentFilterApplied = action.payload;
    },
  },
});

export const {
  setIsAgGridClientMode,
  setIsGroupRelatedDataReady,
  setOutGoingPersistentFilterString,
  setCurrentAppliedPersistentFilter,
  setIsPersistentFilterSaveButtonVisible,
  setIsFilterFromDashboard,
  setIsWebTablesGroupView,
  setIsExportCSVCalled,
  setIsPersistentFilterEditMode,
  setIsPersistentFilterDeleteMode,
  setIsPersistentFilterSaveMode,
  setIsPersistentFilterEditViewClicked,
  setIsDarkWebTablesGroupView,
  setShouldRestorePageNumber,
  setShouldTriggerAgGrigRefresh,
  setSearchTermsOptionsToDisplay,
  setTableTotalRows,
  setPreviousUrlSha,
  setTagsFromBulkAction,
  setShouldRefreshClientSideTable,
  setTableIdField,
  setCurrentRowIndex,
  setNumOfOtherCategories,
  setGlobalTakedownRowData,
  setCurrentSelectedMonth,
  setGtdWebBolsterTakeDownCount,
  setGtdWebCustomerTakeDownCount,
  setIsPersistentFilterApplied,
} = tableSlice.actions;

export default tableSlice.reducer;
