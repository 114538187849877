import React from 'react';
import moment from 'moment';
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { ITableApiColumn } from '../table.api';
import {
  AgAnyTagsFilterApiStringTypes,
  AgFilterDisplayOptions,
  MultiFilterConditions,
} from './constants';
import { accerssorTableIds } from './ag-defaultColumns-constants';
import _ from 'lodash';
import { appConstants, featureIsAvailable, setLocalStorageValue } from '../../../../constants';
import { ITableDataColumn } from '../table.data';
import { store } from '../../../../helpers';
import { getIsReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import { setPaginationControls } from '../../../../reducers/insightsContainer.reducer';

export const MAX_DATE_RANGE = 180;

export function getColumnRenderer(
  columnObj: ITableApiColumn,
  key: 'primary_domain' | 'search_term' | 'email' | 'src_url',
  isGroupViewEnabled?: boolean,
) {
  if (columnObj['id'] === key && isGroupViewEnabled) {
    return 'agGroupCellRenderer';
  } else {
    return function CustomColRenderer(params: ICellRendererParams) {
      // This is a workaround to store the current pages data in the redux store and local storage
      // when ag grid is displaying the data from cache
      const pageNumber = params?.api?.paginationGetCurrentPage() || 0;
      const currentTableId = store.getState().insightsContainerReducer.currentTableId;

      const currentPaginationControls =
        store.getState().insightsContainerReducer.paginationControls?.[currentTableId];

      if (currentPaginationControls && currentPaginationControls.currentPage !== pageNumber) {
        const allNodes = params?.api?.getRenderedNodes() || [];
        const allNodesData = allNodes.map(node => node.data);
        const urlShasAndTimestamps = allNodesData.map((item: any) => {
          return {
            urlSha256: item.url_sha256 || item.urlSha256,
            timestamp: item.timestamp,
          };
        });
        store.dispatch(
          setPaginationControls({
            [currentTableId]: {
              ...currentPaginationControls,
              currentPage: pageNumber,
              urlShaAndTimestamps: urlShasAndTimestamps,
            },
          }),
        );

        setLocalStorageValue(['paginationControls', currentTableId], {
          ...currentPaginationControls,
          currentPage: pageNumber,
          urlShaAndTimestamps: urlShasAndTimestamps,
        });
      }

      if (params.node.group) {
        return null;
      }
      if (typeof columnObj['render'] === 'function') {
        return columnObj['render'](params.data || {}) || '--';
      }
      return typeof params.value === 'object' || params.value === undefined ? (
        '--'
      ) : (
        <span>{params.value || '--' + ''}</span>
      );
    };
  }
}

export function getNumOfConditions(
  columnObj: any,
  numOfOtherCategories?: number,
  moduleType?: string,
) {
  const { CONTENT_TYPE } = appConstants;
  if (
    (moduleType === CONTENT_TYPE.WEB && columnObj['id'] === 'final_category') ||
    (moduleType === CONTENT_TYPE.SOCIAL && columnObj['id'] === 'category')
  ) {
    if (numOfOtherCategories === 0) {
      return MultiFilterConditions.MAX_NUM_CONDITIONS;
    } else if (numOfOtherCategories && numOfOtherCategories > 0) {
      return numOfOtherCategories;
    }
  }
  return MultiFilterConditions.MIN_NUM_CONDITIONS;
}

export function getValueOfTheColumn(columnObj: ITableApiColumn, params: ValueGetterParams) {
  const copyFieldValueFunc = columnObj['copyFieldValue'];
  if (typeof copyFieldValueFunc === 'function') {
    return copyFieldValueFunc(params.data);
  }
}

export function getAgFilterParams(
  columnObj: any,
  numOfOtherCategories?: number,
  tableType?: AgAnyTagsFilterApiStringTypes,
  moduleType?: string,
) {
  switch (columnObj['type']) {
    case 'number':
      return {
        filterOptions: ['inRange'],
        inRangeInclusive: true,
        maxNumConditions: 1,
        buttons: ['reset'],
      };
    case 'options':
    case 'optionsString':
      const numOfOptionsConditions = getNumOfConditions(
        columnObj,
        numOfOtherCategories,
        moduleType,
      );
      return {
        filterOptions: [
          'empty',
          ...(columnObj['filterOptions']?.map((option: { label: string; value: string }) => ({
            displayKey: option['value'],
            displayName: option['label'],
            predicate: (_: any, cellValue: string | { label: string }[]) => {
              if (columnObj['type'] === 'optionsString') {
                // search term tables' platform column filter specific
                if (!Array.isArray(cellValue)) {
                  cellValue = [];
                }
                return cellValue
                  .map((obj: { label: string }) => obj.label)
                  .includes(option['value']);
              }
              return cellValue === option['value'];
            },
            numberOfInputs: 0,
          })) || []),
        ],
        maxNumConditions: numOfOptionsConditions,
        defaultJoinOperator: 'OR',
        buttons: ['reset'],
      };
    case 'optionsMultiSelect':
      return {
        values: [
          'No tags',
          ...new Set(
            columnObj['filterOptions']?.map(
              (option: { label: string; value: string }) => option.label,
            ),
          ),
        ],
        suppressSorting: true,
        defaultToNothingSelected: true,
        buttons: ['reset'],
        suppressSelectAll: true,
      };
    case 'date':
      return {
        filterOptions: ['inRange'],
        maxNumConditions: 1,
        minValidDate: moment().subtract(MAX_DATE_RANGE, 'day').format('YYYY-MM-DD'),
        maxValidDate: moment().format('YYYY-MM-DD'),
        buttons: ['reset'],
      };
    default:
      const numOfContainsConditions =
        moduleType === appConstants.CONTENT_TYPE.WEB && columnObj['id'] === 'src_url'
          ? MultiFilterConditions.MAX_NUM_CONDITIONS
          : MultiFilterConditions.MIN_NUM_CONDITIONS;
      return {
        filterOptions:
          columnObj['type'] === 'ipv4'
            ? [AgFilterDisplayOptions.BEGINS_WITH, AgFilterDisplayOptions.NOT_BEGIN_WITH]
            : [AgFilterDisplayOptions.CONTAINS, AgFilterDisplayOptions.NOT_CONTAINS],
        maxNumConditions: numOfContainsConditions,
        defaultJoinOperator: 'OR',
        textMatcher: ({
          filterOption,
          value,
          filterText,
        }: {
          filterOption: string;
          value: string;
          filterText: string;
        }) => {
          if (filterText == null) {
            return false;
          }
          switch (filterOption) {
            case AgFilterDisplayOptions.CONTAINS:
            case AgFilterDisplayOptions.BEGINS_WITH:
              return ('.' + value).indexOf(filterText) >= 0;
            case AgFilterDisplayOptions.NOT_CONTAINS:
            case AgFilterDisplayOptions.NOT_BEGIN_WITH:
              return ('.' + value).indexOf(filterText) === -1;
            default:
              console.warn(`invalid filter option ${filterOption}`);
              return false;
          }
        },
        buttons: ['reset'],
      };
  }
}

export const generateWebColDefs = ({
  columns,
  savedNumOfCategoryFltrConditions,
  firstRenderDisplayColumnIdSet,
  renderGroupActionBtn,
  enableCheckbox,
  moduleType,
}: {
  columns: ITableApiColumn[];
  savedNumOfCategoryFltrConditions: number;
  firstRenderDisplayColumnIdSet: Set<string>;
  renderGroupActionBtn?: (params: any) => JSX.Element;
  enableCheckbox?: boolean;
  moduleType?: string;
}) => {
  const isReadOnlyUser = getIsReadOnlyUser();
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj): ColDef => {
      const isGroupViewEnabled = !!renderGroupActionBtn;
      return {
        field: columnObj['id'],
        hide: isGroupViewEnabled
          ? columnObj['id'] === 'primary_domain' ||
            !firstRenderDisplayColumnIdSet.has(columnObj['id'])
          : columnObj['accessor'] !== 'srcUrl' &&
            !firstRenderDisplayColumnIdSet.has(columnObj['id']),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'primary_domain', isGroupViewEnabled),
        cellRendererParams: isGroupViewEnabled && {
          innerRenderer: (params: any) => {
            return (
              <>
                {`${params.node.key} (${params.data.related_findings_count || 1})`}
                {renderGroupActionBtn && renderGroupActionBtn(params.value)}
              </>
            );
          },
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          }
          return params.value;
        },
        rowGroup: isGroupViewEnabled && columnObj['id'] === 'primary_domain',
        suppressColumnsToolPanel: isGroupViewEnabled && columnObj['id'] === 'primary_domain',
        autoHeight: columnObj['id'] === 'tags',
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(
          columnObj,
          savedNumOfCategoryFltrConditions,
          AgAnyTagsFilterApiStringTypes.IN_WITH_OR,
          moduleType,
        ),
        floatingFilter: true,
        headerCheckboxSelection:
          enableCheckbox &&
          !isGroupViewEnabled &&
          columnObj['accessor'] === 'srcUrl' &&
          !isReadOnlyUser,
        checkboxSelection:
          enableCheckbox &&
          !isGroupViewEnabled &&
          columnObj['accessor'] === 'srcUrl' &&
          !isReadOnlyUser,
        lockVisible: !isGroupViewEnabled && columnObj['accessor'] === 'srcUrl',
        lockPosition: !isGroupViewEnabled && columnObj['accessor'] === 'srcUrl',
        sortable:
          !isGroupViewEnabled && columnObj['id'] !== 'tags' && columnObj['id'] !== 'sourceFeed',
      };
    });

  return res;
};

export const generateDarkWebFlatTableColumnDefs = (
  columns: ITableApiColumn[],
  firstRenderDisplayColumnIdSet: Set<string>,
  isThreatActorTable: boolean,
) => {
  const isReadOnlyUser = getIsReadOnlyUser();

  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj): ColDef => {
      return {
        field: columnObj['accessor'],
        hide: !firstRenderDisplayColumnIdSet.has(columnObj['accessor']),
        headerName: columnObj['header'],
        cellRenderer: (params: ICellRendererParams) => {
          if (typeof columnObj['render'] === 'function') {
            return columnObj['render'](params.data || {});
          }
          return typeof params.value === 'object' || params.value === undefined ? (
            '--'
          ) : (
            <span>{params.value + ''}</span>
          );
        },
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj, AgAnyTagsFilterApiStringTypes.IN_WITH_COMMAS),
        floatingFilter: !isThreatActorTable,
        checkboxSelection: columnObj['id'] === 'title' && !isReadOnlyUser,
        headerCheckboxSelection: columnObj['id'] === 'title' && !isReadOnlyUser,
        lockVisible:
          columnObj['id'] === 'title' ||
          (isThreatActorTable && columnObj['accessor'] === 'threat_actor'),
        lockPosition:
          columnObj['id'] === 'title' ||
          (isThreatActorTable && columnObj['accessor'] === 'threat_actor'),
        sortable: columnObj['id'] !== 'tags' && !isThreatActorTable,
      };
    });
  return res;
};

const setHidden = (
  isGroupViewEnabled: boolean,
  columnObj: ColDef,
  firstRenderDisplayColumnIdSet: Set<string>,
) => {
  if (_.has(columnObj, 'hidden')) return columnObj['hidden'];
  return isGroupViewEnabled
    ? (columnObj['id'] === 'search_term' || columnObj['id'] !== 'title') &&
        !firstRenderDisplayColumnIdSet.has(columnObj['id'])
    : (columnObj['id'] !== 'search_term' && columnObj['id'] !== 'title') ||
        !firstRenderDisplayColumnIdSet.has(columnObj['id']);
};

export const generateDarkWebTableColumnDefs = ({
  columns,
  firstRenderDisplayColumnIdSet,
  enableCheckbox,
  isGroupViewEnabled,
}: {
  columns: ITableApiColumn[];
  firstRenderDisplayColumnIdSet: Set<string>;
  enableCheckbox?: boolean;
  isGroupViewEnabled: boolean;
}) => {
  const isReadOnlyUser = getIsReadOnlyUser();

  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: columnObj['id'],
        hide: setHidden(isGroupViewEnabled, columnObj, firstRenderDisplayColumnIdSet),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'search_term', isGroupViewEnabled),
        cellRendererParams: isGroupViewEnabled && {
          innerRenderer: (params: any) => {
            return <>{`${params.node.key} (${params.data.related_findings_count || 1})`}</>;
          },
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          }
          return params.value;
        },
        rowGroup: isGroupViewEnabled && columnObj['id'] === 'search_term',
        suppressColumnsToolPanel: isGroupViewEnabled && columnObj['id'] === 'search_term',
        autoHeight: columnObj['id'] === 'tags',
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj, AgAnyTagsFilterApiStringTypes.IN_WITH_OR),
        floatingFilter: true,
        headerCheckboxSelection: false,
        checkboxSelection: enableCheckbox && columnObj['id'] === 'search_term' && !isReadOnlyUser,
        lockVisible: columnObj['id'] === 'title',
        lockPosition: isGroupViewEnabled && columnObj['id'] === 'search_term',
        sortable: !columnObj['sortDisabled'],
        cellStyle: params => {
          if (
            columnObj['id'] === 'platform' &&
            params.context.cellClickedIndex === params.rowIndex
          ) {
            return { border: '1px solid var(--ag-range-selection-border-color)' };
          }
          return null;
        },
      };
    });
  return res;
};
// TODO seperate ugc tables and use this colDefs generator
export const generateUGCColumnDefs = (
  columns: ITableApiColumn[],
  firstRenderDisplayColumnIdSet: Set<string>,
  moduleType?: string,
  tableId?: string,
  savedNumOfCategoryFltrConditions?: number,
) => {
  const isReadOnlyUser = getIsReadOnlyUser();
  const displayKey = accerssorTableIds.includes(tableId as string) ? 'accessor' : 'id';
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: columnObj['accessor'],
        hide:
          columnObj['id'] === 'url' || columnObj['id'] === 'srcUrl'
            ? undefined
            : !firstRenderDisplayColumnIdSet.has(columnObj[displayKey]),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'primary_domain'),
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          }
          return params.value;
        },
        suppressColumnsToolPanel: columnObj['id'] === 'primary_domain',
        autoHeight: columnObj['id'] === 'tags',
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(
          columnObj,
          savedNumOfCategoryFltrConditions,
          moduleType === 'app-store'
            ? AgAnyTagsFilterApiStringTypes.IN_WITH_COMMAS
            : AgAnyTagsFilterApiStringTypes.NOT_OR_NULL,
          moduleType,
        ),
        valueGetter: (params: ValueGetterParams) => {
          return getValueOfTheColumn(columnObj, params);
        },
        floatingFilter: true,
        headerCheckboxSelection:
          (columnObj['id'] === 'url' || columnObj['id'] === 'srcUrl') && !isReadOnlyUser,
        checkboxSelection:
          (columnObj['id'] === 'url' || columnObj['id'] === 'srcUrl') && !isReadOnlyUser,
        lockVisible: columnObj['id'] === 'url' || columnObj['id'] === 'srcUrl',
        lockPosition: columnObj['id'] === 'url' || columnObj['id'] === 'srcUrl',
        sortable: !columnObj['sortDisabled'],
      };
    });

  return res;
};

export const generateAgGridClientModeColumnDefs = ({
  columns,
  firstRenderDisplayColumnIdSet,
  isAcquisitionTable,
  tableId,
}: {
  columns: ITableApiColumn[];
  firstRenderDisplayColumnIdSet: Set<string>;
  isAcquisitionTable?: boolean;
  tableId?: string;
}) => {
  const isReadOnlyUser = getIsReadOnlyUser();

  const displayKey = accerssorTableIds.includes(tableId as string) ? 'accessor' : 'id';
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: isAcquisitionTable ? columnObj['accessor'] : columnObj['id'],
        hide: !firstRenderDisplayColumnIdSet.has(columnObj[displayKey]),
        headerName: columnObj['header'],
        cellRenderer: (params: ICellRendererParams) => {
          if (typeof columnObj['render'] === 'function') {
            return columnObj['render'](params.data || {});
          }
          return typeof params.value === 'object' || params.value === undefined ? (
            '--'
          ) : (
            <span>{params.value + ''}</span>
          );
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          } else if (columnObj['id'] === 'platforms') {
            return params.value.map((platform: any) => platform.label).join(', ');
          }
          return params.value;
        },
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj),
        floatingFilter: true,
        checkboxSelection:
          (columnObj['id'] === 'domain' || columnObj['id'] === 'asset_name') && !isReadOnlyUser,
        headerCheckboxSelection:
          (columnObj['id'] === 'domain' || columnObj['id'] === 'asset_name') && !isReadOnlyUser,
        lockVisible: columnObj['disableForSelection'],
        lockPosition: columnObj['disableForSelection'],
        sortable: !columnObj['sortDisabled'],
        comparator: columnObj['comparator'] ?? undefined,
      };
    });

  return res;
};

export const generateReportsColumnDefs = (
  columns: ITableApiColumn[],
  firstRenderDisplayColumnIdSet: Set<string>,
  tableId?: string,
) => {
  const displayKey = accerssorTableIds.includes(tableId as string) ? 'accessor' : 'id';
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: columnObj['accessor'],
        hide: !firstRenderDisplayColumnIdSet.has(columnObj[displayKey]),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'primary_domain'),
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format']?.(params.data);
          }
          return params.value;
        },
        suppressColumnsToolPanel: columnObj['id'] === 'primary_domain',
        autoHeight: columnObj['id'] === 'tags',
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj, AgAnyTagsFilterApiStringTypes.NOT_OR_NULL),
        floatingFilter: true,
        sortable: !columnObj['sortDisabled'],
      };
    });

  return res;
};

export const generateBrandPropertyColumnDefs = (
  columns: ITableApiColumn[],
  firstRenderDisplayColumnIdSet: Set<string>,
  tableId?: string,
) => {
  const displayKey = accerssorTableIds.includes(tableId as string) ? 'accessor' : 'id';
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: columnObj['accessor'],
        hide: !firstRenderDisplayColumnIdSet.has(columnObj[displayKey]),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'primary_domain'),
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format']?.(params.data);
          }
          return params.value;
        },
        valueGetter: (params: ValueGetterParams) => {
          return getValueOfTheColumn(columnObj, params);
        },
        lockVisible: columnObj['disableForSelection'],
        lockPosition: columnObj['disableForSelection'],
        autoHeight: columnObj['id'] === 'logos',
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj, AgAnyTagsFilterApiStringTypes.NOT_OR_NULL),
        floatingFilter: true,
        sortable: !columnObj['sortDisabled'],
      };
    });

  return res;
};

export const generateAgGridTeamMemberColDefs = ({
  columns,
  firstRenderDisplayColumnIdSet,
  tableId,
}: {
  columns: ITableDataColumn[];
  firstRenderDisplayColumnIdSet: Set<string>;
  tableId: string;
}) => {
  const displayKey = accerssorTableIds.includes(tableId as string) ? 'accessor' : 'id';

  const isReadOnlyFeatureOn = featureIsAvailable(
    store.getState()?.dashboardReducer?.user,
    appConstants.FEATURE_CODE.READ_ONLY_USER_PER_MODULE,
  );

  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: columnObj['accessor'],
        hide:
          !firstRenderDisplayColumnIdSet.has(columnObj[displayKey]) ||
          (columnObj['id'] === 'teamMemberModule' && !isReadOnlyFeatureOn),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'email'),
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          } else if (columnObj['hiddenInExport']) {
            return '';
          }
          return params.value;
        },
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj),
        floatingFilter: true,
        sortable: !columnObj['sortDisabled'],
        editable: columnObj['editable'],
        comparator: columnObj['comparator'] ?? undefined,
        lockPosition: columnObj['disableForSelection'],
        lockVisible: columnObj['disableForSelection'],
      };
    });
  return res;
};

export const generateTyposquattingDeltaColumnDefs = ({
  columns,
  lockColumnId,
}: {
  columns: ITableApiColumn[];
  lockColumnId: string;
}) => {
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj): ColDef => {
      return {
        field: columnObj['id'],
        headerName: columnObj['header'],
        hide: columnObj['id'] === 'primary_domain',
        cellRenderer: getColumnRenderer(columnObj, 'primary_domain', true),
        cellRendererParams: columnObj['id'] === 'primary_domain' && {
          innerRenderer: (params: any) =>
            columnObj['render'] ? columnObj['render'](params.data) : <></>,
        },
        valueGetter: columnObj['valueGetter'],
        comparator: columnObj['comparator'] ?? undefined,
        rowGroup: columnObj['id'] === 'primary_domain',
        suppressColumnsToolPanel: columnObj['id'] === 'primary_domain',
        lockPosition: columnObj['id'] === lockColumnId,
        lockVisible: columnObj['id'] === lockColumnId,
        sortable: !columnObj['sortDisabled'],
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj),
        floatingFilter: true,
      };
    });
  return res;
};

export const generateTyposquattingDeltaFlatColumnDefs = ({
  columns,
  lockColumnId,
}: {
  columns: ITableApiColumn[];
  lockColumnId: string;
}) => {
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj): ColDef => {
      return {
        field: columnObj['id'],
        headerName: columnObj['header'],
        cellRenderer: (params: ICellRendererParams) => {
          if (typeof columnObj['render'] === 'function') {
            return columnObj['render'](params.data || {});
          }
          return <span>{params.value + ''}</span>;
        },
        rowGroup: false,
        lockPosition: columnObj['id'] === lockColumnId,
        lockVisible: columnObj['id'] === lockColumnId,
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj),
        floatingFilter: true,
        valueGetter: columnObj['valueGetter'],
        sortable: !columnObj['sortDisabled'],
        comparator: columnObj['comparator'] ?? undefined,
      };
    });
  return res;
};

export const generateAgGridGlobalTakedownTableColumnDefs = ({
  columns,
  firstRenderDisplayColumnIdSet,
  tableId,
}: {
  columns: ITableApiColumn[];
  firstRenderDisplayColumnIdSet: Set<string>;
  tableId?: string;
}) => {
  const displayKey = accerssorTableIds.includes(tableId as string) ? 'accessor' : 'id';
  const res = columns
    .filter(columnObj => !columnObj['hidden'])
    .map((columnObj: any): ColDef => {
      return {
        field: columnObj['id'],
        hide: !firstRenderDisplayColumnIdSet.has(columnObj[displayKey]),
        headerName: columnObj['header'],
        cellRenderer: getColumnRenderer(columnObj, 'src_url', true),
        valueFormatter: (params: ValueFormatterParams) => {
          if (columnObj['format']) {
            return columnObj['format'](params.data);
          }
          return params.value;
        },
        filter: columnObj['filterDisabled'] ? false : columnObj['agFilterType'],
        filterParams: getAgFilterParams(columnObj),
        floatingFilter: true,
        lockVisible: columnObj['disableForSelection'],
        lockPosition: columnObj['disableForSelection'],
        sortable: !columnObj['sortDisabled'],
        comparator: columnObj['comparator'] ?? undefined,
        enableRowGroup: columnObj['id'] === 'src_url',
        cellRendererParams: {
          innerRenderer: (params: any) => {
            if (typeof columnObj['render'] === 'function') {
              return columnObj['render'](params.data || {}) || '--';
            }
            return typeof params.value === 'object' || params.value === undefined ? (
              '--'
            ) : (
              <span>{params.value || '--' + ''}</span>
            );
          },
        },
      };
    });
  return res;
};
