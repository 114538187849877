import { useCallback, useEffect, useState } from 'react';
import fetchApi from '../../../services/api.service';
import { handleResponse } from '../../../services/serviceWorker';
import { DashboardData } from '../constants';
import moment from 'moment';
import { useAppSelector } from '../../../helpers/hooks';
import { bankOfBolsterDashboardPocData } from '../demo-data-bankofbolster/dashboard.poc';

export function useAMDashboardData(
  include?: string[],
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  timeZone?: string,
  emailOnly?: boolean,
) {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);

  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);
  const isDemoBrand = brandInfo?.brand?.brandName === 'bankofbolster';

  const fetchData = useCallback(async () => {
    try {
      setDashboardData(null);

      const requestBody = {
        startDate: startDate
          ? moment(startDate).format('YYYY-MM-DD')
          : moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        include: include || [],
        exclude: [],
        timeZone,
      };

      const requestConfig: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      };
      const [emailResponse = {}, threatResponse = {}] = await Promise.all(
        (emailOnly ? ['email'] : ['email', 'threat']).map(type =>
          !isDemoBrand
            ? fetchApi(
                `/platform-api/v1/abuse-mailbox/dashboard/${type}/stats`,
                requestConfig,
              ).then(handleResponse)
            : Promise.resolve(bankOfBolsterDashboardPocData),
        ),
      );

      const response = isDemoBrand
        ? bankOfBolsterDashboardPocData
        : { ...threatResponse, ...emailResponse };

      setDashboardData(response);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }, [isDemoBrand, startDate, endDate, include, timeZone, emailOnly]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  return { dashboardData };
}

export function useAMDashboardDataForThreat(
  include: string[],
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  timeZone?: string,
) {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);

  const brandInfo = useAppSelector(state => state.dashboardReducer.brandInfo);
  const isDemoBrand = brandInfo?.brand?.brandName === 'bankofbolster';

  const fetchData = useCallback(async () => {
    try {
      setDashboardData(null);

      const requestBody = {
        startDate: startDate
          ? moment(startDate).format('YYYY-MM-DD')
          : moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        include,
        exclude: [],
        timeZone,
      };

      const requestConfig: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      };
      const threatResponse = isDemoBrand
        ? bankOfBolsterDashboardPocData
        : await fetchApi(
            `/platform-api/v1/abuse-mailbox/dashboard/threat/stats`,
            requestConfig,
          ).then(handleResponse);

      setDashboardData(threatResponse);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }, [isDemoBrand, startDate, endDate, include, timeZone]);

  useEffect(() => {
    if (include.length === 0) return;
    void fetchData();
  }, [fetchData, include.length]);

  return { dashboardData };
}
