import { useMemo } from 'react';
import {
  useAMDashboardData,
  useAMDashboardDataForThreat,
} from '../AbuseMailboxDashboard/useAMDashboardData';
import {
  EInternalUserFilter,
  emailCategoryOptions,
  emailDispositionOptions,
  internalUserFilterOptions,
} from './constants';

export function useEmailCounts({
  filterBy,
  startDate,
  endDate,
  isInternal,
  timeZone,
}: {
  filterBy?: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  isInternal: boolean;
  timeZone?: string;
}) {
  const emailsCountParams = useMemo(
    () => ['emailSubmissions', 'emailCategories', 'emailDisposition'],
    [],
  );

  const emailsCountParamsForThreat = useMemo(
    () => [...(isInternal ? ['needReview'] : [])],
    [isInternal],
  );

  const { dashboardData } = useAMDashboardData(
    emailsCountParams,
    startDate,
    endDate,
    timeZone,
    true,
  );

  const { dashboardData: threatDashboardData } = useAMDashboardDataForThreat(
    emailsCountParamsForThreat,
    startDate,
    endDate,
    timeZone,
  );

  const totalEmailsCount = dashboardData?.emailSubmissions?.total || 0;

  const filterCategoryCountMap = useMemo(() => {
    return [...emailCategoryOptions].reduce((acc, category) => {
      const matchingKey = Object.keys(dashboardData?.emailCategories || {}).find(key =>
        key.endsWith(category),
      );
      acc[category] = dashboardData?.emailCategories?.[matchingKey as string] || 0;
      return acc;
    }, {} as Record<string, number>);
  }, [dashboardData?.emailCategories]);

  const filterDispositionCountMap = useMemo(() => {
    return [
      ...emailDispositionOptions,
      ...(isInternal ? [...internalUserFilterOptions] : []),
    ].reduce((acc, disposition) => {
      acc[disposition] = dashboardData?.emailDisposition?.[disposition.toLowerCase()] || 0;
      return acc;
    }, {} as Record<string, number>);
  }, [dashboardData?.emailDisposition]);

  const filterInternalCountMap = useMemo(() => {
    const map: Record<string, number> = {};
    if (isInternal && threatDashboardData?.needReview) {
      map[EInternalUserFilter.NEEDS_REVIEW] = threatDashboardData?.needReview;
    } else {
      map[EInternalUserFilter.NEEDS_REVIEW] = 0;
    }
    return map;
  }, [threatDashboardData?.needReview, isInternal]);

  const filteredCount = filterBy
    ? filterCategoryCountMap[filterBy] ||
      filterDispositionCountMap[filterBy] ||
      filterInternalCountMap[filterBy]
    : undefined;

  return {
    filteredCount,
    totalEmailsCount,
  };
}
