import React, { useContext, useEffect, useMemo, useState } from 'react';
import './integration.scss';
import { NewConnectorModal } from '../Connectors/newConnectorModal';
import { updateConnector } from '../Connectors/connector-requests';
import { history } from '../../helpers';
import { useAppDispatch } from '../../helpers/hooks';
import { alertActions } from '../../actions';
import Back from '../../assets/icons/Back.svg';
import BackWhite from '../../assets/icons/BackWhite.svg';
import { Button } from '@mui/material';
import arrow from '../../assets/icons/ArrowRight-white.svg';

import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { IReasonDropdownValues } from '../../constants';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

export interface IIntegrationsProps {
  isCommunity: boolean;
}
export function Integrations({ isCommunity }: IIntegrationsProps) {
  const { setReason, setTriggerContactUsModal } = useContext(CheckPhishContactUsContext);

  const integrations = useMemo(() => {
    return [
      {
        name: 'Splunk',
        enabled: true,
        knowledgeBaseSurfix: 'splunk-integration',
      },
      {
        name: 'Sumo Logic',
        knowledgeBaseSurfix: 'sumo-logic',
        enabled: true,
      },
      {
        name: 'Tines',
        knowledgeBaseSurfix: 'tines-integration',
        enabled: true,
      },
      {
        name: 'Cortex XSOAR',
        enabled: true,
        knowledgeBaseSurfix: 'xsoar-integration',
      },
      // {
      //   name: 'Anomali',
      //   enabled: true,
      //   knowledgeBaseSurfix: 'anomali-integration',
      // },
      {
        name: 'ThreatConnect',
        enabled: true,
        knowledgeBaseSurfix: 'threatconnect-integration',
      },
      {
        name: 'Microsoft Sentinel',
        enabled: true,
        knowledgeBaseSurfix: 'microsoft-sentinal-integration',
      },
      {
        name: 'Slack',
        enabled: true,
        knowledgeBaseSurfix: 'slack-integration',
      },
      {
        name: 'Microsoft Teams',
        knowledgeBaseSurfix: 'microsoft-teams-integration',
        enabled: true,
      },
      {
        name: 'Microsoft 365 Exchange/Defender',
        knowledgeBaseSurfix: 'microsoft-365-exchange-defender-integration',
        enabled: true,
      },
      {
        name: 'API Integration',
        knowledgeBaseSurfix: 'api-integration',
        enabled: true,
      },
    ];
  }, []);

  const endabledIntegrations = useMemo(() => {
    return integrations.filter(integration => integration.enabled);
  }, [integrations]);

  const [selectedIntegration, setSelectedIntegration] = useState('');
  const dispatch = useAppDispatch();
  const [isDarkMode] = useIsDarkMode();

  const [knowledgeBaseHtml, setKnowledgeBaseHtml] = useState('');

  useEffect(() => {
    const knowledgeBaseSurfix = endabledIntegrations.find(
      integration => integration.name === selectedIntegration,
    )?.knowledgeBaseSurfix;
    if (!knowledgeBaseSurfix) {
      return;
    }
    void (async () => {
      try {
        const response = await fetch(
          `https://bolster.ai/wp-json/wp/v2/kbarticles?slug=${knowledgeBaseSurfix}`,
        );
        const html = (await response.json())?.[0]?.content?.rendered;
        setKnowledgeBaseHtml(html);
      } catch (error) {
        console.error('Failed to fetch knowledge base html', error);
      }
    })();
  }, [endabledIntegrations, selectedIntegration]);

  useEffect(() => {
    document.title = 'Integrations';
  }, []);

  const openContactUs = () => {
    setReason(IReasonDropdownValues?.UPGRADE);
    setTriggerContactUsModal(true);
  };

  return (
    <div className={`integrations-tab ${isCommunity ? 'checkphish-integrations-tab' : ''}`}>
      {!selectedIntegration && (
        <div>
          <div className='integrations-header'>Available Connectors </div>
          <div
            className={`${
              isCommunity
                ? 'integration-container checkphish-integration-container'
                : 'integration-container'
            }`}
          >
            {endabledIntegrations.map((integration, index) => {
              const enabled = integration.enabled;

              return (
                <div
                  key={index}
                  className={`integration-item ${
                    isCommunity ? 'integration-disabled-checkphish ' : ''
                  } ${enabled ? 'integration-enabled' : 'integration-disabled'}`}
                  onClick={() => {
                    if (isCommunity) return;
                    if (enabled) {
                      setSelectedIntegration(integration.name);
                    }
                  }}
                >
                  <div className='integration-image-container'>
                    <img
                      src={`/integrations/${integration.name}${isDarkMode ? '-dark' : ''}.svg`}
                      alt={integration.name}
                    />
                  </div>
                  <div
                    className={isCommunity ? 'highlight-text' : 'integration-name-hidden-on-hover'}
                  >
                    {integration.name}
                  </div>
                  {isCommunity ? (
                    <>
                      <span className='enable-text'>
                        Your current plan does not have access to {integration.name}.{' '}
                        <span className='link-text' onClick={openContactUs}>
                          Upgrade your plan
                        </span>{' '}
                        to get access.
                      </span>
                    </>
                  ) : (
                    <>
                      <div className='integration-button-visible-on-hover'>
                        <Button variant='contained' endIcon={<img src={arrow} />}>
                          Enable
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {selectedIntegration && (
        <div>
          <div className='d-flex align-items-center'>
            <img
              src={isDarkMode ? BackWhite : Back}
              alt='Back'
              className='back-logo'
              onClick={() => {
                setSelectedIntegration('');
              }}
            />
            <div className='selected-integration-title'>{selectedIntegration}</div>
          </div>
          <div className='new-connector-with-knowledge-base'>
            <NewConnectorModal
              show={!!selectedIntegration}
              onSubmit={connectorInfo => {
                void (async () => {
                  try {
                    await updateConnector(connectorInfo);
                    dispatch(
                      alertActions.success(`Successfully added connector '${connectorInfo.name}`),
                    );
                    history.push('/integrations/implemented-connectors');
                  } catch (error) {
                    dispatch(
                      alertActions.error('Updating connector failure, ' + JSON.stringify(error)),
                    );
                  }
                })();
              }}
              toggleModal={() => {
                setSelectedIntegration('');
              }}
              initValues={{
                isNewAdded: true,
                selectedIntegration,
                name: '',
                connectorTypeId: selectedIntegration === 'Slack' ? 3 : 2,
                details: {
                  method: 'POST',
                },
              }}
              fromIntegration
            />

            <div
              id='knowledge-base-content'
              dangerouslySetInnerHTML={{ __html: knowledgeBaseHtml }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
