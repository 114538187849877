import React from 'react';
import _ from 'lodash';
import { IDropdownOption, DropdownOptionDefaultValue, Dropdown } from '../Dropdown';
import { capitalize, numberRangeColumnValidator } from '../../../constants';
import { IFilter, TFilterAction } from './constant';
import { generateDefaultFilter } from './filter.controller';
import { FilterDate } from './filter.date';
import './table.scss';
import BinIcon from '../../../assets/icons/Bin.svg';
import { DropdownMultiSelect } from '../DropdownMultiSelect';
import PillLabel from '../PillLabel';
import { Checkbox } from '../Checkbox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ITableApiColumn } from './table.api';
import {
  setRecentChangesMultiOptions,
  setRecentChangesDateRange,
} from '../../../reducers/playbook.reducer';
import { connect } from 'react-redux';
import { convertDateRange, convertDateRangeForPlaybookFilter } from '../../Playbook/playbook-util';
import { AppState } from '../../../helpers';

export const DEFAULT_FILTER_METHOD_STRING = {
  label: 'Includes',
  value: 'includes',
};
export const MUST_NOT_FILTER_METHOD_STRING = {
  label: 'Does Not Include',
  value: 'notIncludes',
};

export const DEFAULT_FILTER_METHOD_IPV4 = {
  label: 'Begins with',
  value: 'beginWith',
};
export const MUST_NOT_FILTER_METHOD_IPV4 = {
  label: 'Does not begin with',
  value: 'notBeginWith',
};

export const DEFAULT_FILTER_METHOD_NUMBER = {
  label: 'In range',
  value: 'numberRange',
};

export const DEFAULT_FILTER_METHOD_DATE = {
  label: 'the last',
  value: 'last',
};

export const DEFAULT_FILTER_METHOD_OPTIONS = {
  label: 'Is',
  value: 'is',
};
export const MUST_NOT_FILTER_METHOD_OPTIONS = {
  label: 'Is not',
  value: 'isNot',
};

const FILTER_METHOD_OPTIONS_STRING = [DEFAULT_FILTER_METHOD_STRING, MUST_NOT_FILTER_METHOD_STRING];

const FILTER_METHOD_OPTIONS_IPV4 = [DEFAULT_FILTER_METHOD_IPV4, MUST_NOT_FILTER_METHOD_IPV4];

const FILTER_METHOD_OPTIONS_NUMBER = [DEFAULT_FILTER_METHOD_NUMBER];

export const DEFAULT_OLDER_THEN_DATE_METHOD = {
  label: 'older than',
  value: 'older',
};
const FILTER_METHOD_OPTIONS_DATE = [DEFAULT_FILTER_METHOD_DATE, DEFAULT_OLDER_THEN_DATE_METHOD];

const FILTER_METHOD_OPTIONS_OPTIONS = [
  DEFAULT_FILTER_METHOD_OPTIONS,
  MUST_NOT_FILTER_METHOD_OPTIONS,
];

interface IComponentProps {
  id: string;
  filterByOptions: IDropdownOption[];
  filter: IFilter;
  columns: any[];
  checkBoxOptionEnabled: boolean;
  isSelectedCheckbox: boolean;
  type?: string;
  addPlaybookFilterIds?: (filterValue: string) => void;
  removePlaybookFilterId?: (filterValue: string) => void;
  onChange: (id: string, filter: IFilter, action: TFilterAction) => void;
  onRemove: (id: string, filter: IFilter, action: TFilterAction) => void;
  tagsOptions?: any[];
  filterDisabled?: boolean;
  setRecentChangesMultiOptions?: (selection: any) => void;
  setRecentChangesDateRange?: (dateValue: any) => void;
  recentChangesDateRange?: { dateRange: string };
}

interface IComponentState {}

class TableFilterControllerEditItem extends React.Component<IComponentProps, IComponentState> {
  private filterBy: IDropdownOption = DropdownOptionDefaultValue;
  private filterType: string | undefined = '';
  private filterMethod: IDropdownOption = { label: 'Includes', value: 'includes' };
  private filterValue = '';
  private filterValueInput: React.RefObject<HTMLInputElement> = React.createRef();
  private filterErr = '';
  private checkBoxOptionEnabled = false;
  private isSelectedCheckbox = false;

  constructor(props: IComponentProps) {
    super(props);
    this.state = {};
    const { filter, checkBoxOptionEnabled, isSelectedCheckbox } = props;
    this.filterType = filter.filterType;
    this.filterBy = filter.filterBy;
    this.filterMethod = filter.filterMethod;
    this.filterValue = filter.filterValue;
    this.checkBoxOptionEnabled = checkBoxOptionEnabled;
    this.isSelectedCheckbox = isSelectedCheckbox;
  }
  shouldComponentUpdate(nextProps: Readonly<any>): boolean {
    return this.props.filter.filterValue !== nextProps.filter.filterValue;
  }

  onFilterByChange = (selection: IDropdownOption): void => {
    const { id } = this.props;

    if (selection.value !== this.filterBy.value) {
      this.filterType = 'string';

      if (!_.isEmpty(this.props.columns)) {
        const column = _.find(this.props.columns, (i: any): any => {
          return i.header && selection.label && i.header === selection.label;
        });
        if (column && column.type) {
          this.filterType = column.type;
        }
      }

      switch (this.filterType) {
        case 'optionsString':
        case 'string':
          this.filterMethod = DEFAULT_FILTER_METHOD_STRING;
          break;
        case 'ipv4':
          this.filterMethod = DEFAULT_FILTER_METHOD_IPV4;
          break;
        case 'number':
          this.filterMethod = DEFAULT_FILTER_METHOD_NUMBER;
          break;
        case 'date':
          this.filterMethod = DEFAULT_FILTER_METHOD_DATE;
          break;
        case 'recentChangeDateOptions':
          this.filterMethod = DEFAULT_FILTER_METHOD_DATE;
          break;
        case 'options':
        case 'optionsMultiSelect':
          this.filterMethod = DEFAULT_FILTER_METHOD_OPTIONS;
          break;
        default:
          this.filterMethod = DEFAULT_FILTER_METHOD_OPTIONS;
          break;
      }

      this.filterValue = '';
      this.filterErr = '';
    }

    this.filterBy = selection;

    this.props.onChange(
      id,
      {
        id,
        filterType: this.filterType,
        filterBy: selection,
        filterMethod: this.filterMethod,
        filterValue: this.filterValue,
        filterErr: this.filterErr,
      },
      'edit',
    );
  };

  onFilterMethodChange = (selection: IDropdownOption): void => {
    const { id, filter } = this.props;
    this.filterMethod = selection;
    this.props.onChange(
      id,
      {
        id,
        filterType: this.filterType,
        filterBy: this.filterBy,
        filterMethod: selection,
        filterValue: this.filterValue,
        filterErr: this.filterErr,
        filterLabel: filter.filterLabel,
      },
      'edit',
    );
  };
  onMultiDropFilterValueChange = (selection: any, controlId?: string): void => {
    if (selection.length > 0) {
      const filterVal: any[] = [];
      const filterLab: any[] = [];

      selection.forEach((item: any) => {
        filterVal.push(item.value);
        filterLab.push(item.label);
      });
      const filterValue = filterVal.join('&');
      const filterLabel = filterLab.join(', ');

      const { id } = this.props;
      this.filterValue = filterValue;
      this.props.onChange(
        id,
        {
          id,
          filterType: this.filterType,
          filterBy: this.filterBy,
          filterMethod: this.filterMethod,
          filterValue,
          filterLabel,
          filterErr: this.filterErr,
        },
        'edit',
      );
    }
  };
  onFilterValueChange = (filterValue: string, filterLabel?: string, validator?: any) => {
    const { id } = this.props;
    this.filterValue = filterValue;
    if (validator) {
      this.filterErr = validator(filterValue);
      this.setState({
        err: this.filterErr,
      });
    }
    this.props.onChange(
      id,
      {
        id,
        filterType: this.filterType,
        filterBy: this.filterBy,
        filterMethod: this.filterMethod,
        filterValue,
        filterLabel,
        filterErr: this.filterErr,
      },
      'edit',
    );
  };

  onFilterRemove = () => {
    const { id } = this.props;
    this.props.onRemove(id, generateDefaultFilter(), 'remove');
  };

  onRecentChangesFilterValueChange = (selection: any[]) => {
    const filterVal: string[] = [];

    selection.forEach((item: any) => {
      filterVal.push(item.value);
    });
    this.props.setRecentChangesMultiOptions?.({ fields: filterVal });
    const filterValue = filterVal.join('&');

    const { id } = this.props;
    this.filterValue = filterValue;
    this.props.onChange(
      id,
      {
        id,
        filterType: this.filterType,
        filterBy: this.filterBy,
        filterMethod: this.filterMethod,
        filterValue,
        filterLabel: '',
        filterErr: this.filterErr,
        isExcluded: true,
      },
      'edit',
    );
  };
  renderFilterMethodOptions = () => {
    let defaultSelection;
    if (this.filterBy !== DropdownOptionDefaultValue) {
      defaultSelection = this.filterMethod;
    }
    switch (this.filterType) {
      case 'optionsString':
      case 'string':
        return (
          <Dropdown
            disabled={this.props.filterDisabled || _.isEmpty(this.filterBy.value)}
            options={FILTER_METHOD_OPTIONS_STRING}
            defaultSelection={defaultSelection || DEFAULT_FILTER_METHOD_STRING}
            onChange={this.onFilterMethodChange}
            className={'mx--8'}
          />
        );
      case 'ipv4':
        return (
          <Dropdown
            options={FILTER_METHOD_OPTIONS_IPV4}
            defaultSelection={defaultSelection || DEFAULT_FILTER_METHOD_IPV4}
            onChange={this.onFilterMethodChange}
            className={'mx--8'}
          />
        );
      case 'number':
        return (
          <Dropdown
            disabled
            options={FILTER_METHOD_OPTIONS_NUMBER}
            defaultSelection={defaultSelection || DEFAULT_FILTER_METHOD_NUMBER}
            onChange={this.onFilterMethodChange}
            className={'mx--8'}
          />
        );
      case 'date':
        return (
          <Dropdown
            options={FILTER_METHOD_OPTIONS_DATE}
            defaultSelection={defaultSelection || DEFAULT_FILTER_METHOD_DATE}
            onChange={this.onFilterMethodChange}
            className={'mx--8'}
          />
        );
      case 'recentChangeDateOptions':
        return (
          <Dropdown
            options={[DEFAULT_FILTER_METHOD_DATE]}
            defaultSelection={DEFAULT_FILTER_METHOD_DATE}
            onChange={this.onFilterMethodChange}
            className={'mx--8'}
          />
        );
      case 'options':
      case 'optionsMultiSelect':
        return (
          <Dropdown
            disabled={this.props.filterDisabled || _.isEmpty(this.filterBy.value)}
            options={FILTER_METHOD_OPTIONS_OPTIONS}
            defaultSelection={defaultSelection || DEFAULT_FILTER_METHOD_OPTIONS}
            onChange={this.onFilterMethodChange}
            className={'mx--8'}
          />
        );
      default:
        return <Dropdown disabled options={[]} emptyText={'please select'} className={'mx--8'} />;
    }
  };

  renderFilterValueForm = () => {
    const err = this.filterErr;
    let defaultSelection;
    const column = _.find(
      this.props.columns,
      (i: ITableApiColumn): string | boolean | undefined => {
        return i.header && this.filterBy.label && i.header === this.filterBy.label;
      },
    );
    switch (this.filterType) {
      case 'optionsString':
      case 'options':
        if (this.filterValue) {
          defaultSelection = _.find(column.filterOptions, (i: any): any => {
            return i.value === this.filterValue;
          });
        }
        return (
          <Dropdown
            options={column.filterOptions}
            ordersPersist={column.optionsOrdersPersist}
            defaultSelection={defaultSelection}
            onChange={(selection: IDropdownOption) => {
              this.onFilterValueChange(selection.value, selection.label);
            }}
            disabled={this.props.filterDisabled}
            className={'mx--8'}
          />
        );
      case 'optionsMultiSelect':
        if (this.filterValue.split('&').length > 0) {
          defaultSelection = _.filter(
            column?.filterOptions && column.filterOptions.length > 0
              ? column.filterOptions
              : this.props.tagsOptions,
            (i: any): any => {
              return this.filterValue.split('&').includes(`${i.value}`);
            },
          );
        }
        if (
          this.filterValue?.split('|')?.length &&
          this.filterValue?.split('|')?.length > 0 &&
          this.filterValue?.split('|')?.length === column?.filterOptions?.length
        ) {
          defaultSelection = [{ label: 'Any tags', value: this.filterValue }];
        }
        return (
          <DropdownMultiSelect
            options={
              column?.filterOptions && column.filterOptions.length > 0
                ? column.filterOptions
                : this.props.tagsOptions
            }
            onSubmit={this.onMultiDropFilterValueChange}
            initialSelections={defaultSelection}
            updateOnChange
            placeholder='Select tags'
            hideFooter
            renderDom={PillLabel}
            renderDomKeys={[{ label: 'label' }, { backgroundColor: 'color' }]}
            maxValueLimit={2}
            oneOption={{
              label: 'Any tags',
              value: column?.filterOptions?.map((tag: any) => tag?.value).join('|') || '',
            }}
          />
        );
      case 'date':
        return (
          <FilterDate
            originFilterValue={this.filterValue}
            onChange={value => {
              this.onFilterValueChange(value);
            }}
            type={this.props.type}
          />
        );
      case 'recentChangeDateOptions':
        return (
          <>
            <FilterDate
              originFilterValue={convertDateRangeForPlaybookFilter(
                this.props.recentChangesDateRange?.dateRange,
              )}
              onChange={value => {
                this.props.setRecentChangesDateRange?.({
                  dateRange: convertDateRange(value),
                });
              }}
              type={this.props.type}
            />
          </>
        );
      case 'number':
        return (
          <input
            className={_.isEmpty(err) ? '' : 'filter-value-error'}
            disabled={this.props.filterDisabled || this.filterBy === DropdownOptionDefaultValue}
            ref={this.filterValueInput}
            placeholder={'2, 98'}
            defaultValue={this.filterValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              this.onFilterValueChange(
                e.currentTarget.value,
                undefined,
                column.validator || numberRangeColumnValidator,
              );
            }}
          />
        );
      default:
        return (
          <input
            className={_.isEmpty(err) ? '' : 'filter-value-error'}
            disabled={this.props.filterDisabled || this.filterBy === DropdownOptionDefaultValue}
            ref={this.filterValueInput}
            defaultValue={this.filterValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              this.onFilterValueChange(e.currentTarget.value, undefined, column.validator);
            }}
          />
        );
    }
  };

  //Show the multi select dropdown for recent changes filter only
  renderFilterOptionsForRecentChanges = () => {
    const { columns, filter } = this.props;

    //map the recent changes filter value from reduc
    if (filter.filterBy.value !== 'recent_changes') return null;
    const recentChangesOptions: any = _.find(columns, (c: any) => c.id === 'recent_changes');
    const defaultSelection: any = _.filter(recentChangesOptions?.filterOptions, (i: any): any => {
      return this.filterValue.includes(i.value);
    });
    return (
      <>
        <span className={'text d-flex align-items-center'}>for</span>
        <DropdownMultiSelect
          options={recentChangesOptions?.filterOptions}
          initialSelections={defaultSelection}
          onSubmit={this.onRecentChangesFilterValueChange}
          hideFooter
          customClassName={'recent-changes-dropdown'}
          updateOnChange
          controlId='recent_changes'
        />
      </>
    );
  };
  toggleCheckbox = (filterValue: string) => {
    if (this.props.addPlaybookFilterIds) {
      this.props.addPlaybookFilterIds(filterValue);
    }
  };
  render() {
    const { filterByOptions, isSelectedCheckbox } = this.props;
    const textClassName = 'text d-flex align-items-center';
    return (
      <div className='filter-content  d-flex align-content-center'>
        <span className={textClassName}>Show results from </span>
        <Dropdown
          options={filterByOptions}
          onChange={this.onFilterByChange}
          defaultSelection={this.filterBy}
          disabled={this.props.filterDisabled}
          className={'mx--8'}
        />
        {this.renderFilterOptionsForRecentChanges()}
        <span className={textClassName}>That</span>
        {this.renderFilterMethodOptions()}
        <span className={textClassName}>Value</span>
        {this.renderFilterValueForm()}
        {this.checkBoxOptionEnabled && (
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip id={'tooltip-platform-info'}>
                <div className={'text-left'}>
                  {this.filterBy.value === 'ccns' ? (
                    <div>Only Export CCNs with this BIN</div>
                  ) : (
                    <div>
                      Only Export This {capitalize(this.filterBy.value.slice(0, -1))} Domain
                    </div>
                  )}
                </div>
              </Tooltip>
            }
          >
            <div>
              <Checkbox
                defaultChecked={isSelectedCheckbox}
                onChange={() => this.toggleCheckbox(this.filterBy.value)}
              />
            </div>
          </OverlayTrigger>
        )}
        <img
          src={BinIcon}
          alt={'BinIcon'}
          className={`cursor-pointer${this.props.filterDisabled ? '-disabled' : ''}`}
          onClick={() => {
            if (this.props.filterDisabled) return;

            if (this.props.removePlaybookFilterId) {
              this.props.removePlaybookFilterId(this.filterBy.value);
            }
            this.onFilterRemove();
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  const { recentChangesDateRange } = state.playbookReducer;
  return {
    recentChangesDateRange,
  };
};
const mapDispatchToProps = {
  setRecentChangesMultiOptions,
  setRecentChangesDateRange,
};

const connectedTableFilterControllerEditItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableFilterControllerEditItem);
export { connectedTableFilterControllerEditItem as TableFilterControllerEditItem };
