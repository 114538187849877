import React, { useMemo, useState } from 'react';
import './pricing.scss';
import _ from 'lodash';

export interface HeaderProps {
  showCheckoutForm: boolean;
}

const Header = ({ showCheckoutForm }: HeaderProps) => {
  window.document.title = showCheckoutForm
    ? 'Checkout | Checkphish Platform'
    : 'Pricing | Checkphish Platform';
  return (
    <div className='header-wrap'>
      <h2 className='main-heading'>
        {showCheckoutForm ? `Upgrade with Credit Card` : 'Choose pricing plan that fits you'}
      </h2>
      {!showCheckoutForm && (
        <p className='sub-heading'>
          Detect and take down phishing sites. Remove scam content. Protect against copyright
          infringements. All with our industry-leading LLM security platform.
        </p>
      )}
    </div>
  );
};

export default Header;
