import React, { Component } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import './auth.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLocalStorageValue, IAppDispatchProps, setLocalStorageValue } from '../../constants';
import { userActions, appActions } from '../../actions';
import { AppState } from '../../helpers';
import { PasswordEye, PASSWORD_LIMIT } from '../Common/PasswordEye';
import { AuthenticationWrapper } from '..';
import { CompanyIcon } from '../Common/CompanyIcon';
import { KeyIcon } from '../../assets/SVGIcons';
import { SsoSignInButton } from '../Common/SsoSignInButton';
import UserService from '../../services/user.service';
import GoogleLogo from '../../assets/logo/google_logo.svg';
import MicrosoftLogo from '../../assets/logo/office-logo.svg';
import SlackLogo from '../../assets/logo/slack.png';
import DRR_Banner from '../../assets/DRR_Banner.png';
import { ILoginError } from '../../App';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { redirectToCheckphish } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import TyposquatContext from '../../context/TyposquatContext';
import { history } from '../../helpers';
import { Button, CircularProgress } from '@mui/material';

interface IUserLoginProps {
  email: string;
  password: string;
}

interface IPropsFromState {
  loggingIn: boolean;
  login: any;
  theme: string;
  loginError: ILoginError;
  history: any;
}

interface IUserLoginState {
  user: IUserLoginProps;
  submitted: boolean;
  idx: number;
  processingStep: number;
}

type Props = IPropsFromState & IAppDispatchProps;

class LoginPage extends Component<Props, IUserLoginState> {
  static contextType = CheckPhishContactUsContext;
  private passwordDom: any = '';
  private formSubmitRef: React.RefObject<any>;
  private errMsg: any = [
    '',
    'Password should not be empty.',
    'Invalid email or password. Please contact support@bolster.ai.',
    'Password should be between 8 letters to 64 letters',
  ];
  private isCommunity: boolean;
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
      },
      submitted: false,
      idx: 0,
      processingStep: 0,
    };
    window.document.title = 'Login | Bolster Platform';
    this.formSubmitRef = React.createRef();
    const isCommunity = props.runByClientApp({
      onBolster: () => false,
      onCheckPhish: () => true,
    });
    this.isCommunity = isCommunity as boolean;
  }

  componentDidMount(): void {
    setLocalStorageValue('currentActiveUrl', '');
    setLocalStorageValue('lastVisitedPageCheckPhish', '');
  }

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.currentTarget.value;
    const { user } = this.state;
    let tempIdx = 0;
    if (!_.isEmpty(password) && password.length > PASSWORD_LIMIT) {
      tempIdx = 3;
    }
    this.setState({
      user: {
        ...user,
        password,
      },
      idx: tempIdx,
      submitted: false,
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  goBack = (domain: any) => {
    if (domain.length) {
      return history.push(`domain-monitor?domain=${domain}`);
    }
    redirectToCheckphish();
  };

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.formSubmitRef.current.blur();

    let routeToNavigate = '';
    const publicInsightsRoute = getLocalStorageValue('bolsterPublicRoute', '');
    if (publicInsightsRoute) {
      routeToNavigate = publicInsightsRoute;
    } else {
      routeToNavigate = window.location.pathname;
    }
    if (this.isCommunity && this.state.processingStep !== 2) {
      this.setState({ processingStep: 1 });
      this.setState({
        submitted: true,
      });
      const userService = new UserService();
      try {
        const data = await userService.validateUser({
          email: this.state.user.email,
        });
        if (data && data.isValid) {
          window.location.href = `/platform-api/v1/sso/oauth/login/${data.ssoProviderName.toUpperCase()}${
            this.context.identifier ? `?fingerprint=${this.context.identifier}` : ''
          }`;
        } else {
          this.setState({ processingStep: 2 });
          this.handleAfterValidate(false);
        }
      } catch (error) {
        console.log('error', error);
        this.setState({ processingStep: 2 });
        this.handleAfterValidate(false);
      }
    } else {
      window.history.pushState(null, 'Login | Bolster Platform', routeToNavigate);
      this.handleAfterValidate();
    }
  };

  handleAfterValidate = (validatePassword: boolean = true) => {
    this.setState({ submitted: true });
    const {
      user: { email, password },
      processingStep,
    } = this.state;
    const { identifier } = this.context;
    const submittedUser = {
      email,
      password,
    };
    if (password === '' && processingStep == 2) {
      this.setState({
        submitted: true,
        idx: validatePassword ? 1 : 0,
      });
    } else {
      if (this.isCommunity) {
        this.props
          .login({
            ...submittedUser,
            fingerprint: identifier,
          })
          .then((res: any) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'loginSubmitClicked',
              category: 'Page',
              action: 'clicked',
              label: 'loginSubmitClicked',
            });

            if (!res['result'] && processingStep == 2) {
              this.setState({
                submitted: true,
                idx: 2,
              });
            }
          });
      } else {
        this.props.login(submittedUser).then((res: any) => {
          if (!res['result']) {
            this.setState({
              submitted: true,
              idx: 2,
            });
          }
        });
      }
    }
  };

  renderPlatformLogin() {
    const { loggingIn } = this.props;
    const { user, submitted, idx } = this.state;
    const submitButtonDisabled =
      _.isEmpty(user.email) ||
      _.isEmpty(user.password) ||
      user.password.length > PASSWORD_LIMIT ||
      idx !== 0 ||
      submitted;
    const urlObj = new URL(window.location.href);
    let globalError = urlObj.searchParams.get('error');
    if (globalError && idx === 0) {
      globalError += ' Please sign in again.';
    }
    return (
      <AuthenticationWrapper>
        <Helmet>
          <link rel='canonical' href='https://platform.bolster.ai/sign-in' />
        </Helmet>
        <div className={'auth-title '}>
          <CompanyIcon withName />
        </div>
        <div className={'auth-page-wrapper'}>
          <form name='form' onSubmit={this.handleSubmit}>
            <h3>Login</h3>
            {globalError && (
              <div className={'login-error'}>
                <div className={'help-block'}>{globalError}</div>
              </div>
            )}
            <div className={'login-error' + (submitted && idx === 2 ? '' : ' hidden')}>
              <div className={'help-block'}>{this.errMsg[idx]}</div>
            </div>
            <div className={'form-group email-component'}>
              <label className={'email-label'}>Email</label>
              <input
                type='email'
                className={'form-control' + (idx > 0 ? ' error' : '')}
                placeholder='Enter email'
                name='email'
                value={user.email}
                onChange={this.handleChange}
              />
            </div>
            <PasswordEye
              isError={idx > 0}
              label={'Password'}
              name={'password'}
              onChange={this.onChangePassword}
            />
            <div className={'help-block' + (submitted && idx === 1 ? '' : ' hidden')}>
              {this.errMsg[idx]}
            </div>
            <div className={'help-block' + (idx === 3 ? '' : ' hidden')}>{this.errMsg[idx]}</div>
            <div className={'bottom-component'}>
              <div className='forget-password-text'>
                <Button variant='text' onClick={() => history.push('/forgot-password')}>
                  Forgot password?
                </Button>
              </div>
              <div className='form-group'>
                <Button
                  disabled={submitButtonDisabled}
                  type='submit'
                  ref={this.formSubmitRef}
                  variant='contained'
                >
                  {loggingIn ? <CircularProgress size={16} /> : 'Submit'}
                </Button>
              </div>
            </div>
          </form>
          <div className={'form-separator-container w-100 d-flex align-items-center'}>
            <div className={'form-separator mr-2'} />
            Or
            <div className={'form-separator ml-2'} />
          </div>
          <Button variant='outlined' onClick={() => history.push('sign-in-sso')}>
            Login with SSO
          </Button>
        </div>
      </AuthenticationWrapper>
    );
  }

  renderCheckphishLogin() {
    const { loggingIn, theme, history } = this.props;
    const { search } = history.location;
    const { user, submitted, idx, processingStep } = this.state;
    const title = 'Sign In | CheckPhish';
    const url = 'https://checkphish.ai/sign/in';
    const description =
      'CheckPhish is an Artificial Intelligence powered zero-day phishing detection.';
    const type = 'website';
    const imagePath = 'https://checkphish.ai/static/media/og-image.9d427d73.png';
    const keywords =
      'url,website,phishing,scanner,analyze,cyber threat intelligence, open source threat intelligence, online fraud';
    const displayError = search.includes('errMsg');
    return (
      <AuthenticationWrapper>
        <Helmet>
          <title>{title}</title>
          <meta name='robots' content='index, follow' />
          <meta name='description' content={description} />
          <meta name='keywords' content={keywords} />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />

          <meta property='og:type' content={type} />
          <meta property='og:url' content={url} />
          <meta name='title' property='og:title' content={title} />
          <meta name='description' property='og:description' content={description} />
          <meta name='image' property='og:image' content={imagePath} />

          <meta name='twitter:description' content={description} />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:image' content={imagePath} />
          <meta name='twitter:site' content='@checkphish' />
          <meta name='twitter:creator' content='@checkphish' />
        </Helmet>
        <div className={'login-page'}>
          {/* TO DO: ENABLE this is DEV */}
          <div className={'back-btn'}>
            <TyposquatContext.Consumer>
              {(state: any) => {
                const { domain } = state;
                return (
                  <button className='btn transparent-btn' onClick={() => this.goBack(domain)}>
                    <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
                    Back
                  </button>
                );
              }}
            </TyposquatContext.Consumer>
          </div>
          <CompanyIcon className='checkphish-logo-login' />
          {this.props.loginError.status && displayError && (
            <p className='error'>{this.props.loginError.message}</p>
          )}
          <div className={'login-page-content flex-center align-items-stretch'}>
            <a
              rel='noopener noreferrer'
              href='https://bolster.ai/domain-risk-report?utm_source=checkphish&utm_medium=login&utm_campaign=&utm_content=banner'
              target='_blank'
            >
              <img
                className={'login-glitch d-none d-md-block'}
                src={DRR_Banner}
                alt={'DRR Banner'}
              />
            </a>
            <div className={'auth-page-wrapper my-0 mx-3 mx-md-0 w-100 flex-center'}>
              <form className={'w-100'} name='form' onSubmit={this.handleSubmit}>
                <h3>Login</h3>
                <div className={'form-group email-component'}>
                  {/* <label className={'email-label'}>Email</label> */}
                  <input
                    type='email'
                    className={'form-control m-0' + (idx > 0 ? ' error' : '')}
                    placeholder='Enter email'
                    name='email'
                    value={user.email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={processingStep !== 2 ? 'hide-login' : 'show-login'}>
                  <PasswordEye
                    isError={idx > 0}
                    label={''}
                    name={'password'}
                    onChange={this.onChangePassword}
                  />
                  <div className={'help-block' + (idx === 3 ? '' : ' hidden')}>
                    {this.errMsg[idx]}
                  </div>
                </div>

                <div className={'bottom-component justify-content-center mt-1'}>
                  <div className='form-group row align-items-center w-100 m-0'>
                    <button
                      ref={this.formSubmitRef}
                      disabled={loggingIn}
                      type='submit'
                      className={
                        'btn submit-button w-100 text-white ' + (loggingIn ? 'disabled' : '')
                      }
                    >
                      {loggingIn || processingStep === 1 ? (
                        <img
                          src='data:image/gif;base64,R0lGODlhEAAQAPIAAER58////2+Y9c3b+////7bK+Z65+JOx9yH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQACgABACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkEAAoAAgAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkEAAoAAwAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkEAAoABAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQACgAFACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQACgAGACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAAKAAcALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                          alt='please wait'
                        />
                      ) : processingStep === 0 ? (
                        'Continue'
                      ) : (
                        'Log In'
                      )}
                    </button>
                  </div>
                </div>

                {processingStep === 2 && (
                  <div className='forget-password-text'>
                    <a className='link-ocean-theme' href={'/forgot-password'}>
                      Forgot password?
                    </a>
                  </div>
                )}

                <div className={'small font-weight-bold text-secondary text-center my-3'}>OR</div>

                <SsoSignInButton
                  icon={MicrosoftLogo}
                  href={`/platform-api/v1/sso/oauth/login/MICROSOFT${
                    this.context.identifier ? `?fingerprint=${this.context.identifier}` : ''
                  }`}
                  label={'Login with Office 365'}
                  color={theme === 'dark' ? '#ffffff' : '#64717E'}
                  eventName='SsoSignInOffice365Clicked'
                />
                <SsoSignInButton
                  icon={GoogleLogo}
                  href={`/platform-api/v1/sso/oauth/login/GOOGLE${
                    this.context.identifier ? `?fingerprint=${this.context.identifier}` : ''
                  }`}
                  label={'Login with Google'}
                  color={theme === 'dark' ? '#ffffff' : '#64717E'}
                  eventName='SsoSignInGoogleClicked'
                />
                <SsoSignInButton
                  icon={SlackLogo}
                  href={`/platform-api/v1/sso/oauth/login/SLACK${
                    this.context.identifier ? `?fingerprint=${this.context.identifier}` : ''
                  }`}
                  label={'Login with Slack'}
                  color={theme === 'dark' ? '#ffffff' : '#64717E'}
                  eventName='SSsoSignInSlackClicked'
                />

                <div className={'login-error' + (submitted && idx === 2 ? '' : ' hidden')}>
                  <div className={'help-block'}>{this.errMsg[idx]}</div>
                </div>

                <div className={'help-block' + (submitted && idx === 1 ? '' : ' hidden')}>
                  {this.errMsg[idx]}
                </div>
                <div className={'pt-3 font-size-normal'}>
                  Need an account?{' '}
                  <Link className='link-ocean-theme' to={'/sign-up'}>
                    Sign up
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthenticationWrapper>
    );
  }

  render() {
    return this.props.runByClientApp({
      onBolster: () => {
        return this.renderPlatformLogin();
      },
      onCheckPhish: () => {
        return this.renderCheckphishLogin();
      },
    });
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    loggingIn: state.login.loggingIn,
  };
};

const mapDispatchToProps = {
  login: userActions.login,
  runByClientApp: appActions.runByClientApp,
};

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
