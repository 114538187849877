import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import '../../Style/DetectionBanner.scss';
import {
  appConstants,
  DashBoardDto,
  featureIsAvailable,
  IAlertDispatchProps,
  IContentPlatform,
  IFindingsTableUrls,
  isAdminUser,
  isInternalUser,
  setLocalStorageValue,
} from '../../../../constants';
import { AppState } from '../../../../helpers';
import { IFindingsTableItemProps } from '../../Types/ugc.types';
import SocAddDetectionModal from './SocAddDetectionModal';
import SocDeleteDetectionModal from './SocDeleteDetectionModal';
import SocTakedownDetectionModal from './SocTakedownDetectionModal';
import { alertActions, dashboardActions } from '../../../../actions';
import SocUpdateDetectionStatusModal from './SocUpdateDetectionStatusModal';
import SocApproveDetectionModal from './SocApproveDetectionModal';
import { socReview, socTakenDownInProgress } from '../../constants';
import { FINDINGS_TABLE_IDS, MALICIOUS_TABLE_IDS } from '../../../MonitorAndTakedown/constants';
import { setTableTotalRows } from '../../../../reducers/table.reducer';
import { useAppDispatch } from '../../../../helpers/hooks';
import { Button } from '@mui/material';

interface IDetectionBannerProps {
  selectedFindings?: IFindingsTableItemProps[];
  ugcPlatforms: IContentPlatform[];
  addScanFindingSuccess?: (message?: string, data?: any) => void;
  deleteFindingsSuccess?: (message?: string, data?: any) => void;
  takedownFindingsSuccess?: (message?: string, data?: any) => void;
  updateStatusFindingsSuccess?: (message?: string, data?: any) => void;
  approveFindingsSuccess?: (message?: string, data?: any) => void;
  type: string;
  tableId: FINDINGS_TABLE_IDS;
  findingsTableUrls: IFindingsTableUrls;
  setFindingsTableUrls: (
    tableId: FINDINGS_TABLE_IDS,
    selected: IFindingsTableItemProps[],
    type: string,
  ) => void;
}
interface IPropsFromGlobalState {
  user: DashBoardDto;
}

const DetectionBanner = ({
  user,
  ugcPlatforms,
  selectedFindings = [],
  addScanFindingSuccess = _.noop,
  deleteFindingsSuccess = _.noop,
  takedownFindingsSuccess = _.noop,
  updateStatusFindingsSuccess = _.noop,
  approveFindingsSuccess = _.noop,
  alertError,
  tableId,
  type,
  findingsTableUrls,
  setFindingsTableUrls,
}: IDetectionBannerProps & IPropsFromGlobalState & IAlertDispatchProps) => {
  const [deleteDetectionModalShown, setDeleteDetectionModalShown] = useState(false);
  const [addDetectionModalShown, setAddDetectionModalShown] = useState(false);
  const [takedownDetectionModalShown, setTakedownDetectionModalShown] = useState(false);
  const [updateStatusModalShown, setUpdateStatusModalShown] = useState(false);
  const [approveModalShown, setApproveModalShown] = useState(false);
  const [platformTakedown, sePlatformTakedown] = useState('');

  const dispatch = useAppDispatch();

  if (!isAdminUser(user)) {
    return null;
  }

  const toggleDeleteDetectionModal = () => {
    setDeleteDetectionModalShown(!deleteDetectionModalShown);
  };
  const toggleAddDetectionModal = () => {
    setAddDetectionModalShown(!addDetectionModalShown);
  };
  const toggleTakedownDetectionModal = () => {
    setTakedownDetectionModalShown(!takedownDetectionModalShown);
  };
  const toggleUpdateStatusModal = () => {
    setUpdateStatusModalShown(!updateStatusModalShown);
  };
  const toggleApproveModal = () => {
    setApproveModalShown(!approveModalShown);
  };

  const onDeleteFindings = (data: any) => {
    toggleDeleteDetectionModal();
    deleteFindingsSuccess(undefined, data);
    restartCrossPage();
  };

  const onAddScanFinding = (data: any) => {
    toggleAddDetectionModal();
    addScanFindingSuccess(undefined, data);
    restartCrossPage();
  };

  const onTakedownFinding = (data: any) => {
    toggleTakedownDetectionModal();
    takedownFindingsSuccess(undefined, data);
    restartCrossPage();
  };
  const onSelectTakedown = () => {
    if (featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)) {
      for (let i = 1; i < findingsTableUrls[type][tableId]?.length; i++) {
        if (
          findingsTableUrls[type][tableId][0].platformId !==
          findingsTableUrls[type][tableId][i].platformId
        ) {
          alertError(`Please select the findings with the same platform`);
          return;
        }
      }
      sePlatformTakedown(findingsTableUrls[type][tableId][0].platformLabel);
    } else {
      for (let i = 1; i < selectedFindings.length; i++) {
        if (selectedFindings[0].platformId !== selectedFindings[i].platformId) {
          alertError(`Please select the findings with the same platform`);
          return;
        }
      }
      sePlatformTakedown(selectedFindings[0].platformLabel);
    }
    setTakedownDetectionModalShown(true);
  };

  const onUpdateStatusFindings = (data: any) => {
    toggleUpdateStatusModal();
    updateStatusFindingsSuccess(undefined, data);
    restartCrossPage();
  };
  const onApproveFindings = (data: any) => {
    toggleApproveModal();
    approveFindingsSuccess(undefined, data);
    restartCrossPage();
  };
  const restartCrossPage = () => {
    dispatch(setTableTotalRows(0));
    const type = appConstants.CONTENT_TYPE.APP_STORE;
    dispatch(dashboardActions.setFindingsTableUrls(tableId as FINDINGS_TABLE_IDS, [], type));
    setLocalStorageValue(['savedSelectionState', tableId || ''], []);
    dispatch(dashboardActions.setSelectedWebUrls(tableId as MALICIOUS_TABLE_IDS, []));
  };
  const buttons = [
    <div className='outline-secondary-button-container update-button' key='update'>
      <Button
        variant='outlined'
        disabled={
          featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
            ? findingsTableUrls[type][tableId]?.length === 0
            : selectedFindings.length === 0
        }
        onClick={() => {
          setUpdateStatusModalShown(true);
        }}
        id={`update-detection-status-${type}`}
      >
        Update Status
      </Button>
    </div>,
  ];

  if (!window.location.pathname.includes(socTakenDownInProgress)) {
    const isContainedVariant =
      window.location.pathname.includes('ignored') ||
      window.location.pathname.includes('detection') ||
      window.location.pathname.includes('takendown');
    buttons.push(
      <Fragment key='delete and add'>
        <div className='outline-secondary-button-container' key='delete'>
          <Button
            variant={isContainedVariant ? 'contained' : 'outlined'}
            disabled={
              featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
                ? findingsTableUrls[type][tableId]?.length === 0
                : selectedFindings.length === 0
            }
            onClick={() => {
              setDeleteDetectionModalShown(true);
            }}
            id={`delete-detection-${type}`}
          >
            Delete Detection
          </Button>
        </div>
      </Fragment>,
    );
  }
  if (window.location.pathname.includes(socReview)) {
    buttons.unshift(
      <Fragment key='approve and update'>
        <div className='outline-secondary-button-container' key='approve'>
          <Button
            variant='outlined'
            disabled={
              featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
                ? findingsTableUrls[type][tableId]?.length === 0
                : selectedFindings.length === 0
            }
            onClick={() => {
              setApproveModalShown(true);
            }}
            id={`approve-detection-${type}`}
          >
            Approve Findings
          </Button>
        </div>
      </Fragment>,
    );
    buttons.push(
      <Fragment key='Add Bolster Detection'>
        <Button
          variant='contained'
          onClick={toggleAddDetectionModal}
          key='add'
          id={`add-detection-${type}`}
        >
          Add Bolster Detection
        </Button>
      </Fragment>,
    );
  }
  if (window.location.pathname.includes(socTakenDownInProgress)) {
    buttons.push(
      <Button
        variant='contained'
        onClick={onSelectTakedown}
        disabled={
          featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
            ? findingsTableUrls[type][tableId]?.length === 0
            : selectedFindings.length === 0
        }
        key='takedown'
        id={`takedown-detection-${type}`}
      >
        Takedown
      </Button>,
    );
  }
  return (
    <div className='detection-action-banner'>
      <div className='detection-action-banner-inner'>
        <div className='name'>{user?.organization}</div>
        <div className='buttons'>{buttons}</div>
      </div>

      {deleteDetectionModalShown && (
        <SocDeleteDetectionModal
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][tableId]
              : selectedFindings
          }
          show={deleteDetectionModalShown}
          onCancel={toggleDeleteDetectionModal}
          onSubmit={onDeleteFindings}
          type={type}
        />
      )}
      {addDetectionModalShown && (
        <SocAddDetectionModal
          show={addDetectionModalShown}
          onCancel={toggleAddDetectionModal}
          onSubmit={onAddScanFinding}
          type={type}
        />
      )}
      {takedownDetectionModalShown && (
        <SocTakedownDetectionModal
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][tableId]
              : selectedFindings
          }
          platform={platformTakedown}
          show={takedownDetectionModalShown}
          onCancel={toggleTakedownDetectionModal}
          onSubmit={onTakedownFinding}
        />
      )}
      {updateStatusModalShown && (
        <SocUpdateDetectionStatusModal
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][tableId]
              : selectedFindings
          }
          show={updateStatusModalShown}
          onCancel={toggleUpdateStatusModal}
          onSubmit={onUpdateStatusFindings}
          type={type}
          tableId={tableId}
        />
      )}
      {approveModalShown && (
        <SocApproveDetectionModal
          data={
            featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)
              ? findingsTableUrls[type][tableId]
              : selectedFindings
          }
          show={approveModalShown}
          onCancel={toggleApproveModal}
          onSubmit={onApproveFindings}
          type={type}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { user } = state.dashboardReducer;
  const { contents } = state.appReducer;
  const { findingsTableUrls } = state.dashboardReducer;

  return { user, ugcPlatforms: contents?.[type]?.platforms || [], findingsTableUrls };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
  setFindingsTableUrls: dashboardActions.setFindingsTableUrls,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetectionBanner);
