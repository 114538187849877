import React, { useState } from 'react';
import _ from 'lodash';
import ModalOverlay from '../../../Common/ModalOverlay';
import { DarkWebFindingStatusAction, EDarkWebFindingStatus } from '../Types/DarkWeb.types';
import DarkWebService from '../../../../services/darkWeb.service';
import { capitalize, getSingleOrPluralForm, IAlertDispatchProps } from '../../../../constants';
import { connect } from 'react-redux';
import { alertActions } from '../../../../actions';

const darkWebService = new DarkWebService();

interface IChangeStatusModalProps {
  data: any[];
  status: EDarkWebFindingStatus | undefined;
  show: boolean;
  onCancel: () => void;
  onSubmit: (status: EDarkWebFindingStatus, data: any[]) => void;
}

function ChangeStatusModal({
  data,
  status,
  show,
  onCancel,
  onSubmit,
  alertSuccess,
  alertError,
}: IChangeStatusModalProps & IAlertDispatchProps) {
  const [isLoading, setIsLoading] = useState(false);

  const onModalSubmit = () => {
    const formData = new FormData();
    if (status === undefined) {
      return;
    }

    formData.append('sha256', _.map(data, d => d.sha256).join(','));
    formData.append('status', status);
    setIsLoading(true);
    darkWebService
      .updateFindingStatus(formData)
      .then(() => {
        setIsLoading(false);
        const successMessage = `You have changed the status to ${capitalize(
          status.toLowerCase(),
        )} successfully`;
        alertSuccess(successMessage);
        onSubmit(status, data);
      })
      .catch(error => {
        setIsLoading(false);
        alertError(error.message);
      });
  };

  return (
    <ModalOverlay
      show={show}
      isLoading={isLoading}
      title={`Confirm to mark as ${status && _.capitalize(status.toLowerCase())}`}
      onCancel={onCancel}
      onSubmit={onModalSubmit}
      submitButtonLabel={'Confirm'}
    >
      <div className='change-status-modal'>
        <div className='theme-text-secondary-color mb-2'>
          {`Are you sure you want to mark ${data.length} ${getSingleOrPluralForm(
            data.length,
            'finding',
          )} as
          ${status && DarkWebFindingStatusAction[status]} ?`}
        </div>
      </div>
    </ModalOverlay>
  );
}

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

export default connect(null, mapDispatchToProps)(ChangeStatusModal);
