import React from 'react';
import './AllUrlAnalysis.scss';
import { Box, Divider, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { DispositionStatus } from '../../../Common/DispositionStatus';
import moment from 'moment';
import { DateRangeOutlined } from '@mui/icons-material';
import SourceIcon from '../../../../assets/abuseMailBox/linkRounded-light.svg';
import StorageIcon from '../../../../assets/abuseMailBox/storageRounded-light.svg';
import IPLocation from '../../../../assets/abuseMailBox/location-light.svg';
import CopyButton from '../../../Common/CopyButton';
import { IThreatFindings } from '../constants';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { abuseMailBoxTargetedMaliciousParams } from '../../TargetedMalicious/TargetedMaliciousUtils';
import { TablePaginationController } from '../../../Common/Table/pagination.controller';
import { getScreenshotUrl } from '../../../../helpers/screenshotUrl';
import { Image } from '../../../Common/Image';
import AllUrlAnalysisMetaData, { AllURLAnalysisScanTimeStamp } from './AllUrlAnalysisMetaData';
import { setLocalStorageValue } from '../../../../constants';
import { Link } from 'react-router-dom';
import {
  ABUSE_MAILBOX_INSIGHTS_PARAMS,
  INSIGHTS_TYPE,
} from '../../TargetedMalicious/TargetedMaliciousColDefs';

interface IAllUrlAnalysisTiles {
  data: IThreatFindings[];
  apiParams: abuseMailBoxTargetedMaliciousParams;
  setApiParams: React.Dispatch<React.SetStateAction<abuseMailBoxTargetedMaliciousParams>>;
  total: number;
  isLoading: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const AllUrlAnalysisTiles = ({
  data,
  apiParams,
  setApiParams,
  total,
  isLoading,
  startDate,
  endDate,
}: IAllUrlAnalysisTiles) => {
  const onPageChange = (pageNumber: number) => {
    setApiParams((params: abuseMailBoxTargetedMaliciousParams) => ({
      ...params,
      pageNumber: pageNumber - 1,
    }));
  };

  const NoDataAvailable = (
    <Typography component='span' className='my--top-2'>
      --
    </Typography>
  );

  const setLastVisitedPath = () =>
    setLocalStorageValue('lastVisitedPageCheckPhish', window.location.pathname);

  return (
    <Box className={'all-url-analysis-tile-wrapper'}>
      <LoadingWrapper isLoading={isLoading} className='fill-space'>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          id={'scrollable-div'}
          style={{
            width: '100%',
            padding: '10px 0',
          }}
        >
          {data.map((data: IThreatFindings, indx: number) => {
            const noInsightslink = data?.sha256 && data?.timestamp ? '' : 'no-insights-link';
            return (
              <Grid item xs={12} md={6} key={`${indx}`}>
                <Paper elevation={0} variant='outlined' square className='tile-border'>
                  <Box component='div' paddingBlock='16px' margin='16px'>
                    <Box component='div'>
                      <Box component='div' display='flex' columnGap='18px' alignItems='center'>
                        <Typography className='card-title--header'>Scan Result</Typography>
                        <DispositionStatus
                          status={data.disposition}
                          displayedStyle='pill'
                          className='disposition-status'
                        />
                        <Divider
                          orientation='vertical'
                          variant='fullWidth'
                          flexItem
                          className='divider--yaxis'
                        />
                        <Typography fontSize={14}>Report Count {data.count}</Typography>
                      </Box>

                      <Box component='div' display='flex' marginBlockStart='10px'>
                        <AllURLAnalysisScanTimeStamp
                          firstScan={data.firstSeen}
                          lastScan={data.lastScan}
                          firstScanLabel='First Scan Time'
                          lastScanLabel='Last Scan Time'
                        />
                      </Box>
                    </Box>
                    <Divider className='divider--xaxis my--12-16' />
                    {/* meta Info starts here... */}
                    <Stack direction='row' spacing={4} justifyContent='space-between'>
                      <Box component='main' display='grid' rowGap={2}>
                        <AllUrlAnalysisMetaData
                          icon={<img src={SourceIcon} alt='souce icon' />}
                          title='Source URL'
                          component={'section'}
                        >
                          <Box component='div' display='flex'>
                            <Tooltip title={data.sourceUrl}>
                              <Link
                                to={`/abuse-mailbox/insights/${data.timestamp}/${data.sha256}`}
                                className={`insights-link link-font-size--14 text-eclipse text-eclipse-width--200 my--top-2 ${noInsightslink}`}
                                onClick={() => {
                                  setLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS, {
                                    insightsType: INSIGHTS_TYPE.ALL_URL_ANALYSIS,
                                    disposition: data.disposition,
                                    threatType: data.threatType,
                                    submissionTs: [startDate, endDate],
                                  });
                                }}
                              >
                                {data.sourceUrl}
                              </Link>
                            </Tooltip>

                            <Box component='span'>
                              <CopyButton copyButton value={data.sourceUrl} />
                            </Box>
                          </Box>
                        </AllUrlAnalysisMetaData>

                        <AllUrlAnalysisMetaData
                          icon={<img src={IPLocation} alt='IP location icon' />}
                          title='IP Address'
                          component={'section'}
                        >
                          {data?.ip ? (
                            <Link
                              to={`/abuse-mailbox/ip/${data.ip}`}
                              className='insights-link my--top-2'
                              onClick={setLastVisitedPath}
                            >
                              {data.ip}
                            </Link>
                          ) : (
                            NoDataAvailable
                          )}
                        </AllUrlAnalysisMetaData>

                        <AllUrlAnalysisMetaData
                          icon={<img src={StorageIcon} alt='Hosting provider icon' />}
                          title='Hosting Provider'
                          component={'section'}
                        >
                          <Typography className='meta-data--value my--top-2'>
                            {data?.hostingProvider ? data?.hostingProvider : NoDataAvailable}
                          </Typography>
                        </AllUrlAnalysisMetaData>

                        <AllUrlAnalysisMetaData
                          icon={<DateRangeOutlined cursor='none' className='no-hover' />}
                          title=' Registration Date'
                          component={'section'}
                        >
                          <Typography className='meta-data--value my--top-2'>
                            {data?.registrationDate
                              ? moment(data?.registrationDate).format('MMM DD YYYY h:mm:ss a')
                              : NoDataAvailable}
                          </Typography>
                        </AllUrlAnalysisMetaData>
                      </Box>
                      {/* Image goes here */}
                      <Box component='aside'>
                        <Image imagePath={getScreenshotUrl(data.screenshot)} />
                      </Box>
                    </Stack>
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </LoadingWrapper>
      <TablePaginationController
        total={total}
        pageSize={apiParams.pageSize}
        currentPageNumber={apiParams.pageNumber + 1}
        firstRowIndex={apiParams.pageNumber * apiParams.pageSize}
        lastRowIndex={apiParams.pageNumber * apiParams.pageSize + apiParams.pageSize}
        onPageChange={onPageChange}
        isLoading={isLoading}
        disableInput
      />
    </Box>
  );
};

export default AllUrlAnalysisTiles;
