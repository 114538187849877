import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import './RadioButtons.scss';
import RadioButtonEmpty from '../../assets/icons/RadioButtonEmpty.svg';
import RadioButtonFilled from '../../assets/icons/RadioButtonFilled.svg';

interface IOption {
  label: string;
  value: string;
}

interface IComponentProps {
  label: string;
  labelDom: any;
  options: IOption[];
  defaultValue: string;
  onChange: (value: string) => void;
  direction: 'row' | 'column';
}

interface IComponentState {
  selectedValue: string;
}

class RadioButtons extends React.Component<IComponentProps, IComponentState> {
  static defaultProps = {
    label: '',
    labelDom: null,
    options: [],
    defaultValue: '',
    onChange: _.noop,
    direction: 'row',
  };

  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      selectedValue: props.defaultValue || '',
    };
  }

  onChange = (value: string) => {
    this.setState({
      selectedValue: value,
    });
    this.props.onChange(value);
  };

  render() {
    const { label, labelDom, options, direction } = this.props;
    const { selectedValue } = this.state;
    const Direction: any = direction === 'row' ? Row : Col;
    return (
      <div className={'radio-buttons-component'}>
        {_.isEmpty(labelDom) ? <div className={'radio-buttons-label'}>{label}</div> : labelDom}
        <Direction>
          {options.map((option: IOption) => {
            const { label, value } = option;

            return (
              <div
                key={value}
                className='radio-button-container'
                onClick={() => {
                  this.onChange(value);
                }}
                id={`radio-button-${value}`}
                data-testid={`radio-button-${value}`}
              >
                <img
                  src={selectedValue === value ? RadioButtonFilled : RadioButtonEmpty}
                  alt={'icon'}
                />
                <div className='label'>{label}</div>
              </div>
            );
          })}
        </Direction>
      </div>
    );
  }
}

export { RadioButtons };
