import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { appConstants } from '../../constants';
import { EDstUrlTableId } from './GlobalSearchConstants';

interface IUseonGlobalSearchSubTabChange {
  globalSearchFindingData: { [key: string]: any[] };
}

export default function useOnGlobalSearchSubTabChange({
  globalSearchFindingData,
}: IUseonGlobalSearchSubTabChange) {
  const webData = useMemo(() => {
    const webAllFindingData = globalSearchFindingData[appConstants.CONTENT_TYPE.WEB];
    const response = _.groupBy(webAllFindingData, 'tableStatus');
    return response;
  }, [globalSearchFindingData]);

  const webDstData = useMemo(() => {
    const data = globalSearchFindingData[EDstUrlTableId[appConstants.CONTENT_TYPE.WEB]] || [];
    const response = _.groupBy(data, 'tableStatus');
    return response;
  }, [globalSearchFindingData]);

  const tvcData = useMemo(() => {
    const data = globalSearchFindingData?.[appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER];
    return data;
  }, [globalSearchFindingData]);

  const tvcDataDstUrl = useMemo(() => {
    const data =
      globalSearchFindingData[
        EDstUrlTableId[appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER]
      ] || [];
    return data;
  }, [globalSearchFindingData]);

  const [webFindings, setWebFindings] = useState({});
  const [tvcFindings, setTvcFindings] = useState<any[]>([]);

  const [webDstFindings, setWebDstFindings] = useState({});
  const [tvcDstFindings, setTvcDstFindings] = useState<any[]>([]);

  useEffect(() => {
    setWebFindings(webData);
    setTvcFindings(tvcData);
    setWebDstFindings(webDstData);
    setTvcDstFindings(tvcDataDstUrl);
  }, [tvcData, tvcDataDstUrl, webData, webDstData]);

  return {
    webFindings,
    tvcFindings,
    webDstFindings,
    tvcDstFindings,
  };
}
