import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { DashboardData, SUBMISSION_CATEGORY_COLOR_MAP } from '../../constants';
import moment from 'moment';
import ThemeContext from '../../../../context/ThemeContext';
import { Button, CircularProgress, Popover, TextField } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import './OverallEmailSubmission.scss';
import fetchApi from '../../../../services/api.service';
import { handleResponse } from '../../../../services/serviceWorker';
import { useAppDispatch } from '../../../../helpers/hooks';
import { alertActions } from '../../../../actions/alert.actions';

export function OverallEmailSubmission({ dashboardData }: { dashboardData: DashboardData }) {
  const emailSubmissions = dashboardData.emailSubmissions;
  const trendsChart = emailSubmissions.trendsChart;

  const xAxisData = Object.keys(trendsChart).map(key => new Date(key));
  const totalSeries = Object.values(trendsChart).map(value => value.total);
  const maliciousSeries = Object.values(trendsChart).map(value => value.malicious);
  const isDarkMode = useContext(ThemeContext).selectedTheme === 'dark';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [emailsPerHour, setEmailsPerHour] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [roiValue, setRoiValue] = useState(0);

  const [isUpdating, setIsUpdating] = useState(false);

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();

  const handleUpdate = useCallback(async () => {
    if (emailsPerHour <= 0 || hourlyRate <= 0) {
      dispatch(alertActions.error('Emails/hour and hourly rate must be greater than 0'));
      return;
    }
    setIsUpdating(true);

    try {
      const response = await fetchApi('/platform-api/v1/abuse-mailbox/automation-roi', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailsPerHour,
          hourlyRate,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update ROI`);
      }

      handlePopoverClose();
      setIsUpdating(false);
      dispatch(alertActions.success('ROI updated successfully'));
      const roiValue = Math.round((emailSubmissions.total / emailsPerHour) * hourlyRate);
      setRoiValue(roiValue);
    } catch (error) {
      setIsUpdating(false);
      dispatch(alertActions.error(error instanceof Error ? error.message : 'Failed to update ROI'));
    }
  }, [emailSubmissions.total, emailsPerHour, hourlyRate, dispatch, handlePopoverClose]);

  useEffect(() => {
    void fetchApi('/platform-api/v1/abuse-mailbox/automation-roi')
      .then(handleResponse)
      .then(res => {
        const { emailsPerHour, hourlyRate } = res;
        setEmailsPerHour(emailsPerHour);
        setHourlyRate(hourlyRate);
        const roiValue = Math.round((emailSubmissions.total / emailsPerHour) * hourlyRate);
        setRoiValue(roiValue);
      });
  }, [emailSubmissions.total]);

  return (
    <div className='overall-email-submission-container'>
      <div className='shared-titles-for-cards'>Overall Email Submission</div>
      <div className='overall-email-submission-total-container'>
        <div className='overall-email-submission-total'>
          <div className='overall-email-submission-total-description'>
            Total Emails Reported by
            <span className='overall-email-submission-total-users'>
              {' ' + emailSubmissions.users + ' '}
            </span>
            <span className='overall-email-submission-total-users'>Users</span>
          </div>
          <div className='overall-email-submission-total-value'>{emailSubmissions.total}</div>
        </div>

        <div className='overall-email-submission-roi-container'>
          <div className='overall-email-submission-roi-value'>{`$${roiValue.toLocaleString(
            'en-US',
          )}`}</div>
          <div className='overall-email-submission-roi-tooltip'>Automation ROI</div>
          <div className='overall-email-submission-roi-tooltip-icon' onClick={handlePopoverOpen}>
            <InfoOutlined />
          </div>
        </div>
        <div className='overall-email-submission-chart-container'>
          <LineChart
            xAxis={[
              {
                data: xAxisData,
                valueFormatter: (value: Date) => moment(value).format('MM/DD/YYYY'),
              },
            ]}
            leftAxis={null}
            bottomAxis={null}
            series={[
              {
                data: totalSeries,
                label: 'Total',
                color: isDarkMode ? '#599BFF' : '#2368BC',
              },
              {
                data: maliciousSeries,
                label: 'Targeted Malicious',
                color: SUBMISSION_CATEGORY_COLOR_MAP['Targeted Malicious'],
              },
            ]}
            legend={{ hidden: true }}
            height={240}
          />
        </div>
      </div>
      <Popover
        id='mouse-over-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <div className='overall-email-submission-roi-popover'>
          <div className='overall-email-submission-roi-popover-title'>
            Tier 1 SOC Analyst Triage:
          </div>
          <div className='overall-email-submission-roi-popover-item-title'>Processing Output:</div>
          <div className='overall-email-submission-roi-popover-item'>
            <span className='overall-email-submission-roi-popover-item-label'>Emails/hour:</span>
            <TextField
              className='overall-email-submission-roi-popover-item-input'
              type='number'
              inputProps={{ min: 1 }}
              value={emailsPerHour}
              onKeyDown={e => {
                if (e.key === '.') {
                  e.preventDefault();
                }
              }}
              onChange={e => setEmailsPerHour(Number(e.target.value))}
            />
          </div>
          <div className='overall-email-submission-roi-popover-item-title'>Hourly rate($):</div>
          <div className='overall-email-submission-roi-popover-item'>
            <span className='overall-email-submission-roi-popover-item-label'>Hourly rate($):</span>
            <TextField
              className='overall-email-submission-roi-popover-item-input'
              type='number'
              inputProps={{ min: 0, title: '' }}
              value={hourlyRate}
              onChange={e => setHourlyRate(Number(e.target.value))}
            />
          </div>
          <div className='overall-email-submission-roi-popover-update-button-container'>
            <Button variant='contained' size='small' onClick={handleUpdate}>
              {isUpdating ? <CircularProgress size={20} /> : 'Update'}
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
