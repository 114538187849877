import _ from 'lodash';
import moment from 'moment';
import CleanIcon from '../assets/icons/Clean.svg';
import PhishingIcon from '../assets/icons/Phishing.svg';
import ScamIcon from '../assets/icons/Scam.svg';
import HackedIcon from '../assets/icons/Hacked.svg';
import CryptojackingIcon from '../assets/icons/CryptoJacking.svg';
import OtherIcon from '../assets/icons/Other.svg';
import {
  DashBoardDto,
  IAttribute,
  ClientAppType,
  appConstants,
  MainModuleDisabledCodeMap,
  ERoleName,
} from '../constants';
import { Base64Img } from '../types/base-64-img.interface';
import { IUserListItemDto } from '../components/TeamMembers/TeamMembers';
import { IDropdownOption } from '../components/Common/Dropdown';
import { ITags } from '../components/Common/Tags/Tags';
import { PERSISTANT_DATE_FILTER } from '../basic-hooks/usePersistanceDate';

export const DISPLAYED_NULL = '--';
const readLocalStorage = (session: boolean = false) => {
  const data = session
    ? window.sessionStorage.getItem('bolsterStorage')
    : window.localStorage.getItem('bolsterStorage');
  return JSON.parse(data || '{}');
};

export const setLocalStorageValue = (key: string | string[], value: any) => {
  const data = readLocalStorage();
  _.set(data, key, value);
  window.localStorage.setItem('bolsterStorage', JSON.stringify(data));
};

export const unsetLocalStorageValue = (key: string | []) => {
  const data = readLocalStorage();
  _.unset(data, key);
  window.localStorage.setItem('bolsterStorage', JSON.stringify(data));
};

export const getLocalStorageValue = (key: string | string[], defaultValue: any = {}) => {
  const data = readLocalStorage();
  return _.get(data, key, defaultValue);
};

//session storage
export const setSessionStorageValue = (key: string | string[], value: any) => {
  const data = readLocalStorage();
  _.set(data, key, value);
  window.sessionStorage.setItem('bolsterStorage', JSON.stringify(data));
};

export const unsetSessionStorageValue = (key: string | []) => {
  const data = readLocalStorage();
  _.unset(data, key);
  window.sessionStorage.setItem('bolsterStorage', JSON.stringify(data));
};

export const getSessionStorageValue = (key: string | string[], defaultValue: any = {}) => {
  const data = readLocalStorage(true);
  return _.get(data, key, defaultValue);
};

export const withCommas = (x: string | number) => {
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
};

export const validateDomain = (domain: string) => {
  return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(domain);
};

export const validateIpv4Address = (ipv4: string) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ipv4,
  );
};

export const redirectToBolsterOne = (url: any) => {
  const { host } = window.location;
  const hostMapper: Record<string, string> = {
    'localhost:3300': 'http://localhost:3202',
    'checkphish.ai': 'https://app.checkphish.ai',
    'stage.checkphish.ai': 'https://app-stage.checkphish.ai',
  };

  if (hostMapper[host]) {
    window.location.replace(`${hostMapper[host]}/${url}`);
  }
};

export const redirectToCheckphish = (
  url: string = '/',
  returnPath: boolean = false,
  query: string = '',
) => {
  const { host } = window.location;
  const hostMapper: Record<string, string> = {
    'localhost:3202': 'http://localhost:3300',
    'app.checkphish.ai': 'https://checkphish.ai',
    'app-stage.checkphish.ai': 'https://stage.checkphish.ai',
  };

  if (hostMapper[host]) {
    if (returnPath) {
      return `${hostMapper[host]}${url}`;
    } else {
      window.location.replace(`${hostMapper[host]}${url}?${query}`);
    }
  }
  return `${host}/${url}?${query}`;
};

export const saveDashboardTimeRangeParameters = (value: any = {}) => {
  const localStorage = readLocalStorage();
  _.set(localStorage, ['dashboard', 'time'], { ...value });
  window.localStorage.setItem('bolsterStorage', JSON.stringify(localStorage));
};

export const saveDashboardTableParameters = (value: any = {}) => {
  const localStorage = readLocalStorage();
  _.set(localStorage, ['dashboard', 'table'], { ...value });
  window.localStorage.setItem('bolsterStorage', JSON.stringify(localStorage));
};

export const saveBulkscanTableParameters = (value: any = {}) => {
  const localStorage = readLocalStorage();
  _.set(localStorage, ['bulkscan', 'table'], { ...value });
  window.localStorage.setItem('bolsterStorage', JSON.stringify(localStorage));
};

export const loadDashboardTableParameters = () => {
  return getLocalStorageValue(['dashboard', 'table']);
};

export const cleanUpParameters = (needCleanUp = true) => {
  saveDashboardTableParameters();
  saveDashboardTimeRangeParameters();
  saveBulkscanTableParameters();
  setSessionStorageValue('bolsterPreviousUrl', '');
  setLocalStorageValue('bolsterPublicRoute', '');
  setLocalStorageValue('tableSetting', '');
  setLocalStorageValue('tableFilterModel', {});
  setLocalStorageValue('savedNumOfOtherCategories', {});
  setLocalStorageValue('globalSearchParams', { searchValue: '', selectFilterType: '' });
  setLocalStorageValue(PERSISTANT_DATE_FILTER, {});
  if (needCleanUp) {
    setLocalStorageValue('sessionExpiration', '');
    setLocalStorageValue('sessionInActiveLimit', '');
    setLocalStorageValue('userLatestActivity', '');
    setLocalStorageValue('isTabHidden', '');
    setLocalStorageValue('onSameDomain', '');
    setLocalStorageValue('paginationControls', {});
  }
};

export const generateId = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const setColumnsLabels = async (
  attributes: IAttribute[],
  tableIndex: string,
  columns: any,
) => {
  _.forEach(columns, (column: any) => {
    const matchedAttr = _.find(attributes, (attr: any) => {
      const field = attr.field;
      return attr.index === tableIndex && (field === column.accessor || field === column.id);
    });
    if (matchedAttr) {
      column.header = matchedAttr.label;
    }
  });
};

/**
 * @deprecated
 * function within the obj could not be deep clone in this function
 * Should use _.cloneDeep instead.
 */
export const duplicateObject = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const replaceDashWithSpace = (str: string) => {
  return str?.replace('_', ' ');
};
export const replaceSpaceWithDash = (str: string) => {
  return str?.toLowerCase().replace(/\s+/g, '-');
};
export const capitalize = (str: string) => {
  if (_.isEmpty(str)) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const covertSnakeCaseToTitleCase = (inputStr: string, separator = '_'): string => {
  return _.map(_.split(inputStr, separator), str => _.capitalize(str)).join(' ');
};

export const getNavPathnameForInsightPage = () => {
  return window.location.pathname.split('/insights/')[0];
};
export const getNavPathnameForOldInsightPage = () => {
  return window.location.pathname.split('/detail/')[0];
};

export const getInsightPagePathname = (path?: string): string => {
  const tmp = path || window.location.pathname;
  const pieces = tmp.split('/');
  return pieces.slice(1, pieces.length - 1).join('/');
};

export const getTimestampAndUrlSHA256 = (path?: string) => {
  const tmp = path || window.location.href;
  const search = tmp.split('?')[0];
  const pieces = search.split('/');
  return [pieces.slice(-2)[0], pieces.slice(-1)[0]];
};

export const getSourceTrigger = (path?: string): 'OUTLOOK' | 'UI' => {
  const tmp = path || window.location.href;
  const search = tmp.split('?')[1];
  const params = new URLSearchParams(search);
  return params.get('origin') === 'outlook' ? 'OUTLOOK' : 'UI';
};

export const getDomainTimestampSHA256AndIp = () => {
  const tmp = window.location.href;
  const pieces = tmp.split('/');
  return [pieces.slice(-3)[0], pieces.slice(-2)[0], pieces.slice(-1)[0]];
};

export const getNameFromUrlPath = (path: string): string => {
  if (path && path.includes('/')) {
    const pieces = path.split('/');
    return pieces.slice(-1)[0];
  }
  return path || '--';
};

export const getKeyFromUrlPath = (path: string): string => {
  if (path && path.includes('/')) {
    const pieces = path.split('/');
    return pieces.slice(3).join('/');
  }
  return path || '--';
};

export const getIp = () => {
  const tmp = window.location.href;
  return getNameFromUrlPath(tmp);
};

export const getDomain = () => {
  return getIp();
};
export const getSha256 = () => {
  return getIp();
};

export const getPlaybookId = () => {
  return parseInt(getIp(), 10);
};

export const getDisplayTimeFromTimeStamp = (ts: string | number, format = 'DD-MMM-YYYY') => {
  const date = moment(ts);
  if (ts && date.isValid()) {
    return moment(ts).format(format);
  }
  return '--';
};

export const getLocalTimeFromUtcTime = (utc: string, format = 'DD-MMM-YYYY') => {
  if (utc) {
    return moment.utc(utc).local().format(format);
  }
  return '--';
};

export const getDateTimeFormat = (date: string | number, format = 'MMMM Do YYYY, h:mm:ss a') => {
  if (date === '--') return '--';
  return moment(date).format(format);
};

export const isInternalUser = (user: DashBoardDto, newInternalOnly = false) => {
  if (newInternalOnly === true) {
    return user.is_internal === true;
  } else {
    return user.email.indexOf('redmarlinlabs') === 0 || user.is_internal === true;
  }
};

export const isAdminUser = (
  user: DashBoardDto,
  newInternalOnly = false,
  returnOnlyInternalAdmin = true,
) => {
  if (returnOnlyInternalAdmin) {
    return (
      isInternalUser(user, newInternalOnly) &&
      ['brand_admin', 'super_admin', 'brand_module_admin'].includes(user.role_name)
    );
  }
  return ['brand_admin', 'super_admin', 'brand_module_admin'].includes(user.role_name);
};

export const isBrandModuleAdmin = (user: DashBoardDto) => {
  return user.role_name === ERoleName.brandModuleAdmin;
};

export const isBrandReadOnlyUser = (user: DashBoardDto) => {
  return user.role_name === ERoleName.brandReadOnlyUser;
};

export const getUserInitial = (user: DashBoardDto) => {
  let name = '';
  if (user) {
    if (user.first_name) {
      name = user.first_name[0];
    }
    if (user.last_name) {
      name += user.last_name[0];
    }
  }
  return name;
};

export const ordinalSuffixOf = (i: number) => {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const getDispositionIcon = (status: string) => {
  let icon;

  switch (status.toLocaleLowerCase()) {
    case 'clean':
      icon = CleanIcon;
      break;
    case 'phish':
    case 'phishing':
      icon = PhishingIcon;
      break;
    case 'scam':
      icon = ScamIcon;
      break;
    case 'hack':
    case 'hacked':
      icon = HackedIcon;
      break;
    case 'cryptojacking':
      icon = CryptojackingIcon;
      break;
    case 'other':
    default:
      icon = OtherIcon;
      break;
  }
  return icon;
};

export const getSingleOrPluralForm = (number: number, singleForm: string, pluralForm?: string) => {
  if (number <= 1) {
    return singleForm;
  } else {
    return pluralForm ? pluralForm : singleForm + 's';
  }
};

export const copyToClipboard = (content: string) => {
  void navigator.clipboard.writeText(content);
};

// ResponsiveReactGridLayout does not calculate its size properly due to the width animation from sidebar level 2.
// Thus, trigger the window resize event to make it re-calculate after the width of sidebar level has be changed.
export const adjustResponsiveReactGridLayout = () => {
  if (window.location.pathname.indexOf('/dashboard') === 0) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 600);
  }
};

export const ipv4RemoveTailZero = (ip: string) => {
  // convert 10.20.0.0 => 10.20;
  while (ip.endsWith('.0')) {
    ip = ip.replace(/\.0$/, '');
  }
  return ip;
};

export const ipv4SortingFunction = (a: any, b: any) => {
  let aIp = a.ipAddress || a.ip_address || a.ip || a.ipv4 || '--';
  if (_.isArray(aIp)) {
    aIp = aIp[0];
  }
  if (aIp === '--') {
    return -1;
  }
  aIp = aIp.split('.');

  let bIp = _.isArray(b) ? b[0] : b.ipAddress || b.ip_address || b.ip || b.ipv4 || '--';
  if (_.isArray(bIp)) {
    bIp = bIp[0];
  }
  if (bIp === '--') {
    return 1;
  }
  bIp = bIp.split('.');

  for (let i = 0; i < aIp.length; i++) {
    if ((aIp[i] = parseInt(aIp[i])) < (bIp[i] = parseInt(bIp[i]))) return -1;
    else if (aIp[i] > bIp[i]) return 1;
  }
  return 0;
};

export const numberSortingFunction = (a: any, b: any) => {
  a = parseFloat(a);
  b = parseFloat(b);
  if (a < b) return -1;
  else if (a > b) return 1;
  else return 0;
};

/**
 * This method sort the data who has the null value or empty string
 * @param data : unsorted data
 * @param sortBy : sort property
 * @param sortDirection : sort direction
 * @returns sortedResult
 */
export const dashedSortingFunction = (data: any[], sortBy: string, sortDirection: any) => {
  const sortedResult = data.sort(customSortingFunc(sortDirection, sortBy));
  return sortedResult;
};

const customSortingFunc = (sortDirection: any, sortBy: string) => {
  return function (a: any, b: any) {
    if (a[sortBy] === b[sortBy] && a[sortBy] !== null && b[sortBy] !== null) {
      return 0;
    } else if (a[sortBy] === undefined || a[sortBy] === null) {
      return 1;
    } else if (b[sortBy] === undefined || b[sortBy] === null) {
      return -1;
    } else if (sortDirection === 'asc') {
      return a[sortBy] <= b[sortBy] ? -1 : 1;
    } else {
      return a[sortBy] <= b[sortBy] ? 1 : -1;
    }
  };
};

export const DateSortingFunction = (a: any, b: any, key = 'lastActivity') => {
  const aTs = moment(a[key], moment.ISO_8601, true).valueOf();
  const bTs = moment(b[key], moment.ISO_8601, true).valueOf();
  if (aTs > bTs) {
    return 1;
  } else if (aTs < bTs) {
    return -1;
  }
  return 0;
};

export const numberRangeColumnValidator = (value: string) => {
  const reg = /^[0-9]{1,9}([,][0-9]{1,9})?$/;
  const numbers = value.split(',');
  if (
    reg.test(value.replace(/ /g, '')) &&
    parseInt(numbers[0], 10) >= 0 &&
    parseInt(numbers[1], 10) >= parseInt(numbers[0], 10)
  ) {
    return true;
  }
  return 'Invalid Format. Proper format is: "2, 98"';
};

export const generateWidgetId = () => {
  return Math.floor(moment().valueOf() / 1000);
};

export const covertToMillion = (inNumber: number | string | undefined): number | string => {
  let result: number | string = 0;
  if (!inNumber) {
    return result;
  }
  result = typeof inNumber === 'string' ? parseInt(inNumber, 10) : inNumber;
  if (result >= 1000000) {
    result = (Math.round(result * 10) / 10000000).toFixed(1) + 'M';
  } else if (result >= 1000) {
    result = numberWithCommas(inNumber);
  }
  return result;
};

export const convertToThousand = (num: number, roundOf: boolean = false): string => {
  if (num > 0 && num < 1000) {
    return `${num}`;
  }
  if (num === 0 || num === null || num === undefined) {
    return '';
  }
  const result: string = roundOf ? Math.round(num / 1000) + 'K' : Math.ceil(num / 1000) + 'K';
  return numberWithCommas(result);
};
export const numberWithCommas = (number: number | string | undefined): string => {
  if (number === undefined || number === null) {
    return '';
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isMobile = () => {
  const appsVersion = navigator.appVersion,
    isAndroid = /android/gi.test(appsVersion),
    isIOS = /iphone|ipad|ipod/gi.test(appsVersion);
  return (
    isAndroid ||
    isIOS ||
    /(Opera Mini)|Kindle|webOS|BlackBerry|(Opera Mobi)|(Windows Phone)|IEMobile/i.test(
      navigator.userAgent,
    )
  );
};

export const featureIsAvailable = (
  user: DashBoardDto,
  featureCode: string | string[] | undefined,
  match: 'all' | 'any' = 'any',
): boolean => {
  const enableFeatures = [].concat(featureCode as any).filter(i => i != null);

  // if feature code is empty, feature is enabled by default
  if (enableFeatures.length <= 0) {
    return true;
  }

  // a protected feature is only available to authenticated users
  if (!user?.features || user.features.length <= 0) {
    return false;
  }

  for (const ef of enableFeatures) {
    const feature = user.features.find(f => f.code === ef);
    if (feature) {
      // special check for 4 main modules, using disabled code
      // say, if web module is enabled, then web_disabled should be checked also
      if (MainModuleDisabledCodeMap[feature.code]) {
        return (
          feature.enabled &&
          user.features.find(f => f.code === MainModuleDisabledCodeMap[feature.code])?.enabled !==
            true
        );
      }
      if (feature.enabled === true && match === 'any') {
        return true;
      }

      if (feature.enabled !== true && match === 'all') {
        return false;
      }
    }
  }

  return match !== 'any';
};

export const JSONToCSVConvertor = (JSONData: object[], reportTitle: string, showLabel: boolean) => {
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
  let CSV = '';

  //This condition will generate the Label/Header for the cols
  if (showLabel) {
    let row = '';
    for (const index in arrData[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1); // To remove the last empty col
    CSV += row + '\r\n';
  }

  for (let i = 0; i < arrData.length; i++) {
    let row = '';
    for (const index in arrData[i]) {
      if (arrData[i][index] && arrData[i][index].indexOf && arrData[i][index].indexOf(',') !== -1) {
        if (
          arrData[i][index] &&
          arrData[i][index].indexOf &&
          arrData[i][index].indexOf('"') !== -1
        ) {
          arrData[i][index] = arrData[i][index].replace(/,/g, '');
        } else {
          arrData[i][index] = `"${arrData[i][index]}"`;
        }
      }
      row += arrData[i][index] + ',';
    }
    row = row.slice(0, -1); //To remove the last empty col

    CSV += row + '\n';
  }

  //Generate a file name and get download
  let fileName = '';
  fileName += reportTitle.replace(/ /g, '_');
  const uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
  const link = document.createElement('a');
  link.href = uri;
  link.setAttribute('style', 'visibility:hidden');
  link.download = fileName + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const makeDefaultCol = (
  defaultColumnIds: any[],
  columnID: string,
  neededDefault: boolean,
) => {
  if (neededDefault) {
    defaultColumnIds.push(columnID);
  }
};

export const getUsersListLabelValue = (usersList: IUserListItemDto[]) => {
  const massagedUserListWithIds: IDropdownOption[] = [];

  if (usersList && usersList.length > 0) {
    usersList.forEach((user: IUserListItemDto) => {
      const labelAndValue: any = { label: '', value: '' };
      labelAndValue['label'] = `${user.user.firstName} ${user.user.lastName}`;
      labelAndValue['value'] = `${user.user.id}`;
      massagedUserListWithIds.push(labelAndValue);
    });
  }

  return massagedUserListWithIds;
};

export const getProvidedTagLabelValue = (providedTags: ITags[], isDark: boolean) => {
  const tagOptions: any = [];
  providedTags.forEach((tag: any) => {
    const labelAndValue = {};
    labelAndValue['label'] = tag.label;
    labelAndValue['value'] = tag.id;
    labelAndValue['color'] = isDark ? tag.color.darkColor : tag.color.lightColor;
    tagOptions.push(labelAndValue);
  });

  return tagOptions;
};

export function b64DataUrl(b64Img: Base64Img): string {
  return `data:${b64Img.mimeType};base64,${b64Img.data}`;
}

export async function convertToB64(file: File): Promise<string> {
  const dataUrl = await convertToDataUrl(file);
  return dataUrl.split(';')[1];
}

export function convertToDataUrl(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
}

export const subtractDays = (days: string, format = 'YYYY-MM-DD') => {
  return moment().subtract(days, 'days').format(format);
};

export const getQueryParams = (history: any): Record<any, any> => {
  const { search } = history.location;
  const result = {};
  if (search !== '') {
    const [_empty, params] = search.split('?');
    const data = params.split('&');
    data.forEach((d: any) => {
      const [key, value] = d.split('=');
      result[key] = value;
    });
  }
  return result;
};

export interface IRunByClientAppType {
  onBolster?: () => unknown;
  onCheckPhish?: () => unknown;
}

export const runByClientAppType = ({ onBolster, onCheckPhish }: IRunByClientAppType) => {
  switch (appConstants.CLIENT_APP_TYPE) {
    case ClientAppType.Bolster:
      return onBolster && onBolster();

    case ClientAppType.CheckPhish:
    default:
      return onCheckPhish && onCheckPhish();
  }
};

export const setRedirectRoute = async () => {
  const { search } = window.location;
  const [key, path, ...others] = search.split('=');
  if (key.includes('loginRedirectUrl')) {
    await setLocalStorageValue('loginRedirectUrl', path);
  }
};

export const redirectAfterLogin = () => {
  const loginRedirectUrl = getLocalStorageValue('loginRedirectUrl');
  if (!_.isEmpty(loginRedirectUrl)) {
    unsetLocalStorageValue('loginRedirectUrl');
    setLocalStorageValue('currentActiveUrl', loginRedirectUrl);
  }
};

export const compareIP = (a: string, b: string) => {
  const aIp = a.split('.').map(Number);
  const bIp = b.split('.').map(Number);
  for (let i = 0; i < aIp.length; i++) {
    if (aIp[i] < bIp[i]) {
      return -1;
    }
    if (aIp[i] > bIp[i]) {
      return 1;
    }
  }
  return 0;
};

export const checkIsURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

// camelCase to Title Case
export const camelCaseToTitleCase = (input: string): string => {
  const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(/\s+/);
  const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const titleCaseString = titleCaseWords.join(' ');

  return titleCaseString;
};

export const customAgGridDateComparator = (
  date1: string,
  date2: string,
  format: string = 'DD-MMM-YYYY',
) => {
  const aTs = moment(date1, format, true);
  const bTs = moment(date2, format, true);
  if (aTs > bTs) {
    return 1;
  } else if (aTs < bTs) {
    return -1;
  }
  return 0;
};
