import React, { Fragment } from 'react';
import { ETimelineViewModes, ITakedownActions, ITakedownEvents } from './TakedownTimelineContainer';
import './DetailTakedownTimelineView.scss';
import EventsLabel from './EventsLabel';
import moment from 'moment';
import { CollapsedDarkIcon, CollapsedLightIcon } from '../../assets/SVGIcons';
import { useAppSelector } from '../../helpers/hooks';
import useOnMonthlyLayoutChange from './useOnMonthlyLayoutChange';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

export interface MonthlyTakedownTimelineViewProps {
  takeDownEvents: ITakedownActions;
  rowId: string;
  yearlyTakedownEvents: ITakedownActions;
}
export default function MonthlyTakedownTimelineView(props: MonthlyTakedownTimelineViewProps) {
  const [isDarkMode] = useIsDarkMode();
  const globalTakedownRowData = useAppSelector(state => state.tableReducer.globalTakedownRowData);
  const { takeDownEvents, rowId, yearlyTakedownEvents } = props;

  const { onMonthlyLayoutChange } = useOnMonthlyLayoutChange({ yearlyTakedownEvents, rowId });
  return (
    <div className={`detail-takedown-timeline-container`} data-testid='monthly-takedown-view'>
      <div className='detail-takedown-timeline-container-title'>
        <div className='mr-2'>{takeDownEvents.currentMonth}</div>
        {globalTakedownRowData[rowId].prevMode === ETimelineViewModes.YEARLY && (
          <div
            data-testid='on-yearly-layout-change'
            onClick={() => onMonthlyLayoutChange(ETimelineViewModes.YEARLY)}
          >
            {isDarkMode ? <CollapsedDarkIcon /> : <CollapsedLightIcon />}
          </div>
        )}
      </div>
      <div className='detail-takedown-timeline-wrapper'>
        {Object.keys(takeDownEvents.events).map((event, index) => {
          return (
            <div key={index} className='detail-takedown-timeline-item'>
              <div className='detail-takedown-timeline-item-date'>{event}</div>
              <div className='detail-takedown-timeline-item-events'>
                {takeDownEvents.events[event].map((a: ITakedownEvents) => {
                  return (
                    <Fragment key={moment(a.createdts).valueOf()}>
                      <EventsLabel
                        label={a.label}
                        createdts={moment(a.createdts).format('hh:mm A')}
                        event={a.action}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
