import fetchApi from '../../../../services/api.service';
import { handleResponse } from '../../../../services/serviceWorker';
import {
  getDefaultUTCEndDate,
  getDefaultUTCStartDate,
} from '../../../Common/Table/ag-table/constants';

export interface IBreachDataLeakDetails {
  externalLinks: string[];
  details: string[];
}
export const getBreachDescriptions = async (): Promise<Record<string, string>> => {
  const rawGroupResponse = await fetchApi(
    '/platform-api/v1/content/dark-web/breach-descriptions/map',
  ).then(handleResponse);
  return rawGroupResponse;
};

export const getBreachDataLeakDetails = async (params: {
  entity: string;
  breach: string;
  searchTermId: string | number;
}): Promise<IBreachDataLeakDetails> => {
  const { entity, breach, searchTermId } = params;
  const rawGroupResponse = await fetchApi(
    `/platform-api/v1/content/dark-web/breach-description?entity=${entity}&breach=${breach}&searchTermId=${searchTermId}`,
  ).then(handleResponse);
  return rawGroupResponse;
};

export const getFindingsWidget = async (status: string, filter?: string): Promise<any> => {
  const requestGroupOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      status,
      must: {
        startDate: getDefaultUTCStartDate(),
        endDate: getDefaultUTCEndDate(),
        ...(filter && { filter }),
      },
    }),
  };
  const response = await fetchApi(
    '/platform-api/v1/content/dark-web/widget-analytics',
    requestGroupOptions,
  ).then(handleResponse);
  return response;
};
