import React, { useEffect, useState } from 'react';
import './CheckPhishSideBar.scss';
import ExpandableNavItem from './ExpandableNavItem';
import { CHECKPHISH_DRAWER_SUBITEM } from '../RouteOptions';
import NavItemV2 from './NavItemV2';
import _ from 'lodash';
import ActiveAppContext from '../../../context/CheckphishActiveAppContext';
import { useContext } from 'react';
import { appOptions } from './Constants';
import { history } from '../../../helpers';
import { getLocalStorageValue, setLocalStorageValue } from '../../../constants';
import { getCurrentActivePage } from '../../../helpers/url-helpers';
import {
  TYPOSQUATTING_DASHBOARD_PATHNAME,
  TYPOSQUATTING_MASTER_PATHNAME,
} from '../../DomainMonitoring/constant';
import { Link } from 'react-router-dom';
import { HUBSPOT_FORM_TYPE } from '../../Pricing/types';
import CheckPhishContactUsContext from '../../../context/CheckPhishContactUsContext';
import PopupBg from '../../../assets/paywallbg.svg';
import { SCROLL_TO_COMPARISION } from '../../Pricing/constants';
import { PRICING_PAGE_PATH } from '../../Pricing/constants';
import { IReasonDropdownValues } from '../../../constants';

type Props = {
  hasTyposquatData: boolean;
  selectedTheme: string;
  navHeader: string;
  isExpanded: boolean;
};

const CheckPhishSideBar = ({
  hasTyposquatData = true,
  selectedTheme = 'light',
  navHeader,
  isExpanded,
}: Props) => {
  const { selectedApp, setSelectedApp } = useContext(ActiveAppContext);
  const [navItems, setNavItems] = useState<any[]>([]);
  const [selectedNavItems, setSelectedNavItems] = useState<any>([]);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const {
    setIsSmallPopup,
    setHubspotFormType,
    setTriggerBackgroundImg,
    setTriggerSubtext,
    setTriggerPopUpButtonData,
    setTriggerContactUsModal,
    setTriggerMultiTyposquatDomainContactUsModal,
    setReason,
    setShowContactUsV2,
  } = useContext(CheckPhishContactUsContext);

  const triggerPaywallPopUp = (type: HUBSPOT_FORM_TYPE): void => {
    //set uptext
    setHubspotFormType(type);
    if (type === HUBSPOT_FORM_TYPE.TAKEDOWN) {
      setIsSmallPopup(true);
      setTriggerSubtext(
        <span style={{ fontSize: '14px' }}>
          <span style={{ fontWeight: 'bold' }}>Upgrade to premium</span> for AI auto-takedown and
          fast brand/phishing protection with direct host and platform integrations
        </span>,
      );
      setTriggerBackgroundImg(PopupBg);
      setTriggerPopUpButtonData([
        {
          text: 'Compare All Plans',
          onClick: () => {
            setTriggerMultiTyposquatDomainContactUsModal(false);
            setLocalStorageValue(SCROLL_TO_COMPARISION, true);
            history.push(PRICING_PAGE_PATH);
          },
          variant: 'outline-secondary',
          className: 'compare-all-plans',
        },
        {
          text: 'Contact Us',
          onClick: () => {
            setReason(IReasonDropdownValues.TAKEDOWN);
            setTriggerContactUsModal(true);
          },
          variant: 'primary',
        },
      ]);

      setTriggerMultiTyposquatDomainContactUsModal(true);
    } else {
      setShowContactUsV2(false);
      setTriggerContactUsModal(true);
    }
  };

  useEffect(() => {
    return setSelectedApp(appOptions[0]);
  }, []);

  useEffect(() => {
    const lastVisitedPage = getCurrentActivePage();
    if (
      _.startsWith(lastVisitedPage, '/domain-monitoring/dashboard') ||
      _.startsWith(lastVisitedPage, '/domain-monitoring/domain-acquisition')
    ) {
      return hasTyposquatData
        ? history.push(lastVisitedPage)
        : history.push(TYPOSQUATTING_MASTER_PATHNAME);
    }
    if (
      _.startsWith(lastVisitedPage, '/domain-monitoring/dashboard') ||
      _.startsWith(lastVisitedPage, '/domain-monitoring/domain-acquisition')
    ) {
      return hasTyposquatData
        ? history.push(lastVisitedPage)
        : history.push(TYPOSQUATTING_MASTER_PATHNAME);
    }
    if (!_.isEmpty(lastVisitedPage)) {
      return lastVisitedPage.includes('premium')
        ? history.push('/domain-monitoring/dashboard')
        : history.push(lastVisitedPage);
    }
    return hasTyposquatData
      ? history.push(TYPOSQUATTING_DASHBOARD_PATHNAME)
      : history.push(TYPOSQUATTING_MASTER_PATHNAME);
  }, []);

  useEffect(() => {
    const redirectUrl = getLocalStorageValue('loginRedirectUrl');
    !firstRender &&
      _.isEmpty(redirectUrl) &&
      setLocalStorageValue('currentActiveUrl', window.location.pathname);
    if (window.location.pathname.includes('premium') && selectedApp === appOptions[0]) {
      setSelectedApp(appOptions[1]);
    }
    if (!window.location.pathname.includes('premium') && selectedApp === appOptions[1]) {
      setSelectedApp(appOptions[0]);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (firstRender) return setFirstRender(false);
    const { pathname } = window.location;
    if (selectedApp === appOptions[0] && pathname.includes('premium')) {
      history.push('/domain-monitoring/dashboard');
    }
    if (!pathname.includes('premium') && selectedApp === appOptions[1]) {
      history.push('/premium/dashboard/web');
    }
  }, [selectedApp, firstRender]);

  useEffect(() => {
    const updatedNavItems = CHECKPHISH_DRAWER_SUBITEM(hasTyposquatData);
    updatedNavItems.length && setNavItems(updatedNavItems);
  }, [CHECKPHISH_DRAWER_SUBITEM, hasTyposquatData]);

  useEffect(() => {
    const selectedNavItems = navItems.filter((item: any) =>
      selectedApp === appOptions[1]
        ? item.parentLabel === selectedApp.label
        : item.parentLabel.toLocaleLowerCase() === navHeader.toLocaleLowerCase(),
    );
    selectedNavItems.length && setSelectedNavItems(selectedNavItems);
  }, [selectedApp, navItems, navHeader]);

  return (
    <div className='side-bar-wrapper' data-testid='side-bar-wrapper'>
      {selectedApp.value === appOptions[1].value && (
        <div className='navigation-wrap'>
          <Link
            to={hasTyposquatData ? TYPOSQUATTING_DASHBOARD_PATHNAME : TYPOSQUATTING_MASTER_PATHNAME}
          >
            Go back to my plan
          </Link>
        </div>
      )}
      <div className='side-bar-items-wrap'>
        {selectedNavItems.length &&
          selectedNavItems.map((item: any, index: number) => {
            return item.subItems ? (
              <ExpandableNavItem
                navItem={item}
                key={index}
                selectedTheme={selectedTheme}
                isExpanded={isExpanded}
                triggerPaywall={triggerPaywallPopUp}
              />
            ) : (
              <NavItemV2
                navItem={item}
                key={index}
                selectedTheme={selectedTheme}
                isExpanded={isExpanded}
                triggerPaywall={triggerPaywallPopUp}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CheckPhishSideBar;
