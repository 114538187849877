import React, { useState } from 'react';
import '../../Style/SocialMedia.scss';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { AuthenticationWrapper } from '../../../AuthenticationWrapper';
import { PageTitle } from '../../../Common/PageTitle';
import { AddSearchTerm } from './AddSearchTerm';
import { SearchesTable } from './SearchesTable';
import { ISearchTermsProp, ugcTypeLabel } from '../../Types/ugc.types';
import UgcService from '../../../../services/ugc.service';
import { alertActions } from '../../../../actions';

const ugcService = new UgcService();
const PAGE_TITLE = 'Search Terms';

const SearchTerms = ({
  ugcPlatforms,
  ugcCategories,
  type,
  tableName,
  darkWebEntityOptions,
  alertSuccess,
  alertError,
}: ISearchTermsProp) => {
  window.document.title = `${PAGE_TITLE} | ${ugcTypeLabel[type]}`;
  const [newSearchTerm, setNewSearchTerm] = useState<string>('');

  const addSearchTerm = (data: any, searchTerm: string) => {
    return ugcService
      .addSearchTerm(data, type)
      .then(res => {
        if (res) {
          setNewSearchTerm(searchTerm);
          alertSuccess(
            `Search term "${searchTerm}" successfully added, click refresh button to see updated search status`,
          );
        }
      })
      .catch(error => {
        alertError(`${error} "${searchTerm}"`);
      });
  };

  return (
    <AuthenticationWrapper>
      <div className={'search-term-page'}>
        <PageTitle title={PAGE_TITLE} />
        <AddSearchTerm
          ugcPlatforms={ugcPlatforms}
          ugcCategories={ugcCategories}
          onSubmit={addSearchTerm}
          darkWebEntityOptions={darkWebEntityOptions}
          type={type}
        />
        <SearchesTable
          ugcPlatforms={ugcPlatforms}
          ugcCategories={ugcCategories}
          newSearchTerm={newSearchTerm}
          darkWebEntityOptions={darkWebEntityOptions}
          tableName={tableName}
          type={type}
        />
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState, contentType: any) => {
  const { type } = contentType;
  const { user } = state.dashboardReducer;
  const { contents, darkWebEntityOptions } = state.appReducer;
  return {
    user,
    ugcPlatforms: contents?.[type]?.platforms || [],
    ugcCategories: contents?.[type]?.categories || [],
    darkWebEntityOptions: darkWebEntityOptions,
  };
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedSearchTerms = connect(mapStateToProps, mapDispatchToProps)(SearchTerms);
export { connectedSearchTerms as SearchTerms };
