import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IAllUrlAnalysisColumns } from '../../../AbuseMailbox/AllEmailSubmissions/AllUrlAnalysis/AllUrlAnalysisColDefs';
import {
  ABUSE_MAIL_BOX_MODULE_TYPE,
  ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID,
  defaultAbuseMailboxApiParams,
} from '../../../AbuseMailbox/constants';
import { AgGridReact } from 'ag-grid-react';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { TableContext } from '../table.context';
import {
  abuseMailBoxTargetedMaliciousParams,
  abuseMailBoxTargetedMaliciousParamsFilter,
  convertToFetchAbuseMailBoxTargetedMaliciousParams,
  generateAbuseMailBoxTargetedMaliciousColDefs,
} from '../../../AbuseMailbox/TargetedMalicious/TargetedMaliciousUtils';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { fetchAbuseMailBoxTargetedMaliciousUrlAnalysis } from './ag-requests';
import useOnGridReady from './useOnGridReady';
import { alertActions } from '../../../../actions';
import { paginationPageSizeSelector, TABLE_EMPTY_RESULTS_MESSAGE } from './constants';
import { getAgGridThemeClassName } from './ag-utils';
import { useAgGridEvents } from './useAgGridEvents';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import useAgGridFilterChange from './useAgGridFilterChange';
import _, { filter } from 'lodash';
import moment from 'moment';
import PersistentFilters from '../../PersistentFilters/PersistentFilters';
import { ExportCSV } from '../../Export/ExportCSV';
import { useAgServerSideExportCSV } from './useAgServerSideExportCSV';
import { ExportLoader } from '../../Export/ExportLoader';
import {
  setIsPersistentFilterSaveButtonVisible,
  setIsWebTablesGroupView,
  setOutGoingPersistentFilterString,
} from '../../../../reducers/table.reducer';
import { setCurrentTableId } from '../../../../reducers/insightsContainer.reducer';
import { IThreatFindings, IUrlRespose } from '../../../AbuseMailbox/AllEmailSubmissions/constants';
import { GridViewIcon } from '../../../../assets/SVGIcons';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import AllUrlAnalysisTiles from '../../../AbuseMailbox/AllEmailSubmissions/AllUrlAnalysis/AllUrlAnalysis.tiles';
import { getLocalStorageValue, setLocalStorageValue } from '../../../../constants';
import useOnAgRowHighlight from './useOnAgRowHighlight';

export interface IAgGridAbuseMailBoxAllUrlAnalysis {
  columns: IAllUrlAnalysisColumns[];
  tableId: typeof ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID;
  moduleType: typeof ABUSE_MAIL_BOX_MODULE_TYPE;
  style?: React.CSSProperties;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const AgGridAbuseMailBoxAllUrlAnalysis = ({
  columns,
  tableId,
  moduleType,
  style,
  startDate,
  endDate,
}: IAgGridAbuseMailBoxAllUrlAnalysis) => {
  const agRef = useRef<AgGridReact>(null);

  const BLOCK_SIZE = 15;
  const initialDisplayView = useMemo(() => {
    const tab = getLocalStorageValue(['currentTab', 'abuse-mailbox', tableId]);
    return _.isEmpty(tab) ? 'grid' : tab;
  }, []);

  const initialApiParams = useMemo(() => {
    const savedParams = getLocalStorageValue(['tableApiParams', tableId]);
    return _.isEmpty(savedParams)
      ? defaultAbuseMailboxApiParams(startDate, endDate, BLOCK_SIZE)
      : savedParams;
  }, []);

  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs } = useContext(TableContext);
  const dispatch = useAppDispatch();
  const [cachedAgRequest, setCachedAgRequest] = useState<IServerSideGetRowsRequest>();
  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);
  const [selectedItems, setSelectedItems] = useState<IThreatFindings[]>([]);
  const [displayContent, setDisplayContent] = useState<'table' | 'grid'>(initialDisplayView);
  const [gridViewData, setGridViewData] = useState<IThreatFindings[]>([]);
  const [cacheApiParams, setApiParams] =
    useState<abuseMailBoxTargetedMaliciousParams>(initialApiParams);

  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    setSelectedItems(event.api.getSelectedRows());
  }, []);

  const colDefs = useMemo<ColDef[]>(() => {
    return generateAbuseMailBoxTargetedMaliciousColDefs({
      columns,
      firstRenderDisplayColumnIdSet,
      tableId,
    });
  }, [columns, displayedColumnsIDs]);

  const onToggleDisplayContent = () => {
    setDisplayContent(current => {
      return current === 'table' ? 'grid' : 'table';
    });
  };

  useEffect(() => {
    setLocalStorageValue(['currentTab', 'abuse-mailbox', tableId], displayContent);
  }, [displayContent]);

  useEffect(() => {
    const allUrlsAnalysisPaginationHelper = getLocalStorageValue('allUrlsAnalysisPaginationHelper');
    let after: any;
    let excludeThreatShas: any;
    if (cacheApiParams.pageNumber > 0) {
      if (allUrlsAnalysisPaginationHelper[cacheApiParams.pageNumber - 1]) {
        after = allUrlsAnalysisPaginationHelper[cacheApiParams.pageNumber - 1].after;
        excludeThreatShas =
          allUrlsAnalysisPaginationHelper[cacheApiParams.pageNumber - 1].excludeThreatShas;
      }
    }
    const params = { ...cacheApiParams };
    const newDateFilters: abuseMailBoxTargetedMaliciousParamsFilter[] = [
      {
        field: 'submissionTs',
        operator: 'gte',
        value: startDate.format('YYYY-MM-DD'),
        isNot: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        field: 'submissionTs',
        operator: 'lte',
        value: endDate.format('YYYY-MM-DD'),
        isNot: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    ];
    const newFilters = params.filter.filter(item => item.field !== 'submissionTs');
    newFilters.push(...newDateFilters);
    params.filter = newFilters;
    if (_.isEqual(params.filter, cacheApiParams.filter)) return;
    setApiParams(params);
    agRef.current?.api?.onFilterChanged();
  }, [startDate, endDate]);

  const fetchData = useCallback(async (apiParams: abuseMailBoxTargetedMaliciousParams) => {
    setIsLoading(true);
    const allUrlsAnalysisPaginationHelper = getLocalStorageValue('allUrlsAnalysisPaginationHelper');
    let excludeThreatShas: string[] = [];
    if (allUrlsAnalysisPaginationHelper[apiParams.pageNumber - 1]) {
      apiParams['after'] = allUrlsAnalysisPaginationHelper[apiParams.pageNumber - 1].after;
      apiParams['excludeThreatShas'] =
        allUrlsAnalysisPaginationHelper[apiParams.pageNumber - 1].excludeThreatShas;
      excludeThreatShas = [
        ...allUrlsAnalysisPaginationHelper[apiParams.pageNumber - 1].excludeThreatShas,
      ];
    }
    const rawResponse: IUrlRespose = await fetchAbuseMailBoxTargetedMaliciousUrlAnalysis(apiParams);
    if (rawResponse.total === 0) {
      dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
    }
    const data = rawResponse.threatFindings.map(data => {
      excludeThreatShas.push(`${data.sha256}_${data.disposition}`);
      return {
        ...data,
        srcUrl: data.sourceUrl,
        url_sha256: data.sha256,
      };
    });
    allUrlsAnalysisPaginationHelper[apiParams.pageNumber] = {
      after: rawResponse.after,
      excludeThreatShas: [...new Set(excludeThreatShas)],
    };
    setLocalStorageValue('allUrlsAnalysisPaginationHelper', { ...allUrlsAnalysisPaginationHelper });
    setGridViewData(data);
    setTotal(rawResponse.total);
    setIsLoading(false);
    return { data, total: rawResponse.total };
  }, []);

  useEffect(() => {
    setLocalStorageValue(['tableApiParams', tableId], cacheApiParams);
    if (displayContent === 'table') return;
    fetchData(cacheApiParams);
  }, [cacheApiParams]);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const agRequest: IServerSideGetRowsRequest = params.request;
        try {
          const apiParams = convertToFetchAbuseMailBoxTargetedMaliciousParams(agRequest, tableId);
          apiParams['pageNumber'] = params?.api?.paginationGetCurrentPage() || 0;
          apiParams['pageSize'] = params?.api?.paginationGetPageSize();
          const { data, total } = await fetchData(apiParams);
          setCachedAgRequest(agRequest);
          setApiParams(apiParams);
          setTimeout(() => {
            params.success({
              rowData: data,
              rowCount: total,
            });
          }, 0);
        } catch (error) {
          console.log('Error fetching data :', error);
          params.fail();
        }
      },
    };
  }, [tableId]);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData: gridViewData,
  });

  const { onGridReady } = useOnGridReady(datasource, tableId, agRef);

  const { handleFirstDataRendered, handleOnPaginationChanged, currentPaginationSize } =
    usePersistentTablePageNumber(tableId);

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({
      tableId,
    });

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data.timestamp}-${params.data.sha256}-${params.data.disposition}`;
  }, []);

  const isTableView = displayContent === 'table';

  const { exportError, isExporting, onExportCSV, closeExportLoader, rowCount } =
    useAgServerSideExportCSV({
      agRef,
      cachedAgRequest,
      tableId,
      isTileView: !isTableView,
      tileViewApiParams: cacheApiParams,
      total: total,
    });

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const { handleUGCModuleFilterChanged } = useAgGridFilterChange(agRef, tableId);
  const { getRowClass } = useOnAgRowHighlight();

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
      dispatch(setIsWebTablesGroupView(false));
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  const isLoadingClass = isLoading ? ' ag-grid-loading-am--all-url-analysis' : '';

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme) + ' table-component' + `${isLoadingClass}`}
      style={style}
    >
      <div className='ag-grid-abuse-mailbox-table-tool-bar'>
        <PersistentFilters
          tableId={tableId}
          moduleType={moduleType}
          hideSavedFilter={displayContent === 'grid'}
        />
        <div onClick={onToggleDisplayContent}>
          {isTableView ? (
            <GridViewIcon data-testid='table-view' className='grid-view-icon' />
          ) : (
            <TableRowsOutlinedIcon data-testid='grid-view' />
          )}
        </div>
        {/* <ExportCSV onClick={() => void onExportCSV()} style={{ marginLeft: '8px' }} /> */}
        {/* <BulkActionBtn
          selectedItems={selectedItems}
          successCallBack={() => {
            agRef?.current?.api.deselectAll();
            agRef?.current?.api.onFilterChanged();
          }}
        /> */}
      </div>
      {displayContent === 'grid' ? (
        <AllUrlAnalysisTiles
          data={gridViewData}
          apiParams={cacheApiParams}
          total={total}
          setApiParams={setApiParams}
          isLoading={isLoading}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <AgGridReact
          ref={agRef}
          getRowId={getRowId}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          localeText={localeText}
          columnMenu={'new'}
          onGridReady={onGridReady}
          rowModelType={'serverSide'}
          blockLoadDebounceMillis={300}
          onColumnVisible={handleColumnVisible}
          onColumnMoved={handleColumnMoved}
          onColumnResized={handleColumnResized}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onFirstDataRendered={handleFirstDataRendered}
          onPaginationChanged={handleOnPaginationChanged}
          onSortChanged={handleOnSortChanged}
          sideBar={sideBarConfigs}
          onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
          overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
          reactiveCustomComponents
          onGridSizeChanged={onHandleGridSizeChanged}
          enableCellTextSelection={true}
          onFilterChanged={handleUGCModuleFilterChanged}
          animateRows={true}
          onSelectionChanged={onSelectionChanged}
          pagination={true}
          paginationPageSize={BLOCK_SIZE}
          paginationPageSizeSelector={paginationPageSizeSelector}
          suppressContextMenu={true}
          cacheBlockSize={currentPaginationSize}
          getRowClass={getRowClass}
        />
      )}
      <ExportLoader
        show={isExporting || exportError !== null}
        error={exportError || ''}
        totalCount={rowCount}
        closeExportLoader={closeExportLoader}
      />
    </div>
  );
};

export default AgGridAbuseMailBoxAllUrlAnalysis;
