import React, { memo, useCallback, useState } from 'react';
import _ from 'lodash';
import './PasswordEye.scss';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export const PASSWORD_LENGTH = 8;
export const PASSWORD_LIMIT = 64;

interface IComponentProp {
  label: string;
  name: string;
  isError?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  helpText?: string;
}

const NewPasswordEye: React.FC<IComponentProp> = ({
  label,
  name,
  helpText,
  isError = false,
  onChange = _.noop,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  const onInputChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.currentTarget;
      setPassword(value);
      onChange(event);
    },
    [onChange],
  );

  const onClickShowPasswordHandler = useCallback(() => {
    setShowPassword(prevState => !prevState);
  }, []);

  let inputClassName = 'form-control m-0 padding-x--end';
  if (isError) {
    inputClassName += ' error';
  }
  return (
    <>
      <div className={'password-eye-component form-group'}>
        <label className={'password-label'} htmlFor='enter-password'>
          {label}
        </label>
        <input
          type={showPassword ? 'text' : 'password'}
          className={inputClassName}
          placeholder={label}
          name={name}
          value={password}
          onChange={onInputChangeHandler}
          minLength={PASSWORD_LENGTH}
          id='enter-password'
        />
        <span className={'password-trigger'} onClick={onClickShowPasswordHandler}>
          {!showPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </span>
      </div>
      <div className='nav-text'>{helpText}</div>
    </>
  );
};

export const PasswordEye = memo(NewPasswordEye);
