import React from 'react';
import _ from 'lodash';
import { isBrandReadOnlyUser, isInternalUser } from '../../../../constants';
import { useAppSelector } from '../../../../helpers/hooks';
import {
  URL_COLUMN,
  PLATFORM_COLUMN,
  ORIGIN_COLUMN,
  CATEGORY_COLUMN,
  SOURCE_COLUMN,
  ACTION_NEEDED_COLUMN,
  FIRST_FOUND_COLUMN,
  MATCH_LOGO_IMAGE_COLUMN,
  MATCH_SEARCH_TERMS_COLUMN,
  IS_LOGO_DETECTION_COL,
  FINDING_SOURCE,
} from './TableColDef/FindingsTableCols';
import FindingsTableComponent from './FindingsTableComponent';
import { EFindingStatus, ugcType, ugcTypeLabel } from '../../Types/ugc.types';

const PAGE_TITLE = 'Live Detections';

const Detections = ({
  type,
  tableId,
  urlColPathName,
  tableName,
  enableSocInterface,
  AppStoreDetectionTableCols,
  AppStoreDefaultDetectionIds,
}: any) => {
  const user = useAppSelector(state => state.dashboardReducer.user);
  const isReadOnlyUser = isBrandReadOnlyUser(user);
  const TABLE_COLUMNS = [
    URL_COLUMN(urlColPathName),
    PLATFORM_COLUMN,
    CATEGORY_COLUMN,
    SOURCE_COLUMN,
    !isReadOnlyUser ? ACTION_NEEDED_COLUMN : null,
    FIRST_FOUND_COLUMN,
    MATCH_LOGO_IMAGE_COLUMN,
    MATCH_SEARCH_TERMS_COLUMN,
  ].filter(Boolean);
  window.document.title = `${PAGE_TITLE} | ${ugcTypeLabel[type]}`;
  const DEFAULT_COLUMN_IDS = [
    'url',
    'createdTs',
    'activeSince',
    'searchTerm',
    'platform',
    'category',
    'title',
    'logos',
    'actions',
    'externalLinks',
    'tags',
  ];
  if (type === ugcType.Social && isInternalUser(user)) {
    TABLE_COLUMNS.push(FINDING_SOURCE);
    DEFAULT_COLUMN_IDS.push('findingSource');
  }
  if (type === ugcType.Social) {
    TABLE_COLUMNS.push(ORIGIN_COLUMN);
    DEFAULT_COLUMN_IDS.push('origin');
  }

  if (type === ugcType.App_Store) {
    TABLE_COLUMNS.push(IS_LOGO_DETECTION_COL);
  }
  return (
    <FindingsTableComponent
      id={tableId}
      tableName={tableName}
      title={PAGE_TITLE}
      columns={ugcType.App_Store === type ? AppStoreDetectionTableCols : TABLE_COLUMNS}
      status={EFindingStatus.LIVE}
      defaultColumnIds={
        ugcType.App_Store === type ? AppStoreDefaultDetectionIds : DEFAULT_COLUMN_IDS
      }
      enableCheckbox
      type={type}
      enableSocInterface={enableSocInterface}
    />
  );
};

export default Detections;
