import React from 'react';
import { AppState, history } from '../../helpers';
import {
  AlertActionsTypes,
  DashBoardDto,
  ERoleName,
  getQueryParams,
  IAppDispatchProps,
  isInternalUser,
  platformUserRolesMap,
  ScanCountDto,
  ThemeModes,
} from '../../constants';
import { AuthenticationWrapper } from '../../components/AuthenticationWrapper';
import { alertActions, appActions, dashboardActions } from '../../actions';
import { connect } from 'react-redux';
import UserService from '../../services/user.service';
import DashboardService from '../../services/dashboard.service';
import { PageTitle } from '../Common/PageTitle';
import './profile.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';
import { LabelAndValue } from '../Common/LabelAndValue';
import { Link } from 'react-router-dom';
import {
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputGroup,
  Button,
  Spinner,
} from 'react-bootstrap';
import WarningRedRoundIcon from '../../assets/icons/Warning-round-red.svg';
import WarningRoundIcon from '../../assets/icons/Warning-round-red-border.svg';
import TickGreenRound from '../../assets/icons/tick-green-round.svg';
import ThemeContext from '../../context/ThemeContext';
import { EPlanProduct, SubscriptionV2 } from '../Common/Subscription';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
interface ILinkDispatchProps {
  logout: () => void;
  getUserInfo: () => void;
  enablePricing: (value: boolean) => void;
  alertSuccess: (message: string) => AlertActionsTypes;
  alertError: (message: string) => AlertActionsTypes;
}

interface IProfileProps {
  user: DashBoardDto;
}

const themeRadioButtons = [
  { name: ThemeModes.LIGHT, themeValue: ThemeModes.LIGHT.toLowerCase() },
  { name: ThemeModes.AUTO, themeValue: ThemeModes.AUTO.toLowerCase() },
  { name: ThemeModes.DARK, themeValue: ThemeModes.DARK.toLowerCase() },
];
interface IProfileState {
  scan_info: ScanCountDto;
  currUser: {
    newPassword: string;
    brand: string;
  };
  themeSetting: {
    themeName: string;
  };
  submitted: boolean;
  showPassword: boolean;
  editWorkEmail: boolean;
  workEmail?: string;
  editWorkEmailLoading: boolean;
  typosquattingSubscriptionPresent: boolean;
}

type Props = (IProfileProps & ILinkDispatchProps) & IAppDispatchProps;

class Profile extends React.Component<Props, IProfileState> {
  private readonly userService: UserService;
  private readonly dashboardService: DashboardService;
  private _isMounted = false;
  static contextType = ThemeContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      scan_info: {
        api_key: '',
        monthly_scan_limit: 0,
        yearly_scan_limit: 0,
        monthly_total_usage_count: 0,
        monthly_api_usage_count: 0,
        monthly_bulk_api_usage_count: 0,
      },
      currUser: {
        newPassword: '',
        brand: '',
      },
      themeSetting: {
        themeName: 'Light',
      },
      submitted: false,
      showPassword: false,
      editWorkEmail: false,
      editWorkEmailLoading: false,
      typosquattingSubscriptionPresent: false,
    };
    this.userService = new UserService();
    this.dashboardService = new DashboardService();
    this.props.getUserInfo();
    window.document.title = 'Profile | Bolster Platform';
  }

  getLocationParams() {
    const params = getQueryParams(history);
    if (params.pricing === 'true') {
      this.props.enablePricing(true);
    }
  }

  checkIfTyposquatSubscription() {
    const { user } = this.props;
    const userSubscription = user?.subscription;
    if (userSubscription) {
      const typosquatPlan = userSubscription.plans?.filter(
        (plan: any) => plan.product === EPlanProduct.TYPOSQUATTING,
      );
      if (typosquatPlan[0]) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getLocationParams();
    this.dashboardService
      .getScanCount()
      .then((data: any) => {
        this.setCompState({
          scan_info: {
            api_key: _.get(data, ['api_key'], '--'),
            monthly_scan_limit: _.get(data, ['monthly_scan_limit'], '--'),
            yearly_scan_limit: _.get(data, ['yearly_scan_limit'], '--'),
            monthly_total_usage_count: _.get(data, ['monthly_total_usage_count'], '--'),
            monthly_api_usage_count: _.get(data, ['monthly_api_usage_count'], '--'),
            monthly_bulk_api_usage_count: _.get(data, ['monthly_bulk_api_usage_count'], '--'),
          },
        });
      })
      .catch(err => {
        console.log(err);
      });

    this.getBrandInfo();
    this.setState({ typosquattingSubscriptionPresent: this.checkIfTyposquatSubscription() });
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  componentDidUpdate = (prevProps: Props, prevState: IProfileState) => {
    if (!_.isEqual(prevProps.user.email, this.props.user.email)) {
      this.getBrandInfo();
    }
    if (!_.isEqual(prevProps.user.subscription, this.props.user.subscription)) {
      this.setState({ typosquattingSubscriptionPresent: this.checkIfTyposquatSubscription() });
    }
  };

  setCompState = (newState: any, cb: any = _.noop) => {
    if (this._isMounted) {
      this.setState(newState, cb);
    }
  };

  getBrandInfo = () => {
    const { user } = this.props;
    if (!user.email) {
      return;
    }
    this.dashboardService
      .getBrandInfo(user.type_name)
      .then((data: any) => {
        this.setCompState({
          currUser: {
            ...this.state.currUser,
            brand: _.get(data, ['brand', 'brandName'], '--'),
          },
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  addEditWorkEmail = () => {
    const { workEmail } = this.state;
    const { alertError, alertSuccess } = this.props;
    if (workEmail) {
      this.setState({ editWorkEmailLoading: true });
      this.userService
        .addEditWorkEmail(workEmail)
        .then(resp => {
          alertSuccess(resp.message);
          this.props.getUserInfo();
        })
        .catch(err => {
          console.log(err);
          alertError(err);
        })
        .finally(() => this.setState({ editWorkEmailLoading: false, editWorkEmail: false }));
    }
  };

  sendEmailVerification = (workEmail: string) => {
    const { alertError, alertSuccess } = this.props;
    this.setState({ editWorkEmailLoading: true });
    this.userService
      .sendWorkEmailVerification({
        email: workEmail,
      })
      .then(resp => {
        alertSuccess(resp.message);
        this.props.getUserInfo();
      })
      .catch(err => {
        console.log(err);
        alertError(err);
      })
      .finally(() => this.setState({ editWorkEmailLoading: false }));
  };

  noWorkEmailView = (workEmail?: string): JSX.Element => {
    const { user } = this.props;
    const { editWorkEmailLoading, typosquattingSubscriptionPresent } = this.state;
    if (
      user?.domainMonitoringDaysToExpire !== undefined &&
      user?.domainMonitoringDaysToExpire !== -1
    ) {
      return (
        <div className='no-work-email-view-container'>
          <Col md={12}>
            <InputGroup className='mb-3'>
              <FormControl
                placeholder='name@company.com'
                aria-describedby='basic-addon2'
                defaultValue={workEmail}
                onChange={e => this.setState({ workEmail: e.currentTarget.value })}
              />
              <InputGroup.Append>
                <Button
                  variant='primary'
                  disabled={editWorkEmailLoading}
                  className='btn'
                  onClick={() => this.addEditWorkEmail()}
                >
                  {editWorkEmailLoading ? (
                    <Spinner className='spinner' animation='border' variant='light' size='sm' />
                  ) : (
                    'Add Email'
                  )}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          {typosquattingSubscriptionPresent === false && (
            <Col md={12} className='message-wrapper d-flex'>
              <Col className='work-email-err-msg-logo' md={1}>
                <img src={WarningRoundIcon} alt={'warning'} />
              </Col>
              <Col md={11} className='message' as={'p'}>
                {user.domainMonitoringDaysToExpire > 0 ? (
                  <>
                    The domain monitoring feature access will{' '}
                    <span className='darker'>
                      end in {user?.domainMonitoringDaysToExpire} days.
                    </span>{' '}
                    Please <span className='darker capitalise'>add your work email</span> to
                    continue access.
                  </>
                ) : (
                  <>
                    The domain monitoring feature access has <span className='darker'>ended.</span>{' '}
                    Please <span className='darker capitalise'>add your work email</span> to
                    continue access.
                  </>
                )}
              </Col>
            </Col>
          )}
        </div>
      );
    }
    return <></>;
  };

  hasWorkEmailView = (workEmail: string): JSX.Element => {
    const { editWorkEmail, editWorkEmailLoading } = this.state;
    const {
      user: { workEmailVerified },
    } = this.props;
    if (editWorkEmail) {
      return this.noWorkEmailView(workEmail);
    }
    return (
      <div className='has-work-email-view-container'>
        <Col md={12} className='d-flex'>
          <Col md={7} as={'p'} className='no-padding work-email-text'>
            {workEmail}
          </Col>
          {workEmailVerified ? (
            <Col md={5} className='d-flex verification-text'>
              <Col md={1}>
                <img src={TickGreenRound} alt={'warning'} />
              </Col>
              <Col md={11} className='content'>
                Verified
              </Col>
            </Col>
          ) : (
            <Col md={7} className='d-flex verification-error'>
              <Col className='mr-1' md={1}>
                <img src={WarningRedRoundIcon} alt={'warning'} />
              </Col>
              <Col md={11} className='error-content'>
                Please verify your email.
              </Col>
            </Col>
          )}
        </Col>
        {!workEmailVerified && (
          <Col md={12}>
            <Button
              variant='primary'
              className='btn'
              onClick={() => this.setState({ editWorkEmail: true })}
            >
              Edit Email
            </Button>
            <Button
              variant='outline primary'
              className='btn resend-email'
              onClick={() => this.sendEmailVerification(workEmail)}
            >
              {editWorkEmailLoading ? (
                <Spinner className='spinner' animation='border' variant='dark' size='sm' />
              ) : (
                'Resend Email'
              )}
            </Button>
          </Col>
        )}
      </div>
    );
  };

  emailContainer = (): JSX.Element => {
    const {
      user: { isPrimaryEmailWorkEmail, workEmail, email },
    } = this.props;
    return (
      <div className={'row section-container'}>
        <Col
          md={12}
          className={`d-flex primary-email-container isPrimaryEmailWorkEmail-${isPrimaryEmailWorkEmail}`}
        >
          <Col md={3} className='primary-email-header'>
            Primary Email
          </Col>
          <Col md={9} className='d-flex'>
            <Col as={'p'} className='no-padding' md={9}>
              {email}
            </Col>
            <Col md={3}>
              <div className='primary-tag'>Primary</div>
            </Col>
          </Col>
        </Col>
        {!isPrimaryEmailWorkEmail && (
          <Col md={12} className='d-flex work-email-container'>
            <Col md={3} className='work-email-header'>
              Work Email
            </Col>
            <Col md={9} className='d-flex'>
              {workEmail ? this.hasWorkEmailView(workEmail) : this.noWorkEmailView()}
            </Col>
          </Col>
        )}
      </div>
    );
  };

  render() {
    const { scan_info, currUser } = this.state;
    const { user, runByClientApp } = this.props;
    const { themeName, toggle } = this.context;

    return (
      <AuthenticationWrapper>
        <PageTitle title={'Profile Information'} titleAlwaysShown className={'background-white'} />
        <div className={'profile-container'}>
          <Row className={'top-container section-container'}>
            <div className={'user-big-avatar'}>
              {` `}
              {user.first_name[0] + `${user.last_name ? user.last_name[0] : ''}`}{' '}
            </div>
            <div className={'user-full-name'}>
              {' '}
              {user.first_name} {'  '} {user.last_name}
            </div>
          </Row>
          {runByClientApp({
            onBolster: () => (
              <Row className={'section-container'}>
                <Col xs={6}>
                  {currUser.brand && (
                    <LabelAndValue
                      label={'Organization'}
                      value={currUser.brand}
                      direction={'column'}
                    />
                  )}
                </Col>
                <Col xs={6}>
                  {user.role_name && (
                    <LabelAndValue
                      label={'Type of User'}
                      value={platformUserRolesMap[user.role_name]}
                      direction={'column'}
                    />
                  )}
                </Col>
              </Row>
            ),
            onCheckPhish: () =>
              this.props.user && (
                <>
                  {this.emailContainer()}
                  <SubscriptionV2
                    user={this.props.user}
                    callbackUserInfo={this.props.getUserInfo}
                    showMultipleSubs={true}
                  />
                </>
              ),
          })}
          <Row className={'section-container'}>
            <Col>
              {scan_info.api_key && user.role_name !== ERoleName.brandReadOnlyUser && (
                <LabelAndValue
                  label={'API Key'}
                  longTextLineNumberLimit={3}
                  value={scan_info.api_key}
                  direction={'column'}
                  copyButton
                />
              )}
              {scan_info.api_key && (
                <LabelAndValue
                  direction={'column'}
                  label={'API Documentation'}
                  renderDom={
                    <div>
                      {'Visit '}
                      <a
                        target={'_blank'}
                        rel='noopener noreferrer'
                        href={
                          runByClientApp({
                            onCheckPhish: () =>
                              'https://bolster.ai/kbarticles/scan-apis-for-checkphish-users',
                            onBolster: () =>
                              'https://bolster.ai/docs/phishing-and-fraudulent-site-detection-apis',
                          }) as string
                        }
                      >
                        Docs
                      </a>
                    </div>
                  }
                />
              )}
              {/*not displaying the limit for now*/}
              {/*<LabelAndValue label={ "Monthly Scan Limit" } value={ scan_info.monthly_scan_limit.toString() }*/}
              {/*               direction={ "column" }/>*/}
              {/*<LabelAndValue label={ "Yealy Scan Limit" } value={ scan_info.yearly_scan_limit.toString() }*/}
              {/*               direction={ "column" }/>*/}
              {/*<LabelAndValue label={ "Monthly Total Usage Count" }*/}
              {/*               value={ scan_info.monthly_total_usage_count.toString() } direction={ "column" }/>*/}
              {/*<LabelAndValue label={ "Monthly Api Usage Count" } value={ scan_info.monthly_api_usage_count.toString() }*/}
              {/*               direction={ "column" }/>*/}
              {/*<LabelAndValue label={ "Monthly Bulk Api Usage Count" }*/}
              {/*               value={ scan_info.monthly_bulk_api_usage_count.toString() } direction={ "column" }/>*/}
            </Col>
          </Row>
          <Row className={'section-container'}>
            <Col>
              <LabelAndValue
                label={'Theme Preferences'}
                direction={'column'}
                renderDom={
                  <ToggleButtonGroup type='radio' name='options' defaultValue={themeName}>
                    {themeRadioButtons.map((btn, idx) => (
                      <ToggleButton
                        key={idx}
                        type='checkbox'
                        id={`theme-btn-${idx}`}
                        className='btn-toggle-primary'
                        onChange={() => toggle(btn.name)}
                        value={btn.name}
                        checked={themeName === btn.name}
                        active={themeName === btn.name}
                      >
                        {btn.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                }
              />
            </Col>
          </Row>
          {isInternalUser(user, true) &&
            (runByClientApp({
              onCheckPhish: () => false,
              onBolster: () => true,
            }) as boolean) && (
              <Row>
                <Col>
                  <Link to={'/account/internal'} className={'internal-button'}>
                    <span>Internal</span>
                  </Link>
                </Col>
              </Row>
            )}
        </div>
      </AuthenticationWrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return { user };
};

const mapDispatchToProps = {
  getUserInfo: dashboardActions.getUserInfo,
  enablePricing: appActions.setPricingPopupFlag,
  runByClientApp: appActions.runByClientApp,
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export { connectedProfile as Profile };
