import React, { Fragment } from 'react';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ITableApiColumn } from '../../Common/Table/table.api';
import { DispositionStatus } from '../../Common/DispositionStatus';
import { DisputeStatus } from '../../Common/DisputeStatus';
import { Risk } from '../../Common/Risk';
import { getDisplayTimeFromTimeStamp, numberRangeColumnValidator } from '../../../constants';
import { Priority } from '../../Common/Priority';
import { WEB_DASHBOARD_PATHNAME } from '../constants';
import { HourGlassIcon, UserIcon } from '../../../assets/SVGIcons';
import Country from '../../Common/Country';
import Tags from '../../Common/Tags/Tags';
import AgGridDateRangeFilter from '../../Common/Table/ag-table/AgGridDateRangeFilter';
import { Image } from '../../Common/Image';
import { getLabelByValue } from '../../AbuseMailbox/constants';

const DISPOSITION_SEVERITY_MAP = { clean: 0, suspicious: 1, phish: 2, scam: 3, spam: 4 };

export const CATEGORY_FILTER_OPTIONS = [
  { label: 'Unknown', value: 'unknown' },
  { label: 'Domain Parking', value: 'domain_parking' },
  { label: 'BEC', value: 'bec' },
  { label: 'Sensitive Data', value: 'sensitive_data' },
  { label: 'Survey', value: 'survey' },
  { label: 'Promo Code', value: 'promo_code' },
  { label: 'Warning', value: 'warning' },
  { label: 'Gift Card', value: 'gift_card' },
  { label: 'Cryptocurrency', value: 'crypto' },
  { label: 'Tech Support', value: 'tech_support' },
  { label: 'Hacked Websites', value: 'hacked_site' },
  { label: 'Gaming', value: 'gaming' },
  { label: 'Adult', value: 'adult' },
  { label: 'Gambling', value: 'gambling' },
  { label: 'Non Sensitive Data', value: 'non_sensitive_data' },
  { label: 'Crypto Giveaway', value: 'crypto_giveaway' },
  { label: 'App Store', value: 'app_store' },
  { label: 'Contact', value: 'contact' },
  { label: 'Health', value: 'health' },
  { label: 'Banking', value: 'banking' },
  { label: 'Captcha Pages', value: 'captcha' },
  { label: 'Error Pages', value: 'error_page' },
  { label: 'Streaming', value: 'streaming' },
  { label: 'Pharmacy/Drug', value: 'drug' },
  { label: 'Domain Purchase', value: 'domain_purchase' },
  { label: 'Directory Listing', value: 'directory_listing' },
  { label: 'eCommerce/Shopping', value: 'online_store' },
];

export const DISPOSITION_FILTER_OPTIONS = [
  { label: 'Phish', value: 'phish' },
  { label: 'Clean', value: 'clean' },
  { label: 'Suspicious', value: 'suspicious' },
  { label: 'Scam', value: 'scam' },
];

export const DISPUTE_STATUS_COLUMN_OPTIONS = [
  { label: 'Accepted', value: 'accepted' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Under Review', value: 'under_review' },
];

export const DISPOSITION_SORT_FUNCTION = (disposition1: string, disposition2: string) => {
  const disposition1Severity = DISPOSITION_SEVERITY_MAP[disposition1];
  const disposition2Severity = DISPOSITION_SEVERITY_MAP[disposition2];
  if (disposition1Severity > disposition2Severity) {
    return -1;
  } else if (disposition1Severity < disposition2Severity) {
    return 1;
  }
  return 0;
};

export const SOURCE_URL_COLUMN = (urlPath: string): ITableApiColumn => ({
  id: 'src_url',
  accessor: 'srcUrl',
  fieldForExport: 'src_url',
  header: 'Source URL',
  headerTooltip: 'Link to URL Details View',
  render: (data: any) => {
    const { srcUrl } = data;
    return (
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={'tooltip-' + srcUrl} className={'table-source-url-tooltip'}>
            {srcUrl}
          </Tooltip>
        }
      >
        <div className={'table-source-url-column d-flex align-items-center'}>
          <Link to={`${WEB_DASHBOARD_PATHNAME}/insights/${data.timestamp}/${data.url_sha256}`}>
            {srcUrl}
          </Link>
        </div>
      </OverlayTrigger>
    );
  },
  hiddenInDragDrop: true,
  agFilterType: 'agTextColumnFilter',
  disableForSelection: true,
});

export const IP_COLUMN = (urlPath: string): ITableApiColumn => ({
  id: 'ip',
  accessor: 'ipAddress',
  fieldForExport: 'ip',
  header: 'IP Address',
  headerTooltip: 'Link to IP Details View',
  type: 'ipv4',
  render: (data: any) => {
    const { ip } = data;
    if (_.isEmpty(ip) || ip === '0.0.0.0') {
      return '--';
    }
    return (
      <div className={'table-ip-column'}>
        <Link to={`${WEB_DASHBOARD_PATHNAME}/ip/${ip}`}>{ip}</Link>
      </div>
    );
  },
  agFilterType: 'agTextColumnFilter',
});

export const PAST_DISPOSITION_COLUMN: ITableApiColumn = {
  id: 'dispositions',
  accessor: 'dispositions',
  header: 'Past Dispositions',
  headerTooltip: 'All the Past Dispositions',
  type: 'options',
  filterOptions: DISPOSITION_FILTER_OPTIONS,
  sortDisabled: true,
  render: (data: any) => {
    if (_.isEmpty(data)) return '--';
    const pastDispositions = data.dispositions?.sort(DISPOSITION_SORT_FUNCTION) || [
      data.current_disposition,
    ];
    return (
      <div className='table-icon'>
        {_.map(pastDispositions, (disposition: any, index: number) => (
          <Fragment key={index}>
            <DispositionStatus status={disposition} className={'pr-1'} />
          </Fragment>
        ))}
        {!_.isEmpty(data.disputeDetail) && (
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-dispute-${data.urlSha256}`} className={'table-icon-tooltip'}>
                <DisputeStatus
                  disposition={data.disputeDetail.reporter_disposition}
                  bolsterDisposition={data.disputeDetail.bolster_disposition}
                  status={data.disputeDetail.status}
                  user={data.disputeDetail.user}
                  timestamp={data.disputeDetail.created_ts}
                  comments={data.disputeDetail.reporter_comment}
                />
              </Tooltip>
            }
          >
            <div>
              <HourGlassIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    );
  },
  agFilterType: 'agTextColumnFilter',
};

export const DISPUTE_INITIATED: ITableApiColumn = {
  id: 'dispute_initiated',
  accessor: 'dispute_initiated',
  fieldForExport: 'dispute_initiated',
  header: 'Dispute Initiated',
  headerTooltip: 'Dispute Initiated',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'dispute_initiated') ? (data.dispute_initiated ? 'True' : 'False') : '--';
  },
  hidden: true,
  agFilterType: 'agTextColumnFilter',
};

export const DISPUTE_STATUS_COLUMN: ITableApiColumn = {
  id: 'dispute_status',
  accessor: 'dispute_status',
  header: 'Dispute Status',
  headerTooltip: 'Dispute Status',
  type: 'options',
  filterOptions: DISPUTE_STATUS_COLUMN_OPTIONS,
  optionsOrdersPersist: true,
  hidden: true,
  render: (data: any) => {
    return _.has(data, 'dispute_status')
      ? getLabelByValue(data?.dispute_status, DISPUTE_STATUS_COLUMN_OPTIONS)
      : '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const CURRENT_DISPOSITION_COLUMN: ITableApiColumn = {
  id: 'current_disposition',
  accessor: 'currentDisposition',
  fieldForExport: 'current_disposition',
  header: 'Current Disposition',
  headerTooltip: 'Disposition when Site was Last Scanned',
  type: 'options',
  filterOptions: DISPOSITION_FILTER_OPTIONS,
  render: (data: any) => {
    if (_.isEmpty(data)) {
      return '--';
    }
    return <DispositionStatus status={data.current_disposition} />;
  },
  agFilterType: 'agTextColumnFilter',
};

export const DISPOSITION_CHANGE_TIMESTAMP_COLUMN: ITableApiColumn = {
  id: 'current_disposition_ts',
  header: 'Disposition Change',
  accessor: 'current_disposition_ts',
  filterDisabled: false,
  type: 'date',
  isLongText: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.current_disposition_ts);
  },
  agFilterType: AgGridDateRangeFilter,
  fieldForPlaybook: 'dispositionChange',
};

export const LAST_UPDATED_COLUMN: ITableApiColumn = {
  id: 'last_updated_ts',
  accessor: 'last_updated_ts',
  header: 'Last Updated',
  headerTooltip: 'Time of last update',
  type: 'date',
  isLongText: true,
  filterDisabled: false,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.last_updated_ts);
  },
  agFilterType: AgGridDateRangeFilter,
  fieldForPlaybook: 'lastUpdated',
};
export const USER_REQUESTED_TAKEDOWN_COLUMN: ITableApiColumn = {
  id: 'User Requested Takedown',
  header: 'User Requested Takedown',
  headerTooltip: 'User Requested Takedown',
  accessor: 'userRequestedTakedown',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  hidden: true,
  agFilterType: 'agTextColumnFilter',
};

export const TAKEDOWN_TIME_COLUMN: ITableApiColumn = {
  id: 'takedown_ts',
  accessor: 'takedown_ts',
  header: 'Takedown Time',
  headerTooltip: 'Time of Taken down',
  type: 'date',
  isLongText: true,
  filterDisabled: false,
  render: (data: any) => {
    return _.isEmpty(data.takedown_ts)
      ? '--'
      : getDisplayTimeFromTimeStamp(data.takedown_ts, 'DD-MMM-YYYY, h:mm a');
  },
  agFilterType: AgGridDateRangeFilter,
};

export const FIRST_SEEN_COLUMN: ITableApiColumn = {
  id: 'first_seen_ts',
  accessor: 'first_seen_ts',
  header: 'First Seen',
  headerTooltip: 'Time of First Scan',
  type: 'date',
  isLongText: true,
  filterDisabled: false,
  isDefaultSort: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.first_seen_ts);
  },
  agFilterType: AgGridDateRangeFilter,
  fieldForPlaybook: 'firstSeen',
};

export const LOGO_DETECTION_COLUMN: ITableApiColumn = {
  id: 'brand_logo_detected',
  accessor: 'brandLogoDetected',
  fieldForExport: 'brand_logo_detected',
  header: 'Logo Detected',
  headerTooltip: 'Logo Detected',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  agFilterType: 'agTextColumnFilter',
  render(data) {
    return data.brand_logo_detected ? 'True' : 'False';
  },
};

export const HOSTING_COLUMN: ITableApiColumn = {
  id: 'as_description',
  accessor: 'networkOwner',
  header: 'Hosting Provider',
  headerTooltip: 'Entity that hosts the site and to which Bolster directs takedown requests',
  fieldForExport: 'as_description',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
};

export const SCAN_SOURCE_COLUMN: ITableApiColumn = {
  id: 'scan_source',
  accessor: 'scanSource',
  fieldForExport: 'scan_source',
  header: 'Source',
  headerTooltip: 'Initiating entity of scan',
  type: 'options',
  filterOptions: [{ label: 'Bolster', value: 'bolster' }],
  agFilterType: 'agTextColumnFilter',
};

export const COUNTRY_COLUMN: ITableApiColumn = {
  id: 'country_code',
  accessor: 'countryCode',
  header: 'Country',
  headerTooltip: 'Country Code',
  fieldForExport: 'country_code',
  render: (data: any) => {
    return <Country countryCode={data.countryCode} />;
  },
  agFilterType: 'agTextColumnFilter',
};

export const URL_CONSTRUCTION_COLUMN: ITableApiColumn = {
  id: 'matrix_algorithm',
  accessor: 'matrix_algorithm',
  header: 'URL Construction',
  headerTooltip: 'URL Construction',
  isLongText: true,
  type: 'options',
  filterOptions: [],
  agFilterType: 'agTextColumnFilter',
};
export const URL_CONSTRUCTION_ACQUISITION_COLUMN: ITableApiColumn = {
  ...URL_CONSTRUCTION_COLUMN,
  id: 'fuzz_algorithm',
  accessor: 'fuzz_algorithm',
  agFilterType: 'agTextColumnFilter',
};

export const RISK_COLUMN: ITableApiColumn = {
  id: 'risk_score',
  accessor: 'risk_score',
  header: 'Risk',
  headerTooltip: 'Risk',
  type: 'number',
  render: (data: any) => {
    if (data.risk_score) {
      return <Risk risk={data.risk_score} trending={data.risk_trending} />;
    }
    return '--';
  },
  agFilterType: 'agNumberColumnFilter',
};

export const RISK_TRENDING_COLUMN: ITableApiColumn = {
  id: 'risk_trending',
  accessor: 'risk_trending',
  hidden: true,
  type: 'options',
  filterOptions: [
    { label: 'Up', value: 'up' },
    { label: 'Down', value: 'down' },
  ],
  agFilterType: 'agTextColumnFilter',
};

export const CATEGORY_COLUMN: ITableApiColumn = {
  id: 'final_category',
  accessor: 'final_category',
  header: 'Category',
  headerTooltip: 'Category',
  isLongText: true,
  type: 'options',
  filterOptions: CATEGORY_FILTER_OPTIONS,
  agFilterType: 'agTextColumnFilter',
};

export const REGISTRANT_COLUMN: ITableApiColumn = {
  id: 'registrant_organization',
  accessor: 'registrant_organization',
  header: 'Registrant',
  headerTooltip: 'Registrant',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
};

export const REGISTRAR_COLUMN: ITableApiColumn = {
  id: 'registrar',
  accessor: 'registrar',
  header: 'Registrar',
  headerTooltip: 'Registrar',
  isLongText: true,
  render: ({ registrar }) => {
    if (!registrar) {
      return '--';
    }
    return registrar;
  },
  agFilterType: 'agTextColumnFilter',
};

export const REGISTRATION_DATE_COLUMN: ITableApiColumn = {
  id: 'domain_registration_date',
  accessor: 'domain_registration_date',
  headerTooltip: 'Registration Date',
  header: 'Registration Date',
  isLongText: true,
  filterDisabled: false,
  type: 'date',
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.domain_registration_date);
  },
  agFilterType: AgGridDateRangeFilter,
  fieldForPlaybook: 'domainRegistration',
};

export const NAMESERVERS_COLUMN: ITableApiColumn = {
  id: 'nameservers',
  header: 'Nameservers',
  headerTooltip: 'Nameservers',
  accessor: 'nameservers',
  isLongText: true,
  agFilterType: 'agTextColumnFilter',
};

export const MX_RECORDS_COLUMN: ITableApiColumn = {
  id: 'has_mx_records',
  accessor: 'has_mx_records',
  headerTooltip: 'Number of MX Records is more than zero',
  header: 'MX Records',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'has_mx_records') ? (data.has_mx_records ? 'True' : 'False') : '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const SSL_COLUMN: ITableApiColumn = {
  id: 'ssl_cert_provider',
  accessor: 'ssl_cert_provider',
  headerTooltip: 'SSL Certificate',
  header: 'SSL Certificate',
  agFilterType: 'agTextColumnFilter',
};

export const LAST_SCANNED_COLUMN: ITableApiColumn = {
  id: 'created_ts',
  accessor: 'created_ts',
  header: 'Last Scanned',
  headerTooltip: 'Last Scanned',
  isLongText: true,
  filterDisabled: false,
  isDefaultSort: true,
  render: (data: any) => {
    return getDisplayTimeFromTimeStamp(data.created_ts);
  },
  agFilterType: AgGridDateRangeFilter,
};

export const BRAND_COLUMN: ITableApiColumn = {
  id: 'sub_brand_id',
  accessor: 'sub_brand_display_name',
  header: 'Brand',
  headerTooltip: 'Brand',
  isLongText: true,
  type: 'options',
  filterOptions: [],
  agFilterType: 'agTextColumnFilter',
};

export const TLD_COLUMN: ITableApiColumn = {
  id: 'TLD',
  header: 'TLD',
  headerTooltip: 'Top Level Domain',
  accessor: 'tld',
  render: ({ tld }: any) => {
    return tld ? `.${tld}` : '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const PRICE_COLUMN: ITableApiColumn = {
  id: 'estimated_price',
  header: 'estimated_price',
  headerTooltip: 'Domain Price',
  accessor: 'estimated_price',
  type: 'number',
  render: ({ estimated_price }: any) => {
    return `$${estimated_price}`;
  },
  agFilterType: 'agNumberColumnFilter',
};

export const PRIORITY_COLUMN: ITableApiColumn = {
  id: 'acquisition_priority',
  header: 'acquisition_priority',
  headerTooltip: 'Domain Priority',
  accessor: 'acquisition_priority',
  type: 'number',
  render: ({ acquisition_priority }: any) => {
    if (acquisition_priority) {
      return <Priority priority={acquisition_priority} />;
    }
    return '--';
  },
  agFilterType: 'agNumberColumnFilter',
};

export const REGION_COLUMN: ITableApiColumn = {
  id: 'region',
  header: 'region',
  headerTooltip: 'Region',
  accessor: 'region',
  type: 'options',
  filterOptions: [
    { label: 'Asia', value: 'Asia' },
    { label: 'Africa', value: 'Africa' },
    { label: 'Generic', value: 'Generic' },
    { label: 'Australia (Oceania)', value: 'Australia(Oceania)' },
    { label: 'Europe', value: 'Europe' },
    { label: 'America', value: 'America' },
  ],
  agFilterType: 'agTextColumnFilter',
};

export const DOMAIN_COLUMN: ITableApiColumn = {
  id: 'primary_domain',
  accessor: 'primary_domain',
  header: 'Domain Name',
  headerTooltip: 'Domain',
  agFilterType: 'agTextColumnFilter',
};

export const CUSTOMER_SCANS_COLUMN: ITableApiColumn = {
  id: 'brand_scan_count',
  accessor: 'brand_scan_count',
  header: '# Customer Scans',
  headerTooltip: 'Number of Customer Scans',
  type: 'number',
  agFilterType: 'agNumberColumnFilter',
};

export const BOLSTER_SCANS_COLUMN: ITableApiColumn = {
  id: 'bolster_scan_count',
  accessor: 'bolster_scan_count',
  header: '# Bolster Scans',
  headerTooltip: 'Number of Bolster Scans',
  type: 'number',
  agFilterType: 'agNumberColumnFilter',
};

export const PAST_PHISH_ON_HOST_COLUMN: ITableApiColumn = {
  id: 'host_phish_count',
  accessor: 'host_phish_count',
  fieldForExport: 'pastPhishCountOnHost',
  header: 'Past Phish on Host',
  headerTooltip: 'Past Phish on Host',
  type: 'number',
  filterDisabled: true,
  sortDisabled: true,
  agFilterType: 'agNumberColumnFilter',
};

export const PAST_PHISH_ON_IP_COLUMN: ITableApiColumn = {
  id: 'ip_phish_count',
  accessor: 'ip_phish_count',
  fieldForExport: 'pastPhishCountOnIP',
  header: 'Past Phish on IP',
  headerTooltip: 'Past Phish on IP',
  type: 'number',
  filterDisabled: true,
  sortDisabled: true,
  agFilterType: 'agNumberColumnFilter',
};

export const SFB_DETECTED_COLUMN: ITableApiColumn = {
  id: 'sfb_detected',
  accessor: 'sfb_detected',
  header: 'Google Safe Browsing',
  headerTooltip: 'Google Safe Browsing',
  type: 'options',
  filterOptions: [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'sfb_detected') ? (data.sfb_detected ? 'True' : 'False') : '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const SEARCH_ENGINE_COLUMN: ITableApiColumn = {
  id: 'source',
  accessor: 'source',
  header: 'Google/Bing Searches',
  headerTooltip: 'Google/Bing Searches',
  type: 'options',
  filterOptions: [
    { label: 'True', value: '*_searchengine' },
    { label: 'False', value: 'NOT (*_searchengine)' },
  ],
  sortDisabled: true,
  optionsOrdersPersist: true,
  render: (data: any) => {
    return _.has(data, 'search_engine') ? (data.search_engine ? 'True' : 'False') : '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const TAGS_AUTHOR_COLUMN: ITableApiColumn = {
  id: 'Tags Author',
  header: 'Tags Author',
  headerTooltip: 'Tags Author',
  accessor: 'tags.updated_by',
  type: 'options',
  optionsOrdersPersist: true,
  hidden: true,
  agFilterType: 'agTextColumnFilter',
};

export const TAGS_COLUMN: ITableApiColumn = {
  id: 'tags',
  accessor: 'tags.id',
  header: 'Tags',
  sortDisabled: true,
  type: 'optionsMultiSelect',
  fieldForExport: 'tags_label',
  columnClassName: 'action-column',
  render: (rowData: any) => {
    return <Tags leftAligned rowData={rowData} />;
  },
  agFilterType: 'agSetColumnFilter',
  hiddenInTileView: true,
};

export const REDIRECTED_URL_COL: ITableApiColumn = {
  id: 'dst_url',
  accessor: 'dst_url',
  fieldForExport: 'dst_url',
  header: 'Redirected URL',
  headerTooltip: 'URL Details View',
  render: ({ dst_url }: any) => {
    if (dst_url) {
      return (
        <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={'tooltip-' + dst_url} className={'table-source-url-tooltip'}>
              {dst_url}
            </Tooltip>
          }
        >
          <div>
            <div className='table-long-text long-text-ellipsis-1'>{dst_url}</div>
          </div>
        </OverlayTrigger>
      );
    }
    return '--';
  },
  agFilterType: 'agTextColumnFilter',
};

export const SCAN_SOURCE_CATEGORY_COLUMN: ITableApiColumn = {
  id: 'sourceFeed',
  accessor: 'sourceFeed',
  fieldForExport: 'sourceFeed',
  header: 'Scan Source Category',
  type: 'options',
  sortDisabled: true,
  filterOptions: [],
  agFilterType: 'agTextColumnFilter',
};

export const WEB_PLAYBOOK_RECENT_CHANGES_COL = {
  id: 'recent_changes',
  accessor: 'recent_changes',
  header: 'Recent Changes',
  type: 'recentChangeDateOptions',
  filterOptions: [
    {
      label: 'Hosting Provider',
      value: 'hosting_provider',
    },
    {
      label: 'IP Address',
      value: 'ip_address',
    },
    {
      label: 'Site Title',
      value: 'site_title',
    },
    {
      label: 'MX Records',
      value: 'mx_records',
    },
    {
      label: 'NS Records',
      value: 'ns_records',
    },
    // {
    //   label: 'Logo Detected',
    //   value: 'logo_detected',
    // },
    // {
    //   label: 'Risk Score',
    //   value: 'risk_score',
    // },
    {
      label: 'Disposition',
      value: 'disposition',
    },
  ],
};

export const MALICIOUS_SCREEN_SHOT_COLUMN: ITableApiColumn = {
  id: 'screenshot',
  accessor: 'screenshot',
  header: 'Screenshot',
  headerTooltip: 'Screenshot',
  filterDisabled: true,
  sortDisabled: true,
  agFilterType: 'agTextColumnFilter',
  render: ({ image_path }) => {
    if (!image_path) return '--';
    return (
      <div style={{ maxWidth: '74px' }}>
        <Image imagePath={image_path} alt={image_path}></Image>
      </div>
    );
  },
  hiddenInTileView: true,
};
