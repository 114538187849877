import React from 'react';
import { appConstants } from '../../../constants';
import { Dashboard } from '../../Ugc/Components/DashboardContainer';
import { IMediaIntroProps } from '../../Ugc/Types/ugc.types';
import { ABUSE_MAIL_BOX_MODULE_TYPE, abuseMailBoxTeaserPageDescription } from '../constants';
import dashboardTeaserPageDark from '../../../assets/abuseMailBox/dashboardTeaserPageDark.svg';
import dashboardTeaserPageLight from '../../../assets/abuseMailBox/dashboardTeaserPageLight.svg';

const mediaTryOutProps: IMediaIntroProps = {
  featureName: 'Abuse Mailbox',
  featureDescription: abuseMailBoxTeaserPageDescription,
  staticLightImage: dashboardTeaserPageLight,
  staticDarkImage: dashboardTeaserPageDark,
  featureCode: appConstants.FEATURE_CODE.ABUSE_MAILBOX,
};

export const AbuseMailBoxTeaserPage = () => {
  return (
    <Dashboard
      type={ABUSE_MAIL_BOX_MODULE_TYPE}
      mediaTryOutProps={mediaTryOutProps}
      widgetsTitle={''}
    />
  );
};
