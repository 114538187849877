import React from 'react';
import { top5SuspiciousURLsNoDataMainText, top5SuspiciousURLsNoDataSubText } from '../../constants';
import './Top5SuspiciousURLsNoDataView.scss';

export function Top5SuspiciousURLsNoDataView() {
  return (
    <div className='top-5-suspicious-urls-no-data-container'>
      <div className='top-5-suspicious-urls-no-data-text-container'>
        <div className='top-5-suspicious-urls-no-data-main-text'>
          {top5SuspiciousURLsNoDataMainText}
        </div>
        <div className='top-5-suspicious-urls-no-data-sub-text'>
          {top5SuspiciousURLsNoDataSubText}
        </div>
      </div>
    </div>
  );
}
