import React, { useEffect } from 'react';
import { PageTitleInsights } from '../../Common/PageTitleInsights';
import { ThreatTypeInsightsHeader } from './ThreatTypeInsightsHeader';
import { EmailDetails } from './EmailDetails';
import { history } from '../../../helpers';
import { ThreatTypeInsights } from './ThreatTypeInsights';
import { getSha256 } from '../../../constants/util';
import { useThreatDetailsBasedOnSha } from './useThreatDetailsBasedOnSha';

interface ThreatTypeParams {
  type: string;
  tableId: string;
}

export const ThreatTypeInsightsContainer: React.FC<ThreatTypeParams> = ({ type, tableId }) => {
  const sha256 = getSha256()?.split('?')[0];

  const { threatTypeInsightsDetails } = useThreatDetailsBasedOnSha(sha256, tableId);

  const handleBack = () => {
    history.go(-2);
  };
  const insightsTitle = threatTypeInsightsDetails?.sourceUrl || threatTypeInsightsDetails?.name;

  useEffect(() => {
    if (window.location.href.includes('phone_number')) {
      document.title = 'Phone Number Insight | Bolster Platform';
      return;
    }
    if (window.location.href.includes('intent')) {
      document.title = 'Intent Insight | Bolster Platform';
      return;
    }
  }, []);

  return (
    <div className={'abuse-mailBox-threatType-details-container'}>
      <PageTitleInsights
        title={insightsTitle as string}
        backFn={handleBack}
        toolsLeft={
          <ThreatTypeInsightsHeader
            disposition={threatTypeInsightsDetails?.disposition as string}
            reportCount={threatTypeInsightsDetails?.count || 0}
          />
        }
      />
      <div className='abuse-mailBox-threatType-insights page-content'>
        {threatTypeInsightsDetails && (
          <ThreatTypeInsights savedAbuseMailBoxThreatData={threatTypeInsightsDetails} />
        )}
      </div>
      {threatTypeInsightsDetails?.sha256 && (
        <EmailDetails sha256={sha256} totalEmailsCount={threatTypeInsightsDetails?.count || 0} />
      )}
    </div>
  );
};
