import { useEffect, useState } from 'react';
import { fetchAbuseMailBoxTargetedMaliciousData } from '../../Common/Table/ag-table/ag-requests';
import { ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID, IThreatTypeDetails } from '../constants';
import { abuseMailBoxTargetedMaliciousParams } from '../TargetedMalicious/TargetedMaliciousUtils';
import { getLocalStorageValue } from '../../../constants';
import {
  ABUSE_MAILBOX_INSIGHTS_PARAMS,
  INSIGHTS_TYPE,
} from '../TargetedMalicious/TargetedMaliciousColDefs';
import { fetchAbuseMailBoxTargetedMaliciousUrlAnalysis } from '../../Common/Table/ag-table/ag-requests';
import moment from 'moment';

export const ThreatType_Details_Init_Data = {
  sha256: '',
  sourceUrl: '',
  status: '',
  threatType: '',
  timestamp: '',
  disposition: '',
  size: '',
  fileType: '',
  count: 0,
  category: '',
  informationGathering: '',
  threatSourceType: '',
  carrier: '',
  location: '',
  name: '',
};

export const useThreatDetailsBasedOnSha = (sha256: string, tableId: string) => {
  const [threatTypeInsightsDetails, setThreatTypeInsightsDetails] = useState<IThreatTypeDetails>(
    ThreatType_Details_Init_Data,
  );

  const extraParams = getLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS);
  const insightsType = extraParams.insightsType || INSIGHTS_TYPE.TARGETED_MALICIOUS;
  const submissionTs = extraParams['submissionTs'] || [];

  const fetchApi =
    insightsType === INSIGHTS_TYPE.TARGETED_MALICIOUS
      ? fetchAbuseMailBoxTargetedMaliciousData
      : fetchAbuseMailBoxTargetedMaliciousUrlAnalysis;

  useEffect(() => {
    const fetchThreatDetails = async () => {
      const apiParams: any = {
        filter: [{ field: 'sha256', operator: 'eq', value: sha256, isNot: false }],
        pageSize: 1,
        pageNumber: 0,
      };
      if (extraParams.threatType && insightsType === INSIGHTS_TYPE.TARGETED_MALICIOUS) {
        apiParams.filter.push({
          field: 'threatType',
          operator: 'eq',
          value: extraParams.threatType,
          isNot: false,
        });
      }
      if (extraParams.disposition) {
        apiParams.filter.push({
          field: 'disposition',
          operator: 'eq',
          value: extraParams.disposition,
          isNot: false,
        });
      }
      // add submission date to filter if its all url analysis.
      if (insightsType === INSIGHTS_TYPE.ALL_URL_ANALYSIS && submissionTs.length) {
        const [startDate, endDate] = submissionTs;
        apiParams.filter.push(
          {
            field: 'submissionTs',
            operator: 'gte',
            value: moment(startDate).format('YYYY-MM-DD'),
            isNot: false,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          {
            field: 'submissionTs',
            operator: 'lte',
            value: moment(endDate).format('YYYY-MM-DD'),
            isNot: false,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        );
      }

      try {
        const { threatFindings } = await fetchApi(apiParams as abuseMailBoxTargetedMaliciousParams);
        setThreatTypeInsightsDetails(threatFindings?.[0]);
      } catch (err) {
        console.log('Error Fetching data');
      }
    };
    if (tableId === ABUSE_MAIL_BOX_TARGETED_MALICIOUS_TABLE_ID) fetchThreatDetails();
  }, [sha256]);

  return { threatTypeInsightsDetails };
};
