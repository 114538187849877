export const res = {
  total: 349,
  threatFindings: [
    {
      sha256: '762a5209354fc89fb6c3222be102704b635e1f4fdf5f965676c9ad51ee118e5d',
      threatType: 'phone_number',
      firstSeen: '2024-10-30T22:14:08.091Z',
      lastScan: '2024-11-04T13:04:43.757Z',
      status: 'not_applicable',
      sourceUrl: '+1 808 289 8192',
      timestamp: 1730725483757,
      disposition: 'malicious',
      category: 'tech_support_scam',
      count: 25,
      carrier: 'unavailable',
      location: 'unavailable',
    },
    {
      sha256: 'e008d745a32ee0ad8427c62447f5eb8e764f7b6391401398d868d05c018d877e',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:30:54.593Z',
      lastScan: '2024-11-06T22:35:14.404Z',
      status: 'not_applicable',
      sourceUrl: 'verification',
      timestamp: 1730932514404,
      disposition: 'malicious',
      category: 'sensitive information',
      count: 6529,
      informationGathering: 'Email requesting for any verifications.',
    },
    {
      sha256: 'a2fcf1dbae89ff2a37428a2e3c09d2df24a20f559b9f8756b76302fb59b97f26',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:22:48.940Z',
      lastScan: '2024-11-06T22:35:14.404Z',
      status: 'not_applicable',
      sourceUrl: 'update account',
      timestamp: 1730932514404,
      disposition: 'malicious',
      category: 'account compromise',
      count: 8901,
      informationGathering: 'Email requesting to update account details.',
    },
    {
      sha256: 'f0d1c03a534bd55313c215462b6589ce3379936f645205bbf3a56380ef07d06a',
      threatType: 'phone_number',
      firstSeen: '2024-11-04T09:35:59.206Z',
      lastScan: '2024-11-04T09:35:59.206Z',
      status: 'not_applicable',
      sourceUrl: '+1 844 505 2993',
      timestamp: 1730712959206,
      disposition: 'malicious',
      category: 'tech_support_scam',
      count: 1,
      carrier: 'unavailable',
      location: 'unavailable',
    },
    {
      sha256: '42955822e6a3b064407db8d7cdaa45d0ef9d7e41f614dd1c8d00816e7e8e6f6a',
      threatType: 'intent',
      firstSeen: '2024-10-15T14:26:37.718Z',
      lastScan: '2024-11-06T22:35:14.404Z',
      status: 'not_applicable',
      sourceUrl: 'tech support',
      timestamp: 1730932514404,
      disposition: 'malicious',
      category: 'support',
      count: 4772,
      informationGathering: 'Email offering tech support by providing contact information.',
    },
    {
      sha256: 'fb80a8be81109f15ac454aca7213c68cd218e64e5884f324a2d3ca41a3ce86db',
      threatType: 'phone_number',
      firstSeen: '2024-11-04T11:44:07.001Z',
      lastScan: '2024-11-06T10:52:10.668Z',
      status: 'not_applicable',
      sourceUrl: '+1 900 759 106',
      timestamp: 1730890330668,
      disposition: 'malicious',
      category: 'unknown',
      count: 4,
      carrier: 'unavailable',
      location: 'unavailable',
    },
    {
      sha256: '0b824ae0e974a0f163e9775837e90e3ffc5a31d581a42e9d896366fa69974c7b',
      threatType: 'intent',
      firstSeen: '2024-10-10T03:52:15.780Z',
      lastScan: '2024-11-05T09:42:27.149Z',
      status: 'not_applicable',
      sourceUrl: 'romance',
      timestamp: 1730799747149,
      disposition: 'suspicious',
      category: 'baiting',
      count: 26,
      informationGathering: 'Email offering pleasure services.',
    },
    {
      sha256: 'ba8c34c63764a67d8d6ce3becd197d9e67a4f39465e578edfc0730c918b5bd6e',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:24:29.562Z',
      lastScan: '2024-11-06T22:34:40.696Z',
      status: 'not_applicable',
      sourceUrl: 'renewal',
      timestamp: 1730932480696,
      disposition: 'malicious',
      category: 'purchase',
      count: 4149,
      informationGathering: 'Email requests to renew subscription/membership.',
    },
    {
      sha256: 'a2aaa33652527c3ab546bc129486f9146771318bb51e4568ed6bb39acb88e2a8',
      threatType: 'intent',
      firstSeen: '2024-10-10T00:16:17.491Z',
      lastScan: '2024-11-05T16:07:42.559Z',
      status: 'not_applicable',
      sourceUrl: 'purchase request',
      timestamp: 1730822862559,
      disposition: 'malicious',
      category: 'purchase',
      count: 2,
      informationGathering: 'Email requests to buy a product.',
    },
    {
      sha256: '28e0787d51e428483354aca4903216b25665e4ccdf155a5891efc58ec2eddc68',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:22:22.709Z',
      lastScan: '2024-11-06T22:34:40.696Z',
      status: 'not_applicable',
      sourceUrl: 'purchase notification',
      timestamp: 1730932480696,
      disposition: 'malicious',
      category: 'purchase',
      count: 374,
      informationGathering: 'Email notifies about an order purchase.',
    },
    {
      sha256: 'bfdd02d7328852fe1e6c6cfe8d198e421ccd4721201391cb47127180383679e2',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:47:39.517Z',
      lastScan: '2024-11-06T21:39:45.645Z',
      status: 'not_applicable',
      sourceUrl: 'promotion offer',
      timestamp: 1730929185645,
      disposition: 'malicious',
      category: 'baiting',
      count: 362,
      informationGathering: 'Email providing an offer on product or service.',
    },
    {
      sha256: '071a7d7e44093eab0f4a1408c432c3e8dee1d78b3807a717973e42837e078f17',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:24:29.562Z',
      lastScan: '2024-11-06T22:35:14.404Z',
      status: 'not_applicable',
      sourceUrl: 'personal information',
      timestamp: 1730932514404,
      disposition: 'malicious',
      category: 'sensitive information',
      count: 9134,
      informationGathering: 'Email requesting for sensitive personal information.',
    },
    {
      sha256: 'ee386ffbd56c07498fbcb530c1761dad19ef3cd8f7bd770c48712cc4a8c481bf',
      threatType: 'intent',
      firstSeen: '2024-10-09T08:22:48.940Z',
      lastScan: '2024-11-06T22:35:14.404Z',
      status: 'not_applicable',
      sourceUrl: 'payment information',
      timestamp: 1730932514404,
      disposition: 'malicious',
      category: 'sensitive information',
      count: 9274,
      informationGathering: 'Email requests to provide billing information.',
    },
    {
      sha256: '1e5fff89387c829a2c0b8dec90b6f3e9f6e473dc63df028a5168581aa78f22f7',
      threatType: 'intent',
      firstSeen: '2024-10-09T14:01:24.461Z',
      lastScan: '2024-11-06T21:04:47.827Z',
      status: 'not_applicable',
      sourceUrl: 'password reset',
      timestamp: 1730927087827,
      disposition: 'malicious',
      category: 'account compromise',
      count: 152,
      informationGathering: 'Email mentions that password of account was changed.',
    },
    {
      sha256: '259753a1f6beacb81d0fc91d1a39a70bf9a6b1e686b24f4800b38512da89ee2b',
      threatType: 'phone_number',
      firstSeen: '2024-11-05T09:28:53.071Z',
      lastScan: '2024-11-05T09:37:14.445Z',
      status: 'not_applicable',
      sourceUrl: '+1 801 528 4405',
      timestamp: 1730799434445,
      disposition: 'malicious',
      category: 'tech_support_scam',
      count: 1,
      carrier: 'unavailable',
      location: 'unavailable',
    },
    {
      sha256: '764e220a7d3818e481783b70327e49b53bc1319ca2475bc5c6ed481d26982441',
      threatType: 'sender_domain',
      firstSeen: '2024-10-26T18:31:54.525Z',
      lastScan: '2024-10-28T14:05:10.987Z',
      status: 'active',
      sourceUrl: 'hxxps://www[.]mvborgesmanutencao[.]com[.]br',
      timestamp: 1730711270467,
      disposition: 'scam',
      category: 'online_store',
      count: 1,
    },
    {
      sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-04T17:31:49.936Z',
      status: 'active',
      sourceUrl: 'http://mybolbankmoney.com/',
      timestamp: 1725723497162,
      disposition: 'spam',
      category: 'traffic_stealer',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-09T19:23:52.085Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'captcha',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-04T17:00:26.344Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'captcha',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-08T13:00:08.100Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-08T21:42:48.808Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-10T09:13:10.840Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'captcha',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T12:58:44.057Z',
      status: 'active',
      sourceUrl: 'http://belbank.ru/',
      timestamp: 1725723497692,
      disposition: 'clean',
      category: 'captcha',
      count: 3,
      hostingCountry: 'NL',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-15T03:22:03.516Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-29T23:37:46.447Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'captcha',
      count: 9,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T23:36:14.408Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'captcha',
      count: 5,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-16T17:14:20.040Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'captcha',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T14:19:58.100Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'captcha',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: '24c7c8b7d506af17be5ee21599b7aff759ce36ae2514fc482012d65d7af034e3',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-26T07:20:25.664Z',
      status: 'active',
      sourceUrl: 'http://bombank.com/',
      timestamp: 1725723496880,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:33:59.548Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:33:59.548Z',
      status: 'active',
      sourceUrl: 'http://belbank.ru/',
      timestamp: 1725723497692,
      disposition: 'clean',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'NL',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T20:16:30.047Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'captcha',
      count: 5,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-23T09:58:17.928Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T07:13:16.296Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'captcha',
      count: 2,
      hostingCountry: 'US',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T19:35:57.597Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'unknown',
      count: 3,
      hostingCountry: '',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-04T10:31:50.011Z',
      status: 'in_progress',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:41:56.382Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'error_page',
      count: 3,
      hostingCountry: '',
    },
    {
      sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T09:01:58.143Z',
      status: 'active',
      sourceUrl: 'http://boblank.com/',
      timestamp: 1725723496794,
      disposition: 'suspicious',
      category: 'error_page',
      count: 8,
      hostingCountry: '',
    },
    {
      sha256: '24c7c8b7d506af17be5ee21599b7aff759ce36ae2514fc482012d65d7af034e3',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:44:03.873Z',
      status: 'active',
      sourceUrl: 'http://bombank.com/',
      timestamp: 1725723496880,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T04:54:16.697Z',
      status: 'active',
      sourceUrl: 'http://mybolbankmoney.com/',
      timestamp: 1725723497162,
      disposition: 'suspicious',
      category: 'unknown',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:43:27.037Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'error_page',
      count: 7,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-26T10:32:07.447Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'captcha',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T18:06:44.550Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-22T17:03:01.969Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'captcha',
      count: 28,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T07:30:55.685Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T18:05:22.007Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T11:34:17.991Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T11:22:35.505Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: '24c7c8b7d506af17be5ee21599b7aff759ce36ae2514fc482012d65d7af034e3',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-04T06:09:08.757Z',
      status: 'active',
      sourceUrl: 'http://bombank.com/',
      timestamp: 1725723496880,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-29T21:44:14.503Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-16T17:31:34.994Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-16T06:41:52.947Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-14T01:38:38.219Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 7,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T14:49:46.460Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 19,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-29T01:01:34.521Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-04T11:37:30.777Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'captcha',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-20T18:00:25.611Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'captcha',
      count: 8,
      hostingCountry: '',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-22T21:08:32.167Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'captcha',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-23T17:45:54.813Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'captcha',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:04:59.680Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:22:31.580Z',
      status: 'active',
      sourceUrl: 'http://boblank.com/',
      timestamp: 1725723496794,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:01:32.536Z',
      status: 'active',
      sourceUrl: 'http://mybolbankmoney.com/',
      timestamp: 1725723497162,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:59:46.767Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:22:58.409Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-30T08:12:16.217Z',
      status: 'active',
      sourceUrl: 'http://belbank.ru/',
      timestamp: 1725723497692,
      disposition: 'clean',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'NL',
    },
    {
      sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T21:44:02.804Z',
      status: 'active',
      sourceUrl: 'http://boblank.com/',
      timestamp: 1725723496794,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T07:17:26.344Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T19:46:19.145Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'captcha',
      count: 3,
      hostingCountry: '',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T12:29:49.311Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T09:16:27.686Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'captcha',
      count: 5,
      hostingCountry: 'US',
    },
    {
      sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T19:14:29.899Z',
      status: 'active',
      sourceUrl: 'http://belbank.ru/',
      timestamp: 1725723497692,
      disposition: 'clean',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'NL',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-26T16:55:34.101Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'captcha',
      count: 8,
      hostingCountry: 'US',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-21T01:51:48.017Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T15:11:44.220Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 6,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T01:53:09.101Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'unknown',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-29T02:53:23.144Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'captcha',
      count: 2,
      hostingCountry: 'US',
    },
    {
      sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-04T11:30:02.389Z',
      status: 'in_progress',
      sourceUrl: 'http://boblank.com/',
      timestamp: 1725723496794,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-04T10:33:28.043Z',
      status: 'in_progress',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T08:50:15.258Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 4,
      hostingCountry: '',
    },
    {
      sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-26T14:55:21.880Z',
      status: 'active',
      sourceUrl: 'http://mybolbankmoney.com/',
      timestamp: 1725723497162,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-27T09:18:24.863Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T15:50:29.190Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'unknown',
      count: 18,
      hostingCountry: '',
    },
    {
      sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T08:48:17.818Z',
      status: 'active',
      sourceUrl: 'http://blobank.ru/',
      timestamp: 1725723496521,
      disposition: 'clean',
      category: 'unknown',
      count: 1,
      hostingCountry: 'US',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:00:27.442Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'unknown',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T13:52:40.091Z',
      status: 'active',
      sourceUrl: 'http://bankofbolstermoney.com/',
      timestamp: 1725723496728,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:20:55.237Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:17:39.404Z',
      status: 'active',
      sourceUrl: 'http://belbank.ru/',
      timestamp: 1725723497692,
      disposition: 'clean',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: 'NL',
    },
    {
      sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T12:12:12.889Z',
      status: 'active',
      sourceUrl: 'http://bankofbolsterscam.com/',
      timestamp: 1725723496216,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:21:52.183Z',
      status: 'active',
      sourceUrl: 'http://mybolbankcard.net/',
      timestamp: 1725723497936,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T08:52:28.635Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T19:14:57.072Z',
      status: 'active',
      sourceUrl: 'http://oblbank.com/',
      timestamp: 1725723497461,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T08:50:07.017Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 2,
      hostingCountry: '',
    },
    {
      sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T08:38:19.422Z',
      status: 'active',
      sourceUrl: 'http://boblank.com/',
      timestamp: 1725723496794,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T14:39:04.251Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'captcha',
      count: 4,
      hostingCountry: '',
    },
    {
      sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T02:42:16.120Z',
      status: 'active',
      sourceUrl: 'http://bolbankcard.com/',
      timestamp: 1725723497393,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T07:06:40.428Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 4,
      hostingCountry: '',
    },
    {
      sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-06T10:36:52.494Z',
      status: 'active',
      sourceUrl: 'http://boblank.com/',
      timestamp: 1725723496794,
      disposition: 'suspicious',
      category: 'sensitive_data',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T15:26:10.280Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
    {
      sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-11-05T07:16:18.330Z',
      status: 'active',
      sourceUrl: 'http://belbank.ru/',
      timestamp: 1725723497692,
      disposition: 'clean',
      category: 'captcha',
      count: 2,
      hostingCountry: 'NL',
    },
    {
      sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
      threatType: 'url',
      firstSeen: '2024-07-09T23:08:14.858Z',
      lastScan: '2024-10-28T14:11:33.522Z',
      status: 'active',
      sourceUrl: 'http://bolbank.org/',
      timestamp: 1725723496306,
      disposition: 'suspicious',
      category: 'unknown',
      count: 1,
      hostingCountry: '',
    },
  ],
};

// Modify based on the prod env's insights data
// const insights = [
//   {
//     sha256: 'edd05ca5819da6a55c776f0a8cf684a7c26817596010008dd6229ace4af85f8c',
//     timestamp: '1725723497162',
//     date: '20240907',
//     sourceUrl: 'http://mybolbankmoney.com/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: 'aeccfd66533527860938782412d512a6b9e7b2bfd8b147981fa652bf9428aa4c',
//     timestamp: '1725723497461',
//     date: '20240907',
//     sourceUrl: 'http://oblbank.com/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: '5fcc7bec00ade9dc8b8165317431a91e1b8f54f242d6e402f57aa58091962921',
//     timestamp: '1725723497936',
//     date: '20240907',
//     sourceUrl: 'http://mybolbankcard.net/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: '884a49cbe9856734e5a493d499ae017b94dab21a35d44e350d3931cb74e6930c',
//     timestamp: '1725723497692',
//     date: '20240907',
//     sourceUrl: 'http://belbank.ru/',
//     hostingCountry: 'NL',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'clean',
//   },
//   {
//     sha256: '629e29286e6fb4f7e0acfbe43734db7bf620ef19f1e8e5fb911680ec5d5ee5e2',
//     timestamp: '1725723496306',
//     date: '20240907',
//     sourceUrl: 'http://bolbank.org/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: 'f00d0df3cfda28442bbf743d19d89b0659b15d5f302d5c4a2dc04aa2ad05d47c',
//     timestamp: '1725723496521',
//     date: '20240907',
//     sourceUrl: 'http://blobank.ru/',
//     hostingCountry: 'US',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'clean',
//   },
//   {
//     sha256: '1e76bc8f2fa8bf25064ac021a855918d6e9c0e12979a016cd45e3c5ac04c2c3f',
//     timestamp: '1725723497393',
//     date: '20240907',
//     sourceUrl: 'http://bolbankcard.com/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: '467aaa7a56a00b48e927e3e69e3ab083c7a9b83721407a995a4d530885c331a6',
//     timestamp: '1725723496794',
//     date: '20240907',
//     sourceUrl: 'http://boblank.com/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: 'c553198ab39204fecd65caa2cc928ed59cb6161dd22305e79a4816a922a5787e',
//     timestamp: '1725723496216',
//     date: '20240907',
//     sourceUrl: 'http://bankofbolsterscam.com/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: 'd60da1ebacc0726d8750c8e2d88a227f4db86959637b48c3fd61f0460e4d04ba',
//     timestamp: '1725723496728',
//     sourceUrl: 'http://bankofbolstermoney.com/',
//     hostingCountry: '',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
//   {
//     sha256: '24c7c8b7d506af17be5ee21599b7aff759ce36ae2514fc482012d65d7af034e3',
//     timestamp: '1725723496880',
//     sourceUrl: 'http://bombank.com/',
//     hostingCountry: 'US',
//     firstSeen: '2024-07-09T23:08:14.858Z',
//     disposition: 'suspicious',
//   },
// ];

// const updatedThreatFindings = res.threatFindings.map(finding => {
//   if (finding.threatType === 'url') {
//     const randomInsight = insights[Math.floor(Math.random() * insights.length)];
//     return {
//       ...finding,
//       sha256: randomInsight.sha256,
//       timestamp: parseInt(randomInsight.timestamp),
//       sourceUrl: randomInsight.sourceUrl,
//       hostingCountry: randomInsight.hostingCountry,
//       firstSeen: randomInsight.firstSeen,
//       disposition: randomInsight.disposition,
//     };
//   }
//   return finding;
// });

// console.log(updatedThreatFindings);
