import React from 'react';
import { ABUSE_MAIL_BOX_MODULE_PATH_NAME, IThreatTypeDetails } from '../../constants';
import Country from '../../../Common/Country';
import { StatusLabels } from '../../../Common/statusLabels';
import { history, store } from '../../../../helpers';
import { setGoBackUrl } from '../../../../reducers/insightsContainer.reducer';
import { getDisplayTimeFromTimeStamp, setLocalStorageValue } from '../../../../constants';
import { getInsightsPathBasedOnThreatType } from '../../Insights/InsightsUtils';
import {
  ABUSE_MAILBOX_INSIGHTS_PARAMS,
  INSIGHTS_TYPE,
} from '../../TargetedMalicious/TargetedMaliciousColDefs';

export const TOP_5_SUSPICIOUS_URLS_TABLE_COL: {
  label: string;
  key: string;
  render?: (
    value: string,
    extraData: {
      timestamp?: string;
      sha256?: string;
      threatType: string;
      disposition: string;
    },
  ) => React.ReactNode;
}[] = [
  {
    label: 'Source URL',
    key: 'sourceUrl',
    render: (
      value: string,
      extraData: {
        timestamp?: string;
        sha256?: string;
        threatType: string;
        disposition: string;
      },
    ) => (
      <a
        onClick={() => {
          const insightsPath = getInsightsPathBasedOnThreatType(extraData as IThreatTypeDetails);
          setLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS, {
            insightsType: INSIGHTS_TYPE.TARGETED_MALICIOUS,
            disposition: extraData.disposition,
            threatType: extraData.threatType,
          });
          history.push(insightsPath);
          store.dispatch(setGoBackUrl(`${ABUSE_MAIL_BOX_MODULE_PATH_NAME}/dashboard`));
        }}
        className=' top-5-suspicious-urls-source-url '
        title={value}
      >
        {value}
      </a>
    ),
  },
  { label: 'Report Count', key: 'count' },
  { label: 'Hosting Provider', key: 'hostingProvider' },
  {
    label: 'First Seen',
    key: 'firstSeenTargeted',
    render: (value: string) => {
      return getDisplayTimeFromTimeStamp(value, 'MM/DD/YYYY');
    },
  },
  {
    label: 'Last Seen',
    key: 'lastSeenTargeted',
    render: (value: string) => {
      return getDisplayTimeFromTimeStamp(value, 'MM/DD/YYYY');
    },
  },
  {
    label: 'Hosting Country',
    key: 'hostingCountry',
    render: (value: string) => {
      return <Country countryCode={value} />;
    },
  },
  {
    label: 'Status',
    key: 'status',
    render: (value: string) => <StatusLabels status={value} />,
  },
];

export const TOP_5_PHONE_NUMBER_TABLE_COL: {
  label: string;
  key: string;
  render?: (
    value: string,
    extraData?: { sha256: string; timestamp: string; threatType: string; disposition: string },
  ) => React.ReactNode;
}[] = [
  {
    label: 'Phone Number',
    key: 'name',
    render: (
      value: string,
      extraData?: { sha256: string; timestamp: string; threatType: string; disposition: string },
    ) => (
      <a
        className=' top-5-suspicious-urls-source-url '
        onClick={() => {
          setLocalStorageValue(ABUSE_MAILBOX_INSIGHTS_PARAMS, {
            insightsType: INSIGHTS_TYPE.TARGETED_MALICIOUS,
            disposition: extraData?.disposition,
            threatType: extraData?.threatType,
          });
          history.push(
            `${ABUSE_MAIL_BOX_MODULE_PATH_NAME}/insights/threatTypes/phone_number/${extraData?.sha256}`,
          );
          store.dispatch(setGoBackUrl(`${ABUSE_MAIL_BOX_MODULE_PATH_NAME}/dashboard`));
        }}
      >
        {value}
      </a>
    ),
  },
  { label: 'Report Count', key: 'count' },
  {
    label: 'First Seen',
    key: 'firstSeenTargeted',
    render: (value: string) => {
      return getDisplayTimeFromTimeStamp(value, 'MM/DD/YYYY');
    },
  },
  { label: 'Carrier', key: 'carrier' },
  { label: 'Location', key: 'location' },
];
