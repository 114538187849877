import React, { useMemo, useState } from 'react';
import { AM_BUTTON_LABEL_MAP } from '../../constants';
import { DashboardData } from '../../constants';
import { TakedownTimeAnalyticsNoDataView } from './TakedownTimeAnalyticsNoDataView';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { getDayOrDaysBasedOnValue } from './DashboardUtils';

export function TakedownTimeAnalytics({ dashboardData }: { dashboardData: DashboardData }) {
  const [activated, setActivated] = useState<'phish' | 'scam'>('phish');
  const takedownTimeAnalytics = dashboardData.takedownTimeAnalytics;
  const averageTime = parseFloat(takedownTimeAnalytics[activated].avg);
  const medianTime = parseFloat(takedownTimeAnalytics[activated].median);
  const dayAggregations = useMemo(
    () => takedownTimeAnalytics[activated].dayAggregations || {},
    [takedownTimeAnalytics, activated],
  );
  const datasetBase = useMemo(
    () => Array.from({ length: 31 }, (_, index) => ({ idx: `${index + 1}`, value: 0 })),
    [],
  );
  const dataset = useMemo(
    () =>
      datasetBase.map(item => {
        if (dayAggregations[item.idx]) {
          return {
            ...item,
            value: dayAggregations[item.idx],
          };
        }
        return item;
      }),
    [datasetBase, dayAggregations],
  );

  const shouldDisplayNoDataView = (data: { dayAggregations?: Record<string, number> }) => {
    // If the dayAggregations is empty, there should be no average and median time as well, or it should be a data side bug
    const { dayAggregations } = data;
    return !dayAggregations || Object.keys(dayAggregations).length === 0;
  };

  return (
    <div className='takedown-time-analytics'>
      <div className='phish-scam-header'>
        <div className='shared-titles-for-cards'>Takedown Time Analytics</div>
        <div className='phish-scam-header-buttons'>
          {(['phish', 'scam'] as const).map((label, index) => (
            <button
              key={index}
              className={`phish-scam-buttons ${activated === label ? 'active' : ''}`}
              onClick={() => setActivated(label)}
            >
              {AM_BUTTON_LABEL_MAP[label]}
            </button>
          ))}
        </div>
      </div>
      {!shouldDisplayNoDataView(takedownTimeAnalytics[activated]) && (
        <div className='average-median-time'>
          The <b>Average Takedown Time</b> for {AM_BUTTON_LABEL_MAP[activated]} URLs is{' '}
          <b>{averageTime}</b> {getDayOrDaysBasedOnValue(averageTime)}; The
          <b> Median Takedown Time</b> for {AM_BUTTON_LABEL_MAP[activated]} URLs is{' '}
          <b>{medianTime}</b> {getDayOrDaysBasedOnValue(medianTime)}.
        </div>
      )}
      {shouldDisplayNoDataView(takedownTimeAnalytics[activated]) ? (
        <TakedownTimeAnalyticsNoDataView />
      ) : (
        <>
          <div className='bar-container'>
            <BarChart
              dataset={dataset}
              series={[
                {
                  dataKey: 'value',
                  label: 'Takedowns',
                  color: activated === 'scam' ? '#0A89FF' : '#66BFFF',
                },
              ]}
              xAxis={[
                {
                  dataKey: 'idx',
                  scaleType: 'band',
                  tickLabelStyle: {
                    fill: '#555E68',
                  },
                  valueFormatter: (value, context) => {
                    const val = value === '31' ? '31+' : value;
                    return context.location === 'tick'
                      ? val
                      : `${val} ${getDayOrDaysBasedOnValue(parseInt(val))}`;
                  },
                },
              ]}
              yAxis={[
                {
                  tickLabelStyle: {
                    fill: '#555E68',
                  },
                },
              ]}
              grid={{ horizontal: true }}
              slotProps={{
                legend: {
                  hidden: true,
                },
              }}
              sx={theme => ({
                [`.${axisClasses.root}`]: {
                  [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                    strokeWidth: 0,
                  },
                },
              })}
            ></BarChart>
          </div>
        </>
      )}
    </div>
  );
}
