import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import './Style/AcquisitionsComponent.scss';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import DashboardService from '../../services/dashboard.service';
import { ACQUISITION_TABLE_ID, DASHBOARD_NAME } from './constants';
import { TExportTable } from '../Common/Table/constant';
import {
  DOMAIN_COLUMN,
  URL_CONSTRUCTION_ACQUISITION_COLUMN,
  TLD_COLUMN,
  PRIORITY_COLUMN,
  PRICE_COLUMN,
  REGION_COLUMN,
  BRAND_COLUMN,
} from './ColDefinition/malicious.columns';
import RequestPurchaseModal from './RequestPurchaseModal';
import { PageTitle } from '../Common/PageTitle';
import AddCreditModal from '../Common/AddCreditModal';
import { connect } from 'react-redux';
import AssetsService from '../../services/assests.service';
import { alertActions } from '../../actions';
import {
  AlertActionsTypes,
  appConstants,
  featureIsAvailable,
  isBrandReadOnlyUser,
  makeDefaultCol,
  numberWithCommas,
  ThemeModes,
} from '../../constants';
import DangerCircleIcon from '../../assets/icons/DangerCircle.svg';
import { ITableDataColumn, TableData } from '../Common/Table/table.data';
import { TableContextProvider } from '../Common/Table/table.context';
import { getCSVFile } from '../Assets_v2/util';
import Slider from './Components/Slider';
import { ResetFilterIcon } from '../../assets/SVGIcons';
import { AppState } from '../../helpers';
import { useAppDispatch } from '../../helpers/hooks';
import { setIsAgGridClientMode } from '../../reducers/table.reducer';
import { TABLE_EMPTY_RESULTS_MESSAGE } from '../Common/Table/ag-table/constants';
import { Button } from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

const dashboardService = new DashboardService();
const assetsService = new AssetsService();

const COLUMNS = [
  {
    ...DOMAIN_COLUMN,
    id: 'domain',
    accessor: 'domain',
    header: 'Domain',
    isDefaultSort: false,
    disableForSelection: true,
  },
  { ...PRIORITY_COLUMN, isDefaultSort: true, defaultSortDirection: 'asc', header: 'Priority' },
  { ...URL_CONSTRUCTION_ACQUISITION_COLUMN, header: 'URL Construction' },
  { ...PRICE_COLUMN, header: 'Price' },
  { ...REGION_COLUMN, header: 'Region' },
  { ...TLD_COLUMN, id: 'tld' },
  {
    ...BRAND_COLUMN,
    accessor: 'sub_brand_id',
    render: (data: any) => {
      return data.sub_brand_display_name;
    },
  },
];
const priorities = [1, 2, 3];

interface IAcquisitionsProps {
  alertCustom: (domElement: any) => AlertActionsTypes;
  brandInfo: any;
  user?: any;
}
const Acquisitions = ({ alertCustom, brandInfo, user }: IAcquisitionsProps) => {
  window.document.title = 'Monitor for Acquisitions | Web';
  const exportCSVTitle = 'Bolster_Domain_Acquisitions_Candidates';
  const DEFAULT_COLUMN_IDS = ['domain', 'acquisition_priority', 'estimated_price', 'region', 'TLD'];
  if (brandInfo?.brand?.brandMapping) {
    makeDefaultCol(DEFAULT_COLUMN_IDS, 'sub_brand_id', brandInfo?.brand?.brandMapping);
  }

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [purchasedDomains, setPurchasedDomains] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [requestRequestPurchaseModalShown, setRequestPurchaseModalShown] = useState<boolean>(false);
  const [availableCredit, setAvailableCredit] = useState<number>(0);
  const [showAddCredit, setShowAddCredit] = useState(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, []);

  const isOldTable = !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);
  const totalPrice = _.reduce(
    selectedItems,
    (sum, i) => {
      return sum + i.estimated_price;
    },
    0,
  );

  const prioritizedDomains = _.map(priorities, (p: number) =>
    _.filter(purchasedDomains, ['acquisition_priority', p]),
  );
  const prioritizedDomainLengths = _.map(prioritizedDomains, (pd: any[]) => pd.length);
  const maxDomainsLength = _.max(prioritizedDomainLengths) || 0;
  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([]);

  const { investmentAmount, numberOfDomains } = useMemo(() => {
    return {
      investmentAmount: _.reduce(
        prioritizedDomains,
        (sum, domains, index) => {
          let localSum = 0;
          for (let i = 0; selectedNumbers[index + 1] && i < selectedNumbers[index + 1]; i++) {
            localSum += domains[i]?.estimated_price || 0;
          }
          return sum + localSum;
        },
        0,
      ),
      numberOfDomains: _.sum(selectedNumbers),
    };
  }, [purchasedDomains, selectedNumbers]);

  useEffect(() => {
    fetchAcquisitionSummary();
    fetchDomainPurchase();
  }, []);

  useEffect(() => {
    const prioritizedDomains = _.map(priorities, (p: number) =>
      _.filter(purchasedDomains, ['acquisition_priority', p]),
    );
    const prioritizedDomainLengths = _.map(prioritizedDomains, (pd: any[]) => pd.length);
    setSelectedNumbers([0, ...prioritizedDomainLengths]);
  }, [purchasedDomains]);

  const fetchAcquisitionSummary = () => {
    assetsService
      .getDomainAcquisitionSummary()
      .then(res => {
        setAvailableCredit(parseInt(res?.creditBalance, 10) || 0);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchDomainPurchase = () => {
    setShowLoader(true);
    dashboardService
      .getDataForDomainPurchasing({ type: ACQUISITION_TABLE_ID })
      .then((res: any) => {
        const orderedData = _.chain(res?.result?.domainInfo || [])
          .orderBy(['priority', 'estimated_price', 'domain'], ['asc', 'asc', 'asc'])
          .map((domain: any) => {
            return {
              ...domain,
              _index: domain?.domain_sha256,
              sub_brand_id: domain.sub_brand_display_name.toLowerCase(),
            };
          })
          .valueOf();
        setPurchasedDomains(orderedData);
        if (res.result.total === 0) {
          dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
        }
      })
      .catch(error => {
        console.log(error, 'ERROR');
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const exportApi = (data: any, columns: ITableDataColumn[], type: TExportTable) => {
    getCSVFile(data, columns, exportCSVTitle);
  };

  const onSliderChange = (value: number, priority: number) => {
    const newNumbers = selectedNumbers.concat();
    newNumbers[priority] = value;
    setSelectedNumbers(newNumbers);
  };

  const onReset = () => {
    setSelectedItems([]);
    setSelectedNumbers([]);
    setPurchasedDomains([]);
    fetchDomainPurchase();
  };
  const onApply = () => {
    let items: any[] = [];
    _.forEach(prioritizedDomains, (domains, index) => {
      items = items.concat(...domains.slice(0, selectedNumbers[index + 1]));
    });

    setSelectedItems(items);
  };

  const onItemCheck = (checked: boolean, item: any, selectedItems: any) => {
    setSelectedItems(selectedItems);
  };

  const onClickPurchase = useCallback(() => {
    const totalPrice = _.reduce(
      selectedItems,
      (sum, i) => {
        return sum + i.estimated_price;
      },
      0,
    );
    const isSufficientCredit = availableCredit >= totalPrice;
    if (isSufficientCredit) {
      togglePurchaseModal();
    } else {
      alertCustom(
        <div className='d-flex'>
          <img src={DangerCircleIcon} alt={'Danger Circle Icon'} className={'alert-status-icon'} />
          Insufficient Credit. Please
          <Button
            variant='contained'
            className='text-link add-credit-link ml-2'
            onClick={() => {
              setShowAddCredit(true);
            }}
          >
            Add Credit
          </Button>
        </div>,
      );
    }
  }, [selectedItems, availableCredit]);

  const togglePurchaseModal = () => {
    setRequestPurchaseModalShown(!requestRequestPurchaseModalShown);
  };

  const onModalSubmit = () => {
    togglePurchaseModal();
    setSelectedItems([]);
    setSelectedNumbers([]);
    setPurchasedDomains([]);
    fetchDomainPurchase();
    fetchAcquisitionSummary();
  };

  const onSubmitAddCredit = (data: any): Promise<void> => {
    return assetsService.addCredit(data).finally(() => {
      setShowAddCredit(false);
    });
  };

  return (
    <div className={'acquisitions-page'}>
      <PageTitle title={'Monitor for Acquisitions'} />
      <div className={'recommended-domains-section'}>
        <div className={'left'}>
          <div className={'title'}>
            Recommended Domains to Acquire
            {/* TODO: wait until Alain provides more info */}
            {/* <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip
                  id={'tooltip-toggle-logo-detection'}
                  className={'table-source-url-tooltip'}
                >
                  Top 3000 domains suggested to acquire.
                </Tooltip>
              }
            >
              <img src={Info} alt={'info'} />
            </OverlayTrigger> */}
          </div>
          <div className={'description'}>
            Order of domain purchase is from high priority to lower priority. Drag a handle to
            adjust domain number per priority type.
          </div>

          {showLoader ? (
            <div className={'flex-center spinner-container'}>
              <Spinner className='spinner' animation='border' variant='primary' />
            </div>
          ) : (
            <div className={'sliders'}>
              {_.map(priorities, (p, index) => (
                <Slider
                  key={p}
                  themColorName={`theme-color-priority-${p}`}
                  domains={prioritizedDomains[index]}
                  maxDomainsLength={maxDomainsLength}
                  priority={p}
                  onChange={onSliderChange}
                />
              ))}
            </div>
          )}
        </div>
        <div className={'right'}>
          <div className={'credit'}>
            Available Credit: <b>{`$${numberWithCommas(availableCredit)}`}</b>
          </div>
          <Form.Group>
            <Form.Label>Investment Amount</Form.Label>
            <Form.Control value={`$${numberWithCommas(investmentAmount)}`} disabled />
          </Form.Group>
          <Form.Group>
            <Form.Label>Number of Domains </Form.Label>
            <Form.Control value={`${numberWithCommas(numberOfDomains)}`} disabled />
          </Form.Group>
          <div className={'buttons'}>
            <div
              className={'reset-filter-wrapper'}
              onClick={onReset}
              id='reset-domains-acquire-filter'
            >
              <RefreshOutlinedIcon />
            </div>
            <Button variant='outlined' onClick={onApply} id='apply-domains-acquire-filter'>
              Apply
            </Button>
          </div>
        </div>
      </div>
      <div className={'table-page'}>
        <Card className='table-container'>
          <TableContextProvider
            columns={COLUMNS}
            dashboardName={DASHBOARD_NAME}
            tableId={ACQUISITION_TABLE_ID}
            tableIndex={'brand_matrix_domain_pricing'}
          >
            <TableData
              id={ACQUISITION_TABLE_ID}
              tableClassName='tool-bar-container-padding'
              title=''
              titleComp={
                <div className={'text-medium'}>
                  Selected domains for purchase: <b>{numberWithCommas(selectedItems.length)}</b>
                  <span className={'px-2 text-dark-grey-2'}>|</span>
                  Investment Amount: <b>{`$${numberWithCommas(totalPrice)}`}</b>
                </div>
              }
              columns={COLUMNS}
              disableDatePicker
              exportFn={exportApi}
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              tableIndex={'brand_matrix_domain_pricing'}
              tileTopThreeProps={['domain', 'estimated_price', 'acquisition_priority']}
              indexBy={'domain_sha256'}
              defaultColumnIds={DEFAULT_COLUMN_IDS}
              enableCheckbox
              data={purchasedDomains}
              columnsNotEditable={false}
              selectedItems={selectedItems}
              onItemCheck={onItemCheck}
              showLoader={showLoader}
              disablePagination={true}
              customTools={
                !isBrandReadOnlyUser(user) && [
                  <Button
                    variant='text'
                    className='text-link add-credit-link'
                    key='add-credit'
                    onClick={() => {
                      setShowAddCredit(true);
                    }}
                    id='add-credit-for-purchase'
                  >
                    Add Credit
                  </Button>,
                  <Button
                    variant='contained'
                    disabled={selectedItems.length === 0}
                    key='purchase'
                    className='ml-3'
                    onClick={onClickPurchase}
                    id='purchase-domains'
                  >
                    Purchase
                  </Button>,
                ]
              }
              user={user}
              isOldTable={isOldTable}
            />
          </TableContextProvider>
        </Card>
      </div>
      <RequestPurchaseModal
        data={selectedItems}
        show={requestRequestPurchaseModalShown}
        availableCredit={availableCredit}
        onCloseModal={togglePurchaseModal}
        onModalSubmit={onModalSubmit}
      />
      <AddCreditModal
        show={showAddCredit}
        onModalSubmit={onSubmitAddCredit}
        onCloseModal={() => setShowAddCredit(false)}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};
const mapDispatchToProps = {
  alertCustom: alertActions.custom,
};

const connectedAcquisitions = connect(mapStateToProps, mapDispatchToProps)(Acquisitions);
export { connectedAcquisitions as Acquisitions };
