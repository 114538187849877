import React, { useEffect, useState } from 'react';
import { EmailDetailsParams } from '../constants';
import './ThreatTypeInsights.scss';
import { Card } from '@mui/material';
import { ALL_EMAIL_SUBMISSION_SECTIONS } from '../AllEmailSubmissions/constants';

import { EmailDetailsHeaderSection } from './EmailDetailsHeaderSection';
import AllEmailUrlAnalysisSection from '../AllEmailSubmissions/AllEmailUrlAnalysisSection';
import AllEmailSenderDomainAnalysis from '../AllEmailSubmissions/AllEmailSenderDomainAnalysis';
import AllEmailAttachmentAnalysis from '../AllEmailSubmissions/AllEmailAttachmentAnalysis';
import AllEmailPhoneAnalysis from '../AllEmailSubmissions/AllEmailPhoneAnalysis';
import AllEmailIntentAnalysis from '../AllEmailSubmissions/AllEmailIntentAnalysis';
import AllEmailScreenShotAnalysis from '../AllEmailSubmissions/AllEmailScreenShotAnalysis';
import _ from 'lodash';

export const EmailDetailsAnalysisContainer = ({
  abuseMailBoxEmailDetails,
}: {
  abuseMailBoxEmailDetails: EmailDetailsParams;
}) => {
  const [activeSection, setActiveSection] = useState<string>(ALL_EMAIL_SUBMISSION_SECTIONS[0].id);
  const [mannualScrollTriggred, setMannualScrollTriggered] = useState<boolean>(false);

  useEffect(() => {
    const container = document.getElementById('section-wrap');
    if (!container) return;

    const sections = Array.from(container.querySelectorAll('.section-header'));
    if (sections.length === 0) return;

    let lastScrollTop = container.scrollTop;

    const handleScroll = () => {
      if (mannualScrollTriggred) return;
      const scrollBlock = container.scrollHeight > 1000 ? 40 : 25;
      const scrollTop = container.scrollTop;

      lastScrollTop = scrollTop;
      let activeSection = Math.floor(scrollTop / scrollBlock);
      if (activeSection <= 1) activeSection = 1;
      if (activeSection >= 6) activeSection = 6;
      setActiveSection(ALL_EMAIL_SUBMISSION_SECTIONS[activeSection - 1]?.id);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [mannualScrollTriggred]);

  const onSectionSelect = (id: string) => {
    setMannualScrollTriggered(true);
    setActiveSection(id);
    const section = document.getElementById(id);
    if (section && section['scrollIntoView']) {
      section.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        setMannualScrollTriggered(false);
      }, 1000);
    }
  };

  return (
    <div className='emailDetails-analysis-container' data-testid='email-details-analysis-wrap'>
      <EmailDetailsHeaderSection
        abuseMailBoxEmailDetails={abuseMailBoxEmailDetails}
        activeSection={activeSection}
        onSectionSelect={onSectionSelect}
      />
      <Card id='section-wrap' className='section-wrap' data-testid={'section-wrap'}>
        <AllEmailUrlAnalysisSection
          urlAnalysisData={abuseMailBoxEmailDetails.urlAnalysis}
          key='url-section'
        />
        <AllEmailSenderDomainAnalysis
          senderDomainData={abuseMailBoxEmailDetails.senderDomainAnalysis}
        />
        <AllEmailAttachmentAnalysis
          attachmentAnalysisData={abuseMailBoxEmailDetails.attachmentAnalysis}
        />
        <AllEmailPhoneAnalysis phoneNumberAnalysis={abuseMailBoxEmailDetails.phoneNumberAnalysis} />
        <AllEmailIntentAnalysis intentAnalysisData={abuseMailBoxEmailDetails.contentAnalysis} />
        <AllEmailScreenShotAnalysis
          emailScreenshotUrl={abuseMailBoxEmailDetails.screenShot}
          attachments={abuseMailBoxEmailDetails.attachmentAnalysis?.attachments}
        />
      </Card>
    </div>
  );
};
