import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './custom.ag-grid.scss';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GetRowIdParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  FilterChangedEvent,
  IServerSideSelectionState,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { convertToFetchUgcApiParams, getAgGridThemeClassName } from './ag-utils';
import { fetchUGCData, massageUGCData } from './ag-requests';
import {
  appConstants,
  getLocalStorageValue,
  isBrandReadOnlyUser,
  setLocalStorageValue,
  featureIsAvailable,
} from '../../../../constants';
import { AgGridColumnType } from './interfaces';
import { TableContext } from '../table.context';
import {
  setOutGoingPersistentFilterString,
  setIsPersistentFilterSaveButtonVisible,
  setTableTotalRows,
} from '../../../../reducers/table.reducer';
import { FINDINGS_TABLE_IDS } from '../../../MonitorAndTakedown/constants';

import { alertActions, dashboardActions } from '../../../../actions';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { EFindingStatus } from '../../../Ugc/Types/ugc.types';
import { generateUGCColumnDefs } from './ag-col-defs';
import { ITableApiColumn } from '../table.api';
import { useSearchTermFilters } from './useSearchTermFilters';
import { usePersistentTablePageNumber } from './usePersistentTablePageNumber';
import useOnCustomTagActions from '../../CustomHooks/useOnCustomTagActions';
import useOnAgGridRefresh from '../../CustomHooks/useOnAgGridRefresh';
import useOnTagFilterOptions from '../../CustomHooks/useOnTagFilterOptions';
import { useAgGridEvents } from './useAgGridEvents';
import { useAgOnFilterClick } from './useAgOnFilterClick';
import { TABLE_EMPTY_RESULTS_MESSAGE, paginationPageSizeSelector } from './constants';
import { useAgGridFeaturesConfigs } from './useAgGridFeaturesConfigs';
import { useAgGridCurrentPageRows } from './useAgGridCurrentPageRows';
import useOnAgRowHighlight from './useOnAgRowHighlight';
import { useOnSelectionChangeEvent } from './useOnSelectionChangeEvent';
import { useUpdateTagsFromBulkAction } from './useUpdateTagsFromBulkAction';
import useAgGridFilterChange from './useAgGridFilterChange';
import { useNumOfCategoryFltrConditions } from './useNumOfCategoryFltrConditions';
import useOnStoringPaginationControls from '../../CustomHooks/useOnStoringPaginationControls';
import { UGC_SCREEN_SHOT_COLUMN } from '../../../Ugc/Components/Findings/TableColDef/FindingsTableCols';
import _ from 'lodash';
import useIsDarkMode from '../../CustomHooks/useIsDarkMode';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052170}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bolster.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bolster}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bolster}_need_to_be_licensed___{Bolster}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{13_December_2024}____[v3]_[0102]_MTczNDA0ODAwMDAwMA==87ad2987761e8e699f35e3905c9c185b`,
);

const AgGridServerModeTable = ({
  columns,
  tableId,
  moduleType,
  ugcStatus,
}: {
  columns: ITableApiColumn[];
  tableId: FINDINGS_TABLE_IDS;
  moduleType: string;
  ugcStatus?: EFindingStatus;
}) => {
  const [allRowData, setAllRowData] = useState<object[]>([]);
  const [totalNumOfNodes, setTotalNumOfNodes] = useState<number>(0);
  const agRef = useRef<AgGridReact>(null);
  const [, selectedTheme] = useIsDarkMode();
  const { displayedColumnsIDs, onTableSaveColumns } = useContext(TableContext);

  const firstRenderDisplayColumnIdSet = useMemo<Set<string>>(() => {
    return new Set(displayedColumnsIDs);
  }, [displayedColumnsIDs]);

  const dispatch = useAppDispatch();

  const {
    handleFirstDataRendered,
    handleOnPaginationChanged,
    currentPageNumber,
    currentPaginationSize,
  } = usePersistentTablePageNumber(tableId);

  const isAppStore = useMemo(
    () => moduleType === appConstants.CONTENT_TYPE.APP_STORE,
    [moduleType],
  );

  useSearchTermFilters({
    agRef,
    columns,
    colFieldForSearchTerm: isAppStore ? 'search_term' : 'search_term_labels',
  });

  const { sideBarConfigs, columnHeaderMenuItems, onHandleGridSizeChanged, localeText } =
    useAgGridFeaturesConfigs({ tableId });
  const { numOfOtherCategories } = useAppSelector(state => state.tableReducer);

  const { savedNumOfCategoryFltrConditions } = useNumOfCategoryFltrConditions({ tableId });

  const user = useAppSelector(state => state.dashboardReducer.user);
  const colDefs: ColDef[] = useMemo<ColDef[]>(() => {
    // TODO: remove feature check after testing
    if (
      moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
      moduleType === appConstants.CONTENT_TYPE.SOCIAL
    ) {
      const screenshotCol = _.find(columns, ['id', 'screenshot']);
      if (!screenshotCol && featureIsAvailable(user, appConstants.FEATURE_CODE.SCREENSHOT_COLUMN)) {
        columns.push(UGC_SCREEN_SHOT_COLUMN);
      }
    }

    return generateUGCColumnDefs(
      columns,
      firstRenderDisplayColumnIdSet,
      moduleType,
      tableId,
      savedNumOfCategoryFltrConditions,
    );
    // We have to add displayedColumnsIDs here, to update colDefs with latest visible columns
  }, [columns, moduleType, displayedColumnsIDs, savedNumOfCategoryFltrConditions, user]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      menuTabs: ['columnsMenuTab'],
      mainMenuItems: columnHeaderMenuItems,
    };
  }, []);

  const isFilterModelCacheUsed = useRef(false);

  const currentAppliedPersistentFilter = useAppSelector(
    state => state.tableReducer.currentAppliedPersistentFilter,
  );

  const { handlePaginationControls } = useOnStoringPaginationControls({ tableId });

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        // console.log('[Datasource] - rows requested by grid: ', params);
        const agRequest: IServerSideGetRowsRequest = params.request;
        const response: { data: object[]; total?: number } = { data: [], total: 0 };

        try {
          const pageSize = params.api?.paginationGetPageSize();
          const pageNumber = params.api?.paginationGetCurrentPage?.() || 0;
          const apiParams = convertToFetchUgcApiParams(agRequest, pageSize, ugcStatus, moduleType);

          const rawResponse = await fetchUGCData(apiParams, moduleType);
          response.data = massageUGCData(rawResponse.findings, moduleType);
          response.total = rawResponse.metadata?.total;
          if (response.total === 0) {
            dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
          }

          // Store the current table settings for insights pagination
          setLocalStorageValue(['tableApiParams', tableId], apiParams);

          handlePaginationControls(pageNumber, pageSize, response.total as number, response.data);
          setLocalStorageValue(['currentTableId'], tableId); // set the current table id

          setAllRowData(response.data);
          setTotalNumOfNodes(response.total as number);
          setTimeout(() => {
            params.success({
              rowData: response.data,
              rowCount: response.total,
            });
          }, 0);
        } catch (error) {
          params.fail();
        }
      },
    };
  }, [moduleType, ugcStatus]);

  const { currentRowsOfPage } = useAgGridCurrentPageRows({
    agRef,
    allRowData,
    currentPageNumber,
    currentPaginationSize,
  });

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      // first render, only initiate filter model from local  .
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource],
  );

  const getRowId = useCallback((params: GetRowIdParams) => {
    return `row-id-${params.data._id}-${params.data.primary_domain}-${params.data.domain_sha256}-${params.data.url_sha256}`;
  }, []);

  const {
    handleColumnVisible,
    handleColumnMoved,
    handleOnSortChanged,
    handleToolPanelVisibleChanged,
    handleColumnResized,
  } = useAgGridEvents({
    agRef,
    tableId,
    allRowData,
  });

  useEffect(() => {
    setLocalStorageValue(['savedSelectionState', tableId], []);
  }, []);

  const { handleOnSelectionChange } = useOnSelectionChangeEvent({
    agRef,
    tableId: tableId,
    allRowData: allRowData,
    moduleType: moduleType,
    currentRowsOfPage,
    currentPageNumber,
    currentPaginationSize,
  });

  const { updateTagsFromBulkAction } = useUpdateTagsFromBulkAction({ agRef });

  useEffect(() => {
    updateTagsFromBulkAction();
  }, [updateTagsFromBulkAction]);
  // setFindingsTableUrls to empty when unmount
  useEffect(() => {
    return () => {
      dispatch(dashboardActions.setFindingsTableUrls(tableId, [], moduleType));
      dispatch(setTableTotalRows(0));
      setLocalStorageValue(['savedSelectionState', tableId], []);
    };
  }, [dispatch, tableId, moduleType]);

  const { handleUGCModuleFilterChanged } = useAgGridFilterChange(agRef, tableId);

  useEffect(() => {
    if (agRef.current && agRef.current.api) {
      agRef.current.api.setFilterModel(currentAppliedPersistentFilter);
    }
  }, [currentAppliedPersistentFilter]);

  //Custom hooks for triggering fetch call on custom tag action
  useOnCustomTagActions(agRef);

  //custom hook to add new custom tag filter options
  useOnTagFilterOptions(agRef, columns, 'tags.id', moduleType);

  //Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(setIsPersistentFilterSaveButtonVisible(false));
      dispatch(setOutGoingPersistentFilterString('{}'));
    };
  }, [dispatch]);

  const { getRowClass } = useOnAgRowHighlight();

  //use to refresh the table when the triggerAgGridRefresh is true
  useOnAgGridRefresh(agRef);

  const isReadOnlyUser = isBrandReadOnlyUser(user);

  return (
    <div
      id='ag-table-container'
      data-testid='ag-table-container'
      className={getAgGridThemeClassName(selectedTheme)}
    >
      <AgGridReact<AgGridColumnType>
        ref={agRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        localeText={localeText}
        columnMenu={'new'}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={isAppStore ? paginationPageSizeSelector : false}
        cacheBlockSize={isAppStore ? currentPaginationSize : 15}
        blockLoadDebounceMillis={1000}
        onColumnVisible={handleColumnVisible}
        onColumnMoved={handleColumnMoved}
        onColumnResized={handleColumnResized}
        rowSelection={'multiple'}
        onSelectionChanged={handleOnSelectionChange}
        onFilterChanged={handleUGCModuleFilterChanged}
        suppressRowClickSelection={true}
        onFirstDataRendered={handleFirstDataRendered}
        onPaginationChanged={handleOnPaginationChanged}
        onSortChanged={handleOnSortChanged}
        sideBar={sideBarConfigs}
        onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
        overlayLoadingTemplate={'<div class="loading-wrapper-component spinner-border"></div>'}
        reactiveCustomComponents
        onGridSizeChanged={onHandleGridSizeChanged}
        getRowClass={getRowClass}
        suppressRowVirtualisation={true}
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default AgGridServerModeTable;
