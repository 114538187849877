import fetchApi from '../../../services/api.service';
import { handleResponse } from '../../../services/serviceWorker';

export interface IMaintenanceAlertResponse {
  id: number;
  applicationName: string;
  description: string;
  showMaintenanceBanner: boolean;
}

export const getMaintenanceAlertStatus = async (
  applicationType: string,
): Promise<IMaintenanceAlertResponse> => {
  const rawResponse = await fetchApi(
    `/platform-api/application-maintenance/v1/status/${applicationType}`,
  ).then(handleResponse);
  return rawResponse;
};
