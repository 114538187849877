import moment from 'moment';
import { ThemeModes, appConstants, getLocalStorageValue, generateId } from '../../../../constants';
import { FilterModel, IServerSideGetRowsRequest, ColumnState } from 'ag-grid-community';
import {
  transformWebTableFilterToApiParams,
  transformFilterToDarkWebApiFilterString,
  transformUgcFilterToApiParams,
  transformBrandPropertyFilterToApiParams,
  transformToGlobalWebTakeDownFilterToApiParams,
} from './ag-filter-to-api-params';
import { EFindingStatus, ugcType } from '../../../Ugc/Types/ugc.types';
import { IFilter } from '../constant';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { fetchUGCData, fetchWebTableData, massageUGCData } from './ag-requests';
import { mapTableIdToEnum } from '../../../MonitorAndTakedown/constants';
import DashboardService from '../../../../services/dashboard.service';
import { ETagsTypes, ITags } from '../../Tags/Tags';
import _ from 'lodash';
import {
  catergoryLabel,
  getDefaultUTCEndDate,
  getDefaultUTCStartDate,
  othersCategoryLabel,
} from './constants';
import { AdvancedFilterParams } from '../../../GlobalSearch/AdvancedFiltersUtils';
import { EWebModuleTabFilterFields } from '../../../GlobalSearch/GlobalSearchConstants';
import { EGTDTakedownStatus } from '../../../GlobalTakedown/GlobalTakeDownUtils';

export interface TableApiParams {
  must: object;
  mustNot: object;
  query: object;
  sortBy: object;
  primaryDomain?: string;
}

export interface UgcTableApiParams {
  filter: string;
  startDate: string;
  endDate: string;
  limit?: number;
  offset?: number;
  order?: string;
  total?: boolean;
  includeImages?: boolean;
}
export interface BrandPropertyTableApiParams {
  filter: string;
  limit: number;
  offset: number;
  order: string;
  total: boolean;
}

export interface DarkWebTableApiParams extends UgcTableApiParams {
  includeFields: string[];
}

export interface ReportsFilterParams {
  filterType: string;
  filterBy: string;
  filterMethod: string;
  filterValue: string;
}
export interface DarkWebTableApiV2Params {
  isEntity?: boolean;
  entity?: string[];
  isEmployee?: boolean;
  isOthers?: boolean;
  searchTerms?: string[];
  searchTermIds?: string[];
}
export const sortKeyMap = {
  country_code: 'countryCode',
  brand_logo_detected: 'brandLogoDetected',
  current_disposition: 'currentDisposition',
  scan_source: 'scanSource',
  takedowns: 'takeDownCount',
  TLD: 'tld',
  ip: 'ipAddress',
};
export const convertToFetchApiParams = (params: IServerSideGetRowsRequest): TableApiParams => {
  let apiParams = {
    must: {},
    mustNot: {},
    query: {},
  };
  if (params.filterModel) {
    apiParams = transformWebTableFilterToApiParams(params.filterModel);
  }
  const sortBy = {};
  if (params.sortModel && params.sortModel.length > 0) {
    let sortKey = params.sortModel[0].colId;
    // API: some columns sorting keys are using accessor, some are using id, need to map them for now.
    sortKey = sortKeyMap[sortKey] || sortKey;
    sortBy[sortKey] = params.sortModel[0].sort;
  } else {
    sortBy['first_seen_ts'] = 'desc';
  }
  // console.log('sortBy', sortBy);

  return { ...apiParams, sortBy };
};

export function transformTagObject(originalObj: any): any {
  const transformedObj = {
    ...originalObj,
    active: true,
    brandId: '',
  };

  return transformedObj;
}

export const convertToFetchUgcApiParams = (
  params: IServerSideGetRowsRequest,
  pageSize: number,
  status?: EFindingStatus,
  moduleType?: string,
): UgcTableApiParams => {
  const isAppStore = moduleType === appConstants.CONTENT_TYPE.APP_STORE;
  const apiParams: UgcTableApiParams = {
    filter: `status=in.(${status ? status.split('|').join(',') : ''})`,
    startDate: getDefaultUTCStartDate(),
    endDate: getDefaultUTCEndDate(),
    limit: isAppStore ? pageSize : 15,
    offset: 0,
    order: !isAppStore ? 'created_ts.desc.nullslast' : 'first_seen_ts.desc.nullslast', // sorting for ugc module
    total: true,
    includeImages: true,
  };
  const { filterModel, sortModel, startRow, endRow } = params;
  if (filterModel) {
    const activeFilter = transformUgcFilterToApiParams(filterModel, isAppStore);
    if (activeFilter.filter) apiParams.filter += '&' + activeFilter.filter;
    if (activeFilter.startDate) apiParams.startDate = activeFilter.startDate;
    if (activeFilter.endDate) apiParams.endDate = activeFilter.endDate;
  }

  if (sortModel && sortModel.length > 0) {
    const sortBy = sortModel[0];
    apiParams.order = `${sortBy.colId}.${sortBy.sort}.nullslast`;
  }
  if (startRow && endRow) {
    apiParams.offset = startRow;
  }
  return apiParams;
};

export const convertToFetchDarkWebApiParams = (
  params: IServerSideGetRowsRequest,
  limit: number,
  darkwebStatus?: EDarkWebFindingStatus,
  extraFilters?: DarkWebTableApiV2Params,
  additionalFields: string[] = [],
): DarkWebTableApiParams => {
  let filterQuery = `status=in.(${darkwebStatus})`;
  if (extraFilters) {
    const { isEntity, entity, searchTermIds } = extraFilters;
    if (isEntity) {
      filterQuery = `${filterQuery}&is_entity=eq.${isEntity}`;
    }
    if (entity) {
      filterQuery = `${filterQuery}&entities=in.(${entity.join(',')})`;
    }
    if (searchTermIds) {
      filterQuery = `${filterQuery}&search_term_id=in.(${searchTermIds.join(',')})`;
    }
  }
  const limitVal = limit ? limit : 15;
  const apiParams: DarkWebTableApiParams = {
    filter: filterQuery,
    startDate: getDefaultUTCStartDate(),
    endDate: getDefaultUTCEndDate(),
    limit: limitVal,
    offset: 0,
    order: 'platform_published_ts.desc.nullslast',
    total: true,
    includeFields: [
      'brand_id',
      'category_id',
      'category',
      'content_sha_256',
      'created_ts',
      'highlights',
      'keywords',
      'network',
      'platform_published_ts',
      'platform',
      'risk_level',
      'risk_score',
      'search_term_id',
      'search_term',
      'sha256',
      'source_specific_info.has_sensitive_data',
      'source_specific_info.description',
      'source',
      'status',
      'sub_category_id',
      'sub_category',
      'tags',
      'threat_actor',
      'title',
      'updated_ts',
      'source_specific_info.breach_description',
      ...additionalFields,
    ],
  };

  const { filterModel, sortModel, startRow, endRow } = params;
  if (filterModel) {
    const activeFilter = transformFilterToDarkWebApiFilterString(filterModel);
    if (activeFilter) apiParams.filter += '&' + activeFilter;
  }

  if (sortModel && sortModel.length > 0) {
    const sortBy = sortModel[0];
    apiParams.order = `${sortBy.colId}.${sortBy.sort}.nullslast`;
  }
  if (startRow && endRow) {
    apiParams.offset = startRow;
  }
  return apiParams;
};

// convert web module dashboard old IFilter array to AgGrid filterModel object
export function transformOldIFiltersToAgFilterModel(
  sourceArray: IFilter[],
  query: { startDate: string; endDate: string },
): FilterModel {
  const targetObj: FilterModel = {};
  const otherCategories = sourceArray
    .filter(ele => ele.otherFilterCategories)
    .flatMap(ele => ele.otherFilterCategories);
  sourceArray.forEach(item => {
    if (otherCategories && otherCategories?.length > 0 && item.filterBy.label === catergoryLabel) {
      const sourceKey = sourceArray[0].filterBy.value;
      const webOthercategoryConditions = otherCategories.map((item: any) => {
        return {
          filterType: 'text',
          type: item.value || '',
        };
      });
      targetObj[sourceKey] = {
        filterType: 'text',
        operator: 'OR',
        conditions: webOthercategoryConditions,
      };
    } else {
      let key = item.filterBy.value;
      const filterType = item.filterType === 'options' ? 'text' : item.filterType;
      // special key format
      if (key === 'brandLogoDetected') {
        key = 'brand_logo_detected';
      }
      targetObj[key] = {
        filterType,
        type: item.filterValue || '',
      };
    }
  });
  if (query) {
    targetObj['first_seen_ts'] = {
      dateFrom: moment(query.startDate).format('YYYY-MM-DD'),
      dateTo: moment(query.endDate).format('YYYY-MM-DD'),
      filterType: 'date',
      type: 'inRange',
    };
  }

  return targetObj;
}

export function transformOldIFiltersToUgcAgFilterModel(
  sourceArray: IFilter[],
  query: { startDate: string; endDate: string },
  type: string,
  label: string,
): FilterModel {
  const targetObj: FilterModel = {};
  let otherCategoryFilters: IFilter[] = [];
  if (label === othersCategoryLabel && type === ugcType.Social) {
    otherCategoryFilters = sourceArray.filter(ele => {
      return ele.filterBy.label === catergoryLabel;
    });
  }
  sourceArray.forEach(item => {
    if (
      otherCategoryFilters?.length > 0 &&
      type === ugcType.Social &&
      item.filterBy.label === catergoryLabel
    ) {
      let sourceKey: string | undefined;
      let sourceFilterType: string | undefined;
      const categoryConditions = otherCategoryFilters.map(item => {
        sourceKey = item.filterBy.value;
        sourceFilterType = item.filterType === 'options' ? 'text' : item.filterType;

        return {
          filterType: sourceFilterType,
          type: item.filterValue || '',
        };
      });
      if (sourceKey)
        targetObj[sourceKey] = {
          filterType: 'text',
          operator: 'OR',
          conditions: categoryConditions,
        };
    } else {
      const key = item.filterBy.value;
      const filterType = item.filterType === 'options' ? 'text' : item.filterType;
      targetObj[key] = {
        filterType,
        type: item.filterValue || '',
      };
    }
  });

  if (query) {
    targetObj[type === ugcType.Social ? 'created_ts' : 'first_seen_ts'] = {
      dateFrom: moment(query.startDate).utc().format(),
      dateTo: moment(query.endDate).utc().format(),
      filterType: 'date',
      type: 'inRange',
    };
  }

  return targetObj;
}

export async function getResponseAfterAgQueryParamsConversion(
  tableId: string,
  pageSize: number,
  pageNumber: number,
  moduleType?: string,
  sort?: any,
  ugcStatus?: EFindingStatus,
): Promise<{ data: any[]; total: number }> {
  const agGridFilter = getLocalStorageValue(['tableFilterModel', tableId]);
  const defaultSortKey =
    moduleType === appConstants.CONTENT_TYPE.APP_STORE ||
    moduleType === appConstants.CONTENT_TYPE.WEB
      ? 'first_seen_ts'
      : 'created_ts';

  const dashboardService = new DashboardService();
  if (agGridFilter) {
    const sortKey = sort?.['sortBy'] || defaultSortKey;
    const sortBy = {
      [sortKey]: sort?.['sortDirection'] || 'desc',
    };
    switch (moduleType) {
      case appConstants.CONTENT_TYPE.WEB:
        const convertedFilters = transformWebTableFilterToApiParams(agGridFilter);
        const { must, mustNot, query } = convertedFilters;
        const apiParams = {
          must,
          mustNot,
          query: {
            ...query,
            pageNumber,
            pageSize,
            type: tableId,
          },
          sortBy,
        };
        const res = await fetchWebTableData(apiParams, false);
        const response = { data: [], total: 0 };
        response.data = res.result?.urlInfo?.map((item: any) => {
          return dashboardService.massageDataCommon(item, mapTableIdToEnum[tableId]);
        });
        response.total = res.result?.total;
        return response;
      case appConstants.CONTENT_TYPE.APP_STORE:
      case appConstants.CONTENT_TYPE.SOCIAL:
      case appConstants.CONTENT_TYPE.MARKETPLACE:
        const apiUgcParams = transformUgcFilterToApiParams(
          agGridFilter,
          moduleType === appConstants.CONTENT_TYPE.APP_STORE,
        );

        const statusFilter = `status=in.(${ugcStatus ? ugcStatus.split('|').join(',') : ''})`;
        apiUgcParams.filter = `${statusFilter}${
          apiUgcParams.filter.length >= 1 ? '&' + apiUgcParams.filter : ''
        }`;

        const rawResponse = await fetchUGCData(
          {
            ...apiUgcParams,
            order: `${sortKey}.${sort?.['sortDirection'] || 'desc'}.nullslast`,
            limit: pageSize,
            offset: pageNumber * pageSize,
            total: true,
            includeImages: true,
          },
          moduleType,
        );
        return {
          data: massageUGCData(rawResponse.findings, moduleType),
          total: rawResponse.metadata.total,
        };
    }
  }

  return { data: [], total: 0 };
}

export const checkTagForFilterOptions = (tag: ITags, moduleType?: string) => {
  if (moduleType === appConstants.CONTENT_TYPE.WEB) {
    return tag.label !== 'Marked as Scam';
  } else {
    return tag.type !== ETagsTypes.BOLSTER_RESTRICTED;
  }
};

export const getSortModelFromLocalStorage = (
  tableId: string,
): { sortBy?: string; sortDirection?: 'asc' | 'desc' | null } => {
  const colState: ColumnState[] = getLocalStorageValue(['savedColumnState', tableId]);
  const currentSortCol: ColumnState[] = _.isArray(colState)
    ? colState.filter((col: any) => col.sort !== null)
    : [];
  const sortModel: { sortBy?: string; sortDirection?: 'asc' | 'desc' | null } = {};
  if (currentSortCol && currentSortCol.length > 0) {
    sortModel.sortBy = currentSortCol[0].colId;
    sortModel.sortDirection = currentSortCol[0].sort;
  }
  return sortModel;
};

export const convertToFetchApiReportsParams = (params: IServerSideGetRowsRequest) => {
  const sorting: { sortBy: string; sortDirection: string } = {
    sortBy: '',
    sortDirection: '',
  };
  const filters: ReportsFilterParams[] = [];
  if (params.filterModel) {
    filters.push(...transformReportFilters(params.filterModel));
  }

  if (params.sortModel && params.sortModel.length > 0) {
    let sortKey = params.sortModel[0].colId;
    // API: some columns sorting keys are using accessor, some are using id, need to map them for now.
    sortKey = sortKeyMap[sortKey] || sortKey;
    sorting.sortBy = sortKey;
    sorting.sortDirection = params.sortModel[0].sort;
  } else {
    sorting.sortBy = 'created_ts';
    sorting.sortDirection = 'desc';
  }
  return { filters, sorting };
};

export const transformReportFilters = (filters: FilterModel) => {
  const filterArray: ReportsFilterParams[] = [];
  const filterMethodMap: { [key: string]: string } = {
    contains: 'includes',
    notContains: 'notIncludes',
  };
  for (const key in filters) {
    const fObj = filters[key];
    filterArray.push({
      filterType: fObj.filterType === 'text' ? 'string' : '',
      filterBy: key,
      filterMethod: filterMethodMap[fObj.type] || '',
      filterValue: fObj.filter,
    });
  }
  return filterArray;
};

export const getAgGridThemeClassName = (selectedTheme: string) => {
  return selectedTheme === ThemeModes.LIGHT.toLowerCase()
    ? 'ag-theme-quartz'
    : 'ag-theme-quartz-dark';
};

export const convertToFetchBrandPropertyApiParams = (
  params: IServerSideGetRowsRequest,
  typeId: number,
): BrandPropertyTableApiParams => {
  const apiParams: BrandPropertyTableApiParams = {
    filter: `&type_id=eq.${typeId}`,
    limit: 15,
    offset: 0,
    order: 'created_ts.desc.nullslast',
    total: true,
  };
  const { filterModel, sortModel, startRow, endRow } = params;

  if (filterModel) {
    const activeFilter = transformBrandPropertyFilterToApiParams(filterModel);
    if (activeFilter.filter) apiParams.filter += '&' + activeFilter.filter;
  }

  if (sortModel && sortModel.length > 0) {
    const sortBy = sortModel[0];
    apiParams.order = `${sortBy.colId}.${sortBy.sort}.nullslast`;
  }
  if (startRow && endRow) {
    apiParams.offset = startRow;
  }
  return apiParams;
};

export interface GlobalWebTableParams {
  order: {
    field: string;
    order: 'asc' | 'desc';
  };
  pageSize: number;
  pageNumber: number;
  filter?: Array<{
    field: string;
    operator: string;
    value: string | Date | number | string[] | number[];
    isNot?: boolean;
  }>;
}

export const convertToFetchGlobalWebTableParams = (
  params: IServerSideGetRowsRequest,
  tableId: string,
): GlobalWebTableParams => {
  const apiParams: GlobalWebTableParams = {
    order: {
      field: 'latest_gtd_ts',
      order: 'asc',
    },
    pageSize: 0,
    pageNumber: 0,
  };

  if (params.sortModel && params.sortModel.length > 0) {
    const sort = params.sortModel[0];
    apiParams.order.field = sort.colId;
    apiParams.order.order = sort.sort as 'asc' | 'desc';
  }

  if (
    params.filterModel &&
    typeof params.filterModel === 'object' &&
    Object.keys(params.filterModel).length !== 0
  ) {
    const apiFilterParams = transformToGlobalWebTakeDownFilterToApiParams(
      params.filterModel,
      tableId,
    );
    apiParams.filter = [];
    apiParams.filter.push(...apiFilterParams);
  }
  return apiParams;
};

export const constructFilterForAgGridFilterModel = (
  searchTerm: string,
  fieldName: string,
  moduleType: string,
  currentTab?: string,
): FilterModel => {
  if (['tags', 'tags.id'].includes(fieldName)) {
    return {
      [fieldName]: {
        filterType: 'set',
        values: [searchTerm],
      },
    };
  }
  if (fieldName === 'ip') {
    return {
      [fieldName]: {
        filterType: 'text',
        type: 'Begins with',
        filter: searchTerm,
      },
    };
  }
  if (moduleType === appConstants.CONTENT_TYPE.APP_STORE) {
    if (fieldName === 'url') {
      return {
        ['src_url']: {
          filterType: 'text',
          type: 'contains',
          filter: searchTerm,
        },
      };
    }
  }

  if (
    (moduleType === appConstants.CONTENT_TYPE.WEB ||
      moduleType === appConstants.CONTENT_TYPE.TAKEDOWN_VISIBILITY_CENTER) &&
    currentTab === 'dst_url'
  ) {
    return {
      ['dst_url']: {
        filterType: 'text',
        type: 'contains',
        filter: searchTerm,
      },
    };
  }
  return {
    [fieldName]: {
      filterType: 'text',
      type: 'contains',
      filter: searchTerm,
    },
  };
};

export const constructFilterForBulkScanTable = (searchTerm: string, fieldName: string) => {
  const bulkScanfilters: IFilter[] = [];

  if (fieldName === 'ip') {
    bulkScanfilters.push({
      id: generateId(10),
      filterType: 'ipv4',
      filterBy: {
        label: 'IP Address',
        value: 'ipAddress',
      },
      filterMethod: {
        label: 'Begins with',
        value: 'beginWith',
      },
      filterValue: searchTerm,
      filterErr: '',
    });
  }

  if (fieldName === 'src_url') {
    bulkScanfilters.push({
      id: generateId(10),
      filterType: 'string',
      filterBy: {
        label: 'Source URL',
        value: 'url' || '',
      },
      filterMethod: {
        label: 'Includes',
        value: 'includes',
      },
      filterValue: searchTerm,
      filterErr: '',
    });
  }
  return bulkScanfilters;
};

export const constructFilterForAgGridAssetFilterModel = (
  searchTerm: string,
  fieldName: string,
  moduleType: string,
): FilterModel => {
  if (
    moduleType === appConstants.CONTENT_TYPE.SOCIAL ||
    moduleType === appConstants.CONTENT_TYPE.APP_STORE
  ) {
    return {
      [`value_json->>${fieldName}`]: {
        filterType: 'text',
        type: 'contains',
        filter: searchTerm,
      },
    };
  }
  return {
    [fieldName]: {
      filterType: 'text',
      type: 'contains',
      filter: searchTerm,
    },
  };
};

export const constructFltrForAgGridFltrModelWithAdvancedFltrParams = (
  searchTerm: string,
  moduleType: string,
  currentTab?: string,
  advancedFilterParams?: AdvancedFilterParams,
): FilterModel => {
  const filters: FilterModel = {};
  if (!advancedFilterParams) return filters;

  const {
    searchParams,
    disposition,
    firstSeenStartDate,
    firstSeenEndDate,
    scanSource,
    statusFieldType,
  } = advancedFilterParams;
  const fieldName = searchParams?.find(field => field.moduleType === moduleType)?.fieldName;

  if (fieldName?.includes('url')) {
    filters[
      currentTab === EWebModuleTabFilterFields.dst_url
        ? EWebModuleTabFilterFields.dst_url
        : (fieldName as string)
    ] = {
      filterType: 'text',
      type: 'contains',
      filter: searchTerm,
    };
  } else {
    filters[fieldName as string] = {
      filterType: 'text',
      type: 'contains',
      filter: searchTerm,
    };
  }

  if (fieldName === 'ip') {
    filters['ip'] = {
      filterType: 'text',
      type: 'Begins with',
      filter: searchTerm,
    };
  }

  if (disposition) {
    filters['current_disposition'] = {
      filterType: 'text',
      type: disposition,
    };
  }

  if (firstSeenStartDate && firstSeenEndDate) {
    const dateFilter = {
      filterType: 'date',
      type: 'inRange',
      dateFrom: firstSeenStartDate,
      dateTo: firstSeenEndDate,
    };
    if (moduleType === appConstants.CONTENT_TYPE.SOCIAL) {
      filters['created_ts'] = dateFilter;
    }
    if (moduleType === appConstants.CONTENT_TYPE.DARK_WEB) {
      filters['platform_published_ts'] = {
        ...dateFilter,
        customRange: true,
      };
    }
    filters['first_seen_ts'] = dateFilter;
  }

  if (scanSource) {
    const scanSourceFilter = {
      filterType: 'text',
      type: 'contains',
      filter: scanSource,
    };
    if (moduleType === appConstants.CONTENT_TYPE.SOCIAL) {
      filters['source'] = scanSourceFilter;
    }
    if (moduleType === appConstants.CONTENT_TYPE.APP_STORE) {
      filters['scan_source'] = scanSourceFilter;
    }
    if (moduleType === appConstants.CONTENT_TYPE.WEB) {
      filters['scan_source'] = {
        filterType: 'text',
        type: scanSource,
      };
    }
  }

  const globalTakeDownStatusMap: { [key: string]: string } = {
    complete: EGTDTakedownStatus.TAKEN_DOWN,
    pending: EGTDTakedownStatus.IN_PROGRESS,
  };

  if (statusFieldType) {
    const statusFilter = {
      filterType: 'text',
      type: statusFieldType[0].value,
    };
    if (moduleType === appConstants.CONTENT_TYPE.SOCIAL) {
      filters['status'] = statusFilter;
    }
    if (moduleType === appConstants.CONTENT_TYPE.WEB) {
      const statusValueToFilter =
        globalTakeDownStatusMap[statusFieldType[0].value] || statusFieldType[0].value;
      filters['global_takedown_status'] = {
        ...statusFilter,
        type: statusValueToFilter,
      };
    }
  }
  return filters;
};

export const constructFltrForBulkScanWithAdvancedFltrParams = (
  searchTerm: string,
  moduleType: string,
  advancedFilterParams?: AdvancedFilterParams,
) => {
  const bulkScanfilters: IFilter[] = [];
  const fieldName = advancedFilterParams?.searchParams?.find(
    field => field.moduleType === moduleType,
  )?.fieldName;
  bulkScanfilters.push(...constructFilterForBulkScanTable(searchTerm, fieldName as string));

  if (advancedFilterParams?.disposition) {
    bulkScanfilters.push({
      id: generateId(10),
      filterType: 'options',
      filterBy: {
        label: 'Disposition',
        value: 'disposition',
      },
      filterMethod: {
        label: 'Is',
        value: 'is',
      },
      filterValue: advancedFilterParams?.disposition,
      filterErr: '',
    });
  }
  return bulkScanfilters;
};
