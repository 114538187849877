import { store } from '../../../helpers';
import fetchApi from '../../../services/api.service';
import { handleResponse } from '../../../services/serviceWorker';
import { EmailParams } from '../constants';
import { res } from '../demo-data-bankofbolster/allemails.poc';

export const fetchEmailDetails = async (emailParams?: EmailParams) => {
  const requestGroupOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...emailParams,
    }),
  };
  const isDemoBrand =
    store.getState().dashboardReducer.brandInfo?.brand?.brandName === 'bankofbolster';
  const rawResponse = isDemoBrand
    ? res
    : await fetchApi('/platform-api/v1/abuse-mailbox/emails', requestGroupOptions).then(
        handleResponse,
      );
  return rawResponse;
};
