import React from 'react';
import { abuseMailBoxDashboardNoDataMainText } from '../constants';
import './AbuseMailBoxDashboardNoDataView.scss';

export const AbuseMailBoxDashboardNoDataView = ({
  abuseMailboxEmailId,
}: {
  abuseMailboxEmailId: string;
}) => {
  return (
    <div className='abuse-mailbox-dashboard-no-data-container'>
      <div className='abuse-mailbox-dashboard-no-data-text-container'>
        <div className='abuse-mailbox-dashboard-no-data-main-text'>
          {abuseMailBoxDashboardNoDataMainText}
        </div>
        <div className='abuse-mailbox-dashboard-no-data-sub-text'>
          Below is sample data. Forward suspicious emails as attachment to{' '}
          <span className='abuse-mailbox-dashboard-no-data-sub-text-example'>
            {abuseMailboxEmailId}
          </span>{' '}
          to see your data in action.
        </div>
      </div>
    </div>
  );
};
