import React, { ReactNode } from 'react';
import { Box, Typography, BoxProps } from '@mui/material';
import LableValue from '../../Insights/LableValue';
import moment from 'moment';

interface IMetaDataItemProps extends BoxProps {
  icon: ReactNode;
  title: string;
  children?: React.ReactNode;
}

const AllUrlAnalysisMetaData: React.FC<IMetaDataItemProps> = props => {
  const { icon, title, children, ...otherProps } = props;
  return (
    <Box display='flex' flexDirection='column' {...otherProps}>
      <Box display='flex'>
        {icon}
        <Typography className='meta-data--title px--start'>{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default AllUrlAnalysisMetaData;

type ScanTimeStampProps = {
  firstScan: string;
  lastScan: string;
  firstScanLabel: string;
  lastScanLabel: string;
};

export const AllURLAnalysisScanTimeStamp = ({
  firstScan,
  lastScan,
  firstScanLabel,
  lastScanLabel,
}: ScanTimeStampProps) => {
  return (
    <>
      <Box component='span'>
        <LableValue
          key={firstScanLabel}
          lable={firstScanLabel}
          value={moment(firstScan).format('MM/DD/YYYY hh:mm A')}
          className='label-text'
        />
      </Box>
      <Box component='span'>
        <LableValue
          key={lastScanLabel}
          lable={lastScanLabel}
          value={moment(lastScan).format('MM/DD/YYYY hh:mm A')}
          className='border-left label-text'
        />
      </Box>
    </>
  );
};
