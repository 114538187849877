import React from 'react';
import { Grid } from '@mui/material';
import { LabelAndValue } from '../../Common/LabelAndValue';
import { MuiCardWrapper } from '../../Common/MuiCardWrapper';
import { IThreatTypeDetails } from '../constants';

interface SenderDomainAnalysisProps {
  senderDomainDetails: IThreatTypeDetails;
}

export const SenderDomainAnalysis: React.FC<SenderDomainAnalysisProps> = ({
  senderDomainDetails,
}) => {
  return (
    <div className='senderDomain-analysis'>
      <MuiCardWrapper cardTitle='Threat Overview'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <LabelAndValue
              label={'Domain Name'}
              value={senderDomainDetails?.sourceUrl}
              direction={'column'}
              longTextLineNumberLimit={1}
              copyButton
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <LabelAndValue
              label={'Category'}
              value={senderDomainDetails?.category}
              direction={'column'}
            />
          </Grid>
        </Grid>
      </MuiCardWrapper>
    </div>
  );
};
