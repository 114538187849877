import React from 'react';
import _, { groupBy } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UnregisterIcon from '../../../../assets/icons/Unregister.svg';
import ChevronRightNewIcon from '../../../../assets/icons/ChevronRightNew.svg';
import ChevronRightWhiteIcon from '../../../../assets/icons/ChevronRightWhite.svg';

import { generateId, ICategory, setLocalStorageValue } from '../../../../constants';
import { history } from '../../../../helpers';
import {
  POST_MALICIOUS_TABLE_ID,
  PRE_MALICIOUS_TABLE_ID,
  TAKEDOWN_MALICIOUS_TABLE_ID,
  ACQUISITION_TABLE_ID,
  WEB_DASHBOARD_PATHNAME,
} from '../../../MonitorAndTakedown/constants';
import Funnel from './Funnel';
import TextCenterWithChevronRight from './TextCenterWithChevronRight';
import { CleanIcon, PhishIcon, ScamIcon, SuspiciousIcon } from '../../../../assets/SVGIcons';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

export const MONITOR_FOR_ACQUISITIONS = 'Monitor for Acquisitions';
export const MONITOR_PRE_MALICIOUS = 'Monitor Pre-Malicious';
export const TAKEDOWN_MALICIOUS = 'Takedown Malicious';
export const MONITOR_POST_MALICIOUS = 'Monitor Post-Malicious';

export const UNREGISTERED_DOMAINS = 'Unregistered Domains';
export const ERROR_PAGE = 'Error Pages';
export const PARKED_DOMAINS = 'Parked domains';
export const DIRECTORY_E_COMM_OTHER = 'Directory, e-comm, other';
export const BEC_SENSITIVE = 'BEC, sensitive';
export const TAKEN_DOWN = 'Taken Down';

const getCategoryFilterLabel = (
  categoriesByGroup: Record<string, ICategory[]>,
  categoryGroup: string,
) => {
  if (categoriesByGroup && categoriesByGroup[categoryGroup]) {
    return categoriesByGroup?.[categoryGroup]?.map(category => category?.label).join(', ');
  }
  return '';
};

const getCategoryFilterValue = (
  domainCountsByGroup: Record<string, any[]>,
  categoryGroup: string,
) => {
  return _.first(domainCountsByGroup?.[categoryGroup])?.categories?.join('|');
};

const MonitoredDomainFunnel = ({
  monitoredDomains,
  domainCategories,
  query,
  filters,
}: any): JSX.Element => {
  const categoriesByGroup = groupBy(domainCategories, 'group');
  const domainCountsByGroup = groupBy(monitoredDomains, 'label');
  const [isDarkMode] = useIsDarkMode();
  const routes: object = {
    [MONITOR_FOR_ACQUISITIONS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/acquisitions`,
      tableId: ACQUISITION_TABLE_ID,
    },
    [MONITOR_PRE_MALICIOUS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/pre-malicious`,
      tableId: PRE_MALICIOUS_TABLE_ID,
    },
    [TAKEDOWN_MALICIOUS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/takedown-malicious`,
      tableId: TAKEDOWN_MALICIOUS_TABLE_ID,
    },
    [MONITOR_POST_MALICIOUS]: {
      pathName: `${WEB_DASHBOARD_PATHNAME}/post-malicious`,
      tableId: POST_MALICIOUS_TABLE_ID,
    },
  };

  const initialFilters: Record<string, any> = {
    [ERROR_PAGE]: {
      id: generateId(10),
      filterBy: { label: 'Category', value: 'final_category' },
      filterErr: '',
      filterLabel: getCategoryFilterLabel(categoriesByGroup, ERROR_PAGE),
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'string',
      filterValue: getCategoryFilterValue(domainCountsByGroup, ERROR_PAGE),
    },
    [PARKED_DOMAINS]: {
      id: generateId(10),
      filterBy: { label: 'Category', value: 'final_category' },
      filterErr: '',
      filterLabel: getCategoryFilterLabel(categoriesByGroup, PARKED_DOMAINS),
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'string',
      filterValue: getCategoryFilterValue(domainCountsByGroup, PARKED_DOMAINS),
    },
    [DIRECTORY_E_COMM_OTHER]: {
      id: generateId(10),
      filterBy: { label: 'Category', value: 'final_category' },
      filterErr: '',
      filterLabel: getCategoryFilterLabel(categoriesByGroup, DIRECTORY_E_COMM_OTHER),
      filterMethod: { label: 'Is', value: 'is' },
      filterType: 'string',
      filterValue: getCategoryFilterValue(domainCountsByGroup, DIRECTORY_E_COMM_OTHER),
    },
  };

  const handleRedirect = (parentLabel: string, label: string) => {
    const allFilters = initialFilters[label] ? [initialFilters[label]] : [];
    if (filters) allFilters.push(...filters);

    setLocalStorageValue(['tableSetting', routes[parentLabel]?.tableId], {
      query,
      filters: allFilters,
    });
    history.push(routes[parentLabel]?.pathName);
  };

  const handleHeaderRedirect = (parentLabel: string) => {
    setLocalStorageValue(['tableSetting', routes[parentLabel]?.tableId], {
      query,
      filters: filters,
    });
    history.push(routes[parentLabel]?.pathName);
  };

  return (
    <div className='monitored-domains-funnel-wrapper'>
      <div className='acquisition-wrapper'>
        <div className='monitored-domains-label-wrapper'>
          <div
            className='monitored-domains-label'
            onClick={() => handleHeaderRedirect(MONITOR_FOR_ACQUISITIONS)}
          >
            <TextCenterWithChevronRight
              iconSrc={isDarkMode ? ChevronRightWhiteIcon : ChevronRightNewIcon}
            >
              {MONITOR_FOR_ACQUISITIONS}
            </TextCenterWithChevronRight>
          </div>
        </div>
        <div className='funnel-wrapper'>
          <Funnel
            width={'16vw'}
            height={'185px'}
            background={
              isDarkMode
                ? 'linear-gradient(90.47deg, #A54F5B 0.24%, #96277D 99.73%)'
                : 'linear-gradient(90.47deg, #EE8695 0.24%, #A73B8F 99.73%)'
            }
            isTransform={true}
            parentLabel={MONITOR_FOR_ACQUISITIONS}
            label={UNREGISTERED_DOMAINS}
            value={_.first(domainCountsByGroup[UNREGISTERED_DOMAINS])?.count}
            totalVariants={_.first(domainCountsByGroup[UNREGISTERED_DOMAINS])?.subCount?.count}
            handleRedirect={handleRedirect}
          />
        </div>
        <div className='bottom-labels-wrapper'>
          <div className='gray-horizontal-dashed-line' />
          <div className='labels recommended-label'>Recommended to buy</div>
          <div className='gray-horizontal-dashed-line' />
        </div>
      </div>
      <div className='red-dashed-line'>
        <OverlayTrigger
          overlay={
            <Tooltip id={`tooltip-unregister}`} className='monitor-icon-tooltip'>
              Domains to the right have already been registered by potentially bad actors.
            </Tooltip>
          }
        >
          <div className='icon'>
            <img src={UnregisterIcon} alt='unregister-right' />
          </div>
        </OverlayTrigger>
      </div>
      <div className='acquisition-wrapper'>
        <div className='monitored-pre-label-wrapper'>
          <div
            className='monitored-domains-label'
            onClick={() => handleHeaderRedirect(MONITOR_PRE_MALICIOUS)}
          >
            <TextCenterWithChevronRight
              iconSrc={isDarkMode ? ChevronRightWhiteIcon : ChevronRightNewIcon}
            >
              {MONITOR_PRE_MALICIOUS}
            </TextCenterWithChevronRight>
          </div>
        </div>
        <div className='funnel-wrapper'>
          <Funnel
            width={'16vw'}
            height={'170px'}
            background={
              isDarkMode
                ? 'linear-gradient(90deg, #fa9d15 0%, #f48025 100%)'
                : 'linear-gradient(90deg, #ffb803 0%, #ff961c 100%)'
            }
            isTransform={true}
            parentLabel={MONITOR_PRE_MALICIOUS}
            label={ERROR_PAGE}
            value={_.first(domainCountsByGroup[ERROR_PAGE])?.count}
            handleRedirect={handleRedirect}
          />
          <Funnel
            width={'16vw'}
            height={'156px'}
            background={
              isDarkMode
                ? 'linear-gradient(90deg, #f37f23 0%, #ed6134 100%)'
                : 'linear-gradient(90deg, #ff961c 0%, #ff7236 100%)'
            }
            className='parked-domains-funnel'
            isTransform={true}
            parentLabel={MONITOR_PRE_MALICIOUS}
            label={PARKED_DOMAINS}
            value={_.first(domainCountsByGroup[PARKED_DOMAINS])?.count}
            handleRedirect={handleRedirect}
          />
          <Funnel
            width={'16vw'}
            height={'142px'}
            background={
              isDarkMode
                ? 'linear-gradient(90deg, #ed6134 0%, #e74343 100%)'
                : 'linear-gradient(90deg, #ff7236 0%, #fe514f 100%)'
            }
            isTransform={true}
            parentLabel={MONITOR_PRE_MALICIOUS}
            label={DIRECTORY_E_COMM_OTHER}
            value={_.first(domainCountsByGroup[DIRECTORY_E_COMM_OTHER])?.count}
            handleRedirect={handleRedirect}
          />
        </div>
        <OverlayTrigger
          overlay={
            <Tooltip id={`tooltip-disposition}`} className='monitor-icon-disposition'>
              Disposition
            </Tooltip>
          }
        >
          <div className='bottom-labels-wrapper'>
            <div className='gray-horizontal-dashed-line' />
            <SuspiciousIcon color='grey' />
            <div className='labels'>Suspicious</div>
            <div className='gray-horizontal-dashed-line' />
          </div>
        </OverlayTrigger>
      </div>
      <div className='gray-vertical-dashed-line' />
      <div className='acquisition-wrapper'>
        <div className='monitored-takedown-label-wrapper'>
          <div
            className='monitored-domains-label'
            onClick={() => handleHeaderRedirect(TAKEDOWN_MALICIOUS)}
          >
            <TextCenterWithChevronRight
              iconSrc={isDarkMode ? ChevronRightWhiteIcon : ChevronRightNewIcon}
            >
              {TAKEDOWN_MALICIOUS}
            </TextCenterWithChevronRight>
          </div>
        </div>
        <div className='funnel-wrapper'>
          <Funnel
            width={'16vw'}
            height={'129px'}
            background={
              isDarkMode
                ? 'linear-gradient(90deg, #EB6328 0%, #D82424 100%)'
                : 'linear-gradient(90deg, #FF6D2D 0%, #F90000 100%)'
            }
            parentLabel={TAKEDOWN_MALICIOUS}
            isTransform={true}
            label={BEC_SENSITIVE}
            value={_.first(domainCountsByGroup[BEC_SENSITIVE])?.count}
            handleRedirect={handleRedirect}
          />
        </div>
        <OverlayTrigger
          overlay={
            <Tooltip id={`tooltip-disposition}`} className='monitor-icon-disposition'>
              Disposition
            </Tooltip>
          }
        >
          <div className='bottom-labels-wrapper'>
            <div className='gray-horizontal-dashed-line' />
            <PhishIcon color='grey' />
            <div className='labels'>Phish</div>
            <ScamIcon color='grey' />
            <div className='labels'>Scam</div>
            <div className='gray-horizontal-dashed-line' />
          </div>
        </OverlayTrigger>
      </div>
      <div className='gray-vertical-dashed-line' />
      <div className='acquisition-wrapper large-screen-padding'>
        <div className='monitored-post-label-wrapper'>
          <div
            className='monitored-domains-label'
            onClick={() => handleHeaderRedirect(MONITOR_POST_MALICIOUS)}
          >
            <TextCenterWithChevronRight
              iconSrc={isDarkMode ? ChevronRightWhiteIcon : ChevronRightNewIcon}
            >
              {MONITOR_POST_MALICIOUS}
            </TextCenterWithChevronRight>
          </div>
        </div>
        <div className='funnel-wrapper'>
          <Funnel
            width={'16vw'}
            height={'123px'}
            background={
              isDarkMode
                ? 'linear-gradient(90deg, #3887C0 0%, #4DA0B3 41.8%, #1576BC 107%)'
                : 'linear-gradient(90deg, #46B2FF 0%, #76E6FF 41.8%, #31A1F1 107%)'
            }
            isTransform={false}
            parentLabel={MONITOR_POST_MALICIOUS}
            label={TAKEN_DOWN}
            value={_.first(domainCountsByGroup[TAKEN_DOWN])?.count}
            handleRedirect={handleRedirect}
          />
        </div>
        <OverlayTrigger
          overlay={
            <Tooltip id={`tooltip-disposition}`} className='monitor-icon-disposition'>
              Disposition
            </Tooltip>
          }
        >
          <div className='bottom-labels-wrapper'>
            <div className='gray-horizontal-dashed-line' />
            <CleanIcon color='grey' />
            <div className='labels'>Clean</div>
            <SuspiciousIcon color='grey' />
            <div className='labels'>Suspicious</div>
            <div className='gray-horizontal-dashed-line' />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};
export default MonitoredDomainFunnel;
