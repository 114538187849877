import React, { useCallback, useRef, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { IFilter, TExportTable } from './constant';
import { ITableApiColumn } from './table.api';
import { IDropdownOption } from '../../Common/Dropdown';
import { Table } from './table';
import { TableContext } from './table.context';
import { getLocalStorageValue } from '../../../constants';
import { setLocalStorageValue } from '../../../constants';
import { unsetLocalStorageValue } from '../../../constants';

export type ITableDataColumn = ITableApiColumn;

export interface IComponentProps {
  id: string;
  title: string;
  customTools?: any;
  columns: ITableDataColumn[];
  columnsNotEditable?: boolean;
  defaultColumnIds?: string[];
  tileComp?: any;
  titleComp?: any;
  tableIndex: string;
  disableDatePicker?: boolean;
  data: any;
  exportOptions?: IDropdownOption[];
  exportFn?: any;
  tileTopThreeProps?: string[];
  enableCheckbox?: boolean;
  selectedItems?: any[];
  indexBy?: string;
  deleteModalConfig?: any;
  deleteFn?: (items: any[]) => any;
  onItemCheck?: (checkbox: boolean, data: any, allSelectedItems: any) => void;
  showLoader?: boolean;
  summaryWidget?: any;
  disablePagination?: boolean;
  tableClassName?: string;
  customToolsDirection?: boolean;
  user?: any;
  hideDeleteBin?: boolean;
  initialPageNumber?: number;
  initialPageSize?: number;
  filters?: any;
  onPageChange?: (page: number) => void;
  onFilterApplied?: (filters: IFilter[]) => void;
  defaultLayout?: string;
  isOldTable?: boolean;
  hideHeader?: boolean;
  handleDisplayLayout?: (layout: string) => void;
  tileCompEvents?: any;
  type?: string;
  disableFilter?: boolean;
}

const defaultDayRange = 90;

const TableData = (props: IComponentProps) => {
  const {
    id,
    defaultColumnIds = [],
    exportFn,
    columnsNotEditable,
    summaryWidget,
    hideHeader = false,
    handleDisplayLayout,
  } = props;
  const tableContext = useContext(TableContext);
  const columns = tableContext.columns || props.columns;
  let { displayedColumnsIDs, onTableSaveColumns } = tableContext;

  if (_.isEmpty(displayedColumnsIDs) || displayedColumnsIDs.length === 0) {
    displayedColumnsIDs = defaultColumnIds.length
      ? defaultColumnIds
      : _.map(columns, column => column.id);
  }

  const savedSetting = getLocalStorageValue(['tableSetting', id]);

  const startDateStr = _.get(savedSetting, ['query', 'startDate']);
  let startDate = startDateStr ? moment(startDateStr) : moment().subtract(defaultDayRange, 'day');
  let endDate = moment(_.get(savedSetting, ['query', 'endDate']));
  const lastXDay = useRef(_.get(savedSetting, 'lastXDay', 0));
  let sortBy = _.get(savedSetting, ['sortBy', 'sortBy']);
  let sortDirection = _.get(savedSetting, ['sortBy', 'sortDirection'], 'desc');
  if (_.isEmpty(sortBy)) {
    const sortByColumn = _.find(columns, column => column.isDefaultSort);
    sortBy = _.get(sortByColumn, 'accessor', 'update_ts');
    sortDirection = _.get(sortByColumn, 'defaultSortDirection', 'desc');
  }
  if (lastXDay.current > 0) {
    endDate = moment();
    startDate = moment().subtract(lastXDay.current, 'day');
  } else {
    lastXDay.current = defaultDayRange;
  }

  const [pageNumber, setPageNumber] = useState<number>(
    _.get(savedSetting, ['query', 'pageNumber'], 1),
  );
  const [filters, setFilters] = useState<any>(_.get(savedSetting, ['filters']));

  useEffect(() => {
    if (props.initialPageNumber) {
      setPageNumber(props.initialPageNumber);
    }
  }, [props.initialPageNumber]);

  useEffect(() => {
    if (savedSetting.id !== props.id) {
      unsetLocalStorageValue('tableSetting');
    }
  }, [props.id]);

  useEffect(() => {
    if (props.filters) {
      setFilters(props.filters);
    }
  }, [props.filters]);

  useEffect(() => {
    if (props.onPageChange) props.onPageChange(pageNumber);
    setLocalStorageValue(['tableSetting', id], {
      id: id,
      filters: savedSetting?.filters || {},
      query: { pageNumber: pageNumber },
    });
  }, [pageNumber]);

  useEffect(() => {
    if (props.onFilterApplied) props.onFilterApplied(filters);
    setLocalStorageValue(['tableSetting', id], {
      id: id,
      filters: filters,
      query: { pageNumber: savedSetting?.query?.pageNumber || 1 },
    });
  }, [filters]);

  const exportDataFn = useCallback(
    exportFn
      ? (filteredSortedData: any, type: TExportTable) => {
          const exportColumns = _.chain(columns)
            .filter(
              column =>
                !column.hidden &&
                (displayedColumnsIDs.indexOf(column.id) !== -1 ||
                  displayedColumnsIDs.indexOf(column.accessor) !== -1) &&
                !column.hiddenInExport,
            )
            .map(column => ({
              label: column.header,
              field: column.fieldForExport || column.accessor,
            }))
            .value();
          return exportFn(filteredSortedData, exportColumns, type);
        }
      : _.noop,
    [displayedColumnsIDs],
  );

  const tileCompProperties = _.map(columns, column => ({ id: column.id }));
  return (
    <Table
      key={`${props.id}-${props.defaultLayout}`}
      {...props}
      columns={columns}
      displayedColumnsIDs={displayedColumnsIDs}
      exportDataFn={exportDataFn}
      initialFilters={filters}
      startDate={startDate}
      endDate={endDate}
      sortBy={sortBy}
      sortDirection={sortDirection}
      initialPageNumber={pageNumber}
      enableEditColumns={!columnsNotEditable && onTableSaveColumns !== _.noop}
      onSaveColumns={onTableSaveColumns}
      tileCompProperties={tileCompProperties}
      summaryWidget={summaryWidget}
      onPageChange={setPageNumber}
      onFilterApplied={setFilters}
      hideHeader={hideHeader}
      disableToolsBar={hideHeader}
      handleDisplayLayout={handleDisplayLayout}
      hideColumnHeader={hideHeader}
    />
  );
};

export { TableData };
