import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AuthenticationWrapper } from '../../components/AuthenticationWrapper';
import { IComponentProps as ITableApiProps, TableApi } from '../Common/Table/table.api';
import {
  IEventProps as ITableReduxWrapEventProps,
  TableContextProvider,
} from '../Common/Table/table.context';
import Tags, { ITags } from '../Common/Tags/Tags';
import { AppState } from '../../helpers';
import {
  AlertActionsTypes,
  appConstants,
  featureIsAvailable,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
  isBrandReadOnlyUser,
  IScanSourceFeeds,
  ISelectedWebUrls,
  isInternalUser,
  setLocalStorageValue,
} from '../../constants';
import {
  MALICIOUS_SCREEN_SHOT_COLUMN,
  TAGS_AUTHOR_COLUMN,
  TAGS_COLUMN,
} from './ColDefinition/malicious.columns';
import { DASHBOARD_NAME, EMatFetchTypes, MALICIOUS_TABLE_IDS } from './constants';
import { WebUrlData } from '../../types/web-url-data.interface';
import { IDashboardReducerState } from '../../reducers/dashboard.reducer';
import { alertActions, dashboardActions } from '../../actions';
import DisputeDispositionModal from '../BulkScan/Common/DisputeDispositionModal';
import BulkScanTakedownRequestModal from '../BulkScan/Common/BulkScanTakedownRequestModal';
import { IUserListItemDto } from '../TeamMembers/TeamMembers';
import DeleteCustomTagsModal from '../Common/Tags/DeleteCustomTagsModal';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import EditCustomTagsModal from '../Common/Tags/EditCustomTagsModal';
import useOnGroupedView from '../Common/CustomHooks/useOnGroupedView';
import { Dropdown, IDropdownOption } from '../Common/Dropdown';
import { EBulkActionModalType } from '../Ugc/Components/Findings/FindingsTableComponent';
import { setIsGroupRelatedDataReady, setTagsFromBulkAction } from '../../reducers/table.reducer';
import BulkActionsOverhead from '../Ugc/Components/Common/BulkActionsOverhead';
import { setCurrentTableId } from '../../reducers/insightsContainer.reducer';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';
interface IComponentProps {
  id: MALICIOUS_TABLE_IDS;
  children?: any;
  usersList?: IUserListItemDto[];
  user?: any;
  type: string;
  selectedWebUrls: IDashboardReducerState['selectedWebUrls'];
  setSelectedWebUrls: (tableId: MALICIOUS_TABLE_IDS, selected: WebUrlData[]) => void;
  alertError: (message: string) => AlertActionsTypes;
  scanSourceCategories?: IScanSourceFeeds[];
  tableType?: EMatFetchTypes;
  isGroupRelatedDataReady?: boolean;
}

const MaliciousComponent = (
  props: IComponentProps & ITableApiProps & ITableReduxWrapEventProps,
) => {
  const [isDarkMode] = useIsDarkMode();
  const [refreshOnTag, setRefreshOnTag] = useState(false);
  const [tagsAddedPerSha, setTagsAddedPerSha] = useState<any>({});
  const [selectedItems, setSelectedItems] = useState<WebUrlData[]>([]);

  const [disputeDispositionModalShown, setDisputeDispositionModalShown] = useState(false);
  const [takedownModalShown, setTakedownModalShown] = useState(false);
  // const [groupedIsEnabled, setGroupedIsEnabled] = useState(false);
  const { id, columns, onUserChanged = _.noop, usersList, user } = props;
  const [findings, setFindings] = useState<WebUrlData[]>([]);
  const [displayLayout, setDisplayLayout] = useState(true);
  const displayLayoutRef = useRef(true);
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);
  const [bulkActionModal, setBulkActionModal] = useState<EBulkActionModalType>(
    EBulkActionModalType.None,
  );
  const [tagsAnchorEl, setTagsAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const isWebTablesGroupView = useAppSelector(state => state.tableReducer.isWebTablesGroupView);

  // Custom hook to handle grouped view
  useOnGroupedView();

  useEffect(() => {
    const newSelected = props.selectedWebUrls?.[props.id] ?? [];
    setSelectedItems(newSelected);
  }, [props.selectedWebUrls, props.id]);

  useEffect(() => {
    displayLayoutRef.current = displayLayout;
  }, [displayLayout]);

  useEffect(() => {
    const bulkActionBtnElement = document.getElementById('dropdown-basic-button');
    if (bulkActionBtnElement) {
      setTagsAnchorEl(bulkActionBtnElement);
      dispatch(setIsGroupRelatedDataReady(true));
    }
  }, [tagsAnchorEl, selectedItems]);

  const onTagsChangeHandler = (isAdded: boolean, addedSha?: string, newTags?: any[]) => {
    setRefreshOnTag(isAdded);

    if (isWebTablesGroupView && newTags?.length) {
      setTagsAddedPerSha(newTags);
    }
  };

  useEffect(() => {
    dispatch(setCurrentTableId(id));
    return () => {
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  const modifyCol = (col: any) => {
    const tagsColumn = _.find(col, ['id', 'tags']);
    const tagsAuthorColumn = _.find(col, ['id', 'Tags Author']);
    const scanSourceCategoryCol = _.find(col, ['id', 'sourceFeed']);
    const tagsDropdown = getProvidedTagLabelValue(
      providedTags.filter((tag: ITags) => {
        if (tag.label !== 'Marked as Scam') {
          return true;
        }
      }),
      isDarkMode,
    );
    if (!(tagsAuthorColumn && tagsColumn && tagsDropdown.length)) {
      col.push(
        {
          ...TAGS_COLUMN,
          sortDisabled: true,
          filterOptions: tagsDropdown,
          render: (rowData: any) => {
            return (
              <Tags
                leftAligned={displayLayoutRef.current}
                rowData={rowData}
                onTagsChangeHandler={onTagsChangeHandler}
                type={appConstants.CONTENT_TYPE.WEB}
              />
            );
          },
        },
        {
          ...TAGS_AUTHOR_COLUMN,
          filterOptions: (usersList && usersList.length && getUsersListLabelValue(usersList)) || [],
        },
      );
    }
    if (isWebTablesGroupView && props.type === appConstants.CONTENT_TYPE.WEB) {
      //To reset the local storage on group view enabled
      setLocalStorageValue(['tableSetting', id], {
        sortBy: {
          sortBy: 'first_seen_ts',
          sortDirection: 'desc',
        },
      });
    }
    col.map((item: any) => {
      if (item.id !== 'domain' && item.id !== 'first_seen_ts') {
        item.sortDisabled = isWebTablesGroupView;
      }
      return item;
    });

    if (scanSourceCategoryCol && props?.scanSourceCategories) {
      scanSourceCategoryCol.sortDisabled = true;
      scanSourceCategoryCol.filterOptions = props.scanSourceCategories.map(
        (feed: IScanSourceFeeds) => {
          return {
            label: feed.label,
            value: feed.value,
          };
        },
      );
    }
    // TODO: remove feasture check after testing
    const screenshotCol = _.find(col, ['id', 'screenshot']);
    if (
      !screenshotCol &&
      featureIsAvailable(props.user, appConstants.FEATURE_CODE.SCREENSHOT_COLUMN)
    ) {
      col.push(MALICIOUS_SCREEN_SHOT_COLUMN);
    }
    return col;
  };

  const selectedTakedown = (checked: boolean, item: WebUrlData) => {
    if (checked) {
      props.setSelectedWebUrls(props.id, _.uniq([...props.selectedWebUrls[props.id], item]));
    } else {
      props.setSelectedWebUrls(
        props.id,
        props.selectedWebUrls[props.id].filter(url => url.url_sha256 !== item.url_sha256),
      );
    }
  };

  const toggleDisputeDispositionModal = () => {
    setDisputeDispositionModalShown(false);
    setFindings([]);
    clearUserSelection();
  };
  const onSuccessDisputeDisposition = () => {
    toggleDisputeDispositionModal();
    dispatch(alertActions.success('Dispute Request Sent Successfully!'));
  };

  const toggleTakedownRequestModal = () => {
    setTakedownModalShown(false);
    setFindings([]);
    clearUserSelection();
  };

  const getBulkActionUserSelection = (selection: IDropdownOption) => {
    if (selection.value === EBulkActionModalType.Dispute_All) {
      setDisputeDispositionModalShown(true);
    }
    if (selection.value === EBulkActionModalType.Request_Takedown) {
      setTakedownModalShown(true);
    }
  };

  const clearUserSelection = () => {
    setBulkActionModal(EBulkActionModalType.None);
    dispatch(dashboardActions.setSelectedWebUrls(props.id, []));
    setLocalStorageValue(['savedSelectionState', props.id], []);
    setSelectedItems([]);
  };

  const customTools = [];

  const bulkActionOptions = [
    {
      label: EBulkActionModalType.Dispute_All,
      value: EBulkActionModalType.Dispute_All,
    },
    {
      label: EBulkActionModalType.Request_Takedown,
      value: EBulkActionModalType.Request_Takedown,
    },
    {
      label: EBulkActionModalType.Add_Tags,
      value: EBulkActionModalType.Add_Tags,
    },
  ];

  if (!isWebTablesGroupView && !isBrandReadOnlyUser(user)) {
    {
      customTools.push(
        <Dropdown
          boxStyle
          disabled={selectedItems.length === 0}
          variant='outlined'
          fixedPlaceholder={'Bulk Actions'}
          key={'bulk-action'}
          options={bulkActionOptions}
          onChange={selection => {
            setBulkActionModal(selection.value);
            getBulkActionUserSelection(selection);
          }}
        />,
      );
    }
  }

  const isOldTable = !featureIsAvailable(props.user, appConstants.FEATURE_CODE.AG_GRID_TABLE);
  return (
    <AuthenticationWrapper>
      <div className={'page-content dashboard-page'}>
        {(featureIsAvailable(user, appConstants.FEATURE_CODE.CROSS_PAGE) || isInternalUser(user)) &&
          !isWebTablesGroupView && (
            <BulkActionsOverhead
              findingsTableUrls={selectedItems as unknown as ISelectedWebUrls}
              type={appConstants.CONTENT_TYPE.WEB}
              tableId={id as string}
            />
          )}
        <Card className={'dashboard-table-container'}>
          <TableContextProvider
            tableIndex={props.tableIndex}
            columns={columns}
            dashboardName={DASHBOARD_NAME}
            tableId={id}
            onUserChanged={onUserChanged}
            modifyColumns={modifyCol}
            isGroupViewEnabled={isWebTablesGroupView}
            setFindings={setFindings}
            disputeModalShown={setDisputeDispositionModalShown}
            takedownModalShown={setTakedownModalShown}
            defaultColumnIds={props.defaultColumnIds}
          >
            <TableApi
              {...props}
              enableCheckbox={true}
              onItemCheck={(checkbox: boolean, data: WebUrlData) => {
                selectedTakedown(checkbox, data);
              }}
              refreshOnColChange={true}
              refreshOnTag={refreshOnTag}
              user={user}
              tagsAddedPerSha={tagsAddedPerSha}
              titleTooltip={`${
                isWebTablesGroupView
                  ? 'Toggle between Grouped View and List View for a customized display of your results.'
                  : ''
              }`}
              selectedItems={selectedItems}
              providedTags={providedTags}
              customTools={customTools}
              handleDisplayLayout={(layout: string) => setDisplayLayout(layout === 'table')}
            />
            {disputeDispositionModalShown && (
              <DisputeDispositionModal
                data={isWebTablesGroupView ? findings : selectedItems}
                show={
                  isOldTable
                    ? disputeDispositionModalShown
                    : disputeDispositionModalShown && !!props.isGroupRelatedDataReady
                }
                onCloseModal={toggleDisputeDispositionModal}
                submitButtonLabel='Submit'
                onSuccess={onSuccessDisputeDisposition}
                onFailure={props.alertError}
                bulkScanPage={false}
              />
            )}
            {takedownModalShown && (
              <BulkScanTakedownRequestModal
                data={isWebTablesGroupView ? findings : selectedItems}
                show={
                  isOldTable
                    ? takedownModalShown
                    : takedownModalShown && !!props.isGroupRelatedDataReady
                }
                onCloseModal={toggleTakedownRequestModal}
                onSuccess={toggleTakedownRequestModal}
                onFailure={props.alertError}
                user={user}
              />
            )}
            {bulkActionModal === 'Add Tags' && (
              <Tags
                fromAgGrid
                agAnchorEl={tagsAnchorEl}
                rowData={selectedItems}
                showTagsOverlay={true}
                onTagsChangeHandler={onTagsChangeHandler}
                type={appConstants.CONTENT_TYPE.WEB}
                bulkOption
                onClose={() => {
                  setBulkActionModal(EBulkActionModalType.None);
                }}
                getUpdatedTags={async (updatedTagsRes: any) => {
                  const { tagIdsToAdd, tagIdsToRemove, urlShas256 } = updatedTagsRes;
                  dispatch(setTagsFromBulkAction(updatedTagsRes));
                }}
              />
            )}
            {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
            {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
          </TableContextProvider>
        </Card>
      </div>
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state: AppState) => {
  const { usersList, user, selectedWebUrls } = state.dashboardReducer;
  const { scanSourceCategories } = state.appReducer;
  const { isGroupRelatedDataReady } = state.tableReducer;

  return {
    user,
    usersList,
    selectedWebUrls,
    scanSourceCategories,
    isGroupRelatedDataReady,
  };
};

const mapDispatchToProps = {
  setSelectedWebUrls: dashboardActions.setSelectedWebUrls,
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};

const connectedMaliciousComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaliciousComponent);
export { connectedMaliciousComponent as MaliciousComponent };
