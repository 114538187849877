import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  className?: string;
}

const TabPanel = ({ index, value, children, className = '' }: TabPanelProps) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
