import _ from 'lodash';
import { Record as ImmutableRecord } from 'immutable';
import {
  appConstants,
  IAttribute,
  ICategory,
  TContentType,
  IContentProps,
  IScanSourceFeeds,
  ClientAppType,
} from '../constants';
import { IDropdownOption } from '../components/Common/Dropdown';
import { ITags } from '../components/Common/Tags/Tags';
import { IPropertyType } from '../components/Assets_v2/PropertyTypes';
import { ICountryCode } from '../components/Common/Country';

// TODO: add other app metadata (i.e. attributes)
interface IAppState {
  attributes: IAttribute[];
  categories: ICategory[];
  categoriesAndGroupsOptions: IDropdownOption[];
  contents: Record<TContentType, IContentProps>;
  urlConstructions: IDropdownOption[];
  countryCodes: Record<string, ICountryCode>;
  tags: ITags[];
  brandPropertyTypes: IPropertyType[];
  darkWebEntityOptions: IDropdownOption[];
  showPricingPopup: boolean;
  scanSourceCategories: IScanSourceFeeds[];
  clientAppType: ClientAppType;
}

const initialState: IAppState = {
  attributes: [],
  categories: [],
  categoriesAndGroupsOptions: [],
  urlConstructions: [],
  countryCodes: {},
  contents: {
    social: { platforms: [], origins: [], categories: [] },
    marketplace: { platforms: [], origins: [], categories: [] },
  },
  tags: [],
  brandPropertyTypes: [],
  darkWebEntityOptions: [],
  showPricingPopup: false,
  scanSourceCategories: [],
  clientAppType: appConstants.CLIENT_APP_TYPE,
};

const initialEntities: any = [
  {
    label: 'Any',
    value: '',
  },
];

const formatUrlConstruction = (urlConstructions: any) => {
  return _.forEach(urlConstructions, u => {
    u.value = u.algorithm;
  });
};
class InitialState extends ImmutableRecord(initialState) implements IAppState {}

const appReducer = (state = new InitialState(), action: any) => {
  switch (action.type) {
    case appConstants.GET_ATTRIBUTES:
      return state.set('attributes', action.payload);
    case appConstants.SET_PRICING_POPUP:
      return state.set('showPricingPopup', action.showPricingPopup);
    case appConstants.GET_ENTERPRISE_APP_CONFIG: {
      const categories = _.sortBy(action.payload.categories, 'label');
      const categoryGroupBy = _.groupBy(categories, 'group');
      const groups = _.chain(categoryGroupBy)
        .map((categoriesInGroup, groupName) => ({
          label: groupName,
          value: _.map(categoriesInGroup, c => c.value).join('|'),
        }))
        .value();
      return state
        .set('attributes', action.payload.attributes)
        .set('urlConstructions', formatUrlConstruction(action.payload.urlConstructions))
        .set('countryCodes', action.payload.countryCodes)
        .set('contents', action.payload.contents)
        .set('categories', categories)
        .set('categoriesAndGroupsOptions', [...categories, ...groups])
        .set('tags', action.payload.tags)
        .set('brandPropertyTypes', _.get(action.payload.brandPropertyTypes, ['brandPropertyTypes']))
        .set('darkWebEntityOptions', [
          ...initialEntities,
          ...(action.payload?.darkWebEntityOptions?.options || []),
        ])
        .set('scanSourceCategories', action.payload.scanSourceCategories)
        .set('clientAppType', action.payload.clientAppType);
    }
    case appConstants.GET_CHECKPHISH_APP_CONFIG:
      return state
        .set('categories', _.sortBy(action.payload.categories, 'label'))
        .set('scanSourceCategories', action.payload.scanSourceCategories)
        .set('urlConstructions', formatUrlConstruction(action.payload.urlConstructions));
    default:
      return state;
  }
};

export { appReducer };
