/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import '../../Style/SocialMedia.scss';
import { ISearchesTableProps, ISearchesTableItemProps, ugcType } from '../../Types/ugc.types';
import { AuthenticationWrapper } from '../../../AuthenticationWrapper';
import { TableContextProvider } from '../../../Common/Table/table.context';
import { TableData, ITableDataColumn } from '../../../Common/Table/table.data';
import {
  SEARCH_TERM_COLUMN,
  PLATFORMS_COLUMN,
  CATEGORY_COLUMN,
  AUTHOR_COLUMN,
  ADDED_COLUMN,
  TIMES_DETECTED_COLUMN,
  LAST_SEARCH_COLUMN,
  IS_DISABLED_COLUMN,
  OPTIONS_ENTITY_COL,
} from './TableColDef/SearchesTableCols';
import UgcService from '../../../../services/ugc.service';
import ChangeSearchTermStatusModal from './ChangeSearchTermStatusModal';
import { getCSVFile } from '../../../Assets_v2/util';
import {
  appConstants,
  featureIsAvailable,
  getLocalTimeFromUtcTime,
  isBrandReadOnlyUser,
} from '../../../../constants';
import { connect } from 'react-redux';
import { AppState } from '../../../../helpers';
import { useAppDispatch } from '../../../../helpers/hooks';
import { setIsAgGridClientMode } from '../../../../reducers/table.reducer';
import ActionColRender from './ActionColRender';
import { SEARCH_TERM_TABLE_ID } from '../../constants';
import { alertActions } from '../../../../actions';
import { TABLE_EMPTY_RESULTS_MESSAGE } from '../../../Common/Table/ag-table/constants';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

const ugcService = new UgcService();
const exportCSVTitle = `Bolster Search Terms ${moment().format('YYYYMMDDhhmmss')}`;

const SearchesTable = ({
  ugcCategories,
  ugcPlatforms,
  newSearchTerm,
  tableName,
  darkWebEntityOptions,
  type,
  user,
}: ISearchesTableProps) => {
  const TABLE_COLUMNS = [
    SEARCH_TERM_COLUMN,
    AUTHOR_COLUMN,
    ADDED_COLUMN,
    TIMES_DETECTED_COLUMN,
    LAST_SEARCH_COLUMN,
    IS_DISABLED_COLUMN,
  ];

  switch (type) {
    case ugcType.Social:
    case ugcType.Marketplace:
      TABLE_COLUMNS.push(PLATFORMS_COLUMN);
      TABLE_COLUMNS.push(CATEGORY_COLUMN);
      break;
    case ugcType.Dark_Web:
      TABLE_COLUMNS.push(OPTIONS_ENTITY_COL);
      TABLE_COLUMNS.push(CATEGORY_COLUMN);
      break;
  }

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, []);

  const [data, setData] = useState<ISearchesTableItemProps[]>([]);
  const [statusAction, setStatusAction] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<ISearchesTableItemProps | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const updatedData = useRef<ISearchesTableItemProps>();

  const isOldTable = !featureIsAvailable(user, appConstants.FEATURE_CODE.AG_GRID_TABLE);

  const onModalSubmit = (res: ISearchesTableItemProps) => {
    setSelectedItem(undefined);
    updatedData.current = res;
    fetchData();
  };

  const ACTION_COLUMN: ITableDataColumn = useMemo(() => {
    return {
      id: 'action',
      accessor: '',
      header: 'Actions',
      filterDisabled: true,
      sortDisabled: true,
      columnClassName: 'action-column always-enable',
      hiddenInExport: true,
      render: (data: ISearchesTableItemProps) => {
        const isReadOnlyUser = isBrandReadOnlyUser(user);
        if (isReadOnlyUser) {
          return null;
        }
        return (
          <ActionColRender
            data={data}
            type={type}
            setSelectedItem={setSelectedItem}
            setStatusAction={setStatusAction}
          />
        );
      },
    };
  }, []);

  const modifyColumns = (columns: ITableDataColumn[]): ITableDataColumn[] => {
    const categoryColumn = _.find(columns, ['id', 'category']);
    if (categoryColumn) {
      categoryColumn.filterOptions = _.map(ugcCategories, c => ({
        label: c.label,
        value: c.label,
      }));
    }
    const platformsColumn = _.find(columns, ['id', 'platforms']);
    if (platformsColumn) {
      platformsColumn.filterOptions = _.map(ugcPlatforms, p => ({
        label: p.label,
        value: p.label,
      }));
    }
    const entityColumn = _.find(columns, ['id', 'entity']);
    if (entityColumn) {
      entityColumn.filterOptions = _.map(darkWebEntityOptions, e => ({
        label: e.label,
        value: e.label,
      }));
    }
    return columns;
  };

  useEffect(() => {
    fetchData();
  }, [newSearchTerm]);

  const fetchData = () => {
    setIsLoading(true);
    ugcService.getSearches(type).then((res: any) => {
      setIsLoading(false);
      if (res.length === 0) {
        dispatch(alertActions.information(TABLE_EMPTY_RESULTS_MESSAGE));
      }
      setData(
        _.map(res, (item): ISearchesTableItemProps => {
          const {
            id,
            searchTerm,
            platforms,
            category,
            createdTs,
            history,
            uploads,
            isDisabled,
            option,
          } = item;
          const completedHistory = history.filter((h: { isComplete: boolean }) => h.isComplete);
          const lastSearch =
            completedHistory.length &&
            _.orderBy(completedHistory, 'updatedTs', 'desc')[0].updatedTs;
          let lastSearchText;
          if (isDisabled) {
            if (lastSearch) {
              lastSearchText = getLocalTimeFromUtcTime(lastSearch);
            } else {
              lastSearchText = 'Stopped';
            }
          } else {
            if (lastSearch) {
              lastSearchText = getLocalTimeFromUtcTime(lastSearch);
            } else {
              lastSearchText = 'In Progress';
            }
          }
          const times = _.reduce(
            history,
            (sum: number, h) => {
              return sum + h.resultCount;
            },
            0,
          );
          return {
            id,
            searchTerm,
            platforms,
            platformNames: _.map(platforms, p => p.label).join(', '),
            category: category.label,
            author: _.get(item, ['createdBy', 'firstName'], '--'),
            createdTs,
            times,
            historyCreatedTs: lastSearch,
            lastSearchText,
            isNewAdded: newSearchTerm === searchTerm || updatedData.current?.id === id,
            isDisabled: item.isDisabled,
            isDisabledTextValue: item.isDisabled ? 'Disabled' : 'Enabled',
            isLogoDetection:
              item.isLogoDetection === null ? '--' : capitalize(`${item.isLogoDetection}`),
            brandLogoDetected:
              item.brandLogoDetected === null ? '--' : capitalize(`${item.brandLogoDetected}`),
            logos: item.isLogoDetection ? uploads : [],
            uploadCount: item.isLogoDetection ? uploads.length : 0,
            entity: option?.options?.label || '--',
          };
        })
          .sort((a: ISearchesTableItemProps, b: ISearchesTableItemProps) => {
            const result = new Date(a.createdTs).getTime() - new Date(b.createdTs).getTime();
            return result;
          })
          .reverse(),
      );
    });
  };

  const exportSearchTerms = (data: any, columns: any[]) => {
    getCSVFile(
      data,
      _.filter(columns, column => column.label !== ACTION_COLUMN.header),
      exportCSVTitle,
    );
  };
  const customTools = [
    <div onClick={fetchData} key='reset-icon' className={'tools-button-wrapper'}>
      <RefreshOutlinedIcon />
    </div>,
  ];
  return (
    <AuthenticationWrapper>
      <div className={'search-term-page'}>
        <div className='searches-table-container'>
          <TableContextProvider
            columns={[...TABLE_COLUMNS, ACTION_COLUMN]}
            dashboardName={tableName}
            tableId={SEARCH_TERM_TABLE_ID}
            modifyColumns={modifyColumns}
          >
            <TableData
              id={SEARCH_TERM_TABLE_ID}
              tableIndex={'social_media_search_term'}
              title={'Searches'}
              data={data}
              columns={[...TABLE_COLUMNS, ACTION_COLUMN]}
              columnsNotEditable
              exportOptions={[{ label: 'CSV', value: 'csv' }]}
              exportFn={exportSearchTerms}
              indexBy={'searchTerm'}
              disableDatePicker
              showLoader={isLoading}
              customTools={customTools}
              customToolsDirection={true}
              user={user}
              isOldTable={isOldTable}
              type={type}
            />
          </TableContextProvider>
        </div>
      </div>
      <ChangeSearchTermStatusModal
        data={selectedItem}
        statusAction={statusAction}
        description={`Are you sure you want to ${statusAction} search term: `}
        onCloseModal={() => {
          setSelectedItem(undefined);
        }}
        onModalSubmit={onModalSubmit}
        type={type}
      />
    </AuthenticationWrapper>
  );
};
const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

const connectedSearchesTable = connect(mapStateToProps, null)(SearchesTable);

export { connectedSearchesTable as SearchesTable };
