import { useState } from 'react';
import { IConnector } from './connectors.component';

export function useEditingConnector() {
  const [editingConnector, setEditingConnector] = useState<IConnector>();

  if (editingConnector?.type === 'Exchange') {
    editingConnector.selectedIntegration = 'Microsoft 365 Exchange/Defender';
  }
  const initialToken =
    editingConnector?.details?.header?.find(
      (header: { parameter: string; value: string }) => header.parameter === 'Authorization',
    )?.value || '';

  if (editingConnector && initialToken) {
    // senitel for those who has extra token
    editingConnector.selectedIntegration = 'Cortex XSOAR';
    editingConnector.details.extraToken = initialToken;
  }

  if (
    editingConnector &&
    editingConnector.type === 'API' &&
    !editingConnector.selectedIntegration
  ) {
    editingConnector.selectedIntegration = 'API Integration';
  }

  if (editingConnector && editingConnector.details?.selectedIntegration) {
    editingConnector.selectedIntegration = editingConnector.details.selectedIntegration;
  }

  return {
    editingConnector,
    setEditingConnector,
  };
}
