import React, { useState, useRef, useCallback } from 'react';
import './AttachFile.scss';
import PaperClipIcon from '../../assets/icons/PaperClip.svg';
import PaperClipWhiteIcon from '../../assets/icons/PaperClipWhite.svg';
import { AttachedFileObject } from './DisputeStatus';
import moment from 'moment';
import AttachedFileViewer from './AttachedFileViewer';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

const FILES_LIMIT = 10;
const MAX_FILE_SIZE = 5;
const DEFAULT_FILE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'message/rfc822',
  'text/plain',
];

export const convertToAttachedFileObjects = (files: File[] | string[]): AttachedFileObject[] => {
  if (typeof files[0] === 'string') {
    const f = files as string[];
    return f.map((file: string): AttachedFileObject => {
      return {
        name: file,
        path: file,
        createdTS: '',
      };
    });
  } else {
    const f = files as File[];
    return f.map((file: File): AttachedFileObject => {
      return {
        name: file.name,
        path: URL.createObjectURL(file),
        createdTS: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
    });
  }
};

interface AttachFileProps {
  onChangeHandler: (files: File[]) => void;
  label?: string;
  acceptFileTypes?: string[];
}

export default function AttachFile({
  onChangeHandler,
  label,
  acceptFileTypes = DEFAULT_FILE_TYPES,
}: AttachFileProps) {
  const [isDarkMode] = useIsDarkMode();
  let fileSelector: any = {};
  const [attachedFiles, setAttachedFiles] = useState<AttachedFileObject[]>([]);
  const [error, setError] = useState<string>();
  const files = useRef<File[]>([]);
  const maxFileSizeInBytes = MAX_FILE_SIZE * 1000000;

  const onFilesAdded = useCallback((event: any) => {
    const selectedFiles = event.target.files;
    if (files.current.length + selectedFiles.length > FILES_LIMIT) {
      setError(`No more than ${FILES_LIMIT} files.`);
      return;
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      if (!acceptFileTypes.includes(selectedFiles[i].type)) {
        setError(`Wrong file type: ${selectedFiles[i].name} is not allowed.`);
        return;
      }
    }
    let currentFilesSize = 0;
    [...files.current, ...selectedFiles].forEach(file => {
      currentFilesSize += file.size;
    });
    if (currentFilesSize > maxFileSizeInBytes) {
      setError(`Files size should not exceeds ${MAX_FILE_SIZE} MB`);
      return;
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      files.current.push(selectedFiles[i]);
    }

    setAttachedFiles(convertToAttachedFileObjects(files.current));
    setError('');
    onChangeHandler(files.current);
  }, []);

  const onFileRemoved = useCallback((e): void => {
    files.current.splice(e.currentTarget.dataset.fileIndex, 1);
    setAttachedFiles(convertToAttachedFileObjects(files.current));
    onChangeHandler(files.current);
  }, []);

  return (
    <div className={'attach-file-container'}>
      <input
        type='file'
        name='file'
        id='attach-file'
        accept={acceptFileTypes.join(',')}
        ref={r => (fileSelector = r)}
        onChange={onFilesAdded}
        style={{ display: 'none' }}
        multiple
        data-testid='attach-file'
      />
      <div>
        <div className={'d-flex align-items-center'}>
          <div onClick={() => fileSelector.click()}>
            <img
              src={isDarkMode ? PaperClipWhiteIcon : PaperClipIcon}
              className={'cursor-pointer'}
              alt={'paper clip'}
            />
            <span className={'attach-file-text cursor-pointer'}>{label}</span>
          </div>
        </div>
        <div className={'mt-2 text-dark-grey-2'}>
          Maximum {FILES_LIMIT} files. Files must be less than 5 MB. Allowed file types: png, jpg,
          jpeg, txt, svg, gif or eml.
        </div>
        {error && <div className={'mt-2 text-danger'}>{error}</div>}
        {attachedFiles?.length > 0 && (
          <AttachedFileViewer
            attachedFiles={attachedFiles}
            horizontal
            handleFileRemove={onFileRemoved}
          />
        )}
      </div>
    </div>
  );
}
