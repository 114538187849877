import {
  INotificationConfigs,
  IUpdateNotificationPayload,
  IUserNotificationPreferences,
} from '../components/Profile/CommunicationSettings/CommunicationSettings.types';
import { INotificationSetting } from '../components/Profile/NotificationsSetting';
import { handleResponse, setApiUrl } from './serviceWorker';
export interface IUpdateNotificationPreference {
  module_name: string;
  actions: any;
}

export interface NotificationServicesInterface {
  getLatestUrlInfo(urlSha256: string): Promise<Response>;
  getAllNotificationConfig(applicationType?: string): Promise<INotificationConfigs>;
  getUserNotificationPrefrences(): Promise<IUserNotificationPreferences>;
  updateUserNotificationSettings(updatedSettings: IUpdateNotificationPayload): Promise<Response>;
}

export default class NotificationServices implements NotificationServicesInterface {
  private apiUrl: string;

  constructor() {
    this.apiUrl = setApiUrl();
  }

  getLatestUrlInfo(urlSha256: string) {
    return fetch(`/platform-api/v1/notifications/url-info/${urlSha256}`).then(handleResponse);
  }

  getAllNotificationConfig(): Promise<INotificationConfigs> {
    return fetch(`/platform-api/v1/notifications/all-notification-settings/`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  getAllCommunityNotificationConfig(): Promise<INotificationSetting[]> {
    return fetch(`/platform-api/v1/notifications/all-community-notification-settings/`, {
      credentials: 'include',
    }).then(handleResponse);
  }

  //get user notification settings
  getUserNotificationPrefrences(): Promise<IUserNotificationPreferences> {
    return fetch('/platform-api/v1/notifications/user-notification-settings', {
      credentials: 'include',
    }).then(handleResponse);
  }

  getCommunityUserNotificationPrefrences(): Promise<INotificationSetting[]> {
    return fetch('/platform-api/v1/notifications/community-user-notification-settings', {
      credentials: 'include',
    }).then(handleResponse);
  }

  updateUserNotificationSettings(updatedSettings: IUpdateNotificationPayload) {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedSettings),
    };
    return fetch('/platform-api/v1/notifications/user-notification-settings', requestOptions).then(
      handleResponse,
    );
  }

  updateCommunityUserNotificationSettings(
    updatedSettings: INotificationSetting[],
  ): Promise<INotificationSetting[]> {
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ notificationTypes: updatedSettings }),
    };
    return fetch(
      '/platform-api/v1/notifications/community-user-notification-settings',
      requestOptions,
    ).then(handleResponse);
  }
}
