import React from 'react';
import { takeDownTimeAnalyticsDataPoints, takedownTimeAnalyticsNoDataText } from '../../constants';
import './TakedownTimeAnalyticsNoDataView.scss';

export const TakedownTimeAnalyticsNoDataView = () => {
  return (
    <div className='takedown-time-analytics-no-data-container'>
      <div className='takedown-time-analytics-no-data-text'>{takedownTimeAnalyticsNoDataText}</div>
      <div className='takedown-time-analytics-no-data-bar-container'>
        {takeDownTimeAnalyticsDataPoints.map((value, index) => (
          <div key={index} className='takedown-time-analytics-no-data-bar-row'>
            <span className={`takedown-time-analytics-no-data-bar-value bar-value-${value}`}>
              {value}
            </span>
            <div className={`takedown-time-analytics-no-data-bar bar-${value}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
