import React from 'react';
import { Badge, Row } from 'react-bootstrap';

const RiskLevelIndicator = (props: any) => {
  return (
    <>
      <Row className='item-center padding-x-s-24' data-testid='risk-level-indicator'>
        {Object.keys(props.colorMap)
          .sort((a: string, b: string) => parseInt(b) - parseInt(a))
          .map((k, i) => (
            <div key={i} style={{ display: 'inline-flex', marginRight: '4px' }}>
              <Badge
                variant='primary'
                style={{
                  background: props.colorMap[k],
                  width: '18px',
                  height: '18px',
                  borderRadius: '50%',
                }}
                data-testid='circle-badge'
              >
                {k}
              </Badge>
            </div>
          ))}
        <div className='sub-text padding-x-end-7 ml-1'>Risk Level </div>
      </Row>
    </>
  );
};

export default RiskLevelIndicator;
