import { Drawer } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../helpers/hooks';
import DataLeakDetails from './DataLeakDetails';
import '../../Styles/DataLeakDetailsDrawer.scss';
import useOnToggleDataLeakDrawer from './useOnToggleDataLeakDrawer';

export default function DataLeakDetailsDrawer() {
  const { toggleDrawer } = useOnToggleDataLeakDrawer();

  const shouldShowDataLeakModal = useAppSelector(
    state => state.darkWebReducer.shouldShowDataLeakModal,
  );

  return (
    <Drawer open={shouldShowDataLeakModal} onClose={toggleDrawer} anchor='right'>
      <div data-testid='data-leak-details-drawer'>
        <DataLeakDetails />
      </div>
    </Drawer>
  );
}
