import React from 'react';
import './LableValue.scss';

interface ILableValue {
  lable: string;
  value: React.ReactNode | string | number;
  className?: string;
  showTooltip?: boolean;
}

const LableValue = ({ lable, value, className = '', showTooltip = false }: ILableValue) => {
  return (
    <span
      className={`label-value-wrap ${className}`}
      title={showTooltip && typeof value === 'string' ? value : ''}
    >
      <span className='label'>{lable}: </span>
      <span className='value'>{value}</span>
    </span>
  );
};

export default LableValue;
