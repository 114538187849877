import landingPageLight from './automation - light mode.png';
import landingPageDark from './automation - dark mode.png';
import React, { useContext } from 'react';
import ThemeContext from '../../../context/ThemeContext';
import './landingPage.scss';

export function AutomationLandingPage() {
  const theme = useContext(ThemeContext);
  const landingPageImage = theme.selectedTheme === 'light' ? landingPageLight : landingPageDark;
  return (
    <div className='automation-landing-page'>
      <div className='landing-page-title'>Automation</div>
      <div className='landing-page-tips'>
        You do not have permission to access any modules to view or create playbooks. Please contact
        your administrator to request access.
      </div>
      <img src={landingPageImage} alt='Automation Landing Page' width={540} />
    </div>
  );
}
