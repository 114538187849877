import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const ExportCSV = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => {
  return (
    <div key={'Export CSV'} className='d-flex align-items-center export-csv-button' {...props}>
      <FileDownloadOutlinedIcon className='export-icon' />
      <div className='pl-2 export-label'>Export CSV</div>{' '}
    </div>
  );
};
