import React, { useState, useEffect } from 'react';
import { history } from '../../helpers';
import { getLocalStorageValue, setLocalStorageValue } from '../../constants';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import WarningRedRoundIcon from '../../assets/icons/Warning-round-red.svg';
import './typosquat.scss';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

export interface ITyposquatReminderModalProps {
  domain: string;
  daysToExpire: number;
}

const TyposquatReminderModal = ({ domain, daysToExpire }: ITyposquatReminderModalProps) => {
  const [, selectedTheme] = useIsDarkMode();
  const [remindMeLater, setRemindMeLater] = useState<boolean>(false);
  const remindMeLaterAction = () => {
    setLocalStorageValue('typosquattingRemindMeLater', true);
    setRemindMeLater(true);
  };

  useEffect(() => {
    const typosquattingRemindMeLater = getLocalStorageValue('typosquattingRemindMeLater');
    const typosquattingDaysToExpire = getLocalStorageValue('typosquattingDaysToExpire');
    if (typosquattingRemindMeLater === undefined) {
      setLocalStorageValue('typosquattingRemindMeLater', false);
      setLocalStorageValue('typosquattingDaysToExpire', daysToExpire);
    } else {
      if (typosquattingDaysToExpire !== daysToExpire) {
        setLocalStorageValue('typosquattingRemindMeLater', false);
        setLocalStorageValue('typosquattingDaysToExpire', daysToExpire);
      } else {
        setRemindMeLater(typosquattingRemindMeLater);
      }
    }
  }, [
    getLocalStorageValue('typosquattingRemindMeLater'),
    getLocalStorageValue('typosquattingDaysToExpire'),
  ]);

  return (
    <Modal
      show={!remindMeLater}
      size='lg'
      className={`${selectedTheme} typosquatting-reminder-modal`}
    >
      <Modal.Header>
        <Modal.Title id='add-assets-input'>
          <Row>
            <Col md={12} className='d-flex'>
              <Col md={1}>
                <img src={WarningRedRoundIcon} alt={'warning'} />
              </Col>
              <Col md={11} className='header no-padding' as={'p'}>
                The domain Monitoring feature will expire in {daysToExpire} days
              </Col>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'modal-body'}>
        <Col className='work-email-reminder-text' md={12} as={'p'}>
          Add your work email to keep monitoring [{domain}]. This will ensure you receive weekly
          rescans for new potential phish findings.
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Col md={6}>
          <Button
            style={{ float: 'right' }}
            variant='light'
            className='btn remind-me-later-button'
            onClick={remindMeLaterAction}
          >
            Remind me later
          </Button>
        </Col>
        <Col md={6}>
          <Button variant='danger' className='btn' onClick={() => history.push('/account/profile')}>
            Add Work Email
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default TyposquatReminderModal;
