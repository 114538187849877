import { useContext, useEffect, useState } from 'react';
import ThemeContext from '../../../context/ThemeContext';
import { ThemeModes } from '../../../constants';
const useIsDarkMode = (): [boolean, string] => {
  const { selectedTheme } = useContext(ThemeContext);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [currentTheme, setCurrentTheme] = useState<string>(selectedTheme || '');
  useEffect(() => {
    if (selectedTheme === ThemeModes.LIGHT.toLowerCase()) {
      setCurrentTheme(selectedTheme);
    } else {
      setCurrentTheme('dark');
    }
    setIsDarkMode(currentTheme.toLowerCase() !== ThemeModes.LIGHT.toLowerCase());
  }, [currentTheme, selectedTheme]);
  return [isDarkMode, currentTheme];
};
export default useIsDarkMode;
