import React, { useCallback } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ACTION_OPTIONS } from './PlaybookTypes';
import DotDotDot from '../../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../../assets/icons/DotDotDotWhite.svg';
import useIsDarkMode from '../../Common/CustomHooks/useIsDarkMode';

interface PlaybookActionColRenderProps {
  data: any;
  onRunNowPlaybook: (data: any) => void;
  onEditPlaybook: (data: any) => void;
  onDeletePlaybook: (data: any) => void;
}

enum ESocActionModalType {
  RUN_NOW = 'runNow',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum ESocActionOrigin {
  TABLE = 'table',
  TIMELINE = 'timeline',
}
export default function PlaybookActionColRender({
  data,
  onRunNowPlaybook,
  onEditPlaybook,
  onDeletePlaybook,
}: PlaybookActionColRenderProps) {
  const [isDarkMode] = useIsDarkMode();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const actionOptions = [
    {
      label: 'Run Now',
      value: 'runNow',
    },
    ...ACTION_OPTIONS,
  ];

  const onAction = useCallback(
    (action: string) => {
      switch (action) {
        case ESocActionModalType.RUN_NOW:
          onRunNowPlaybook(data);
          break;
        case ESocActionModalType.EDIT:
          onEditPlaybook(data);
          break;
        case ESocActionModalType.DELETE:
          onDeletePlaybook(data);
          break;
      }
    },
    [data, onRunNowPlaybook, onEditPlaybook, onDeletePlaybook],
  );
  return (
    <>
      <Button
        id='basic-button-soc-actions'
        aria-controls={open ? 'basic-menu-soc-actions' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid='basic-button-soc-actions'
      >
        <img src={isDarkMode ? DotDotDotWhiteIcon : DotDotDot} alt={'dotdotdot'} />{' '}
      </Button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actionOptions.map((actionOption, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onAction(actionOption.value);
                handleClose();
              }}
            >
              {actionOption.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
