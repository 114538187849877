import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getMaintenanceAlertStatus, IMaintenanceAlertResponse } from './alert-requests';
import { appConstants } from '../../../constants';
import { Nullable } from '../../../types/common';

export default function MaintenanceAlert() {
  const [maintenanceAlertData, setMaintenanceAlertData] =
    useState<Nullable<IMaintenanceAlertResponse>>(null);

  useEffect(() => {
    const fetchMaintenanceAlertStatus = async () => {
      try {
        const response = await getMaintenanceAlertStatus(appConstants.CLIENT_APP_TYPE);
        if (response) {
          setMaintenanceAlertData(response);
        }
      } catch (e) {
        console.log('Error fetching maintenance alert status:', e);
      }
    };
    void fetchMaintenanceAlertStatus();
  }, []);

  const { showMaintenanceBanner, description } = maintenanceAlertData || {};
  return showMaintenanceBanner ? (
    <div data-testid='maintenance-alert'>
      <Alert
        variant='filled'
        severity='warning'
        id='maintenance-alert'
        style={{ background: '#EF6C00' }}
      >
        {description}
      </Alert>
    </div>
  ) : null;
}
