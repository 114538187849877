import React, { useMemo, useRef } from 'react';
import './AllEmailSubmissions.scss';
import { useState, useEffect, useCallback } from 'react';
import {
  ALL_EMAIL_PAGE_SIZE,
  allEmailfilterOptions,
  EInternalUserFilter,
  emailCategoryOptions,
  emailDispositionOptions,
  emailDispositionToValue,
  internalUserFilterOptions,
  internalUserFilterValue,
} from './constants';
import moment from 'moment';
import { useOnFetchEmailDetails } from '../Insights/useOnFetchEmailDetails';
import { EmailDetailsParams } from '../constants';
import { useAppSelector } from '../../../helpers/hooks';
import { history } from '../../../helpers';
import { useEmailCounts } from './useEmailCounts';
import { fetchEmailDetails } from '../Insights/EmailDetails-requests';
import { usePrevious } from '../../../hooks/usePrevious';
import { Alert, Chip, IconButton, Menu, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatDateToUpperCaseLocaleString } from '../../../helpers/time-and-date-utils';
import { DispositionStatus } from '../../Common/DispositionStatus';
import { AllEmailSubmissionsNoDataView } from './AllEmailSubmissionsNoDataView';
import { EmailDetailsAnalysisContainer } from '../Insights/EmailDetailsAnalysisContainer';
import { massagedAbuseMailBoxEmailDetails } from '../Insights/EmailDetailsUtils';
import { LoadingWrapper } from '../../Common/LoadingWrapper';

export interface IAllEmailAnalysisProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  isDemoBrand: boolean;
}

function AllEmailAnalysis({ startDate, endDate, isDemoBrand }: IAllEmailAnalysisProps) {
  const [selectedEmailIdx, setSelectedEmailIdx] = useState<number>(0);
  const [filterBy, setFilterBy] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visiableEmailCount, setVisiableEmailCount] = useState<number>(ALL_EMAIL_PAGE_SIZE);
  window.document.title = 'All Email Submission | Abuse Mailbox';
  const firstLoad = useRef(true);

  // ALL_EMAIL_PAGE_SIZE is the number of emails to fetch per request
  const extraParams = useMemo(() => {
    return {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      from: visiableEmailCount - ALL_EMAIL_PAGE_SIZE,
      size: ALL_EMAIL_PAGE_SIZE,
      emailDisposition: emailDispositionOptions.has(filterBy)
        ? [emailDispositionToValue[filterBy]]
        : undefined,
      emailCategory: emailCategoryOptions.has(filterBy) ? [filterBy] : undefined,
      ...(filterBy === EInternalUserFilter.NEEDS_REVIEW && internalUserFilterValue[filterBy]),
    };
  }, [visiableEmailCount, filterBy, startDate, endDate]);

  const { abuseMailBoxEmailDetails: deltaEmailDetails, isLoading } =
    useOnFetchEmailDetails(extraParams);
  // fetch email details only when we are on all email page;

  const [emailDetails, setEmailDetails] = useState<EmailDetailsParams[]>([]);

  const { user } = useAppSelector(state => state.dashboardReducer);

  // append deltaEmailDetails to emailDetails
  useEffect(() => {
    setEmailDetails(prev => [...prev, ...(deltaEmailDetails?.emailDetails || [])]);
  }, [deltaEmailDetails]);

  // if there is filterBy changes, reset visiableEmailCount to ALL_EMAIL_PAGE_SIZE, and reset emailDetails to empty array
  useEffect(() => {
    setVisiableEmailCount(ALL_EMAIL_PAGE_SIZE);
    if (!isDemoBrand) setEmailDetails([]);
    setSelectedEmailIdx(0);
    if (filterBy) history.replace('/abuse-mailbox/all-email-submissions');
    setError(null);
  }, [filterBy, isDemoBrand]);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelect = (value: string) => {
    setFilterBy(value);
    handleFilterClose();
  };

  const { filteredCount, totalEmailsCount } = useEmailCounts({
    filterBy,
    startDate,
    endDate,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...(user && user.is_internal !== undefined
      ? {
          isInternal: user.is_internal,
        }
      : { isInternal: false }),
  });

  // if user types emailId in the url, mostly for debugging purpose
  const emailId = new URLSearchParams(window.location.search).get('id');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setVisiableEmailCount(ALL_EMAIL_PAGE_SIZE);
    setSelectedEmailIdx(0);
    !firstLoad.current ? setEmailDetails([]) : (firstLoad.current = false);
  }, [startDate, endDate]);

  useEffect(() => {
    if (!emailId || !emailDetails.length) return;
    const idx = emailDetails.findIndex(email => email.id === emailId);
    if (idx !== -1) {
      setSelectedEmailIdx(idx);
    } else {
      const fetchEmailDetailsAsync = async () => {
        const emailDetailById = (
          await fetchEmailDetails({
            emailId,
          })
        ).emailDetails;
        if (emailDetailById.length) {
          setEmailDetails(prev => [...prev, ...emailDetailById]);
          setSelectedEmailIdx(emailDetails.length - 1);
        } else {
          setError('Email not found');
        }
      };
      void fetchEmailDetailsAsync();
    }
  }, [emailDetails, emailId]);

  const filteredEmails = useMemo(() => {
    if (internalUserFilterOptions.includes(filterBy as EInternalUserFilter)) {
      return emailDetails;
    }
    return filterBy
      ? emailDetails.filter(
          email => email.disposition === filterBy || email.emailCategory === filterBy,
        )
      : emailDetails;
  }, [filterBy, emailDetails]);

  const [isFirstDataFetch, setIsFirstDataFetch] = useState<boolean>(true);

  const prevEmailDetailsLength = usePrevious(emailDetails.length);

  useEffect(() => {
    if (!prevEmailDetailsLength && emailDetails.length) setIsFirstDataFetch(false);
    else if (prevEmailDetailsLength && !emailDetails.length) setIsFirstDataFetch(true);
  }, [emailDetails, prevEmailDetailsLength]);

  const showLoader = isLoading && isFirstDataFetch;

  return (
    <div data-testid='all-email-analysis-wrapper'>
      <LoadingWrapper isLoading={showLoader} className='all-email-loader no-padding'>
        <div className='all-email-submissions-content'>
          {/* Inbox container */}
          <div className='inbox-container'>
            {/* Inbox header */}
            <div className='inbox-header'>
              <div className='inbox-header-title'>
                Total Emails <span className='inbox-header-count'>{totalEmailsCount}</span>
              </div>
              <div className='inbox-header-filter'>
                {filterBy && (
                  <Chip
                    label={filterBy}
                    variant='outlined'
                    onDelete={() => setFilterBy('')}
                    color='default'
                    avatar={
                      <div className='inbox-header-filter-avatar'>
                        {filteredCount ? filteredCount : 0}
                      </div>
                    }
                  />
                )}
                {!filterBy && <span>Filter by</span>}
                <IconButton data-testId='filter-button' onClick={handleFilterClick}>
                  <FilterListIcon />
                </IconButton>
                <Menu
                  data-testid='filter-option'
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleFilterClose}
                >
                  {[
                    ...allEmailfilterOptions,
                    ...(user.is_internal ? internalUserFilterOptions : []),
                  ].map(filter => (
                    <MenuItem key={filter} onClick={() => handleFilterSelect(filter)}>
                      {filter}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
            {/* Inbox content */}
            <div className='inbox-content' id='inbox-scrollable-div'>
              <InfiniteScroll
                dataLength={visiableEmailCount}
                next={() => {
                  setVisiableEmailCount(prev => prev + ALL_EMAIL_PAGE_SIZE);
                }}
                hasMore={visiableEmailCount < totalEmailsCount}
                loader={<h4></h4>}
                scrollableTarget='inbox-scrollable-div'
                scrollThreshold='200px'
              >
                {/* List of Inbox items */}
                {filteredEmails.map((email, idx) => (
                  <div
                    className={`inbox-item ${!error && selectedEmailIdx === idx ? 'selected' : ''}`}
                    key={idx}
                    onClick={() => {
                      setSelectedEmailIdx(idx);
                      history.replace(`/abuse-mailbox/all-email-submissions?id=${email.id}`);
                      if (error) setError(null);
                    }}
                    data-testid='inbox-item'
                  >
                    {/* selected indicator */}
                    {!error && selectedEmailIdx === idx && (
                      <div
                        className={`inbox-item-selected-indicator disposition_${email.disposition?.toLowerCase()}`}
                        data-testid='selected-indicator'
                      ></div>
                    )}
                    <div className='inbox-item-header-title long-text-ellipsis-1'>
                      {email.subject}
                    </div>
                    <div className='d-flex inbox-item-header-date-container'>
                      <div className='inbox-item-header-date long-text-ellipsis-1'>
                        {formatDateToUpperCaseLocaleString(email.submissionDate)}
                      </div>
                      <DispositionStatus status={email.disposition?.toLowerCase()} />
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          {error ? (
            <div className='inbox-item-details abuse-mailBox-emailDetails-container'>
              <Alert severity='error'>{error}</Alert>
            </div>
          ) : !filteredEmails.length ? (
            <AllEmailSubmissionsNoDataView />
          ) : (
            <div className='inbox-item-details abuse-mailBox-emailDetails-container'>
              <EmailDetailsAnalysisContainer
                abuseMailBoxEmailDetails={
                  massagedAbuseMailBoxEmailDetails(emailDetails)[selectedEmailIdx] || {}
                }
              />
            </div>
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
}

export default AllEmailAnalysis;
