import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './darkwebfindings.scss';
import { STATUS_COLUMN_DYNAMIC_RENDER } from '../../../DarkWeb/Components/Common/TableColDefs/DarkWebColDefs';
import _, { capitalize, find, map } from 'lodash';
import { Dropdown } from '../../../Common/Dropdown';
import {
  EDarkWebFindingStatus,
  IDarkWebFindingData,
} from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { connect } from 'react-redux';
import { alertActions } from '../../../../actions';
import {
  appConstants,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
} from '../../../../constants';
import { Card } from 'react-bootstrap';
import { TableContextProvider } from '../../../Common/Table/table.context';
import { DARK_WEB_TABLE_IDS, DARK_WEB_TABLE_NAME } from '../../../DarkWeb/DarkWebConstants';
import DarkWebFindingStatus from '../../../DarkWeb/Components/Common/DarkWebFindingStatus';
import Tags, { ITags, ETagsTypes } from '../../../Common/Tags/Tags';
import {
  TAGS_COLUMN,
  TAGS_AUTHOR_COLUMN,
} from '../../../MonitorAndTakedown/ColDefinition/malicious.columns';
import { AppState } from '../../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { ENTITY_MAPPER, ENTITY_TYPE } from '../../DarkWebConstants';
import AgGridDarkWebTable from '../../../Common/Table/ag-table/AgGridDarkWebTable';
import AgGridDarkWebFlatTable from '../../../Common/Table/ag-table/AgGridDarkWebTableFlatView';
import ChangeStatusModal from '../../../DarkWeb/Components/Common/ChangeStatusModal';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { convertToFetchDarkWebApiParams } from '../../../Common/Table/ag-table/ag-utils';
import {
  ExportFindingsDarkWebV2HistoryModal,
  IExportFindingsDarkWebV2HistoryModalDependencies,
} from './ExportFindingsDarkWebV2HistoryModal';
import { FilterModel } from 'ag-grid-community';
import { EBulkActionModalType } from '../../../Ugc/Components/Findings/FindingsTableComponent';
import {
  setShouldRestorePageNumber,
  setTagsFromBulkAction,
} from '../../../../reducers/table.reducer';
import { useReadOnlyUser } from '../../../../basic-hooks/useUserRoles';
import {
  setCurrentGroupedSelectedFindings,
  setCurrentSingleSelectedFindings,
} from '../../../../reducers/darkWeb.reducer';
import PersistentFilters from '../../../Common/PersistentFilters/PersistentFilters';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

interface IProps {
  id: DARK_WEB_TABLE_IDS;
  status: EDarkWebFindingStatus;
  selectedEntity: ENTITY_TYPE;
  widgetDetails: any;
  setIsDarkWebTablesGroupView: (isDarkWebTablesGroupView: boolean) => void;
  onChangeStatusSuccess?: () => void;
  handleGroupFilterChange: (filterModel: FilterModel) => void;
}

const DarkWebFindingsTable = (props: IProps) => {
  const {
    id,
    status,
    selectedEntity,
    widgetDetails,
    setIsDarkWebTablesGroupView,
    onChangeStatusSuccess,
    handleGroupFilterChange,
  } = props;

  const [isDarkMode] = useIsDarkMode();
  const [newStatus, setNewStatus] = useState<EDarkWebFindingStatus>(status);
  const [changeStatusModalShown, setChangeStatusModalShown] = useState<boolean>(false);
  const displayLayoutRef = useRef(true);
  const [exportDependencies, setExportDependencies] = useState<
    IExportFindingsDarkWebV2HistoryModalDependencies | undefined
  >(undefined);
  const [bulkActionModal, setBulkActionModal] = useState<EBulkActionModalType>(
    EBulkActionModalType.None,
  );
  const [tagsAnchorEl, setTagsAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();

  const providedTags = useAppSelector((state: AppState) => state.tagsReducer.allPlatformTags);
  const usersList = useAppSelector((state: AppState) => state.dashboardReducer.usersList);
  const isDarkWebTablesGroupView = useAppSelector(
    (state: AppState) => state.tableReducer.isDarkWebTablesGroupView,
  );
  const outGoingPersistentFilterString = useAppSelector(
    (state: AppState) => state.tableReducer.outGoingPersistentFilterString,
  );

  const filteredTags = providedTags.filter(
    (tag: ITags) => tag.type !== ETagsTypes.BOLSTER_RESTRICTED,
  );
  const tagsDropdown = getProvidedTagLabelValue(filteredTags, isDarkMode);

  useEffect(() => {
    if (selectedEntity === ENTITY_TYPE.OTHERS) {
      setIsDarkWebTablesGroupView(false);
    } else {
      setIsDarkWebTablesGroupView(true);
    }
  }, [selectedEntity]);

  const toggleChangeStatusModal = () => {
    setChangeStatusModalShown(!changeStatusModalShown);
  };

  const onChangeStatusSuccessLogic = () => {
    dispatch(setCurrentGroupedSelectedFindings([]));
    dispatch(setCurrentSingleSelectedFindings([]));
    setChangeStatusModalShown(false);
    onChangeStatusSuccess && onChangeStatusSuccess();
    dispatch(setShouldRestorePageNumber(true));
  };

  const openExportModal = useCallback(() => {
    const searchTermIds: string[] = [];
    let searchTermsCount = 0;
    const columns: { label: string; field: string }[] = [];
    ENTITY_MAPPER[selectedEntity].columns.forEach(c => {
      if (c.header) {
        columns.push({
          label: c.header,
          field: c.accessor,
        });
      }
    });
    columns.push({
      label: 'Status',
      field: 'status',
    });
    Object.keys(widgetDetails.searchterms).map(k => {
      searchTermIds.push(widgetDetails.searchterms[k].searchTermId);
      searchTermsCount = searchTermsCount + widgetDetails.searchterms[k].totalFindings;
    });
    const apiParams = convertToFetchDarkWebApiParams(
      {
        sortModel: [],
        startRow: undefined,
        endRow: undefined,
        rowGroupCols: [],
        valueCols: [],
        pivotCols: [],
        groupKeys: [],
        pivotMode: false,
        filterModel: outGoingPersistentFilterString
          ? JSON.parse(outGoingPersistentFilterString)
          : null,
      },
      searchTermsCount,
      status,
      {
        ...ENTITY_MAPPER[selectedEntity].extraFilters,
        searchTermIds,
      },
      ENTITY_MAPPER[selectedEntity].additionalFields,
    );
    setExportDependencies({
      apiParams,
      columns,
      status,
      label: ENTITY_MAPPER[selectedEntity].label,
    });
  }, [status, outGoingPersistentFilterString, selectedEntity, widgetDetails]);

  const renderTable = () => {
    const columns = [];
    if (selectedEntity) {
      columns.push(
        ...ENTITY_MAPPER[selectedEntity].columns,
        STATUS_COLUMN_DYNAMIC_RENDER((data: IDarkWebFindingData) => {
          return (
            <DarkWebFindingStatus
              data={data}
              status={data.status}
              placement='right-start'
              showChevron
              onSubmit={onChangeStatusSuccessLogic}
            />
          );
        }),
        {
          ...TAGS_COLUMN,
          filterOptions: tagsDropdown,
          render: (rowData: any) => {
            return (
              <Tags
                leftAligned={displayLayoutRef.current}
                rowData={rowData}
                type={appConstants.CONTENT_TYPE.DARK_WEB}
              />
            );
          },
        },
        {
          ...TAGS_AUTHOR_COLUMN,
          filterOptions: getUsersListLabelValue(usersList),
        },
      );
      const searchTermsColumn: any = find(ENTITY_MAPPER[selectedEntity].columns, [
        'id',
        'search_term',
      ]);
      if (searchTermsColumn && widgetDetails !== undefined && 'searchterms' in widgetDetails) {
        (searchTermsColumn as any).filterOptions = Object.keys(widgetDetails.searchterms).map(k => {
          return {
            label: k,
            value: widgetDetails.searchterms[k].searchTermId,
          };
        });
      }
    }

    const extraFilters = ENTITY_MAPPER?.[selectedEntity]?.extraFilters ?? undefined;
    const additionalFields = ENTITY_MAPPER?.[selectedEntity]?.additionalFields ?? undefined;
    return (
      <Card className={'dashboard-table-container'}>
        <div className='table-component'>
          <div className='bs-table-container'>
            <TableContextProvider
              key={`${id}-${selectedEntity}`}
              columns={columns as any}
              dashboardName={DARK_WEB_TABLE_NAME}
              tableId={`${id}-${selectedEntity}`}
            >
              {isDarkWebTablesGroupView ? (
                <AgGridDarkWebTable
                  key={`${id}-${selectedEntity}`}
                  darkwebStatus={status}
                  widgetDetails={widgetDetails}
                  extraFilters={extraFilters}
                  additionalFields={additionalFields}
                  handleGroupFilterChange={handleGroupFilterChange}
                  currentTableId={id}
                />
              ) : (
                <AgGridDarkWebFlatTable
                  columns={columns as any}
                  key={`${id}-${selectedEntity}`}
                  darkwebStatus={status}
                  widgetDetails={widgetDetails}
                  extraFilters={extraFilters}
                  currentTableId={id}
                />
              )}
            </TableContextProvider>
          </div>
        </div>
      </Card>
    );
  };

  const bulkActionDropDownOptions = map(EDarkWebFindingStatus, value => ({
    label: `Mark as ${capitalize(value.toLowerCase())}`,
    value,
    disabled: value === status,
  }));

  const onChangeBulkActionHandler = (event: any) => {
    toggleChangeStatusModal();

    setNewStatus(event.value);
    setBulkActionModal(event.value);
  };

  const isReadOnlyUser = useReadOnlyUser();

  const currentGroupedSelectedFindings = useAppSelector(
    state => state.darkWebReducer.currentGroupedSelectedFindings,
  );
  const currentSingleSelectedFindings = useAppSelector(
    state => state.darkWebReducer.currentSingleSelectedFindings,
  );
  const selectedItems = useMemo(() => {
    const allSelected = [...currentGroupedSelectedFindings, ...currentSingleSelectedFindings];
    const uniqAllSelected = _.uniq(allSelected);
    return uniqAllSelected.map((sha256: string) => {
      return {
        sha256,
      };
    });
  }, [currentGroupedSelectedFindings, currentSingleSelectedFindings]);
  return (
    <>
      <div className='mb-3 mt-3 tools-container d-flex justify-content-end align-content-center'>
        <div className='d-flex align-items-center tool-buttons-wrapper mr-3'>
          <PersistentFilters tableId={id} moduleType={appConstants.CONTENT_TYPE.DARK_WEB} />
        </div>
        <div
          key={'Export CSV'}
          onClick={openExportModal}
          className='export-wrapper d-flex align-items-center tool-buttons-wrapper mr-3 export-csv-button'
        >
          <FileDownloadOutlinedIcon className='export-icon' />
          <div className='pl-2 export-label'>Export CSV</div>{' '}
        </div>
        <Dropdown
          boxStyle
          variant='outlined'
          fixedPlaceholder={'BULK ACTION'}
          key={'change dark web status'}
          options={bulkActionDropDownOptions}
          disabled={selectedItems.length === 0 || isReadOnlyUser}
          onChange={event => onChangeBulkActionHandler(event)}
        />
      </div>
      {renderTable()}
      <ChangeStatusModal
        data={selectedItems}
        show={changeStatusModalShown}
        status={newStatus}
        onCancel={toggleChangeStatusModal}
        onSubmit={onChangeStatusSuccessLogic}
      />
      <ExportFindingsDarkWebV2HistoryModal
        show={exportDependencies !== undefined}
        dependencies={exportDependencies}
        onComplete={() => setExportDependencies(undefined)}
        onCancel={() => setExportDependencies(undefined)}
      />
      {bulkActionModal === 'Add Tags' && (
        <Tags
          fromAgGrid
          agAnchorEl={tagsAnchorEl}
          rowData={selectedItems}
          showTagsOverlay={true}
          type={appConstants.CONTENT_TYPE.DARK_WEB}
          bulkOption
          onClose={() => {
            setBulkActionModal(EBulkActionModalType.None);
          }}
          getUpdatedTags={async (updatedTagsRes: any) => {
            dispatch(setTagsFromBulkAction(updatedTagsRes));
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  alertSuccess: alertActions.success,
  alertError: alertActions.error,
};
export default connect(undefined, mapDispatchToProps)(DarkWebFindingsTable);
