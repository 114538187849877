import { useEffect, useState } from 'react';
import { getLocalStorageValue } from '../../../../constants';
import { useAppSelector } from '../../../../helpers/hooks';

export const useNumOfCategoryFltrConditions = ({ tableId }: { tableId: string }) => {
  const { numOfOtherCategories } = useAppSelector(state => state.tableReducer);

  const [savedNumOfCategoryFltrConditions, setSavedNumOfCategoryFltrConditions] = useState(0);

  useEffect(() => {
    getNumOfCategoryConditions();
  }, [tableId, savedNumOfCategoryFltrConditions]);

  const getNumOfCategoryConditions = () => {
    const filterConditions = getLocalStorageValue(['savedNumOfOtherCategories', tableId]);
    const numOfOtherFilterConditions =
      typeof filterConditions === 'object' && Object.keys(filterConditions).length === 0
        ? numOfOtherCategories
        : filterConditions;
    setSavedNumOfCategoryFltrConditions(numOfOtherFilterConditions);
  };

  return { savedNumOfCategoryFltrConditions };
};
