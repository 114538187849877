import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './darkwebfindings.scss';
import { capitalize } from 'lodash';
import { EDarkWebFindingStatus } from '../../../DarkWeb/Components/Types/DarkWeb.types';
import { LoadingWrapper } from '../../../Common/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../../Common/PageTitle';
import { useAppDispatch } from '../../../../helpers/hooks';
import {
  setIsDarkWebTablesGroupView,
  setIsFilterFromDashboard,
} from '../../../../reducers/table.reducer';
import { ENTITY_TYPE } from '../../DarkWebConstants';
import { DarkWebFindingsWidget, ENTITY_IMAGE_MAPPER } from './DarkWebFindingsWidget';
import DarkWebFindingsTable from './DarkWebFindingsTable';
import moment from 'moment';
import { transformFilterToDarkWebApiFilterString } from '../../../Common/Table/ag-table/ag-filter-to-api-params';
import { useAppSelector } from '../../../../helpers/hooks';
import _ from 'lodash';
import { defaultDarkWebEntityWidgetDetails } from '../../../../constants/darkWeb.constants';
import { getBreachDescriptions, getFindingsWidget } from '../Common/darkweb-requests';
import { setBreachDescriptions } from '../../../../reducers/darkWeb.reducer';
import SingleFindingDataLeakPopup from '../Common/SingleFindingDataLeakPopup';
import { Nullable } from '../../../../types/common';
import { alertActions } from '../../../../actions';
import useOnSelectingEntity from './useOnSelectingEntity';
import { DARK_WEB_TABLE_IDS } from '../../../DarkWeb/DarkWebConstants';
import useIsDarkMode from '../../../Common/CustomHooks/useIsDarkMode';

interface IDarkWebFindingsV2 {
  id: DARK_WEB_TABLE_IDS;
  status: EDarkWebFindingStatus;
}
const DarkWebFindingsV2 = (props: IDarkWebFindingsV2) => {
  const { id, status } = props;
  const [widgetSummaryDetails, setWidgetSummaryDetails] = useState<any>(undefined);
  const [entityWidgetDetails, setEntityWidgetDetails] = useState<any>(undefined);
  const [widgetFilterString, setWidgetFilterString] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const history = useHistory();
  const [, selectedTheme] = useIsDarkMode();
  const [apiTs, setApiTs] = useState<number>(moment().valueOf());
  const selectedEntity = useAppSelector(
    (state: any) => state.darkWebReducer.selectedEntityCard[status],
  );

  const title = `${capitalize(status.toLowerCase())} Findings`;
  window.document.title = `${title} Findings | Dark Web`;

  const dispatch = useAppDispatch();

  const { setSelectedEntity } = useOnSelectingEntity({ status });

  useEffect(() => {
    try {
      const fetchbBreachDescriptions = async () => {
        const response = await getBreachDescriptions();
        if (response) {
          dispatch(setBreachDescriptions(response));
        }
      };
      void fetchbBreachDescriptions();
    } catch (error) {
      dispatch(alertActions.error('Error in fetching breach descriptions'));
    }
  }, [dispatch]);

  const fetchWidgetDetails = useCallback(
    async (widgetFilterString?: string) => {
      setTableLoading(true);
      try {
        const response = await getFindingsWidget(status, widgetFilterString);
        const keys = new Set(Object.keys(response));
        const sequenceKeys = Object.keys(ENTITY_IMAGE_MAPPER).filter(e => keys.has(e));
        if (sequenceKeys.length > 0) {
          setWidgetSummaryDetails(response);

          //Get the entity from the query params when user navigates from dashboard
          const urlParams = new URLSearchParams(history.location.search);
          history.replace({
            search: '',
          });
          let entity = urlParams.get('entity');
          if (!entity || (entity && !sequenceKeys.includes(entity))) {
            entity = _.isEmpty(selectedEntity) ? sequenceKeys[0] : selectedEntity;
          }
          setSelectedEntity(entity as ENTITY_TYPE);
          if (response[entity as ENTITY_TYPE]) {
            setEntityWidgetDetails(response[entity as ENTITY_TYPE]);
          } else {
            // Special case if use selected all the findings and moved to other status
            // Then use the first entity of the widget details
            const defaultEntity = sequenceKeys[0];
            if (response[defaultEntity]) {
              setSelectedEntity(defaultEntity as ENTITY_TYPE);
              setEntityWidgetDetails(response[defaultEntity]);
            } else {
              setEntityWidgetDetails(defaultDarkWebEntityWidgetDetails);
            }
          }
        } else {
          setWidgetSummaryDetails({});
          setEntityWidgetDetails(defaultDarkWebEntityWidgetDetails);
        }
      } catch (error) {
        dispatch(alertActions.error('Error in fetching widget details'));
      } finally {
        setTableLoading(false);
        setIsLoading(false);
      }
    },
    [dispatch, history, selectedEntity, setSelectedEntity, status],
  );

  useEffect(() => {
    if (!selectedEntity) return;
    if (selectedEntity === ENTITY_TYPE.OTHERS) {
      dispatch(setIsDarkWebTablesGroupView(false));
    } else {
      dispatch(setIsDarkWebTablesGroupView(true));
    }
  }, [dispatch, selectedEntity]);

  const onChangeStatusSuccess = () => {
    setTimeout(() => {
      setApiTs(moment().valueOf());
    }, 1000);
  };

  const shouldShowDataLeakTablePopup = useAppSelector(
    (state: any) => state.darkWebReducer.shouldShowDataLeakTablePopup,
  );

  //On click entity widget
  const handleOnWidgetDetailsClick = useCallback(
    (entity: Nullable<ENTITY_TYPE>) => {
      if (!entity || !widgetSummaryDetails) return;
      setSelectedEntity(entity);
      if (entity === ENTITY_TYPE.OTHERS) {
        setEntityWidgetDetails(widgetSummaryDetails[entity]);
        return;
      } else {
        if (widgetSummaryDetails[entity]) {
          setEntityWidgetDetails(widgetSummaryDetails[entity]);
        } else {
          setEntityWidgetDetails(defaultDarkWebEntityWidgetDetails);
        }
      }
    },
    [setSelectedEntity, widgetSummaryDetails],
  );

  useEffect(() => {
    setIsLoading(true);
    void fetchWidgetDetails(widgetFilterString?.replace('tags', 'tags.id'));

    return () => {
      setIsLoading(false);
      setTableLoading(false);
    };
  }, [fetchWidgetDetails, widgetFilterString, apiTs]);

  const currentSelectedEntity = useMemo(() => {
    if (!selectedEntity || !widgetSummaryDetails) {
      return undefined;
    }
    if (widgetSummaryDetails[selectedEntity]) {
      return selectedEntity;
    } else {
      return Object.keys(widgetSummaryDetails)[0];
    }
  }, [selectedEntity, widgetSummaryDetails]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div
        key={`findings-container-${status}`}
        className={`${selectedTheme} page-content darkweb-v2-dashboard-page ${
          shouldShowDataLeakTablePopup ? 'darkweb-v2-dashboard-resize' : ''
        }`}
        data-testid='dark-web-findings-v2'
      >
        <PageTitle
          title={`${status.toLowerCase()} Findings`}
          className='mb-3 pb-3 findings-title'
        />
        <React.Fragment>
          <DarkWebFindingsWidget
            widgetDetails={widgetSummaryDetails}
            selectedEntity={currentSelectedEntity}
            handleOnWidgetDetailsClick={handleOnWidgetDetailsClick}
          />
          <LoadingWrapper isLoading={tableLoading}>
            <DarkWebFindingsTable
              id={id}
              key={selectedEntity}
              setIsDarkWebTablesGroupView={setIsDarkWebTablesGroupView}
              status={status}
              selectedEntity={selectedEntity}
              widgetDetails={entityWidgetDetails}
              onChangeStatusSuccess={onChangeStatusSuccess}
              handleGroupFilterChange={filterModel => {
                setWidgetFilterString(transformFilterToDarkWebApiFilterString(filterModel));
                dispatch(setIsFilterFromDashboard(true));
              }}
            />
          </LoadingWrapper>
        </React.Fragment>

        <SingleFindingDataLeakPopup />
      </div>
    </LoadingWrapper>
  );
};

export default DarkWebFindingsV2;
