import React, { useContext } from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  ICheckPhishSubscription,
  CheckphishSubscriptionContext,
} from '../../../context/CheckPhishContactUsContext';
import { NumberOfDomains } from '../constants';

type Props = {};
const DomainSelector = (props: Props) => {
  const { numberOfDomains, setNumberOfDomains } = useContext<ICheckPhishSubscription>(
    CheckphishSubscriptionContext,
  );

  const onChangeHandler = (event: SelectChangeEvent<number>) => {
    const selectedNumberOfDomain = event.target.value;
    if (selectedNumberOfDomain === numberOfDomains) return;
    setNumberOfDomains(selectedNumberOfDomain);
  };

  return (
    <div className='domain-select-wrap' data-testid='domain-selector-wrap'>
      <FormControl size='small'>
        <Select
          id='number_of_domain_selector'
          value={numberOfDomains}
          defaultValue={numberOfDomains}
          variant='outlined'
          data-testid='domain-selector'
          style={{
            margin: '0 5px',
          }}
          className={`select-domain-input-box`}
          onChange={onChangeHandler}
        >
          {NumberOfDomains.map((domains: number, index: number) => (
            <MenuItem value={domains} data-testid='domain-select-value' key={`domain-${index}`}>
              {domains}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {numberOfDomains === 1 ? 'Domain' : 'Domains'}
    </div>
  );
};

export default DomainSelector;
