import React from 'react';
import _ from 'lodash';
import './table.scss';
import Button from 'react-bootstrap/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '../../../assets/icons/Close.svg';
import DragIcon from '../../../assets/icons/Drag.svg';
import { Form } from 'react-bootstrap';
import { appConstants, DashBoardDto, UserAppSettings } from '../../../constants';
import { AppState } from '../../../helpers';
import { connect } from 'react-redux';
import UserService from '../../../services/user.service';
import { dashboardActions } from '../../../actions';

const getHiddenColumns = (columns: object[], displayedColumnsIDs: string[]) => {
  const hiddenColumnsIDs: string[] = [];
  _.forEach(columns, column => {
    if (displayedColumnsIDs.indexOf(column['id']) === -1 && !column['hidden']) {
      if (!hiddenColumnsIDs.includes(column['id'])) {
        hiddenColumnsIDs.push(column['id']);
      }
    }
  });
  return hiddenColumnsIDs;
};
interface ITableColumnItemProps {
  label: string;
  index: string;
  isDragDisabled?: boolean;
  opt: any;
}

class TableColumnItem extends React.PureComponent<ITableColumnItemProps> {
  render() {
    const { label, index, isDragDisabled = false, opt } = this.props;
    let itemClassName = 'column-item-container ' + (opt && opt.className);
    if (isDragDisabled) {
      itemClassName += ' opacity-05';
    }
    return (
      <Draggable
        draggableId={label}
        index={parseInt(index, 10)}
        key={label}
        isDragDisabled={isDragDisabled}
      >
        {provided => (
          <div className={itemClassName} {...provided.draggableProps} ref={provided.innerRef}>
            <span>{label}</span>
            <img src={DragIcon} alt={'drag'} {...provided.dragHandleProps} />
          </div>
        )}
      </Draggable>
    );
  }
}

interface ITableColumnsControllerProps {
  columns: object[];
  displayedColumnsIDs: string[];
  onClose: () => void;
  onSubmitColumnsConfig: (displayedColumnsIDs: string[]) => void;
  user: any;
  getUserInfo: () => void;
  type?: string;
  resetPageOnViewChanged?: () => void;
}

interface IComponentState {
  groupFindingsSwitchValue: boolean;
}

class TableColumnsController extends React.Component<
  ITableColumnsControllerProps,
  IComponentState
> {
  static defaultProps = {
    columns: [],
  };
  private displayedColumnsIDs: string[] = [];
  private hiddenColumnsIDs: string[] = [];
  private readonly userService = new UserService();

  constructor(props: ITableColumnsControllerProps) {
    super(props);
    this.state = {
      groupFindingsSwitchValue: false,
    };
    _.forEach(props.displayedColumnsIDs, columnID => {
      if (_.findIndex(props.columns, ['id', columnID]) !== -1) {
        this.displayedColumnsIDs.push(columnID);
      }
    });
    this.hiddenColumnsIDs = getHiddenColumns(props.columns, this.displayedColumnsIDs);
  }

  onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return;
    }
    const column = _.find(this.props.columns, ['header', draggableId]);
    this[source.droppableId].splice(source.index, 1);
    this[destination.droppableId].splice(destination.index, 0, _.get(column, ['id']));
  };

  onSubmitColumnsConfig = () => {
    this.props.onSubmitColumnsConfig(this.displayedColumnsIDs);
  };

  renderColumn(title: string, items: any, columnId: string, opt: any = {}) {
    const { columns } = this.props;
    return (
      <div className={'column-container'}>
        <div className={'column-title'}>{title}</div>
        <Droppable droppableId={columnId}>
          {provided => (
            <div className={'column-content'} {...provided.droppableProps} ref={provided.innerRef}>
              {_.map(items, (item: any, index) => {
                let isDragDisabled = false;
                if (_.find(columns, { id: item, isDragDisabled: true })) {
                  isDragDisabled = true;
                }
                const column: any = _.find(columns, ['id', item]);
                const label = _.get(column, ['header'], item);
                if (!label || (column && column?.hiddenInDragDrop)) {
                  return null;
                }
                return (
                  <TableColumnItem
                    key={item}
                    label={label}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    opt={opt}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
  getSwitchValue = (user: DashBoardDto) => {
    const switchValue = _.find(this.props.user.userAppSetting, {
      setting_name: UserAppSettings.GroupFindings,
    });
    if (switchValue) {
      return switchValue.setting_value;
    }
    return false;
  };
  saveSwitchValue = () => {
    const currSwitchValue = this.state.groupFindingsSwitchValue;
    this.userService
      .updateUserSettings({
        setting_name: UserAppSettings.GroupFindings,
        setting_value: !currSwitchValue,
      })
      .then(res => {
        this.setState({ groupFindingsSwitchValue: !currSwitchValue });
        if (res) {
          this.props.getUserInfo();
          this.props.resetPageOnViewChanged && this.props.resetPageOnViewChanged();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  componentDidMount = () => {
    if (this.props.user) {
      const switchValue = this.getSwitchValue(this.props.user) === 'true' ? true : false;
      this.setState({ groupFindingsSwitchValue: switchValue });
    }
  };
  render() {
    const { onClose } = this.props;

    return (
      <div className={'table-columns-controller'}>
        <div className={'columns-editor-container'}>
          <img src={CloseIcon} alt={'close'} onClick={onClose} className={'cursor-pointer'} />
          {this.props?.type === appConstants.CONTENT_TYPE.WEB && (
            <div className={'group-findings-container'}>
              <div className={'columns-editor-header'}>
                <div className={'d-flex align-items-center'}>View Options</div>
              </div>
              <div className={'columns-editor-header'}>
                <div className={'d-flex align-items-center columns-title-color'}>
                  TABLE SETTINGS
                </div>
              </div>

              <div className={'columns-editor-header'}>
                <div className={'d-flex align-items-center'}>Group Findings on the same Domain</div>
                <Form.Check
                  type='switch'
                  id='group-findings-switch'
                  onChange={this.saveSwitchValue}
                  checked={this.state.groupFindingsSwitchValue}
                />
              </div>
            </div>
          )}
          <div className={'columns-editor-header'}>
            <div className={'d-flex align-items-center columns-title-color'}>EDIT COLUMNS</div>
            <img src={CloseIcon} alt={'close'} onClick={onClose} className={'cursor-pointer'} />
          </div>
          <div className={'columns-editor-description'}>
            Drag and drop columns into desired position
          </div>
          <div className={'columns-editor'}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              {this.renderColumn(
                'Current columns displayed and order',
                this.displayedColumnsIDs,
                'displayedColumnsIDs',
              )}
              {this.renderColumn('Hidden columns', this.hiddenColumnsIDs, 'hiddenColumnsIDs')}
            </DragDropContext>
          </div>
          <div className={'columns-editor-buttons'}>
            <Button
              variant='primary'
              onClick={this.onSubmitColumnsConfig}
              size='sm'
              className={'submit-button'}
            >
              Apply
            </Button>
            <Button
              variant='outline-secondary'
              onClick={onClose}
              size='sm'
              className={'cancel-button'}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.dashboardReducer;
  return {
    user,
  };
};

const mapDispatchToProps = {
  getUserInfo: dashboardActions.getUserInfo,
};

const connectedTableController = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableColumnsController);

export { connectedTableController as TableColumnsController };
