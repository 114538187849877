import React, { useEffect } from 'react';
import './AllUrlAnalysis.scss';
import { ALL_URL_ANALYSIS_COLUMNS, generateSourceUrlColumn } from './AllUrlAnalysisColDefs';
import { useDispatch } from 'react-redux';
import { setCurrentTableId } from '../../../../reducers/insightsContainer.reducer';
import {
  ABUSE_MAIL_BOX_MODULE_TYPE,
  ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID,
} from '../../constants';
import { TableContextProvider } from '../../../Common/Table/table.context';
import AgGridAbuseMailBoxAllUrlAnalysis from '../../../Common/Table/ag-table/AgGridAbuseMailBoxAllUrlAnalysisTable';
import moment from 'moment';

type Props = { startDate: moment.Moment; endDate: moment.Moment };

const AllUrlAnalysis = ({ startDate, endDate }: Props) => {
  window.document.title = 'All Email Submissions - All Url Analysis | Abuse Mailbox';

  const dispatch = useDispatch();

  const SOURCE_URL_COLUMN = generateSourceUrlColumn(startDate, endDate);
  const columns = [SOURCE_URL_COLUMN, ...ALL_URL_ANALYSIS_COLUMNS];

  useEffect(() => {
    dispatch(setCurrentTableId(ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID));
    return () => {
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  return (
    <div data-testid={ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID}>
      <TableContextProvider
        dashboardName={'Abuse Mailbox Targeted Malicious'}
        tableId={ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID}
      >
        <AgGridAbuseMailBoxAllUrlAnalysis
          columns={columns}
          tableId={ABUSE_MAILBOX_ALL_URL_ANALYSIS_TABLE_ID}
          moduleType={ABUSE_MAIL_BOX_MODULE_TYPE}
          startDate={startDate}
          endDate={endDate}
          style={{
            height: '85vh',
          }}
        />
      </TableContextProvider>
    </div>
  );
};

export default AllUrlAnalysis;
