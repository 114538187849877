import { EPlanProduct } from '../Common/Subscription';

export enum CHECKPHISH_USER_PRICING_STATE {
  STARTER = 'starter',
  TRIAL = 'trial',
  EXPIRED = 'expired',
}

export enum PRICING_PLANS {
  FREE = 'free',
  STARTER = 'starter',
  PREMIUM = 'premium',
  PROGESSIONAL_API = 'professional_api',
}

export interface IPrice {
  price: number;
  priceId?: number;
  subText?: string;
  duration: string;
  type: 'monthly' | 'yearly' | 'lifetime' | 'none';
}

export interface CustomerInfo {
  name: string;
  city: string;
  country: string;
  line1: string;
  line2: string;
  state: string;
}

export interface IPlan {
  priceId: number;
  name: string;
  description: string;
  term: PlanTerm;
  limit: number;
  price: number;
}

export interface ISubscription {
  priceId: number;
  quantity: number;
}

export interface ISubscriptionItem extends ISubscription {
  price: number;
  term: string;
}

export enum HUBSPOT_FORM_TYPE {
  PRO = 'pro',
  PREMIUM = 'premium',
  TAKEDOWN = 'takedown',
}

export interface IDiscount {
  minQuantity: number;
  price: number;
}

export interface IPricingPlan {
  priceId: number;
  name: string;
  description: string;
  term: term;
  limit: number;
  price: number;
  discounts: IDiscount[] | null;
  product: EPlanProduct;
}

export interface ISubsciptionPlan extends IPricingPlan {
  quantity: number;
}

export enum PlanTerm {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type term = PlanTerm;

export type HubspotFormDetials = {
  showForm: boolean;
  formId: string;
};
