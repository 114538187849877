import React, { useMemo } from 'react';
import _, { isEqual } from 'lodash';
import isLockedLight from '../../assets/icons/TagLockLight.svg';
import isLockedDark from '../../assets/icons/TagLockDark.svg';
import { ThemeModes } from '../../constants';
import './LockImage.scss';
import useIsDarkMode from './CustomHooks/useIsDarkMode';

type Props = {};

const LockImage = (props: Props) => {
  const [, selectedTheme] = useIsDarkMode();
  const lightThemeLockColor = isEqual(selectedTheme, 'light') && 'lock-icon-image';
  const LockImage = useMemo(() => {
    return _.isEqual(selectedTheme, ThemeModes.LIGHT.toLocaleLowerCase())
      ? isLockedLight
      : isLockedDark;
  }, [selectedTheme]);

  return (
    <img
      src={LockImage}
      alt='Lock Image'
      className={`${lightThemeLockColor ? `lock-img ${lightThemeLockColor}` : 'lock-img'}`}
      data-testid='lock-img'
    />
  );
};

export default LockImage;
