import moment from 'moment';

export enum AgAnyTagsFilterApiStringTypes {
  NOT_OR_NULL, // not.or=(tag_ids.is.null)
  IN_WITH_COMMAS, // ags.id=in.(67,72)
  IN_WITH_OR, // tags.id: "(40 OR 67)"
}

export enum AgFilterDisplayOptions {
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  BEGINS_WITH = 'Begins with',
  NOT_BEGIN_WITH = 'Does not begin with',
}

export enum AgDateRangeFilterRanges {
  ONE_DAY = 'Last 1 Day',
  SEVEN_DAYS = 'Last 7 Days',
  THIRTY_DAYS = 'Last 30 Days',
  NINTY_DAYS = 'Last 90 Days',
  ONE_EIGHTY_DAYS = 'Last 180 Days',
  TWELEVE_MONTHS = 'Last 12 Months',
  CUSTOM = 'Custom',
}

export const getDefaultStartDate = (startDate?: moment.Moment) => {
  return startDate
    ? moment(startDate).format('YYYY-MM-DD')
    : moment().subtract(365, 'days').format('YYYY-MM-DD');
};

export const getDefaultEndDate = (endDate?: moment.Moment) => {
  return moment(endDate).format('YYYY-MM-DD');
};

export const UTCTimeFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const getDefaultUTCStartDate = (format?: string) => {
  return moment().subtract(365, 'day').utc().format(format);
};

export const getDefaultUTCEndDate = (format?: string) => {
  return moment().utc().format(format);
};

export const getDefaultScanStartDate = () => {
  return moment().subtract(2, 'years').format('YYYY-MM-DD');
};

export const TABLE_EMPTY_RESULTS_MESSAGE =
  'Nothing to display in the table at the moment. This could be a result of applied filters or a temporary absence of data.';

export enum MultiFilterConditions {
  MAX_NUM_CONDITIONS = 3,
  MIN_NUM_CONDITIONS = 1,
}

export const paginationPageSizeSelector = [15, 50, 100];

export const othersCategoryLabel = 'OTHERS';

export const catergoryLabel = 'Category';
