import moment from 'moment';
import { IBrandInfo, capitalize, getUsersListLabelValue } from '../../constants';
import {
  TakedownSubmittedByOptions,
  getMappedValues,
  globalTakeDownAccountActions,
  globalTakeDownActionDescription,
  latestGtdActions,
} from './constants';
import { ITakedownActions, ITakedownEvents } from './TakedownTimelineContainer';
import { store } from '../../helpers';

export const actionMapping = (numberOfActions: number): string => {
  if (numberOfActions === 1) {
    return globalTakeDownActionDescription.FIRST_NOTICE;
  } else {
    return globalTakeDownActionDescription.FOLLOW_UP;
  }
};

export const mapLatestActions = (
  latestAction: string,
  latestActionCount: number | undefined,
  isReplied: boolean | undefined,
  brandInfo: IBrandInfo | undefined,
) => {
  if (!latestAction && !latestActionCount) {
    return '--';
  }
  if (latestAction === EGTDTakedownStatus.TAKEN_DOWN) {
    return 'Takendown';
  }
  const latestActionMap = getMappedValues(latestGtdActions);
  if (isReplied && latestAction in latestActionMap) {
    return `${globalTakeDownActionDescription.REPLIED} to ${latestActionMap[latestAction]}`;
  }

  const latestActions = latestAction?.toUpperCase();
  if (latestActions && latestActions in globalTakeDownActionDescription) {
    return globalTakeDownActionDescription[latestActions];
  }

  if (latestActions && latestActions in globalTakeDownAccountActions) {
    return `${globalTakeDownAccountActions[latestActions]} ${brandInfo?.brand?.displayName}`;
  }

  return latestActionCount !== undefined && latestAction in latestActionMap
    ? `${actionMapping(latestActionCount)} to ${latestActionMap[latestAction]}`
    : '--';
};

export const massageGlobalTakeDownWebData = (
  webTakeDownData: any[],
  brandInfo: IBrandInfo | undefined,
) => {
  const globalTakeDownStatusMap: { [key: string]: string } = {
    complete: EGTDTakedownStatus.TAKEN_DOWN,
    down: EGTDTakedownStatus.TAKEN_DOWN,
    pending: EGTDTakedownStatus.IN_PROGRESS,
  };

  const usersList = store.getState().dashboardReducer?.usersList || {};
  const globalTakeDownSubmissionMap = getMappedValues([
    ...TakedownSubmittedByOptions,
    ...getUsersListLabelValue(usersList),
  ]);

  return webTakeDownData.map(webData => {
    const allTakedownActions = webData?.takedown_actions ?? {};
    const latestAction = webData?.latest_gtd_action;
    const takeDownActions = allTakedownActions?.[latestAction];
    const latestActionCount = takeDownActions ? takeDownActions.length : undefined;
    const isReplied = webData?.latest_gtd_reply;
    const returnTextToDisplay = mapLatestActions(
      latestAction,
      latestActionCount,
      isReplied,
      brandInfo,
    );
    const globalTakeDownStatus =
      globalTakeDownStatusMap[webData?.global_takedown_status] || webData?.global_takedown_status;
    if (globalTakeDownStatus === EGTDTakedownStatus.TAKEN_DOWN && webData?.takedown_ts) {
      allTakedownActions[EGTDTakedownStatus.TAKEN_DOWN] = [
        {
          created_ts: webData?.takedown_ts,
          reply: false,
        },
      ];
    }
    const scanSource = webData?.scan_source;
    const sortedEvents = massagedGlobalEventsAndSort(allTakedownActions, brandInfo);
    let monthlyTakedownEvents = {} as ITakedownActions;
    let yearlyTakedownEvents = {} as ITakedownActions;
    if (
      sortedEvents.length > 0 &&
      sortedEvents !== undefined &&
      sortedEvents[0].createdts &&
      sortedEvents[sortedEvents.length - 1].createdts
    ) {
      if (isSameMonth(sortedEvents[0].createdts, sortedEvents[sortedEvents.length - 1].createdts)) {
        monthlyTakedownEvents = massagedMonthlyEvents(sortedEvents);
      }
      if (
        !isSameMonth(sortedEvents[0].createdts, sortedEvents[sortedEvents.length - 1].createdts)
      ) {
        yearlyTakedownEvents = massagedYearlyEvents(sortedEvents);
      }
    }

    return {
      ...webData,
      latest_gtd_action: returnTextToDisplay,
      global_takedown_status: globalTakeDownStatus,
      scan_source: capitalize(scanSource),
      gtd_submitted_by:
        globalTakeDownSubmissionMap[webData?.gtd_submitted_by] ||
        globalTakeDownSubmissionMap[webData?.gtd_submitted_by_id],
      monthlyTakedownEvents,
      yearlyTakedownEvents,
    };
  });
};

export const isSameMonth = (date1: string, date2: string) => {
  return moment(date1).isSame(date2, 'month');
};

const massagedYearlyEvents = (events: ITakedownEvents[]) => {
  const months: number[] = [];
  events.forEach(event => {
    months.push(moment(event.createdts).month() + 1);
  });
  const mapHash: ITakedownActions = { currentMonth: '', events: {} };

  months.forEach(month => {
    if (!mapHash.events[monthMap[month]]) {
      mapHash.events[monthMap[month]] = [];
    }
  });
  events.forEach((event: any) => {
    const presentMonth = moment(event.createdts).month() + 1;
    const month = monthMap[presentMonth];
    mapHash.events[month].push(event);
  });
  mapHash.currentMonth = `${moment(events[0].createdts).format('MMMM, DD - YYYY')} - ${moment(
    events[events.length - 1].createdts,
  ).format('MMMM, DD - YYYY')}`;

  return mapHash;
};

export const massagedMonthlyEvents = (events: ITakedownEvents[]) => {
  const days = moment(events?.[0]?.createdts).daysInMonth();
  const currentMonth = moment(events[0].createdts).format('MMMM, YYYY');
  const mapHash: ITakedownActions = { currentMonth, events: {} };
  for (let i = 1; i <= days; i++) {
    mapHash.events[i] = [];
  }
  events.forEach((event: any) => {
    const currentDay = moment(event.createdts).date();
    mapHash.events[currentDay].push(event);
  });
  return mapHash;
};

const massagedGlobalEventsAndSort = (
  events: { [key: string]: any[] },
  brandInfo: IBrandInfo | undefined,
): ITakedownEvents[] => {
  const takeDownEvents: ITakedownEvents[] = [];
  if (!events || events === undefined) {
    return [];
  }

  Object.keys(events).forEach(key => {
    events[key].forEach((action: any, index: number) => {
      const actionKey = key === EGTDTakedownStatus.REPLY ? action.entity : key;
      const isReplied = key === EGTDTakedownStatus.REPLY ? true : action.replied;
      takeDownEvents.push({
        action: actionKey,
        createdts: action.created_ts,
        label: mapLatestActions(actionKey, index + 1, isReplied, brandInfo),
      });
    });
  });

  //sort the events by createdts with moment
  takeDownEvents.sort((a, b) => {
    return moment(a.createdts).diff(moment(b.createdts));
  });

  return takeDownEvents;
};
export const monthMap: { [key: number]: string } = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const labelToNumberMap: { [key: string]: number } = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

export enum EGTDTakedownStatus {
  TAKEN_DOWN = 'taken_down',
  IN_PROGRESS = 'in_progress',
  REPLY = 'reply',
}
