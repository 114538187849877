import React, { useState } from 'react';
import { getScreenshotUrl } from '../../../helpers/screenshotUrl';
import { ON_ERROR_IMAGE } from '../../Common/Image';
import { EmailDetailsParams } from '../constants';
import './EmailsListViewPanel.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from '../../../helpers/hooks';

interface IEmailsListViewPanelProps {
  abuseMailBoxEmailDetails: EmailDetailsParams[];
  handleEmailSelection: (email: EmailDetailsParams) => void;
  setVisiableEmailCount: React.Dispatch<React.SetStateAction<number>>;
  totalEmailsCount: number;
}

export const EmailsListViewPanel: React.FC<IEmailsListViewPanelProps> = ({
  abuseMailBoxEmailDetails,
  handleEmailSelection,
  setVisiableEmailCount,
  totalEmailsCount,
}) => {
  const [selectedEmailIndex, setSelectedEmailIndex] = useState<number>(0);

  const onEmailSelection = (email: EmailDetailsParams, index: number) => {
    setSelectedEmailIndex(index);
    handleEmailSelection(email);
  };

  const isDemoBrand = useAppSelector(
    state => state.dashboardReducer.brandInfo?.brand?.brandName === 'bankofbolster',
  );

  return (
    <div className='emails-list-view-container'>
      {abuseMailBoxEmailDetails.length === 0 ? (
        <p>No emails found</p>
      ) : (
        <>
          <div className='emails-list-view-count-conatiner'>
            <span className='emails-list-view-count'>
              {selectedEmailIndex + 1} / {totalEmailsCount}
            </span>
          </div>
          <div className='emails-list-view-panel' data-testid='emails-list-view-panel'>
            <div className='emails-list-view-panel-content' id='insights-emails-scrollable-div'>
              <InfiniteScroll
                dataLength={abuseMailBoxEmailDetails.length}
                next={() => {
                  setVisiableEmailCount(prev => prev + 25);
                }}
                hasMore={abuseMailBoxEmailDetails.length < totalEmailsCount}
                loader={<h4></h4>}
                scrollableTarget='insights-emails-scrollable-div'
              >
                {abuseMailBoxEmailDetails.map((email, index) => (
                  <li
                    key={index}
                    data-testid={email.id}
                    className={`emails-list-view-item ${
                      selectedEmailIndex === index ? 'selected' : ''
                    }`}
                    onClick={() => onEmailSelection(email, index)}
                  >
                    <div className='email-list-view-item-details'>
                      <img
                        src={!isDemoBrand ? getScreenshotUrl(email?.screenShot) : email?.screenShot}
                        alt='Email Screenshot'
                        width={160}
                        height={90}
                        className='mb-2'
                        onError={e => {
                          e.currentTarget.src = ON_ERROR_IMAGE;
                        }}
                      />
                    </div>
                  </li>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
