import {
  Attachment,
  Language,
  AttachFile,
  Phone,
  WebAsset,
  PhotoSizeSelectActualOutlined,
} from '@mui/icons-material';

export const emailDispositionOptions = new Set(['Malicious', 'Suspicious', 'Clean']);

export const emailDispositionToValue: Record<string, string> = {
  Malicious: 'malicious',
  Suspicious: 'suspicious',
  Clean: 'clean',
};

export const emailCategoryOptions = new Set([
  'Targeted Malicious',
  'Generic Malicious',
  'Official Emails',
  'Other',
]);

export enum EInternalUserFilter {
  NEEDS_REVIEW = 'Needs Review',
}

export const internalUserFilterOptions = [EInternalUserFilter.NEEDS_REVIEW];

export const internalUserFilterValue = {
  [EInternalUserFilter.NEEDS_REVIEW]: {
    needReview: true,
  },
};

export const allEmailfilterOptions = [...emailDispositionOptions, ...emailCategoryOptions];

export const allEmailSubmissionsNoDataMainText = 'No results found';

export const allEmailSubmissionsNoDataSubText = 'Try refining your filter for better results.';

export const ALL_EMAIL_PAGE_SIZE = 25;

export const ALL_EMAIL_SUBMISSIONS_SUBPATH = 'all-email-submissions';

export enum ALL_EMAIL_SUBMISSION_SECTIONS_TYPES {
  URL = 'url',
  SENDER_DOMAIN = 'sender_domain',
  ATTACHMENT = 'attachment',
  PHONE_NUMBER = 'phone_number',
  INTENT = 'intent',
  SCREENSHOT = 'screenshot',
}

export enum ALL_EMAIL_PAGE_SECTION {
  ALL_EMAIL_ANALYSIS = 'all_email_analysis',
  ALL_URL_ANALYSIS = 'all_url_analysis',
}

export const ALL_EMAIL_PAGE_INDEX_MAPPER = {
  [ALL_EMAIL_PAGE_SECTION.ALL_EMAIL_ANALYSIS]: 0,
  [ALL_EMAIL_PAGE_SECTION.ALL_URL_ANALYSIS]: 1,
};

export const ALL_EMAIL_SUBMISSION_SECTIONS = [
  {
    id: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.URL,
    label: 'URL',
    icon: Attachment,
  },
  {
    id: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.SENDER_DOMAIN,
    label: 'Sender Domain',
    icon: Language,
  },
  {
    id: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.ATTACHMENT,
    label: 'Attachment',
    icon: AttachFile,
  },
  {
    id: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.PHONE_NUMBER,
    label: 'Phone Number',
    icon: Phone,
  },
  {
    id: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.INTENT,
    label: 'Intent',
    icon: WebAsset,
  },
  {
    id: ALL_EMAIL_SUBMISSION_SECTIONS_TYPES.SCREENSHOT,
    label: 'Screenshot',
    icon: PhotoSizeSelectActualOutlined,
  },
];

export interface IThreatFindings {
  sha256: string;
  threatType: string;
  firstSeen: string;
  lastScan: string;
  lastSeen: string;
  submissionTs: string;
  status: string;
  sourceUrl: string;
  timestamp: number;
  disposition: string;
  category: string;
  count: number;
  hostingProvider: string;
  ip: string;
  brandLogoDetected: boolean;
  screenshot: string;
  url_sha256: string;
  srcUrl: string;
  registrationDate?: string;
  // we need a brand id to ensure only valid user can initiate takedown.
}
export interface IUrlRespose {
  threatFindings: IThreatFindings[];
  total: number;
  after: any;
}
