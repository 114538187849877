import React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { DashboardData } from '../../constants';
import { getCategoryColor } from './DashboardUtils';

export function OverallEmailSubmissionBreakdown({
  dashboardData,
}: {
  dashboardData: DashboardData;
}) {
  const emailCategories = dashboardData.emailCategories || {};
  const total = Object.values(emailCategories).reduce((acc, curr) => acc + curr, 0);

  return (
    <div className='overall-email-submission-breakdown-container'>
      <div className='shared-titles-for-cards'>Overall Email Submission Breakdown</div>
      <div className='overall-email-submission-breakdown-chart-container'>
        {Object.entries(emailCategories).map(([category, value]) => (
          <div className='overall-email-submission-breakdown-chart-unit' key={category}>
            <Gauge
              className='overall-email-submission-breakdown-chart'
              value={Number(((value / total) * 100).toFixed(1))}
              text={`${((value / total) * 100).toFixed(1)}%`}
              sx={() => ({
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: getCategoryColor(category),
                },
              })}
            />
            <div className='overall-email-submission-breakdown-chart-label-container'>
              <div className='overall-email-submission-breakdown-chart-label'>{category}</div>
              <div className='overall-email-submission-breakdown-chart-value'>{value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
