import React, { ReactElement, useContext, useMemo } from 'react';
import NoDataLight from '../../../../assets/NewMvpNoDataLight.svg';
import NoDataDark from '../../../../assets/NewMvpNoDataDark.svg';
import ThemeContext from '../../../../context/ThemeContext';
import { ThemeModes } from '../../../../constants';
import CardWrapper from '../common/CardWrapper';
import './NoDataWidget.scss';

export interface INoDataWidget {
  heading: string;
  subHeading: ReactElement<any, any>;
}

const NoDataWidget = ({ heading, subHeading }: INoDataWidget) => {
  const { themeName } = useContext(ThemeContext);
  const img = useMemo(() => {
    return themeName === ThemeModes.LIGHT ? NoDataLight : NoDataDark;
  }, [themeName]);

  return (
    <CardWrapper>
      <div className='no-data-wrapper'>
        <img src={img} alt={`no-data-present-${themeName}`} />
        <h5>{heading}</h5>
        {subHeading}
      </div>
    </CardWrapper>
  );
};

export default NoDataWidget;
