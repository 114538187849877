import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import FeatureList from './FeatureList';
import './ContactUs.scss';
import { redirectToCheckphish } from '../../constants';
import CheckPhishContactUsContext from '../../context/CheckPhishContactUsContext';
import { IReasonDropdownValues } from '../../constants';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

interface IContactUsV2 {}

const ContactUsV2 = ({}: IContactUsV2) => {
  const { setShowContactUsV2, setReason, setTriggerContactUsModal } = useContext(
    CheckPhishContactUsContext,
  );
  const [, selectedTheme] = useIsDarkMode();
  return (
    <div className={`contact-us-wrap ${selectedTheme}`} data-testid='contact-us-wrap'>
      <div className='community-wrap pack-wrap mb-40'>
        <div className='header-wrap pack-container'>
          <h6 className='main-head'>FREE (Community Edition)</h6>
          <span className='sub-head'>ALWAYS FREE (no credit card required)</span>
        </div>
        <div className='contact-us-btn-wrap pack-container'>
          <Button disabled={true} className='diabled-btn'>
            Current Plan
          </Button>
        </div>
        <div className='feature-wrap pack-container'>
          <div className='features'>
            <FeatureList
              featureTitle='Domain Monitoring'
              featureList={[
                'Once a week re-scan of Typosquat website content and MX record changes',
                '1 monitored domain / day',
                '300 Typosquats 100 live scan verdict results',
                '1000 prioritized domain acquisition recommendations',
                'Weekly notifications',
              ]}
            />
            <FeatureList
              featureTitle='URL Scanner'
              featureList={[
                '25 URL Live Scans / day',
                'USA Geo based scanning',
                'Advanced API dashboard',
                'Bulk Scan functionality',
              ]}
            />
          </div>
        </div>
      </div>
      <div className='starter-wrap pack-wrap'>
        <div className='most-popular-tag'>Most Popular</div>
        <div className='header-wrap pack-container'>
          <h6 className='main-head'>STARTER (by Bolster)</h6>
        </div>
        <div className='contact-us-btn-wrap pack-container'>
          <Button
            onClick={() => {
              setShowContactUsV2(false);
            }}
          >
            Start a Free Trial
          </Button>
        </div>
        <div className='feature-wrap pack-container'>
          <div className='features'>
            <FeatureList
              featureTitle='Domain Monitoring'
              featureList={[
                'Daily re-scans of typosquat website content and MX record changes',
                'Multiple brand domain and sub-domain monitoring',
                'Unlimited typosquat results and scan verdicts',
                'Unlimited domain acquisition recommendations',
                'Logo detection',
              ]}
            />
            <FeatureList
              featureTitle='URL Scanner'
              featureList={[
                'Up to 500 URL Live Scans/day',
                'Multiple geo location scanning via proxy',
              ]}
            />
            <FeatureList
              featureTitle='Teams'
              featureList={['Admin functionalities', 'Ability to add multiple users', 'SSO']}
            />
            <FeatureList featureTitle='Take-down' featureList={['Self-supported take-down']} />
            <FeatureList
              featureTitle='Support'
              featureList={['Support during business hours (PST) via email']}
            />
          </div>
        </div>
      </div>
      <div className='bussiness-wrap pack-wrap mb-40'>
        <div className='header-wrap pack-container'>
          <h6 className='main-head'>BUSINESS (by Bolster)</h6>
          <span className='sub-head'>Customize module and pricing</span>
        </div>
        <div className='contact-us-btn-wrap btn-wrap pack-container'>
          <Button
            className='transparent-btn'
            onClick={() => {
              setReason(IReasonDropdownValues.SUPPORT);
              setShowContactUsV2(false);
            }}
          >
            Request Demo
          </Button>
        </div>
        <div className='sub-head bold-text'>Everything in STARTER, plus:</div>
        <div className='feature-wrap pack-container'>
          <div className='features'>
            <FeatureList
              featureTitle='Domain Monitoring'
              featureList={[
                'Multiple sources including Newly Registered domains, Search Engine Results, Ads, Certificate Monitoring logs, Anti-Virus, Threat Feed',
              ]}
            />
            <FeatureList
              featureTitle='Additional Modules'
              featureList={[
                'Live social media monitoring and threat protection',
                'App store monitoring and fake app take-down',
                'Dark web monitoring',
                '24/7 support and more',
              ]}
            />
          </div>
        </div>
        <div className='learn-more-wrap btn-wrap pack-container'>
          <Button
            className='transparent-btn'
            onClick={() => {
              redirectToCheckphish('/');
            }}
          >
            Learn More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUsV2;
