import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BulkScanForm from './BulkScanForm';
import BulkScanService from '../../services/bulkscan.service';
import DashboardService from '../../services/dashboard.service';
import CheckPhishService from '../../services/checkphish.service';
import { PageTitle } from '../Common/PageTitle';
import { Table } from '../Common/Table/table';
import { DashboardTile } from '../Dashboard/dashboard.tile';
import './bulkscan.scss';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { IFilter } from '../Common/Table/constant';
import {
  appConstants,
  capitalize,
  DashBoardDto,
  featureIsAvailable,
  getLocalStorageValue,
  getProvidedTagLabelValue,
  getUsersListLabelValue,
  saveBulkscanTableParameters,
  ThemeModes,
  setLocalStorageValue,
  isBrandReadOnlyUser,
} from '../../constants';
import { FIRST_SEEN_COLUMN, LOGO_DETECTION_COLUMN } from '../Dashboard/dashboard.component';
import { alertActions, appActions } from '../../actions';
import {
  CATEGORY_COLUMN,
  TAGS_AUTHOR_COLUMN,
  TAGS_COLUMN,
} from '../MonitorAndTakedown/ColDefinition/malicious.columns';
import DisputeDispositionModal from './Common/DisputeDispositionModal';
import BulkScanTakedownRequestModal from './Common/BulkScanTakedownRequestModal';
import { BulkScanIcon } from '../../assets/SVGIcons';
import {
  BulkScanSelectedItem,
  BulkScanUrlInfoParams,
  BULK_SCAN_TABLE_ID,
  IBulkScanState,
  IScanLocations,
  ScanClass,
} from './BulkScanConstants';
import Tags from '../Common/Tags/Tags';
import { SCANTYPE } from '../Checkphish/AllScans/scanType.enum';
import {
  DISPOSITION_COLUMN_CP,
  HOSTING_COLUMN_CP,
  IP_COLUMN_CP,
  SCANNED_BY_COLUMN_CP,
  URL_COLUMN_CP,
  BRAND_COLUMN_CP,
  SOURCE_COLUMN_CP,
  LAST_SCANNED_COLUMN,
  SCAN_LOCATION,
  WAIT_COLUMN,
  SCANNED_LOCATIONS,
} from '../Checkphish/AllScans/columns.constants';
import { DEFAULT_FILTERS } from '../Checkphish/AllScans/AllScans';
import UserService from '../../services/user.service';
import DeleteCustomTagsModal from '../Common/Tags/DeleteCustomTagsModal';
import EditCustomTagsModal from '../Common/Tags/EditCustomTagsModal';
import { useLocation } from 'react-router';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';

import useOnStoringPaginationControls from '../Common/CustomHooks/useOnStoringPaginationControls';
import { setCurrentTableId } from '../../reducers/insightsContainer.reducer';
import useOnToggleTeamView from './Common/useOnToggleTeamView';

export const DEFAULT_SCAN_TYPE = 'full';

interface ILinkStateProp {
  match: any;
  selectedTheme: any;
}

type BSProps = ILinkStateProp;

type FilterTagOptionItem = {
  accessor: string;
  filterOptions?:
    | Array<{ label: string; value: string }>
    | (() => Array<{ label: string; value: string }>);
  render?: (rowData: any) => JSX.Element;
  [key: string]: any;
};

const BulkScan: React.FC<BSProps> = ({ selectedTheme }) => {
  const [bulkScanstate, setBulkScanState] = useState<IBulkScanState>({
    isLoading: false,
    scanType: DEFAULT_SCAN_TYPE,
    api_key: '',
    data: [],
    total: 0,
    selectedItems: [],
    disputeDispositionModalShown: false,
    takedownRequestModalShown: false,
    allUserAgents: [],
    refreshOnTag: false,
    isLayoutChanged: false,
    isBulkscanLoading: false,
    scanLevel: SCANTYPE.SOLO,
    teamView: false,
    displayLayout: false,
    isMultiLocScanChecked: false,
  });

  const [tablePageNumber, setTablePageNumber] = useState(1);
  const [defaultSortBy, setDefaultSortBy] = useState('lastScanned');
  const [tableSort, setTableSort] = useState({ sortBy: 'lastScanned', sortDirection: 'desc' });
  const [tableFilters, setTableFilters] = useState<IFilter[]>([]);
  const [numOfFinish, setNumOfFinish] = useState(0);
  const [submittedUrls, setSubmittedUrls] = useState<string[]>([]);
  const [submittedScanLocations, setSubmittedScanLocations] = useState<string[]>([]);
  const [renderUrlAndTimestamp, setRenderUrlAndTimestamp] = useState<string[]>([]);
  const { teamViewValue, scanLevel, onSaveTeamViewHandler } = useOnToggleTeamView();
  const [bulkScanStatus, setBulkScanStatus] = useState('');
  const [bulkScanUrlInfo, setBulkScanUrlInfo] = useState<BulkScanUrlInfoParams[]>([]);

  const showTeamView = getLocalStorageValue('showTeamView');
  const isTeamCheckedRef = useRef<boolean>(showTeamView ? showTeamView : teamViewValue);

  const bulkScanService = new BulkScanService();
  const dashboardService = new DashboardService();
  const checkPhishService = new CheckPhishService();
  const userService = new UserService();

  const location = useLocation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.dashboardReducer.user);
  const usersList = useAppSelector(state => state.dashboardReducer.usersList);
  const modifyCustomTag = useAppSelector(state => state.tagsReducer.modifyCustomTag);
  const providedTags = useAppSelector(state => state.tagsReducer.allPlatformTags);

  const { handlePaginationControls } = useOnStoringPaginationControls({
    tableId: BULK_SCAN_TABLE_ID,
  });

  useEffect(() => {
    dispatch(setCurrentTableId(BULK_SCAN_TABLE_ID));
    setLocalStorageValue(['currentTableId'], BULK_SCAN_TABLE_ID); // set the current table id

    return () => {
      dispatch(setCurrentTableId(''));
    };
  }, [dispatch]);

  const setTitle = useCallback(() => {
    dispatch(
      appActions.runByClientApp({
        onBolster: () => {
          window.document.title =
            user.type_name === 'non_brand'
              ? 'Live Scan | Bolster Platform'
              : 'Bulk Scan | Bolster Platform';
        },
        onCheckPhish: () => {
          if (location.pathname === '/bulk-scan') {
            window.document.title = 'Live Scan | Checkphish Platform';
          }
        },
      }),
    );
  }, [dispatch, user.type_name, location.pathname]);

  const fetchData = useCallback(() => {
    Promise.allSettled([dashboardService.getScanCount(), bulkScanService.getUserAgents()])
      .then(allResponses => {
        const [scanCountData, allUserAgents] = allResponses.map((result: any) => result.value);
        setBulkScanState(prevState => ({
          ...prevState,
          api_key: scanCountData['api_key'],
          allUserAgents: allUserAgents,
        }));
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const getTileCompProperties = (isNonBrand: boolean, user: DashBoardDto) => {
    return [
      ...(featureIsAvailable(user, [appConstants.FEATURE_CODE.LOCATION_BASED])
        ? [{ id: 'scan_location' }, { id: 'wait' }]
        : []),
      { id: 'Last scanned' },
      { id: 'First seen' },
      { id: 'Logo Detected' },

      ...(featureIsAvailable(user, [appConstants.FEATURE_CODE.PREMIUM_SCAN_LOCATION])
        ? [{ id: 'scanned_locations' }]
        : []),

      ...(dispatch(
        appActions.runByClientApp({
          onBolster: () => (!isNonBrand ? [{ id: 'Number Takedowns' }] : []),
          onCheckPhish: () => [],
        }),
      ) as Array<{ id: string }>),
      ...(dispatch(
        appActions.runByClientApp({
          onBolster: () => [{ id: 'tags' }],
          onCheckPhish: () => [],
        }),
      ) as Array<{ id: string }>),
    ];
  };

  const handleDisplayLayout = (layout: string) => {
    const isDisplayLayout = layout === 'table';
    setBulkScanState(prevState => ({
      ...prevState,
      displayLayout: isDisplayLayout,
    }));
  };

  const saveSwitchValue = () => {
    const currSwitchValue = !bulkScanstate.teamView;
    void onSaveTeamViewHandler(currSwitchValue);
    isTeamCheckedRef.current = currSwitchValue;
    setTablePageNumber(1);
    setLocalStorageValue('showTeamView', isTeamCheckedRef.current);
    setBulkScanState(prevState => ({
      ...prevState,
      teamView: currSwitchValue,
      scanLevel: currSwitchValue ? SCANTYPE.TEAM : SCANTYPE.SOLO,
    }));
  };

  const toggleDisputeDispositionModal = () => {
    setBulkScanState(prevState => ({
      ...prevState,
      disputeDispositionModalShown: !prevState.disputeDispositionModalShown,
    }));
  };

  const toggleTakedownRequestModal = () => {
    setBulkScanState(prevState => ({
      ...prevState,
      takedownRequestModalShown: !prevState.takedownRequestModalShown,
    }));
  };

  const onModalSuccess = (res: any, msg: string) => {
    dispatch(alertActions.success(msg));

    getBulkScanHistory({ pageNumber: tablePageNumber }, tableFilters, tableSort);
  };

  const setTableParams = useCallback(() => {
    const brandType = user.type_name;
    const isBranded = brandType === 'brand';
    setDefaultSortBy(isBranded ? 'lastScanned' : 'createdTs');
    const tableParameters = getLocalStorageValue(['bulkscan', 'table']);
    const activeFilters = tableParameters?.table?.filters || [];
    const tablePageNum = _.get(tableParameters, ['query', 'pageNumber'], 1);
    setTablePageNumber(tablePageNum);
    setTableSort(
      _.get(tableParameters, ['sortBy'], {
        sortBy: defaultSortBy,
        sortDirection: 'desc',
      }),
    );
    setTableFilters(
      _.uniqBy(
        [
          ...activeFilters,
          ...(brandType === 'brand' ? DEFAULT_FILTERS.bulkAndApiScans : DEFAULT_FILTERS.bulkScans),
        ],
        'filterBy.value',
      ),
    );
  }, [user.type_name, defaultSortBy]);

  //TODO - Need to refactor the below Use effect code  for better performance
  // Use effect to set the table parameters, fetch the scan data and set the title on initial load
  useEffect(() => {
    fetchData();
    setTitle();
  }, [fetchData, setTitle, setTableParams]);

  const shouldTriggerAgGrigRefresh = useAppSelector(
    state => state.tableReducer.shouldTriggerAgGrigRefresh,
  );

  useEffect(() => {
    setTableParams();
  }, [setTableParams, shouldTriggerAgGrigRefresh]);

  // Use effect to fetch the scan data if the user is not empty
  useEffect(() => {
    if (!_.isEmpty(user.email)) {
      const query = {
        pageNumber: tablePageNumber,
      };
      getBulkScanHistory(query, tableFilters, tableSort);
    }
  }, [bulkScanstate.scanLevel, user.email, tableFilters.length]);

  // Use effect to update the team view and scan level values for bulk scan form
  useEffect(() => {
    setBulkScanState(prevState => ({
      ...prevState,
      teamView: teamViewValue,
      scanLevel: scanLevel,
    }));
  }, [teamViewValue, scanLevel]);

  const bulkScan = (arg: any) => {
    const { api_key, scanType } = bulkScanstate;
    const bulkScanApiCalls: any = [];
    arg.submittedLocationsForScan.map((scanLocation: any) => {
      bulkScanApiCalls.push(
        bulkScanService.bulkScan(arg.urlsSubmitted, 'bulk_' + api_key, scanType, {
          scanLocation: scanLocation,
          wait: arg.wait,
          userAgent: arg.selectedUserAgent,
          scanClass: arg.scanClass,
          scanClassOptions: arg.scanClassOptions,
        }),
      );
    });
    let query: any = { pageNumber: tablePageNumber };
    query.pageNumber = Math.max(query.pageNumber ? query.pageNumber - 1 : 0, 0);
    query = {
      ...query,
      startDate: moment().subtract(180, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    };
    Promise.allSettled(bulkScanApiCalls)
      .then((allResponses: any) => {
        allResponses.map((result: any) => {
          // POC for multi location scan
          if (result.status === 'fulfilled' && arg.scanClass === ScanClass.HEADFUL_MAC_MULTI) {
            const status = result?.value?.[0];
            if (status.error) {
              dispatch(alertActions.error('Error processing the request.'));
            } else {
              dispatch(
                alertActions.information(
                  status?.success?.message ||
                    'Your scan is in progress and may take 3–5 minutes to complete. Please revisit or refresh the page to view the result.',
                ),
              );
            }
            setBulkScanStatus(result.status);
            return result.value;
          }

          if (result.status === 'fulfilled') {
            getBulkScanUrlInfo(result);
            renderUrlAndTimestamp.push(result.value.srcUrl + result.value.timestamp);
            result.value.map((v: any) => {
              if (v.error) {
                dispatch(alertActions.error(v.error.message));
              }
            });
            return result.value;
          }
          if (result.status === 'rejected') {
            getBulkScanUrlInfo(result);
            if (result?.reason instanceof SyntaxError) {
              dispatch(alertActions.error('Error processing the request.'));
            } else {
              dispatch(alertActions.error(result?.reason || 'Error processing the request.'));
            }
          }
        });
      })
      .catch((err: Error) => {
        dispatch(alertActions.error(err.message || 'Error processing the request.'));
      })
      .finally(() => {
        setTimeout(() => {
          fetchBulkScanHistory(query, tableFilters, tableSort, 'bulk_scan');
        }, 1000);
      });
  };

  const getBulkScanUrlInfo = (result: any) => {
    const allUserEntetedUrlsErr = result?.value?.every(
      (item: any) => item.error?.message !== undefined,
    );
    if (allUserEntetedUrlsErr || result.status === 'rejected') {
      setBulkScanStatus('');
    } else {
      setBulkScanStatus(result.status);
      const urlDataArray: BulkScanUrlInfoParams[] = result.value.map((item: any) => ({
        url: item?.srcUrl,
        timestamp: item.timestamp,
        urlSHA256: item.urlSHA256,
        isAlreadyExisted: item?.isUrlTakenDown ? false : item?.isAlreadyExisted,
        isNewScan: item?.isNewScan,
        isUrlTakenDown: item?.isUrlTakenDown,
      }));
      setBulkScanUrlInfo(urlDataArray);
    }
  };

  const updateBulkScanStatus = () => {
    setBulkScanStatus('');
  };

  const submitScans = (scanInfo: any) => {
    const {
      urls,
      scanLocations,
      wait,
      onSubmitType,
      selectedUserAgent,
      scanClass,
      scanClassOptions,
    } = scanInfo;
    const tempData: object[] = [];

    setNumOfFinish(0);
    setTableSort({
      sortBy: defaultSortBy,
      sortDirection: 'desc',
    });
    setTablePageNumber(1);
    const urlsSubmitted = urls;
    const submittedLocationsForScan =
      scanLocations.length > 0 ? scanLocations : [IScanLocations.US.value];
    setSubmittedUrls(urls);
    setSubmittedScanLocations(scanLocations.length > 0 ? scanLocations : [IScanLocations.US.value]);

    if (onSubmitType === 'bulkscan') {
      setBulkScanState(prevState => ({
        ...prevState,
        isBulkscanLoading: true,
        isLoading: false,
        selectedItems: [],
      }));
    }

    if (scanClass !== ScanClass.HEADFUL_MAC_MULTI) {
      urlsSubmitted.forEach((url: string) => {
        submittedLocationsForScan.forEach((scanLocation: string) => {
          url = url.trim();
          if (!_.isEmpty(url)) {
            tempData.push({
              urlAndTimestamp: url + Math.random(),
              url: url,
              imageUrl: '--',
              status: '--',
              takedowns: '--',
              brandId: '--',
              ipAddress: '--',
              networkOwner: '--',
              disposition: '--',
              brandLogoDetected: '--',
              logoDetectedCount: '--',
              firstSeen: '',
              lastScanned: moment().valueOf(),
              createdTs: moment().valueOf(),
              scanLocation,
              wait,
              userAgent: selectedUserAgent,
              isLocationBasedEnabled: featureIsAvailable(user, [
                appConstants.FEATURE_CODE.LOCATION_BASED,
              ]),
              urlSha256: '--',
              timestamp: '--',
              isNewAdded: true,
              isLoading: true,
              isBulkscan: true,
              onSubmitType,
            });
          }
        });
      });
    }

    setBulkScanState(prevState => {
      const mostRecentData = prevState.data;
      return {
        ...prevState,
        data: [...tempData, ...mostRecentData],
        selectedItems: [],
      };
    });

    bulkScan({
      wait,
      selectedUserAgent: selectedUserAgent === '--' ? '' : selectedUserAgent,
      urlsSubmitted,
      submittedLocationsForScan,
      scanClass,
      scanClassOptions: scanClassOptions,
    });
  };

  const onTableFilterApplied = (filters: IFilter[]) => {
    const brandType = user.type_name;
    const tablefiltrs = _.uniqBy(
      [
        ...filters,
        ...(brandType === 'brand' ? DEFAULT_FILTERS.bulkAndApiScans : DEFAULT_FILTERS.bulkScans),
      ],
      'filterValue',
    );
    getBulkScanHistory({}, tablefiltrs, tableSort);
    setTableFilters(tablefiltrs);
  };

  const onTableSortChanged = (sort: any) => {
    setTableSort(sort);
    getBulkScanHistory({ pageNumber: tablePageNumber }, tableFilters, sort);
  };

  const onTablePageChange = (num: number, cb?: any) => {
    setTablePageNumber(num);
    getBulkScanHistory({ pageNumber: num }, tableFilters, tableSort, cb);
  };

  const fetchBulkScanHistory = (
    query?: any,
    filters?: IFilter[],
    sort?: any,
    scanType?: string,
  ) => {
    setBulkScanState(prevState => ({
      ...prevState,
      isLoading: scanType === 'bulk_scan' ? false : true,
      isBulkscanLoading: scanType === 'bulk_scan' ? true : false,
    }));
    checkPhishService
      .getUrlScanData(scanLevel, query, filters, sort)
      .then((res: any) => {
        let scanData: any[] = [];
        const total = res.total;
        if (res && res.urlInfo) {
          scanData = res.urlInfo.map((item: any, i: number) => {
            return {
              urlAndTimestamp: item.srcUrl + item.timestamp + i,
              url: item.srcUrl,
              url_sha256: item.urlSha256,
              imageUrl: item.imagePath,
              status: item.status,
              takedowns: item.takedowns,
              brandId: item.brandId,
              brandDisplayName: item.brandDisplayName || capitalize(item.brandId),
              ipAddress: item.ip,
              networkOwner: item.as_description,
              disposition: item.disposition,
              brandLogoDetected: item.brand_logo_detected ?? '--',
              firstSeen: item.firstSeen,
              lastScanned: moment(item.createdTs).valueOf(),
              createdTs: item.createdTs,
              urlSha256: item.urlSha256,
              timestamp: item.timestamp,
              isNewAdded: renderUrlAndTimestamp?.indexOf(item.srcUrl + item.timestamp) !== -1,
              isBulkscan: true,
              final_category: item.final_category,
              scanLocation: item.scan_location,
              wait: item.wait,
              userAgent: item.user_agent,
              isLocationBasedEnabled: featureIsAvailable(user, [
                appConstants.FEATURE_CODE.LOCATION_BASED,
              ]),
              rescanDomain: submitScans,
              tags: item.tags || [],
              _index: item.srcUrl + item.timestamp + i,
              source: item.source,
              ...(item.scannedBy && { scannedBy: item.scannedBy }),
              scanClass: item.scan_class,
              scannedLocations: item.scanned_locations,
            };
          });
        }
        // Save the query setting in local storage
        query.pageNumber++;
        saveBulkscanTableParameters({
          query,
          filters: _.uniqBy(filters, 'filterValue'),
          sortBy: sort,
        });
        setBulkScanState(prevState => ({
          ...prevState,
          data: scanData,
          total,
          isLayoutChanged: false,
          refreshOnTag: false,
          isLoading: false,
          isBulkscanLoading: false,
        }));
        setTablePageNumber(query.pageNumber);
        handlePaginationControls(query.pageNumber - 1, 15, total, scanData);

        // Store the current table settings for insights pagination
        setLocalStorageValue(['tableApiParams', BULK_SCAN_TABLE_ID], {
          query: { ...query, type: scanLevel, pageSize: 15 },
          filters,
          sort,
        });
      })
      .catch(error => {
        setBulkScanState(prevState => ({
          ...prevState,
          isLoading: false,
          isBulkscanLoading: false,
        }));
        dispatch(
          alertActions.error(
            error.message || 'Error while fetching the scans. Please try again later.',
          ),
        );
      });
  };

  const getBulkScanHistory = (query?: any, filters?: IFilter[], sort?: any, cb?: any) => {
    query.pageNumber = Math.max(query.pageNumber ? query.pageNumber - 1 : 0, 0);
    query = {
      ...query,
      startDate: moment().subtract(180, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    };
    if (cb) {
      cb();
    } else {
      fetchBulkScanHistory(query, filters, sort);
    }
  };

  const onItemCheck = (checked: boolean, item: any, selectedItems: BulkScanSelectedItem[]) => {
    setBulkScanState(prevState => ({
      ...prevState,
      selectedItems: selectedItems,
    }));
  };

  const onTagsChangeHandler = (isAdded: boolean, url_sha256: string, newTags: any[]) => {
    const { data } = bulkScanstate;
    const newPayload = data.map((item: any) => {
      if (item.url_sha256 === url_sha256) {
        item.tags = newTags;
      }
      return item;
    });
    setBulkScanState(prevState => ({
      ...prevState,
      data: newPayload,
    }));
  };

  const { type_name } = user;
  const BULK_SCAN_HISTORY_COLUMNS = useMemo(() => {
    const filterOption = dispatch(
      appActions.runByClientApp({
        onBolster: () => {
          return [
            {
              ...TAGS_COLUMN,
              accessor: 'tagIds',
              filterOptions: getProvidedTagLabelValue(
                providedTags.filter((tag: any) => tag.label !== 'Marked as Scam'),
                selectedTheme === ThemeModes.DARK.toLowerCase(),
              ),
              render: (rowData: any) => {
                return (
                  <Tags
                    leftAligned={bulkScanstate.displayLayout}
                    rowData={rowData}
                    onTagsChangeHandler={onTagsChangeHandler}
                    type={appConstants.CONTENT_TYPE.BULK_SCAN}
                  />
                );
              },
            },
            {
              ...TAGS_AUTHOR_COLUMN,
              accessor: 'tagUpdatedBy',
              filterOptions: getUsersListLabelValue(usersList),
            },
          ];
        },
      }),
    ) as FilterTagOptionItem[];

    return [
      URL_COLUMN_CP(),
      IP_COLUMN_CP(),
      CATEGORY_COLUMN,
      HOSTING_COLUMN_CP,
      DISPOSITION_COLUMN_CP(),
      LOGO_DETECTION_COLUMN,
      FIRST_SEEN_COLUMN,
      LAST_SCANNED_COLUMN,
      BRAND_COLUMN_CP,
      ...(type_name === 'brand' ? [SOURCE_COLUMN_CP] : []),
      ...(bulkScanstate.scanLevel === SCANTYPE.TEAM
        ? [SCANNED_BY_COLUMN_CP(false, usersList)]
        : []),
      ...(filterOption ?? []),
    ];
  }, [type_name, bulkScanstate.scanLevel, usersList]);

  if (
    featureIsAvailable(user, [appConstants.FEATURE_CODE.LOCATION_BASED]) &&
    !BULK_SCAN_HISTORY_COLUMNS.includes(SCAN_LOCATION)
  ) {
    BULK_SCAN_HISTORY_COLUMNS.push(SCAN_LOCATION);

    if (featureIsAvailable(user, [appConstants.FEATURE_CODE.PREMIUM_SCAN_LOCATION])) {
      BULK_SCAN_HISTORY_COLUMNS.push(SCANNED_LOCATIONS);
    }
    BULK_SCAN_HISTORY_COLUMNS.push(WAIT_COLUMN);
  }

  const customTools = !isBrandReadOnlyUser(user)
    ? [
        <div className='custom-btn-wrapper' key={'bulk-action'}>
          <Button
            id='bulk-scan-dispute-disposition'
            className='margin-inline-end'
            variant='outlined'
            disabled={bulkScanstate.selectedItems.length === 0}
            onClick={toggleDisputeDispositionModal}
          >
            DISPUTE DISPOSITION
          </Button>
          <Button
            id='bulk-scan-request-takedown'
            variant='contained'
            disabled={bulkScanstate.selectedItems.length === 0}
            onClick={toggleTakedownRequestModal}
          >
            REQUEST TAKEDOWN
          </Button>
        </div>,
      ]
    : [];

  return (
    <AuthenticationWrapper>
      <div className={'bulk-scan-page'}>
        <PageTitle title={'Scan URLs'} />
        {!isBrandReadOnlyUser(user) && (
          <div className='title-description'>
            {dispatch(
              appActions.runByClientApp({
                onBolster: () =>
                  `To use our Scan URLs feature, simply enter the website URLs you want to scan (e.g.,
                http://google.com), and our advanced algorithms will provide you with a comprehensive
                report that identifies any potential security risks or vulnerabilities associated with
                those URLs. For Social Media or Mobile App Scanning, please use the corresponding module (and NOT this page)`,
                onCheckPhish: () =>
                  `A real-time URL scanner that provides detailed threat intelligence, monitors 3000+ brands, and flags potential brand impersonation.`,
              }),
            )}
          </div>
        )}
        <div className='bulk-scan-page-content'>
          {!isBrandReadOnlyUser(user) && (
            <Card className='card-between'>
              <div className='bulk-scan-form-header'>
                <div className='d-flex align-items-center '>
                  <div className='bulk-scan-icon-wrapper'>
                    <BulkScanIcon color='grey' />
                  </div>
                  <Card.Text>New Scan</Card.Text>
                </div>

                {/* POC for multi location scan */}
                {featureIsAvailable(user, [appConstants.FEATURE_CODE.PREMIUM_SCAN_LOCATION]) && (
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                      <Tooltip id='multi-location-scan-tooltip'>
                        Runs multiple scans for a URL from various geographic locations, but only
                        one final result will be shown. Please allow 3–5 minutes for processing,
                        after which you may revisit or refresh the page.
                      </Tooltip>
                    }
                  >
                    <div>
                      <Form.Check
                        type='switch'
                        id='multi-location-scan-switch'
                        label='Multi Location Scan'
                        onChange={() =>
                          setBulkScanState(prevState => ({
                            ...prevState,
                            isMultiLocScanChecked: !prevState.isMultiLocScanChecked,
                          }))
                        }
                        checked={bulkScanstate.isMultiLocScanChecked}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
              <BulkScanForm
                submitScans={submitScans}
                isLoading={bulkScanstate.isBulkscanLoading}
                user={user}
                allUserAgents={bulkScanstate.allUserAgents}
                userEntedredUrls={submittedUrls}
                bulkScanStatus={bulkScanStatus}
                updateBulkScanStatus={updateBulkScanStatus}
                bulkScanUrlInfo={bulkScanUrlInfo}
                isMultiLocScanChecked={bulkScanstate.isMultiLocScanChecked}
              />
            </Card>
          )}
          <Card className='bulk-scan-table-container card-between'>
            {dispatch(
              appActions.runByClientApp({
                onBolster: () => (
                  <div className='team-view'>
                    <FormControlLabel
                      sx={{ display: 'block' }}
                      control={
                        <Switch
                          checked={bulkScanstate.teamView}
                          onChange={saveSwitchValue}
                          name='loading'
                          color='primary'
                        />
                      }
                      label='Team View'
                      id='team-view-switch'
                    />
                  </div>
                ),
              }),
            )}
            <Table
              id={BULK_SCAN_TABLE_ID}
              key={`${bulkScanstate.scanLevel}-${BULK_SCAN_TABLE_ID}`}
              showLoader={bulkScanstate.isLoading}
              userType={user.type_name}
              customTools={type_name === 'brand' ? customTools : null}
              disableDatePicker
              titleComp={<div />}
              indexBy={'urlAndTimestamp'}
              enableCheckbox={type_name === 'brand' && !isBrandReadOnlyUser(user)}
              data={bulkScanstate.data}
              total={bulkScanstate.total}
              filterAppliedOnFetchApi
              onItemCheck={onItemCheck}
              fetchApi={true}
              rowId={'urlAndTimestamp'}
              initialFilters={tableFilters}
              onFilterApplied={onTableFilterApplied}
              onSortChange={onTableSortChanged}
              columns={BULK_SCAN_HISTORY_COLUMNS}
              sortBy={tableSort.sortBy}
              sortDirection={tableSort.sortDirection}
              startDate={moment().subtract(1, 'year')}
              endDate={moment()}
              tileComp={DashboardTile}
              tileCompProperties={getTileCompProperties(type_name === 'non_brand', user)}
              initialPageNumber={tablePageNumber}
              onPageChange={onTablePageChange}
              defaultLayout={'tile'}
              allUserAgents={bulkScanstate.allUserAgents}
              selectedItems={bulkScanstate.selectedItems}
              handleDisplayLayout={handleDisplayLayout}
              user={user}
            />
          </Card>
        </div>
      </div>
      {bulkScanstate.disputeDispositionModalShown && (
        <DisputeDispositionModal
          data={bulkScanstate.selectedItems}
          show={bulkScanstate.disputeDispositionModalShown}
          onCloseModal={toggleDisputeDispositionModal}
          submitButtonLabel='Submit'
          onSuccess={onModalSuccess}
          onFailure={(error: any) => dispatch(alertActions.error(error))}
          bulkScanPage={true}
        />
      )}
      {bulkScanstate.takedownRequestModalShown && (
        <BulkScanTakedownRequestModal
          data={bulkScanstate.selectedItems}
          show={bulkScanstate.takedownRequestModalShown}
          onCloseModal={toggleTakedownRequestModal}
          onSuccess={onModalSuccess}
          onFailure={(error: any) => dispatch(alertActions.error(error))}
          user={user}
          itemOnSelect={onItemCheck}
        />
      )}
      {modifyCustomTag.isDeleteMode && <DeleteCustomTagsModal />}
      {modifyCustomTag.isEditMode && <EditCustomTagsModal />}
    </AuthenticationWrapper>
  );
};

export { BulkScan };
