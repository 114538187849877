import { Button, Menu, MenuItem, Select } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronRightIcon } from '../../../assets/SVGIcons';
import { IAssociatedBrand, ISubBrands } from '../../../constants';
import { useAppSelector } from '../../../helpers/hooks';
import SuperAdminService from '../../../services/super-admin.service';
import './OrgSwitcherContainer.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export interface IAllMspOrgs {
  label: string;
  value: string;
  subBrands: ISubBrands[];
}

export default function OrgSwitcherContainer() {
  const superAdminService = useMemo(() => new SuperAdminService(), []);
  const user = useAppSelector(state => state.dashboardReducer.user);

  const [allMSPOrgs, setAllMSPOrgs] = useState<IAllMspOrgs[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenuAnchorEls, setSubMenuAnchorEls] = useState<Record<string, HTMLElement | null>>({});

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEls({});
  };

  useEffect(() => {
    const {
      mspDetails,
      brand_info: { brand },
    } = user;

    if (mspDetails) {
      const options: IAllMspOrgs[] = [];

      mspDetails.associatedBrands.map((org: IAssociatedBrand) => {
        if (org.brandMapping) {
          const brandName = _.find(org.subBrands, { brandDisplayName: org.orgName })?.brandName;
          options.push({ value: brandName || '', label: org.orgName, subBrands: [] });
        } else {
          options.push({ value: org.orgName, label: org.orgName, subBrands: org.subBrands });
        }
      });
      setAllMSPOrgs(options);
      setSelectedValue(brand.brandName);
    }
  }, []);

  // on switch
  const handleBrandInputChange = async (selection: any) => {
    if (selection === '') return;
    setSelectedValue(selection);
    await superAdminService.switchBrand(selection);
  };

  const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setSubMenuAnchorEls(prev => ({
      ...prev,
      [value]: event.currentTarget,
    }));
  };

  const handleSubMenuClose = (value: string) => {
    setSubMenuAnchorEls(prev => ({
      ...prev,
      [value]: null,
    }));
  };

  const handleSubMenuClick = (
    e: React.MouseEvent<HTMLElement>,
    value: string,
    subBrands: ISubBrands[],
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (subBrands.length === 0) {
      void handleBrandInputChange(value);
    }
  };
  return (
    <div className='org-switcher-container' data-testid='org-switcher-container'>
      <Button
        aria-controls='main-menu'
        aria-haspopup='true'
        onClick={handleMenuOpen}
        variant='outlined'
        sx={{ width: 180, height: 35, display: 'flex', justifyContent: 'space-between' }}
        endIcon={<ExpandMoreIcon />}
        data-testid={'org-switcher-button'}
      >
        {selectedValue}
      </Button>
      <Menu
        id='main-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        data-testid='main-menu'
      >
        {allMSPOrgs.map(({ label, subBrands, value }: IAllMspOrgs, i: number) => (
          <MenuItem
            id={value}
            key={`${value}-${i}`}
            aria-haspopup='true'
            sx={{ width: 180, display: 'flex', justifyContent: 'space-between' }}
            value={value}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              handleSubMenuClick(e, value, subBrands);
            }}
            data-testid={`${value}-${i}`}
          >
            {subBrands.length === 0 ? (
              label
            ) : (
              <>
                <div
                  id={value}
                  key={value}
                  aria-haspopup={true}
                  className='sub-menu-item-wrapper'
                  data-testid={`sub-menu-item-${value}`}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubMenuOpen(e, value);
                  }}
                >
                  {label}
                  <div>
                    <ChevronRightIcon />
                  </div>
                </div>
                <Menu
                  id={`sub-menu-${value}`}
                  anchorEl={subMenuAnchorEls[value] || null}
                  open={Boolean(subMenuAnchorEls[value])}
                  onClose={() => handleSubMenuClose(value)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  data-testid={`sub-menu-${value}`}
                >
                  {subBrands.map((subMenuItem: any, i: number) => (
                    <MenuItem
                      key={i}
                      aria-haspopup='true'
                      id={subMenuItem.brandName}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        void handleBrandInputChange(subMenuItem.brandName);
                      }}
                      value={subMenuItem.brandName}
                    >
                      {subMenuItem.brandDisplayName}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
