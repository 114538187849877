import _ from 'lodash';
import React from 'react';
import { BFScanLocation } from '../BulkScanConstants';

export type ILocationUnsortedOptions = Omit<BFScanLocation, 'isDefault'>[];

export default function useOnGroupingAndSortingLocations() {
  const onGroupingAndSortingLocations = (locations: ILocationUnsortedOptions) => {
    //Grouping the locations by continent name and
    //Sorting the locations by continent name and sort location name

    let sortedContinents = [];

    // Sort scan locations by continentName ('Asia', 'Europe', 'North America', 'Tor')
    sortedContinents = locations.sort((a: any, b: any) =>
      a?.continentName?.localeCompare(b?.continentName),
    );

    const groupByContinent = _.groupBy(sortedContinents, 'continentName');
    const locationOptions: any[] = [];
    for (const continentName in groupByContinent) {
      locationOptions.push({
        label: continentName,
        value: continentName,
        options: groupByContinent[continentName],
      });
    }
    // Sort locationOptions by label i.e. locationName ('United States', 'Spain', 'Tor')
    const sortedLocationOptions = locationOptions.map(item => {
      if (item.options && item?.options?.length > 0) {
        item.options = item.options.sort((a: any, b: any) => a?.label?.localeCompare(b?.label));
      }
      return item;
    });

    return sortedLocationOptions;
  };
  return {
    onGroupingAndSortingLocations,
  };
}
