import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ACTION_OPTIONS } from '../Playbook/Common/PlaybookTypes';

import DotDotDot from '../../assets/icons/DotDotDot.svg';
import DotDotDotWhiteIcon from '../../assets/icons/DotDotDotWhite.svg';
import { useAppSelector } from '../../helpers/hooks';
import useIsDarkMode from '../Common/CustomHooks/useIsDarkMode';

export interface IConnectorActionColRendererProps {
  data: any;
  onDeleteConnector: (data: any) => void;
  setEditingConnector: (data: any) => void;
  toggleModal: () => void;
}
const ConnectorActionColRenderer = ({
  data,
  onDeleteConnector,
  setEditingConnector,
  toggleModal,
}: IConnectorActionColRendererProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDarkMode] = useIsDarkMode();
  const open = Boolean(anchorEl);

  const connectorTypes = useAppSelector(state => state.playbookReducer.connectorTypes);

  const getActionOptions = useCallback(() => {
    const { connectorTypeId } = data;
    const isDefault = connectorTypes?.find((type: any) => type.id === connectorTypeId)?.isDefault;
    return ACTION_OPTIONS.filter(option => option.value !== 'delete' || !isDefault);
  }, [connectorTypes, data]);

  const onAction = useCallback((action: string, data: any) => {
    switch (action) {
      case 'edit':
        setEditingConnector(data);
        toggleModal();
        break;
      case 'delete':
        onDeleteConnector(data);
        break;
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id='basic-button-connector-actions'
        aria-controls={open ? 'basic-menu-soc-actions' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          src={isDarkMode ? DotDotDotWhiteIcon : DotDotDot}
          alt={'dotdotdot'}
          data-testid='basic-button-connector-actions'
        />{' '}
      </Button>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {getActionOptions().map((actionOption, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onAction(actionOption.value, data);
                handleClose();
              }}
            >
              {actionOption.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ConnectorActionColRenderer;
