import React, { ChangeEvent, useMemo, useRef } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import AttachFile from '../../Common/AttachFile';
import { DISPUTE_DISPOSITION_OPTIONS } from '../../Common/DisputeStatus';
import { RadioButtons } from '../../Common/RadioButtons';
import { IDisputeData } from '../InsightsContainer';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Dropdown, IDropdownOption } from '../../Common/Dropdown';
import { useAppSelector } from '../../../helpers/hooks';

export interface IInsightsDisputeModalProps {
  isDisputeModalShown: boolean;
  toggleDisputeModal: () => void;
  submitDisputeVerdict: (formValues: any) => void;
  sendingDisputeRequest: boolean;
  disputeData: IDisputeData;
  selectedTheme: string;
  disputeError: string;
}

const COMMENT_MAX_LENGTH = 1000;

export default function InsightsDisputeModal(props: IInsightsDisputeModalProps) {
  const {
    isDisputeModalShown,
    toggleDisputeModal,
    submitDisputeVerdict,
    sendingDisputeRequest,
    disputeData,
    selectedTheme,
    disputeError,
  } = props;

  const { disposition, comments } = disputeData;
  const brandInfo = useAppSelector(state => state.dashboardReducer.user)?.brand_info.brand;

  const formValues = useRef<{
    disposition: string;
    comments: string;
    attachments: File[];
    brandMnemonic: string;
  }>({
    disposition: '',
    comments: '',
    attachments: [],
    brandMnemonic: brandInfo?.subBrands?.length === 1 ? brandInfo?.subBrandNames[0] : '',
  });

  const updateValue = (value: string | File[], field: string) => {
    formValues.current[field] = value;
  };
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, controlId: string) => {
    updateValue(event.target.value, controlId);
  };

  const handleRadioButtonChange = (value: string, controlId: string) => {
    formValues.current[controlId] = value;
  };
  const handleFilesAdded = (files: File[], controlId: string) => {
    updateValue(files, controlId);
  };

  const renderDisputeOftions = () => {
    const DISPUTE_OPTIONS = [];
    for (const value in DISPUTE_DISPOSITION_OPTIONS) {
      DISPUTE_OPTIONS.push({ label: DISPUTE_DISPOSITION_OPTIONS[value], value });
    }

    return DISPUTE_OPTIONS;
  };

  const handleBrandInputChange = (selection: IDropdownOption) => {
    updateValue(selection.value, 'brandMnemonic');
  };

  const brandOptions = useMemo(() => {
    return brandInfo?.subBrands?.map((brand: any) => {
      return {
        label: brand.brandDisplayName,
        value: brand.brandName,
      };
    });
  }, [brandInfo]);

  return (
    <div>
      <Modal show={isDisputeModalShown} onHide={toggleDisputeModal} className={`${selectedTheme}`}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Dispute Disposition</Modal.Title>
          <div
            className='close'
            onClick={toggleDisputeModal}
            data-testid='dispute-close-button-modal'
            id='dispute-close-button-modal'
          >
            <ClearRoundedIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          {disputeError && <div className={'mb-2 text-danger'}>{disputeError}</div>}
          <RadioButtons
            label='Please select what you believe to be the correct disposition.'
            defaultValue={disposition}
            options={renderDisputeOftions()}
            onChange={(disposition: string) => handleRadioButtonChange(disposition, 'disposition')}
            direction={'row'}
          />
          {brandInfo?.brandMapping === true && brandInfo?.subBrands?.length > 1 ? (
            <Form.Group>
              <Form.Label>Select Brand:</Form.Label>
              <Dropdown
                boxStyle
                options={brandOptions as IDropdownOption[]}
                onChange={handleBrandInputChange}
                key={'select-brand'}
                data-testid='select-brand'
              />
            </Form.Group>
          ) : null}
          <AttachFile
            label='Attach File'
            onChangeHandler={files => handleFilesAdded(files, 'attachments')}
          />
          <Form.Label>Comments:</Form.Label>
          <Form.Control
            as='textarea'
            defaultValue={comments}
            maxLength={COMMENT_MAX_LENGTH}
            className={'playbook-description-input'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleTextFieldChange(e, 'comments');
            }}
            data-testid='dsipute-comments'
          />
          <Form.Text>
            {COMMENT_MAX_LENGTH - (comments?.length || 0)} characters remaining.
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={toggleDisputeModal}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => submitDisputeVerdict(formValues.current)}
            disabled={sendingDisputeRequest}
            data-testid='dispute-submit-button'
          >
            {sendingDisputeRequest ? (
              <div data-testId='dispute-spinner'>
                <Spinner
                  className='spinner'
                  animation='border'
                  variant='light'
                  size='sm'
                  id='dispute-spinner'
                />
              </div>
            ) : (
              'Submit'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
