import React from 'react';
import { DispositionStatus } from '../../Common/DispositionStatus';
import { EmailDetailsParams } from '../constants';
import { getDisplayTimeFromTimeStamp } from '../../../constants';
import { Chip, Button, Tooltip } from '@mui/material';
import './EmailCategory.scss';
import { ALL_EMAIL_SUBMISSION_SECTIONS } from '../AllEmailSubmissions/constants';
import _ from 'lodash';
import LableValue from './LableValue';

export const EmailDetailsHeaderSection: React.FC<{
  abuseMailBoxEmailDetails: EmailDetailsParams;
  activeSection: string;
  onSectionSelect: (id: string) => void;
}> = ({ abuseMailBoxEmailDetails, activeSection, onSectionSelect }) => {
  const dispositionStatusClassName = 'disposition-status';
  const emailCategoryClassName = `email-category email-category-${abuseMailBoxEmailDetails.emailCategory
    ?.toLowerCase()
    .replace(/\s+/g, '-')}`;

  return (
    <div className='email-details-header-section'>
      <div className='email-details-header-container'>
        <Tooltip
          title={abuseMailBoxEmailDetails.subject}
          classes={{
            popper: 'email-details-header--tooltip',
          }}
        >
          <div className='email-details-header'>Subject: {abuseMailBoxEmailDetails.subject}</div>
        </Tooltip>
        {abuseMailBoxEmailDetails.disposition && (
          <DispositionStatus
            status={abuseMailBoxEmailDetails.disposition}
            className={dispositionStatusClassName}
            displayedStyle={'pill'}
          />
        )}
        {abuseMailBoxEmailDetails.emailCategory && (
          <Chip
            label={abuseMailBoxEmailDetails.emailCategory}
            size='small'
            className={emailCategoryClassName}
          />
        )}
      </div>
      <div className='email-details-subheader-container'>
        <div className='subheader-item'>
          <LableValue
            lable={'Submission Date'}
            value={getDisplayTimeFromTimeStamp(
              abuseMailBoxEmailDetails.submissionDate,
              'MM/DD/YYYY h:mm a',
            )}
          />
        </div>
        <div className='subheader-item'>
          <LableValue
            lable={'Last Scan Date'}
            value={getDisplayTimeFromTimeStamp(
              abuseMailBoxEmailDetails.lastScannedDate,
              'MM/DD/YYYY h:mm a',
            )}
            className='border-left'
          />
        </div>
      </div>
      <div className='email-details-section-bar'>
        {ALL_EMAIL_SUBMISSION_SECTIONS.map(sectionItem => (
          <>
            <Button
              className={`section-item ${
                activeSection === sectionItem.id ? 'active-section-item' : ''
              }`}
              key={sectionItem.id}
              data-testId={`section-item-${sectionItem.id}`}
              startIcon={<sectionItem.icon className='section-item-icon' />}
              onClick={onSectionSelect.bind(null, sectionItem.id)}
            >
              {sectionItem.label}
            </Button>
            <span className='filled-circle'>
              <img src='/abusemailbox/filled-circle.svg' alt='filled circle' />
            </span>
          </>
        ))}
      </div>
    </div>
  );
};
