import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import '../Playbook/Style/playbook.scss';
import Card from 'react-bootstrap/Card';
import { NewConnectorModal } from './newConnectorModal';
import { alertActions } from '../../actions';

import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { setIsAgGridClientMode } from '../../reducers/table.reducer';
import { TableContextProvider } from '../Common/Table/table.context';
import { TableData } from '../Common/Table/table.data';
import {
  deleteConnector,
  fetchConnectorTypes,
  fetchConnectors,
  massageConnectors,
  massagedConnectorTypes,
  updateConnector,
} from './connector-requests';
import {
  CONNECTOR_COL_CREATED_AT,
  CONNECTOR_COL_CREATED_BY,
  CONNECTOR_COL_DETAILS,
  CONNECTOR_COL_NAME,
  CONNECTOR_COL_TYPE,
} from './ConnectorColDefs';
import ConnectorActionColRenderer from './ConnectorActionColRenderer';
import { ITableApiColumn } from '../Common/Table/table.api';
import { setConnectorTypes } from '../../reducers/playbook.reducer';
import { CONNECTOR_TABLE_ID } from '../Assets_v2/constants';
import { integrationDocsSufixMap, simpliedIntegrationNameMap } from '../../constants';

import { Modal, Button } from 'react-bootstrap';
import { useEditingConnector } from './useEditingConnector';

export interface IConnector {
  id: string;
  name: string;
  type: string;
  connectorTypeId: number;
  details: any;
  selectedIntegration?: string;
}

export enum EConnectorUpdateType {
  UPDATED = 'updated',
  ADDED = 'added',
}

interface IConnectorsProps {}

const Connectors = (props: IConnectorsProps) => {
  window.document.title = 'Connectors | Automation';
  const [deletingConnector, setDeletingConnector] = useState<any>();
  const [connectorsData, setConnectorData] = useState<IConnector[]>([]);
  const [modalShown, setModalShown] = useState<boolean>(false);

  const connectorTypes = useAppSelector(state => state.playbookReducer.connectorTypes);

  const { editingConnector, setEditingConnector } = useEditingConnector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsAgGridClientMode(true));
    return () => {
      dispatch(setIsAgGridClientMode(false));
    };
  }, [dispatch]);

  useEffect(() => {
    try {
      Promise.allSettled([fetchConnectors(), fetchConnectorTypes()])
        .then(allResponses => {
          const [connectors, connectorTypes] = allResponses.map((result: any) => result.value);
          setConnectorData(massageConnectors(connectors?.result || []));
          dispatch(setConnectorTypes(massagedConnectorTypes(connectorTypes?.result || [])));
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      dispatch(alertActions.error('Failed to fetch connectors data'));
    }
  }, [dispatch, setConnectorData, setConnectorTypes]);

  const toggleModal = useCallback(() => {
    setModalShown(!modalShown);
  }, [modalShown]);

  const onModalSummit = useCallback(async values => {
    const action = values.id ? EConnectorUpdateType.UPDATED : EConnectorUpdateType.ADDED;
    try {
      await updateConnector(values);
      dispatch(alertActions.success(`Successfully ${action} connector '${values.name}'`));
      void fetchMassagedConnectors();
    } catch (error) {
      dispatch(alertActions.error('Updating connector failure, ' + JSON.stringify(error)));
    }
  }, []);

  const onDeleteConnector = useCallback(async () => {
    setDeletingConnector(undefined);
    try {
      await deleteConnector(deletingConnector.id);
      void fetchMassagedConnectors();
      dispatch(alertActions.success(`Successfully deleted connector '${deletingConnector.name}'`));
    } catch (error) {
      dispatch(alertActions.error('Deleting connector failure, ' + JSON.stringify(error)));
    }
  }, [dispatch, deletingConnector]);

  const fetchMassagedConnectors = async () => {
    const connectors = await fetchConnectors();
    setConnectorData(massageConnectors(connectors?.result || []));
  };

  const modifyColumns = async (columns: ITableApiColumn[]) => {
    const typeColumn = _.find(columns, { id: 'type' });
    if (typeColumn) {
      typeColumn.filterOptions = connectorTypes
        .map((connectorType: any) => {
          return {
            label: connectorType.label,
            value: connectorType.value,
          };
        })
        .concat(
          Object.keys(integrationDocsSufixMap)
            .filter(k => !simpliedIntegrationNameMap[k])
            .map(key => ({ label: key, value: key })),
        );
    }
    return columns;
  };

  const columns = useMemo(() => {
    return [
      CONNECTOR_COL_NAME,
      CONNECTOR_COL_TYPE,
      CONNECTOR_COL_CREATED_BY,
      CONNECTOR_COL_CREATED_AT,
      {
        ...CONNECTOR_COL_DETAILS,
        render: (data: any) => {
          return (
            <ConnectorActionColRenderer
              data={data}
              setEditingConnector={setEditingConnector}
              onDeleteConnector={setDeletingConnector}
              toggleModal={toggleModal}
            />
          );
        },
      },
    ];
  }, [connectorTypes, toggleModal]);

  return (
    <>
      <div className={'connectors-page page-content'}>
        <Card className='connectors-table-container'>
          <TableContextProvider
            columns={columns as any}
            dashboardName={'Connectors'}
            tableId={CONNECTOR_TABLE_ID}
            modifyColumns={modifyColumns}
            tableIndex={'type'}
          >
            <TableData
              id={CONNECTOR_TABLE_ID}
              tableIndex='type'
              title={'Implemented Connectors'}
              disableDatePicker
              data={connectorsData}
              columns={columns as any}
            />
          </TableContextProvider>
        </Card>
      </div>
      {modalShown && (
        <NewConnectorModal
          show={modalShown}
          toggleModal={toggleModal}
          onSubmit={onModalSummit}
          initValues={editingConnector}
        />
      )}
      {deletingConnector && (
        <Modal
          show={!!deletingConnector}
          onHide={() => setDeletingConnector(undefined)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          id='delete-connector-modal'
        >
          <Modal.Header closeButton>
            <Modal.Title className='delete-connector-modal-title'>
              {`Confirm Deletion of Connector`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='delete-connector-modal-body'>
              <div className='delete-connector-modal-body-title'>
                {`${deletingConnector?.name ?? ''}`}
              </div>
              <div className='delete-connector-modal-body-description'>
                Deleting this connector cannot be undone, and any integrations using it will stop
                working.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant='outline-secondary' onClick={() => setDeletingConnector(undefined)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={onDeleteConnector} autoFocus>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export { Connectors };
