import React, { useCallback, useRef } from 'react';
import { getLocalStorageValue } from '../../../../constants';
import { useAgOnFilterClick } from './useAgOnFilterClick';

export default function useOnGridReady(
  datasource: any,
  tableId: string,
  agRef: React.MutableRefObject<any>,
) {
  const isFilterModelCacheUsed = useRef(false);

  const { handleFilterClick } = useAgOnFilterClick({ agRef });

  const onGridReady = useCallback(
    params => {
      handleFilterClick();
      params.api.setGridOption('serverSideDatasource', datasource);
      const filterModelCache = getLocalStorageValue(['tableFilterModel', tableId]);
      params.api.setFilterModel(filterModelCache);
      isFilterModelCacheUsed.current = true;
    },
    [datasource, tableId],
  );

  return { onGridReady };
}
