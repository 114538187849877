import { PlatformModule } from './types';
import { IRunByClientAppType } from './util';
import abuseMailBoxSideNavDashboardLight from '../assets/abuseMailBox/sideNav-dashboard-light.svg';
import abuseMailBoxSideNavDashboardLightActive from '../assets/abuseMailBox/sideNav-dashboard-light-active.svg';
import abuseMailBoxSideNavDashboardDark from '../assets/abuseMailBox/sideNav-dashboard-dark.svg';
import abuseMailBoxSideNavDashboardDarkActive from '../assets/abuseMailBox/sideNav-dashboard-dark-active.svg';
import abuseMailBoxSideNavTargetedMaliciousLight from '../assets/abuseMailBox/sideNav-targetedMalicious-light.svg';
import abuseMailBoxSideNavTargetedMaliciousLightActive from '../assets/abuseMailBox/sideNav-targetedMalicious-light-active.svg';
import abuseMailBoxSideNavTargetedMaliciousDark from '../assets/abuseMailBox/sideNav-targetedMalicious-dark.svg';
import abuseMailBoxSideNavTargetedMaliciousDarkActive from '../assets/abuseMailBox/sideNav-targetedMalicious-dark-active.svg';
import abuseMailBoxSideNavEmailSubmissionsLight from '../assets/abuseMailBox/sideNav-emailSubmissions-light.svg';
import abuseMailBoxSideNavEmailSubmissionsLightActive from '../assets/abuseMailBox/sideNav-emailSubmissions-light-active.svg';
import abuseMailBoxSideNavEmailSubmissionsDark from '../assets/abuseMailBox/sideNav-emailSubmissions-dark.svg';
import abuseMailBoxSideNavEmailSubmissionsDarkActive from '../assets/abuseMailBox/sideNav-emailSubmissions-dark-active.svg';
import { Theme } from '@mui/material';

export enum ClientAppType {
  Bolster = 'PLATFORM',
  CheckPhish = 'CHECKPHISH',
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    errorContained: true;
    errorOutlined: true;
    errorText: true;
  }
}

// determine Bolster App Type
const getClientAppType = (): ClientAppType => {
  let clientAppType;
  const { REACT_APP_BOLSTER_APP_TYPE: envAppType } = process.env;

  // return if valid
  if (Object.values(ClientAppType).includes(envAppType as ClientAppType)) {
    clientAppType = envAppType as ClientAppType;
  }

  if (!clientAppType) {
    // check if current domain is ended with bolster.ai
    const { hostname } = window.location;
    if (hostname.match(/bolster.ai$/gi) != null) {
      clientAppType = ClientAppType.Bolster;
    }
  }

  // default to CHECKPHISH
  return clientAppType || ClientAppType.CheckPhish;
};

export const appConstants = {
  PRODUCT_CODE: '4b69317c',
  FEATURE_CODE: {
    WEB: '92bd400d',
    SOCIAL_MEDIA: '6f9182ba',
    MARKETPLACE: 'fece96a3',
    TYPOSQUATTING_SCORE: 'hfgmfz5n',
    APP_STORE: 'ccf8ff81',
    LOCATION_BASED: 'd5abcf2b',
    DARK_WEB: 'f4c33e93',
    DARK_WEB_V2: '9b36cf1b',
    GROUP_FINDINGS: 'eccbb50c', //Todo: Can be used for ZION-4929
    APP_STORE_PLAYBOOK: 'bb632d97',
    SOCIAL_PLAYBOOK: 'f1281425',
    WEB_SOC_TAKEDOWN: '6e073383',
    SOLO_TEAM: 'f25835af',
    TEAM_SCAN: '80dc5cd1',
    WEB_NEW_TIMELINE: '1e97c343',
    CROSS_PAGE: 'd1d3cd1f',
    REPORTING: 'b2d85fc2',
    NEW_WEB_DASHBOARD: 'a68c7055',
    INTERNAL_PLAYBOOK_ACCESS: 'fac65202',
    NEW_USER_SESSION: 'ef2d2b0d',
    NOTIFICATION_SETTINGS: 'dfa835ab',
    AG_GRID_TABLE: '9af41a78',
    USER_GLOBAL_TAKEDOWN: 'e1ff5bc9',
    SEARCH_TERM_RE_SCAN: '05f999cf',
    // disabled code for the main modules, will override the enabled code if both are true
    WEB_DISABLED: '56783eec',
    SOCIAL_DISABLED: 'd773a6b1',
    APP_STORE_DISABLED: '0ddd787b',
    DARK_WEB_DISABLED: '7badd21e',
    // read only user per module feature code
    READ_ONLY_USER_PER_MODULE: 'c77eddd6',
    // ORCA phase 1 - Abuse Mailbox feature code
    ABUSE_MAILBOX: '749ec1c9',
    ABUSE_MAILBOX_DISABLED: 'fbfbfd09',
    WEB_CROWD_SOURCE: '18ca806c',
    //Insights pagination
    INSIGHTS_PAGINATION: 'accc9242',
    //Screenshot Column
    SCREENSHOT_COLUMN: 'dc894e30',
    //Global Search
    GLOBAL_SEARCH: 'ac6713a8',
    //Premium Scan Location
    PREMIUM_SCAN_LOCATION: '6de9c2c7',
    ABUSE_MAILBOX_ALL_URL_ANALYSIS: '5a7278b9',
  },
  GET_ENTERPRISE_APP_CONFIG: 'GET_ENTERPRISE_APP_CONFIG',
  GET_CHECKPHISH_APP_CONFIG: 'GET_CHECKPHISH_APP_CONFIG',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_ATTRIBUTES: 'GET_ATTRIBUTES',
  SET_PRICING_POPUP: 'SET_PRICING_POPUP',
  UGC_CONTENT_TYPE: {
    SOCIAL: 'social',
    MARKETPLACE: 'marketplace',
    APP_STORE: 'app-store',
    DARK_WEB: 'dark-web',
  },
  CONTENT_TYPE: {
    SOCIAL: 'social',
    MARKETPLACE: 'marketplace',
    APP_STORE: 'app-store',
    DARK_WEB: 'dark-web',
    WEB: 'web',
    BULK_SCAN: 'bulk_scan',
    PLAYBOOK: 'playbook',
    SOCIAL_PLAYBOOK: 'social_playbook',
    CHECK_PHISH: 'checkphish',
    REPORT: 'report',
    TEAM_MEMBERS: 'team_members',
    ABUSE_MAILBOX: 'abuse-mailbox',
    TAKEDOWN_VISIBILITY_CENTER: 'takedown-visibility-center',
    ASSESTS: 'assets',
  },
  CLIENT_APP_TYPE: getClientAppType(),
};

export interface IAppDispatchProps {
  runByClientApp: ({ onBolster, onCheckPhish }: IRunByClientAppType) => unknown;
}

export const PlatformMainModules: PlatformModule[] = [
  {
    name: 'Web',
    enabledCode: appConstants.FEATURE_CODE.WEB,
    disabledCode: appConstants.FEATURE_CODE.WEB_DISABLED,
  },
  {
    name: 'Social Media',
    enabledCode: appConstants.FEATURE_CODE.SOCIAL_MEDIA,
    disabledCode: appConstants.FEATURE_CODE.SOCIAL_DISABLED,
  },
  {
    name: 'App Store',
    enabledCode: appConstants.FEATURE_CODE.APP_STORE,
    disabledCode: appConstants.FEATURE_CODE.APP_STORE_DISABLED,
  },
  {
    name: 'Dark Web',
    enabledCode: appConstants.FEATURE_CODE.DARK_WEB_V2,
    disabledCode: appConstants.FEATURE_CODE.DARK_WEB_DISABLED,
  },
  {
    name: 'Abuse MailBox',
    enabledCode: appConstants.FEATURE_CODE.ABUSE_MAILBOX,
    disabledCode: appConstants.FEATURE_CODE.ABUSE_MAILBOX_DISABLED,
  },
];

// map the feature code to the disabled code
export const MainModuleDisabledCodeMap = {
  [appConstants.FEATURE_CODE.WEB]: appConstants.FEATURE_CODE.WEB_DISABLED,
  [appConstants.FEATURE_CODE.SOCIAL_MEDIA]: appConstants.FEATURE_CODE.SOCIAL_DISABLED,
  [appConstants.FEATURE_CODE.APP_STORE]: appConstants.FEATURE_CODE.APP_STORE_DISABLED,
  [appConstants.FEATURE_CODE.DARK_WEB_V2]: appConstants.FEATURE_CODE.DARK_WEB_DISABLED,
};

export const platformUserRolesMap: {
  [key: string]: string;
} = {
  super_admin: 'Admin',
  brand_admin: 'Admin',
  brand_user: 'User',
  brand_read_only_user: 'Read-Only',
  brand_module_admin: 'Module Admin',
};

export const integrationDocsSufixMap: {
  [key: string]: string;
} = {
  Splunk: 'splunk-integration',
  'Sumo Logic': 'sumo-logic',
  Tines: 'tines-integration',
  'Cortex XSOAR': 'xsoar-integration',
  ThreatConnect: 'threatconnect-integration',
  'Microsoft Sentinel': 'microsoft-sentinal-integration',
  Slack: 'slack-integration',
  'Microsoft Teams': 'microsoft-teams-integration',
  'Microsoft 365 Exchange/Defender': 'microsoft-365-exchange-defender-integration',
  'API Integration': 'api-integration',
};

export const simpliedIntegrationNameMap: {
  [key: string]: string;
} = {
  'API Integration': 'API',
  'Microsoft 365 Exchange/Defender': 'Exchange',
};

export const SIDEBAR_ICONS = {
  '/web/dashboard': {
    darkImgURL: '/static/assets/dark/regular/dashboard-home-dark.svg',
    lightImgURL: '/static/assets/light/regular/dashboard-home-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/dashboard-home-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/dashboard-home-light-active.svg',
  },

  '/web/pre-malicious': {
    darkImgURL: '/static/assets/dark/regular/pre-malicious-dark.svg',
    lightImgURL: '/static/assets/light/regular/pre-malicious-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/pre-malicious-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/pre-malicious-light-active.svg',
  },
  '/web/takedown-malicious': {
    darkImgURL: '/static/assets/dark/regular/takedown-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-light-active.svg',
  },
  '/web/post-malicious': {
    darkImgURL: '/static/assets/dark/regular/post-malicious-dark.svg',
    lightImgURL: '/static/assets/light/regular/post-malicious-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/post-malicious-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/post-malicious-light-active.svg',
  },
  '/web/acquisitions': {
    darkImgURL: '/static/assets/dark/regular/monitor-aquisition-dark.svg',
    lightImgURL: '/static/assets/light/regular/monitor-aquisition-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/monitor-aquisition-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/monitor-aquisition-light-active.svg',
  },

  // Social Media
  '/social-media/dashboard': {
    darkImgURL: '/static/assets/dark/regular/dashboard-home-dark.svg',
    lightImgURL: '/static/assets/light/regular/dashboard-home-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/dashboard-home-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/dashboard-home-light-active.svg',
  },
  '/social-media/detection': {
    darkImgURL: '/static/assets/dark/regular/search-dark.svg',
    lightImgURL: '/static/assets/light/regular/search-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/search-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/search-light-active.svg',
  },
  '/social-media/takedown-in-progress': {
    darkImgURL: '/static/assets/dark/regular/takedown-in-progress-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-in-progress-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-in-progress-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-in-progress-light-active.svg',
  },
  '/social-media/takendown': {
    darkImgURL: '/static/assets/dark/regular/takedown-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-light-active.svg',
  },
  '/social-media/ignored': {
    darkImgURL: '/static/assets/dark/regular/ignored-dark.svg',
    lightImgURL: '/static/assets/light/regular/ignored-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/ignored-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/ignored-light-active.svg',
  },
  '/social-media/search': {
    darkImgURL: '/static/assets/dark/regular/edit-dark.svg',
    lightImgURL: '/static/assets/light/regular/edit-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/edit-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/edit-light-active.svg',
  },
  '/social-media/review': {
    darkImgURL: '/static/assets/dark/regular/eye-dark.svg',
    lightImgURL: '/static/assets/light/regular/eye-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/eye-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/eye-light-active.svg',
  },

  // Marketplace
  '/marketplace/dashboard': {
    darkImgURL: '/static/assets/dark/regular/dashboard-home-dark.svg',
    lightImgURL: '/static/assets/light/regular/dashboard-home-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/dashboard-home-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/dashboard-home-light-active.svg',
  },
  '/marketplace/detection': {
    darkImgURL: '/static/assets/dark/regular/search-dark.svg',
    lightImgURL: '/static/assets/light/regular/search-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/search-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/search-light-active.svg',
  },
  '/marketplace/takedown-in-progress': {
    darkImgURL: '/static/assets/dark/regular/takedown-in-progress-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-in-progress-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-in-progress-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-in-progress-light-active.svg',
  },
  '/marketplace/takendown': {
    darkImgURL: '/static/assets/dark/regular/takedown-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-light-active.svg',
  },
  '/marketplace/ignored': {
    darkImgURL: '/static/assets/dark/regular/ignored-dark.svg',
    lightImgURL: '/static/assets/light/regular/ignored-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/ignored-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/ignored-light-active.svg',
  },
  '/marketplace/search': {
    darkImgURL: '/static/assets/dark/regular/edit-dark.svg',
    lightImgURL: '/static/assets/light/regular/edit-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/edit-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/edit-light-active.svg',
  },
  '/marketplace/review': {
    darkImgURL: '/static/assets/dark/regular/eye-dark.svg',
    lightImgURL: '/static/assets/light/regular/eye-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/eye-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/eye-light-active.svg',
  },

  // Appstore
  '/app-store/dashboard': {
    darkImgURL: '/static/assets/dark/regular/dashboard-home-dark.svg',
    lightImgURL: '/static/assets/light/regular/dashboard-home-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/dashboard-home-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/dashboard-home-light-active.svg',
  },
  '/app-store/detection': {
    darkImgURL: '/static/assets/dark/regular/search-dark.svg',
    lightImgURL: '/static/assets/light/regular/search-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/search-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/search-light-active.svg',
  },
  '/app-store/takedown-in-progress': {
    darkImgURL: '/static/assets/dark/regular/takedown-in-progress-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-in-progress-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-in-progress-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-in-progress-light-active.svg',
  },
  '/app-store/takendown': {
    darkImgURL: '/static/assets/dark/regular/takedown-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-light-active.svg',
  },
  '/app-store/ignored': {
    darkImgURL: '/static/assets/dark/regular/ignored-dark.svg',
    lightImgURL: '/static/assets/light/regular/ignored-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/ignored-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/ignored-light-active.svg',
  },
  '/app-store/search': {
    darkImgURL: '/static/assets/dark/regular/edit-dark.svg',
    lightImgURL: '/static/assets/light/regular/edit-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/edit-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/edit-light-active.svg',
  },

  // Dark Web
  '/dark-web/dashboard': {
    darkImgURL: '/static/assets/dark/regular/dashboard-home-dark.svg',
    lightImgURL: '/static/assets/light/regular/dashboard-home-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/dashboard-home-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/dashboard-home-light-active.svg',
  },
  '/dark-web/active': {
    darkImgURL: '/static/assets/dark/regular/active-finding-dark.svg',
    lightImgURL: '/static/assets/light/regular/active-finding-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/active-finding-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/active-finding-light-active.svg',
  },
  '/dark-web/mitigated': {
    darkImgURL: '/static/assets/dark/regular/takedown-dark.svg',
    lightImgURL: '/static/assets/light/regular/takedown-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/takedown-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/takedown-light-active.svg',
  },
  '/dark-web/ignored': {
    darkImgURL: '/static/assets/dark/regular/eye-dark.svg',
    lightImgURL: '/static/assets/light/regular/eye-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/eye-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/eye-light-active.svg',
  },
  '/dark-web/threat-actors': {
    darkImgURL: '/static/assets/dark/regular/warning-dark.svg',
    lightImgURL: '/static/assets/light/regular/warning-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/warning-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/warning-light-active.svg',
  },
  '/dark-web/search': {
    darkImgURL: '/static/assets/dark/regular/edit-dark.svg',
    lightImgURL: '/static/assets/light/regular/edit-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/edit-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/edit-light-active.svg',
  },

  // assets
  '/assets/logos': {
    darkImgURL: '/static/assets/dark/regular/logos-dark.svg',
    lightImgURL: '/static/assets/light/regular/logos-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/logos-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/logos-light-active.svg',
  },
  '/assets/documents': {
    darkImgURL: '/static/assets/dark/regular/asset-document-dark.svg',
    lightImgURL: '/static/assets/light/regular/asset-document-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/asset-document-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/asset-document-light-active.svg',
  },

  '/assets/trademarks': {
    darkImgURL: '/static/assets/dark/regular/copyright-dark.svg',
    lightImgURL: '/static/assets/light/regular/copyright-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/copyright-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/copyright-light-active.svg',
  },
  '/assets/official-phone-numbers': {
    darkImgURL: '/static/assets/dark/regular/phone-dark.svg',
    lightImgURL: '/static/assets/light/regular/phone-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/phone-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/phone-light-active.svg',
  },
  '/assets/managed-by-organization': {
    darkImgURL: '/static/assets/dark/regular/customer-dark.svg',
    lightImgURL: '/static/assets/light/regular/customer-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/customer-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/customer-light-active.svg',
  },
  '/assets/managed-by-affiliates': {
    darkImgURL: '/static/assets/dark/regular/affiliates-dark.svg',
    lightImgURL: '/static/assets/light/regular/affiliates-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/affiliates-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/affiliates-light-active.svg',
  },
  '/assets/managed-by-bolster': {
    darkImgURL: '/static/assets/dark/regular/bolster-logo-dark.svg',
    lightImgURL: '/static/assets/light/regular/bolster-logo-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/bolster-logo-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/bolster-logo-light-active.svg',
  },
  '/assets/official-account/social': {
    darkImgURL: '/static/assets/dark/regular/socialmedia-dark.svg',
    lightImgURL: '/static/assets/light/regular/socialmedia-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/socialmedia-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/socialmedia-light-active.svg',
  },
  '/assets/official-account/marketplace': {
    darkImgURL: '/static/assets/dark/regular/marketplace-dark.svg',
    lightImgURL: '/static/assets/light/regular/marketplace-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/marketplace-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/marketplace-light-active.svg',
  },
  '/assets/official-account/app-store': {
    darkImgURL: '/static/assets/dark/regular/appstore-dark.svg',
    lightImgURL: '/static/assets/light/regular/appstore-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/appstore-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/appstore-light-active.svg',
  },

  // Automation
  '/automation/playbooks/web-playbook': {
    darkImgURL: '/static/assets/dark/regular/global-dark.svg',
    lightImgURL: '/static/assets/light/regular/global-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/global-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/global-light-active.svg',
  },
  '/automation/playbooks/social-media-playbook': {
    darkImgURL: '/static/assets/dark/regular/socialmedia-dark.svg',
    lightImgURL: '/static/assets/light/regular/socialmedia-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/socialmedia-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/socialmedia-light-active.svg',
  },
  '/automation/playbooks/app-store-playbook': {
    darkImgURL: '/static/assets/dark/regular/appstore-dark.svg',
    lightImgURL: '/static/assets/light/regular/appstore-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/appstore-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/appstore-light-active.svg',
  },
  '/automation/playbooks/dark-web-playbook': {
    darkImgURL: '/static/assets/dark/regular/darkweb-dark.svg',
    lightImgURL: '/static/assets/light/regular/darkweb-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/darkweb-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/darkweb-light-active.svg',
  },
  '/automation/playbooks/marketplace': {
    darkImgURL: '/static/assets/dark/regular/marketplace-dark.svg',
    lightImgURL: '/static/assets/light/regular/marketplace-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/marketplace-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/marketplace-light-active.svg',
  },

  // Itegration
  '/integrations/available-connectors': {
    darkImgURL: '/static/assets/dark/regular/available-connector-dark.svg',
    lightImgURL: '/static/assets/light/regular/available-connector-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/available-connector-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/available-connector-light-active.svg',
  },
  '/integrations/implemented-connectors': {
    darkImgURL: '/static/assets/dark/regular/implement-connector-dark.svg',
    lightImgURL: '/static/assets/light/regular/implement-connector-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/implement-connector-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/implement-connector-light-active.svg',
  },

  // Profile
  '/account/profile': {
    darkImgURL: '/static/assets/dark/regular/user-dark.svg',
    lightImgURL: '/static/assets/light/regular/user-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/user-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/user-light-active.svg',
  },
  '/account/team-members': {
    darkImgURL: '/static/assets/dark/regular/team-member-dark.svg',
    lightImgURL: '/static/assets/light/regular/team-member-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/team-member-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/team-member-light-active.svg',
  },
  '/account/communication-settings': {
    darkImgURL: '/static/assets/dark/regular/sms-notification-dark.svg',
    lightImgURL: '/static/assets/light/regular/sms-notification-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/sms-notification-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/sms-notification-light-active.svg',
  },
  '/account/change-password': {
    darkImgURL: '/static/assets/dark/regular/lock-dark.svg',
    lightImgURL: '/static/assets/light/regular/lock-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/lock-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/lock-light-active.svg',
  },
  '/manage/landing-page': {
    darkImgURL: '/static/assets/dark/regular/landing-page-dark.svg',
    lightImgURL: '/static/assets/light/regular/landing-page-light.svg',
    activeDarkImgURL: '/static/assets/dark/active/landing-page-dark-active.svg',
    activeLightImgURL: '/static/assets/light/active/landing-page-light-active.svg',
  },

  //Abuse MailBox
  '/abuse-mailbox/dashboard': {
    darkImgURL: abuseMailBoxSideNavDashboardDark,
    lightImgURL: abuseMailBoxSideNavDashboardLight,
    activeDarkImgURL: abuseMailBoxSideNavDashboardDarkActive,
    activeLightImgURL: abuseMailBoxSideNavDashboardLightActive,
  },
  '/abuse-mailbox/targeted-malicious': {
    darkImgURL: abuseMailBoxSideNavTargetedMaliciousDark,
    lightImgURL: abuseMailBoxSideNavTargetedMaliciousLight,
    activeDarkImgURL: abuseMailBoxSideNavTargetedMaliciousDarkActive,
    activeLightImgURL: abuseMailBoxSideNavTargetedMaliciousLightActive,
  },
  '/abuse-mailbox/all-email-submissions': {
    darkImgURL: abuseMailBoxSideNavEmailSubmissionsDark,
    lightImgURL: abuseMailBoxSideNavEmailSubmissionsLight,
    activeDarkImgURL: abuseMailBoxSideNavEmailSubmissionsDarkActive,
    activeLightImgURL: abuseMailBoxSideNavEmailSubmissionsLightActive,
  },
};

export const LightMUIThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      // let MUI primary color be consistent with react-bootstrap
      main: '#327EF0',
      dark: '#2360BC',
      contrastText: '#327EF080',
      light: '#FFFFFF80',
    },
    action: {
      focus: '#327EF0',
    },
    secondary: { main: '#327EF080' },
    grey: {
      300: '#0000004D',
    },
  },
  typography: {
    fontSize: 13,
    fontWeightBold: 500,
    fontFamily: 'Fakt',
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            minHeight: '30px',
            minWidth: '55px',
            textTransform: 'capitalize',
            variants: [
              {
                props: {
                  variant: 'contained',
                },
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  '&:is(:hover, :active)': {
                    backgroundColor: `${theme.palette.primary.dark} !important`,
                  },
                  '&:focus': {
                    color: '#fff !important',
                  },
                  '&:disabled': {
                    backgroundColor: '#0000001F',
                    color: '#0000004D',
                  },
                },
              },
              {
                props: {
                  variant: 'outlined',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.main,
                  borderColor: '#327EF080',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#2196F30A',
                    color: theme.palette.primary.main,
                  },
                  '&:disabled': {
                    color: '#0000004D',
                  },
                },
              },
              {
                props: {
                  variant: 'text',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.main,
                  '&:disabled': {
                    color: '#0000004D',
                  },
                },
              },

              {
                props: {
                  variant: 'errorContained',
                },
                style: {
                  backgroundColor: '#F44336',
                  color: '#fff',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#E53935',
                  },
                  '&:focus-visible': {
                    backgroundColor: '#D32F2F4D',
                  },
                },
              },

              {
                props: {
                  variant: 'errorOutlined',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: '#F44336',
                  border: '1px solid #D32F2F80',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#D32F2F0A',
                  },
                  '&:focus-visible': {
                    backgroundColor: '#D32F2F4D',
                  },
                },
              },
              {
                props: {
                  variant: 'errorText',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: '#F44336',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#D32F2F0A',
                  },
                  '&:focus-visible': {
                    backgroundColor: '#D32F2F4D',
                  },
                },
              },
            ],
          };
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            color: `${theme.palette.primary.main}`,
          };
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            fill: '#27292D99',
            cursor: 'pointer',
            '&:is(:hover, :active)': {
              fill: `#327EF0 !important`,
            },
          };
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            color: '#00000099',
            opacity: 1,
            fontFamily: 'Fakt',
            minHeight: '50px',
            '&.Mui-selected': {
              color: '#327EF0',
              borderBottom: '2px solid #327EF0',
            },
            '&:not(.Mui-selected):hover': {
              color: '#000000DE',
              borderBottom: '2px solid #000000DE',
            },
            '&.Mui-disabled': {
              color: '#00000061',
              pointerEvents: 'none',
              opacity: 1,
            },
          };
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#000000',
            fill: '#000000',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
        input: {
          color: '#000000',
          '&::placeholder': {
            color: '#000000',
            opacity: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Fakt',
          height: '35px',
          color: '#000000DE',
          '&.Mui-focused': {
            outline: '#327EF0',
          },
          '&:hover': {
            outline: '#000000',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000000DE',
          backgroundColor: '#fff',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: '#000000DE',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '350px',
          maxWidth: '550px',
          overflow: 'auto',
        },
      },
    },
  },
};

export const DarkMUIThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#327EF0CC',
      dark: '#599BFFA6',
      contrastText: '#599BFF',
      light: '#FFFFFF8F',
    },
    grey: {
      600: '#FFFFFFB2',
      50: '#FFFFFF40',
    },
  },
  typography: {
    fontSize: 13,
    fontWeightBold: 500,
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            minHeight: '30px',
            minWidth: '55px',
            textTransform: 'capitalize',
            variants: [
              {
                props: {
                  variant: 'contained',
                },
                style: {
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  '&:is(:hover, :active)': {
                    backgroundColor: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:disabled': {
                    backgroundColor: '#FFFFFF59',
                    color: '#FFFFFFB2',
                  },
                },
              },
              {
                props: {
                  variant: 'outlined',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.contrastText,
                  borderColor: theme.palette.primary.dark,
                  '&:is(:hover, :active)': {
                    backgroundColor: '#2196F30A',
                    borderColor: '#7DB0FFA6',
                    color: '#7DB0FF',
                  },
                  '&:disabled': {
                    color: '#FFFFFFB2',
                    borderColor: '#FFFFFF66',
                  },
                },
              },

              {
                props: {
                  variant: 'text',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: theme.palette.primary.contrastText,
                  '&:is(:hover, :active)': {
                    color: '#7DB0FF',
                  },
                  '&:disabled': {
                    color: theme.palette.grey['600'],
                  },
                },
              },

              {
                props: {
                  variant: 'errorContained',
                },
                style: {
                  backgroundColor: '#F44336',
                  color: '#fff',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#E53935',
                  },
                  '&:focus-visible': {
                    backgroundColor: '#D32F2F4D',
                  },
                },
              },

              {
                props: {
                  variant: 'errorOutlined',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: '#F44336',
                  border: '1px solid #D32F2F80',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#D32F2F0A',
                  },
                  '&:focus-visible': {
                    backgroundColor: '#D32F2F4D',
                  },
                },
              },
              {
                props: {
                  variant: 'errorText',
                },
                style: {
                  backgroundColor: 'transparent',
                  color: '#F44336',
                  '&:is(:hover, :active)': {
                    backgroundColor: '#D32F2F0A',
                  },
                  '&:focus-visible': {
                    backgroundColor: '#D32F2F4D',
                  },
                },
              },
            ],
          };
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: `${theme.palette.primary.contrastText}`,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            fill: '#F1F5F880',
            cursor: 'pointer',
            '&:is(:hover, :active)': {
              fill: ' #599BFF !important',
            },
          };
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            backgroundColor: '#353535',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => {
          return {
            color: '#FFFFFFA6',
            opacity: 1,
            fontFamily: 'Fakt',
            minHeight: '50px',
            '&.Mui-selected': {
              color: '#599BFF',
              borderBottom: '2px solid #599BFF',
            },
            '&:not(.Mui-selected):hover': {
              color: '#FFF',
              borderBottom: '2px solid #FFF',
            },
            '&.Mui-disabled': {
              color: '#FFFFFF99',
              pointerEvents: 'none',
              opacity: 1,
            },
          };
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#FFFFFF',
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
        input: {
          color: '#FFFFFF',
          '&::placeholder': {
            color: '#FFFFFF',
            opacity: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Fakt',
          fontSize: '14px',
          color: '#FFFFFF',
          height: '35px',
          '&.Mui-focused': {
            outline: '#599BFF',
          },
          '&:hover': {
            outline: '#FFFFFF',
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: '#383c41',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: '#383c41',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '350px',
          maxWidth: 'fit-content',
          overflow: 'auto',
        },
      },
    },
  },
};
